import { CONTAINER_MAX_WIDTH } from 'styles/theme';
import MainNavButton from '../mainNavButton/MainNavButton';
import { NavMenuItem } from './MainNav';

const BottomNavigation = ({
  backgroundColor,
  includeWrapper,
  navigationItems
}: {
  backgroundColor?: string;
  includeWrapper?: boolean;
  navigationItems?: NavMenuItem[];
}) => (
  <>
    {navigationItems && navigationItems?.length > 0 && (
      <div
        className="mainNavWrapper"
        sx={{
          zIndex: '2997',
          backgroundColor: backgroundColor ?? 'brandNavy100',
          height: '5rem',
          padding: ['1rem 2rem', null, null, '1rem 1.5rem']
        }}
      >
        <ul
          className="mainNavLinks"
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.25rem',
            maxWidth: includeWrapper ? CONTAINER_MAX_WIDTH : undefined,
            margin: includeWrapper ? '0 auto' : undefined
          }}
        >
          {navigationItems?.map(item => (
            <li key={item.id}>
              <MainNavButton
                buttonColor={backgroundColor ?? 'dark'}
                {...item}
                icon={undefined}
              />
            </li>
          ))}
        </ul>
      </div>
    )}
  </>
);

export default BottomNavigation;
