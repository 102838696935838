import { FormError } from 'types';

export const getErrorMessage = (
  errorMessage?: string | FormError | Array<string>
) => {
  if (typeof errorMessage === 'string') {
    return errorMessage;
  }

  if (Array.isArray(errorMessage)) {
    return errorMessage?.length === 0 ? '' : errorMessage?.map(x => x);
  }

  return errorMessage?.message ?? '';
};
