/* eslint-disable consistent-return */
import { call, put, select } from 'redux-saga/effects';
import Router from 'next/router';

import { RootState } from 'types';
import {
  systemErrorAction,
  logoutForcedAction
} from 'containers/auth/userSessionActions';

export function* safeEffect(saga: any, ...args: any[]) {
  try {
    yield call(saga, ...args);
  } catch (e) {
    yield call(systemErrorAction, e);
  }
}

/**
 * Intercept service call response for 401 unauthorized; redirect to login
 * page and logout user if 401
 */
export function* safeAuthEffect(saga: any, ...args: any[]): any {
  try {
    const res = yield call(saga, ...args);

    if (res?.status === 401) {
      yield put(logoutForcedAction({ logoutNotification: 'expired' }));
    }

    return res;
  } catch (e) {
    yield call(systemErrorAction, e);
  }
}

/**
 * Safe router navigation to enable sagas to return user to last cached router nav;
 * intent is to not rely on Router.back() as this is not always what is expected
 * due to user navigating into app externally or from RouterConfirm cancelling navigation
 */
export function* safeNavHistoryBack(fallbackRoute = '/home') {
  const { history } = yield select((state: RootState) => state.navHistory);
  const route =
    history.length > 2 ? history[history.length - 2] : fallbackRoute;
  yield Router.push(route ?? fallbackRoute);
}
