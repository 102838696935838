/* eslint-disable camelcase */
import { isNil } from 'lodash';

import {
  Form143Model,
  UnmappedEmploymentPeriod,
  EmploymentPeriod,
  ContactModel
} from 'interfaces';
import { format, parse } from 'date-fns';
import { getYMDStringsFromDate, formatToCad } from 'utils';
import { Form143Data } from './form143Reducer';
import { swapYears } from './utils/143Utils';

export const mapEmploymentPeriods = (
  employmentPeriods: UnmappedEmploymentPeriod[]
): EmploymentPeriod[] => {
  if (employmentPeriods && employmentPeriods.filter(val => val).length > 0) {
    return employmentPeriods.map(
      (employmentPeriod: UnmappedEmploymentPeriod): EmploymentPeriod => ({
        retroTotal: !isNil(employmentPeriod.hashMap?.retro_total)
          ? String(employmentPeriod.hashMap.retro_total)
          : '',
        earnings: !isNil(employmentPeriod.hashMap?.earnings)
          ? formatToCad(employmentPeriod.hashMap.earnings, 'en', false)
          : '',
        creditedService: !isNil(employmentPeriod.hashMap?.credited_service)
          ? String(employmentPeriod.hashMap.credited_service)
          : '',
        pensionAdjustment: !isNil(
          employmentPeriod.hashMap?.pension_adjustment_amt
        )
          ? formatToCad(
              employmentPeriod.hashMap.pension_adjustment_amt,
              'en',
              false
            )
          : '',
        rppContribution: !isNil(employmentPeriod.hashMap?.rpp_contribution_amt)
          ? formatToCad(
              employmentPeriod.hashMap.rpp_contribution_amt,
              'en',
              false
            )
          : '',
        rcaContribution: !isNil(employmentPeriod.hashMap?.rca_contribution_amt)
          ? formatToCad(
              employmentPeriod.hashMap.rca_contribution_amt,
              'en',
              false
            )
          : ''
      })
    );
  }
  return [];
};

export const mapForm143 = (model: Form143Model): Form143Data => {
  const { employmentModel, supportingInfoModel, financialModel } = model;

  const {
    employmentPeriodCurrYearModels,
    employmentPeriodPrevYearModels
  } = swapYears(financialModel);

  const isAlternative =
    (supportingInfoModel?.deathModel?.eligibleChildrenStatus === 'N' ||
      supportingInfoModel?.deathModel?.eligibleChildrenStatus === 'D') &&
    (supportingInfoModel?.deathModel?.eligibleSpouseStatus === 'N' ||
      supportingInfoModel?.deathModel?.eligibleSpouseStatus === 'D');

  const commonLaw =
    supportingInfoModel?.deathModel?.contactModel?.relationshipType === 'O' &&
    supportingInfoModel.deathModel?.contactModel?.relationshipOther ===
      'Common law'
      ? 'Y'
      : supportingInfoModel?.deathModel?.contactModel?.relationshipType === 'S'
      ? 'N'
      : supportingInfoModel?.deathModel?.contactModel?.relationshipType ===
          'O' &&
        supportingInfoModel.deathModel?.contactModel?.relationshipOther ===
          'Spouse or common law'
      ? 'D'
      : '';

  const relationshipToMemberAlternative = isAlternative
    ? supportingInfoModel?.deathModel?.contactModel?.relationshipOther ===
      'Unknown'
      ? 'I'
      : supportingInfoModel?.deathModel?.contactModel?.relationshipType ?? ''
    : '';

  const reusedModel: Array<ContactModel> = [
    {
      contactMemberFirstName:
        supportingInfoModel?.deathModel?.contactModel?.firstName ?? '',
      contactMemberLastName:
        supportingInfoModel?.deathModel?.contactModel?.lastName ?? '',
      contactBirthDateDay: supportingInfoModel?.deathModel?.contactModel
        ?.birthDate
        ? getYMDStringsFromDate(
            supportingInfoModel?.deathModel?.contactModel?.birthDate
          ).day
        : '',
      contactBirthDateMonth: supportingInfoModel?.deathModel?.contactModel
        ?.birthDate
        ? getYMDStringsFromDate(
            supportingInfoModel?.deathModel?.contactModel?.birthDate
          ).month
        : '',
      contactBirthDateYear: supportingInfoModel?.deathModel?.contactModel
        ?.birthDate
        ? getYMDStringsFromDate(
            supportingInfoModel?.deathModel?.contactModel?.birthDate
          ).year
        : '',
      address1: !isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.addrLine1 ?? ''
        : '',
      address2: !isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.addrLine2 ?? ''
        : '',
      contactMemberFirstNameNoSpouse: '',
      contactMemberLastNameNoSpouse: '',
      city: !isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.city ?? ''
        : '',
      province: !isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.province ?? ''
        : '',
      postal: !isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.postalCode ?? ''
        : '',
      country: !isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.country ?? 'CAN'
        : 'CAN',
      contactPrimaryPhone: !isAlternative
        ? (supportingInfoModel?.deathModel?.contactModel?.phoneAreaCode || '') +
          (supportingInfoModel?.deathModel?.contactModel?.phone || '')
        : '',
      apartmentUnit: !isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.addrLine3 ?? ''
        : '',
      contactSIN:
        supportingInfoModel?.deathModel?.contactModel?.spouseSin ?? '',
      commonLaw,
      relationshipToMemberAlternative,
      pleaseSpecifyAlternative:
        supportingInfoModel?.deathModel?.contactModel?.relationshipOther ?? '',
      contactPrimaryPhoneAlternative: isAlternative
        ? (supportingInfoModel?.deathModel?.contactModel?.phoneAreaCode || '') +
          (supportingInfoModel?.deathModel?.contactModel?.phone || '')
        : '',
      contactMemberFirstNameAlternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.firstName ?? ''
        : '',
      contactMemberLastNameAlternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.lastName ?? ''
        : '',
      address1Alternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.addrLine1 ?? ''
        : '',
      address2Alternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.addrLine2 ?? ''
        : '',
      apartmentUnitAlternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.addrLine3 ?? ''
        : '',
      cityAlternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.city ?? ''
        : '',
      provinceAlternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.province ?? ''
        : '',
      postalAlternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.postalCode ?? ''
        : '',
      countryAlternative: isAlternative
        ? supportingInfoModel?.deathModel?.contactModel?.country ?? 'CAN'
        : 'CAN'
    }
  ];
  const childModel =
    (isAlternative && supportingInfoModel?.deathModel?.contactModel) ||
    (supportingInfoModel?.deathModel?.eligibleChildrenStatus === 'Y' &&
      !supportingInfoModel?.deathModel?.childModels) ||
    (isAlternative && !supportingInfoModel?.deathModel?.childModels)
      ? reusedModel
      : supportingInfoModel?.deathModel?.childModels
      ? supportingInfoModel?.deathModel?.childModels.map(child => ({
          contactMemberFirstName: child.firstName ?? '',
          contactMemberLastName: child.lastName ?? '',
          contactBirthDateMonth: child.birthDate
            ? getYMDStringsFromDate(child.birthDate).month
            : '',
          contactBirthDateDay: child.birthDate
            ? getYMDStringsFromDate(child.birthDate).day
            : '',
          contactBirthDateYear: child.birthDate
            ? getYMDStringsFromDate(child.birthDate).year
            : '',
          contactMemberFirstNameNoSpouse: child.contactFirstName ?? '',
          contactMemberLastNameNoSpouse: child.contactLastName ?? '',
          livingWithSpouse: child.liveWithSpouseInd ?? '',
          address1: child.addrLine1 ?? '',
          address2: child.addrLine2 ?? '',
          apartmentUnit: child.addrLine3 ?? '',
          country: child.country ?? 'CAN',
          province: child.province ?? '',
          city: child.city ?? '',
          postal: child.postalCode ?? '',
          postalAlternative: child.postalCode ?? '',
          countryAlternative: child.country ?? 'CAN',
          relationshipToMemberAlternative: '',
          pleaseSpecifyAlternative: '',
          contactPrimaryPhone:
            (child.phoneAreaCode || '') + (child.phone || ''),
          contactPrimaryPhoneAlternative:
            (child.phoneAreaCode || '') + (child.phone || '')
        }))
      : [];
  return {
    eventType: employmentModel?.eventType ?? '',
    dateLastWorked: employmentModel?.dateLastWorked ?? '',
    minimumRetirementDate: '',
    reasonForDifference: employmentModel?.reasonForDifference ?? '',
    reasonForDifferenceOther: employmentModel?.reasonForDifferenceOther ?? '',
    lastDateRegEarnings:
      employmentModel?.lastDateRegEarnings ??
      employmentModel?.dateLastWorked ??
      '',
    advanceElectionInd:
      supportingInfoModel?.retirementModel?.advanceElectionInd ?? '',
    memberFirstName:
      supportingInfoModel?.retirementModel?.spouseModel?.firstName ?? '',
    memberMiddleName:
      supportingInfoModel?.retirementModel?.spouseModel?.middleName ?? '',
    memberLastName:
      supportingInfoModel?.retirementModel?.spouseModel?.lastName ?? '',
    birthDateMonth:
      getYMDStringsFromDate(
        supportingInfoModel?.retirementModel?.spouseModel?.birthday
      ).month ?? '',
    birthDateDay:
      getYMDStringsFromDate(
        supportingInfoModel?.retirementModel?.spouseModel?.birthday
      ).day ?? '',
    birthDateYear:
      getYMDStringsFromDate(
        supportingInfoModel?.retirementModel?.spouseModel?.birthday
      ).year ?? '',
    maritalStatus:
      supportingInfoModel?.retirementModel?.spouseModel?.maritalStatus ?? '',
    showType3Supp: supportingInfoModel?.retirementModel?.showType3Supp ?? 'N',
    type3ProvidedInd:
      supportingInfoModel?.retirementModel?.type3ProvidedInd ?? 'N',
    employmentPeriodsCurrent: mapEmploymentPeriods(
      employmentPeriodCurrYearModels
    ),
    employmentPeriodsPrevious: mapEmploymentPeriods(
      employmentPeriodPrevYearModels
    ),
    carryOver: mapEmploymentPeriods(
      financialModel.carryOverModel === null
        ? []
        : [financialModel.carryOverModel]
    ),
    eligibleSpouse: supportingInfoModel?.deathModel?.eligibleSpouseStatus ?? '',
    eligibleChildren:
      supportingInfoModel?.deathModel?.eligibleChildrenStatus ?? '',
    spouseModel:
      (!isAlternative && supportingInfoModel?.deathModel?.contactModel) ||
      (supportingInfoModel?.deathModel?.eligibleSpouseStatus === 'Y' &&
        !supportingInfoModel?.deathModel?.contactModel)
        ? reusedModel
        : [],
    childModel,
    percentageTimeWorked: employmentModel.otcftPct ?? '',
    reasonForAdjustment: employmentModel.reasonForAdjustment ?? '',
    disabilityStartDate: employmentModel.otcftNotWorkedFromDate
      ? format(
          parse(
            employmentModel.otcftNotWorkedFromDate,
            'yyyy-MM-dd',
            new Date()
          ),
          'MM/dd/yyyy'
        )
      : '',
    disabilityEndDate: employmentModel.otcftNotWorkedToDate
      ? format(
          parse(employmentModel.otcftNotWorkedToDate, 'yyyy-MM-dd', new Date()),
          'MM/dd/yyyy'
        )
      : '',
    cppDisabilityBenefitInd:
      supportingInfoModel?.disabilityModel?.cppDisabilityBenefitInd ?? '',
    cppDisabilityBenefitStatus:
      supportingInfoModel?.disabilityModel?.cppDisabilityBenefitInd ?? '',
    ltdBenefitInd: supportingInfoModel?.disabilityModel?.ltdBenefitInd ?? '',
    showCPP: supportingInfoModel?.disabilityModel?.showCPP ?? '',
    waiverAnnualEarningsAmt: !isNil(
      supportingInfoModel?.disabilityModel?.waiverAnnualEarningsAmt
    )
      ? formatToCad(
          supportingInfoModel?.disabilityModel?.waiverAnnualEarningsAmt,
          'en',
          false
        )
      : '',
    wsibBenefitInd: supportingInfoModel?.disabilityModel?.wsibBenefitInd ?? '',
    wsibBenefitStatus:
      supportingInfoModel?.disabilityModel?.wsibBenefitStatus ?? '',
    wsibMonthlyAmt: !isNil(supportingInfoModel?.disabilityModel?.wsibMonthlyAmt)
      ? formatToCad(
          supportingInfoModel?.disabilityModel?.wsibMonthlyAmt,
          'en',
          false
        )
      : '',
    noteToOmers: model?.notes ?? ''
  };
};
