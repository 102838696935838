import { forwardRef, useState } from 'react';
import {
  Classes,
  FormGroup,
  FormGroupProps,
  IRef,
  TextArea as TextAreaBP,
  TextAreaProps as TextAreaBPProps
} from '@blueprintjs/core';
import { ThemeUIStyleObject } from 'theme-ui';
import { useTranslation } from 'react-i18next';

export type FormGroupTextAreaProps = FormGroupProps &
  Omit<TextAreaBPProps, 'large' | 'loading'>;
export interface TextAreaProps extends FormGroupTextAreaProps {
  error?: string;
  hideErrorMessage?: boolean;
  hideCounter?: boolean;
  characterCount?: number;
  placeholder?: string;
  sxFormGroup?: ThemeUIStyleObject;
  id: string;
}

const formGroupStyle = (hasError: boolean, reachedLimit: boolean) => ({
  [`&.${Classes.FORM_GROUP} label.${Classes.LABEL}`]: {
    fontFamily: 'body',
    fontSize: 'small',
    fontWeight: 'bold',
    mb: '8px'
  },
  [`&.${Classes.FORM_GROUP} .${Classes.FORM_HELPER_TEXT}`]: {
    fontFamily: 'body',
    fontSize: 'small',
    color: 'extendedRed100',
    mt: '8px'
  },
  [`.${Classes.INPUT}`]: {
    fontFamily: 'body',
    fontSize: 'base'
  },
  '.helper-text-div': {
    display: 'flex',
    justifyContent: hasError ? 'space-between' : 'flex-end'
  },
  '.char-count-span': {
    color: reachedLimit ? 'extendedRed100' : 'grey100',
    fontSize: 'caption'
  }
});

const textAreaStyle = (hasError: boolean): ThemeUIStyleObject => ({
  [`&.${Classes.INPUT}`]: {
    lineHeight: '20px',
    margin: '0px',
    borderRadius: '5px',
    color: 'darkGreyText',
    border: '1px solid',
    borderColor: hasError ? 'extendedRed100' : 'grey100',
    backgroundColor: hasError ? 'extendedRed25' : 'grey10',
    boxShadow: 'none',
    padding: '12px 15px 12px 19px',
    '&:disabled, :read-only': {
      backgroundColor: 'grey10',
      borderColor: 'grey80',
      '&:hover': {
        backgroundColor: 'grey10'
      }
    }
  },
  [`&.${Classes.INPUT}:hover`]: {
    backgroundColor: 'extendedBlue25'
  },
  [`&.${Classes.INPUT}:hover:disabled`]: {
    backgroundColor: 'grey10'
  },
  [`&.${Classes.INPUT}::placeholder`]: {
    color: 'grey80'
  }
});

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      label,
      error,
      placeholder,
      hideErrorMessage = false,
      disabled,
      fill = true,
      hideCounter = false,
      characterCount,
      sxFormGroup,
      id,
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState('');
    const { t } = useTranslation('common');

    return (
      <FormGroup
        label={label}
        labelFor={id}
        helperText={(() => {
          if (props.maxLength) {
            return (
              <div className="helper-text-div">
                {!hideErrorMessage && error ? <span>{error}</span> : <div />}
                {!hideCounter && (
                  <span className="char-count-span">
                    {characterCount ?? value?.length ?? 0}{' '}
                    {t('FORM_TEXT_AREA_COUNTER_OF')} {props.maxLength}{' '}
                    {t('FORM_TEXT_AREA_COUNTER_CHARACTERS')}
                  </span>
                )}
              </div>
            );
          }

          return error || undefined;
        })()}
        intent={error ? 'danger' : undefined}
        sx={{
          ...formGroupStyle(!!error, value?.length === props.maxLength),
          ...sxFormGroup
        }}
      >
        <TextAreaBP
          {...props}
          id={id}
          data-testid={`text-area-${id}`}
          fill={fill}
          disabled={disabled}
          sx={{ ...textAreaStyle(!!error) }}
          aria-invalid={!!error}
          inputRef={ref as IRef<HTMLTextAreaElement>}
          placeholder={placeholder}
          onChange={e => {
            if (props.onChange) {
              props.onChange(e);
            }
            return setValue(e.target.value);
          }}
        />
      </FormGroup>
    );
  }
);

export default TextArea;
