import { isPlainObject } from 'lodash';

export const stripToken = (token: string) => token.replace('Bearer ', '');

type ErrorWithMessage = {
  message: string;
};

type ErrorWithStack = {
  stack: string;
};

function isErrorWithMessage(error: unknown): error is ErrorWithMessage {
  return (
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof (error as Record<string, unknown>).message === 'string'
  );
}

function isErrorWithStack(error: unknown): error is ErrorWithStack {
  return (
    typeof error === 'object' &&
    error !== null &&
    'stack' in error &&
    typeof (error as Record<string, unknown>).stack === 'string'
  );
}

/**
 * Converts an error object into a logger error object compatible with Winston.
 * @param error - The error object to convert.
 * @returns The logger error object.
 */
export const toLoggerError = (error: unknown) => {
  let errorMessage: string;

  if (isPlainObject(error)) {
    // occasionally errors we want to log something that isn't an Error object
    return error as Record<string | number | symbol, unknown>;
  }

  if (isErrorWithMessage(error)) {
    errorMessage = error.message;
  } else if (typeof error === 'string' || error instanceof String) {
    errorMessage = error.toString();
  } else {
    try {
      errorMessage = JSON.stringify(error);
    } catch {
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#exceptions
      errorMessage = String(error);
    }
  }

  return {
    message: errorMessage,
    stack: isErrorWithStack(error) ? error.stack : 'No stack available'
  };
};
