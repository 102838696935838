import { useEffect } from 'react';
import { useRouter } from 'next/router';

/**
 * React hooks listens for route change and triggers onExitCallback when the
 * user navigates away from the form
 */
const useFormExit = (onExitCallback: Function, deps: Array<any> = []) => {
  const Router = useRouter();

  useEffect(() => {
    const listener = () => {
      onExitCallback();
    };

    Router.events.on('routeChangeStart', listener);
    return () => Router.events.off('routeChangeStart', listener);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router.events, onExitCallback, ...deps]);
};

export default useFormExit;
