export const warningCodes = {
  WarningCodeMap: [
    {
      Code: '-1',
      DefaultMsg:
        'System warning. Please contact OMERS Employer Experience at 1-833-884-0389 or 416-350-6750.  OMERS Ref. No: -1'
    },
    {
      Code: '401',
      DefaultMsg:
        "We're currently reviewing this membership record. Please contact OMERS Employer Experience before using this information on a Form 143 - Request for an OMERS plan benefit or Form 190 - Request for a pension estimate."
    }
  ]
};
