import { EapAction } from 'types';
import {
  FormState,
  FormStatus
} from 'containers/requestForm/common/interfaces';
import {
  HighestEarningModel,
  MemberSearchResult,
  QuoteAssumptionFormData,
  ServicePeriodModel
} from 'interfaces';
import { format, parseISO } from 'date-fns';
import { getSubmissionStatus } from 'utils';
import { form190ActionTypes } from './form190Actions';
import {
  buildForm190Data,
  getServicePeriodModelTypes
} from './utils/form190Utils';

export interface DirtyValues {
  [key: string]: any;
}
export type D = keyof DirtyValues;

export interface Form190Data {
  servicePeriodModels: Array<ServicePeriodModel>;
  highestEarningsModels: Array<HighestEarningModel>;
  quoteAssumptionModel: QuoteAssumptionFormData | null;
  noteToMember: string;
  noteToOmers: string;
}

export interface Form190State extends FormState {
  form190Data: Form190Data;
  memberInfo: MemberSearchResult | null;
  dirtyValues: DirtyValues;
  retroPayModels: any | null;
  activeServicePeriodNo: number | null;
  retroStatus: FormStatus;
  servicePeriodModels: Array<ServicePeriodModel>;
}

const form190Data: Form190Data = {
  servicePeriodModels: [],
  highestEarningsModels: [],
  quoteAssumptionModel: {
    nraMilestoneInd: 'N',
    unreducedMilestoneInd: 'N',
    firstAssumedEventDate: '',
    secondAssumedEventDate: '',
    thirdAssumedEventDate: '',
    fourthAssumedEventDate: '',
    fifthAssumedEventDate: '',
    firstPeriodProjectionPct: '',
    secondPeriodProjectionPct: '',
    firstPeriodToDate: '',
    firstPeriodToDateYear: '',
    requestNumber: null,
    otcftPct: '',
    otcftNotWorkedFromDate: '',
    otcftNotWorkedToDate: '',
    otcftCode: 'C',
    lastEarningsDate: ''
  },
  noteToMember: '',
  noteToOmers: ''
};

export const initialState: Form190State = {
  requestNumber: null,
  initLoading: false,
  formStep: 1,
  formStatus: '' as const,
  submissionStatus: '' as const,
  submissionComplete: false,
  exceptions: [],
  overrideExceptions: [],
  pdfs: [],
  form190Data,
  memberInfo: null,
  dirtyValues: {},
  retroPayModels: {},
  activeServicePeriodNo: null,
  retroStatus: '' as const,
  servicePeriodModels: []
};

const form190 = (
  state: Form190State = initialState,
  action: EapAction
): Form190State => {
  const { type, data } = action;
  switch (type) {
    case form190ActionTypes.SET_REQUEST_NUMBER_FORM_190:
      return {
        ...state,
        requestNumber: data,
        initLoading: true
      };
    case form190ActionTypes.GET_FORM_190_SUCCESS:
      return {
        ...state,
        dirtyValues: {},
        servicePeriodModels: data.servicePeriodModels,
        requestNumber: data.requestNumber,
        form190Data: buildForm190Data(data),
        retroPayModels: {},
        activeServicePeriodNo: null,
        memberInfo: data.memberInfo,
        exceptions: data.exceptions,
        initLoading: false,
        formStep: 1,
        formStatus: '' as const,
        retroStatus: '' as const,
        submissionStatus: '' as const,
        submissionComplete: false,
        overrideExceptions: []
      };
    case form190ActionTypes.GET_FORM_190_FAILED:
      return {
        ...state,
        initLoading: false
      };
    case form190ActionTypes.CLEAR_FORM_190_STATUS:
      return {
        ...state,
        formStatus: '' as const
      };
    case form190ActionTypes.SET_FORM_190_STEP:
      return {
        ...state,
        formStep: data
      };
    case form190ActionTypes.ADD_SERVICE_PERIOD_190_SUCCESS:
      return {
        ...state,
        form190Data: {
          ...state.form190Data,
          quoteAssumptionModel: { ...state.dirtyValues.quoteAssumptionModel },
          noteToMember: state.dirtyValues.noteToMember,
          noteToOmers: state.dirtyValues.noteToOmers,
          servicePeriodModels: [
            ...getServicePeriodModelTypes(state.dirtyValues.servicePeriodModels)
              ?.servicePeriodModels,
            {
              associatedService: data?.associatedService,
              creditedService: data?.creditedService,
              dirty: data?.dirty,
              earnings: data?.earnings,
              hashMap: data?.hashMap,
              requestNo: data?.requestNo,
              retroEarningsTotal: data?.retroEarningsTotal,
              retroEndDate: data?.retroEndDate,
              retroStartDate: data?.retroStartDate,
              retroType: data?.retroType,
              servicePeriodNo: data?.servicePeriodNo,
              servicePeriodType: data?.servicePeriodType,
              updateUserId: data?.updateUserId,
              year: data?.year
            }
          ]
        }
      };
    case form190ActionTypes.REMOVE_SERVICE_PERIOD_190_SUCCESS:
      return {
        ...state,
        form190Data: {
          ...state.form190Data,
          quoteAssumptionModel: { ...state.dirtyValues.quoteAssumptionModel },
          noteToMember: state.dirtyValues.noteToMember,
          noteToOmers: state.dirtyValues.noteToOmers,
          servicePeriodModels: [
            ...getServicePeriodModelTypes(
              state.dirtyValues.servicePeriodModels
            )?.servicePeriodModels.filter(
              service => service.servicePeriodNo !== data
            )
          ]
        }
      };
    case form190ActionTypes.CLEAR_FORM_190_DATA:
      return {
        ...initialState
      };
    case form190ActionTypes.STASH_DIRTY_VALUES:
      return {
        ...state,
        dirtyValues: data
      };
    case form190ActionTypes.SAVE_FORM_190_REQUESTED:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form190ActionTypes.SAVE_FORM_190_SUCCEED:
      return {
        ...state,
        dirtyValues: {},
        formStatus: 'success' as const,
        retroStatus: '' as const,
        form190Data: {
          ...state.form190Data,
          quoteAssumptionModel: {
            ...state.form190Data.quoteAssumptionModel,
            nraMilestoneInd: data.quoteAssumptionModel.nraMilestoneInd,
            unreducedMilestoneInd:
              data.quoteAssumptionModel.unreducedMilestoneInd,
            firstAssumedEventDate: data.quoteAssumptionModel
              .firstAssumedEventDate
              ? format(
                  parseISO(data.quoteAssumptionModel.firstAssumedEventDate),
                  'MM/dd/yyyy'
                )
              : '',
            secondAssumedEventDate: data.quoteAssumptionModel
              .secondAssumedEventDate
              ? format(
                  parseISO(data.quoteAssumptionModel.secondAssumedEventDate),
                  'MM/dd/yyyy'
                )
              : '',
            thirdAssumedEventDate: data.quoteAssumptionModel
              .thirdAssumedEventDate
              ? format(
                  parseISO(data.quoteAssumptionModel.thirdAssumedEventDate),
                  'MM/dd/yyyy'
                )
              : '',
            fourthAssumedEventDate: data.quoteAssumptionModel
              .fourthAssumedEventDate
              ? format(
                  parseISO(data.quoteAssumptionModel.fourthAssumedEventDate),
                  'MM/dd/yyyy'
                )
              : '',
            fifthAssumedEventDate: data.quoteAssumptionModel
              .fifthAssumedEventDate
              ? format(
                  parseISO(data.quoteAssumptionModel.fifthAssumedEventDate),
                  'MM/dd/yyyy'
                )
              : '',
            firstPeriodProjectionPct:
              data.quoteAssumptionModel.firstPeriodProjectionPct,
            firstPeriodToDateYear:
              data.quoteAssumptionModel.firstPeriodToDateYear,
            secondPeriodProjectionPct:
              data.quoteAssumptionModel.secondPeriodProjectionPct,
            otcftPct: data.quoteAssumptionModel.otcftPct,
            otcftNotWorkedFromDate: data.quoteAssumptionModel
              .otcftNotWorkedFromDate
              ? format(
                  parseISO(data.quoteAssumptionModel.otcftNotWorkedFromDate),
                  'MM/dd/yyyy'
                )
              : '',
            otcftNotWorkedToDate: data.quoteAssumptionModel.otcftNotWorkedToDate
              ? format(
                  parseISO(data.quoteAssumptionModel.otcftNotWorkedToDate),
                  'MM/dd/yyyy'
                )
              : '',
            lastEarningsDate: data.quoteAssumptionModel.lastEarningsDate
              ? format(
                  parseISO(data.quoteAssumptionModel.lastEarningsDate),
                  'MM/dd/yyyy'
                )
              : '',
            otcftCode: data.quoteAssumptionModel.otcftCode
          },
          servicePeriodModels: data.servicePeriodModels,
          noteToOmers: data.notes,
          noteToMember: data.commentsToMember
        }
      };
    case form190ActionTypes.SAVE_FORM_190_FAILED:
      return {
        ...state,
        formStatus: 'error' as const,
        retroStatus: '' as const
      };
    case form190ActionTypes.GET_RETRO_PAY_190_SUCCESS: {
      return {
        ...state,
        retroPayModels: {
          ...state.retroPayModels,
          [data.servicePeriodNo]: data.retroPayModels
        },
        activeServicePeriodNo: data.servicePeriodNo
      };
    }
    case form190ActionTypes.GET_LATEST_FORM_190_SUCCESS: {
      return {
        ...state,
        form190Data: {
          ...state.form190Data,
          servicePeriodModels: data?.e190Model?.servicePeriodModels
        },
        activeServicePeriodNo: null
      };
    }
    case form190ActionTypes.SAVE_RETRO_PAY_190_REQUESTED: {
      return {
        ...state,
        retroStatus: 'saving'
      };
    }
    case form190ActionTypes.SAVE_RETRO_PAY_190_SUCCESS: {
      return {
        ...state,
        retroStatus: 'success'
      };
    }
    case form190ActionTypes.SAVE_RETRO_PAY_190_FAILED: {
      return {
        ...state,
        retroStatus: 'error'
      };
    }
    case form190ActionTypes.DELETE_FORM_190:
      return {
        ...state,
        formStatus: 'deleted',
        requestNumber: null,
        memberInfo: null
      };
    case form190ActionTypes.OVERRIDE_FORM_190_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form190ActionTypes.SUBMIT_FORM_190_SUCCESS:
      return {
        ...state,
        exceptions: !data.exceptions ? [] : data.exceptions,
        overrideExceptions: [],
        submissionStatus: getSubmissionStatus(data, data.exceptions),
        submissionComplete:
          getSubmissionStatus(data, data.exceptions) === 'success',
        formStep:
          getSubmissionStatus(data, data.exceptions) === 'success' ? 3 : 2,
        pdfs: data.attachments
      };
    case form190ActionTypes.SET_FORM_190_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    default:
      return state;
  }
};

export default form190;
