import { EapAction } from 'types';
import { propertiesActionTypes } from './propertiesActions';

export interface PropertiesState {
  readonly properties: any;
}

const initialState = {
  properties: null
};

const properties = (
  state: PropertiesState = initialState,
  action: EapAction
): PropertiesState => {
  const { type, data } = action;

  switch (type) {
    case propertiesActionTypes.PROPERTIES_SUCCEEDED:
      return {
        ...state,
        properties: data.properties
      };
    case propertiesActionTypes.PROPERTIES_FAILED:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default properties;
