import { EapAction } from 'types';
import { memberProfileActionTypes } from './memberProfileActions';

export interface RetroPayEarningsState {
  retroServicePeriodList: Array<any>;
  mssForm143ServicePeriod: any;
  isLoading: boolean;
}

export const initialState = {
  retroServicePeriodList: [],
  mssForm143ServicePeriod: null,
  isLoading: false
};

const retroPayEarnings = (
  state: RetroPayEarningsState = initialState,
  action: EapAction
): RetroPayEarningsState => {
  const { type, data } = action;
  switch (type) {
    case memberProfileActionTypes.RETRO_PAY_REQUESTED:
      return {
        ...state,
        retroServicePeriodList: [],
        mssForm143ServicePeriod: null,
        isLoading: true
      };
    case memberProfileActionTypes.RETRO_PAY_SUCCEEDED:
      return {
        ...state,
        retroServicePeriodList:
          data.memberRetroPayEarnings.retroServicePeriodList,
        mssForm143ServicePeriod:
          data.memberRetroPayEarnings.mssForm143ServicePeriod,
        isLoading: false
      };
    case memberProfileActionTypes.RETRO_PAY_FAILED:
      return {
        ...state,
        retroServicePeriodList: [],
        mssForm143ServicePeriod: null,
        isLoading: false
      };
    default:
      return state;
  }
};

export default retroPayEarnings;
