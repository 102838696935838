import { all, takeLatest, put, call } from 'redux-saga/effects';

import { EapAction } from 'types';

import { getApiErrorMessage } from 'utils';

import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';

import {
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';

import { updatePassword } from 'services';
import {
  updatePasswordActionTypes,
  submitUpdatePasswordSuccessAction,
  submitUpdatePasswordFailedAction
} from './updatePasswordActions';

export function* submitUpdatePasswordSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(updatePassword, action.data);

  if (res.data) {
    yield put(clearNotifications());
    yield put(submitUpdatePasswordSuccessAction());
  } else if (
    res.errors &&
    res.errors[0]?.msg?.indexOf(
      "We didn't recognize the username or password you entered"
    ) !== -1
  ) {
    yield put(
      notifyErrorAction(
        getApiErrorMessage([
          {
            code: '8',
            msg: 'invalid password given',
            parameters: null
          }
        ])
      )
    );
    yield put(submitUpdatePasswordFailedAction());
  } else if (
    res.errors &&
    res.errors[0]?.msg?.indexOf(
      'The password you entered does not meet policy restrictions'
    ) !== -1
  ) {
    yield put(
      notifyErrorAction(
        getApiErrorMessage([
          {
            code: '9',
            msg:
              'password pattern not following the requirements or password was used in the past',
            parameters: null
          }
        ])
      )
    );
    yield put(submitUpdatePasswordFailedAction());
  } else if (
    res.errors &&
    res.errors[0]?.msg?.indexOf(
      'Current and new passwords must be different'
    ) !== -1
  ) {
    yield put(
      notifyErrorAction(
        getApiErrorMessage([
          {
            code: '10',
            msg: 'current password and new password are same',
            parameters: null
          }
        ])
      )
    );
    yield put(submitUpdatePasswordFailedAction());
  } else {
    yield put(
      notifyErrorAction(
        getApiErrorMessage([
          {
            code: '-1',
            msg: '',
            parameters: null
          }
        ])
      )
    );
    yield put(submitUpdatePasswordFailedAction());
  }

  yield put(clearLoadingAction());
}

export function* updatePasswordSaga(): any {
  yield all([
    yield takeLatest(
      updatePasswordActionTypes.SUBMIT_UPDATE_PASSWORD_REQUESTED,
      submitUpdatePasswordSaga
    )
  ]);
}

export default updatePasswordSaga;
