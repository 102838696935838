import { isValidElement, useEffect, createRef } from 'react';
import { ReactSVG } from 'react-svg';
import classNames from 'classnames/bind';
import { isEqual } from 'lodash';

import RichText from 'components/richText/RichText';
import usePrevious from 'utils/hooks/usePrevious';

import styles from './Notification.module.scss';

const cx = classNames.bind(styles);

export const getNotificationStyles = (type: string) => {
  switch (type) {
    case 'success':
      return 'notificationSuccess';
    case 'info':
      return 'notificationInfo';
    case 'error':
      return 'notificationError';
    case 'warning':
    case 'pending':
      return 'notificationWarning';
    default:
      return 'notificationWarning';
  }
};

export const getIcon = (type: string) => {
  switch (type) {
    case 'success':
      return <ReactSVG src="/images/check-circle-solid.svg" />;
    case 'info':
      return <ReactSVG src="/images/info-blue.svg" />;
    case 'error':
      return <ReactSVG src="/images/exclamation-triangle-solid.svg" />;
    case 'warning':
      return <ReactSVG src="/images/exclamation-triangle-solid.svg" />;
    case 'pending':
      return <ReactSVG src="/images/clock-yellow.svg" />;
    default:
      return <ReactSVG src="/images/exclamation-triangle-solid.svg" />;
  }
};

export const getColor = (type: string) => {
  switch (type) {
    case 'success':
      return 'green';
    case 'info':
      return 'blue';
    case 'error':
      return 'red';
    case 'warning':
    case 'pending':
      return 'yellow';
    default:
      return 'yellow';
  }
};

export const getMessages = (messages: any[], type: string) =>
  messages.map((message, i) => (
    // eslint-disable-next-line react/no-array-index-key
    <div key={i} className={styles.notificationMsg}>
      {isValidElement(message) ? (
        message
      ) : (
        <RichText content={message} linkColor={getColor(type)} />
      )}
    </div>
  ));

interface NotificationProps {
  type?: string;
  messages?: any[];
  customIconPath?: string;
  noMargin?: boolean;
  marginBottom?: boolean;
  marginTop?: boolean;
  scrollTo?: boolean;
  submissionStatus?: string;
  inlineMini?: boolean;
}

const Notification = ({
  type = 'warning',
  messages = [],
  customIconPath = '',
  noMargin = false,
  marginBottom = false,
  marginTop = false,
  scrollTo = false,
  submissionStatus,
  inlineMini = false
}: NotificationProps) => {
  const notificationRef = createRef<HTMLDivElement>();
  const prevMessages = usePrevious(messages);
  const isError = submissionStatus === 'error';
  useEffect(() => {
    if (
      scrollTo &&
      notificationRef?.current?.scrollIntoView &&
      !isEqual(prevMessages, messages)
    ) {
      notificationRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end'
      });
    }
  }, [submissionStatus !== undefined ? isError : messages]);

  return (
    <div
      data-testid="notification-container"
      className={cx({
        notificationContainer: true,
        [styles[getNotificationStyles(type)]]: true,
        noMargin,
        marginBottom,
        marginTop,
        inlineMini
      })}
      ref={notificationRef}
    >
      <div className={styles.notificationIcon}>
        {!customIconPath && getIcon(type)}
        {customIconPath && <ReactSVG src={customIconPath} />}
      </div>
      <div className={styles.notificationMsgContainer}>
        {messages && getMessages(messages, type)}
      </div>
    </div>
  );
};

export default Notification;
