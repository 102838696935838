import { indicators } from 'utils/constants';

export enum batchProcessingTabIds {
  IMPORT_FILES = 'import-files',
  REVIEW_IMPORTS = 'review-imports',
  SUBMIT_BATCH = 'submit-batch',
  REVIEW_SUBMISSION = 'review-submission'
}

export enum fileNames {
  IMPORT_FILE_BUILDER = 'ImportFileBuilder'
}

export enum reviewActions {
  CANCEL = 'Cancel',
  REVIEW = 'Review errors'
}

export enum formTypes {
  FORM_102 = 'e-Form 102',
  FORM_119 = 'e-Form 119',
  FORM_165_A = 'e-Form 165a',
  FORM_119_RETRO = 'Retro payment to e-Form 119'
}

export enum reviewStatus {
  ERROR = 'Completed: with errors',
  COMPLETED = 'Completed: no errors',
  PENDING = 'Pending',
  FAILED = 'Failed',
  PROCESSING = 'Processing'
}

export enum FilingType {
  FORM_102 = '102',
  FORM_119 = '119',
  FORM_119_RETRO = 'RTR',
  FORM_165 = 'BRS'
}

export enum reviewErrorStatus {
  COMPLETED = 'completed',
  PENDING = 'pending',
  TO_REVIEW = 'to review'
}

export enum formId {
  FORM_119 = '119',
  FORM_165 = '165a',
  FORM_102 = '102'
}

export enum reviewSubmissionStatus {
  COMPLETED = 'Completed: no exceptions',
  COMPLETED_WITH_EXCEPTION = 'Completed: with exceptions',
  PENDING = 'Pending',
  PROCESSING = 'Processing',
  PROCESSING_IN_PROGRESS = 'Processing in progress...',
  FAILED = 'Failed'
}

export interface IReviewImports {
  action: reviewActions | null;
  batchDate: string;
  batchProcessNo: string;
  employerUserId: string;
  errorsNo: string;
  position: string;
  processedRecordsNo: string;
  reviewedInd: null | indicators;
  status: reviewStatus;
  statusCode: string;
  totalRecordsNo: string;
  type: formTypes;
  batchTime: string;
  isErrorsNumberExceeding2000: boolean;
}

export interface IReviewImportErrors {
  employeeId: string;
  errorSupportingData: any;
  fieldName: string;
  importError: any;
  itemtx: string;
  membershipNo: string;
  firstName: string;
  lastName: string;
  recordNo: string;
  recordType: string;
  recordsInError: string;
  reviewedInd: indicators | null;
  sin: string;
}

export interface uploadError {
  showError: boolean;
  message: string;
}

export interface ISubmittedBatchData {
  action: string | null;
  batchDate: string;
  batchTime: string;
  batchProcessNo: string;
  employerUserId: string;
  errorsNo: string;
  formCode: string;
  processedRecordsNo: string;
  status: string;
  statusCode: string;
  totalRecordsNo: string;
  type: string;
}

export interface IReviewedImports {
  batchProcessNo: string;
  isReviewed: boolean;
}

export interface IDataToBatchSubmit {
  type: formTypes | null;
  totalRecordsNo: string | null;
}
export interface ISearchCriteria {
  employeeId: string | null;
  employerNumber: number;
  employmentStatus: string | null;
  formMonth: string | null;
  formYear: string | null;
  fromDate: string | null;
  fromName: string | null;
  nra: string | null;
  owner: string | null;
  reason: string | null;
  reconciliationYear: string | null;
  requestType: formId;
  rowLimit: number | null;
  searchAscending: boolean;
  sin: string | null;
  sortByDeptId: string | null;
  sortByEmployeeId: string | null;
  sortByLastName: boolean;
  startIndex: number;
  status: number;
  toDate: string | null;
  toName: string | null;
}

export interface IUserSearchCriteria {
  dateImported: string;
  employmentStatus: string;
  importedBy: string;
  nameRangeA: string;
  nameRangeZ: string;
  normalRetirementAge: string;
}

export interface ISelectedBatchIds {
  [key: string]: string;
}

export interface IBatchIdSearchResults {
  requests: Array<BatchIdSearchRequests>;
}

export enum employmentStatus {
  CFT = 'C',
  OTCFT = 'O',
  ALL = 'All'
}

export enum normalRetirementAge {
  AGE65 = '65',
  AGE60 = '60',
  ALL = 'All'
}

export enum numberOfDays {
  ALL_TIME = 'All Time',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_6_MONTHS = 'Last 6 months',
  LAST_12_MONTHS = 'Last 12 months'
}

export enum importedBy {
  ALL_USERS = 'All'
}

export interface IBatchFilter {
  dateImported: string;
  employmentStatus: string;
  importedBy: string;
  nameRangeA: string;
  nameRangeZ: string;
  normalRetirementAge: string;
}

export interface BatchIdSearchRequests {
  additionalProcNeededInd: string | null;
  attachments: any;
  commentText: string | null;
  confirmationText: string | null;
  createdDates: Array<string>;
  creationDate: string | null;
  dirty: boolean;
  employerAmount: null;
  employerGroupName: null;
  employerGroupNo: null;
  employerNo: null;
  formReceivedDate: null;
  hashMap: null;
  machineId: null;
  memberAmount: null;
  memberDeptId: string;
  memberEmployeeId: string;
  memberFirstName: string;
  memberLastName: string;
  memberPurchaseAmount: null;
  memberPurchaseNumber: null;
  memberPurchaseType: null;
  memberSelected: string;
  memberSin: string;
  membershipForm143Info: any;
  membershipNo: null;
  notes: null;
  omersFormId: string;
  omersFormType: string;
  originalRequestNo: null;
  reconciliationYear: string;
  requestNo: string;
  sessionId: null;
  status: string;
  statusDate: null;
  suppPlanInd: null;
  userId: string;
}
