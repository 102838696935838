import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'types';
import { ExceptionsObject } from 'interfaces';
import useProperties from 'utils/hooks/useProperties';
import Notification from 'components/notification/Notification';
import ContainerBorder from 'components/containerBorder/ContainerBorder';
import Table from 'components/table/Table';
import Divider from 'components/divider/Divider';
import { getOverrideNotificationMessage } from 'components/override/Override';
import useClearOverrideExceptions from 'containers/requestForm/common/hooks/useClearOverrideExceptions';
import { openNewMessageWithPresetDataAction } from 'containers/messages/messagesActions';
import Button from 'components/button/Button';
import { overrideForm119ExceptionsAction } from '../form119Actions';
import { Form119State } from '../form119Reducer';
import styles from './AnnualReportingReview.module.scss';

const AnnualReportingReview = ({
  summaryTableHeading,
  summaryTableData
}: any) => {
  const { t } = useTranslation(['form119', 'common']);
  const dispatch = useDispatch();

  const form119State: Form119State = useSelector(
    (state: RootState) => state.form119
  );
  const { form119Data, overrideExceptions, memberInfo } = form119State;

  const {
    e119Model: { employeeModel, exceptions }
  } = form119Data;

  const memoizedProps = useProperties([['overrideReasons', 'Override Reason']]);

  useClearOverrideExceptions(overrideForm119ExceptionsAction([]));

  const has11998Or11999Warnings =
    exceptions?.filter(x => x.messageid === '11998' || x.messageid === '11999')
      .length > 0 ? (
      <Notification
        type="info"
        messages={[
          <>
            <span className="semiBold">
              {t('ANNUAL_REPORTING_WARNING_11999_OR_11998_1')}
            </span>
            <span>
              {t('ANNUAL_REPORTING_WARNING_11999_OR_11998_2', {
                name: `${memberInfo?.memberFirstName} ${memberInfo?.memberLastName}`
              })}
            </span>
            <Button
              baseStyle="link"
              disableSvgStroke
              onClick={() =>
                dispatch(
                  openNewMessageWithPresetDataAction({
                    topic: '119',
                    linkedMember: memberInfo,
                    hasAttachment: true,
                    attachmentCategory: 'Annual Reporting',
                    attachmentDocumentType: 'Pay Period Breakdown'
                  })
                )
              }
            >
              <span>{t('ANNUAL_REPORTING_WARNING_11999_OR_11998_3')}</span>
            </Button>
            <span>{t('ANNUAL_REPORTING_WARNING_11999_OR_11998_4')}</span>
          </>
        ]}
      />
    ) : null;

  return (
    <div className={styles.annualReportingReview}>
      {has11998Or11999Warnings}
      {exceptions &&
        exceptions.map((exception: ExceptionsObject) => (
          <div className={styles.notifications} key={exception.itemtx}>
            <Notification
              type={exception.exception.toLowerCase()}
              messages={getOverrideNotificationMessage(
                exception,
                overrideExceptions,
                memoizedProps.overrideReasons,
                e => dispatch(overrideForm119ExceptionsAction(e)),
                true
              )}
            />
          </div>
        ))}
      <ContainerBorder>
        <h2>
          {t(
            'REQUEST_FORM_ANNUAL_REPORTING_REVIEW_HEADING_EMPLOYEE_INFORMATION'
          )}
        </h2>
        <div className={styles.gridWrapper}>
          <div>
            <div className={styles.twoColumns}>
              <span className={styles.label}>
                {t('REQUEST_FORM_ANNUAL_REPORTING_REVIEW_LABEL_EMPLOYEE_ID')}
              </span>{' '}
              {employeeModel.employeeId ? (
                <div>{employeeModel.employeeId}</div>
              ) : (
                <div className={styles.notAvailable}>
                  {t('common:NOT_AVAILABLE')}
                </div>
              )}
            </div>
            <div className={styles.twoColumns}>
              <span className={styles.label}>
                {t('REQUEST_FORM_ANNUAL_REPORTING_REVIEW_LABEL_DEPARTMENT_ID')}
              </span>
              {employeeModel.deptId ? (
                <div>{employeeModel.deptId}</div>
              ) : (
                <div className={styles.notAvailable}>
                  {t('common:NOT_AVAILABLE')}
                </div>
              )}
            </div>
            <div className={styles.twoColumns}>
              <span className={styles.label}>
                {t('REQUEST_FORM_ANNUAL_REPORTING_REVIEW_LABEL_EMAIL')}
              </span>
              {employeeModel.email && !form119Data.e119Model.originalEmail ? (
                <div>{employeeModel.email}</div>
              ) : (
                <div className={styles.notAvailable}>
                  {t('common:NOT_AVAILABLE')}
                </div>
              )}
            </div>
          </div>
        </div>

        <Divider />

        <h2>
          {t(
            'REQUEST_FORM_ANNUAL_REPORTING_REVIEW_HEADING_FINANCIAL_INFORMATION'
          )}
        </h2>
        <Table
          withColoredHeaderRow
          headerColor="blue"
          withTableBorder
          boldFirstColumn
          withRowBorders
          withColumnBorders
          tableHeading={summaryTableHeading}
          tableData={summaryTableData}
          withEvenWidthColumns
        />
      </ContainerBorder>
    </div>
  );
};

export default AnnualReportingReview;
