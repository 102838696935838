import {
  clearLoadingAction,
  startLoadingAction
} from 'containers/auth/userSessionActions';
import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import {
  getExtractDataType,
  getExtractStatus,
  setExtract,
  downloadCsv,
  cancelExtract
} from 'services/dataExtract';
import { downloadFile, getApiErrorMessage } from 'utils';
import { EapAction } from 'types';
import { responseTypes } from 'types/response-type';
import { safeAuthEffect } from 'store/sagas/sagaUtils';

import {
  dataExtractActionTypes,
  setDataExtractType,
  setDataExtractStatus,
  setExtractDataRes,
  setExtractDataResError,
  getExtractStatusAction
} from './dataExtractActions';

export function* getDataExtractTypeSaga(): any {
  const employerGroupNo = yield select(
    state => state.selectEmployers?.selectGroupNumber
  );
  const res = yield call(safeAuthEffect, getExtractDataType, employerGroupNo);

  if (res?.data) {
    yield put(setDataExtractType(res.data?.MSSEExtractType));
  }
}

export function* setExtractData(): any {
  const employerNo = yield select(
    state => state.selectEmployers?.selectEmployerNumber
  );
  const extractType = yield select(
    state => state.dataExtract?.chosenExtractDataType
  );
  const year = yield select(state => state.dataExtract?.chosenExtractDataYear);

  const data = {
    employerNo,
    extractType,
    year
  };
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, setExtract, data);

  if (res?.data?.status === 'initiated') {
    yield put(setExtractDataRes(res.data?.status));
  } else {
    yield put(setExtractDataResError(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

export function* getDataExtractStatus(): any {
  const employerGroupNo = yield select(
    state => state.selectEmployers?.selectGroupNumber
  );
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getExtractStatus, employerGroupNo);
  if (res?.data) {
    yield put(setDataExtractStatus(res.data?.MSSANRStatusModel));
  }
  yield put(clearLoadingAction());
}

export function* downloadCSVSaga(action: EapAction): any {
  const res = yield call(safeAuthEffect, downloadCsv, action.data);
  if (res?.type === responseTypes.OCTET_STREAM) {
    downloadFile(res, `data-extract-${action.data}`, 'csv');
  }
}

export function* cancelExtractSaga(action: EapAction): any {
  const res = yield call(safeAuthEffect, cancelExtract, action.data);
  if (res.status === 200 || res.status === 204) {
    yield put(getExtractStatusAction());
  }
}

export function* dataExtractSaga(): any {
  yield all([
    yield takeLatest(
      dataExtractActionTypes.GET_DATA_EXTRACT_TYPE,
      getDataExtractTypeSaga
    ),
    yield takeLatest(
      dataExtractActionTypes.GET_DATA_EXTRACT_STATUS,
      getDataExtractStatus
    ),
    yield takeLatest(dataExtractActionTypes.SET_EXTRACT_DATA, setExtractData),
    yield takeLatest(dataExtractActionTypes.DOWNLOAD_CSV, downloadCSVSaga),
    yield takeLatest(dataExtractActionTypes.CANCEL_EXTRACT, cancelExtractSaga)
  ]);
}
