import { all, takeLatest, put, call } from 'redux-saga/effects';

import { EapAction } from 'types';

import { getApiErrorMessage } from 'utils';

import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';

import {
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';

import { resetPassword } from 'services';
import {
  resetPasswordActionTypes,
  submitResetPasswordSuccessAction,
  submitResetPasswordFailedAction
} from './resetPasswordActions';

export function* submitResetPasswordSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(resetPassword, action.data);

  if (res.data) {
    yield put(clearNotifications());
    yield put(submitResetPasswordSuccessAction());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(submitResetPasswordFailedAction());
  }

  yield put(clearLoadingAction());
}

export function* resetPasswordSaga(): any {
  yield all([
    yield takeLatest(
      resetPasswordActionTypes.SUBMIT_RESET_PASSWORD_REQUESTED,
      submitResetPasswordSaga
    )
  ]);
}

export default resetPasswordSaga;
