import { EapAction } from 'types';
import { FeatureInterface } from 'unleash-client/lib/feature';
import { featureFlagsActionTypes } from './featureFlagsActions';

export interface FeatureFlagsState {
  status: FeatureFlagStatus;
  featureFlags: FeatureInterface[];
}

export const initialState: FeatureFlagsState = {
  status: 'loading',
  featureFlags: []
};

// no idle state as feature flags should be fetched on app load
export type FeatureFlagStatus = 'loading' | 'success' | 'error';

const featureFlags = (
  state: FeatureFlagsState = initialState,
  action: EapAction
): FeatureFlagsState => {
  const { type, data } = action;
  switch (type) {
    case featureFlagsActionTypes.FEATURE_FLAGS_SUCCEEDED:
      return {
        ...state,
        status: 'success',
        featureFlags: data
      };
    case featureFlagsActionTypes.FEATURE_FLAGS_FAILED:
      return {
        ...state,
        status: 'error',
        featureFlags: []
      };
    default:
      return state;
  }
};

export default featureFlags;
