import { put, call, take, race, delay } from 'redux-saga/effects';
import { pollingActionTypes } from 'store/polling/pollingActions';

/* Worker Function */
/**
 * Executes saga polling for an interval
 * @param data.tryCallBackFunction function to call inside try block
 * @param data.catchCallBackFunction function to call inside catch block
 * @param data.pollInterval poll interval in milliseconds
 */
function* pollTask(data: {
  tryCallBackFunction: () => void;
  catchCallBackFunction: () => void;
  pollInterval: number;
}) {
  while (true) {
    try {
      yield call(data.tryCallBackFunction);
      yield delay(data.pollInterval);
    } catch (err) {
      yield call(data.catchCallBackFunction);
      // Stops the poll by default if there is an error
      yield put({ type: pollingActionTypes.POLL_STOP, err });
    }
  }
}

/* Watcher Function */
export function* pollTaskWatcher(): any {
  while (true) {
    const action = yield take(pollingActionTypes.POLL_START);
    yield race([
      call(pollTask, action.data),
      take(pollingActionTypes.POLL_STOP)
    ]);
  }
}
