import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getAnrDashboardInfo = (employerGroupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/dashboard/anr/${employerGroupNo}`
  );

export const getAnrDashboardNotSubmittedInfo = (employerGroupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/dashboard/notsubmitted-forms/${employerGroupNo}`
  );

export const refreshAnrDashboard = (employerGroupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/dashboard/refresh/${employerGroupNo}`
  );

export const getAnrDashboardRequestSummary = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/dashboard/anr/summary/${requestNumber}`,
    undefined,
    requestNumber,
    'blob'
  );

export const getAnrDashboardRequestReport = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/dashboard/anr/report/${requestNumber}`,
    undefined,
    requestNumber,
    'blob'
  );

export const getAnrDashboardRequestExtra = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/dashboard/anr/extract/${requestNumber}`,
    undefined,
    requestNumber
  );
