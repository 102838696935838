import { all, put, call, select, takeLeading } from 'redux-saga/effects';
import { getBusinessConfig } from 'services';
import clientSide from 'utils/logger/client-side';
import { DonnaApiResponse } from '@omers-packages/next-donna-gateway-handler';
import { UserSessionState } from 'containers/auth/userSessionReducer';
import { overrideReasonsActionTypes } from 'containers/overrideReasons/overrideReasonsActions';
import {
  getDonnaBusinessConfig,
  getDonnaBusinessConfigSucceeded
} from './donnaBusinessConfigReducer';
import { DonnaBusinessConfig, DonnaBusinessConfigNames } from './types';

export function* getDonnaBusinessConfigSaga() {
  const { userData }: UserSessionState = yield select(
    state => state.userSession
  );

  try {
    const resTermination: DonnaApiResponse<DonnaBusinessConfig> = yield call(
      getBusinessConfig,
      DonnaBusinessConfigNames.TERMINATION,
      userData.omersUser
    );

    if (!resTermination.result?.data) {
      throw new Error(
        `Failed to fetch donna ${DonnaBusinessConfigNames.TERMINATION} business config`
      );
    }

    const resRetirement: DonnaApiResponse<DonnaBusinessConfig> = yield call(
      getBusinessConfig,
      DonnaBusinessConfigNames.RETIREMENT,
      userData.omersUser
    );

    if (!resRetirement.result?.data) {
      throw new Error(
        `Failed to fetch donna ${DonnaBusinessConfigNames.RETIREMENT} business config`
      );
    }

    yield put(
      getDonnaBusinessConfigSucceeded({
        configTermination: resTermination.result.data,
        configRetirement: resRetirement.result.data
      })
    );

    if (resTermination.result.data.configValue.value) {
      // auto-fetch termination overrides if business config is enabled
      yield put({
        type: overrideReasonsActionTypes.OVERRIDE_REASONS_REQUESTED
      });
    }
  } catch (e) {
    clientSide.error((e as Error).message, {}, e as Error);
  }
}

export function* donnaBusinessConfigSaga() {
  yield all([
    takeLeading(getDonnaBusinessConfig.type, getDonnaBusinessConfigSaga)
  ]);
}
