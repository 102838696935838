import { all, takeLatest, put, call } from 'redux-saga/effects';
import Router from 'next/router';

import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { memberSearch, getMemberDetails } from 'services';
import { getApiErrorMessage } from 'utils';
import { EapAction } from 'types';
import {
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import {
  clearLoadingAction,
  startLoadingAction
} from 'containers/auth/userSessionActions';
import {
  memberSearchActionTypes,
  memberSearchSuccess,
  memberSearchFailed,
  memberProfileValid,
  resetValidMember,
  memberProfileInvalid
} from './memberSearchActions';

export function* searchSaga(action: EapAction): any {
  const res = yield call(safeAuthEffect, memberSearch, action.data);
  yield put(resetValidMember());

  if (res.data) {
    yield put(memberSearchSuccess({ type: action.data.type, data: res.data }));
    yield put(clearNotifications());
  } else {
    yield put(memberSearchFailed({ type: action.data.type, data: res.data }));
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* postSearchSaga(action: EapAction) {
  if (
    action.data.type === 'profile' &&
    Router.router?.pathname !== '/members'
  ) {
    yield call(Router.push, '/members');
  }
}

export function* checkMemberValiditySaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, getMemberDetails, {
    membershipNumber: action.data.membershipNumber,
    employmentNumber: action.data.mssemploymentItem?.employmentNumber,
    employerNumber:
      action.data.mssemploymentItem?.theMSSEmployer?.employerNumber,
    ctx: 'MIS'
  });
  yield put(resetValidMember());

  if (res.data) {
    yield put(
      memberProfileValid({
        member: action.data,
        warningCode: res.data?.memberEmploymentInfo?.warningCode
      })
    );
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(memberProfileInvalid());
  }

  yield put(clearLoadingAction());
}

export function* memberSearchSaga(): any {
  yield all([
    yield takeLatest(
      memberSearchActionTypes.MEMBER_SEARCH_REQUESTED,
      searchSaga
    ),
    yield takeLatest(
      memberSearchActionTypes.MEMBER_SEARCH_FAILED,
      postSearchSaga
    ),
    yield takeLatest(
      memberSearchActionTypes.MEMBER_SEARCH_SUCCEEDED,
      postSearchSaga
    ),
    yield takeLatest(
      memberSearchActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_REQUESTED,
      checkMemberValiditySaga
    )
  ]);
}
