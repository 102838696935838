import { useMemo } from 'react';
import useBreakpointHook, { Breakpoint } from 'use-breakpoint';
import mediaQuery from 'css-mediaquery';
import { BREAKPOINTS } from 'styles/theme';

export const createMockMatchMedia = (width: number) => (query: any) => ({
  matches: mediaQuery.match(query, { width }),
  media: '',
  addListener: () => {},
  removeListener: () => {},
  onchange: () => {},
  addEventListener: () => {},
  removeEventListener: () => {},
  dispatchEvent: () => true
});

const getEnhancedBreakpoint = (breakpoint: Breakpoint<typeof BREAKPOINTS>) => ({
  ...breakpoint,
  isXLarge: breakpoint.breakpoint === 'xlarge',
  isLarge: breakpoint.breakpoint === 'large',
  isMedium: breakpoint.breakpoint === 'medium',
  isSmall: breakpoint.breakpoint === 'small',
  isXSmall: breakpoint.breakpoint === 'xsmall',
  isLessThanXLargeMin: breakpoint.minWidth < BREAKPOINTS.xlarge,
  isBetweenMediumAndXLarge:
    breakpoint.minWidth < BREAKPOINTS.xlarge &&
    breakpoint.minWidth >= BREAKPOINTS.medium,
  isBetweenLargeAndXLarge:
    breakpoint.minWidth < BREAKPOINTS.xlarge &&
    breakpoint.minWidth >= BREAKPOINTS.large,
  isLessThanLargeMin: breakpoint.minWidth < BREAKPOINTS.large,
  isBetweenMediumAndLarge:
    breakpoint.minWidth < BREAKPOINTS.large &&
    breakpoint.minWidth >= BREAKPOINTS.medium,
  isBetweenSmallAndLarge:
    breakpoint.minWidth <= BREAKPOINTS.large &&
    breakpoint.minWidth > BREAKPOINTS.small,
  isBetweenSmallAndXLarge:
    breakpoint.minWidth <= BREAKPOINTS.xlarge &&
    breakpoint.minWidth > BREAKPOINTS.small,
  isLessThanMediumMin: breakpoint.minWidth < BREAKPOINTS.medium,
  isLessThanSMediumMin: breakpoint.minWidth < BREAKPOINTS.smedium,
  isBetweenSmallAndMedium:
    breakpoint.minWidth < BREAKPOINTS.medium &&
    breakpoint.minWidth >= BREAKPOINTS.small,
  isLessThanSmallMin: breakpoint.minWidth < BREAKPOINTS.small,
  isLessThanXSmallMin: false
});

/**
 * Hook provides active breakpoints and helper metadata for different ranges
 *
 * <-- xsmall | <-- small --> | <-- medium --> | <-- large --> | xlarge -->
 *           768             1111             1536            1920
 */
const useBreakpoint = (
  defaultBreakpoint: keyof typeof BREAKPOINTS = 'xlarge'
) => {
  const breakpoint = useBreakpointHook(BREAKPOINTS, defaultBreakpoint);

  return useMemo(() => getEnhancedBreakpoint(breakpoint), [breakpoint]);
};

export default useBreakpoint;
