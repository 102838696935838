import {
  all,
  put,
  call,
  takeLatest,
  takeLeading,
  select,
  delay
} from 'redux-saga/effects';
import { EapAction, RootState } from 'types';
import Router from 'next/router';
import { format } from 'url';

import { isEnabled, currentFlags } from 'features/featureFlags/featureFlags';
import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import {
  getApiErrorMessage,
  downloadPdf,
  viewPdf,
  featureFlagSelector,
  getErrorMessageWithParams
} from 'utils';
import {
  checkMemberContext,
  createForm143,
  getBankingInfo,
  postAdvanceElection,
  getForm143,
  saveForm143,
  memberSearch,
  calculatePA,
  getPDF143,
  getAdvanceElection,
  deleteAdvanceElection,
  submitForm143,
  getPDF,
  setRetro,
  deleteRetro,
  getMemberDetails,
  getRetro,
  EaccessAPIError
} from 'services';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import {
  lockFormSaga,
  unlockFormSaga,
  clearLockedFormNotificationSaga,
  deleteFormSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  clearLockedFormNotificationAction,
  notifyLockedFormErrorAction,
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import { responseTypes } from 'types/response-type';
import _, { first } from 'lodash';
import clientSide from 'utils/logger/client-side';
import {
  form143ActionTypes,
  checkMemberContextSucceededAction,
  resetMemberContextAction,
  retryCreateForm143Action,
  carryOverUpdateAction,
  resetFinancialInfo,
  updateFinancialInfo,
  calculatePAActionSuccess,
  getLatest143Action,
  getLatest143SuccessAction,
  getLatestForm143FailureAction,
  bankingInfoSuccededAction,
  setBankingPdfAction,
  bankingInfoNeededAction,
  showModalAction,
  getForm143SuccessAction,
  saveForm143SuccessAction,
  submitForm143Action,
  saveForm143FailedAction,
  clearForm143StatusAction,
  setForm143StepAction,
  setForm143SubmitStatusAction,
  submitForm143SuccessAction,
  unlockForm143Action,
  deleteForm143SuccessAction,
  deleteForm143FailedAction,
  resetValidMember,
  memberProfileValid,
  memberProfileInvalid,
  createForm143Action,
  getRetroPay143SuccessAction,
  getRetroPay143Action,
  deleteRetroPay143Action,
  updateForm143LastDateWorkedAction
} from './form143Actions';

const legacyErrorModalPaths = ['/members/profile', '/form/143'];
const shouldUseLegacyErrorModal = () =>
  legacyErrorModalPaths.some(path => Router.pathname.startsWith(path));

// This Saga POSTs to the requests/e143 API with a partial payload in order to
// check a member's eligibility for starting a 143 form
export function* checkMemberContextSaga(action: EapAction): any {
  yield put(startLoadingAction());

  let res;
  try {
    res = yield call(safeAuthEffect, checkMemberContext, action.data);
  } catch (error) {
    clientSide.error(
      'There was an error checking the member context',
      {},
      error as Error
    );
    yield put(createFormFailedAction());
    yield put(clearLoadingAction());
    return;
  }

  if (res.data?.memberContextValid) {
    yield put(
      checkMemberContextSucceededAction({
        validMemberContext: res.data?.memberContextValid,
        validMemberProfile: action.data.validMemberProfile,
        prevTotalDisabilityDate: res.data.prevTotalDisabilityDate
      })
    );
  } else {
    const error: EaccessAPIError | undefined = first(res?.errors);

    if (error && !shouldUseLegacyErrorModal()) {
      const message = getErrorMessageWithParams(error);
      yield call(Router.push, {
        query: {
          ...Router.query,
          errorId: error?.errorId,
          msg: message
        }
      });
    }

    if (error && shouldUseLegacyErrorModal()) {
      yield put(createFormFailedAction(error.code));
    }
  }
  yield put(clearLoadingAction());
}

export function* createForm143Saga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, createForm143, action.data);
  const requestNumber = res.data?.requestNumber || res.data?.requestNo;

  if (requestNumber) {
    yield Router.push(
      '/form/143/[id]',
      format({ pathname: `/form/143/${requestNumber}` })
    );
    yield put(resetMemberContextAction());
    yield put(clearLoadingAction());
  } else if (
    res.status === 400 &&
    res.errors.find((error: any) => error.code === '11245')
  ) {
    yield put(
      retryCreateForm143Action({
        ...action.data,
        employmentModel: {
          ...action.data.employmentModel,
          eventType: 'R'
        }
      })
    );
    yield put(clearLoadingAction());
  } else {
    const error: EaccessAPIError | undefined = first(res?.errors);

    if (error && shouldUseLegacyErrorModal()) {
      yield put(createFormFailedAction(error.code));
    }

    if (error && !shouldUseLegacyErrorModal()) {
      const message = getErrorMessageWithParams(error);
      Router.push({
        query: {
          ...Router.query,
          errorId: error?.errorId,
          msg: message
        }
      });
    }

    yield put(resetMemberContextAction());
    yield put(clearLoadingAction());
  }
}

// When creating a new e143 form we first try to create a Termination form.
// If creating a Termination form fails, we retry creation as a Retirement form,
// this avoids the need to calculate which form should be initiated on the front-end
// since that logic exists on the API side already
export function* retryCreateForm143Saga(action: EapAction): any {
  const res = yield call(safeAuthEffect, createForm143, action.data);
  const requestNumber = res.data?.requestNumber || res.data?.requestNo;

  if (requestNumber) {
    yield Router.push(
      '/form/143/[id]',
      format({ pathname: `/form/143/${requestNumber}` })
    );
    yield put(resetMemberContextAction());
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
    yield put(resetMemberContextAction());
  }
  yield put(clearLoadingAction());
}

export function* getForm143Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const featureFlags = yield select(featureFlagSelector);
  const { requestNumber, lockForm } = action.data;

  if (lockForm) {
    yield call(lockFormSaga, { ...action, data: requestNumber });
  }

  const formRes = yield call(safeAuthEffect, getForm143, requestNumber);

  if (
    (formRes?.data?.e143Model?.employmentModel?.eventType === 'V' &&
      !isEnabled(featureFlags.featureFlags, currentFlags.FORM_143_DEATH)) ||
    (formRes?.data?.e143Model?.employmentModel?.eventType === 'D' &&
      !isEnabled(
        featureFlags.featureFlags,
        currentFlags.FORM_143_DISABILITY
      )) ||
    ((formRes?.data?.e143Model?.employmentModel?.eventType === 'R' ||
      formRes?.data?.e143Model?.employmentModel?.eventType === 'T') &&
      !isEnabled(featureFlags.featureFlags, currentFlags.FORM_143))
  ) {
    yield call(unlockFormSaga, action);
    yield put(clearLoadingAction());
    yield call(Router.push, '/requests');
    return;
  }

  const advancedElection = yield call(
    safeAuthEffect,
    getAdvanceElection,
    requestNumber
  );

  const memberSearchRes = yield call(safeAuthEffect, memberSearch, {
    omersMembershipNumber: formRes?.data?.e143Model?.membershipNo,
    employerNumber: formRes?.data?.e143Model?.employerNo
  });

  if (formRes.data) {
    yield put(
      getForm143SuccessAction({
        requestNumber,
        e143Model: formRes.data.e143Model,
        memberInfo:
          memberSearchRes?.data?.memberSearchResult?.memberships[0] ?? null,
        advanceElectionModel: advancedElection?.data?.advanceElectionModel
      })
    );
    yield put(clearLockedFormNotificationAction());
    if (Router.route !== '/form/143/[id]') {
      yield Router.push(
        '/form/143/[id]',
        format({ pathname: `/form/143/${requestNumber}` })
      );
    }
  } else {
    yield put({
      type: form143ActionTypes.GET_FORM_143_FAILED
    });
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(formRes?.errors)));

    // navigate off the request if user is already there
    if (Router.route === '/form/143/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());
  yield put(clearForm143StatusAction());
  // clear potential locked form notification after delay
  yield call(clearLockedFormNotificationSaga);
}

export function* fetchLatest143Saga(action: EapAction): any {
  if (!action.data.disableSpinner) {
    yield put(startLoadingAction());
  }

  const formRes = yield call(
    safeAuthEffect,
    getForm143,
    action.data.requestNumber
  );
  const advancedElection = yield call(
    safeAuthEffect,
    getAdvanceElection,
    action.data.requestNumber
  );
  const employmentModel = yield select(
    (state: RootState) => state.form143.employmentModel
  );
  const {
    dateLastWorked,
    lastDateRegEarnings,
    reasonForDifference,
    reasonForDifferenceOther,
    reasonForAdjustment
  } = employmentModel;
  if (formRes.data) {
    if (action.data.updateFinancialInfoOnly) {
      yield put(
        updateFinancialInfo({
          ...formRes.data,
          advanceElectionModel: advancedElection?.data?.advanceElectionModel
        })
      );
      if (reasonForAdjustment) {
        yield put(
          updateForm143LastDateWorkedAction({
            dateLastWorked,
            lastDateRegEarnings,
            reasonForDifference,
            reasonForDifferenceOther: reasonForDifferenceOther ?? ''
          })
        );
      }
    } else {
      yield put(
        getLatest143SuccessAction({
          ...formRes.data,
          advanceElectionModel: advancedElection?.data?.advanceElectionModel,
          dirtyStateSave: action?.data?.dirtyStateSave
        })
      );
    }
  } else {
    yield put(getLatestForm143FailureAction());
    yield put(notifyErrorAction(getApiErrorMessage(formRes?.errors)));
  }

  if (!action.data.disableSpinner) {
    yield put(clearLoadingAction());
  }
}

export function* setCarryOverSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const { requestNumber } = yield select((state: RootState) => state.form143);

  const res = yield call(
    safeAuthEffect,
    saveForm143,
    {
      ...action.data.payload,
      employmentModel: {
        ...action.data.payload.employmentModel,
        postEventInd: action.data.poseEventInd ? 'Y' : 'N'
      }
    },
    requestNumber
  );

  if (res.data) {
    yield put(carryOverUpdateAction(res.data));
  }
}

export function* getCarryOverSaga(): any {
  const { requestNumber } = yield select((state: RootState) => state.form143);
  const res = yield call(safeAuthEffect, getForm143, requestNumber);
  const advancedElection = yield call(
    safeAuthEffect,
    getAdvanceElection,
    requestNumber
  );

  if (res.data) {
    yield put(
      updateFinancialInfo({
        ...res.data,
        advanceElectionModel: advancedElection?.data?.advanceElectionModel
      })
    );
  }
  yield put(clearLoadingAction());
}

export function* calculatePASaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    calculatePA,
    action.data.data,
    action.data.requestNumber,
    action.data.servicePeriodNumber
  );
  yield put(resetFinancialInfo());

  if (res.data) {
    yield put(
      calculatePAActionSuccess({
        pensionAdjustment: res.data.pension_adjustment_amt,
        servicePeriodNumber: action.data.servicePeriodNumber
      })
    );
    yield put(
      getLatest143Action({
        requestNumber: action.data.requestNumber,
        updateFinancialInfoOnly: true
      })
    );
  } else {
    yield put(
      getLatest143Action({
        requestNumber: action.data.requestNumber,
        updateFinancialInfoOnly: true
      })
    );
  }
}

export function* setBankingInformationSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form143);
  const {
    institutionNumber,
    transitNumber,
    bankAccountNumber,
    bankAddress,
    bankName
  } = action.data;
  const res = yield call(
    safeAuthEffect,
    getBankingInfo,
    institutionNumber,
    transitNumber
  );
  const formRes = yield call(safeAuthEffect, getForm143, requestNumber);
  if (
    res?.data?.bankingInfoModel?.bankName &&
    res?.data?.bankingInfoModel?.bankAddress1
  ) {
    const ret = yield call(safeAuthEffect, postAdvanceElection, requestNumber, {
      ...res.data.bankingInfoModel,
      bankAccountNumber
    });
    yield put(
      bankingInfoSuccededAction({
        ...formRes.data,
        advanceElectionModel: {
          ...res.data.bankingInfoModel,
          bankAccountNumber
        }
      })
    );
    yield put(setBankingPdfAction(ret?.data?.attachments));
    yield put(bankingInfoNeededAction(false));
    yield put(showModalAction(false));
  } else if (bankAddress && bankName) {
    const ret = yield call(safeAuthEffect, postAdvanceElection, requestNumber, {
      ...res.data.bankingInfoModel,
      bankAccountNumber,
      bankAddress1: bankAddress ?? null,
      bankName: bankName ?? null
    });
    yield put(
      bankingInfoSuccededAction({
        ...formRes.data,
        advanceElectionModel: {
          ...res.data.bankingInfoModel,
          bankAccountNumber,
          bankAddress1: bankAddress ?? null,
          bankName: bankName ?? null
        }
      })
    );
    yield put(setBankingPdfAction(ret?.data?.attachments));
    yield put(bankingInfoNeededAction(false));
    yield put(showModalAction(false));
  } else {
    yield put(bankingInfoNeededAction(true));
  }
}

export function* getBankingPdfSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form143);
  const { formNo, oracleReportInd } = action.data[0];
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    getPDF143,
    requestNumber,
    formNo,
    oracleReportInd
  );
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    downloadPdf(res, '143: Retirement');
    yield put({
      type: form143ActionTypes.GET_FORM_143_PDF_SUCCEEDED,
      data: res.data
    });
    yield put(clearNotifications());
  } else {
    yield put({
      type: form143ActionTypes.GET_FORM_143_PDF_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* deleteAdvanceElectionSaga(): any {
  const { requestNumber } = yield select((state: RootState) => state.form143);

  yield call(safeAuthEffect, deleteAdvanceElection, requestNumber);
}

export function* saveForm143Saga(action: EapAction): any {
  if (_.isEmpty(action.data.deleteData)) {
    yield put(startLoadingAction());
  }
  const { requestNumber } = yield select((state: RootState) => state.form143);
  let res;
  try {
    res = yield call(safeAuthEffect, saveForm143, action.data, requestNumber);
  } catch (e) {
    clientSide.error(
      `There was an error submitting eform 143 request #${requestNumber}`,
      {},
      e as Error
    );
    if (!action.data.submitForm) {
      yield put(clearLoadingAction());
    }
    yield put(saveForm143FailedAction());
    return;
  }

  if (res.data) {
    if (_.isEmpty(action.data.deleteData)) {
      yield put(
        getLatest143Action({
          requestNumber,
          disableSpinner: true,
          updateFinancialInfoOnly: action.data.updateFinancialInfoOnly ?? false,
          dirtyStateSave: action.data.dirtyStateSave
        })
      );
    } else {
      yield put(deleteRetroPay143Action({ ...action.data.deleteData }));
    }
    if (!action.data.updateFinancialInfoOnly) {
      yield put(saveForm143SuccessAction());
    }
    if (action.data.reviewForm) {
      yield put(setForm143StepAction(2));
    }

    if (action.data.submitForm) {
      yield put(submitForm143Action());
    }

    // unlock form on modal dialog exit
    if (action.data.lockForm) {
      yield put(unlockForm143Action());
    }
  } else {
    yield put(saveForm143FailedAction());
  }

  if (!action.data.submitForm) {
    yield put(clearLoadingAction());
  }
}

export function* submitForm143Saga(): any {
  yield put(setForm143SubmitStatusAction('submitting'));
  const { requestNumber } = yield select((state: RootState) => state.form143);
  let res;

  try {
    yield put(startLoadingAction());
    res = yield call(safeAuthEffect, submitForm143, requestNumber);
  } catch (e) {
    yield put(notifyErrorAction(getApiErrorMessage([])));
    clientSide.error(
      'There was an error submitting the form 143',
      {},
      e as Error
    );
    yield put(setForm143SubmitStatusAction('error'));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm143SubmitStatusAction(''));

    return;
  }

  if (res?.data?.submitResponse) {
    yield put(clearNotifications());
    yield put(submitForm143SuccessAction(res.data.submitResponse));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm143SubmitStatusAction(''));
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(setForm143SubmitStatusAction('error'));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm143SubmitStatusAction(''));
  }
}

export function* clearForm143StatusSaga() {
  yield delay(3000);
  yield put(clearForm143StatusAction());
}

export function* unlockForm143Saga(action: EapAction) {
  const { submissionComplete } = yield select(
    (state: RootState) => state.form143
  );

  // prevent unnecessary unlock if form successfully submitted
  if (!submissionComplete) {
    yield call(unlockFormSaga, action);
  }
}

export function* getPDF143Saga(action: EapAction): any {
  const { isView, fileName } = action.data;
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getPDF, action.data);
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    if (isView) {
      viewPdf(res, fileName);
    } else {
      downloadPdf(res, fileName);
    }

    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* saveRetroPaySaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form143);
  const { retroServicePeriodNo, payload } = action.data;
  yield call(
    safeAuthEffect,
    setRetro,
    requestNumber,
    retroServicePeriodNo,
    payload
  );
  yield put({
    type: form143ActionTypes.GET_LATEST_FORM_143_REQUESTED,
    data: { requestNumber, updateFinancialInfoOnly: true }
  });
  yield put(
    getRetroPay143Action({
      requestNumber,
      servicePeriodNo: retroServicePeriodNo
    })
  );
}

export function* getRetroPaySaga(action: EapAction): any {
  const { servicePeriodNo, requestNumber } = action.data;
  const ret = yield call(
    safeAuthEffect,
    getRetro,
    requestNumber,
    servicePeriodNo
  );

  if (ret.data) {
    yield put(
      getRetroPay143SuccessAction({
        retroPayModels: ret.data?.retroPayModels,
        servicePeriodNo
      })
    );
  }
}

export function* deleteRetroPaySaga(action: EapAction): any {
  const { servicePeriodNo, requestNumber } = action.data;
  yield call(safeAuthEffect, deleteRetro, requestNumber, servicePeriodNo);
  yield put({
    type: form143ActionTypes.GET_LATEST_FORM_143_REQUESTED,
    data: { requestNumber, updateFinancialInfoOnly: true }
  });
}

export function* deleteForm143Saga(action: EapAction) {
  yield call(deleteFormSaga, action, {
    deleteSuccessAction: deleteForm143SuccessAction(),
    deleteFailedAction: deleteForm143FailedAction()
  });
}
export function* checkMemberValiditySaga(action: EapAction): any {
  const res = yield call(safeAuthEffect, getMemberDetails, {
    membershipNumber: action.data.membershipNumber,
    employmentNumber: action.data.mssemploymentItem?.employmentNumber,
    employerNumber:
      action.data.mssemploymentItem?.theMSSEmployer?.employerNumber,
    ctx: 'MIS'
  });

  yield put(resetValidMember());

  if (res.data) {
    yield put(memberProfileValid(action.data));
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(memberProfileInvalid());
  }
}

export function* resetform143saga(action: EapAction) {
  yield deleteFormSaga(action.data.deleteAction, {
    deleteSuccessAction: {
      type: form143ActionTypes.DELETE_FORM_143_SUCCEESS
    },
    deleteFailedAction: {
      type: form143ActionTypes.DELETE_FORM_143_FAILED
    }
  });
  yield put(createForm143Action(action.data.create143Data));
}

export function* form143Saga(): any {
  yield all([
    yield takeLatest(
      form143ActionTypes.CHECK_MEMBER_CONTEXT_REQUESTED,
      checkMemberContextSaga
    ),
    yield takeLatest(form143ActionTypes.GET_FORM_143_REQUESTED, getForm143Saga),
    yield takeLatest(
      form143ActionTypes.GET_LATEST_FORM_143_REQUESTED,
      fetchLatest143Saga
    ),
    yield takeLatest(
      form143ActionTypes.CREATE_FORM_143_REQUESTED,
      createForm143Saga
    ),
    yield takeLatest(
      form143ActionTypes.SET_FORM_143_CARRYOVER_REQUESTED,
      setCarryOverSaga
    ),
    yield takeLatest(
      form143ActionTypes.UPDATE_FORM_143_CARRYOVER_REQUESTED,
      getCarryOverSaga
    ),
    yield takeLatest(
      form143ActionTypes.RETRY_CREATE_FORM_143_REQUESTED,
      retryCreateForm143Saga
    ),
    yield takeLatest(
      form143ActionTypes.CALCULATE_PA_REQUESTED,
      calculatePASaga
    ),
    yield takeLatest(
      form143ActionTypes.SET_BANKING_INFORMATION_REQUESTED,
      setBankingInformationSaga
    ),
    yield takeLatest(form143ActionTypes.GET_BANKING_PDF, getBankingPdfSaga),
    yield takeLatest(
      form143ActionTypes.DELETE_BANKING_INFORMATION_PDF,
      deleteAdvanceElectionSaga
    ),
    yield takeLatest(
      [
        form143ActionTypes.SAVE_FORM_143_REQUESTED,
        form143ActionTypes.SAVE_FORM_143_RETRO_REQUESTED
      ],
      saveForm143Saga
    ),
    yield takeLatest(
      [
        form143ActionTypes.SAVE_FORM_143_SUCCESS,
        form143ActionTypes.SAVE_FORM_143_FAILED
      ],
      clearForm143StatusSaga
    ),
    yield takeLeading(
      form143ActionTypes.SUBMIT_FORM_143_REQUESTED,
      submitForm143Saga
    ),
    yield takeLatest(form143ActionTypes.GET_FORM_143_PDF, getPDF143Saga),
    yield takeLatest(form143ActionTypes.UNLOCK_FORM_143, unlockForm143Saga),
    yield takeLatest(
      form143ActionTypes.GET_RETRO_PAY_143_REQUESTED,
      getRetroPaySaga
    ),
    yield takeLatest(
      form143ActionTypes.SAVE_RETRO_PAY_REQUESTED,
      saveRetroPaySaga
    ),
    yield takeLatest(
      form143ActionTypes.DELETE_RETRO_PAY_143_REQUESTED,
      deleteRetroPaySaga
    ),
    yield takeLatest(
      form143ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_143_REQUESTED,
      checkMemberValiditySaga
    ),
    yield takeLatest(
      form143ActionTypes.REFRESH_FORM_143_REQUESTED,
      resetform143saga
    )
  ]);
}
