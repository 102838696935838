import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'types';

/**
 * Hook determines Annual Reporting table header state
 */
const useAnnualReportingTableHeaderState = () => {
  const { form119Data } = useSelector((state: RootState) => state.form119);

  const { financialModel } = form119Data.e119Model;
  const {
    currentLeavePeriodModel,
    form119Model,
    prevLeavePeriodModel
  } = financialModel;

  return useMemo(() => {
    const noCurrent =
      currentLeavePeriodModel === null &&
      form119Model !== null &&
      prevLeavePeriodModel !== null;

    const noFormModel =
      currentLeavePeriodModel !== null &&
      form119Model === null &&
      prevLeavePeriodModel !== null;

    const noPrev =
      currentLeavePeriodModel !== null &&
      form119Model !== null &&
      prevLeavePeriodModel === null;

    const onlyCurrent =
      currentLeavePeriodModel !== null &&
      form119Model === null &&
      prevLeavePeriodModel === null;

    const onlyFormModel =
      currentLeavePeriodModel === null &&
      form119Model !== null &&
      prevLeavePeriodModel === null;

    const onlyPrev =
      currentLeavePeriodModel === null &&
      form119Model === null &&
      prevLeavePeriodModel !== null;

    return {
      noCurrent,
      noFormModel,
      noPrev,
      onlyCurrent,
      onlyTwo: noCurrent || noFormModel || noPrev,
      onlyOne: onlyCurrent || onlyFormModel || onlyPrev,
      allThree:
        currentLeavePeriodModel !== null &&
        form119Model !== null &&
        prevLeavePeriodModel !== null
    };
  }, [currentLeavePeriodModel, form119Model, prevLeavePeriodModel]);
};

export default useAnnualReportingTableHeaderState;
