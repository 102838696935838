/*
 * Next.js uses the App component to initialize pages.
 * You can override it and control the page initialization.
 * To override, create the ./pages/_app.js file and override the App class
 * https://nextjs.org/docs#custom-app
 * https://nextjs.org/docs/basic-features/typescript#custom-app
 */
import { AppProps } from 'next/app';
import { Fragment, Suspense, useCallback, useEffect } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import { configureStore } from 'store';
import 'styles/_global.scss';
import Layout from 'layout/Layout';
import RouteProtectorContainer from 'containers/auth/RouteProtectorContainer';
import Spinner from 'components/spinner/Spinner';
import 'modules/i18n';
import 'modules/client/datadogRum';
import ErrorBoundary from 'containers/errorBoundary/ErrorBoundary';
import {
  logoutForcedAction,
  sessionRefreshedAction
} from 'containers/auth/userSessionActions';
import Toaster from 'features/v2/toaster';
import { ThemeProvider } from 'theme-ui';
import { theme } from 'styles/theme';
import { Theme } from '@omers-packages/elements';
import { isAuthenticated, isTokenExpired, tokenSelector } from 'utils';
import { sessionExpirySelector } from 'containers/auth/userSessionSelectors';
import clientSide from 'utils/logger/client-side';
import useFetchMaintenanceFlags from 'features/maintenanceFlags/useFetchMaintenanceFlags';
import { useEmployerQuarterlyFetcher } from 'containers/auth/LoginContainer/EmployerQuarterly';

export const { store, persistor } = configureStore();

const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV') as string;
const NEXT_PUBLIC_GIT_SHA = env('NEXT_PUBLIC_GIT_SHA') as string;
const NEXT_PUBLIC_GIT_TAG = env('NEXT_PUBLIC_GIT_TAG') as string;
const NEXT_PUBLIC_GOOGLE_RECAPTCHA_CLIENT_SIDE_KEY = env(
  'NEXT_PUBLIC_GOOGLE_RECAPTCHA_CLIENT_SIDE_KEY'
) as string;

const SessionInitiator: React.FC = () => {
  const dispatch = useDispatch();
  const sessionExpiry = useSelector(sessionExpirySelector);
  const isAuth = useSelector(isAuthenticated);
  const accessToken = useSelector(tokenSelector);

  const visibilityChangeHandler = useCallback(() => {
    const isSessionExpired = sessionExpiry
      ? sessionExpiry && Date.now() > sessionExpiry
      : false;

    if (
      document.visibilityState === 'visible' &&
      isAuth &&
      (isSessionExpired || isTokenExpired(accessToken))
    ) {
      clientSide.debug(
        'Session expired on visibility change ... logging user out'
      );
      dispatch(logoutForcedAction({ logoutNotification: 'expired' }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionExpiry, isAuth, accessToken]);

  useEffect(() => {
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionExpiry, isAuth]);

  useEffect(() => {
    isAuth && dispatch(sessionRefreshedAction(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth]);

  return null;
};

function MyApp({ Component, pageProps, router }: AppProps) {
  useEffect(() => {
    console.info(
      `${
        NEXT_PUBLIC_APP_ENV !== 'prod'
          ? `Latest git tag: ${NEXT_PUBLIC_GIT_TAG}`
          : NEXT_PUBLIC_GIT_TAG
      }`
    );

    if (NEXT_PUBLIC_APP_ENV !== 'prod') {
      console.info('Latest git SHA-1:', NEXT_PUBLIC_GIT_SHA);
    }
  }, []);

  useFetchMaintenanceFlags();

  useEmployerQuarterlyFetcher();

  const AppliedLayout =
    router.route === '/prints/e-correspondence' ||
    router.route === '/prints/member-profile'
      ? Fragment
      : Layout;

  return (
    <ThemeProvider theme={theme}>
      <Theme>
        <Provider store={store}>
          <GoogleReCaptchaProvider
            reCaptchaKey={NEXT_PUBLIC_GOOGLE_RECAPTCHA_CLIENT_SIDE_KEY}
          >
            <SessionInitiator />
            <PersistGate loading={null} persistor={persistor}>
              <Suspense fallback={<Spinner size="large" />}>
                <AppliedLayout>
                  {/*  @ts-ignore */}
                  <ErrorBoundary>
                    <RouteProtectorContainer>
                      <Toaster />
                      <Component {...pageProps} />
                    </RouteProtectorContainer>
                  </ErrorBoundary>
                </AppliedLayout>
              </Suspense>
            </PersistGate>
          </GoogleReCaptchaProvider>
        </Provider>
      </Theme>
    </ThemeProvider>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }: any) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  return { pageProps };
};

export default MyApp;
