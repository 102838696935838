import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { DataObj } from 'types';
import { getData, postData, putData, deleteData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const createForm165c = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c`, data);

export const getForm165c = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/${requestNumber}`,
    undefined,
    requestNumber
  );

export const submitForm165c = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const saveForm165c = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const adjustLeavePeriodForm165c = (
  requestNumber: string,
  purchaseNumber: number
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/${requestNumber}/${purchaseNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const removeLeavePeriodForm165c = (
  requestNumber: string,
  intervalNumber: number
) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/${requestNumber}/${intervalNumber}`,
    undefined,
    requestNumber
  );

export const updateLeavePeriodForm165c = (
  data: DataObj,
  requestNumber: string,
  intervalNumber: number,
  stepNo: number
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/step${
      stepNo !== 3 ? stepNo : 2
    }/${requestNumber}/${intervalNumber}`,
    data,
    undefined,
    requestNumber
  );

export const cancelLeavePeriodForm165c = (
  data: DataObj,
  requestNumber: string,
  intervalNumber: number
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/cancel/${requestNumber}/${intervalNumber}`,
    data,
    undefined,
    requestNumber
  );

export const getOriginalState165c = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/${requestNumber}/original-state`,
    undefined,
    requestNumber
  );

export const createLeavePeriodForm165c = (
  data: DataObj,
  requestNumber: string
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165c/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );
