/* eslint-disable camelcase */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormContext, useFieldArray } from 'react-hook-form-legacy';
import { flatten } from 'lodash';
import { ReactSVG } from 'react-svg';

import { RootState } from 'types';
import { formatToCad } from 'utils';
import Table from 'components/table/Table';
import TextCurrencyInput from 'components/forms/inputs/textCurrencyInput/TextCurrencyInput';
import TextFloatInput from 'components/forms/inputs/textFloatInput/TextFloatInput';
import ToolTip from 'components/toolTip/ToolTip';
import Button from 'components/button/Button';
import { createPeriodColumn } from '../utils/annualReportingUtils';
import useAnnualReportingTableHeaderState from '../hooks/useAnnualReportingTableHeaderState';

import styles from '../AnnualReporting.module.scss';

interface AnnualReportingSinglePeriodTableProps {
  onClickRetroDelete: () => void;
  onClickRetroEdit: () => void;
}
const AnnualReportingSinglePeriodTable = ({
  onClickRetroDelete,
  onClickRetroEdit
}: AnnualReportingSinglePeriodTableProps) => {
  const { form119Data } = useSelector((state: RootState) => state.form119);
  const { t } = useTranslation('form119');
  const { selectedReconciliationYear, financialModel } = form119Data.e119Model;
  const { columnYear } = financialModel;
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );

  const { control, register, errors, clearErrors, trigger } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'financialModel.employmentPeriodModels'
  });

  const {
    allThree,
    onlyTwo,
    noFormModel,
    noPrev,
    noCurrent,
    onlyOne,
    onlyCurrent
  } = useAnnualReportingTableHeaderState();

  const tableHeading = useMemo(() => {
    const firstYear = columnYear[0] || columnYear[1];
    const secondYear = columnYear[2] || columnYear[3];
    const headings = [
      [{ headingCell: '' }, { headingCell: firstYear, txtAlign: 'center' }]
    ];

    if (allThree) {
      headings[0].push({ headingCell: firstYear, txtAlign: 'center' });
      headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
    }

    if (onlyTwo) {
      if (noFormModel || noPrev) {
        headings[0].push({ headingCell: firstYear, txtAlign: 'center' });
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      }

      if (noCurrent) {
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      }
    }

    if (onlyOne) {
      if (onlyCurrent) {
        headings[0].push({ headingCell: firstYear, txtAlign: 'center' });
      } else {
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      }
    }

    return headings;
  }, [columnYear]);

  const tableData = (() => {
    let initialData = flatten(
      fields.map((field: any, index: number) => {
        if (field && field.hashMap) {
          return [
            [
              { contentCell: '' },
              {
                contentCell: (
                  <span className={styles.columnHeading}>
                    {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_1')}
                  </span>
                )
              }
            ],
            [
              {
                contentCell: (
                  <span className={styles.rowHeader}>
                    {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_1')}{' '}
                    <ToolTip
                      content={t('ANNUAL_REPORTING_CREDITED_SERVICE_TOOLTIP')}
                    />
                  </span>
                )
              },
              {
                contentCell: (
                  <TextFloatInput
                    key={`${field.id}-credited_service`}
                    testingTag={`financialModel.employmentPeriodModels[${index}].hashMap.credited_service`}
                    name={`financialModel.employmentPeriodModels[${index}].hashMap.credited_service`}
                    errorMessage={
                      errors.financialModel?.employmentPeriodModels[index]
                        .hashMap?.credited_service
                    }
                    refRegister={register()}
                    defaultValue={field.hashMap?.credited_service ?? ''}
                    noMarginBottom
                  />
                )
              }
            ],
            [
              {
                contentCell: (
                  <span className={styles.rowHeader}>
                    {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_2')}
                    <ToolTip
                      content={[
                        t('ANNUAL_REPORTING_CONTRIBUTORY_EARNINGS_TOOLTIP_1'),
                        t('ANNUAL_REPORTING_CONTRIBUTORY_EARNINGS_TOOLTIP_2')
                      ]}
                    />
                  </span>
                )
              },
              {
                contentCell: (
                  <TextCurrencyInput
                    key={`${field.id}-earnings`}
                    testingTag={`financialModel.employmentPeriodModels[${index}].hashMap.earnings`}
                    name={`financialModel.employmentPeriodModels[${index}].hashMap.earnings`}
                    defaultValue={field.hashMap?.earnings ?? ''}
                    errorMessage={
                      errors.financialModel?.employmentPeriodModels[index]
                        .hashMap?.earnings
                    }
                    refRegister={register()}
                    currencyMaxLength={7}
                    noMarginBottom
                  />
                )
              }
            ],
            [
              {
                contentCell: (
                  <span className={styles.rowHeader}>
                    <div>
                      {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_3')}
                      {!financialModel.paMandatory && (
                        <div className={styles.optionalIndicator}>
                          {t('ANNUAL_REPORTING_OPTIONAL')}
                        </div>
                      )}
                    </div>
                    <ToolTip
                      content={[
                        t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_1'),
                        t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_2'),
                        t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_3'),
                        t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_4')
                      ]}
                    />
                  </span>
                )
              },
              {
                contentCell: (
                  <span>
                    <TextCurrencyInput
                      key={`${field.id}-pension_adjustment_amt`}
                      testingTag={`financialModel.employmentPeriodModels[${index}].hashMap.pension_adjustment_amt`}
                      name={`financialModel.employmentPeriodModels[${index}].hashMap.pension_adjustment_amt`}
                      defaultValue={field.hashMap?.pension_adjustment_amt ?? ''}
                      refRegister={register()}
                      errorMessage={
                        errors.financialModel?.employmentPeriodModels[index]
                          ?.hashMap?.pension_adjustment_amt
                      }
                      currencyMaxLength={5}
                      noMarginBottom
                      disableUserDecimalInput
                    />
                    <input
                      key={`${field.id}-isPensionAdjustmentMandatory`}
                      name={`financialModel.employmentPeriodModels[${index}].hashMap.isPensionAdjustmentMandatory`}
                      type="hidden"
                      ref={register()}
                      value={financialModel.paMandatory ? 'Y' : 'N'}
                    />
                  </span>
                )
              }
            ],
            [
              {
                contentCell: (
                  <span className={styles.rowHeader}>
                    {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_4')}
                    <ToolTip content={t('ANNUAL_REPORTING_RPP_TOOLTIP')} />
                  </span>
                )
              },
              {
                contentCell: (
                  <TextCurrencyInput
                    key={`${field.id}-rpp_contribution_amt`}
                    testingTag={`financialModel.employmentPeriodModels[${index}].hashMap.rpp_contribution_amt`}
                    name={`financialModel.employmentPeriodModels[${index}].hashMap.rpp_contribution_amt`}
                    defaultValue={field.hashMap?.rpp_contribution_amt ?? ''}
                    refRegister={register()}
                    errorMessage={
                      errors.financialModel?.employmentPeriodModels[index]
                        ?.hashMap?.rpp_contribution_amt
                    }
                    onBlur={async () => {
                      clearErrors(
                        `financialModel.employmentPeriodModels[${index}].hashMap.rca_contribution_amt`
                      );
                      await trigger(
                        `financialModel.employmentPeriodModels[${index}].hashMap.rca_contribution_amt`
                      );
                    }}
                    currencyMaxLength={5}
                    noMarginBottom
                  />
                )
              }
            ],
            [
              {
                contentCell: (
                  <span className={styles.rowHeader}>
                    <div>
                      {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_5')}
                      <div className={styles.optionalIndicator}>
                        {t('ANNUAL_REPORTING_OPTIONAL')}
                      </div>
                    </div>
                    <ToolTip content={t('ANNUAL_REPORTING_RCA_TOOLTIP')} />
                  </span>
                )
              },
              {
                contentCell: (
                  <TextCurrencyInput
                    key={`${field.id}-rca_contribution_amt`}
                    testingTag={`financialModel.employmentPeriodModels[${index}].hashMap.rca_contribution_amt`}
                    name={`financialModel.employmentPeriodModels[${index}].hashMap.rca_contribution_amt`}
                    defaultValue={field.hashMap?.rca_contribution_amt ?? ''}
                    errorMessage={
                      errors.financialModel?.employmentPeriodModels[index]
                        ?.hashMap?.rca_contribution_amt
                    }
                    refRegister={register()}
                    currencyMaxLength={7}
                    noMarginBottom
                  />
                )
              }
            ],
            [
              {
                contentCell: (
                  <span className={styles.rowHeader}>
                    <div>
                      {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_6')}
                      <div className={styles.optionalIndicator}>
                        {t('ANNUAL_REPORTING_OPTIONAL')}
                      </div>
                    </div>

                    <ToolTip
                      content={[
                        t('ANNUAL_REPORTING_RETRO_PAY_TOOLTIP_1'),
                        t('ANNUAL_REPORTING_RETRO_PAY_TOOLTIP_2')
                      ]}
                    />
                  </span>
                )
              },
              {
                contentCell: (
                  <div className={styles.retroEdit}>
                    {formatToCad(financialModel.retroPay[0] || 0, 'en')}{' '}
                    <span className={styles.retroEditIcons}>
                      {financialModel.retroPay[0] !== null && (
                        <div className={styles.retroDelete}>
                          <Button
                            baseStyle="basic"
                            isDisabled={omersUser}
                            onClick={onClickRetroDelete}
                          >
                            <ReactSVG src="/images/delete.svg" />
                          </Button>
                        </div>
                      )}
                      <Button baseStyle="basic" onClick={onClickRetroEdit}>
                        <ReactSVG src="/images/edit.svg" />
                      </Button>
                    </span>
                  </div>
                )
              }
            ]
          ];
        }
        return [];
      })
    );

    if (financialModel.currentLeavePeriodModel && initialData[0]) {
      // first row
      initialData[0].push({
        contentCell: (
          <span className={styles.columnHeading}>
            {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_2')}
          </span>
        )
      });
      initialData = createPeriodColumn(
        initialData,
        financialModel.currentLeavePeriodModel,
        financialModel.retroPay[1]
      );
    }

    if (financialModel.form119Model && initialData[0]) {
      // first row
      initialData[0].push({
        contentCell: (
          <span className={styles.columnHeading}>
            {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_3')}
          </span>
        )
      });
      initialData = createPeriodColumn(
        initialData,
        financialModel.form119Model,
        financialModel.retroPay[2]
      );
    }

    if (financialModel.prevLeavePeriodModel && initialData[0]) {
      // first row
      initialData[0].push({
        contentCell: (
          <span className={styles.columnHeading}>
            {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_4')}
          </span>
        )
      });
      initialData = createPeriodColumn(
        initialData,
        financialModel.prevLeavePeriodModel,
        financialModel.retroPay[3]
      );
    }

    return initialData;
  })();

  return (
    <>
      {selectedReconciliationYear && (
        <>
          <h3>{`${selectedReconciliationYear?.yearText} - ${t(
            'ANNUAL_REPORTING_SINGLE_PERIOD'
          )}`}</h3>
          <p>
            {t('ANNUAL_REPORTING_SINGLE_PERIOD_PARA', {
              reportYear: selectedReconciliationYear?.yearText
            })}
          </p>
        </>
      )}

      <Table
        withColoredHeaderRow
        headerColor="blue"
        withTableBorder
        boldFirstColumn
        withRowBorders
        withColumnBorders
        tableHeading={tableHeading}
        tableData={tableData}
        withEvenWidthColumns
        testingTag="annual-reporting-single-period-table"
      />
    </>
  );
};

export default AnnualReportingSinglePeriodTable;
