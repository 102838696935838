import { EapAction } from 'types';
import { isNull } from 'util';
import { batchPrintActionTypes } from './BatchPrintActions';
import { buildData } from './utils/BatchPrintUtils';

export interface FormDataState {
  NRA: string | null;
  employmentStatus: string | null;
  owner: string | null;
  fromName: string | null;
  toName: string | null;
  fromDate: string;
  toDate: string;
  isSearchFilterDirty: boolean;
  isNewSearch: boolean;
  isLoading: boolean;
  filteredResultsArr: Array<{
    requestNo: number;
    userId: string | null;
    createdDates: Array<string> | null;
    memberFirstName: string | null;
    memberMiddleName: string | null;
    memberLastName: string | null;
    memberMembershipNumber: string | null;
    memberEmployeeId: number | null;
    memberNRA: string | null;
    memberEmploymentStatus: string | null;
    memberSelected: string;
    attachments: any;
  }>;
  searchDefaultValues: {
    NRA: string | null;
    employmentStatus: string | null;
    owner: string | null;
    fromName: string | null;
    toName: string | null;
    fromDate: string;
    toDate: string;
    isSearchFilterDirty: boolean;
  };
}

export interface BatchPrintState {
  formData: FormDataState;
  filteredResults: any;
}

const initialState = {
  formData: {
    NRA: 'All',
    employmentStatus: 'All',
    owner: 'All users',
    fromName: '',
    toName: '',
    fromDate: `01/01/${new Date().getFullYear() - 2}`,
    toDate: '',
    isSearchFilterDirty: false,
    isNewSearch: false,
    isLoading: false,
    filteredResultsArr: [],
    searchDefaultValues: {
      NRA: 'All',
      employmentStatus: 'All',
      owner: 'All users',
      fromName: '',
      toName: '',
      fromDate: `01/01/${new Date().getFullYear() - 2}`,
      toDate: '',
      isSearchFilterDirty: false
    }
  },
  filteredResults: null
};

const batchPrint = (
  state: BatchPrintState = initialState,
  action: EapAction
): BatchPrintState => {
  const { type, data } = action;
  switch (type) {
    case batchPrintActionTypes.GET_BATCH_PRINT_SEARCH_RESULTS_REQUESTED:
      return {
        ...state,
        formData: {
          ...state.formData,
          isNewSearch: data.newFilterCriteria,
          isLoading: true,
          filteredResultsArr: [],
          NRA: isNull(data.payload.nra) ? 'All' : data.payload.nra,
          employmentStatus: isNull(data.payload.employmentStatus)
            ? 'All'
            : data.payload.employmentStatus,
          owner: isNull(data.payload.owner) ? 'All users' : data.payload.owner,
          fromName: data.payload.fromName,
          toName: data.payload.toName,
          fromDate: data.payload.fromDate,
          toDate: data.payload.toDate
        }
      };
    case batchPrintActionTypes.GET_BATCH_PRINT_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        filteredResults: data.requestSearchResult,
        formData: {
          ...state.formData,
          isSearchFilterDirty: false,
          isLoading: false,
          filteredResultsArr: buildData(data?.requestSearchResult?.requests)
        }
      };
    case batchPrintActionTypes.GET_BATCH_PRINT_SEARCH_RESULTS_FAILED:
      return {
        ...state,
        filteredResults: {},
        formData: {
          ...state.formData,
          isSearchFilterDirty: false,
          isLoading: false,
          filteredResultsArr: []
        }
      };
    case batchPrintActionTypes.SET_FILTER_IS_DIRTY:
      return {
        ...state,
        formData: {
          ...state.formData,
          isSearchFilterDirty: data.isFilterDirty
        }
      };
    case batchPrintActionTypes.SET_IS_NEW_FILTER_CRITERIA:
      return {
        ...state,
        formData: {
          ...state.formData,
          isNewSearch: data
        }
      };
    case batchPrintActionTypes.RESET_SEARCH_FILTER:
      return {
        ...state,
        formData: {
          ...state.formData,
          NRA: 'All',
          employmentStatus: 'All',
          owner: 'All users',
          fromName: '',
          toName: '',
          fromDate: `01/01/${new Date().getFullYear() - 2}`,
          toDate: '',
          isSearchFilterDirty: false
        }
      };
    default:
      return state;
  }
};
export default batchPrint;
