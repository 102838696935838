import { datadogLogs, StatusType } from '@datadog/browser-logs';
import { env } from '@omers-packages/next-isomorphic-runtime-env';

const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV');

if (NEXT_PUBLIC_APP_ENV === 'prod') {
  datadogLogs.logger.setHandler('http');
  datadogLogs.logger.setLevel(StatusType.info);
} else if (NEXT_PUBLIC_APP_ENV === 'local') {
  datadogLogs.logger.setHandler('console');
  datadogLogs.logger.setLevel(StatusType.debug);
} else {
  datadogLogs.logger.setHandler(['console', 'http']);
  datadogLogs.logger.setLevel(StatusType.debug);
}

export default datadogLogs.logger;
