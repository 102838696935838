/* eslint-disable camelcase */
import { maskCurrency, getYMDStringsFromDate } from 'utils';
import { convertDateYMDtoMDY } from 'utils/date';

const mapForm102 = (form: any) => ({
  // Employee Information section
  sin: form.e102Model.employeeEmployment?.sin?.toString() ?? '',
  employeeId: form.e102Model.employeeEmployment?.employeeId ?? '',
  employerNo: form.e102Model.employerNo ?? '',
  departmentID: form.e102Model.employeeEmployment?.departmentId ?? '',
  firstName: form.e102Model.employeeEmployment?.firstName ?? '',
  middleName: form.e102Model.employeeEmployment?.middleName ?? '',
  lastName: form.e102Model.employeeEmployment?.lastName ?? '',
  sex: form.e102Model.employeeEmployment?.sex ?? '',
  birthDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.birthDate).month ??
    '',
  birthDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.birthDate
  ).day,
  birthDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.birthDate
  ).year,
  signedForm132:
    form.e102Model.employeeEmployment?.releaseInfoConsentInd ?? 'N',

  // Employment section
  occupation: form.e102Model.employeeEmployment?.pssOccupation ?? '',
  memberAffiliation: form.e102Model.employeeEmployment?.pssAffiliation ?? '',
  normalRetirementAge: form.e102Model.employeeEmployment?.nra ?? '',
  hireDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.hireDate).month ??
    '',
  hireDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.hireDate
  ).day,
  hireDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.hireDate
  ).year,
  hireDate: convertDateYMDtoMDY(form.e102Model.employeeEmployment?.hireDate),
  electionDate: convertDateYMDtoMDY(
    form.e102Model.employeeEmployment?.electionDate
  ),
  enrolSameAsHire:
    form.e102Model.employeeEmployment?.hireEnrolDatesSameInd === 'Y',
  enrolDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.enrolmentDate)
      .month ?? '',
  enrolDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.enrolmentDate
  ).day,
  enrolDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.enrolmentDate
  ).year,
  enrolDate: convertDateYMDtoMDY(
    form.e102Model.employeeEmployment?.enrolmentDate
  ),
  enrolReason: form.e102Model.employeeEmployment?.enrolReason ?? '',

  // Compensation section
  salary: form.e102Model.employeeEmployment?.hourlyRate ? 'H' : 'A',
  salaryRate: (() => {
    if (form.e102Model.employeeEmployment?.hourlyRate) {
      return form.e102Model.employeeEmployment?.hourlyRate
        ? maskCurrency(
            form.e102Model.employeeEmployment.hourlyRate
              ?.toFixed(2)
              .toString() ?? '',
            'en'
          )
        : '';
    }
    return form.e102Model.employeeEmployment?.annualSalaryRate
      ? maskCurrency(
          form.e102Model.employeeEmployment.annualSalaryRate
            ?.toFixed(2)
            .toString() ?? '',
          'en'
        )
      : '';
  })(),
  employmentStatus: form.e102Model.employeeEmployment?.employmentStatus ?? 'C',
  hasDivestment: form.e102Model.employeeEmployment?.restructInd ?? 'N',
  hasPriorPension:
    form.e102Model.employeeEmployment?.prevPPParticipationInd ?? 'N',
  priorPensionDateMonth:
    getYMDStringsFromDate(form.e102Model.employeeEmployment?.prevPPHireDate)
      .month ?? '',
  priorPensionDateDay: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.prevPPHireDate
  ).day,
  priorPensionDateYear: getYMDStringsFromDate(
    form.e102Model.employeeEmployment?.prevPPHireDate
  ).year,
  priorPensionDate: convertDateYMDtoMDY(
    form.e102Model.employeeEmployment?.prevPPHireDate
  ),
  priorPensionName: form.e102Model.employeeEmployment?.prevPPName ?? '',

  // Address section
  apartmentUnit: form.e102Model.address?.unit ?? '',
  address1: form.e102Model.address?.addr_line_1 ?? '',
  address2: form.e102Model.address?.addr_line_2 ?? '',
  country: form.e102Model.address?.country ?? 'CAN',
  province: form.e102Model.address?.province ?? '',
  city: form.e102Model.address?.city ?? '',
  postal: form.e102Model.address?.postal_code ?? '',
  mobilePhone: `${form.e102Model.address?.mobilePhoneAreaCode ?? ''}${form
    .e102Model.address?.mobilePhoneNo ?? ''}`,
  homePhone: `${form.e102Model.address?.phone_area_code ?? ''}${form.e102Model
    .address?.phone_no ?? ''}`,
  email: form.e102Model.address?.email_address,
  paperlessConsent: form.e102Model.address?.paperlessConsentInd ?? 'N',
  noteToOmers: form.e102Model.notes ?? ''
});

export default mapForm102;
