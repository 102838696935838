import { parseISO, format } from 'date-fns';
import { ServicePeriodModel } from 'interfaces';
import { merge } from 'lodash';
import { formatCurrencyStringToNumber, formatDateUTC } from 'utils';
import { Form190Data } from '../form190Reducer';

export const buildForm190Data = (data: any) => ({
  servicePeriodModels:
    data?.servicePeriodModels?.map((service: ServicePeriodModel) => ({
      associatedService: service?.associatedService ?? null,
      creditedService: service?.creditedService ?? null,
      earnings: service?.earnings ?? null,
      requestNo: service?.requestNo ?? null,
      retroEarningsTotal: service?.retroEarningsTotal ?? null,
      retroEndDate: service?.retroEndDate
        ? format(parseISO(service?.retroEndDate), 'MM/dd/yyyy')
        : '',
      retroStartDate: service?.retroStartDate
        ? format(parseISO(service?.retroStartDate), 'MM/dd/yyyy')
        : '',
      retroType: service?.retroType ?? '',
      servicePeriodNo: service?.servicePeriodNo ?? '',
      servicePeriodType: service?.servicePeriodType ?? '',
      year: service?.year ?? ''
    })) ?? [],
  highestEarningsModels: data?.highestEarningsModels ?? [],
  quoteAssumptionModel:
    {
      nraMilestoneInd: data?.quoteAssumptionModel?.nraMilestoneInd ?? 'N',
      unreducedMilestoneInd:
        data?.quoteAssumptionModel?.unreducedMilestoneInd ?? 'N',
      firstAssumedEventDate: data?.quoteAssumptionModel?.firstAssumedEventDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.firstAssumedEventDate),
            'MM/dd/yyyy'
          )
        : '',
      secondAssumedEventDate: data?.quoteAssumptionModel?.secondAssumedEventDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.secondAssumedEventDate),
            'MM/dd/yyyy'
          )
        : '',
      thirdAssumedEventDate: data?.quoteAssumptionModel?.thirdAssumedEventDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.thirdAssumedEventDate),
            'MM/dd/yyyy'
          )
        : '',
      fourthAssumedEventDate: data?.quoteAssumptionModel?.fourthAssumedEventDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.fourthAssumedEventDate),
            'MM/dd/yyyy'
          )
        : '',
      fifthAssumedEventDate: data?.quoteAssumptionModel?.fifthAssumedEventDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.fifthAssumedEventDate),
            'MM/dd/yyyy'
          )
        : '',
      firstPeriodProjectionPct:
        data?.quoteAssumptionModel?.firstPeriodProjectionPct ?? 2,
      secondPeriodProjectionPct:
        data?.quoteAssumptionModel?.secondPeriodProjectionPct ?? 2,
      firstPeriodToDateYear:
        data?.quoteAssumptionModel?.firstPeriodToDateYear ??
        new Date().getFullYear() + 5,
      otcftCode: data?.quoteAssumptionModel?.otcftCode ?? '',
      otcftPct: data?.quoteAssumptionModel?.otcftPct ?? '',
      otcftNotWorkedFromDate: data?.quoteAssumptionModel?.otcftNotWorkedFromDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.otcftNotWorkedFromDate),
            'MM/dd/yyyy'
          )
        : '',
      otcftNotWorkedToDate: data?.quoteAssumptionModel?.otcftNotWorkedToDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.otcftNotWorkedToDate),
            'MM/dd/yyyy'
          )
        : '',
      lastEarningsDate: data?.quoteAssumptionModel?.lastEarningsDate
        ? format(
            parseISO(data?.quoteAssumptionModel?.lastEarningsDate),
            'MM/dd/yyyy'
          )
        : ''
    } ?? null,
  noteToMember: data?.commentsToMember ?? '',
  noteToOmers: data?.notes ?? ''
});

export const constructSaveForm190Payload = (
  saveFormActionData: any,
  form190Data: Form190Data,
  formStep: number,
  requestNumber: string
) => ({
  notes:
    formStep === 1
      ? form190Data.noteToOmers
      : saveFormActionData.formData.noteToOmers,
  commentsToMember:
    formStep === 1
      ? form190Data.noteToMember
      : saveFormActionData.formData.noteToMember,
  exceptions: saveFormActionData.exceptions,
  quoteAssumptionModel: {
    requestNumber,
    nraMilestoneInd:
      formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.nraMilestoneInd
        : form190Data.quoteAssumptionModel?.nraMilestoneInd,
    unreducedMilestoneInd:
      formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.unreducedMilestoneInd
        : form190Data.quoteAssumptionModel?.unreducedMilestoneInd,
    firstAssumedEventDate: formatDateUTC(
      (formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.firstAssumedEventDate
        : form190Data.quoteAssumptionModel?.firstAssumedEventDate) || null
    ),
    secondAssumedEventDate: formatDateUTC(
      (formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel
            .secondAssumedEventDate
        : form190Data.quoteAssumptionModel?.secondAssumedEventDate) || null
    ),
    thirdAssumedEventDate: formatDateUTC(
      (formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.thirdAssumedEventDate
        : form190Data.quoteAssumptionModel?.thirdAssumedEventDate) || null
    ),
    fourthAssumedEventDate: formatDateUTC(
      (formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel
            .fourthAssumedEventDate
        : form190Data.quoteAssumptionModel?.fourthAssumedEventDate) || null
    ),
    fifthAssumedEventDate: formatDateUTC(
      (formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.fifthAssumedEventDate
        : form190Data.quoteAssumptionModel?.fifthAssumedEventDate) || null
    ),
    firstPeriodProjectionPct:
      formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel
            .firstPeriodProjectionPct
        : form190Data.quoteAssumptionModel?.firstPeriodProjectionPct,
    firstPeriodToDateYear:
      formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.firstPeriodToDateYear
        : form190Data.quoteAssumptionModel?.firstPeriodToDateYear,
    secondPeriodProjectionPct:
      formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel
            .secondPeriodProjectionPct
        : form190Data.quoteAssumptionModel?.secondPeriodProjectionPct,
    otcftPct:
      formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.otcftPct
        : form190Data.quoteAssumptionModel?.otcftPct,
    otcftNotWorkedFromDate: formatDateUTC(
      (formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel
            .otcftNotWorkedFromDate
        : form190Data.quoteAssumptionModel?.otcftNotWorkedFromDate) || null
    ),
    otcftNotWorkedToDate: formatDateUTC(
      (formStep === 1
        ? saveFormActionData.formData.quoteAssumptionModel.otcftNotWorkedToDate
        : form190Data.quoteAssumptionModel?.otcftNotWorkedToDate) || null
    ),
    lastEarningsDate: formatDateUTC(
      (formStep === 2
        ? saveFormActionData.formData.quoteAssumptionModel.lastEarningsDate
        : form190Data.quoteAssumptionModel?.lastEarningsDate) || null
    ),
    otcftCode: form190Data.quoteAssumptionModel?.otcftCode
  },
  servicePeriodModels: form190Data.servicePeriodModels.map(
    (servicePeriodData: ServicePeriodModel, index: number) => {
      if (index === 0) return servicePeriodData;
      return {
        requestNo: Number(requestNumber),
        servicePeriodNo: servicePeriodData.servicePeriodNo,
        associatedService:
          formStep === 2
            ? Number(
                saveFormActionData.formData.servicePeriodModels[index]
                  .associatedService || 0
              )
            : servicePeriodData.associatedService,
        creditedService:
          formStep === 2
            ? Number(
                saveFormActionData.formData.servicePeriodModels[index]
                  .creditedService
              )
            : servicePeriodData.creditedService,
        earnings:
          formStep === 2
            ? Number(
                saveFormActionData.formData.servicePeriodModels[
                  index
                ].earnings.replace(/,/g, '')
              )
            : servicePeriodData.earnings,
        retroEarningsTotal:
          formStep === 2
            ? Number(
                saveFormActionData.formData.servicePeriodModels[index]
                  .retroEarningsTotal
              )
            : servicePeriodData.retroEarningsTotal,
        retroEndDate: formatDateUTC(
          (formStep === 2
            ? saveFormActionData.formData.servicePeriodModels[index]
                .retroEndDate
            : servicePeriodData.retroEndDate) || null
        ),
        retroStartDate: formatDateUTC(
          (formStep === 2
            ? saveFormActionData.formData.servicePeriodModels[index]
                .retroStartDate
            : servicePeriodData.retroStartDate) || null
        ),
        retroType: servicePeriodData.retroType,
        servicePeriodType: servicePeriodData.servicePeriodType,
        year: servicePeriodData.year
      };
    }
  )
});

export const mergeDirtyValuesToFormData = (
  formValues: { [key: string]: any },
  formData: any
) => ({
  ...(formValues
    ? {
        servicePeriodModels: merge(
          formData.servicePeriodModels,
          formValues.servicePeriodModels
        ),
        quoteAssumptionModel: merge(
          formData.quoteAssumptionModel,
          formValues.quoteAssumptionModel
        ),
        highestEarningsModels: formData.highestEarningsModels,
        noteToMember: formValues.noteToMember ?? formData.noteToMember,
        noteToOmers: formValues.noteToOmers ?? formData.noteToOmers
      }
    : {})
});

export const getServicePeriodModelTypes = (data: any[]) => ({
  servicePeriodModels:
    data?.map((service: any) => {
      const associatedService =
        service?.servicePeriodNo !== 0
          ? service?.associatedService
            ? parseFloat(service?.associatedService)
            : null
          : service?.associatedService;
      const creditedService =
        service?.servicePeriodNo !== 0
          ? service?.creditedService
            ? parseFloat(service?.creditedService)
            : null
          : service?.creditedService;
      const earnings =
        service?.servicePeriodNo !== 0
          ? service?.earnings
            ? formatCurrencyStringToNumber(service?.earnings)
            : null
          : service?.earnings;
      return {
        associatedService,
        creditedService,
        earnings,
        requestNo: service?.requestNo ?? null,
        retroEarningsTotal: service?.retroEarningsTotal ?? null,
        retroEndDate: service?.retroEndDate ?? '',
        retroStartDate: service?.retroStartDate ?? '',
        retroType: service?.retroType ?? '',
        servicePeriodNo: Number(service?.servicePeriodNo) ?? '',
        servicePeriodType: service?.servicePeriodType ?? '',
        year: service?.year ?? '',
        stepNumber: service?.stepNumber ?? ''
      };
    }) ?? []
});
