import { annualReconciliationActionTypes } from 'containers/annualReconciliation/annualReconciliationActions';
import { userSessionActionTypes } from 'containers/auth/userSessionActions';
import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import { ApiErrorObject } from 'interfaces';
import uniq from 'lodash/uniq';
import { EapAction } from 'types';
import { requestsActionTypes } from './requestsActions';
import { form143ChangeTypes } from './requestsSaga';
import { defaultQuery, RequestsQuery } from './requestsSearchUtil';

export enum REQUESTS_TABS {
  NOT_SUBMITTED = 'notSubmitted',
  SUBMITTED = 'submitted'
}

export enum Request143Types {
  RETIREMENT = 'R',
  TERMINATION = 'T',
  PRE_RETIREMENT_DEATH = 'V',
  DISABILITY = 'D'
}

export type RequestsFilterDate =
  | 'last-7-days'
  | 'last-30-days'
  | 'last-6-months'
  | 'last-12-months'
  | 'custom'
  | 'all';

export interface RequestsFilterState {
  requestType?: string;
  updatedBy?: string;
  updatedDate?: RequestsFilterDate;
  submittedBy?: string;
  submittedDate?: RequestsFilterDate;
  searchByMemberSelect?: string;
  searchByMemberValue?: string;
  customFromDate?: string;
  customToDate?: string;
}

export interface RequestsTableState {
  countPerPage: number;
  hasNextPage: string;
  hasPrevPage: string;
  notSubmittedRequestCount: number;
  requests: Array<any>; // TODO: Add full Request type (might do)
  rowCountExceedsRowLimit: boolean;
  startIndex: number;
  totalRowCount: number;
}

// Not a complete model for type. Provided just to have minimum level of type safety for working with
// requests.
export type MinimumRequest = {
  memberFirstName: string;
  memberLastName: string;
  memberEmployeeId: string;
  requestNo: string;
  membershipForm143Info: {
    memberReasonForRequest: string;
    memberMembershipNumber: string;
  };
};

export interface RequestsState {
  activeTab: REQUESTS_TABS;
  loading: boolean;
  returnedRequestsCount: number;
  form143RequestContext: {
    changeType: null | form143ChangeTypes;
    formType: null | Request143Types;
  };
  form143AdjustmentConfirmationModal: {
    isValidating143Change: boolean;
    isVisible: boolean;
    request: MinimumRequest;
  };
  form143AdjustmentErrorModal: {
    error: ApiErrorObject;
    isVisible: boolean;
  };
  filterNotSubmitted: {
    isDirty: boolean;
    filter: RequestsFilterState;
    query: RequestsQuery;
  };
  filterSubmitted: {
    isDirty: boolean;
    filter: RequestsFilterState;
    query: RequestsQuery;
  };
  tableNotSubmitted: RequestsTableState;
  tableSubmitted: RequestsTableState;
  requestsPendingDeleteOrOverride: Array<string>;
  deleteCallResponse: {
    type: string;
    deleted: number;
    notDeleted: number;
  };
}

const filterNotSubmittedInitialState: RequestsFilterState = {
  requestType: 'all',
  updatedBy: 'all',
  updatedDate: 'all',
  searchByMemberSelect: 'employeeId',
  searchByMemberValue: '',
  customFromDate: undefined,
  customToDate: undefined
};

const filterSubmittedInitialState: RequestsFilterState = {
  requestType: 'all',
  submittedBy: 'all',
  submittedDate: 'last-30-days',
  searchByMemberSelect: 'employeeId',
  searchByMemberValue: '',
  customFromDate: undefined,
  customToDate: undefined
};

export const initialState: RequestsState = {
  activeTab: REQUESTS_TABS.NOT_SUBMITTED,
  loading: false,
  returnedRequestsCount: 0,
  filterNotSubmitted: {
    isDirty: false,
    filter: filterNotSubmittedInitialState,
    query: {
      ...defaultQuery,
      status: 1
    }
  },
  filterSubmitted: {
    isDirty: false,
    filter: filterSubmittedInitialState,
    query: {
      ...defaultQuery,
      status: 2
    }
  },
  form143RequestContext: {
    changeType: null,
    formType: null
  },
  form143AdjustmentConfirmationModal: {
    isValidating143Change: false,
    isVisible: false,
    request: {
      memberFirstName: '',
      memberLastName: '',
      memberEmployeeId: '',
      requestNo: '',
      membershipForm143Info: {
        memberReasonForRequest: '',
        memberMembershipNumber: ''
      }
    }
  },
  form143AdjustmentErrorModal: {
    error: {
      code: '',
      msg: '',
      parameters: null
    },
    isVisible: false
  },
  tableNotSubmitted: {
    countPerPage: 0,
    hasNextPage: 'N',
    hasPrevPage: 'N',
    notSubmittedRequestCount: 0,
    requests: [],
    rowCountExceedsRowLimit: false,
    startIndex: 0,
    totalRowCount: 0
  },
  tableSubmitted: {
    countPerPage: 0,
    hasNextPage: 'N',
    hasPrevPage: 'N',
    notSubmittedRequestCount: 0,
    requests: [],
    rowCountExceedsRowLimit: false,
    startIndex: 0,
    totalRowCount: 0
  },
  requestsPendingDeleteOrOverride: [],
  deleteCallResponse: {
    type: '',
    deleted: 0,
    notDeleted: 0
  }
};

const selectAllForDeletion = (tableNotSubmitted: RequestsTableState) => {
  const { requests: tableRequests, countPerPage } = tableNotSubmitted;
  return tableRequests.map((request: any, index: number) =>
    index < countPerPage ? request.requestNo : undefined
  );
};

const determineRequestsPendingDeleteOrOverride = (
  requestsArr: any[],
  requestsPendingDeleteOrOverride: string[]
) => {
  const ret: any[] = [];
  requestsPendingDeleteOrOverride.forEach(item => {
    const match = requestsArr.filter(
      // eslint-disable-next-line no-shadow
      (requestItem: any) => requestItem.requestNo === item
    )[0];
    if (match && match.requestNo) {
      ret.push(match.requestNo);
    }
  });
  return ret;
};

const requests = (
  state: RequestsState = initialState,
  action: EapAction
): RequestsState => {
  const { type, data } = action;
  switch (type) {
    case requestsActionTypes.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: data
      };
    case requestsActionTypes.APPLY_FILTER:
      return {
        ...state,
        loading: true,
        filterNotSubmitted:
          data.tab === REQUESTS_TABS.NOT_SUBMITTED
            ? {
                isDirty: false,
                filter: data.filter,
                query: data.query
              }
            : state.filterNotSubmitted,
        filterSubmitted:
          data.tab === REQUESTS_TABS.SUBMITTED
            ? {
                isDirty: false,
                filter: data.filter,
                query: data.query
              }
            : state.filterSubmitted
      };
    case requestsActionTypes.RESET_ALL_FILTERS:
      return {
        ...state,
        filterNotSubmitted: {
          isDirty: false,
          filter: filterNotSubmittedInitialState,
          query: {
            ...defaultQuery,
            status: 1
          }
        },
        filterSubmitted: {
          isDirty: false,
          filter: filterSubmittedInitialState,
          query: {
            ...defaultQuery,
            status: 2
          }
        }
      };
    case requestsActionTypes.SET_FILTER_IS_DIRTY:
      return {
        ...state,
        filterNotSubmitted:
          data.tab === REQUESTS_TABS.NOT_SUBMITTED
            ? {
                ...state.filterNotSubmitted,
                isDirty: data.isDirty
              }
            : state.filterNotSubmitted,
        filterSubmitted:
          data.tab === REQUESTS_TABS.SUBMITTED
            ? {
                ...state.filterSubmitted,
                isDirty: data.isDirty
              }
            : state.filterSubmitted
      };
    case requestsActionTypes.GET_RETURNED_ACTIONS_COUNT_SUCCEEDED_FAILED:
      return {
        ...state,
        returnedRequestsCount: data ?? 0
      };
    case requestsActionTypes.SEARCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        tableNotSubmitted:
          data.tab === REQUESTS_TABS.NOT_SUBMITTED
            ? data.tableData.data.requestSearchResult
            : state.tableNotSubmitted,
        tableSubmitted:
          data.tab === REQUESTS_TABS.SUBMITTED
            ? data.tableData.data.requestSearchResult
            : state.tableSubmitted,
        requestsPendingDeleteOrOverride: determineRequestsPendingDeleteOrOverride(
          state.tableNotSubmitted.requests,
          state.requestsPendingDeleteOrOverride
        )
      };
    case requestsActionTypes.SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        tableNotSubmitted:
          data.tab === REQUESTS_TABS.NOT_SUBMITTED
            ? initialState.tableNotSubmitted
            : state.tableNotSubmitted,
        tableSubmitted:
          data.tab === REQUESTS_TABS.SUBMITTED
            ? initialState.tableSubmitted
            : state.tableSubmitted,
        requestsPendingDeleteOrOverride: determineRequestsPendingDeleteOrOverride(
          state.tableNotSubmitted.requests,
          state.requestsPendingDeleteOrOverride
        )
      };
    case requestsActionTypes.ADD_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE:
      return {
        ...state,
        requestsPendingDeleteOrOverride: uniq([
          ...state.requestsPendingDeleteOrOverride,
          data
        ])
      };
    case requestsActionTypes.REMOVE_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE:
      return {
        ...state,
        requestsPendingDeleteOrOverride: state.requestsPendingDeleteOrOverride.filter(
          item => item !== data
        )
      };
    case requestsActionTypes.ADD_ALL_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE:
      return {
        ...state,
        requestsPendingDeleteOrOverride: selectAllForDeletion(
          state.tableNotSubmitted
        )
      };
    case requestsActionTypes.REMOVE_ALL_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE:
      return {
        ...state,
        requestsPendingDeleteOrOverride: []
      };
    case requestsActionTypes.DELETE_MULTIPLE_FORMS_REQUESTED:
    case requestsActionTypes.DELETE_FORM_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case requestsActionTypes.DELETE_FORM_FAILED:
    case requestsActionTypes.DELETE_MULTIPLE_FORMS_FAILED:
    case requestsActionTypes.DELETE_FORM_SUCCEEDED:
    case requestsActionTypes.DELETE_MULTIPLE_FORMS_SUCCEEDED:
      return {
        ...state,
        loading: false,
        requestsPendingDeleteOrOverride:
          data?.requestsPendingDeleteOrOverride ?? [],
        deleteCallResponse: data?.deleteCallResponse
      };
    case requestsActionTypes.CLEAR_DELETE_CALL_RESPONSE:
      return {
        ...state,
        deleteCallResponse: {
          type: '',
          deleted: 0,
          notDeleted: 0
        },
        requestsPendingDeleteOrOverride: determineRequestsPendingDeleteOrOverride(
          state.tableNotSubmitted.requests,
          state.requestsPendingDeleteOrOverride
        )
      };
    case requestsActionTypes.SET_SORT:
      return {
        ...state,
        loading: true,
        filterNotSubmitted: {
          ...state.filterNotSubmitted,
          query:
            data.tab === REQUESTS_TABS.NOT_SUBMITTED
              ? {
                  ...state.filterNotSubmitted.query,
                  searchAscending: data.searchAscending,
                  sortByLastName: data.sortByLastName
                }
              : state.filterNotSubmitted.query
        },
        filterSubmitted: {
          ...state.filterSubmitted,
          query:
            data.tab === REQUESTS_TABS.SUBMITTED
              ? {
                  ...state.filterSubmitted.query,
                  searchAscending: data.searchAscending,
                  sortByLastName: data.sortByLastName
                }
              : state.filterSubmitted.query
        }
      };
    case requestsActionTypes.SET_FORM_143_CONTEXT:
      return {
        ...state,
        form143RequestContext: data
      };
    case requestsActionTypes.SHOW_FORM_143_CHANGE_CONFIRMATION:
      return {
        ...state,
        form143AdjustmentConfirmationModal: {
          ...state.form143AdjustmentConfirmationModal,
          isVisible: true,
          request: data
        }
      };
    case requestsActionTypes.HIDE_FORM_143_CHANGE_CONFIRMATION:
      return {
        ...state,
        form143AdjustmentConfirmationModal: {
          ...state.form143AdjustmentConfirmationModal,
          isVisible: false
        },
        form143RequestContext: initialState.form143RequestContext
      };
    case requestsActionTypes.VALIDATE_FORM_143_CHANGE_TYPE:
      return {
        ...state,
        form143AdjustmentConfirmationModal: {
          ...state.form143AdjustmentConfirmationModal,
          isValidating143Change: true
        }
      };
    case requestsActionTypes.FORM_143_CHANGE_VALIDATION_SUCCEEDED:
    case requestsActionTypes.FORM_143_CHANGE_VALIDATION_FAILED:
      return {
        ...state,
        form143AdjustmentConfirmationModal: {
          ...state.form143AdjustmentConfirmationModal,
          isValidating143Change: false
        }
      };
    case requestsActionTypes.FORM_143_CHANGE_VALIDATION_TIMEOUT:
      return {
        ...state,
        form143AdjustmentConfirmationModal: {
          ...state.form143AdjustmentConfirmationModal,
          isValidating143Change: false,
          isVisible: false
        }
      };
    case requestsActionTypes.SHOW_FORM_143_ERROR_MODAL:
      return {
        ...state,
        form143AdjustmentErrorModal: {
          isVisible: true,
          error: data.error
        }
      };
    case requestsActionTypes.HIDE_FORM_143_ERROR_MODAL:
      return {
        ...state,
        form143AdjustmentErrorModal: {
          isVisible: false,
          error: initialState.form143AdjustmentErrorModal.error
        }
      };
    case annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        tableNotSubmitted: data,
        requestsPendingDeleteOrOverride: determineRequestsPendingDeleteOrOverride(
          state.tableNotSubmitted.requests,
          state.requestsPendingDeleteOrOverride
        )
      };
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
    case selectEmployersActionTypes.SELECT_GROUP_NUMBER_SUCCESS:
    case userSessionActionTypes.LOGOUT_SUCCEEDED_FAILED:
      return initialState;
    default:
      return state;
  }
};

export default requests;
