import { ActionCreator } from 'redux';
import { EapAction } from 'types';

export enum resetPasswordActionTypes {
  INIT_RESET_PASSWORD = 'INIT_RESET_PASSWORD',
  SUBMIT_RESET_PASSWORD_REQUESTED = 'SUBMIT_RESET_PASSWORD_REQUESTED',
  SUBMIT_RESET_PASSWORD_SUCCESS = 'SUBMIT_RESET_PASSWORD_SUCCESS',
  SUBMIT_RESET_PASSWORD_FAILED = 'SUBMIT_RESET_PASSWORD_FAILED',
  SHOW_FORGOT_USERNAME = 'SHOW_FORGOT_USERNAME'
}

export const submitResetPasswordAction: ActionCreator<EapAction> = (data: {
  email: string;
  lastName: string;
  username: string;
}) => ({
  type: resetPasswordActionTypes.SUBMIT_RESET_PASSWORD_REQUESTED,
  data
});

export const submitResetPasswordSuccessAction: ActionCreator<EapAction> = () => ({
  type: resetPasswordActionTypes.SUBMIT_RESET_PASSWORD_SUCCESS
});

export const submitResetPasswordFailedAction: ActionCreator<EapAction> = () => ({
  type: resetPasswordActionTypes.SUBMIT_RESET_PASSWORD_FAILED
});

export const initResetPasswordAction: ActionCreator<EapAction> = () => ({
  type: resetPasswordActionTypes.INIT_RESET_PASSWORD
});

export const showForgotUsernameAction: ActionCreator<EapAction> = () => ({
  type: resetPasswordActionTypes.SHOW_FORGOT_USERNAME
});
