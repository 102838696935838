import { ActionCreator } from 'redux';
import { EapAction } from 'types';
import {
  batchProcessingTabIds,
  IReviewImports,
  IReviewImportErrors,
  uploadError,
  reviewErrorStatus,
  ISubmittedBatchData,
  IDataToBatchSubmit,
  formTypes,
  IBatchFilter
} from '../types';

export enum batchProcessingActionTypes {
  DOWNLOAD_FILE_BUILDER_REQUESTED = 'DOWNLOAD_FILE_BUILDER_REQUESTED',
  DOWNLOAD_FILE_BUILDER_SUCCEEDED = 'DOWNLOAD_FILE_BUILDER_SUCCEEDED',
  DOWNLOAD_FILE_BUILDER_FAILED = 'DOWNLOAD_FILE_BUILDER_FAILED',
  SET_BATCH_PROCESSING_ACTIVE_TAB = 'SET_BATCH_PROCESSING_ACTIVE_TAB',
  GET_IMPORTS_REQUESTED = 'GET_IMPORTS_REQUESTED',
  GET_IMPORTS_SUCCEEDED = 'GET_IMPORTS_SUCCEEDED',
  GET_IMPORTS_FAILED = 'GET_IMPORTS_FAILED',
  SET_SELECT_FILE_TYPE = 'SET_SELECT_FILE_TYPE',
  ADD_FILE_TO_BATCH_PROCESS_PENDING_UPLOAD = 'ADD_FILE_TO_BATCH_PROCESS_PENDING_UPLOAD',
  CLEAR_FILE_PENDING_UPLOAD = 'CLEAR_FILE_PENDING_UPLOAD',
  SET_RECONCILIATION_YEARS = 'SET_RECONCILIATION_YEARS',
  CLEAR_RECONCILIATION_YEARS = 'CLEAR_RECONCILIATION_YEARS',
  SET_HAS_RECONCILIATION_YEARS = 'SET_HAS_RECONCILIATION_YEARS',
  GET_RECONCILIATION_YEARS_FOR_GROUP_REQUESTED = 'GET_RECONCILIATION_YEARS_FOR_GROUP_REQUESTED',
  GET_RECONCILIATION_YEARS_FOR_GROUP_SUCCEEDED = 'GET_RECONCILIATION_YEARS_FOR_GROUP_SUCCEEDED',
  GET_RECONCILIATION_YEARS_FOR_GROUP_FAILED = 'GET_RECONCILIATION_YEARS_FOR_GROUP_FAILED',
  SET_DISPLAY_FILE_INPUT = 'SET_DISPLAY_FILE_INPUT',
  SET_IS_CONFIRM_IMPORT_MODAL = 'SET_IS_CONFIRM_IMPORT_MODAL',
  BATCH_PROCESS_UPLOAD_REQUESTED = 'BATCH_PROCESS_UPLOAD_REQUESTED',
  BATCH_PROCESS_UPLOAD_SUCCEEDED = 'BATCH_PROCESS_UPLOAD_SUCCEEDED',
  BATCH_PROCESS_UPLOAD_FAILED = 'BATCH_PROCESS_UPLOAD_FAILED',
  SET_IMPORT_ERROR = 'SET_IMPORT_ERROR',
  SET_IS_SUCCESSFUL_IMPORT_MODAL = 'SET_IS_SUCCESSFUL_IMPORT_MODAL',
  GET_IMPORT_ERRORS_REQUESTED = 'GET_IMPORT_ERRORS_REQUESTED',
  GET_IMPORT_ERRORS_SUCCEEDED = 'GET_IMPORT_ERRORS_SUCCEEDED',
  GET_IMPORT_ERRORS_FAILED = 'GET_IMPORT_ERRORS_FAILED',
  GO_BACK_TO_VIEW_IMPORTS = 'GO_BACK_TO_VIEW_IMPORTS',
  COMPLETE_REVIEW_IMPORT_ERRORS_REQUESTED = 'COMPLETE_REVIEW_IMPORT_ERRORS_REQUESTED',
  COMPLETE_REVIEW_IMPORT_ERRORS_SUCCEEDED = 'COMPLETE_REVIEW_IMPORT_ERRORS_SUCCEEDED',
  COMPLETE_REVIEW_IMPORT_ERRORS_FAILED = 'COMPLETE_REVIEW_IMPORT_ERRORS_FAILED',
  CANCEL_IMPORTS_REQUESTED = 'CANCEL_IMPORTS_REQUESTED',
  CANCEL_IMPORTS_SUCCEEDED = 'CANCEL_IMPORTS_SUCCEEDED',
  CANCEL_IMPORTS_FAILED = 'CANCEL_IMPORTS_FAILED',
  SHOW_CANCEL_IMPORTS_MODAL = 'SHOW_CANCEL_IMPORTS_MODAL',
  SHOW_CANCEL_IMPORTS_SUCCEEDED_NOTIFICATION = 'SHOW_CANCEL_IMPORTS_SUCCEEDED_NOTIFICATION',
  BATCH_SUBMIT_REQUESTED = 'BATCH_SUBMIT_REQUESTED',
  BATCH_SUBMIT_SUCCEEDED = 'BATCH_SUBMIT_SUCCEEDED',
  BATCH_SUBMIT_FAILED = 'BATCH_SUBMIT_FAILED',
  SET_BATCH_SUBMIT_FORM_119_RETRO_MODAL = 'SET_BATCH_SUBMIT_FORM_119_RETRO_MODAL',
  SET_BATCH_SUBMIT_CONFIRMATION_MODAL = 'SET_BATCH_SUBMIT_CONFIRMATION_MODAL',
  SET_BATCH_SUBMITTED_MODAL = 'SET_BATCH_SUBMITTED_MODAL',
  SET_FILING_TYPE_TO_BE_SUBMITTED = 'SET_FILING_TYPE_TO_BE_SUBMITTED',
  REVIEW_SUBMISSION_REQUESTED = 'REVIEW_SUBMISSION_REQUESTED',
  REVIEW_SUBMISSION_SUCCEEDED = 'REVIEW_SUBMISSION_SUCCEEDED',
  REVIEW_SUBMISSION_FAILED = 'REVIEW_SUBMISSION_FAILED',
  GO_TO_EXCEPTIONS_TAB = 'GO_TO_EXCEPTIONS_TAB',
  GET_DATA_TO_BATCH_SUBMIT_REQUESTED = 'GET_DATA_TO_BATCH_SUBMIT_REQUESTED',
  GET_DATA_TO_BATCH_SUBMIT_SUCCEEDED = 'GET_DATA_TO_BATCH_SUBMIT_SUCCEEDED',
  GET_DATA_TO_BATCH_SUBMIT_FAILED = 'GET_DATA_TO_BATCH_SUBMIT_FAILED',
  SET_SHOW_FILTERED_BATCH_TABLE = 'SET_SHOW_FILTERED_BATCH_TABLE',
  SET_SELECTED_FILTERED_FILING_TYPE = 'SET_SELECTED_FILTERED_FILING_TYPE',
  ADD_BATCH_PROCESSING_REQUEST_ID = 'ADD_BATCH_PROCESSING_REQUEST_ID',
  REMOVE_BATCH_PROCESSING_REQUEST_ID = 'REMOVE_BATCH_PROCESSING_REQUEST_ID',
  SEARCH_BATCH_IDS_REQUESTED = 'SEARCH_BATCH_IDS_REQUESTED',
  SEARCH_BATCH_IDS_SUCCEEDED = 'SEARCH_BATCH_IDS_SUCCEEDED',
  SEARCH_BATCH_IDS_FAILED = 'SEARCH_BATCH_IDS_FAILED',
  SET_BATCH_ID_SEARCH_RESULTS = 'SET_BATCH_ID_SEARCH_RESULTS',
  RESET_BATCH_ID_SEARCH_RESULTS = 'RESET_BATCH_ID_SEARCH_RESULTS',
  SET_SELECTED_BATCH_ID = 'SET_SELECTED_BATCH_ID',
  CONFIGURE_SELECTED_BATCH_ID = 'CONFIGURE_SELECTED_BATCH_ID',
  SELECT_ALL_BATCH_IDS = 'SELECT_ALL_BATCH_IDS',
  SET_BATCH_PROCESSING_FILTER = 'SET_BATCH_PROCESSING_FILTER',
  RESET_BATCH_PROCESSING_FILTER = 'RESET_BATCH_PROCESSING_FILTER',
  SET_APPLY_BUTTON_IS_DISABLED = 'SET_APPLY_BUTTON_IS_DISABLED',
  CHECK_BATCH_SUBMISSION_PRE_PROCESSING_STATUS = 'CHECK_BATCH_SUBMISSION_PRE_PROCESSING_STATUS'
}

export const downloadFileBuilderAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.DOWNLOAD_FILE_BUILDER_REQUESTED
});

export const downloadFileBuilderSucceededAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.DOWNLOAD_FILE_BUILDER_SUCCEEDED
});

export const downloadFileBuilderFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.DOWNLOAD_FILE_BUILDER_FAILED
});

export const setBatchProcessingActiveTab: ActionCreator<EapAction> = (
  data: batchProcessingTabIds
) => ({
  type: batchProcessingActionTypes.SET_BATCH_PROCESSING_ACTIVE_TAB,
  data
});

export const setSelectFileTypeAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.SET_SELECT_FILE_TYPE,
  data
});

export const getImportsRequestedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GET_IMPORTS_REQUESTED
});

export const getImportsSucceededAction: ActionCreator<EapAction> = (
  data: IReviewImports[]
) => ({
  type: batchProcessingActionTypes.GET_IMPORTS_SUCCEEDED,
  data
});

export const getImportsFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GET_IMPORTS_FAILED
});

export const addFileToBatchProcessPendingUploadAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.ADD_FILE_TO_BATCH_PROCESS_PENDING_UPLOAD,
  data
});

export const clearFilePendingUploadAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.CLEAR_FILE_PENDING_UPLOAD
});

export const setReconcilationYearsAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.SET_RECONCILIATION_YEARS,
  data
});
export const setHasReconcilationYearsAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.SET_HAS_RECONCILIATION_YEARS,
  data
});
export const clearReconcilationYearsAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.CLEAR_RECONCILIATION_YEARS
});
export const getReconcilationYearsForGroupAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GET_RECONCILIATION_YEARS_FOR_GROUP_REQUESTED
});
export const getReconcilationYearsForGroupSucceededAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.GET_RECONCILIATION_YEARS_FOR_GROUP_SUCCEEDED,
  data
});
export const getReconcilationYearsForGroupFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GET_RECONCILIATION_YEARS_FOR_GROUP_FAILED
});
export const setDisplayFileInputAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.SET_DISPLAY_FILE_INPUT,
  data
});
export const setIsConfirmImportModalAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.SET_IS_CONFIRM_IMPORT_MODAL,
  data
});
export const setIsSuccessfulImportModalAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.SET_IS_SUCCESSFUL_IMPORT_MODAL,
  data
});
export const batchProcessUploadRequestedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.BATCH_PROCESS_UPLOAD_REQUESTED
});
export const batchProcessUploadRequestedSucceededAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.BATCH_PROCESS_UPLOAD_SUCCEEDED
});
export const batchProcessUploadRequestedFailedAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: batchProcessingActionTypes.BATCH_PROCESS_UPLOAD_FAILED,
  data
});
export const setImportErrorAction: ActionCreator<EapAction> = (
  data: uploadError
) => ({
  type: batchProcessingActionTypes.SET_IMPORT_ERROR,
  data
});

export const getReviewImportErrorsRequestedAction: ActionCreator<EapAction> = (data: {
  batchProcessNo: string;
  currentForm: formTypes | null;
}) => ({
  type: batchProcessingActionTypes.GET_IMPORT_ERRORS_REQUESTED,
  data
});

export const getReviewImportErrorsSucceededAction: ActionCreator<EapAction> = (data: {
  errors: IReviewImportErrors[];
  reviewStatus: reviewErrorStatus;
  showErrorsMaxNumberWarning: boolean;
  hasAlreadyBeenImported: boolean;
  importErrors: any;
}) => ({
  type: batchProcessingActionTypes.GET_IMPORT_ERRORS_SUCCEEDED,
  data
});

export const getReviewImportErrorsFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GET_IMPORT_ERRORS_FAILED
});

export const goBackToViewImportsAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GO_BACK_TO_VIEW_IMPORTS
});

export const completeReviewImportErrorsRequestedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.COMPLETE_REVIEW_IMPORT_ERRORS_REQUESTED
});

export const completeReviewImportErrorsSucceededAction: ActionCreator<EapAction> = data => ({
  type: batchProcessingActionTypes.COMPLETE_REVIEW_IMPORT_ERRORS_SUCCEEDED,
  data
});

export const completeReviewImportErrorsFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.COMPLETE_REVIEW_IMPORT_ERRORS_FAILED
});

export const showCancelImportsModalAction: ActionCreator<EapAction> = (data: {
  currentBatchProcessNo: string;
  showCancelImportsModal: boolean;
  currentForm: formTypes | null;
}) => ({
  type: batchProcessingActionTypes.SHOW_CANCEL_IMPORTS_MODAL,
  data
});

export const cancelImportsRequestedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.CANCEL_IMPORTS_REQUESTED
});

export const cancelImportsSucceededAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.CANCEL_IMPORTS_SUCCEEDED
});

export const cancelImportsFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.CANCEL_IMPORTS_FAILED
});

export const isCancelImportsSucceededAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.SHOW_CANCEL_IMPORTS_SUCCEEDED_NOTIFICATION
});

export const batchSubmitAction: ActionCreator<EapAction> = (
  data: IDataToBatchSubmit
) => ({
  type: batchProcessingActionTypes.BATCH_SUBMIT_REQUESTED,
  data
});
export const batchSubmitSucceededAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.BATCH_SUBMIT_SUCCEEDED
});
export const batchSubmitFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.BATCH_SUBMIT_FAILED
});
export const setBatchSubmitForm119RetroModalAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchProcessingActionTypes.SET_BATCH_SUBMIT_FORM_119_RETRO_MODAL,
  data
});
export const setBatchSubmitConfirmationModalAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchProcessingActionTypes.SET_BATCH_SUBMIT_CONFIRMATION_MODAL,
  data
});
export const setBatchSubmittedModalAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchProcessingActionTypes.SET_BATCH_SUBMITTED_MODAL,
  data
});
export const setFilingTypeToBeSubmittedAction: ActionCreator<EapAction> = (
  data: formTypes
) => ({
  type: batchProcessingActionTypes.SET_FILING_TYPE_TO_BE_SUBMITTED,
  data
});

export const reviewSubmissionRequestedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.REVIEW_SUBMISSION_REQUESTED
});

export const reviewSubmissionSucceededAction: ActionCreator<EapAction> = (
  data: ISubmittedBatchData[]
) => ({
  type: batchProcessingActionTypes.REVIEW_SUBMISSION_SUCCEEDED,
  data
});

export const reviewSubmissionFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.REVIEW_SUBMISSION_FAILED
});

export const goToExceptionsTabAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GO_TO_EXCEPTIONS_TAB
});

export const getDataToBatchSubmitAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GET_DATA_TO_BATCH_SUBMIT_REQUESTED
});

export const getDataToBatchSubmitSucceededAction: ActionCreator<EapAction> = (
  data: IDataToBatchSubmit[]
) => ({
  type: batchProcessingActionTypes.GET_DATA_TO_BATCH_SUBMIT_SUCCEEDED,
  data
});

export const getDataToBatchSubmitFailedAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.GET_DATA_TO_BATCH_SUBMIT_FAILED
});
export const setShowFilteredBatchTable: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchProcessingActionTypes.SET_SHOW_FILTERED_BATCH_TABLE,
  data
});

export const setSelectedFilteredFilingType: ActionCreator<EapAction> = (
  data: formTypes
) => ({
  type: batchProcessingActionTypes.SET_SELECTED_FILTERED_FILING_TYPE,
  data
});

export const addBatchProcessingRequestId = (data: string) => ({
  type: batchProcessingActionTypes.ADD_BATCH_PROCESSING_REQUEST_ID,
  data
});

export const removeBatchProcessingRequestId = (data: string) => ({
  type: batchProcessingActionTypes.REMOVE_BATCH_PROCESSING_REQUEST_ID,
  data
});
export const searchBatchIdsRequestedAction = (data?: any) => ({
  type: batchProcessingActionTypes.SEARCH_BATCH_IDS_REQUESTED,
  data
});
export const searchBatchIdsSucceededAction = () => ({
  type: batchProcessingActionTypes.SEARCH_BATCH_IDS_SUCCEEDED
});
export const searchBatchIdsFailedAction = () => ({
  type: batchProcessingActionTypes.SEARCH_BATCH_IDS_FAILED
});

export const setBatchIdSearchResultsAction = (data: any) => ({
  type: batchProcessingActionTypes.SET_BATCH_ID_SEARCH_RESULTS,
  data
});

export const resetBatchIdSearchResultsAction = () => ({
  type: batchProcessingActionTypes.RESET_BATCH_ID_SEARCH_RESULTS
});

export const setSelectedBatchIdAction = (data: any) => ({
  type: batchProcessingActionTypes.SET_SELECTED_BATCH_ID,
  data
});

export const configureSelectedBatchIdAction = (data: any) => ({
  type: batchProcessingActionTypes.CONFIGURE_SELECTED_BATCH_ID,
  data
});

export const selectAllBatchIdsAction = () => ({
  type: batchProcessingActionTypes.SELECT_ALL_BATCH_IDS
});

export const setBatchProcessingFilterAction: ActionCreator<EapAction> = (
  data: IBatchFilter
) => ({
  type: batchProcessingActionTypes.SET_BATCH_PROCESSING_FILTER,
  data
});

export const resetBatchProcessingFilterAction: ActionCreator<EapAction> = () => ({
  type: batchProcessingActionTypes.RESET_BATCH_PROCESSING_FILTER
});

export const setApplyButtonIsDisabledAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchProcessingActionTypes.SET_APPLY_BUTTON_IS_DISABLED,
  data
});
export const updatePreProcessingBatchSubmissionStatusAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchProcessingActionTypes.CHECK_BATCH_SUBMISSION_PRE_PROCESSING_STATUS,
  data
});
