import { EapAction, EmployerGroup } from 'types';
import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import { userSessionActionTypes } from './userSessionActions';

export interface UserSessionState {
  loading: boolean;
  logoutNotification: string | null;
  isAuthenticated: boolean;
  showNav: boolean;
  userData: {
    username: string;
    firstName: string;
    lastName: string;
    middleName: string;
    groups: Array<EmployerGroup>;
    roles: any;
    omersUser: boolean;
    employeeType: string;
    status: string;
    email: string;
    lastLoginDate: string;
    training: boolean;
  };
  userTimer: {
    isSessionRefreshModalVisible: boolean;
    isTokenExpiryTimerVisible: boolean;
    sessionExpiry: number | null;
  };
  token: string;
  showEmailNotificationRow: boolean;
  showEmailNotificationModal: boolean;
  showEmailNotificationSpinner: boolean;
  eCorrespondenceOptOut: boolean;
  isPasswordExpired: boolean;
  usernameForResetExpiredPassword: string;
  sysDate: any;
}

const initialState = {
  loading: false,
  logoutNotification: null,
  isAuthenticated: false,
  showNav: false,
  userData: {
    username: '',
    firstName: '',
    lastName: '',
    middleName: '',
    groups: [],
    roles: null,
    omersUser: false,
    employeeType: '',
    status: '',
    email: '',
    lastLoginDate: '',
    training: false
  },
  userTimer: {
    isSessionRefreshModalVisible: false,
    isTokenExpiryTimerVisible: false,
    sessionExpiry: null
  },
  token: '',
  showEmailNotificationRow: true,
  showEmailNotificationModal: false,
  showEmailNotificationSpinner: false,
  eCorrespondenceOptOut: false,
  isPasswordExpired: false,
  usernameForResetExpiredPassword: '',
  sysDate: ''
};

const userSession = (
  state: UserSessionState = initialState,
  action: EapAction
): UserSessionState => {
  const { type, data } = action;
  switch (type) {
    case userSessionActionTypes.START_LOADING:
    case userSessionActionTypes.LOGIN_REQUESTED:
    case userSessionActionTypes.LOGOUT_REQUESTED:
      return {
        ...state,
        loading: true
      };
    case userSessionActionTypes.LOGIN_SUCCEEDED:
      return {
        ...state,
        isAuthenticated: true,
        token: data.accessToken,
        userData: data.userProfile,
        loading: false
      };
    case userSessionActionTypes.LOGIN_FAILED:
    case userSessionActionTypes.CLEAR_LOADING:
      return {
        ...state,
        loading: false
      };
    case userSessionActionTypes.LOGOUT_SUCCEEDED_FAILED:
      return {
        ...initialState,
        loading: false,
        logoutNotification: data.logoutNotification
      };
    case userSessionActionTypes.LOGOUT_NOTIFICATION_HIDE:
      return {
        ...state,
        loading: false,
        logoutNotification: null
      };
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
    case selectEmployersActionTypes.SELECT_GROUP_NUMBER_SUCCESS:
      return {
        ...state,
        showNav: true
      };
    case userSessionActionTypes.SET_SHOW_EMAIL_NOTIFICATION_ROW:
      return {
        ...state,
        showEmailNotificationRow: data
      };
    case userSessionActionTypes.SET_SHOW_EMAIL_NOTIFICATION_MODAL:
      return {
        ...state,
        showEmailNotificationModal: data
      };
    case userSessionActionTypes.SET_SHOW_EMAIL_NOTIFICATION_SPINNER:
      return {
        ...state,
        showEmailNotificationSpinner: data
      };
    case userSessionActionTypes.SET_E_CORRESPONDENCE_OPT_OUT:
      return {
        ...state,
        eCorrespondenceOptOut: data
      };
    case userSessionActionTypes.SET_PASSWORD_EXPIRED_FLAG:
      return {
        ...state,
        isPasswordExpired: data
      };
    case userSessionActionTypes.SET_USERNAME_FOR_RESET_EXPIRED_PASSWORD:
      return {
        ...state,
        usernameForResetExpiredPassword: data
      };
    case userSessionActionTypes.SET_SYS_DATE:
      return {
        ...state,
        sysDate: data
      };
    case userSessionActionTypes.SHOW_SESSION_REFRESH_MODAL:
      return {
        ...state,
        userTimer: {
          ...state.userTimer,
          isSessionRefreshModalVisible: true
        }
      };
    case userSessionActionTypes.HIDE_SESSION_REFRESH_MODAL:
      return {
        ...state,
        userTimer: {
          ...state.userTimer,
          isSessionRefreshModalVisible: false
        }
      };
    case userSessionActionTypes.SET_TOKEN_EXPIRY_TIMER_VISIBILITY:
      return {
        ...state,
        userTimer: {
          ...state.userTimer,
          isTokenExpiryTimerVisible: data
        }
      };
    case userSessionActionTypes.SET_SESSION_EXPIRY:
      return {
        ...state,
        userTimer: {
          ...state.userTimer,
          sessionExpiry: data
        }
      };
    default:
      return state;
  }
};

export default userSession;
