import { SelectMenuItem } from 'components/v2/atomic/selectInput/SelectInput';
import { addMinutes, isPast, parseISO } from 'date-fns';
import { last } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import clientSide from 'utils/logger/client-side';
import { Appointment } from './interfaces';

enum BookingDuration {
  HALF_HOUR = '30',
  HOUR = '60'
}

export const PostBookingSurvey = ({
  appointments,
  staffMembersList
}: {
  appointments: Appointment[];
  staffMembersList: SelectMenuItem<string>[];
}) => {
  const { userData } = useSelector((state: RootState) => state.userSession);
  const { selectGroupNumber } = useSelector(
    (state: RootState) => state.selectEmployers
  );
  useEffect(() => {
    if (appointments.length === 0 || staffMembersList.length === 0) {
      return;
    }

    // This presumes that the list-appointments API endpoints returns appointments in ascending order.
    // We're filtering for the latest appointment that has already passed based on appointment
    // startTime and duration
    const surveyAppointment = last(
      appointments.filter(appointment =>
        isPast(
          addMinutes(
            parseISO(appointment.time),
            appointment.duration === BookingDuration.HALF_HOUR ? 30 : 60
          )
        )
      )
    );

    const staffMember = staffMembersList.find(
      member => member.key === surveyAppointment?.staffID[0]
    );

    if (staffMember) {
      if (!userData.username) {
        clientSide.warn(
          'No username defined for this user when attempting to submit sprig survey',
          { ddattr: 'sprigNoUser', employerGroup: selectGroupNumber }
        );
      }

      Sprig('identifyAndTrack', {
        eventName: `Employer Experience Meeting Complete - ${staffMember.value}`,
        userId: userData.username,
        properties: { bookingDate: surveyAppointment?.time }
      });
    }
  }, [appointments, selectGroupNumber, staffMembersList, userData.username]);

  return null;
};
