import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import {
  BrokenService,
  PurchaseCostItem,
  MemberSearchResult,
  ExceptionsObject
} from 'interfaces';
import { Form165bData } from './form165bReducer';

export enum form165bActionTypes {
  SET_REQUEST_NUMBER_FORM_165B = 'SET_REQUEST_NUMBER_FORM_165B',
  CREATE_FORM_165B = 'CREATE_FORM_165B',
  GET_FORM_165B = 'GET_FORM_165B',
  GET_FORM_165B_SUCCESS = 'GET_FORM_165B_SUCCESS',
  GET_FORM_165B_FAILED = 'GET_FORM_165B_FAILED',
  GET_LATEST_FORM_165B_SUCCESS = 'GET_LATEST_FORM_165B_SUCCESS',
  UNLOCK_FORM_165B = 'UNLOCK_FORM_165B',
  SET_FORM_165B_STEP = 'SET_FORM_165B_STEP',
  DELETE_FORM_165B = 'DELETE_FORM_165B',
  DELETE_FORM_165B_SUCCESS = 'DELETE_FORM_165B_SUCCESS',
  DELETE_FORM_165B_FAILED = 'DELETE_FORM_165B_FAILED',
  ELECT_LEAVE_PERIOD_FORM_165B = 'ELECT_LEAVE_PERIOD_FORM_165B',
  REMOVE_LEAVE_PERIOD_FORM_165B = 'REMOVE_LEAVE_PERIOD_FORM_165B',
  SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B = 'SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B',
  SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_SUCCESS = 'SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_SUCCESS',
  SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_FAILED = 'SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_FAILED',
  EXIT_ELECT_LEAVE_PERIOD_FORM_165B = 'EXIT_ELECT_LEAVE_PERIOD_FORM_165B',
  SET_ELECT_LEAVE_PERIOD_STEP_FORM_165B = 'SET_ELECT_LEAVE_PERIOD_STEP_FORM_165B',
  UPDATE_LEAVE_PERIOD_FORM_165B = 'UPDATE_LEAVE_PERIOD_FORM_165B',
  UPDATE_LEAVE_PERIOD_FORM_165B_SUCCESS = 'UPDATE_LEAVE_PERIOD_FORM_165B_SUCCESS',
  UPDATE_LEAVE_PERIOD_FORM_165B_FAILED = 'UPDATE_LEAVE_PERIOD_FORM_165B_FAILED',
  SET_FORM_165B_SAVING_STATUS = 'SET_FORM_165B_SAVING_STATUS',
  SAVE_FORM_165B = 'SAVE_FORM_165B',
  SAVE_FORM_165B_SUCCESS = 'SAVE_FORM_165B_SUCCESS',
  SAVE_FORM_165B_FAILED = 'SAVE_FORM_165B_FAILED',
  CLEAR_FORM_165B_STATUS = 'CLEAR_FORM_165B_STATUS',
  SUBMIT_FORM_165B = 'SUBMIT_FORM_165B',
  SUBMIT_FORM_165B_SUCCESS = 'SUBMIT_FORM_165B_SUCCESS',
  OVERRIDE_FORM_165B_EXCEPTIONS = 'OVERRIDE_FORM_165B_EXCEPTIONS',
  SET_FORM_165B_SUBMIT_STATUS = 'SET_FORM_165B_SUBMIT_STATUS',
  GET_FORM_165B_PDF = 'GET_FORM_165B_PDF'
}

export const setForm165bRequestNumberAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165bActionTypes.SET_REQUEST_NUMBER_FORM_165B,
  data
});

export const createForm165bAction: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form165bActionTypes.CREATE_FORM_165B,
  data
});

export const getForm165bAction: ActionCreator<EapAction> = (data: string) => ({
  type: form165bActionTypes.GET_FORM_165B,
  data
});

export const getForm165bSuccessAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  memberInfo: MemberSearchResult;
  leavePeriods: Array<{
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
  }>;
  availableBrokenServices: Array<BrokenService>;
  commentsToMember: string;
  notes: string;
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form165bActionTypes.GET_FORM_165B_SUCCESS,
  data
});

export const getForm165bFailedAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.GET_FORM_165B_FAILED
});

export const getLatestForm165bSuccessAction: ActionCreator<EapAction> = (data: {
  leavePeriods: Array<{
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
  }>;
  availableBrokenServices: Array<BrokenService>;
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165bActionTypes.GET_LATEST_FORM_165B_SUCCESS,
  data
});

export const unlockForm165bAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165bActionTypes.UNLOCK_FORM_165B,
  data
});

export const setForm165bStepAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165bActionTypes.SET_FORM_165B_STEP,
  data
});

export const deleteForm165bAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165bActionTypes.DELETE_FORM_165B,
  data
});

export const deleteForm165bSuccessAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.DELETE_FORM_165B_SUCCESS
});

export const deleteForm165bFailedAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.DELETE_FORM_165B_FAILED
});

export const electLeavePeriodForm165bAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165bActionTypes.ELECT_LEAVE_PERIOD_FORM_165B,
  data
});

export const removeLeavePeriodForm165bAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165bActionTypes.REMOVE_LEAVE_PERIOD_FORM_165B,
  data
});

export const setEditElectLeavePeriodForm165bAction: ActionCreator<EapAction> = (
  data: number | null
) => ({
  type: form165bActionTypes.SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B,
  data
});

export const setEditElectLeavePeriodForm165bSuccessAction: ActionCreator<EapAction> = (data: {
  intervalNumber: number;
  electLeavePeriodStep: number;
  leavePeriod: {
    brokenService: BrokenService;
    purchaseCostItem: PurchaseCostItem;
  };
}) => ({
  type: form165bActionTypes.SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_SUCCESS,
  data
});

export const setEditElectLeavePeriodForm165bFailedAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_FAILED
});

export const exitElectLeavePeriodForm165bAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.EXIT_ELECT_LEAVE_PERIOD_FORM_165B
});

export const setElectLeavePeriodStepForm165bAction: ActionCreator<EapAction> = (
  data: number | null
) => ({
  type: form165bActionTypes.SET_ELECT_LEAVE_PERIOD_STEP_FORM_165B,
  data
});

export const updateLeavePeriod165bAction: ActionCreator<EapAction> = (data: {
  data: Form165bData;
  preventWizardUpdate?: boolean;
}) => ({
  type: form165bActionTypes.UPDATE_LEAVE_PERIOD_FORM_165B,
  data
});

export const updateLeavePeriod165bSuccessAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.UPDATE_LEAVE_PERIOD_FORM_165B_SUCCESS
});

export const updateLeavePeriod165bFailedAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.UPDATE_LEAVE_PERIOD_FORM_165B_FAILED
});

export const setForm165bSavingStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165bActionTypes.SET_FORM_165B_SAVING_STATUS,
  data
});

export const saveForm165bAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
  exceptions?: Array<ExceptionsObject>;
  reviewForm?: boolean;
  submitForm?: boolean;
}) => ({
  type: form165bActionTypes.SAVE_FORM_165B,
  data
});

export const saveForm165bSuccessAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165bActionTypes.SAVE_FORM_165B_SUCCESS,
  data
});

export const saveForm165bFailedAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.SAVE_FORM_165B_FAILED
});

export const clearForm165bStatusAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.CLEAR_FORM_165B_STATUS
});

export const submitForm165bAction: ActionCreator<EapAction> = () => ({
  type: form165bActionTypes.SUBMIT_FORM_165B
});

export const submitForm165bSuccessAction: ActionCreator<EapAction> = (data: {
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form165bActionTypes.SUBMIT_FORM_165B_SUCCESS,
  data
});

export const overrideForm165bExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: form165bActionTypes.OVERRIDE_FORM_165B_EXCEPTIONS,
  data
});

export const setForm165bSubmitStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165bActionTypes.SET_FORM_165B_SUBMIT_STATUS,
  data
});

export const getForm165bPDFAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  formNo: string;
  employerNo: string;
  isView: boolean;
}) => ({
  type: form165bActionTypes.GET_FORM_165B_PDF,
  data
});
