import {
  Children,
  cloneElement,
  isValidElement,
  FocusEventHandler
} from 'react';

import {
  Classes,
  FormGroup,
  FormGroupProps,
  RadioGroup as RadioGroupBP,
  RadioGroupProps as RadioGroupBPProps
} from '@blueprintjs/core';
import { ThemeUIStyleObject } from 'theme-ui';

const radioButtonGroupStyles = (
  isHorizontal: boolean,
  hasError: boolean,
  disableMarginBottom: boolean
) => ({
  [`&.${Classes.FORM_GROUP} .${Classes.LABEL}`]: {
    mb: '5px',
    fontFamily: 'body',
    fontSize: 'small',
    lineHeight: 'base',
    fontWeight: 'bold',
    color: 'darkGreyText'
  },
  [`&.${Classes.FORM_GROUP} .${Classes.FORM_CONTENT}`]: {
    mt: '3px',
    '> div': {
      display: isHorizontal ? 'flex' : 'block',
      '> label:not(last-of-type)': {
        mr: '20px'
      }
    },
    [`.${Classes.RADIO}.${Classes.CONTROL}`]: {
      mb: !isHorizontal ? '24px' : 0
    },
    [`.${Classes.RADIO}.${Classes.CONTROL}:last-of-type`]: {
      mb: !isHorizontal ? '5px' : 0
    }
  },
  [`&.${Classes.FORM_GROUP} .${Classes.FORM_HELPER_TEXT}`]: {
    fontFamily: 'body',
    fontSize: 'small',
    fontWeight: 'body',
    lineHeight: 'base',
    mt: '8px',
    color: hasError ? 'extendedRed100' : undefined
  },
  [`&.${Classes.FORM_GROUP}`]: {
    mb: disableMarginBottom ? 0 : undefined
  }
});

export interface RadioButtonGroupProps
  extends FormGroupProps,
    RadioGroupBPProps {
  id: string;
  label?: string;
  error?: string;
  name?: string;
  hideErrorMessage?: boolean;
  horizontal?: boolean;
  disableMarginBottom?: boolean;
  disableLabelWordWrap?: boolean;
  onBlur?: FocusEventHandler<HTMLButtonElement>;
  readOnly?: boolean;
  'data-testid'?: string;
  sxFormGroup?: ThemeUIStyleObject;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = ({
  children,
  id,
  label,
  name,
  error,
  hideErrorMessage = false,
  horizontal = false,
  disableMarginBottom = false,
  disableLabelWordWrap = false,
  onChange,
  onBlur,
  sxFormGroup,
  ...props
}) => (
  <FormGroup
    label={label}
    labelFor={id}
    helperText={(!hideErrorMessage && error) ?? undefined}
    intent={error ? 'danger' : undefined}
    sx={{
      ...radioButtonGroupStyles(horizontal, !!error, disableMarginBottom),
      [`.${Classes.LABEL}`]: {
        whiteSpace: disableLabelWordWrap ? 'nowrap' : undefined
      },
      ...sxFormGroup
    }}
  >
    <RadioGroupBP {...props} name={name} onChange={onChange}>
      {Children.map(children, (child, index) => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            // @ts-ignore
            name: id,
            checked: child.props.value === props.selectedValue,
            disabled: child.props.disabled ?? props.disabled,
            readOnly: child.props.readOnly ?? props.readOnly,
            error,
            isHorizontalGroup: horizontal,
            noMargin: horizontal,
            onChange,
            onBlur,
            'data-testid': props['data-testid']
              ? `${props['data-testid']}-${index}`
              : null,
            'aria-invalid': !!error
          });
        }
        return child;
      })}
    </RadioGroupBP>
  </FormGroup>
);

export default RadioButtonGroup;
