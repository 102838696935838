import { useEffect, useState, ReactNode } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'types';
import useRole, { RoleTypes } from 'utils/hooks/useRole';
import {
  isAuthenticated,
  selectEmployerNumber,
  isTrainingUser
} from 'utils/redux/selectors';
import Spinner from 'components/spinner/Spinner';
import { clearNotifications } from 'containers/notifications/notificationsActions';
import { clearIsRedirected } from 'containers/memberSearch/memberSearchActions';
import { isEnabled, currentFlags } from 'features/featureFlags/featureFlags';
import { useMaintenanceFlag } from 'features/maintenanceFlags/useMaintenanceFlag';
import { logoutForcedAction } from './userSessionActions';

export const NON_PROTECTED_ROUTES = [
  '/',
  '/help',
  '/security',
  '/reset-password',
  '/update-password'
];
export const NON_FINANCIAL_BLACKLIST_ROUTES = [
  '/form/105',
  '/form/119',
  '/form/143',
  '/form/143d',
  '/form/165a',
  '/form/165b',
  '/form/165c',
  '/form/190',
  '/form/152/[id]',
  '/messages',
  '/messages/new',
  '/messages/[id]',
  '/batch-processing',
  '/returned-requests',
  '/annual-dashboard',
  '/annual-dashboard/[id]',
  '/batch-print',
  '/data-extract',
  '/reports'
];

export const SMO_ONLY_BLACKLIST_ROUTES = [
  '/form/102',
  '/form/105',
  '/form/106',
  '/form/119',
  '/form/143',
  '/form/143d',
  '/form/165a',
  '/form/165b',
  '/form/165c',
  '/form/190',
  '/form/152/[id]',
  '/batch-processing',
  '/returned-requests',
  '/annual-dashboard',
  '/annual-dashboard/[id]',
  '/batch-print',
  '/data-extract',
  '/requests',
  '/nft',
  '/nft/new',
  '/nft/batchUpload',
  '/nft/[id]',
  '/members',
  '/archived-messages'
];

const SMO_NON_FINANCIAL_BLACKLISTED_ROUTES = NON_FINANCIAL_BLACKLIST_ROUTES.filter(
  route =>
    route !== '/messages' &&
    route !== '/messages/new' &&
    route !== '/messages/[id]' &&
    route !== '/form/125/[id]' &&
    route !== '/reports'
);

export const NON_SMO_BLACKLIST_ROUTES = [
  '/smo/employer-attestation',
  '/smo/review-user-list'
];

const RouteProtectorContainer = ({ children }: { children: ReactNode }) => {
  const Router = useRouter();
  const dispatch = useDispatch();
  const isAuthenticatedUser = useSelector(isAuthenticated);
  const selectEmployerNumberSelector = useSelector(selectEmployerNumber);
  const isTrainingUserSelector = useSelector(isTrainingUser);
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const { isNonFinancial, isSMO, isFinancial } = useRole();
  const isStaticContent = Router.pathname.startsWith('/content');
  const isNonProtected =
    NON_PROTECTED_ROUTES.includes(Router.pathname) || isStaticContent;
  const [isBlacklisted, setIsBlacklisted] = useState(false);
  const [renderRoute, setRenderRoute] = useState(false);
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );
  const { groups } = useSelector((state: RootState) => state.selectEmployers);
  const isLoginInMaintenance = useMaintenanceFlag('login');

  useEffect(() => {
    if (
      isLoginInMaintenance &&
      !omersUser &&
      Router.pathname !== '/' &&
      !isStaticContent
    ) {
      setRenderRoute(false);
      if (isAuthenticatedUser) {
        dispatch(logoutForcedAction());
      }
      Router.push('/');
    }
  }, [
    isLoginInMaintenance,
    omersUser,
    Router,
    dispatch,
    isStaticContent,
    isAuthenticatedUser
  ]);

  useEffect(() => {
    setIsBlacklisted(
      (isNonFinancial &&
        (!isSMO || !isEnabled(featureFlags, currentFlags.SMO_ATTESTATION)) &&
        NON_FINANCIAL_BLACKLIST_ROUTES.includes(Router.pathname)) ||
        (isNonFinancial &&
          isSMO &&
          isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
          SMO_NON_FINANCIAL_BLACKLISTED_ROUTES.includes(Router.pathname)) ||
        (!isSMO && NON_SMO_BLACKLIST_ROUTES.includes(Router.pathname)) ||
        (isSMO &&
          !isFinancial &&
          !isNonFinancial &&
          isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
          SMO_ONLY_BLACKLIST_ROUTES.includes(Router.pathname))
    );
  }, [isNonFinancial, isFinancial, isSMO, Router.pathname, featureFlags]);

  useEffect(() => {
    // non-authenticated route protector
    if (!isAuthenticatedUser) {
      if (
        isEnabled(featureFlags, currentFlags.STATIC_CONTENT) &&
        isStaticContent
      ) {
        setRenderRoute(true);
        return;
      }

      if (
        !isNonProtected ||
        (!isEnabled(featureFlags, currentFlags.STATIC_CONTENT) &&
          isStaticContent)
      ) {
        // reroute to login if path is protected
        setRenderRoute(false);
        Router.push('/');
        return;
      }
    }
    const isPublicPage =
      Router.pathname.startsWith('/content/') || Router.pathname === '/';

    // authenticated route protector
    if (isAuthenticatedUser) {
      if (
        selectEmployerNumberSelector === 0 &&
        Router.pathname !== '/select-employers' &&
        !isPublicPage &&
        Router.pathname !== '/help' &&
        !isTrainingUserSelector &&
        (groups?.length > 1 ||
          omersUser ||
          (isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
            groups[0]?.attestationRequired &&
            groups[0].role !== RoleTypes.SMO))
      ) {
        // reroute any non-'/select-employers' route with no active selected employer
        setRenderRoute(false);
        Router.push('/select-employers');
        return;
      }
      if (
        (!isEnabled(featureFlags, currentFlags.NEW_NAV_HEADER) &&
          Router.pathname === '/') ||
        isBlacklisted
      ) {
        // reroute login page to home or blacklisted routes to home
        setRenderRoute(false);
        Router.push('/home');
        return;
      }
      if (isTrainingUserSelector && Router.pathname !== '/learning-centre') {
        setRenderRoute(false);
        Router.push('/learning-centre');
        return;
      }
      if (!omersUser && Router.pathname === '/ms-bookings/employer-listing') {
        setRenderRoute(false);
        Router.push('/home');
        return;
      }
    }

    setRenderRoute(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticatedUser,
    selectEmployerNumberSelector,
    isNonProtected,
    isBlacklisted,
    Router.pathname
  ]);

  // feature flag re-route
  // Because form 143 form variants share the same url, feature flag route protection is handled in Form143Saga
  useEffect(() => {
    switch (Router.pathname) {
      case '/form/105': {
        if (!isEnabled(featureFlags, currentFlags.FORM_105)) {
          Router.push('/requests');
        }
        break;
      }
      case '/form/119': {
        if (!isEnabled(featureFlags, currentFlags.FORM_119)) {
          Router.push('/requests');
        }
        break;
      }
      case '/form/143/[id]/adj': {
        if (
          !isEnabled(featureFlags, currentFlags.FORM_143_ADJUSTMENTS_RECALL)
        ) {
          Router.push('/home');
        }
        break;
      }
      case '/form/165a': {
        if (!isEnabled(featureFlags, currentFlags.FORM_165A)) {
          Router.push('/requests');
        }
        break;
      }
      case '/form/165b': {
        if (!isEnabled(featureFlags, currentFlags.FORM_165B)) {
          Router.push('/requests');
        }
        break;
      }
      case '/form/165c': {
        if (!isEnabled(featureFlags, currentFlags.FORM_165C)) {
          Router.push('/requests');
        }
        break;
      }
      case '/form/190': {
        if (!isEnabled(featureFlags, currentFlags.FORM_190)) {
          Router.push('/requests');
        }
        break;
      }
      case '/form/152/[id]': {
        if (!isEnabled(featureFlags, currentFlags.FORM_152)) {
          Router.push('/home');
        }
        break;
      }
      case '/batch-processing': {
        if (!isEnabled(featureFlags, currentFlags.BATCH_PROCESSING)) {
          Router.push('/home');
        }
        break;
      }
      case '/returned-requests': {
        if (!isEnabled(featureFlags, currentFlags.RETURNED_REQUESTS)) {
          Router.push('/home');
        }
        break;
      }
      case '/batch-print': {
        if (!isEnabled(featureFlags, currentFlags.BATCH_PRINT)) {
          Router.push('/home');
        }
        break;
      }
      case '/data-extract': {
        if (!isEnabled(featureFlags, currentFlags.DATA_EXTRACT)) {
          Router.push('/home');
        }
        break;
      }
      case '/nft':
      case '/nft/new':
      case '/nft/batchUpload': {
        if (!isEnabled(featureFlags, currentFlags.NFT)) {
          Router.push('/home');
        }
        break;
      }
      case '/reports/stewardship/[id]': {
        if (!isEnabled(featureFlags, currentFlags.ENABLE_ESR)) {
          Router.push('/home');
        }
        break;
      }
      case '/smo/review-user-list':
      case '/smo/employer-attestation': {
        if (
          !isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) ||
          omersUser
        ) {
          Router.push('/home');
        }
        break;
      }
      // This is a temporary route for testing purposes
      case '/content/test-page': {
        if (!isEnabled(featureFlags, currentFlags.STATIC_CONTENT)) {
          Router.push('/home');
        }
        break;
      }
      case '/reports': {
        if (!isEnabled(featureFlags, currentFlags.REPORTS)) {
          Router.push('/home');
        }
        break;
      }
      case '/form/158/[id]': {
        if (!isEnabled(featureFlags, currentFlags.FORM_158_PRD)) {
          Router.push('/home');
        }
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags, Router.pathname]);
  // clear error notifications in store on new page load
  useEffect(() => {
    dispatch(clearNotifications());
    if (Router.pathname !== '/members') {
      dispatch(clearIsRedirected());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Router.pathname]);

  return renderRoute ? <>{children}</> : <Spinner size="large" />;
};

export default RouteProtectorContainer;
