import { EapAction } from 'types';
import { MemberSearchResult } from 'interfaces';
import { getSubmissionStatus, getCurrentEmploymentPeriodIndex106 } from 'utils';
import { FormState } from 'containers/requestForm/common/interfaces';
import { form106ActionTypes } from './form106Actions';

export interface Form106State extends FormState {
  originalForm106Data: any;
  form106Data: any;
  pssPartyId: string | null;
  memberInfo: MemberSearchResult | null;
  newFormCreated: boolean;
  currentPeriodIndex: number;
  periodStatus: string;
  originalForm106State: any;
}

const setDepartmentId = (index: number, value: string, periodsArr: any[]) => {
  let retArr;
  if (periodsArr[index]) {
    // eslint-disable-next-line no-param-reassign
    periodsArr[index].departmentId = value;
    retArr = [...periodsArr];
    return retArr;
  }
  return periodsArr;
};

const form106Data = {
  sin: '',
  employeeId: '',
  firstName: '',
  middleName: '',
  lastName: '',
  sex: '',
  birthDate: '',
  hireDate: '',
  electionDate: '',
  enrolDate: '',
  enrolReason: '',
  activeEffectiveFromDate: '',
  activeEffectiveToDate: '',
  activeNra: '',
  activeEmploymentStatus: '',
  activeOccupation: '',
  activeDepartmentId: '',
  activeMemberAffiliation: '',
  noteToOmers: '',
  employmentPeriods: [],
  lastPostedServicePeriodStartDate: '',
  lastPostedServicePeriodEndDate: '',
  newPeriodCreated: false
};

export const initialState = {
  requestNumber: null,
  initLoading: false,
  originalForm106Data: null,
  form106Data,
  formStep: 1,
  formStatus: '' as const,
  submissionStatus: '' as const,
  submissionComplete: false,
  pssPartyId: null,
  memberInfo: null,
  overrideExceptions: [],
  exceptions: [],
  newFormCreated: false,
  currentPeriodIndex: 0,
  periodStatus: '',
  originalForm106State: null
};

const form106 = (
  state: Form106State = initialState,
  action: EapAction
): Form106State => {
  const { type, data } = action;
  switch (type) {
    case form106ActionTypes.SET_REQUEST_NUMBER_FORM_106:
      return {
        ...initialState,
        requestNumber: data,
        initLoading: true
      };
    case form106ActionTypes.CREATE_FORM_106:
      return {
        ...state,
        newFormCreated: true
      };
    case form106ActionTypes.GET_ORIGINAL_FORM_STATE_106_SUCCESS:
      return {
        ...state,
        originalForm106State: data
      };
    case form106ActionTypes.GET_ORIGINAL_FORM_STATE_106_FAILED:
      return {
        ...state,
        originalForm106State: null
      };
    case form106ActionTypes.GET_FORM_106_SUCCEEDED:
      return {
        ...state,
        employerNo: data.employerNo,
        requestNumber: data.requestNumber,
        form106Data: {
          ...data.formData,
          enrolDate: data.formData.enrolDate,
          enrolReason: data.formData.enrolReason
        },
        originalForm106Data: data.formData,
        memberInfo: data.memberInfo,
        initLoading: false,
        formStep: 1,
        exceptions: data.exceptions,
        formStatus: '' as const,
        submissionStatus: '' as const,
        overrideExceptions: [],
        submissionComplete: false,
        currentPeriodIndex: getCurrentEmploymentPeriodIndex106(
          data.formData.employmentPeriods
        )
      };
    case form106ActionTypes.GET_FORM_106_FAILED:
      return {
        ...state,
        initLoading: true
      };
    case form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_SUCCEEDED:
      return {
        ...state,
        form106Data: { ...state.form106Data, newPeriodCreated: true }
      };
    case form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_FAILED:
      return {
        ...state,
        form106Data: { ...state.form106Data, newPeriodCreated: false }
      };
    case form106ActionTypes.SAVE_FORM_106_IN_STORE:
      return {
        ...state,
        form106Data: data
      };
    case form106ActionTypes.SAVE_FORM_106_REQUESTED:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form106ActionTypes.SAVE_FORM_106_SUCCEEDED:
      return {
        ...state,
        formStatus: 'success' as const,
        newFormCreated: false
      };
    case form106ActionTypes.SAVE_FORM_106_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form106ActionTypes.SET_FORM_106_STEP:
      return {
        ...state,
        formStep: data
      };
    case form106ActionTypes.CLEAR_FORM_106:
    case form106ActionTypes.CLEAR_106_WITHOUT_UNLOCK:
      return {
        ...initialState
      };
    case form106ActionTypes.SET_MEMBER_INFO_FORM_106:
      return {
        ...state,
        memberInfo: data
      };
    case form106ActionTypes.DELETE_FORM_106_SUCCEEDED:
      return {
        ...state,
        ...initialState
      };
    case form106ActionTypes.SET_FORM_106_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_SUCCEEDED:
      return {
        ...state,
        exceptions: !data.submitResponse.exceptions
          ? []
          : data.submitResponse.exceptions,
        submissionStatus: getSubmissionStatus(
          data.submitResponse,
          data.submitResponse.exceptions
        ),
        submissionComplete:
          getSubmissionStatus(
            data.submitResponse,
            data.submitResponse.exceptions
          ) === 'success',
        pssPartyId: data.submitResponse.pssPartyId,
        overrideExceptions: [],
        formStep:
          getSubmissionStatus(
            data.submitResponse,
            data.submitResponse.exceptions
          ) === 'success'
            ? 3
            : 2,
        initLoading: false,
        pdfs: data.submitResponse.attachments
      };
    case form106ActionTypes.OVERRIDE_FORM_106_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form106ActionTypes.RESET_FORM_106_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: '' as const
      };
    case form106ActionTypes.SET_FORM_106_STATUS:
      return {
        ...state,
        formStatus: data
      };
    case form106ActionTypes.RESET_FORM_106_STATUS:
      return {
        ...state,
        formStatus: '' as const
      };
    case form106ActionTypes.UPDATE_CURRENT_PERIOD_106:
      return {
        ...state,
        form106Data: {
          ...state.form106Data,
          activeEffectiveFromDate: data.activePeriod.effectiveFromDate,
          activeEffectiveToDate: data.clearActiveEffectiveToDate
            ? ''
            : data.activePeriod.effectiveToDate,
          activeNra: data.activePeriod.nra,
          activeEmploymentStatus: data.activePeriod.employmentStatus,
          activeOccupation: data.activePeriod.occupation,
          activeDepartmentId: data.activePeriod.departmentId,
          activeMemberAffiliation: data.activePeriod.memberAffiliation
        }
      };
    case form106ActionTypes.REMOVE_FORM_106_EXCEPTIONS:
      return {
        ...state,
        exceptions: [],
        overrideExceptions: []
      };
    case form106ActionTypes.SET_DEPARTMENT_ID_IN_STORE:
      return {
        ...state,
        form106Data: {
          ...state.form106Data,
          employmentPeriods: setDepartmentId(
            data.index,
            data.value,
            state.form106Data.employmentPeriods
          )
        }
      };
    case form106ActionTypes.SET_FORM_106_NOT_NEW_FORM:
      return {
        ...state,
        newFormCreated: false
      };
    case form106ActionTypes.SET_PERIOD_STATUS:
      return {
        ...state,
        periodStatus: data
      };
    case form106ActionTypes.SET_ORIGINAL_106_DATA_IN_STORE:
      return {
        ...state,
        originalForm106Data: data
      };
    default:
      return state;
  }
};

export default form106;
