import React, { ReactNode, useEffect, useRef } from 'react';
import { ThemeUICSSObject } from 'theme-ui';
import { RobotoFlex } from 'styles/theme';
import Icon, { IconNames } from '../icon/Icon';
import Button from '../button/Button';

export interface ModalProps {
  id: string;
  isOpen?: boolean;
  titleText?: string;
  modalHeight?: string;
  modalWidth?: string;
  childrenSxOverride?: ThemeUICSSObject;
  onClose?: () => void;
  children?: ReactNode;
  footer?: ReactNode;
  preventCloseOnBackdropClick?: boolean;
  preventCloseOnEscape?: boolean;
  hideCloseButton?: boolean;
  modalSxOverride?: ThemeUICSSObject;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  titleText,
  modalHeight,
  onClose,
  children,
  footer,
  id,
  modalWidth = '600px',
  preventCloseOnBackdropClick = false,
  preventCloseOnEscape = false,
  hideCloseButton = false,
  modalSxOverride,
  childrenSxOverride
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    const dialog = dialogRef.current;
    if (dialog) {
      if (isOpen) {
        dialog.showModal();
      } else {
        dialog.close();
      }
    }
  }, [isOpen]);

  const handleBackdropClick = (event: React.MouseEvent<HTMLDialogElement>) => {
    const dialog = dialogRef.current;
    if (dialog) {
      const rect = dialog.getBoundingClientRect();
      const isInDialog =
        rect.top <= event.clientY &&
        event.clientY <= rect.top + rect.height &&
        rect.left <= event.clientX &&
        event.clientX <= rect.left + rect.width;

      if (!isInDialog && !preventCloseOnBackdropClick) {
        dialog.close();
      }
    }
  };

  const handleEscape = (event: React.KeyboardEvent<HTMLDialogElement>) => {
    if (preventCloseOnEscape) {
      event.nativeEvent.preventDefault();
    }
  };

  return (
    // This looks like a bug in the linter. These jsx-a11y rules are not applicable to dialog elements.
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
    <dialog
      id={id}
      data-testid={id}
      aria-modal={isOpen ? 'true' : 'false'}
      ref={dialogRef}
      sx={{
        width: modalWidth,
        height: modalHeight,
        border: 'none',
        [`&:focus`]: {
          outline: 'none'
        },
        [`&::backdrop`]: {
          background: 'rgb(51, 51, 51, 0.6)'
        },
        padding: `${hideCloseButton ? '3rem' : '0'} 0 0 0`,
        ...modalSxOverride
      }}
      onClick={handleBackdropClick}
      onClose={onClose}
      onCancel={handleEscape}
    >
      <div>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            textAlign: 'center'
          }}
        >
          {!hideCloseButton && (
            <Button
              id="close-button"
              aria-label="closeModal"
              data-testid={`close-button-${id}`}
              variant="simple"
              onClick={() => {
                dialogRef.current?.close();
                onClose?.();
              }}
              isIconButton
              sxOverride={{ padding: '1.25rem' }}
            >
              <Icon icon={IconNames.CLOSE} />
            </Button>
          )}
        </div>
        <div
          sx={{
            textAlign: 'center',
            '& > h2': {
              fontFamily: RobotoFlex.style.fontFamily,
              fontWeight: 'semibold',
              fontSize: 'large',
              margin: '0',
              padding: '0 3rem'
            }
          }}
        >
          <h2>{titleText || 'Modal'}</h2>
        </div>
      </div>
      <div
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingX: '80px',
          paddingY: '30px',
          textAlign: 'center',
          ...childrenSxOverride
        }}
      >
        {children}
      </div>
      <div sx={{ paddingBottom: '30px' }}>{footer}</div>
    </dialog>
  );
};

export default Modal;
