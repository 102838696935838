import { isEqual, omit } from 'lodash';
import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import clientSide from 'utils/logger/client-side';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import {
  fetchMaintenanceFlags,
  MaintenanceFlagContainer,
  MAINTENANCE_FLAG_KEY
} from './maintenanceFlags';

const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV');

const useFetchMaintenanceFlags = () => {
  const pathname = usePathname();
  const [maintenanceFlags, setMaintenanceFlags] = useLocalStorageState<
    MaintenanceFlagContainer
  >(MAINTENANCE_FLAG_KEY, {
    defaultValue: {
      environment: NEXT_PUBLIC_APP_ENV as string
    } as MaintenanceFlagContainer
  });

  useEffect(() => {
    fetchMaintenanceFlags().then(newFlags => {
      if (!newFlags) {
        clientSide.warn(
          `No maintenance flags found for ${NEXT_PUBLIC_APP_ENV}`
        );
        return;
      }
      // Omitting TTL from comparison because they will always be different
      // and is here for legacy reasons
      if (!isEqual(omit(newFlags, 'TTL'), omit(maintenanceFlags, 'TTL'))) {
        setMaintenanceFlags(newFlags);
      }
    });
  }, [maintenanceFlags, pathname, setMaintenanceFlags]);
};

export default useFetchMaintenanceFlags;
