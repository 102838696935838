import { useState, useCallback } from 'react';

const useModal = (): [boolean, (obj?: any) => void, any] => {
  const [isShowing, setIsShowing] = useState(false);
  const [modalData, setModalData] = useState(undefined);

  const toggleModal = useCallback(
    (obj?: any) => {
      setIsShowing(x => !x);
      if (obj) {
        setModalData(obj);
      }
    },
    [setIsShowing, setModalData]
  );

  return [isShowing, toggleModal, modalData];
};

export default useModal;
