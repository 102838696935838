import { useRef } from 'react';
import { ThemeUIStyleObject } from 'theme-ui';
import Icon from '../icon/Icon';
import { IconNamesStatus } from '../icon/Icons';

export enum SpinnerSize {
  XSMALL = 15,
  SMALL = 22,
  MEDIUM = 36,
  LARGE = 66
}
const spinnerContainerStyle = (
  isFullScreen: boolean,
  isFullScreenExcludingNavBar: boolean,
  visible: boolean,
  backgroundColor: string
) => ({
  display: visible ? 'flex' : 'none',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor,
  opacity: 0.75,
  zIndex: '3000',
  height: '100%',
  width: '100%',
  position:
    (isFullScreen || isFullScreenExcludingNavBar) && ('fixed' as 'fixed'),
  left: (isFullScreenExcludingNavBar && '85px') || (isFullScreen && '0'),
  top: (isFullScreenExcludingNavBar && '80px') || (isFullScreen && '0'),

  '@keyframes spin': {
    from: {
      transform: 'rotate(0)'
    },

    to: {
      transform: 'rotate(360deg)'
    }
  },

  '.text': {
    fontFamily: 'body',
    color: 'brandNavy100',
    marginLeft: ' 13px'
  }
});

const spinnerStyle = (size: number) => ({
  svg: {
    animation: `spin 1400ms linear 0s infinite running`,
    width: size,
    height: size
  }
});

interface SpinnerProps {
  isFullScreen?: boolean;
  isFullScreenExcludingNavBar?: boolean;
  message?: string | React.ReactNode;
  size?: number;
  visible?: boolean;
  sxOverrides?: ThemeUIStyleObject;
  id: string;
  backgroundColor?: string;
}

const Spinner = ({
  isFullScreen = true,
  isFullScreenExcludingNavBar = false,
  message,
  size = SpinnerSize.LARGE,
  visible = true,
  id,
  sxOverrides,
  backgroundColor = 'white'
}: SpinnerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  if (message) {
    return (
      <div
        sx={{
          ...spinnerContainerStyle(
            isFullScreen,
            isFullScreenExcludingNavBar,
            visible,
            backgroundColor
          ),
          ...sxOverrides
        }}
        data-testid={id}
        ref={ref}
      >
        <Icon icon={IconNamesStatus.SPINNER} sx={{ ...spinnerStyle(size) }} />
        {typeof message === 'string' ? (
          <span className="text">{message}</span>
        ) : (
          message
        )}
      </div>
    );
  }

  return (
    <div
      sx={{
        ...spinnerContainerStyle(
          isFullScreen,
          isFullScreenExcludingNavBar,
          visible,
          backgroundColor
        ),
        ...sxOverrides
      }}
      data-testid={id}
      ref={ref}
    >
      <Icon icon={IconNamesStatus.SPINNER} sx={{ ...spinnerStyle(size) }} />
    </div>
  );
};

export default Spinner;
