import { ActionCreator } from 'redux';

import { EapAction, EmployerAccountLocked, EmployerSelection } from 'types';

export enum selectEmployersActionTypes {
  SELECT_EMPLOYER_DROPDOWN_REQUESTED = 'SELECT_EMPLOYER_DROPDOWN_REQUESTED',
  SELECT_EMPLOYER_REQUESTED = 'SELECT_EMPLOYER_REQUESTED',
  SELECT_EMPLOYER_SUCCESS = 'SELECT_EMPLOYER_SUCCESS',
  SELECT_EMPLOYER_FAILED = 'SELECT_EMPLOYER_FAILED',
  SELECT_GROUP_NUMBER_REQUESTED = 'SELECT_GROUP_NUMBER_REQUESTED',
  SELECT_GROUP_NUMBER_SUCCESS = 'SELECT_GROUP_NUMBER_SUCCESS',
  SELECT_GROUP_NUMBER_FAILED = 'SELECT_GROUP_NUMBER_FAILED',
  SET_CLASS_COVERAGE = 'SET_CLASS_COVERAGE',
  GET_CURRENT_GROUP_USERS_REQUESTED = 'GET_CURRENT_GROUP_USERS_REQUESTED',
  GET_CURRENT_GROUP_USERS_SUCCEEDED = 'GET_CURRENT_GROUP_USERS_SUCCEEDED',
  GET_CURRENT_GROUP_USERS_FAILED = 'GET_CURRENT_GROUP_USERS_FAILED',
  SET_ATTESTATION_COMPLETED = 'SET_ATTESTATION_COMPLETED',
  SHOW_ACCOUNT_LOCKED_MODAL = 'SHOW_ACCOUNT_LOCKED_MODAL',
  SHOW_ATTESTATION_REQUITRED_MODAL = 'SHOW_ATTESTATION_REQUITRED_MODAL',
  SHOW_ATTESTATION_REQUITRED_BANNER = 'SHOW_ATTESTATION_REQUITRED_BANNER',
  UPDATE_ATTESTATION_DEADLINE = 'UPDATE_ATTESTATION_DEADLINE'
}

export const selectEmployerDropdownAction: ActionCreator<EapAction> = (
  data: EmployerSelection
) => ({
  type: selectEmployersActionTypes.SELECT_EMPLOYER_DROPDOWN_REQUESTED,
  data
});

export const showAccountLockedModalAction: ActionCreator<EapAction> = (
  data: EmployerAccountLocked
) => ({
  type: selectEmployersActionTypes.SHOW_ACCOUNT_LOCKED_MODAL,
  data
});

export const showAttestationRequiredModalAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: selectEmployersActionTypes.SHOW_ATTESTATION_REQUITRED_MODAL,
  data
});

export const showAttestationRequiredBannerAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: selectEmployersActionTypes.SHOW_ATTESTATION_REQUITRED_BANNER,
  data
});

export const updateAttestationDeadlineAction: ActionCreator<EapAction> = (data: {
  groupNo: string;
  dueDate: string;
  dueDays: number;
  attestationRequired: boolean;
}) => ({
  type: selectEmployersActionTypes.UPDATE_ATTESTATION_DEADLINE,
  data
});

export const selectEmployerAction: ActionCreator<EapAction> = (
  data: EmployerSelection
) => ({
  type: selectEmployersActionTypes.SELECT_EMPLOYER_REQUESTED,
  data
});

export const selectEmployerSuccessAction: ActionCreator<EapAction> = (
  data: EmployerSelection
) => ({
  type: selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS,
  data
});

export const selectGroupNumberAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: selectEmployersActionTypes.SELECT_GROUP_NUMBER_REQUESTED,
  data
});

export const selectGroupNumberSuccessAction: ActionCreator<EapAction> = (
  data: EmployerSelection
) => ({
  type: selectEmployersActionTypes.SELECT_GROUP_NUMBER_SUCCESS,
  data
});

export const setClassCoverageAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: selectEmployersActionTypes.SET_CLASS_COVERAGE,
  data
});

export const setAttestationCompletedAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: selectEmployersActionTypes.SET_ATTESTATION_COMPLETED,
  data
});

export const getCurrentGroupUsersAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: selectEmployersActionTypes.GET_CURRENT_GROUP_USERS_REQUESTED,
  data
});

export const getCurrentGroupUsersSuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: selectEmployersActionTypes.GET_CURRENT_GROUP_USERS_SUCCEEDED,
  data
});

export const getCurrentGroupUsersFailedAction: ActionCreator<EapAction> = () => ({
  type: selectEmployersActionTypes.GET_CURRENT_GROUP_USERS_FAILED
});
