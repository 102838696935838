import { ActionCreator } from 'redux';

import { EapAction } from 'types';

export enum memberProfileActionTypes {
  MEMBER_PROFILE_UPDATE_FORM_132_CONSENT_REQUESTED = 'MEMBER_PROFILE_UPDATE_FORM_132_CONSENT_REQUESTED',
  RETRO_PAY_REQUESTED = 'RETRO_PAY_REQUESTED',
  RETRO_PAY_SUCCEEDED = 'RETRO_PAY_SUCCEEDED',
  RETRO_PAY_FAILED = 'RETRO_PAY_FAILED'
}

export const updateForm132ConsentAction: ActionCreator<EapAction> = (data: {
  membershipNo: number;
  releaseInfoConsentInd: 'Y' | 'N';
}) => ({
  type:
    memberProfileActionTypes.MEMBER_PROFILE_UPDATE_FORM_132_CONSENT_REQUESTED,
  data
});

export const retroPayAction: ActionCreator<EapAction> = (data: {
  membershipNo: number;
  year: number;
}) => ({
  type: memberProfileActionTypes.RETRO_PAY_REQUESTED,
  data
});
