import Link from 'next/link';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { CONTAINER_MAX_WIDTH } from 'styles/theme';
import { showTerms } from 'utils/cobrowse';
import { EXTERNAL_URLS } from 'utils/constants';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import { getYear } from 'date-fns';
import Icon, { IconSize } from 'components/v2/atomic/icon/Icon';
import {
  IconNamesLarge,
  IconNamesRegular
} from 'components/v2/atomic/icon/Icons';

export interface FooterProps {
  footerText?: string | ReactNode;
  id: string;
}

const Footer = ({ footerText, id }: FooterProps) => {
  const { t } = useTranslation(['common', 'header']);
  const dispatch = useDispatch();
  const breakpoint = useBreakpoint();

  return (
    <div
      id={id}
      data-testid={`${id}-footer`}
      sx={{
        fontFamily: 'body',
        backgroundColor: 'brandNavy100',
        padding: ['1.5rem 2rem', '1.5rem', '1.5rem']
      }}
    >
      <div sx={{ maxWidth: CONTAINER_MAX_WIDTH, margin: '0 auto' }}>
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: ['center', 'flex-start', 'flex-start'],
            flexDirection: ['row', 'column']
          }}
        >
          <div
            sx={{
              display: 'flex',
              gap: '1rem 1.25rem',
              alignItems: ['center', 'flex-start'],
              flexWrap: 'wrap',
              flexDirection: ['row', 'column'],
              a: {
                color: 'white',
                textDecoration: 'none',
                padding: '0 0.25rem',
                '&:hover': {
                  textDecoration: 'underline',
                  color: 'white',
                  padding: '0 0.25rem'
                },
                '&:focus-visible': {
                  outline: 'solid 0.125rem white',
                  textDecoration: 'underline',
                  color: 'white',
                  borderRadius: '0.3125rem',
                  padding: '0 0.25rem'
                }
              }
            }}
            data-testid={`${id}-menu-item-list`}
          >
            <Link
              href={EXTERNAL_URLS.OMERS}
              data-testid={`${id}-omersWebsite`}
              target="_blank"
            >
              <div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '0.5rem'
                }}
              >
                <Icon
                  icon={IconNamesRegular.OMERS_LOGO}
                  size={IconSize.REGULAR}
                />
                {t('OMERS_FOOTER')}
              </div>
            </Link>
            <Link
              href={EXTERNAL_URLS.OMERS_TERMS_OF_USE}
              target="_blank"
              data-testid={`${id}-terms_of_use`}
            >
              {t('TERMS_OF_USE_FOOTER')}
            </Link>
            <Link
              href={EXTERNAL_URLS.OMERS_PRIVACY}
              data-testid={`${id}-Privacy`}
              target="_blank"
            >
              {t('PRIVACY_FOOTER')}
            </Link>
            <Link
              href={EXTERNAL_URLS.OMERS_ACCESSIBILITY}
              target="_blank"
              data-testid={`${id}-accessibility`}
            >
              {t('ACCESSIBILITY_FOOTER')}
            </Link>
            <Link
              href="/"
              data-testid={`${id}-co-browse`}
              onClick={() => showTerms(dispatch, t)}
            >
              {t('CO_BROWSE_FOOTER')}
            </Link>
            <Link
              href={EXTERNAL_URLS.OMERS_EACCESS_USER_FEEDBACK}
              data-testid={`${id}-feedback`}
              target="_blank"
            >
              {t('FEEDBACK_FOOTER')}
            </Link>
            <Link
              href={EXTERNAL_URLS.OMERS_CONTACT_US}
              target="_blank"
              data-testid={`${id}-contact-us`}
            >
              {t('CONTACT_US_FOOTER')}
            </Link>
          </div>

          <div
            sx={{
              display: 'flex',
              gap: '1.5rem',
              m: ['0', '1.5rem 0.25rem 0'],
              a: {
                height: '2rem',
                width: '2rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                '&:focus-visible': {
                  outline: 'solid 0.125rem white',
                  borderRadius: '0.3125rem',
                  svg: { color: 'extendedBlue75' }
                }
              },
              svg: {
                color: 'white',
                '&:hover': {
                  color: 'extendedBlue75'
                }
              }
            }}
          >
            <Link
              href={EXTERNAL_URLS.OMERS_FACEBOOK}
              data-testid={`${id}-omersFacebook`}
              target="_blank"
            >
              <Icon icon={IconNamesLarge.FACEBOOK} size={IconSize.LARGE} />
            </Link>
            <Link
              href={EXTERNAL_URLS.OMERS_INSTAGRAM}
              data-testid={`${id}-omersInstagram`}
              target="_blank"
            >
              <Icon icon={IconNamesLarge.INSTAGRAM} size={IconSize.LARGE} />
            </Link>
            <Link
              href={EXTERNAL_URLS.OMERS_LINKEDIN}
              data-testid={`${id}-omersLinkedIn`}
              target="_blank"
            >
              <Icon icon={IconNamesLarge.LINKEDIN} size={IconSize.LARGE} />
            </Link>
          </div>
        </div>

        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            gap: '1rem',
            flexDirection: ['row', 'column'],
            alignItems: ['center', 'flex-start'],
            color: 'white',
            mt: '1.5rem',
            fontSize: '1rem'
          }}
          data-testid={`${id}-footer-text-with-logo`}
        >
          {footerText ? (
            <p sx={{ width: '75%', mb: 0 }} data-testid={`${id}-footer-text`}>
              {footerText}
            </p>
          ) : (
            <div sx={{ mb: 0 }} data-testid={`${id}-footer-text`}>
              {t('FOOTER_TEXT')}{' '}
              {breakpoint.isLessThanSmallMin && (
                <Link
                  href="tel:+14163506750"
                  sx={{
                    color: 'white',
                    '&:link, &:visited': {
                      fontWeight: 'semibold',
                      whiteSpace: 'nowrap',
                      color: 'white'
                    },
                    '&:focus-visible': {
                      outline: 'none'
                    }
                  }}
                >
                  416-350-6750
                </Link>
              )}
              {!breakpoint.isLessThanSmallMin && (
                <span sx={{ color: 'white', fontWeight: 'semibold' }}>
                  416-350-6750
                </span>
              )}{' '}
              {t('OR')}{' '}
              <span sx={{ whiteSpace: 'nowrap' }}>
                {breakpoint.isLessThanSmallMin && (
                  <Link
                    href="tel:+18338840389"
                    sx={{
                      color: 'white',
                      '&:link, &:visited': {
                        fontWeight: 'semibold',
                        whiteSpace: 'nowrap',
                        color: 'white'
                      },
                      '&:focus-visible': {
                        outline: 'none'
                      }
                    }}
                  >
                    1-833-884-0389
                  </Link>
                )}
                {!breakpoint.isLessThanSmallMin && (
                  <span sx={{ color: 'white', fontWeight: 'semibold' }}>
                    1-833-884-0389
                  </span>
                )}{' '}
                <span sx={{ fontWeight: 'semibold' }}>({t('TOLL_FREE')})</span>
              </span>
            </div>
          )}
        </div>
        <div
          sx={{
            mt: '1.5rem',
            flexWrap: 'wrap',
            flexDirection: ['row', 'column'],
            alignItems: ['center', 'flex-start'],
            color: 'white',
            fontSize: '1rem'
          }}
        >
          © <span>{getYear(new Date())}</span> {t('ALL_RIGHTS_RESERVED')}
        </div>
      </div>
    </div>
  );
};

export default Footer;
