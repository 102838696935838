import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import { MemberSearchResult } from 'interfaces';

export enum form106ActionTypes {
  SAVE_FORM_106_IN_STORE = 'SAVE_FORM_106_IN_STORE',
  SET_FORM_106_STEP = 'SET_FORM_106_STEP',
  SET_FORM_106_STATUS = 'SET_FORM_106_STATUS',
  SET_FORM_106_NOT_NEW_FORM = 'SET_FORM_106_NOT_NEW_FORM',
  SAVE_FORM_IN_STORE = 'SAVE_FORM_IN_STORE',
  CREATE_FORM_106 = 'CREATE_FORM_106',
  CREATE_FORM_106_IN_NEW_TAB = 'CREATE_FORM_106_IN_NEW_TAB',
  CLEAR_FORM_106 = 'CLEAR_FORM_106',
  SET_MEMBER_INFO_FORM_106 = 'SET_MEMBER_INFO_FORM_106',
  SET_REQUEST_NUMBER_FORM_106 = 'SET_REQUEST_NUMBER_FORM_106',
  UPDATE_EXISTNG_FORM_106_REQUESTED = 'UPDATE_EXISTNG_FORM_106_REQUESTED',
  UPDATE_EXISTNG_FORM_106_SUCCEEDED = 'UPDATE_EXISTNG_FORM_106_SUCCEEDED',
  UPDATE_EXISTNG_FORM_106_FAILED = 'UPDATE_EXISTNG_FORM_106_FAILED',
  DELETE_FORM_106_REQUESTED = 'DELETE_FORM_106_REQUESTED',
  DELETE_FORM_106_SUCCEEDED = 'DELETE_FORM_106_SUCCEEDED',
  DELETE_FORM_106_FAILED = 'DELETE_FORM_106_FAILED',
  SAVE_FORM_106_REQUESTED = 'SAVE_FORM_106_REQUESTED',
  SAVE_FORM_106_SUCCEEDED = 'SAVE_FORM_106_SUCCEEDED',
  SAVE_FORM_106_FAILED = 'SAVE_FORM_106_FAILED',
  GET_FORM_106_REQUESTED = 'GET_FORM_106_REQUESTED',
  GET_FORM_106_SUCCEEDED = 'GET_FORM_106_SUCCEEDED',
  GET_FORM_106_FAILED = 'GET_FORM_106_FAILED',
  SAVE_FORM_AND_CREATE_PERIOD_106_REQUESTED = 'SAVE_FORM_AND_CREATE_PERIOD_106_REQUESTED',
  SAVE_FORM_AND_CREATE_PERIOD_106_SUCCEEDED = 'SAVE_FORM_AND_CREATE_PERIOD_106_SUCCEEDED',
  SAVE_FORM_AND_CREATE_PERIOD_106_FAILED = 'SAVE_FORM_AND_CREATE_PERIOD_106_FAILED',
  DELETE_LATEST_SERVICE_PERIOD_FORM_106_REQUESTED = 'DELETE_LATEST_SERVICE_PERIOD_FORM_106_REQUESTED',
  DELETE_LATEST_SERVICE_PERIOD_FORM_106_SUCCEEDED = 'DELETE_LATEST_SERVICE_PERIOD_FORM_106_SUCCEEDED',
  DELETE_LATEST_SERVICE_PERIOD_FROM_106_FAILED = 'DELETE_LATEST_SERVICE_PERIOD_FORM_106_FAILED',
  GET_FORM_106_PDF_REQUESTED = 'GET_FORM_106_PDF_REQUESTED',
  GET_FORM_106_PDF_SUCCEEDED = 'GET_FORM_106_PDF_SUCCEEDED',
  GET_FORM_106_PDF_FAILED = 'GET_FORM_106_PDF_FAILED',
  SET_FORM_106_SUBMIT_STATUS = 'SET_FORM_106_SUBMIT_STATUS',
  UPDATE_AND_SUBMIT_FORM_106_REQUESTED = 'UPDATE_AND_SUBMIT_FORM_106_REQUESTED',
  UPDATE_AND_SUBMIT_FORM_106_SUCCEEDED = 'UPDATE_AND_SUBMIT_FORM_106_SUCCEEDED',
  UPDATE_AND_SUBMIT_FORM_106_FAILED = 'UPDATE_AND_SUBMIT_FORM_106_FAILED',
  RESET_FORM_106_SUBMIT_STATUS = 'RESET_FORM_106_SUBMIT_STATUS',
  RESET_FORM_106_STATUS = 'RESET_FORM_106_STATUS',
  UPDATE_CURRENT_PERIOD_106 = 'UPDATE_CURRENT_PERIOD_106',
  OVERRIDE_FORM_106_EXCEPTIONS = 'OVERRIDE_FORM_106_EXCEPTIONS',
  REMOVE_FORM_106_EXCEPTIONS = 'REMOVE_FORM_106_EXCEPTIONS',
  SET_DEPARTMENT_ID_IN_STORE = 'SET_DEPARTMENT_ID_IN_STORE',
  SET_PERIOD_STATUS = 'SET_PERIOD_STATUS',
  UNLOCK_FORM_106 = 'UNLOCK_FORM_106',
  SET_ORIGINAL_106_DATA_IN_STORE = 'SET_ORIGINAL_106_DATA_IN_STORE',
  CLEAR_106_WITHOUT_UNLOCK = 'CLEAR_106_WITHOUT_UNLOCK',
  GET_ORIGINAL_FORM_STATE_106 = 'GET_ORIGINAL_FORM_STATE_106',
  GET_ORIGINAL_FORM_STATE_106_SUCCESS = 'GET_ORIGINAL_FORM_STATE_106_SUCCESS',
  GET_ORIGINAL_FORM_STATE_106_FAILED = 'GET_ORIGINAL_FORM_STATE_106_FAILED'
}

export const saveForm106InStore: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.SAVE_FORM_106_IN_STORE,
  data
});

export const createForm106: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form106ActionTypes.CREATE_FORM_106,
  data
});

export const createForm106InNewTabAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.CREATE_FORM_106_IN_NEW_TAB,
  data
});

export const saveExistingForm106Action: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form106ActionTypes.UPDATE_EXISTNG_FORM_106_REQUESTED,
  data
});

export const getForm106: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  lockForm: boolean;
}) => ({
  type: form106ActionTypes.GET_FORM_106_REQUESTED,
  data
});

export const saveForm106: ActionCreator<EapAction> = (data: {
  formData: any;
  isReview?: boolean;
}) => ({
  type: form106ActionTypes.SAVE_FORM_106_REQUESTED,
  data
});

export const createServicePeriod106: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_REQUESTED,
  data
});

export const deleteLatestServicePeriod106: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.DELETE_LATEST_SERVICE_PERIOD_FORM_106_REQUESTED,
  data
});

export const setForm106StepAction: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.SET_FORM_106_STEP,
  data
});

export const setForm106NotNewFormAction: ActionCreator<EapAction> = () => ({
  type: form106ActionTypes.SET_FORM_106_NOT_NEW_FORM
});

export const clearForm106: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.CLEAR_FORM_106,
  data
});

export const setForm106RequestNumber: ActionCreator<EapAction> = (
  data: string | null
) => ({
  type: form106ActionTypes.SET_REQUEST_NUMBER_FORM_106,
  data
});

export const setForm106MemberInfo: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.SET_MEMBER_INFO_FORM_106,
  data
});

export const deleteForm106Action: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.DELETE_FORM_106_REQUESTED,
  data
});

export const setForm106StatusAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.SET_FORM_106_STATUS,
  data
});

export const getForm106PDFAction: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.GET_FORM_106_PDF_REQUESTED,
  data
});

export const setForm106SubmitStatusAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.SET_FORM_106_SUBMIT_STATUS,
  data
});

export const updateAndSubmitForm106Action: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_REQUESTED,
  data
});

export const updateCurrentPeriod106: ActionCreator<EapAction> = (data: {
  activePeriod: any;
  clearActiveEffectiveToDate: boolean;
}) => ({
  type: form106ActionTypes.UPDATE_CURRENT_PERIOD_106,
  data
});

export const overrideForm106ExceptionsAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.OVERRIDE_FORM_106_EXCEPTIONS,
  data
});

export const clearForm106ErrorsAction: ActionCreator<EapAction> = () => ({
  type: form106ActionTypes.REMOVE_FORM_106_EXCEPTIONS
});

export const setDepartmentIdInStore: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.SET_DEPARTMENT_ID_IN_STORE,
  data
});

export const setPeriodStatus: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.SET_PERIOD_STATUS,
  data
});

export const unlockForm106: ActionCreator<EapAction> = (data: any) => ({
  type: form106ActionTypes.UNLOCK_FORM_106,
  data
});

export const setOriginal106DataInStore: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.SET_ORIGINAL_106_DATA_IN_STORE,
  data
});

export const clear106WithoutUnlock: ActionCreator<EapAction> = () => ({
  type: form106ActionTypes.CLEAR_106_WITHOUT_UNLOCK
});

export const getOriginalFormState106Action: ActionCreator<EapAction> = () => ({
  type: form106ActionTypes.GET_ORIGINAL_FORM_STATE_106
});

export const getOriginalFormState106SuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.GET_ORIGINAL_FORM_STATE_106_SUCCESS,
  data
});

export const getOriginalFormState106FailedAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form106ActionTypes.GET_ORIGINAL_FORM_STATE_106_FAILED,
  data
});
