import { useEffect, useRef } from 'react';

interface ScrollToProps {
  offset?: number;
  scrollCondition?: boolean;
  scrollOnChange?: any | Array<any>;
}
const ScrollTo = ({
  offset = 0,
  scrollCondition = true,
  scrollOnChange
}: ScrollToProps) => {
  const reviewRef = useRef<HTMLDivElement>(null);
  const deps = !Array.isArray(scrollOnChange)
    ? [scrollOnChange]
    : scrollOnChange;

  useEffect(() => {
    if (scrollCondition) {
      reviewRef?.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollCondition, ...deps]);

  return (
    <div
      ref={reviewRef}
      style={{
        visibility: 'hidden',
        position: 'relative',
        top: `${offset}px`
      }}
    />
  );
};

export default ScrollTo;
