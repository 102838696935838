import { DonnaApiExceptionErrorInfo } from '@omers-packages/next-donna-gateway-handler';
import { getData, postData } from 'services/services';
import { FileExtensions } from 'types/file-extensions';
import { downloadFile, getApiErrorMessageByCode } from 'utils';
import { toLoggerError } from 'utils/api';
import clientSide from 'utils/logger/client-side';
import {
  AdhocReportApiResponse,
  AdhocReportData,
  AdhocReportParams,
  GenerateAdhocReportParams
} from '../types';

export const getMssReports = (groupNo: string) =>
  getData(`/proxy/api/v1/reports/mss-reports-metadata/${groupNo}`);

interface AdhocReportResponse {
  success: boolean;
  isEmpty?: boolean;
  data: AdhocReportData | null;
  error: (DonnaApiExceptionErrorInfo & { uiErrorMessage: string }) | null;
}

export const getAdhocReports = async (
  filterOptions: AdhocReportParams
): Promise<AdhocReportResponse> => {
  const filterParams = new URLSearchParams(
    Object.entries(filterOptions)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => [key, String(value)])
  );
  try {
    const response: AdhocReportApiResponse = await getData(
      `/proxy/api/v1/reports/adhoc-reports/?${filterParams.toString()}`
    );

    if (!response.result.success) {
      clientSide.error(
        `Error fetching Adhoc Reports for employer no:${filterOptions.employerGroupNo}`
      );
      return response.result.errors.length > 0
        ? {
            success: false,
            error: {
              ...response.result.errors[0],
              uiErrorMessage: response.result.errors[0].code
                ? getApiErrorMessageByCode(response.result.errors[0].code)
                : getApiErrorMessageByCode('GENERIC_ERROR')
            },
            data: null
          }
        : {
            success: false,
            error: {
              code: 'GENERIC_ERROR',
              message: '',
              uiErrorMessage: getApiErrorMessageByCode('GENERIC_ERROR'),
              path: ''
            },
            data: null
          };
    }

    clientSide.ok(
      `Adhoc Reports for employer no:${filterOptions.employerGroupNo} fetched successfully`
    );
    return {
      success: true,
      isEmpty: response.result.data.empty,
      error: null,
      data: response.result.data
    };
  } catch (error) {
    clientSide.error(
      `There was an unexpected error fetching Adhoc Reports for employer no:${filterOptions.employerGroupNo}`,
      toLoggerError(error)
    );
    return {
      success: false,
      error: {
        code: 'GENERIC_ERROR',
        message: '',
        uiErrorMessage: getApiErrorMessageByCode('GENERIC_ERROR'),
        path: ''
      },
      data: null
    };
  }
};

export const getReportCsv = async (
  selectGroupNumber: string,
  jobNo: string,
  reportName: string
) => {
  try {
    const response = await getData(
      `/proxy/api/v1/reports/mss-reports/${selectGroupNumber}/${jobNo}?fileFormat=CSV `,
      undefined,
      undefined,
      'blob'
    );
    if ((response as Blob).type === 'text/csv') {
      downloadFile(response, reportName, FileExtensions.CSV);
      clientSide.ok(
        `${reportName} downloaded successfully. Job Number:${jobNo}`
      );
      return { success: true };
    }
    clientSide.error(
      `Error downloading report ${reportName}. Job Number:${jobNo}`
    );
    return { success: false, error: 'REPORT_DOWNLOAD_FAILED' };
  } catch (error) {
    clientSide.error(
      `There was an unexpected error downloading report ${reportName}. Job Number:${jobNo}`,
      toLoggerError(error)
    );
    return {
      success: false,
      error: {
        code: 'GENERIC_ERROR',
        message: '',
        uiErrorMessage: getApiErrorMessageByCode('GENERIC_ERROR'),
        path: ''
      }
    };
  }
};

export const generateAdhocReport = async (data: GenerateAdhocReportParams) =>
  postData(
    `/api/reports/generate`,
    { reportInput: data },
    undefined,
    undefined,
    undefined,
    undefined,
    {
      'Content-Type': 'application/json',
      username: data.externalUserId,
      'X-Group-No': data.employerGroupNo
    }
  );
