import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getAnrInfo = (requestNumber: string) =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/${requestNumber}`);

export const getAnrExceptions = (filter: {
  employerNumber: string;
  reconciliationYear: string;
  startIndex: number;
  rowLimit: number;
}) => postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/exceptions`, filter);

export const submitAnrRequest = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/reconcile/${requestNumber}`,
    {}
  );

export const getAnrExceptionsByErrorID = (filter: {
  employerNumber: string;
  reconciliationYear: string;
  exceptionEnvironmentId: string;
  exceptionMessageId: string;
  startIndex: number;
  rowLimit: number;
}) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/exceptions/error`, filter);

export const getAnrNotInitiated = (filter: {
  employerNumber: string;
  reconciliationYear: string;
  startIndex: number;
  rowLimit: number;
}) => postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/unreported`, filter);

export const setAnrOverride119 = (data: {
  requestNo: string;
  overrideReasonCode: string;
  overrideReasonText: string;
  exceptionMessageId: string;
}) =>
  putData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/exceptions/override`, data);

export const getAnrContributions = (path: string) =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/contributions/${path}`);

export const generateAnnualPensionReport = (requestNo: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/anr/report/${requestNo}/true`,
    {}
  );
