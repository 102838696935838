import { fill } from 'lodash';

/**
 * Mask patterns for react-input-mask
 * NOTE:
 * - react-input-mask does not allow dynamic length between delimiters such as a
 *   mask for email or currency; therefore mask inherently acts as maxLength
 * - react-input-mask does not work with repeated Regex patterns such as
 *   (i.e. /[0-9]{9}/i); it expects an array of each individual characters to be
 *   masked --> mask is of type string | Array<string | RegExp>, hence the usage
 *   of fill:
 *   https://github.com/sanniassin/react-input-mask#mask
 */
export const maskName = (maxLen: number) =>
  fill(Array(maxLen), /[a-zÀ-ÿ \-']/i);
export const maskAlphabet = (maxLen: number) => fill(Array(maxLen), /[a-z]/i);
export const maskAlphanumeric = (
  maxLen: number,
  space = false,
  slash = false
) =>
  fill(
    Array(maxLen),
    space && slash
      ? /[a-z0-9 /]/i
      : space
      ? /[a-z0-9 ]/i
      : slash
      ? /[a-z0-9/]/i
      : /[a-z0-9]/i
  );
export const maskNumeric = (maxLen: number, decimal = false) =>
  fill(Array(maxLen), decimal ? /[0-9.]/i : /\d/i);
export const maskAddress = (maxLen: number) =>
  fill(Array(maxLen), /(?!.*[:~!$%^_{}|"<>?[\]])./);
export const maskSin = '999 999 999';
export const maskMembershipNumber = '9999999-99';
export const maskPostalCode = '*** ***';
export const maskZipCode = '99999 9999';
export const maskInternationalPostalCode = '**********';
export const maskPhone = '(999) 999 - 9999';
export const maskDate = '99/99/9999';
export const maskHour = '99';
export const maskEmployeeId = fill(Array(10), /[a-z0-9-]/i);

/**
 * Custom non-react-input-mask Email mask for onChange handler; prevent user from
 * additional @ sign
 */
export const maskEmail = (input: string) => {
  const firstIndex = input.indexOf('@');
  return input.replace(/@/g, (match, offset) => {
    if (offset === firstIndex) {
      return match;
    }
    return '';
  });
};

/**
 * Custom non-react-input-mask Numeric Percentage mask for onChange handler
 */
export const maskPercent = (val: string) => {
  const maskedVal = val.replace(/[^0-9.]/g, '');
  let [whole, decimal] = maskedVal.split('.');
  whole = whole.substring(0, 3);
  if (decimal !== undefined) {
    decimal = decimal.substring(0, 2);
    return `${whole}.${decimal}`;
  }

  return `${whole}`;
};

/**
 * Custom non-react-input-mask Alphanumeric mask for onChange handler
 */
export const maskAlphanumericHandler = (input: string): string =>
  input.replace(/[^a-z0-9]+/gi, '');

/**
 * Custom non-react-input-mask Currency mask for onChange handler
 * @deprecated: Use TextCurrencyInput for handling live currency masking
 */
export const maskCurrency = (
  input: string,
  language: string,
  isSign = false,
  maxLength = 9
): string => {
  let digits = input?.replace(/(-(?!\d))|[^0-9|-]/g, '') ?? '';
  digits = digits.slice(0, maxLength);
  const digitsPadded = digits.length >= 4 ? digits : digits.padStart(4, '0');
  const centsIndex = digitsPadded.length - 2;
  const dollars = digitsPadded
    .substring(0, centsIndex)
    .replace(/^0+(\d+)/, '$1');
  const dollarsDelim = dollars.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    `$1${language === 'en' ? ',' : '.'}`
  );
  const cents = digitsPadded.substring(centsIndex);

  return dollarsDelim || cents
    ? `${language === 'en' && isSign ? '$' : ''}${
        dollarsDelim.length > 0 ? dollarsDelim : '0'
      }${language === 'en' ? '.' : ','}${cents}${
        language !== 'en' && isSign ? '$' : ''
      }`
    : '';
};

/**
 * Revert mask of phone string
 * i.e (647) 244 - 8434 --> 6472448434
 */
export const unmaskPhone = (input: string) => input?.replace(/\D/g, '');
