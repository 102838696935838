import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { EapAction } from 'types';
import { filterValues, filterStatusCode } from 'utils/constants';
import { isEqual } from 'lodash';
import { RootState } from 'store/reducers';
import {
  selectEmployerNumber,
  propertiesSelector,
  returnedRequestsSelector,
  getApiErrorMessage
} from 'utils';
import {
  searchReturnedRequests,
  getReturnedRequestsMessageThread,
  sendReturnedRequestsReply
} from 'services';
import {
  systemErrorNotificationAction,
  notifySuccessAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import i18n from 'i18next';
import clientSide from 'utils/logger/client-side';
import { toLoggerError } from 'utils/api';
import {
  returnedRequestsActionTypes,
  getReturnedRequestsSucceededAction,
  getReturnedRequestsFailedAction,
  setReturnedRequestsButtonStatus,
  getReturnedRequestsRequestedAction,
  getReturnedRequestsMessageThreadSucceededAction,
  getReturnedRequestsMessageThreadFailedAction,
  sendReplyRequestedFailedAction,
  sendReplyRequestedSucceededAction,
  getReturnedRequestsMessageThreadRequestedAction
} from './ReturnedRequestsActions';
import {
  IReturnedRequestsMessage,
  returnedRequestsTopicCategories,
  apiResponse
} from './types';
import {
  transformReturnedRequestsMessages,
  getPayload,
  transformReturnedRequestsMessageThread
} from './ReturnedRequests.utils';
import {
  defaultValues,
  defaultPayload
} from './returnedRequestsFilter/ReturnedRequestFilter';

export function* getReturnedRequestsSaga(action: EapAction): any {
  try {
    const res = yield call(searchReturnedRequests, action.data);
    if (res.data.msscorrSearchResult) {
      // res.data.msscorrSearchResult returns an empty array if no data is found
      const data: IReturnedRequestsMessage[] = transformReturnedRequestsMessages(
        res.data.msscorrSearchResult
      );
      yield put(getReturnedRequestsSucceededAction(data));
      return;
    }
    yield put(getReturnedRequestsFailedAction());
    return;
  } catch (e) {
    yield put(getReturnedRequestsFailedAction());
    yield put(systemErrorNotificationAction());
    clientSide.error(
      `Could not fetch returned requests: system error`,
      toLoggerError(e)
    );
  }
}

export function* callGetReturnedRequestsSagaWithPayload(
  action: EapAction
): any {
  const employerNo = yield select(selectEmployerNumber);
  const properties = yield select(propertiesSelector);
  const correspondenceCategories = properties.find(
    (property: any) =>
      property.id === returnedRequestsTopicCategories.CORRESPONDENCE_CATEGORIES
  );
  const userData = action.data;
  const payload = getPayload(
    employerNo,
    userData,
    correspondenceCategories.items
  );

  yield put(clearNotifications());

  if (action.data.status === filterValues.ACTION_REQUIRED) {
    const readPayload = { ...payload, status: filterStatusCode.READ };
    const unreadPayload = { ...payload, status: filterStatusCode.UNREAD };

    yield put(getReturnedRequestsRequestedAction(readPayload));
    yield put(getReturnedRequestsRequestedAction(unreadPayload));
  } else {
    yield put(getReturnedRequestsRequestedAction(payload));
  }
}

export function* handleGetReturnedRequestsSucceededSaga() {
  const { filters } = yield select(
    (state: RootState) => state.returnedRequests
  );

  if (isEqual(filters, defaultValues) || isEqual(filters, defaultPayload)) {
    yield put(
      setReturnedRequestsButtonStatus({
        isResetButtonDisabled: true,
        isApplyButtonDisabled: true,
        isApplyButtonClicked: false
      })
    );
  } else {
    yield put(
      setReturnedRequestsButtonStatus({
        isResetButtonDisabled: false,
        isApplyButtonDisabled: true,
        isApplyButtonClicked: true
      })
    );
  }
}
function* getReturnedRequestsMessageThreadSaga(): any {
  const { correspondenceNumber } = yield select(returnedRequestsSelector);

  const res = yield call(
    safeAuthEffect,
    getReturnedRequestsMessageThread,
    correspondenceNumber
  );
  if (res.status === 400 && res.errors[0].msg === apiResponse.NO_DATA_FOUND) {
    yield put(getReturnedRequestsMessageThreadFailedAction());
  } else if (res.data.mssEcorrMessages.length > 0) {
    const data = transformReturnedRequestsMessageThread(
      res.data.mssEcorrMessages
    );
    yield put(getReturnedRequestsMessageThreadSucceededAction(data));
  } else {
    yield put(getReturnedRequestsMessageThreadFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

function* sendReplySaga(action: EapAction): any {
  const { correspondenceNumber } = yield select(returnedRequestsSelector);

  const res = yield call(
    safeAuthEffect,
    sendReturnedRequestsReply,
    correspondenceNumber,
    action.data
  );
  if (res.status === 400) {
    yield put(sendReplyRequestedFailedAction(getApiErrorMessage(res.errors)));
  } else if (res?.data?.status) {
    yield put(sendReplyRequestedSucceededAction());
    yield put(notifySuccessAction(i18n.t('SUCCESS')));
    yield put(getReturnedRequestsMessageThreadRequestedAction());
  } else {
    yield put(sendReplyRequestedFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

export function* returnedRequestsSaga(): any {
  yield all([
    yield takeLatest(
      returnedRequestsActionTypes.APPLY_RETURNED_REQUESTS_FILTER_REQUESTED,
      callGetReturnedRequestsSagaWithPayload
    ),
    yield takeLatest(
      returnedRequestsActionTypes.GET_RETURNED_REQUESTS_REQUESTED,
      getReturnedRequestsSaga
    ),
    yield takeLatest(
      returnedRequestsActionTypes.GET_RETURNED_REQUESTS_SUCCEEDED,
      handleGetReturnedRequestsSucceededSaga
    ),
    yield takeLatest(
      returnedRequestsActionTypes.GET_RETURNED_REQUESTS_FAILED,
      handleGetReturnedRequestsSucceededSaga
    ),
    yield takeLatest(
      returnedRequestsActionTypes.GET_RETURNED_REQUESTS_MESSAGE_THREAD_REQUESTED,
      getReturnedRequestsMessageThreadSaga
    ),
    yield takeLatest(
      returnedRequestsActionTypes.SEND_REPLY_REQUESTED,
      sendReplySaga
    )
  ]);
}
