import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import {
  AnrDashboardInfo,
  AnrNotReconciled119,
  AnrReconciled119
} from 'interfaces';

export enum annualDashboardActionTypes {
  GET_ANR_DASHBOARD_INFO_REQUESTED = 'GET_ANR_DASHBOARD_INFO_REQUESTED',
  GET_ANR_DASHBOARD_INFO_SUCCESS = 'GET_ANR_DASHBOARD_INFO_SUCCESS',
  GET_ANR_DASHBOARD_INFO_FAILED = 'GET_ANR_DASHBOARD_INFO_FAILED',
  SET_ACTIVE_NOT_RECONCILED_ANR_DASHBOARD = 'SET_ACTIVE_NOT_RECONCILED_ANR_DASHBOARD',
  SET_ACTIVE_RECONCILED_ANR_DASHBOARD = 'SET_ACTIVE_RECONCILED_ANR_DASHBOARD',
  GET_ACTIVE_RECONCILED_ANR_125_REPORT_REQUESTED = 'GET_ACTIVE_RECONCILED_ANR_125_REPORT_REQUESTED',
  GET_ACTIVE_RECONCILED_ANR_125_REPORT_PANEL_REQUESTED = 'GET_ACTIVE_RECONCILED_ANR_125_REPORT_PANEL_REQUESTED',
  GET_ACTIVE_RECONCILED_ANR_125_REPORT_SUCCESS = 'GET_ACTIVE_RECONCILED_ANR_125_REPORT_SUCCESS',
  GET_ACTIVE_RECONCILED_ANR_125_REPORT_FAILED = 'GET_ACTIVE_RECONCILED_ANR_125_REPORT_FAILED',
  GET_ACTIVE_RECONCILED_SUMMARY_REPORT_REQUESTED = 'GET_ACTIVE_RECONCILED_SUMMARY_REPORT_REQUESTED',
  GET_ACTIVE_RECONCILED_SUMMARY_REPORT_SUCCESS = 'GET_ACTIVE_RECONCILED_SUMMARY_REPORT_SUCCESS',
  GET_ACTIVE_RECONCILED_SUMMARY_REPORT_FAILED = 'GET_ACTIVE_RECONCILED_SUMMARY_REPORT_FAILED',
  REQUEST_ANR_REFRESH_DASHBOARD_DATA = 'REQUEST_ANR_REFRESH_DASHBOARD_DATA',
  REQUEST_ANR_REFRESH_DASHBOARD_SUCCESS = 'REQUEST_ANR_REFRESH_DASHBOARD_SUCCESS',
  REQUEST_ANR_REFRESH_DASHBOARD_FAILED = 'REQUEST_ANR_REFRESH_DASHBOARD_FAILED',
  UPDATE_ANR_UPDATED_TIMESTAMP = 'UPDATE_ANR_UPDATED_TIMESTAMP'
}

export const getAnrDashboardInfoAction: ActionCreator<EapAction> = () => ({
  type: annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_REQUESTED
});

export const getAnrDashboardInfoSuccessAction: ActionCreator<EapAction> = (
  data: AnrDashboardInfo
) => ({
  type: annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_SUCCESS,
  data
});

export const getAnrDashboardInfoFailedAction: ActionCreator<EapAction> = () => ({
  type: annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_FAILED
});

export const setActiveNotReconciledAnrDashboardAction: ActionCreator<EapAction> = (
  data: AnrNotReconciled119
) => ({
  type: annualDashboardActionTypes.SET_ACTIVE_NOT_RECONCILED_ANR_DASHBOARD,
  data
});

export const setActiveReconciledAnrDashboardAction: ActionCreator<EapAction> = (
  data: AnrReconciled119
) => ({
  type: annualDashboardActionTypes.SET_ACTIVE_RECONCILED_ANR_DASHBOARD,
  data
});

export const getActiveReconciledAnr125ReportAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type:
    annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_REQUESTED,
  data
});

export const getActiveReconciledAnr125ReportPanelAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type:
    annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_PANEL_REQUESTED,
  data
});

export const getActiveReconciledAnr125ReportSuccessAction: ActionCreator<EapAction> = () => ({
  type: annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_SUCCESS
});

export const getActiveReconciledAnr125ReportFailedAction: ActionCreator<EapAction> = () => ({
  type: annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_FAILED
});

export const getActiveReconciledSummaryReportAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type:
    annualDashboardActionTypes.GET_ACTIVE_RECONCILED_SUMMARY_REPORT_REQUESTED,
  data
});

export const getActiveReconciledSummaryReportSuccessAction: ActionCreator<EapAction> = () => ({
  type: annualDashboardActionTypes.GET_ACTIVE_RECONCILED_SUMMARY_REPORT_SUCCESS
});

export const getActiveReconciledSummaryReportFailedAction: ActionCreator<EapAction> = () => ({
  type: annualDashboardActionTypes.GET_ACTIVE_RECONCILED_SUMMARY_REPORT_FAILED
});

export const requestAnrRefreshDashboardDataAction: ActionCreator<EapAction> = () => ({
  type: annualDashboardActionTypes.REQUEST_ANR_REFRESH_DASHBOARD_DATA
});

export const requestAnrRefreshDashboardDataSuccessAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: annualDashboardActionTypes.REQUEST_ANR_REFRESH_DASHBOARD_SUCCESS,
  data
});

export const requestAnrRefreshDashboardDataFailedAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: annualDashboardActionTypes.REQUEST_ANR_REFRESH_DASHBOARD_FAILED,
  data
});

export const updateAnrUpdatedTimestampAction: ActionCreator<EapAction> = (data: {
  timestamp: string;
  refreshRequestEmployer: string;
}) => ({
  type: annualDashboardActionTypes.UPDATE_ANR_UPDATED_TIMESTAMP,
  data
});
