import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { DataObj } from 'types';
import { deleteData, getData, postData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const createForm190 = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190`, data);

export const getForm190 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/${requestNumber}`,
    undefined,
    requestNumber
  );

export const addServicePeriodForm190 = (requestNumber: DataObj) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/${requestNumber?.requestNumber}/service-period`,
    {}
  );

export const removeServicePeriodForm190 = (data: DataObj) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/${data?.requestNumber}/service-period/${data?.servicePeriodNo}`,
    undefined,
    data?.requestNumber,
    {}
  );

export const saveForm190 = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const getRetro190 = (requestNumber: string, servicePeriodNo: number) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/${requestNumber}/retropay/${servicePeriodNo}`,
    undefined,
    requestNumber
  );

export const setRetro190 = (
  requestNumber: string,
  servicePeriodNo: number,
  data: any
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/${requestNumber}/retropay/${servicePeriodNo}`,
    data,
    undefined,
    requestNumber
  );

export const deleteRetro190 = (
  requestNumber: string,
  servicePeriodNo: number
) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/${requestNumber}/retropay/${servicePeriodNo}`,
    undefined,
    requestNumber
  );

export const submitForm190 = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e190/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );
