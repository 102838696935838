import Button from 'components/button/Button';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import styles from './ResetButton.module.scss';

interface IResetButton {
  onClick?: Function;
  testingTag?: string;
  isDisabled?: boolean;
}

const ResetButton: React.FC<IResetButton> = ({
  onClick,
  testingTag,
  isDisabled
}: IResetButton) => {
  const { t } = useTranslation('common');

  return (
    <div className={styles.reset}>
      <Button
        baseStyle="basic"
        onClick={onClick}
        testingTag={testingTag}
        isDisabled={isDisabled}
      >
        <ReactSVG src="/images/reset.svg" />
        <span>{t('common:RESET')}</span>
      </Button>
    </div>
  );
};

export default ResetButton;
