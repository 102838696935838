export enum pollingActionTypes {
  POLL_START = 'POLL_START',
  POLL_STOP = 'POLL_STOP',
  POLL_ERROR = 'POLL_ERROR'
}

export const startPoll = (data: any) => ({
  type: pollingActionTypes.POLL_START,
  data
});

export const stopPoll = () => ({
  type: pollingActionTypes.POLL_STOP
});

export const pollError = (data: any) => ({
  type: pollingActionTypes.POLL_ERROR,
  data
});
