import { Reducer, combineReducers } from 'redux';
import { EapAction } from 'types';
import { userSessionActionTypes } from 'containers/auth/userSessionActions';
import userSession from 'containers/auth/userSessionReducer';
import selectEmployers from 'containers/selectEmployers/selectEmployersReducer';
import memberSearch from 'containers/memberSearch/memberSearchReducer';
import properties from 'containers/properties/propertiesReducer';
import overrideReasons from 'containers/overrideReasons/overrideReasonsReducer';
import nft from 'containers/nft/sliceReducer';
import form102 from 'containers/requestForm/memberEnrolment/form102Reducer';
import form105 from 'containers/requestForm/contributionRemittance/form105Reducer';
import form106 from 'containers/requestForm/memberUpdate/form106Reducer';
import form119 from 'containers/requestForm/annualReporting/form119Reducer';
import form143 from 'containers/requestForm/memberEvent/form143Reducer';
import form165a from 'containers/requestForm/leavePeriodEmployer/form165aReducer';
import form165b from 'containers/requestForm/leavePeriodMember/form165bReducer';
import form165c from 'containers/requestForm/leavePeriodAdjustment/form165cReducer';
import form152 from 'containers/form/152/form152Reducer';
import requests from 'containers/requests/requestsReducer';
import commonOverride from 'containers/requestForm/common/formReducer';
import messages from 'containers/messages/messagesReducer';
import retroPayEarnings from 'containers/memberProfile/memberProfileReducer';
import notifications from 'containers/notifications/notificationsReducer';
import idleAction from 'containers/routeConfirm/idleActionReducer';
import featureFlags from 'features/featureFlags/featureFlagsReducer';
import annualDashboard from 'containers/annualDashboard/annualDashboardReducer';
import annualReconciliation from 'containers/annualReconciliation/annualReconciliationReducer';
import batchProcessing from 'containers/batchProcessing/stateManagement/batchProcessingReducer';
import batchPrint from 'containers/batchPrint/BatchPrintReducer';
import dataExtract from 'containers/dataExtract/stateManagement/dataExtractReducer';
import navHistory from 'containers/navHistory/navHistoryReducer';
import returnedRequests from 'containers/returnedRequests/ReturnedRequestsReducer';
import archivedMessages from 'containers/archivedMessages/ArchivedMessagesReducer';
import form190 from 'containers/requestForm/pensionEstimate/form190Reducer';
import resetPassword from 'containers/resetPassword/resetPasswordReducer';
import updatePassword from 'containers/updatePassword/updatePasswordReducer';
import esr from 'containers/stewardshipReport/esrSliceReducer';
import donnaBusinessConfig from 'containers/donnaBusinessConfig/donnaBusinessConfigReducer';
import { toasterReducer } from 'features/v2/toaster';
import { microsoftBookingsDrawerReducer } from 'components/microsoftBookingsDrawer/microsoftBookingsDrawerReducer';
import reports from 'containers/reports/reportsSliceReducer';

const appReducer = combineReducers({
  userSession,
  selectEmployers,
  memberSearch,
  properties,
  overrideReasons,
  nft,
  form102,
  form105,
  form106,
  form119,
  form143,
  form165a,
  form165b,
  form165c,
  form190,
  form152,
  requests,
  commonOverride,
  messages,
  retroPayEarnings,
  notifications,
  idleAction,
  featureFlags,
  annualDashboard,
  annualReconciliation,
  batchProcessing,
  batchPrint,
  dataExtract,
  navHistory,
  returnedRequests,
  resetPassword,
  archivedMessages,
  updatePassword,
  esr,
  donnaBusinessConfig,
  toaster: toasterReducer,
  microsoftBookingsDrawer: microsoftBookingsDrawerReducer,
  reports
});

export type RootState = ReturnType<typeof appReducer>;

const rootReducer: Reducer = (
  state: RootState,
  action: EapAction
): RootState => {
  if (action.type === userSessionActionTypes.LOGOUT_SUCCEEDED_FAILED) {
    return appReducer(undefined, action); // reinitialize entire store on logout
  }

  return appReducer(state, action);
};

export default rootReducer;
