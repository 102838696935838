import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RootState } from 'types';
import Modal from 'components/modal/Modal';
import RouteConfirm from 'containers/routeConfirm/RouteConfirm';

interface SaveProgressModalProps {
  showModal: boolean;
  onSave: Function;
  onDiscard: Function;
  onClose?: Function;
  isDirty: boolean;
  okText?: string;
  titleText?: string;
  bodyText?: string;
  preventNavigationOnOk?: boolean;
}

const SaveProgressModal = ({
  showModal,
  onSave,
  onDiscard,
  onClose = () => {},
  isDirty,
  okText,
  titleText,
  bodyText,
  preventNavigationOnOk = false
}: SaveProgressModalProps) => {
  const { t } = useTranslation('forms');
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );

  if (showModal && !omersUser) {
    return (
      <RouteConfirm
        shouldConfirm={isDirty}
        isCancelAlternativeOk
        preventNavigationOnOk={preventNavigationOnOk}
      >
        <Modal
          visible
          onOk={onSave}
          onCancel={onDiscard}
          onClose={onClose}
          okText={okText || t('FORM_SAVE_PROGRESS_MODAL_SAVE_BTN')}
          cancelText={t('FORM_SAVE_PROGRESS_MODAL_DISCARD_BTN')}
          titleText={titleText || t('FORM_SAVE_PROGRESS_MODAL_TITLE')}
          width={492}
        >
          {bodyText || t('FORM_SAVE_PROGRESS_MODAL_BODY_TEXT')}
        </Modal>
      </RouteConfirm>
    );
  }
  return null;
};

export default SaveProgressModal;
