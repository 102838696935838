import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastProps } from '@blueprintjs/core';

export enum ToastLevel {
  ERROR,
  WARNING,
  SUCCESS,
  INFO
}

export interface Toast extends Omit<ToastProps, 'icon' | 'action' | 'intent'> {
  level: ToastLevel;
  translate?: boolean;
  translateProps?: Record<string, string>;
}

export type ToasterState = {
  toast: Toast | null;
};

export type ToasterAction = PayloadAction<Omit<Toast, 'level'>>;

const initialState: ToasterState = {
  toast: null
};

const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    showWarningToast: (_, { payload }: ToasterAction) => ({
      toast: {
        ...payload,
        level: ToastLevel.WARNING
      }
    }),
    showErrorToast: (_, { payload }: ToasterAction) => ({
      toast: {
        ...payload,
        level: ToastLevel.ERROR
      }
    }),
    showSuccessToast: (_, { payload }: ToasterAction) => ({
      toast: {
        ...payload,
        level: ToastLevel.SUCCESS
      }
    }),
    showInfoToast: (_, { payload }: ToasterAction) => ({
      toast: {
        ...payload,
        level: ToastLevel.INFO
      }
    })
  }
});

export const {
  showWarningToast,
  showErrorToast,
  showSuccessToast,
  showInfoToast
} = toasterSlice.actions;

export const toasterReducer = toasterSlice.reducer;
