import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import {
  AnrModel,
  AnrUnresolvedExceptionRequest,
  AnrActiveUnresolvedExceptionRequest,
  AnrNotInitiatedRequest,
  ExceptionsHashmapObject,
  AnrContributionDetails,
  ExceptionsObject
} from 'interfaces';

export enum annualReconciliationActionTypes {
  GET_ANR_INFO_REQUESTED = 'GET_ANR_INFO_REQUESTED',
  GET_ANR_INFO_SUCCESS = 'GET_ANR_INFO_SUCCESS',
  SET_ANR_MAIN_TAB = 'SET_ANR_MAIN_TAB',
  SET_ANR_TOTALS_TAB = 'SET_ANR_TOTALS_TAB',
  SET_ANR_OTHER_TAB = 'SET_ANR_OTHER_TAB',
  OVERRIDE_ANR_EXCEPTIONS = 'OVERRIDE_ANR_EXCEPTIONS',
  OVERRIDE_ANR_EXCEPTIONS_SUCCESS = 'OVERRIDE_ANR_EXCEPTIONS_SUCCESS',
  OVERRIDE_ANR_EXCEPTIONS_FAILED = 'OVERRIDE_ANR_EXCEPTIONS_FAILED',
  GET_ANR_UNRESOLVED_EXCEPTIONS_REQUESTED = 'GET_ANR_UNRESOLVED_EXCEPTIONS_REQUESTED',
  GET_ANR_UNRESOLVED_EXCEPTIONS_SUCCESS = 'GET_ANR_UNRESOLVED_EXCEPTIONS_SUCCESS',
  GET_ANR_UNRESOLVED_EXCEPTIONS_FAILED = 'GET_ANR_UNRESOLVED_EXCEPTIONS_FAILED',
  SET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS = 'SET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS',
  GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED = 'GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED',
  GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS = 'GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS',
  GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED = 'GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED',
  OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED = 'OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED',
  OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS = 'OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS',
  OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED = 'OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED',
  OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_CLEAR = 'OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_CLEAR',
  GET_ANR_NOT_INITIATED_REQUESTED = 'GET_ANR_NOT_INITIATED_REQUESTED',
  GET_ANR_NOT_INITIATED_SUCCESS = 'GET_ANR_NOT_INITIATED_SUCCESS',
  GET_ANR_NOT_INITIATED_FAILED = 'GET_ANR_NOT_INITIATED_FAILED',
  GET_ANR_CONTRIBUTIONS_REQUESTED = 'GET_ANR_CONTRIBUTIONS_REQUESTED',
  GET_ANR_CONTRIBUTIONS_SUCCESS = 'GET_ANR_CONTRIBUTIONS_SUCCESS',
  GET_ANR_CONTRIBUTIONS_FAILED = 'GET_ANR_CONTRIBUTIONS_FAILED',
  CLEAR_ANR_CONTRIBUTIONS_VIEW = 'CLEAR_ANR_CONTRIBUTIONS_VIEW',
  REQUEST_ANNUAL_PENSION_REPORT_GENERATION = 'REQUEST_ANNUAL_PENSION_REPORT_GENERATION',
  UNLOCK_ANR_REQUESTED = 'UNLOCK_ANR_REQUESTED',
  LOCK_ANR_FAILED = 'LOCK_ANR_FAILED',
  SUBMIT_ANR_REQUEST = 'SUBMIT_ANR_REQUEST'
}

export const getAnrInfoAction: ActionCreator<EapAction> = (data: string) => ({
  type: annualReconciliationActionTypes.GET_ANR_INFO_REQUESTED,
  data
});

export const getAnrInfoSuccessAction: ActionCreator<EapAction> = (
  data: AnrModel
) => ({
  type: annualReconciliationActionTypes.GET_ANR_INFO_SUCCESS,
  data
});

export const setAnrMainTab: ActionCreator<EapAction> = (
  data: 'totals' | 'others' | 'contributions' | 'pension-reports'
) => ({
  type: annualReconciliationActionTypes.SET_ANR_MAIN_TAB,
  data
});

export const setAnrTotalsTab: ActionCreator<EapAction> = (
  data: 'not-submitted' | 'unresolved-exceptions' | 'not-initiated'
) => ({
  type: annualReconciliationActionTypes.SET_ANR_TOTALS_TAB,
  data
});

export const setAnrOtherTab: ActionCreator<EapAction> = (
  data: 'inactive-memberships' | 'adjustments' | 'form-143'
) => ({
  type: annualReconciliationActionTypes.SET_ANR_OTHER_TAB,
  data
});

export const overrideAnrExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: annualReconciliationActionTypes.OVERRIDE_ANR_EXCEPTIONS,
  data
});

export const overrideExceptionsSuccessAction: ActionCreator<EapAction> = () => ({
  type: annualReconciliationActionTypes.OVERRIDE_ANR_EXCEPTIONS_SUCCESS
});

export const overrideExceptionsFailedAction: ActionCreator<EapAction> = () => ({
  type: annualReconciliationActionTypes.OVERRIDE_ANR_EXCEPTIONS_FAILED
});

export const getAnrUnresolvedExceptionsAction: ActionCreator<EapAction> = (data: {
  startIndex: number;
  rowLimit: number;
}) => ({
  type: annualReconciliationActionTypes.GET_ANR_UNRESOLVED_EXCEPTIONS_REQUESTED,
  data
});

export const getAnrUnresolvedExceptionsSuccessAction: ActionCreator<EapAction> = (data: {
  totalRowCount: number;
  requests: Array<AnrUnresolvedExceptionRequest>;
}) => ({
  type: annualReconciliationActionTypes.GET_ANR_UNRESOLVED_EXCEPTIONS_SUCCESS,
  data
});

export const getAnrUnresolvedExceptionsFailedAction: ActionCreator<EapAction> = () => ({
  type: annualReconciliationActionTypes.GET_ANR_UNRESOLVED_EXCEPTIONS_FAILED
});

export const setAnrActiveUnresolvedExceptionsAction: ActionCreator<EapAction> = (
  data: AnrUnresolvedExceptionRequest
) => ({
  type: annualReconciliationActionTypes.SET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS,
  data
});

export const getAnrActiveUnresolvedExceptionsAction: ActionCreator<EapAction> = (data: {
  environmentId: string;
  messageId: string;
  startIndex: number;
  rowLimit: number;
}) => ({
  type:
    annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED,
  data
});

export const getAnrActiveUnresolvedExceptionsSuccessAction: ActionCreator<EapAction> = (data: {
  totalRowCount: number;
  requests: Array<AnrActiveUnresolvedExceptionRequest>;
  overrideReasons: Array<ExceptionsHashmapObject>;
}) => ({
  type:
    annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS,
  data
});

export const getAnrActiveUnresolvedExceptionsFailedAction: ActionCreator<EapAction> = () => ({
  type:
    annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED
});

export const overrideActiveUnresolvedExceptionsAction: ActionCreator<EapAction> = (data: {
  requests: Array<any>;
  messageId: string;
  overrideReason: string;
  overrideExplanation: string;
}) => ({
  type:
    annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED,
  data
});

export const overrideActiveUnresolvedExceptionsSuccessAction: ActionCreator<EapAction> = () => ({
  type:
    annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS
});

export const overrideActiveUnresolvedExceptionsFailedAction: ActionCreator<EapAction> = () => ({
  type:
    annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED
});

export const overrideActiveUnresolvedExceptionsClearAction: ActionCreator<EapAction> = () => ({
  type:
    annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_CLEAR
});

export const getAnrNotInitiatedAction: ActionCreator<EapAction> = (data: {
  startIndex: number;
  rowLimit: number;
}) => ({
  type: annualReconciliationActionTypes.GET_ANR_NOT_INITIATED_REQUESTED,
  data
});

export const getAnrNotInitiatedSuccessAction: ActionCreator<EapAction> = (data: {
  totalRowCount: number;
  requests: Array<AnrNotInitiatedRequest>;
}) => ({
  type: annualReconciliationActionTypes.GET_ANR_NOT_INITIATED_SUCCESS,
  data
});

export const getAnrNotInitiatedFailedAction: ActionCreator<EapAction> = () => ({
  type: annualReconciliationActionTypes.GET_ANR_NOT_INITIATED_FAILED
});

export const getAnrContributionsAction: ActionCreator<EapAction> = (data: {
  type: string;
  view: string;
  path: string;
}) => ({
  type: annualReconciliationActionTypes.GET_ANR_CONTRIBUTIONS_REQUESTED,
  data
});

export const getAnrContributionsSuccessAction: ActionCreator<EapAction> = (data: {
  view: string;
  data: AnrContributionDetails;
}) => ({
  type: annualReconciliationActionTypes.GET_ANR_CONTRIBUTIONS_SUCCESS,
  data
});

export const getAnrContributionsFailedAction: ActionCreator<EapAction> = () => ({
  type: annualReconciliationActionTypes.GET_ANR_CONTRIBUTIONS_FAILED
});

export const clearAnrContributionsViewAction: ActionCreator<EapAction> = () => ({
  type: annualReconciliationActionTypes.CLEAR_ANR_CONTRIBUTIONS_VIEW
});

export const unlockAnrAction: ActionCreator<EapAction> = (data: string) => ({
  type: annualReconciliationActionTypes.UNLOCK_ANR_REQUESTED,
  data
});

export const requestAnnualPensionReportGenerationAction: ActionCreator<EapAction> = (data: {
  requestNo: string;
}) => ({
  type:
    annualReconciliationActionTypes.REQUEST_ANNUAL_PENSION_REPORT_GENERATION,
  data
});

export const lockAnrFailedAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: annualReconciliationActionTypes.LOCK_ANR_FAILED,
  data
});

export const submitAnrRequestAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: annualReconciliationActionTypes.SUBMIT_ANR_REQUEST,
  data
});
