import { useEffect, useRef, useState, useCallback } from 'react';
import * as React from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'types';
import { runIdleAction, clearIdleAction } from './idleActionActions';

interface RouteConfirmProps {
  shouldConfirm: boolean;
  onOkStarted?: Function;
  isCancelAlternativeOk?: boolean;
  preventNavigationOnOk?: boolean;
  children: React.ReactElement;
}

const RouteConfirm = ({
  shouldConfirm,
  onOkStarted,
  preventNavigationOnOk = false,
  isCancelAlternativeOk = false,
  children
}: RouteConfirmProps) => {
  const Router = useRouter();
  const dispatch = useDispatch();
  const { bypassRouteConfirm } = useSelector(
    (state: RootState) => state.idleAction
  );
  const [open, setOpen] = useState(false);
  const discard = useRef(false);
  const pendingUrl = useRef<string | null>(null);
  const confirm = useRef(false);

  const handleRouteChange = useCallback(
    (url: string) => {
      if (!bypassRouteConfirm) {
        if (discard.current) {
          return;
        }

        if (shouldConfirm) {
          pendingUrl.current = url;
          setOpen(true);
        } else {
          confirm.current = true;
          setOpen(false);
        }

        if (!confirm.current || shouldConfirm) {
          // reset the url path to prevent browser from showing failed route in url bar
          window.history.replaceState({}, '', Router.asPath);

          // https://github.com/zeit/next.js/issues/2236
          // https://github.com/zeit/next.js/issues/2476#issuecomment-563190607
          throw 'Stop route change. Ignore the error.'; // eslint-disable-line no-throw-literal
        }
      }
    },
    [shouldConfirm, bypassRouteConfirm]
  );

  const handleRouteComplete = () => {
    pendingUrl.current = null;
    confirm.current = false;
    setOpen(false);
  };

  const onOk = (preventNav = false) => {
    if (pendingUrl.current !== null && !preventNav) {
      const targetUrl = pendingUrl?.current ?? '';
      pendingUrl.current = null;
      confirm.current = true;
      discard.current = true;

      Router.push(targetUrl).then(() => {
        if (onOkStarted) {
          onOkStarted();
        }

        // re-run idle action cancelled from RouteConfirm
        dispatch(runIdleAction());
      });
    } else {
      // alternative logic flow does not route on ok
      pendingUrl.current = null;
      confirm.current = false;
      setOpen(false);

      if (onOkStarted) {
        onOkStarted();
      }

      // clear potential idle action cancelled from RouteConfirm
      dispatch(clearIdleAction());
    }
  };

  const onCancel = () => {
    // enable optional cancel act as secondary ok with alternative functionality
    if (isCancelAlternativeOk) {
      onOk();
    } else {
      pendingUrl.current = null;
      confirm.current = false;
      setOpen(false);

      // clear potential idle action cancelled from RouteConfirm
      dispatch(clearIdleAction());
    }
  };

  const onClose = () => {
    pendingUrl.current = null;
    confirm.current = false;
    setOpen(false);

    // clear potential idle action cancelled from RouteConfirm
    dispatch(clearIdleAction());
  };

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      Router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [shouldConfirm, bypassRouteConfirm, discard, handleRouteChange]);

  useEffect(() => {
    Router.events.on('routeChangeComplete', handleRouteComplete);
    Router.events.on('routeChangeError', handleRouteComplete);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteComplete);
      Router.events.off('routeChangeError', handleRouteComplete);
    };
  }, []);

  useEffect(() => {
    const beforeunload = (event: any) => {
      if (shouldConfirm) {
        event.preventDefault();
        // only IE support custom message
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      } else {
        // eslint-disable-next-line no-param-reassign
        delete event.returnValue;
      }
    };

    window.addEventListener('beforeunload', beforeunload);
    return () => {
      window.removeEventListener('beforeunload', beforeunload);
    };
  }, [shouldConfirm]);

  const childrenWithHandlers = React.Children.map(children, child =>
    React.cloneElement(child, {
      onOk: async () => {
        if (child.props.onOk) {
          await child.props.onOk();
        }
        onOk(preventNavigationOnOk);
      },
      onCancel: async () => {
        if (child.props.onCancel) {
          await child.props.onCancel();
        }
        onCancel();
      },
      onClose: async () => {
        if (child.props.onClose) {
          await child.props.onClose();
        }
        onClose();
      }
    })
  );

  return open ? <>{childrenWithHandlers}</> : null;
};

export default RouteConfirm;
