import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { postData, getData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const searchReturnedRequests = (payload: any) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss/search`, payload);

export const getReturnedRequestsMessageThread = (correspondenceNo: string) =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss/${correspondenceNo}`);

export const sendReturnedRequestsReply = (
  correspondenceNo: string,
  message: string
) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss`, {
    correspondenceNo,
    message
  });
