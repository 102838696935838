import { createSlice } from '@reduxjs/toolkit';
import { MSSReport } from './types';

export interface ReportsState {
  newMssReportsCount: number;
  mssReports: MSSReport[];
  showMssReportsErrorScreen: boolean;
}

export const initialState: ReportsState = {
  newMssReportsCount: 0,
  mssReports: [],
  showMssReportsErrorScreen: false
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    getMSSReportsAction: () => {},
    getMssReportsSucceededAction: (draft, { payload }) => {
      draft.mssReports = payload.mssReports;
      draft.newMssReportsCount = payload.newMssReportsCount;
      draft.showMssReportsErrorScreen = false;
    },
    getMssReportsFailedAction: (draft, { payload }) => {
      draft.showMssReportsErrorScreen = payload.showMssReportsErrorScreen;
    }
  }
});

export const {
  getMSSReportsAction,
  getMssReportsSucceededAction,
  getMssReportsFailedAction
} = reportsSlice.actions;

export default reportsSlice.reducer;
