import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

/**
 * Custom common form hook to clear cached form overrides (by given action)
 * on component exit; intent is to always clear cached override exceptions when
 * exiting the form review page
 */
const useClearOverrideExceptions = (clearOverridesAction: any) => {
  const dispatch = useDispatch();

  useEffect(
    () => () => {
      dispatch(clearOverridesAction);
    },
    []
  );
};

export default useClearOverrideExceptions;
