import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { uniqBy } from 'lodash';

import { PropertiesObjectItem } from 'interfaces';
import { getPropertiesList, propertiesSelector } from 'utils';

export interface MemoizedProperties {
  [key: string]: Array<PropertiesObjectItem>;
}

/**
 * React hook builds memoized properties map from given keys tuple, where first
 * prop of keys tuple is output map key name and second prop is reference
 * properties id name.
 *
 * example:
 *   useProperties([
 *     ['sex', 'Sex'],
 *     ['occupation', 'Occupation Code']
 *   ]);
 *
 *   Results ==> {
 *     sex: [{ key: 'F', val: 'F' }, ...],
 *     occupation: [{ key: 'C', val: 'Councillor' }, ...]
 *   }
 */
const useProperties = (keys: Array<[string, string]>): MemoizedProperties => {
  const properties = useSelector(propertiesSelector);

  return useMemo(
    () =>
      keys.reduce(
        (acc, [key, propRef]) => ({
          ...acc,
          [key]: uniqBy(getPropertiesList(propRef, properties), 'key')
        }),
        {}
      ),
    [keys, properties]
  );
};

export default useProperties;
