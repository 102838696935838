export const buildData = (data: any) => [
  ...data?.map((req: any) => ({
    requestNo: Number(req?.requestNo),
    userId: req?.userId,
    createdDates: req?.createdDates,
    memberFirstName: req?.membershipForm143Info?.memberFirstName,
    memberMiddleName: req?.membershipForm143Info?.memberMiddleName,
    memberLastName: req?.membershipForm143Info?.memberLastName,
    memberMembershipNumber: req?.membershipForm143Info?.memberMembershipNumber,
    memberEmployeeId: req?.memberEmployeeId,
    memberNRA: req?.membershipForm143Info?.memberNRA,
    memberEmploymentStatus: req?.membershipForm143Info?.memberEmploymentStatus,
    memberSelected: req?.memberSelected === 'Y' ? 'Y' : 'N',
    attachments: req?.attachments
  }))
];
