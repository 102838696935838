/* eslint-disable camelcase */
import {
  all,
  takeLatest,
  takeEvery,
  takeLeading,
  select,
  put,
  delay,
  call
} from 'redux-saga/effects';
import Router from 'next/router';
import { format } from 'url';

import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import { RootState, EapAction } from 'types';
import {
  createForm119,
  getForm119,
  upsertForm119,
  submitForm119,
  memberSearch,
  getRetroPayData,
  updateRetroPayData,
  deleteRetroPayData,
  getReconciliationYears,
  getMemberDetails
} from 'services';
import {
  lockFormSaga,
  unlockFormSaga,
  deleteFormSaga,
  clearLockedFormNotificationSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import { getApiErrorMessage } from 'utils';
import {
  notifyErrorAction,
  clearNotifications,
  clearLockedFormNotificationAction,
  notifyLockedFormErrorAction
} from 'containers/notifications/notificationsActions';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import mapForm119 from './mapForm119';
import {
  form119ActionTypes,
  createForm119Action,
  clearRetroPay,
  resetValidMember,
  memberProfileValid,
  memberProfileInvalid,
  setForm119StepAction,
  setForm119ExceptionOverrides
} from './form119Actions';
import { formatPayload } from './AnnualReporting';
import { initialState } from './form119Reducer';

const requestNumberSelector119 = (state: any) => state.form119.requestNumber;

export function* createForm119Saga(action: EapAction): any {
  yield put(startLoadingAction());
  yield put({
    type: form119ActionTypes.VALID_MEMBER_RESET_119
  });

  const res = yield call(safeAuthEffect, createForm119, action.data);
  if (res.data) {
    // route to newly created
    yield Router.push(
      '/form/119/[id]',
      format({ pathname: `/form/119/${res.data.requestNumber}` })
    );

    yield call(lockFormSaga, { ...action, data: res.data.requestNumber });
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
    yield put(clearLoadingAction());
  }
}

export function* getForm119Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data.requestNo;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  const formRes = yield call(safeAuthEffect, getForm119, requestNumber);
  const memberSearchRes = yield call(safeAuthEffect, memberSearch, {
    omersMembershipNumber: formRes?.data?.e119Model?.membershipNo,
    employerNumber: formRes?.data?.e119Model?.employerNo
  });

  if (formRes.data) {
    if (action.data.openFormToReview) {
      yield put(setForm119StepAction(2));
      yield put(setForm119ExceptionOverrides());
    } else {
      yield put(setForm119StepAction(1));
    }

    yield put({
      type: form119ActionTypes.GET_FORM_119_SUCCEEDED,
      data: {
        employerNo: formRes.data.e119Model.employerNo,
        requestNumber,
        formData: mapForm119(formRes.data),
        memberInfo:
          memberSearchRes?.data?.memberSearchResult?.memberships[0] ?? null,
        validEmailAddressState:
          formRes.data?.e102Model?.email?.validated === 'Y'
            ? QASEmailResponseTypes.VERIFIED
            : QASEmailResponseTypes.NOT_VERIFIED,
        validEmailAddress:
          formRes.data?.e102Model?.email?.validated === 'Y'
            ? {
                email: formRes.data?.e119Model?.employeeModel?.email ?? ''
              }
            : initialState.validEmailAddress
      }
    });
    yield put(clearLockedFormNotificationAction());
    if (Router.route !== '/form/119/[id]') {
      yield Router.push(
        '/form/119/[id]',
        format({ pathname: `/form/119/${requestNumber}` })
      );
    }
  } else {
    yield put({
      type: form119ActionTypes.GET_FORM_119_FAILED
    });
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(formRes?.errors)));

    // navigate off the request if user is already there
    if (Router.route === '/form/119/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }
  yield put({
    type: form119ActionTypes.SET_FORM_119_STATUS,
    data: ''
  });

  yield put(clearLoadingAction());

  // clear potential locked form notification after delay
  yield call(clearLockedFormNotificationSaga);
}

// Do not remove
/**
 * get latest 119 data without GET_119 side-effects such as locking the form or changing the step number
 */
export function* fetchLatest119Saga(action: EapAction): any {
  const requestNumber = action.data;
  const formRes = yield call(safeAuthEffect, getForm119, requestNumber);
  const memberSearchRes = yield call(safeAuthEffect, memberSearch, {
    omersMembershipNumber: formRes?.data?.e119Model?.membershipNo,
    employerNumber: formRes?.data?.e119Model?.employerNo
  });
  if (formRes.data) {
    yield put({
      type: form119ActionTypes.FETCH_LATEST_119_SUCCEEDED,
      data: {
        requestNumber,
        formData: mapForm119(formRes.data),
        memberInfo:
          memberSearchRes?.data?.memberSearchResult?.memberships[0] ?? null
      }
    });
  } else {
    yield put({
      type: form119ActionTypes.FETCH_LATEST_119_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(formRes?.errors)));
  }
}

export function* saveForm119Saga(action: EapAction): any {
  const requestNumber = yield select(requestNumberSelector119);
  const res = yield call(
    safeAuthEffect,
    upsertForm119,
    action.data,
    requestNumber
  );
  if (res.data) {
    yield put({
      type: form119ActionTypes.SAVE_FORM_119_SUCCEEDED
    });
    yield put(clearNotifications());
    yield put({
      type: form119ActionTypes.FETCH_LATEST_119_REQUESTED,
      data: requestNumber
    });
  } else {
    yield put({
      type: form119ActionTypes.SAVE_FORM_119_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

/**
 * Save Form 119 and yield the passed in action on successful save
 */
export function* saveForm119AndPerformNewActionSaga(action: EapAction): any {
  const { actionType, actionData } = action.data;
  const requestNumber = yield select(requestNumberSelector119);
  const { e119Model } = yield select(
    (state: RootState) => state.form119.form119Data
  );
  const payload = formatPayload(
    requestNumber,
    e119Model,
    e119Model?.financialModel?.paMandatory
  );
  const res = yield call(safeAuthEffect, upsertForm119, payload, requestNumber);
  if (res.data) {
    yield put({
      type: form119ActionTypes.SAVE_119_AND_PERFORM_ACTION_SUCCEEDED
    });
    yield put(clearNotifications());
    yield put({
      type: actionType,
      data: actionData
    });
  } else {
    yield put({
      type: form119ActionTypes.SAVE_119_AND_PERFORM_ACTION_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

function* deleteForm119Saga(action: EapAction) {
  yield deleteFormSaga(action, {
    deleteSuccessAction: {
      type: form119ActionTypes.DELETE_FORM_119_SUCCEEDED
    },
    deleteFailedAction: {
      type: form119ActionTypes.DELETE_FORM_119_FAILED
    }
  });
}

function* resetform119saga(action: EapAction) {
  yield deleteFormSaga(action.data.deleteAction, {
    deleteSuccessAction: {
      type: form119ActionTypes.DELETE_FORM_119_SUCCEEDED
    },
    deleteFailedAction: {
      type: form119ActionTypes.DELETE_FORM_119_FAILED
    }
  });
  yield put(createForm119Action(action.data.create119Data));
  yield put(clearLoadingAction());
}

export function* updateAndSubmitForm119Saga(action: EapAction): any {
  yield put(startLoadingAction());
  const requestNumber = yield select(requestNumberSelector119);
  const res = yield call(
    safeAuthEffect,
    upsertForm119,
    action.data,
    requestNumber
  );

  if (res.data) {
    const result = yield call(
      safeAuthEffect,
      submitForm119,
      undefined,
      requestNumber
    );

    if (result.data && result.data.submitResponse) {
      yield put({
        type: form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_SUCCEEDED,
        data: result.data
      });
      yield put(clearNotifications());
    } else {
      yield put({
        type: form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_FAILED
      });
      yield put(notifyErrorAction(getApiErrorMessage(result?.errors)));
    }
  } else {
    yield put({
      type: form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

export function* getRetroPaySaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getRetroPayData, action.data);
  if (res.data && res.data.retroPayModels && res.data.retroPayModels[0]) {
    yield put({
      type: form119ActionTypes.GET_RETRO_PAY_SUCCEEDED,
      data: res.data.retroPayModels
    });
    yield put(clearNotifications());
  } else {
    yield put({
      type: form119ActionTypes.GET_RETRO_PAY_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

export function* updateRetroPaySaga(action: EapAction): any {
  yield put(startLoadingAction());
  const requestNumber = yield select(requestNumberSelector119);
  const res = yield call(
    safeAuthEffect,
    updateRetroPayData,
    action.data,
    requestNumber
  );
  if (res.data) {
    yield put({
      type: form119ActionTypes.UPDATE_RETRO_PAY_SUCCEEDED,
      data: res.data
    });
    yield put(clearNotifications());
    yield put({
      type: form119ActionTypes.FETCH_LATEST_119_REQUESTED,
      data: requestNumber
    });
  } else {
    yield put({
      type: form119ActionTypes.UPDATE_RETRO_PAY_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

export function* deleteRetroPaySaga(action: EapAction): any {
  yield put(startLoadingAction());
  const requestNumber = yield select(requestNumberSelector119);
  const res = yield call(
    safeAuthEffect,
    deleteRetroPayData,
    action.data,
    requestNumber
  );
  if (res.status === 204 || res.status === 200) {
    yield put({
      type: form119ActionTypes.DELETE_RETRO_PAY_SUCCEEDED,
      data: res.data
    });
    yield put(clearNotifications());
    yield put(clearRetroPay());
    yield put({
      type: form119ActionTypes.FETCH_LATEST_119_REQUESTED,
      data: requestNumber
    });
  } else {
    yield put({
      type: form119ActionTypes.DELETE_RETRO_PAY_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

function* clearStatusSaga() {
  yield delay(3000);
  yield put({
    type: form119ActionTypes.RESET_FORM_119_STATUS
  });
}

function* clearSubmitStatusSaga() {
  yield delay(3000);
  yield put({
    type: form119ActionTypes.RESET_FORM_119_SUBMIT_STATUS
  });
}

export function* checkMemberValiditySaga(action: EapAction): any {
  const res = yield call(safeAuthEffect, getMemberDetails, {
    membershipNumber: action.data.membershipNumber,
    employmentNumber: action.data.mssemploymentItem?.employmentNumber,
    employerNumber:
      action.data.mssemploymentItem?.theMSSEmployer?.employerNumber,
    ctx: 'MIS'
  });
  yield put(resetValidMember());
  if (res.data) {
    yield put(memberProfileValid(action.data));
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(memberProfileInvalid());
  }
}

export function* getReconciliationYearsSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    getReconciliationYears,
    action.data.employerNo,
    action.data.membershipNo
  );
  if (res.data) {
    yield put({
      type: form119ActionTypes.GET_RECONCILIATON_YEARS_SUCCEEDED,
      data: res.data
    });
  } else {
    yield put({
      type: form119ActionTypes.DELETE_RETRO_PAY_FAILED
    });
  }
  yield put(clearLoadingAction());
}

export function* form119Saga(): any {
  yield all([
    yield takeLeading(form119ActionTypes.CREATE_FORM_119, createForm119Saga),
    yield takeLatest(form119ActionTypes.GET_FORM_119_REQUESTED, getForm119Saga),
    yield takeLatest(
      form119ActionTypes.DELETE_FORM_119_REQUESTED,
      deleteForm119Saga
    ),
    yield takeEvery(
      form119ActionTypes.SAVE_FORM_119_REQUESTED,
      saveForm119Saga
    ),
    yield takeLatest(
      form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_REQUESTED,
      updateAndSubmitForm119Saga
    ),
    yield takeLatest(
      [
        form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_SUCCEEDED,
        form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_FAILED
      ],
      clearSubmitStatusSaga
    ),
    yield takeLatest(
      [
        form119ActionTypes.SAVE_FORM_119_SUCCEEDED,
        form119ActionTypes.SAVE_FORM_119_FAILED
      ],
      clearStatusSaga
    ),
    yield takeLatest(
      [form119ActionTypes.CLEAR_FORM_119, form119ActionTypes.UNLOCK_FORM_119],
      unlockFormSaga
    ),
    yield takeLatest(
      form119ActionTypes.GET_RETRO_PAY_REQUESTED,
      getRetroPaySaga
    ),
    yield takeLatest(
      form119ActionTypes.UPDATE_RETRO_PAY_REQUESTED,
      updateRetroPaySaga
    ),
    yield takeLatest(
      form119ActionTypes.DELETE_RETRO_PAY_REQUESTED,
      deleteRetroPaySaga
    ),
    yield takeLatest(
      form119ActionTypes.SAVE_119_AND_PERFORM_ACTION_REQUESTED,
      saveForm119AndPerformNewActionSaga
    ),
    yield takeLatest(
      form119ActionTypes.FETCH_LATEST_119_REQUESTED,
      fetchLatest119Saga
    ),
    yield takeLatest(
      form119ActionTypes.GET_RECONCILIATON_YEARS_REQUESTED,
      getReconciliationYearsSaga
    ),
    yield takeLatest(
      form119ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_119_REQUESTED,
      checkMemberValiditySaga
    ),
    yield takeLatest(
      form119ActionTypes.REFRESH_FORM_119_REQUESTED,
      resetform119saga
    )
  ]);
}
