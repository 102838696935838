import { isValid, parse, isPast, isBefore } from 'date-fns';
import { TFunction } from 'i18next';
import { isEqual } from 'lodash';
import {
  sinPattern,
  validMembershipNumber,
  restrictedBeforeAtSignPattern,
  restrictedDomainNamePattern,
  restrictedTopLevelDomainPattern,
  phonePattern
} from './regexPatterns';

/**
 * Luhn Algorithm for SIN validation
 * @param sin {string} unmasked sin string '256808900'
 * @returns isValid {boolean}
 */
export const validateSin = (sin: string) => {
  let X = 0;
  let Y = 0;
  let L = 0;
  let T = 0;
  let H: any = 0;
  let roundedNumber = 0;
  let sumNumber = 0;
  let subtractedNumber = 0;
  for (let i = 0; i < 4; i += 1) {
    L = i * 2 + 1;
    X += Number(sin.substring(L - 1, L));
  }
  for (let z = 1; z < 5; z += 1) {
    T = z * 2;
    H = `${Number(sin.substring(T - 1, T)) * 2}`;
    if (H.length === 2) {
      Y += parseInt(H.substring(0, 1), 10) + parseInt(H.substring(1, 2), 10);
    } else {
      Y += parseInt(H, 10);
    }
  }
  sumNumber = X + Y;
  roundedNumber = Math.ceil(sumNumber / 10) * 10;
  subtractedNumber = roundedNumber - sumNumber;
  if (subtractedNumber === parseInt(sin.substr(8, 1), 10)) {
    // pass
    return true;
  }
  // fail
  return false;
};

export const isValidSin = (sin: string | undefined) => {
  if (sin?.length === 0) {
    return true;
  }
  if (sin?.match(sinPattern)) {
    const unmaskedSIN = sin?.replace(/ /g, '');
    return validateSin(unmaskedSIN);
  }
  return false;
};

/**
 * Validate if string is of format 4444444 or 4444444-44
 */
export const isValidMembershipNumber = (
  membershipNumber: string | undefined
): boolean => {
  if (membershipNumber?.length === 0) {
    return true;
  }
  if (membershipNumber?.length === 7) {
    if (membershipNumber?.match(/(\d){7}/)) {
      return true;
    }
  }
  if (membershipNumber?.match(validMembershipNumber)) {
    return true;
  }
  return false;
};

export const isInThePast = (year: string, month: string, day: string) =>
  isPast(
    new Date(parseInt(year, 10), parseInt(month, 10) - 1, parseInt(day, 10))
  );

export const isInThePastSingleField = (value: string) => {
  if (value.length === 10) {
    const month = Number(value.slice(0, 2)) - 1;
    const day = Number(value.slice(3, 5));
    const year = Number(value.slice(6, 10));
    return isPast(new Date(year, month, day));
  }
  return true;
};

export const validateDayMonthYear = (
  day: string,
  month: string,
  year: string
) => isValid(parse(`${month}/${day}/${year}`, 'MM/dd/yyyy', new Date()));

export const validateDayMonthYearSingleField = (value: string | undefined) => {
  if (value && value.length === 10) {
    const month = value.slice(0, 2);
    const day = value.slice(3, 5);
    const year = value.slice(6, 10);
    return validateDayMonthYear(day, month, year);
  }
  return true;
};

export function isDateValidations(
  dateDay: string,
  dateMonth: string,
  dateYear: string,
  otherDateDay: string,
  otherDateMonth: string,
  otherDateYear: string,
  isDateBefore?: boolean,
  isDateEqual?: boolean
) {
  if (
    dateYear &&
    dateYear.length === 4 &&
    dateMonth &&
    dateDay &&
    otherDateDay &&
    otherDateMonth &&
    otherDateYear &&
    otherDateYear.length === 4
  ) {
    const date = new Date(
      Number(dateYear),
      Number(dateMonth) - 1,
      Number(dateDay)
    );
    const otherDate = new Date(
      Number(otherDateYear),
      Number(otherDateMonth) - 1,
      Number(otherDateDay)
    );
    return isDateBefore
      ? isBefore(date, otherDate)
      : isDateEqual
      ? isEqual(date, otherDate)
      : false;
  }
  return false;
}

export const isDateEqualsDate = (
  dateDay: string,
  dateMonth: string,
  dateYear: string,
  otherDateDay: string,
  otherDateMonth: string,
  otherDateYear: string
) => {
  if (
    dateYear &&
    dateYear.length === 4 &&
    dateMonth &&
    dateDay &&
    otherDateDay &&
    otherDateMonth &&
    otherDateYear &&
    otherDateYear.length === 4
  ) {
    const date = new Date(
      Number(dateYear),
      Number(dateMonth) - 1,
      Number(dateDay)
    );
    const otherDate = new Date(
      Number(otherDateYear),
      Number(otherDateMonth) - 1,
      Number(otherDateDay)
    );
    return isEqual(date, otherDate);
  }
  return false;
};

export const isDateBeforeHireDateSingleField = (
  hireDate: string,
  enrolDate: string
) => {
  const dateDay = enrolDate.slice(3, 5);
  const dateMonth = enrolDate.slice(0, 2);
  const dateYear = enrolDate.slice(6, 10);
  const hireDateDay = hireDate.slice(3, 5);
  const hireDateMonth = hireDate.slice(0, 2);
  const hireDateYear = hireDate.slice(6, 10);
  return !isDateValidations(
    dateDay,
    dateMonth,
    dateYear,
    hireDateDay,
    hireDateMonth,
    hireDateYear,
    true,
    false
  );
};

export const validateYearRange = (val: any) => {
  if (val !== undefined) {
    return Number(val) >= 1900;
  }
  return true;
};

export const createYearRangeValidator = (lowerBound: number = 1900) => (
  val?: string | number
) => {
  if (val !== undefined) {
    return Number(val) >= lowerBound;
  }
  return true;
};

export const validateEmailAddress = (val: string, t: TFunction) => {
  const maxCharsBeforeAtSign = 44;
  const maxDomainNameLength = 30;
  if (val[0] === '.') {
    return t('validations:VALIDATION_EMAIL_START_WITH_PERIOD');
  }
  const hasWhitespace = val.match(/\s/g);
  if (hasWhitespace) {
    return t('validations:VALIDATION_EMAIL_NO_SPACE');
  }
  if (val.indexOf('@') === -1) {
    return t('validations:VALIDATION_EMAIL_@REQUIRED');
  }
  const splitted = val.split('@');
  const beforeAt = splitted[0];
  const afterAt = splitted[1] || '';
  if (beforeAt.length === 0) {
    return t('validations:VALIDATION_EMAIL_LOCAL_MIN_LENGTH');
  }
  const beforeInvalid = beforeAt.match(restrictedBeforeAtSignPattern);
  if (beforeInvalid) {
    const invalidChars = beforeInvalid.join(' ');
    return t('validations:VALIDATION_EMAIL_INVALID_BEFORE_@', { invalidChars });
  }
  if (!afterAt) {
    return t('validations:VALIDATION_EMAIL_INVALID');
  }
  if (beforeAt.length > maxCharsBeforeAtSign) {
    return t('validations:VALIDATION_EMAIL_LOCAL_LENGTH');
  }
  if (afterAt) {
    const dotIndex = afterAt.lastIndexOf('.');
    if (dotIndex === -1) {
      return t('validations:VALIDATION_EMAIL_INVALID');
    }
    const domainName = afterAt.slice(0, dotIndex);
    if (domainName.length === 0) {
      return t('validations:VALIDATION_EMAIL_DOMAIN_MIN_LENGTH');
    }
    const topLevelDomain = afterAt.slice(dotIndex + 1);
    const lastCharacterBeforeTLD = domainName[domainName.length - 1];
    const domainNameInvalid = domainName.match(restrictedDomainNamePattern);
    if (domainNameInvalid) {
      const invalidChars = domainNameInvalid.join(' ');
      return t('validations:VALIDATION_EMAIL_INVALID_AFTER_@', {
        invalidChars
      });
    }
    if (lastCharacterBeforeTLD === '-' || lastCharacterBeforeTLD === '.') {
      return t('validations:VALIDATION_EMAIL_INVALID_BEFORE_TLD');
    }
    if (!topLevelDomain) {
      return t('validations:VALIDATION_EMAIL_INVALID');
    }
    const topLevelDomainInvalid = topLevelDomain.match(
      restrictedTopLevelDomainPattern
    );
    if (topLevelDomainInvalid) {
      const invalidChars = topLevelDomainInvalid.join(' ');
      return t('validations:VALIDATION_EMAIL_INVALID_AFTER_PERIOD', {
        invalidChars
      });
    }
    if (topLevelDomain.length < 2) {
      return t('validations:VALIDATION_EMAIL_TLD_LENGTH');
    }
    if (domainName.length > maxDomainNameLength) {
      return t('validations:VALIDATION_EMAIL_DOMAIN_LENGTH');
    }
  }
  return '';
};

/**
 * determine whether filename is valid
 */
export const isFileNameValid = (filename: string) => {
  const allowedCharacters = /[a-zà-ÿ0-9()._-]+/gi;
  // eslint-disable-next-line no-useless-escape
  const disallowedCharacters = /[\\{}[\]:;"'<>$\`~!@#&%^*+=|\/?,]+/gi;
  if (
    allowedCharacters.test(filename) &&
    !disallowedCharacters.test(filename)
  ) {
    return true;
  }
  return false;
};

/**
 * get file extension from file name
 */
export const getFileExtension = (filename: string) => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex !== -1) {
    return filename.slice(dotIndex + 1).toLowerCase();
  }
  return '';
};

/**
 * validate file type of the file being uploaded
 */
export const isValidFileType = (filename: string) => {
  if (filename) {
    const type = getFileExtension(filename);
    switch (type) {
      case 'csv':
      case 'doc':
      case 'docx':
      case 'jpeg':
      case 'jpg':
      case 'pdf':
      case 'png':
      case 'xls':
      case 'xlsx':
        return true;
      default:
        return false;
    }
  }
  return false;
};

/**
 * validate file name length
 */
export const isFileNameLengthValid = (
  name: string,
  maxFileNameLength: number
) => {
  if (name.length > maxFileNameLength) {
    return false;
  }
  return true;
};

export const isFileNameDuplicated = (
  name: string,
  filesPendingUpload: Array<any>
) => {
  if (filesPendingUpload.filter(file => file.name === name).length > 0) {
    return false;
  }
  return true;
};

/**
 * validate phone number in format 123-456-7890
 */
export const isValidPhoneNumber = (phoneNumber: string) =>
  phonePattern.test(phoneNumber);
