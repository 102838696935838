import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import { ExceptionsObject } from 'interfaces';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { Form102Data } from './form102Reducer';

export enum form102ActionTypes {
  SET_FORM_102_REQUEST_NUMBER = 'SET_FORM_102_REQUEST_NUMBER',
  CREATE_FORM_102 = 'CREATE_FORM_102',
  CREATE_FORM_102_SUCCESS = 'CREATE_FORM_102_SUCCESS',
  CREATE_FORM_102_FAILED = 'CREATE_FORM_102_FAILED',
  SAVE_FORM_102 = 'SAVE_FORM_102',
  SAVE_FORM_102_SUCCESS = 'SAVE_FORM_102_SUCCESS',
  SAVE_FORM_102_FAILED = 'SAVE_FORM_102_FAILED',
  SET_FORM_102_STEP = 'SET_FORM_102_STEP',
  DELETE_FORM_102 = 'DELETE_FORM_102',
  DELETE_FORM_102_SUCCESS = 'DELETE_FORM_102_SUCCESS',
  DELETE_FORM_102_FAILED = 'DELETE_FORM_102_FAILED',
  GET_FORM_102 = 'GET_FORM_102',
  GET_FORM_102_SUCCESS = 'GET_FORM_102_SUCCESS',
  GET_FORM_102_FAILED = 'GET_FORM_102_FAILED',
  UNLOCK_FORM_102 = 'UNLOCK_FORM_102',
  GET_FORM_102_PDF = 'GET_FORM_102_PDF',
  SUBMIT_FORM_102 = 'SUBMIT_FORM_102',
  SUBMIT_FORM_102_SUCCESS = 'SUBMIT_FORM_102_SUCCESS',
  OVERRIDE_FORM_102_EXCEPTIONS = 'OVERRIDE_FORM_102_EXCEPTIONS',
  SET_FORM_102_SAVING_STATUS = 'SET_FORM_102_SAVING_STATUS',
  SET_FORM_102_SUBMIT_STATUS = 'SET_FORM_102_SUBMIT_STATUS',
  SET_VALID_ADDRESS_FORM_102 = 'SET_VALID_ADDRESS_FORM_102',
  SET_VALID_ADDRESS_STATE_FORM_102 = 'SET_VALID_ADDRESS_STATE_FORM_102',
  SET_VALID_EMAIL_ADDRESS_FORM_102 = 'SET_VALID_EMAIL_ADDRESS_FORM_102',
  SET_VALID_EMAIL_ADDRESS_STATE_FORM_102 = 'SET_VALID_EMAIL_ADDRESS_STATE_FORM_102',
  VALIDATE_PHONE_NUMBER_REQUESTED = 'VALIDATE_PHONE_NUMBER_REQUESTED',
  SET_PHONE_NUMBER_VALIDITY_STATUS = 'SET_PHONE_NUMBER_VALIDITY_STATUS'
}

export const setForm102RequestNumber: ActionCreator<EapAction> = (
  data: string | null
) => ({
  type: form102ActionTypes.SET_FORM_102_REQUEST_NUMBER,
  data
});

export const createForm102Action: ActionCreator<EapAction> = (data: {
  formData: Form102Data;
  reset: (values?: { [key: string]: any }) => void;
}) => ({
  type: form102ActionTypes.CREATE_FORM_102,
  data
});

export const createForm102SuccessAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  employerNo: string;
  formData: Form102Data;
}) => ({
  type: form102ActionTypes.CREATE_FORM_102_SUCCESS,
  data
});

export const createForm102FailedAction: ActionCreator<EapAction> = () => ({
  type: form102ActionTypes.CREATE_FORM_102_FAILED
});

export const saveForm102Action: ActionCreator<EapAction> = (data: {
  formData: Form102Data;
  exceptions?: Array<ExceptionsObject>;
  reviewForm?: boolean;
  submitForm?: boolean;
  preventNav?: boolean;
  lockForm?: boolean;
}) => ({
  type: form102ActionTypes.SAVE_FORM_102,
  data
});

export const saveForm102SuccessAction: ActionCreator<EapAction> = (
  data: Form102Data
) => ({
  type: form102ActionTypes.SAVE_FORM_102_SUCCESS,
  data
});

export const saveForm102FailedAction: ActionCreator<EapAction> = () => ({
  type: form102ActionTypes.SAVE_FORM_102_FAILED
});

export const getForm102Action: ActionCreator<EapAction> = (data: string) => ({
  type: form102ActionTypes.GET_FORM_102,
  data
});

export const getForm102SuccessAction: ActionCreator<EapAction> = (
  data: Form102Data
) => ({
  type: form102ActionTypes.GET_FORM_102_SUCCESS,
  data
});

export const getForm102FailedAction: ActionCreator<EapAction> = () => ({
  type: form102ActionTypes.GET_FORM_102_FAILED
});

export const setForm102StepAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form102ActionTypes.SET_FORM_102_STEP,
  data
});

export const deleteForm102Action: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form102ActionTypes.DELETE_FORM_102,
  data
});

export const deleteForm102SuccessAction: ActionCreator<EapAction> = () => ({
  type: form102ActionTypes.DELETE_FORM_102_SUCCESS
});

export const deleteForm102FailedAction: ActionCreator<EapAction> = () => ({
  type: form102ActionTypes.DELETE_FORM_102_FAILED
});

export const unlockForm102Action: ActionCreator<EapAction> = () => ({
  type: form102ActionTypes.UNLOCK_FORM_102
});

export const getForm102PDFAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  formNo: string;
  employerNo: string;
  isView: boolean;
}) => ({
  type: form102ActionTypes.GET_FORM_102_PDF,
  data
});

export const submitForm102Action: ActionCreator<EapAction> = () => ({
  type: form102ActionTypes.SUBMIT_FORM_102
});

export const submitForm102SuccessAction: ActionCreator<EapAction> = (data: {
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form102ActionTypes.SUBMIT_FORM_102_SUCCESS,
  data
});

export const overrideForm102ExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: form102ActionTypes.OVERRIDE_FORM_102_EXCEPTIONS,
  data
});

export const setForm102SavingStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form102ActionTypes.SET_FORM_102_SAVING_STATUS,
  data
});

export const setForm102SubmitStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form102ActionTypes.SET_FORM_102_SUBMIT_STATUS,
  data
});

export const setValidAddressForm102: ActionCreator<EapAction> = (data: {
  address1: string;
  country: string;
  province: string;
  city: string;
  postal: string;
}) => ({
  type: form102ActionTypes.SET_VALID_ADDRESS_FORM_102,
  data
});

export const setValidAddressStateForm102: ActionCreator<EapAction> = (
  data: 'valid' | 'invalid' | 'not-validated'
) => ({
  type: form102ActionTypes.SET_VALID_ADDRESS_STATE_FORM_102,
  data
});

export const setValidEmailAddressForm102: ActionCreator<EapAction> = (data: {
  email: string;
}) => ({
  type: form102ActionTypes.SET_VALID_EMAIL_ADDRESS_FORM_102,
  data
});

export const setValidEmailAddressStateForm102: ActionCreator<EapAction> = (
  data: QASEmailResponseTypes
) => ({
  type: form102ActionTypes.SET_VALID_EMAIL_ADDRESS_STATE_FORM_102,
  data
});

export const validatePhoneNumberWithCanadaPost: ActionCreator<EapAction> = (data: {
  phoneNumber: string;
  phoneNumberType: string;
}) => ({
  type: form102ActionTypes.VALIDATE_PHONE_NUMBER_REQUESTED,
  data
});

export const setPhoneNumberValidityStatus: ActionCreator<EapAction> = (data: {
  phoneNumberType: string;
  phoneNumberValidity: string;
}) => ({
  type: form102ActionTypes.SET_PHONE_NUMBER_VALIDITY_STATUS,
  data
});
