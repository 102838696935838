import { all, takeEvery, select, put } from 'redux-saga/effects';

import { RootState } from 'types';
import { idleActionActionTypes, clearIdleAction } from './idleActionActions';

export function* runIdleActionSaga() {
  const { action } = yield select((state: RootState) => state.idleAction);

  if (action) {
    yield put(action);
    yield put(clearIdleAction());
  }
}

export function* idleActionSaga(): any {
  yield all([
    yield takeEvery(idleActionActionTypes.RUN_IDLE_ACTION, runIdleActionSaga)
  ]);
}
