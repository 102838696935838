import { all, takeLeading, takeLatest, call, put } from 'redux-saga/effects';

import { EapAction } from 'types';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { getApiErrorMessage } from 'utils';
import { updateForm132Consent, getMemberRetroPayEarnings } from 'services';
import {
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';

import { memberProfileActionTypes } from './memberProfileActions';

export function* updateForm132ConsentSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, updateForm132Consent, action.data);

  if (res?.status === 200) {
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* getRetroPayEarningsSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield getMemberRetroPayEarnings(action.data);

  if (res.data) {
    yield put({
      type: memberProfileActionTypes.RETRO_PAY_SUCCEEDED,
      data: res.data
    });
  } else {
    yield put({
      type: memberProfileActionTypes.RETRO_PAY_FAILED
    });
  }

  yield put(clearLoadingAction());
}

export function* memberProfileSaga(): any {
  yield all([
    yield takeLeading(
      memberProfileActionTypes.MEMBER_PROFILE_UPDATE_FORM_132_CONSENT_REQUESTED,
      updateForm132ConsentSaga
    ),
    yield takeLatest(
      memberProfileActionTypes.RETRO_PAY_REQUESTED,
      getRetroPayEarningsSaga
    )
  ]);
}
