import { parse, isBefore } from 'date-fns';

import { EapAction } from 'types';
import { AnrNotReconciled119, AnrReconciled119 } from 'interfaces';
import { annualDashboardActionTypes } from './annualDashboardActions';

/**
 * Compares cached updatedTimestamp to latestUpdatedTimestamp returned from the
 * dashboard info request and compares the timestamps. If the latest timestamp
 * is after the cached timestamp, return null to clear the cached refresh
 * request timestamp; otherwise return the last cached refresh request timestamp
 */
export const getDashboardRefreshRequestTimestamp = (
  updatedTimestamp: string | null,
  latestUpdatedTimestamp: string | null,
  dashboardRefreshRequestTimestamp: Date | null
) => {
  if (updatedTimestamp && latestUpdatedTimestamp) {
    return isBefore(
      parse(updatedTimestamp, 'MMM-dd-yyyy hh:mm a', new Date()),
      parse(latestUpdatedTimestamp, 'MMM-dd-yyyy hh:mm a', new Date())
    )
      ? null
      : dashboardRefreshRequestTimestamp;
  }

  return dashboardRefreshRequestTimestamp;
};

interface AnnualDashboardState {
  dashboardLoading: boolean;
  dashboardRefreshRequestTimestampMap: {
    [key: string]: Date | null | any;
  };
  updatedTimestamp: string | null;
  activeNotReconciledANR: AnrNotReconciled119 | null;
  activeReconciledANR: AnrReconciled119 | null;
  notReconciled119: Array<AnrNotReconciled119>;
  reconciled119: Array<AnrReconciled119>;
  anr125ReportLoading: boolean;
  fetchAnrReportFailed: boolean;
}

const initialState = {
  dashboardLoading: false,
  dashboardRefreshRequestTimestampMap: {},
  updatedTimestamp: null,
  activeNotReconciledANR: null,
  activeReconciledANR: null,
  notReconciled119: [],
  reconciled119: [],
  anr125ReportLoading: false,
  fetchAnrReportFailed: false
};

const annualDashboard = (
  state: AnnualDashboardState = initialState,
  action: EapAction
): AnnualDashboardState => {
  const { type, data } = action;

  switch (type) {
    case annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_REQUESTED:
      return {
        ...initialState,
        dashboardLoading: true,
        dashboardRefreshRequestTimestampMap:
          state.dashboardRefreshRequestTimestampMap,
        updatedTimestamp: state.updatedTimestamp
      };
    case annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_SUCCESS:
      return {
        ...state,
        dashboardLoading: false,
        notReconciled119: data?.notReconciled119 ?? [],
        reconciled119: data?.reconciled119 ?? []
      };
    case annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_FAILED:
      return {
        ...state,
        dashboardLoading: false,
        updatedTimestamp: null,
        activeNotReconciledANR: null
      };
    case annualDashboardActionTypes.SET_ACTIVE_NOT_RECONCILED_ANR_DASHBOARD:
      return {
        ...state,
        activeNotReconciledANR: data,
        activeReconciledANR: null
      };
    case annualDashboardActionTypes.SET_ACTIVE_RECONCILED_ANR_DASHBOARD:
      return {
        ...state,
        activeNotReconciledANR: null,
        activeReconciledANR: data
      };
    case annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_REQUESTED:
      return {
        ...state,
        anr125ReportLoading: true,
        fetchAnrReportFailed: false
      };
    case annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_SUCCESS:
      return {
        ...state,
        anr125ReportLoading: false
      };
    case annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_FAILED:
      return {
        ...state,
        anr125ReportLoading: false,
        fetchAnrReportFailed: true
      };
    case annualDashboardActionTypes.GET_ACTIVE_RECONCILED_SUMMARY_REPORT_REQUESTED:
      return {
        ...state,
        fetchAnrReportFailed: false
      };
    case annualDashboardActionTypes.GET_ACTIVE_RECONCILED_SUMMARY_REPORT_FAILED:
      return {
        ...state,
        fetchAnrReportFailed: true
      };
    case annualDashboardActionTypes.REQUEST_ANR_REFRESH_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardRefreshRequestTimestampMap: {
          ...state.dashboardRefreshRequestTimestampMap,
          [data]: new Date().toISOString()
        }
      };
    case annualDashboardActionTypes.REQUEST_ANR_REFRESH_DASHBOARD_FAILED:
      return {
        ...state,
        dashboardRefreshRequestTimestampMap: {
          ...state.dashboardRefreshRequestTimestampMap,
          [data]: null
        }
      };
    case annualDashboardActionTypes.UPDATE_ANR_UPDATED_TIMESTAMP:
      return {
        ...state,
        updatedTimestamp: data.timestamp,
        dashboardRefreshRequestTimestampMap: {
          ...state.dashboardRefreshRequestTimestampMap,
          [data.refreshRequestEmployer]: getDashboardRefreshRequestTimestamp(
            state.updatedTimestamp,
            data.timestamp,
            state.dashboardRefreshRequestTimestampMap[
              data.refreshRequestEmployer
            ]
          )
        }
      };
    default:
      return state;
  }
};

export default annualDashboard;
