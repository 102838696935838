import { Popover2Props } from '@blueprintjs/popover2';
import Icon, { IconNames, IconSize } from '../icon/Icon';
import Popover from '../popover/Popover';

export interface Tooltip2Props extends Omit<Popover2Props, 'content'> {
  type?: 'help' | 'info';
  width?: string;
  maxHeight?: string;
  containerPadding?: string;
  inlineText?: boolean;
  tooltipText?: string;
  enforceFocus?: boolean;
  content?: string | JSX.Element | Array<string | JSX.Element>;
}

const Tooltip: React.FC<Tooltip2Props> = ({
  placement = 'auto',
  content,
  type = 'help',
  width,
  maxHeight,
  containerPadding,
  inlineText = false,
  tooltipText,
  enforceFocus,
  ...props
}) => (
  <Popover
    interactionKind="hover"
    content={
      Array.isArray(content) ? (
        <>
          {content.map((line, key) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={key}>{line}</div>
          ))}
        </>
      ) : (
        content
      )
    }
    placement={placement}
    maxHeight={maxHeight}
    width={width}
    containerPadding={containerPadding}
    enforceFocus={enforceFocus}
    {...props}
  >
    <>
      {inlineText && (
        <div
          sx={{
            textDecorationLine: 'underline',
            textDecorationStyle: 'dashed',
            textUnderlineOffset: '0.125rem',
            color: 'brandNavy100',
            m: 0,
            '&:hover, &:focus, &:active': {
              color: 'extendedBlue100',
              svg: {
                color: 'extendedBlue100'
              }
            },
            '&:focus': {
              border: '2px solid',
              borderColor: 'extendedBlue100',
              borderRadius: '5px',
              outline: 'none'
            }
          }}
        >
          <span sx={{ display: 'inline' }}>{tooltipText}</span>
          <Icon
            role="tooltip"
            icon={
              type === 'help'
                ? IconNames.QUESTION_CIRCLE_OUTLINE
                : IconNames.INFO_OUTLINE
            }
            color="brandNavy100"
            size={IconSize.SMALL}
            sx={{
              display: 'inline-flex',
              verticalAlign: 'middle',
              ml: '0.25rem'
            }}
          />
        </div>
      )}
      {!inlineText && (
        <Icon
          role="tooltip"
          icon={
            type === 'help'
              ? IconNames.QUESTION_CIRCLE_OUTLINE
              : IconNames.INFO_OUTLINE
          }
          color="brandNavy100"
          size={IconSize.SMALL}
          sx={{
            '&:hover, &:focus': {
              svg: {
                color: 'extendedBlue100'
              }
            }
          }}
        />
      )}
    </>
  </Popover>
);

export default Tooltip;
