import { ActionCreator } from 'redux';
import { EapAction } from 'types';
import { dataExtractTabIds } from '../types';

export enum dataExtractActionTypes {
  SET_DATA_EXTRACT_ACTIVE_TAB = 'SET_DATA_EXTRACT_ACTIVE_TAB',
  GET_DATA_EXTRACT_TYPE = 'GET_DATA_EXTRACT_TYPE',
  SET_DATA_EXTRACT_TYPE = 'SET_DATA_EXTRACT_TYPE',
  SET_CHOSEN_DATA_EXTRACT_TYPE = 'SET_CHOSEN_DATA_EXTRACT_TYPE',
  SET_CHOSEN_DATA_EXTRACT_YEAR = 'SET_CHOSEN_DATA_EXTRACT_YEAR',
  SET_EXTRACT_DATA = 'SET_EXTRACT_DATA',
  SET_EXTRACT_DATA_RES = 'SET_EXTRACT_DATA_RES',
  SET_EXTRACT_DATA_RES_ERROR = 'SET_EXTRACT_DATA_RES_ERROR',
  RESET_EXTRACT = 'RESET_EXTRACT',
  RESET_EXTRACT_STATUS = 'RESET_EXTRACT_STATUS',
  GET_DATA_EXTRACT_STATUS = 'GET_DATA_EXTRACT_STATUS',
  SET_DATA_EXTRACT_STATUS = 'SET_DATA_EXTRACT_STATUS',
  DOWNLOAD_CSV = 'DOWNLOAD_CSV',
  CANCEL_EXTRACT = 'CANCEL_EXTRACT'
}

export const setDataExtractActiveTab: ActionCreator<EapAction> = (
  data: dataExtractTabIds
) => ({
  type: dataExtractActionTypes.SET_DATA_EXTRACT_ACTIVE_TAB,
  data
});

export const getDataExtractType: ActionCreator<EapAction> = () => ({
  type: dataExtractActionTypes.GET_DATA_EXTRACT_TYPE
});

export const setDataExtractType: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.SET_DATA_EXTRACT_TYPE,
  data
});

export const setChosenEventType: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.SET_CHOSEN_DATA_EXTRACT_TYPE,
  data
});
export const setChosenEventYear: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.SET_CHOSEN_DATA_EXTRACT_YEAR,
  data
});

export const setExtractData: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.SET_EXTRACT_DATA,
  data
});

export const setExtractDataRes: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.SET_EXTRACT_DATA_RES,
  data
});
export const setExtractDataResError: ActionCreator<EapAction> = (
  data: any
) => ({
  type: dataExtractActionTypes.SET_EXTRACT_DATA_RES_ERROR,
  data
});

export const resetExtract: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.RESET_EXTRACT,
  data
});
export const resetExtractStatus: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.RESET_EXTRACT_STATUS,
  data
});

export const getExtractStatusAction: ActionCreator<EapAction> = () => ({
  type: dataExtractActionTypes.GET_DATA_EXTRACT_STATUS
});

export const setDataExtractStatus: ActionCreator<EapAction> = (data: any) => ({
  type: dataExtractActionTypes.SET_DATA_EXTRACT_STATUS,
  data
});

export const downloadCSV: ActionCreator<EapAction> = (data: string) => ({
  type: dataExtractActionTypes.DOWNLOAD_CSV,
  data
});

export const cancelExtractAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: dataExtractActionTypes.CANCEL_EXTRACT,
  data
});
