import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { qasEmailValidate } from 'services/emailValidation';

export const onQASEmailAddressValidation = async (
  email: string
): Promise<any | Error> => {
  const response = await qasEmailValidate({ email });

  if (response?.result) {
    const { confidence } = response?.result;

    if (
      confidence === QASEmailResponseTypes.VERIFIED ||
      confidence === QASEmailResponseTypes.ILLEGITIMATE ||
      confidence === QASEmailResponseTypes.UNKNOWN
    ) {
      return confidence;
    }
  }
  return QASEmailResponseTypes.ILLEGITIMATE;
};
