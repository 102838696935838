import Button from 'components/v2/atomic/button/Button';
import { useRouter } from 'next/router';
import Divider from 'components/v2/atomic/divider/Divider';
import Icon from 'components/v2/atomic/icon/Icon';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import Image from 'next/image';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import { addLeadingDot } from 'utils/theme-ui/formatImages';
import { CONTAINER_MAX_WIDTH } from 'styles/theme';
import MainNavButton from '../mainNavButton/MainNavButton';
import { NavMenuItem } from './MainNav';

const Header = ({
  isSticky = false,
  includeWrapper = true,
  headerItems,
  showSignInButton = true,
  setIsBurgerMenuOpen
}: {
  isSticky?: boolean;
  includeWrapper?: boolean;
  headerItems?: NavMenuItem[];
  showSignInButton?: boolean;
  setIsBurgerMenuOpen: (isOpen: boolean) => void;
}) => {
  const { t } = useTranslation('header');
  const breakpoint = useBreakpoint();
  const Router = useRouter();

  return (
    <div
      className="topBarWrapper"
      sx={{
        zIndex: '2998',
        position: isSticky ? 'sticky' : undefined,
        top: isSticky ? '0' : undefined,
        backgroundColor: 'white',
        minHeight: '5rem',
        padding: ['1rem 2rem', '1rem 1.5rem', '1rem 1.5rem', '1rem 1.5rem'],
        boxShadow: '0 0 0.9375rem 0 rgba(0, 0, 0, 0.20)'
      }}
    >
      <div
        className="topBar"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          maxWidth: includeWrapper ? CONTAINER_MAX_WIDTH : undefined,
          margin: includeWrapper ? '0 auto' : undefined
        }}
      >
        <div
          className="topBarLeft"
          sx={{
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            minWidth: 'fit-content'
          }}
        >
          <Link
            href={
              Router.pathname.startsWith('/content/') || Router.pathname === '/'
                ? '/'
                : '/home'
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              textDecoration: 'none',
              '&:hover': {
                textDecoration: 'none'
              }
            }}
          >
            <Image
              src={addLeadingDot('/images/logos/logo-e-access.svg')}
              alt="side profile of e-access puffin logo"
              width={43}
              height={40}
            />
            {!breakpoint.isLessThanMediumMin && (
              <span
                sx={{
                  fontFamily: 'body',
                  fontWeight: 'semibold',
                  fontSize: 'medium',
                  color: 'brandNavy100',
                  whiteSpace: 'nowrap',
                  paddingLeft: '0.625rem'
                }}
              >
                {t('E_ACCESS')}
              </span>
            )}
          </Link>
          <Divider
            vertical
            condensed
            sx={{ height: '2.1875rem' }}
            id="main-nav-divider"
          />
          <div>
            <Image
              src={addLeadingDot('/images/logos/omers-logo-colour.svg')}
              alt="omers logo"
              height={24}
              width={106.5}
            />
          </div>
        </div>
        <ul
          className="topLinks"
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {!breakpoint.isLessThanSmallMin &&
            headerItems?.map(headerItem => (
              <li key={headerItem.id}>
                <MainNavButton
                  buttonColor="light"
                  {...headerItem}
                  icon={headerItem.showIconInNav ? headerItem.icon : undefined}
                />
              </li>
            ))}
          {!breakpoint.isLessThanSmallMin && showSignInButton && (
            <li>
              <Link
                href="/"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.5rem',
                  borderRadius: '5px',
                  textDecoration: 'none !important',
                  padding: '0.75rem 1rem',
                  fontWeight: 'body',
                  color: 'brandNavy100',
                  fontFamily: 'body',
                  fontSize: 'small',
                  '&:focus': {
                    outline: 'none'
                  },
                  '&:focus-visible': {
                    outline: '2px solid',
                    outlineColor: 'extendedBlue100'
                  },
                  '&:hover': {
                    backgroundColor: 'extendedBlue25'
                  }
                }}
              >
                <Icon
                  icon={IconNamesSmall.LOCK}
                  sx={{ svg: { height: '18px' } }}
                />
                {t('SIGN_IN')}
              </Link>
            </li>
          )}
          {breakpoint.isLessThanMediumMin && (
            <Button
              id="burger-menu-button"
              isIconButton
              variant="simple"
              aria-label="Menu"
              onClick={() => {
                setIsBurgerMenuOpen(true);
              }}
              sx={{
                '&focus-visible': {
                  outlineColor: 'extendedBlue100'
                },
                '&:hover': {
                  backgroundColor: 'extendedBlue25'
                }
              }}
            >
              <Icon icon={IconNamesSmall.MENU} />
            </Button>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Header;
