import {
  all,
  takeLeading,
  takeEvery,
  select,
  call,
  put,
  delay
} from 'redux-saga/effects';
import Router from 'next/router';

import { RootState, EapAction } from 'types';
import { getApiErrorMessage } from 'utils';
import { deleteForm, lockForm, unlockForm } from 'services';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  clearNotifications,
  notifyErrorAction,
  clearLockedFormNotificationAction
} from 'containers/notifications/notificationsActions';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import {
  formActionTypes,
  deleteFormSuccessAction,
  deleteFormFailedAction
} from './formActions';

export function* deleteFormSaga(action: EapAction): any {
  if (action.data) {
    yield put(startLoadingAction());

    const res = yield call(
      safeAuthEffect,
      deleteForm,
      action.data.requestNumber
    );

    if (res.status === 200 || res.status === 204) {
      yield put(deleteFormSuccessAction());
      if (action.data.deleteSuccessCallbackAction) {
        yield put(action.data.deleteSuccessCallbackAction());
      }
      yield put(clearNotifications());
      yield call(Router.push, '/requests');
    } else {
      yield put(deleteFormFailedAction());
      yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    }

    yield put(clearLoadingAction());
  }
}

export function* lockFormSaga(action: EapAction) {
  if (action.data) {
    const { omersUser } = yield select(
      (state: RootState) => state.userSession.userData
    );

    // lock form if not omers admin user
    if (!omersUser) {
      yield call(safeAuthEffect, lockForm, action.data);
    }
  }
}

export function* unlockFormSaga(action: EapAction) {
  if (action.data.requestNumber && !action.data.submissionComplete) {
    yield call(safeAuthEffect, unlockForm, action.data.requestNumber);
  }
}

export function* clearLockedFormNotificationSaga() {
  yield delay(15000);
  yield put(clearLockedFormNotificationAction());
}

export function* formSaga(): any {
  yield all([
    yield takeLeading(formActionTypes.DELETE_FORM, deleteFormSaga),
    yield takeLeading(formActionTypes.LOCK_FORM, lockFormSaga),
    yield takeLeading(formActionTypes.UNLOCK_FORM, unlockFormSaga),
    yield takeEvery(
      formActionTypes.CLEAR_LOCKED_FORM_NOTIFICATION,
      clearLockedFormNotificationSaga
    )
  ]);
}
