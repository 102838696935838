import { FILTER_STATUS, filterValues, filterStatusCode } from 'utils/constants';
import { compareDesc, parseISO, formatISO } from 'date-fns';
import { IArchivedMessagesValues } from './archivedMessagesFilter/ArchivedMessagesFilter';
import { IArchivedMessagesSearchPayload } from './types';

export const getFilterStatusKey = (status: string) => {
  switch (status) {
    case filterValues.ALL: {
      return null;
    }
    case filterValues.ALL_UNREAD: {
      return filterStatusCode.UNREAD;
    }
    case filterValues.ALL_UNRESOLVED: {
      return filterStatusCode.UNRESOLVED;
    }
    case filterValues.AWAITING_REPLY: {
      return filterStatusCode.AWAITING_REPLY;
    }
    case filterValues.RESOLVED: {
      return filterStatusCode.RESOLVED;
    }
    case filterValues.ACTION_REQUIRED: {
      return filterValues.ACTION_REQUIRED;
    }
    default: {
      return null;
    }
  }
};

export const getCategoryKey = (
  topic: string,
  categories: { key: string; val: string }[]
) =>
  categories.reduce(
    (categoryKey: null | string, category: { key: string; val: string }) => {
      if (category.val === topic) {
        return category.key;
      }
      if (category.val === FILTER_STATUS[0].val) return categoryKey;
      return categoryKey;
    },
    null
  );

export const transformArchivedMessagesMessages = (data: any[]) =>
  [...data]
    .sort((a: any, b: any) =>
      compareDesc(parseISO(a?.LastModifiedDate), parseISO(b?.LastModifiedDate))
    )
    .map((sortedData: any) => ({
      categoryCode: sortedData.categoryCode,
      categoryDescription: sortedData.categoryDescription,
      lastModifiedDate: formatISO(
        new Date(sortedData.lastModifiedDate.replace(/-/g, ' '))
      ),
      memberFirstName: sortedData.mssmembership.memberFirstName || null,
      memberLastName: sortedData.mssmembership.memberLastName || null,
      memberEmployeeId: sortedData.mssmembership.memberEmployeeId || null,
      subject: sortedData.subject,
      readInd: sortedData.readInd,
      resolvedInd: sortedData.resolvedInd,
      waitingInd: sortedData.waitingInd,
      correspondenceNo: sortedData.correspondenceNo,
      memberMembershipNumber: sortedData.mssmembership.memberMembershipNumber,
      memberEmployerNumber: sortedData.mssmembership.memberEmployerNumber
    }));

export const transformArchivedMessagesMessageThread = (data: any[]) =>
  [...data]
    .sort((a: any, b: any) =>
      compareDesc(parseISO(a?.formattedDate), parseISO(b?.formattedDate))
    )
    .map((sortedData: any) => ({
      firstName: sortedData.msscontact.inFirstName,
      lastName: sortedData.msscontact.inLastName,
      date: sortedData.formattedDate,
      message: sortedData.message,
      createMssUserId: sortedData.createMssUserId
    }));

export const getPayload = (
  employerNo: string,
  userData: IArchivedMessagesValues,
  correspondenceCategories: any[]
): IArchivedMessagesSearchPayload => ({
  employerNo,
  categoryCode:
    getCategoryKey(userData.topic, correspondenceCategories) || null,
  status: getFilterStatusKey(userData.status) || null,
  sin: null,
  employeeId: userData.employeeId || null,
  fromDate: null,
  toDate: null,
  formReturnedInd: 'N'
});
