import {
  clearLoadingAction,
  startLoadingAction
} from 'containers/auth/userSessionActions';
import {
  clearNotifications,
  notifyErrorAction
} from 'containers/notifications/notificationsActions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getBatchPrintSearchResults,
  printSelectedRequest
} from 'services/batchPrint';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { EapAction } from 'types';
import { responseTypes } from 'types/response-type';
import { getApiErrorMessage, viewPdf } from 'utils';
import {
  batchPrintActionTypes,
  getBatchPrintSearchResultsFailedAction,
  getBatchPrintSearchResultsSuccessAction,
  printSelectedRequestFailedAction,
  printSelectedRequestSuccessAction
} from './BatchPrintActions';

export function* getBatchPrintSearchResultsSaga(action: EapAction): any {
  const res = yield call(
    safeAuthEffect,
    getBatchPrintSearchResults,
    action.data.payload
  );

  if (res.data) {
    yield put(getBatchPrintSearchResultsSuccessAction(res.data));
  } else {
    yield put(getBatchPrintSearchResultsFailedAction());
  }
  yield put(clearNotifications());
}

export function* printSelectedRequestSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, printSelectedRequest, action.data);
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    yield put(clearNotifications());
    yield call(viewPdf, res, 'Election Forms');
    yield put(printSelectedRequestSuccessAction());
  } else {
    yield put(printSelectedRequestFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* batchPrintSaga(): any {
  yield all([
    yield takeLatest(
      batchPrintActionTypes.GET_BATCH_PRINT_SEARCH_RESULTS_REQUESTED,
      getBatchPrintSearchResultsSaga
    ),
    yield takeLatest(
      batchPrintActionTypes.PRINT_SELECTED_REQUESTED,
      printSelectedRequestSaga
    )
  ]);
}

export default batchPrintSaga;
