import { ActionCreator } from 'redux';
import { EapAction } from 'types';
import { IReturnedRequestsValues } from './returnedRequestsFilter/ReturnedRequestFilter';
import {
  IReturnedRequestsMessage,
  IReturnedRequestsSearchPayload
} from './types';

export enum returnedRequestsActionTypes {
  GET_RETURNED_REQUESTS_REQUESTED = 'GET_RETURNED_REQUESTS_REQUESTED',
  GET_RETURNED_REQUESTS_SUCCEEDED = 'GET_RETURNED_REQUESTS_SUCCEEDED',
  GET_RETURNED_REQUESTS_FAILED = 'GET_RETURNED_REQUESTS_FAILED',
  SET_RETURNED_REQUESTS_BUTTON_STATUS = 'SET_RETURNED_REQUESTS_BUTTON_STATUS',
  APPLY_RETURNED_REQUESTS_FILTER_REQUESTED = 'APPLY_RETURNED_REQUESTS_FILTER_REQUESTED',
  GET_RETURNED_REQUESTS_MESSAGE_THREAD_REQUESTED = 'GET_RETURNED_REQUESTS_MESSAGE_THREAD_REQUESTED',
  GET_RETURNED_REQUESTS_MESSAGE_THREAD_SUCCEEDED = 'GET_RETURNED_REQUESTS_MESSAGE_THREAD_SUCCEEDED',
  GET_RETURNED_REQUESTS_MESSAGE_THREAD_FAILED = 'GET_RETURNED_REQUESTS_MESSAGE_THREAD_FAILED',
  SET_CORRESPONDENCE_NUMBER = 'SET_CORRESPONDENCE_NUMBER',
  SEND_REPLY_REQUESTED = 'SEND_REPLY_REQUESTED',
  SEND_REPLY_REQUESTED_SUCCEEDED = 'SEND_REPLY_REQUESTED_SUCCEEDED',
  SEND_REPLY_REQUESTED_FAILED = 'SEND_REPLY_REQUESTED_FAILED'
}

export const getReturnedRequestsRequestedAction: ActionCreator<EapAction> = (
  data: IReturnedRequestsSearchPayload
) => ({
  type: returnedRequestsActionTypes.GET_RETURNED_REQUESTS_REQUESTED,
  data
});

export const getReturnedRequestsSucceededAction: ActionCreator<EapAction> = (
  data: IReturnedRequestsMessage[]
) => ({
  type: returnedRequestsActionTypes.GET_RETURNED_REQUESTS_SUCCEEDED,
  data
});

export const getReturnedRequestsFailedAction: ActionCreator<EapAction> = data => ({
  type: returnedRequestsActionTypes.GET_RETURNED_REQUESTS_FAILED,
  data
});

export const setReturnedRequestsButtonStatus: ActionCreator<EapAction> = (data: {
  isResetButtonDisabled: boolean;
  isApplyButtonDisabled: boolean;
  isApplyButtonClicked?: boolean;
}) => ({
  type: returnedRequestsActionTypes.SET_RETURNED_REQUESTS_BUTTON_STATUS,
  data
});

export const applyReturnedRequestsFilterRequestedAction: ActionCreator<EapAction> = (
  data: IReturnedRequestsValues
) => ({
  type: returnedRequestsActionTypes.APPLY_RETURNED_REQUESTS_FILTER_REQUESTED,
  data
});
export const getReturnedRequestsMessageThreadRequestedAction: ActionCreator<EapAction> = () => ({
  type:
    returnedRequestsActionTypes.GET_RETURNED_REQUESTS_MESSAGE_THREAD_REQUESTED
});

export const getReturnedRequestsMessageThreadSucceededAction: ActionCreator<EapAction> = (
  data: IReturnedRequestsMessage[]
) => ({
  type:
    returnedRequestsActionTypes.GET_RETURNED_REQUESTS_MESSAGE_THREAD_SUCCEEDED,
  data
});

export const getReturnedRequestsMessageThreadFailedAction: ActionCreator<EapAction> = data => ({
  type: returnedRequestsActionTypes.GET_RETURNED_REQUESTS_MESSAGE_THREAD_FAILED,
  data
});

export const setCorrespondenceNumber: ActionCreator<EapAction> = (
  data: string
) => ({
  type: returnedRequestsActionTypes.SET_CORRESPONDENCE_NUMBER,
  data
});

export const sendReplyRequestedAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: returnedRequestsActionTypes.SEND_REPLY_REQUESTED,
  data
});
export const sendReplyRequestedSucceededAction: ActionCreator<EapAction> = () => ({
  type: returnedRequestsActionTypes.SEND_REPLY_REQUESTED_SUCCEEDED
});
export const sendReplyRequestedFailedAction: ActionCreator<EapAction> = data => ({
  type: returnedRequestsActionTypes.SEND_REPLY_REQUESTED_FAILED,
  data
});
