/* eslint-disable no-param-reassign */
import { EapAction } from 'types';
import { formActionTypes } from './formActions';

export interface FormState {
  submitPressed: boolean;
  errorExceptionIDs: Array<string>;
}

export const initialState = {
  submitPressed: false,
  errorExceptionIDs: []
};

const commonOverride = (
  state: FormState = initialState,
  action: EapAction
): FormState => {
  const { type, data } = action;
  switch (type) {
    case formActionTypes.TRIGGER_OVERRIDE:
      return {
        ...state,
        submitPressed: data
      };
    default:
      return state;
  }
};

export default commonOverride;
