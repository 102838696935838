import { ActionCreator } from 'redux';

import { EapAction } from 'types';

export enum formPDFActionTypes {
  GET_PDF = 'GET_PDF'
}

export const getPdfAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  formNo: string;
  employerNo: string;
  isView: boolean;
}) => ({
  type: formPDFActionTypes.GET_PDF,
  data
});
