import classNames from 'classnames/bind';
import Nav from 'components/nav/Nav';
import NavHeader from 'components/navHeader/NavHeader';
import Spinner from 'components/spinner/Spinner';
import { getFeatureFlagsAction } from 'features/featureFlags/featureFlagsActions';
import { pushNavHistoryAction } from 'containers/navHistory/navHistoryActions';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEnabled, currentFlags } from 'features/featureFlags/featureFlags';
import { RootState } from 'types';
import { featureFlagSelector } from 'utils';
import NavHeaderv2 from 'components/v2/slices/NavHeader/NavHeader';

import PublicNavHeader from 'components/v2/slices/publicNavHeader/PublicNavHeader';
import Footer from 'components/v2/slices/footer/Footer';
import styles from './Layout.module.scss';

const cx = classNames.bind(styles);

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = ({ children }: LayoutProps) => {
  const dispatch = useDispatch();
  const Router = useRouter();
  const { featureFlags } = useSelector(featureFlagSelector);
  const { isAuthenticated, showNav, loading } = useSelector(
    (state: RootState) => state.userSession
  );
  const [
    disableAccessibilityOutline,
    setDisableAccessibilityOutline
  ] = useState(true);
  const isPublicPage =
    isEnabled(featureFlags, currentFlags.STATIC_CONTENT) &&
    (Router.pathname.startsWith('/content/') || Router.pathname === '/');
  const showSideNav =
    showNav && Router.pathname !== '/select-employers' && !isPublicPage;
  const showFooter = Router.pathname === '/select-employers' || isPublicPage;

  const layoutClassNames = cx({
    mainContainer: true,
    withNav: showSideNav,
    disableFocusOutline: disableAccessibilityOutline
  });

  const containerClassNames = cx({
    container: true,
    containerFullWidth:
      isPublicPage || isEnabled(featureFlags, currentFlags.NEW_NAV_HEADER)
  });

  // enable/disable accessibility css outline on tab/mouse navigation;
  // watch router history change and update cached router history
  useEffect(() => {
    const keyDownHandler = (e: KeyboardEvent) => {
      if (e.key === 'Tab') {
        setDisableAccessibilityOutline(false);
      }
    };
    const mouseDownHandler = () => setDisableAccessibilityOutline(true);
    document.addEventListener('keydown', keyDownHandler);
    document.addEventListener('mousedown', mouseDownHandler);

    const routeChangeStartHandler = (url: string) =>
      dispatch(pushNavHistoryAction(url));
    Router.events.on('routeChangeStart', routeChangeStartHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
      document.removeEventListener('mousedown', mouseDownHandler);
      Router.events.off('routeChangeStart', routeChangeStartHandler);
    };
    // Maintain onMount behaviour
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getFeatureFlagsAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
        {/* To disable Edge from turning phone numbers into a link */}
        <meta name="format-detection" content="telephone=no" />
        <link
          rel="preload"
          href="https://use.typekit.net/uyx5yjj.css"
          as="style"
        />
        <link
          href="/favicon/favicon.ico"
          type="image/x-icon"
          rel="shortcut icon"
        />
        <link rel="stylesheet" href="https://use.typekit.net/uyx5yjj.css" />
      </Head>

      <Spinner isFullScreen size="large" visible={loading} />
      <div className={layoutClassNames}>
        {isAuthenticated && showSideNav && <Nav />}
        <div className={containerClassNames}>
          {isEnabled(featureFlags, currentFlags.NEW_NAV_HEADER) ? (
            <>
              <>
                <NavHeaderv2 />
                <div>
                  <div className={styles.main}>
                    <>{children}</>
                  </div>
                </div>
                {showFooter && <Footer id="publicFooter" />}
              </>
            </>
          ) : (
            <>
              {isPublicPage ? (
                <>
                  <PublicNavHeader />
                  <div>
                    <div className={styles.main}>
                      <>{children}</>
                    </div>
                  </div>
                  <Footer id="publicFooter" />
                </>
              ) : (
                <>
                  <NavHeader withNav={showSideNav} />
                  <div className={styles.content}>
                    <div className={styles.main}>
                      <>{children}</>
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Layout;
