import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { last } from 'lodash';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';

import { RootState } from 'types';
import { getReturnedCountAction } from 'containers/requests/requestsActions';
import { getInboxCountAction } from 'containers/messages/messagesActions';
import useRole from 'utils/hooks/useRole';
import { isEnabled, currentFlags } from 'features/featureFlags/featureFlags';
import { featureFlagSelector } from 'utils';

import { getPaginationInfoAction } from 'containers/nft/sliceReducer';
import styles from './Nav.module.scss';

const cx = classNames.bind(styles);

export const NAV_LINKS = [
  {
    key: 'home',
    href: '/home',
    paths: ['home'],
    icon: '/images/home.svg',
    translation: 'NAV_TITLE_HOME'
  },
  {
    key: 'members',
    href: '/members',
    paths: ['members'],
    icon: '/images/multiple.svg',
    translation: 'NAV_TITLE_MEMBERS'
  },
  {
    key: 'nft',
    href: '/nft',
    paths: ['nft'],
    icon: '/images/nft.svg',
    translation: 'NAV_TITLE_NFT',
    featureFlag: currentFlags.NFT
  },
  {
    key: 'requests',
    href: '/requests',
    paths: ['requests', 'form'],
    icon: '/images/single-folded-content.svg',
    translation: 'NAV_TITLE_REQUESTS'
  },
  {
    key: 'batch',
    href: '/batch-processing',
    paths: ['batch-processing'],
    icon: '/images/batch.svg',
    translation: 'NAV_TITLE_BATCH_PROCESSING',
    featureFlag: currentFlags.BATCH_PROCESSING
  },
  {
    key: 'dataExtract',
    href: '/data-extract',
    paths: ['data-extract'],
    icon: '/images/data_extract.svg',
    translation: 'NAV_TITLE_DATA_EXTRACT',
    featureFlag: currentFlags.DATA_EXTRACT
  },
  {
    key: 'annual',
    href: '/annual-dashboard',
    paths: ['annual-dashboard'],
    icon: '/images/dashboard-level.svg',
    translation: 'NAV_TITLE_ANNUAL_DASHBOARD',
    featureFlag: currentFlags.ANNUAL_RECONCILIATION
  },
  {
    key: 'reports',
    href: '/reports',
    paths: ['reports'],
    icon: '/images/reports-nav.svg',
    translation: 'NAV_TITLE_REPORTS',
    featureFlag: currentFlags.REPORTS
  },
  {
    key: 'messages',
    href: '/messages',
    paths: ['messages'],
    icon: '/images/messages-full.svg',
    translation: 'NAV_TITLE_MESSAGES'
  },
  {
    key: 'returnedRequests',
    href: '/returned-requests',
    paths: ['returned-requests'],
    icon: '/images/returned-requests-full.svg',
    translation: 'NAV_TITLE_RETURNED_REQUESTS',
    featureFlag: currentFlags.RETURNED_REQUESTS
  },
  {
    key: 'legacyEcorrespondance',
    href: '/archived-messages',
    paths: ['archived-messages'],
    icon: '/images/archived-messages.svg',
    translation: 'NAV_TITLE_ARCHIVED_MESSAGES',
    featureFlag: currentFlags.LEGACY_ECORR_OPTIONS
  },
  {
    key: 'batchPrint',
    href: '/batch-print',
    paths: ['batch-print'],
    icon: '/images/batch_print.svg',
    translation: 'NAV_TITLE_BATCH_PRINT',
    featureFlag: currentFlags.BATCH_PRINT
  },
  {
    key: 'stewardshipReport',
    href: '', // it's a dynamic link that depends on the group id
    paths: ['stewardship'],
    icon: '/images/esr.svg',
    translation: 'NAV_TITLE_ESR',
    featureFlag: currentFlags.ENABLE_ESR
  }
];

const SMO_ONLY_NAV_LINKS = [
  {
    key: 'home',
    href: '/home',
    paths: ['home'],
    icon: '/images/home.svg',
    translation: 'NAV_TITLE_HOME'
  },
  {
    key: 'messages',
    href: '/messages',
    paths: ['messages'],
    icon: '/images/messages-full.svg',
    translation: 'NAV_TITLE_MESSAGES'
  },
  {
    key: 'reports',
    href: '/reports',
    paths: ['reports'],
    icon: '/images/reports-nav.svg',
    translation: 'NAV_TITLE_REPORTS',
    featureFlag: currentFlags.REPORTS
  },
  {
    key: 'stewardshipReport',
    href: '', // it's a dynamic link that depends on the group id
    paths: ['stewardship'],
    icon: '/images/esr.svg',
    translation: 'NAV_TITLE_ESR',
    featureFlag: currentFlags.ENABLE_ESR
  }
];

const Nav = () => {
  const dispatch = useDispatch();
  const Router = useRouter();
  const { t } = useTranslation();
  const { isFinancial, isSMO, isNonFinancial } = useRole();
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );
  const { inboxCount } = useSelector((state: RootState) => state.messages);
  const { returnedRequestsCount } = useSelector(
    (state: RootState) => state.requests
  );
  const { invalidCount: nftCount } = useSelector(
    (state: RootState) => state.nft.reportedEmployeesTable
  );
  const { featureFlags } = useSelector(featureFlagSelector);
  const { selectGroupNumber: currentGroupID } = useSelector(
    (state: RootState) => state.selectEmployers
  );
  const { newMssReportsCount } = useSelector(
    (state: RootState) => state.reports
  );

  const smoOnlyView =
    isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
    isSMO &&
    !isFinancial &&
    !isNonFinancial;

  const navLinks = useMemo(() => {
    if (isFinancial || omersUser) {
      // filter disabled feature flag links
      return NAV_LINKS.filter(link => {
        switch (link.featureFlag) {
          case currentFlags.BATCH_PROCESSING:
            return isEnabled(featureFlags, currentFlags.BATCH_PROCESSING);
          case currentFlags.ANNUAL_RECONCILIATION:
            return isEnabled(featureFlags, currentFlags.ANNUAL_RECONCILIATION);
          case currentFlags.REPORTS:
            return isEnabled(featureFlags, currentFlags.REPORTS);
          case currentFlags.RETURNED_REQUESTS:
            return isEnabled(featureFlags, currentFlags.RETURNED_REQUESTS);
          case currentFlags.LEGACY_ECORR_OPTIONS:
            return isEnabled(featureFlags, currentFlags.LEGACY_ECORR_OPTIONS);
          case currentFlags.BATCH_PRINT:
            return isEnabled(featureFlags, currentFlags.BATCH_PRINT);
          case currentFlags.DATA_EXTRACT:
            return isEnabled(featureFlags, currentFlags.DATA_EXTRACT);
          case currentFlags.NFT:
            return isEnabled(featureFlags, currentFlags.NFT);
          case currentFlags.ENABLE_ESR:
            return isEnabled(featureFlags, currentFlags.ENABLE_ESR);
        }

        return true;
      }).filter(link => {
        if (
          link.key === 'stewardshipReport' &&
          isEnabled(featureFlags, currentFlags.REPORTS)
        ) {
          return false;
        }
        return true;
      });
    }

    if (smoOnlyView) {
      return SMO_ONLY_NAV_LINKS.filter(link => {
        if (
          link.key === 'stewardshipReport' &&
          isEnabled(featureFlags, currentFlags.REPORTS)
        ) {
          return false;
        }
        if (
          !isEnabled(featureFlags, currentFlags.REPORTS) &&
          link.key === 'reports'
        ) {
          return false;
        }
        return true;
      });
    }

    // filter non-financial links
    return NAV_LINKS.filter(link => {
      if (
        isEnabled(featureFlags, currentFlags.SMO_ATTESTATION) &&
        isSMO &&
        link.key === 'messages'
      ) {
        return true;
      }
      if (
        isEnabled(featureFlags, currentFlags.REPORTS) &&
        isSMO &&
        link.key === 'reports'
      ) {
        return true;
      }
      if (
        isEnabled(featureFlags, currentFlags.REPORTS) &&
        isSMO &&
        link.key === 'stewardshipReport'
      ) {
        return false;
      }
      if (link.featureFlag === currentFlags.ENABLE_ESR) {
        return isEnabled(featureFlags, currentFlags.ENABLE_ESR);
      }
      return (
        link.key !== 'messages' &&
        link.key !== 'batch' &&
        link.key !== 'annual' &&
        link.key !== 'reports' &&
        link.key !== 'returnedRequests' &&
        link.key !== 'legacyEcorrespondance' &&
        link.key !== 'batchPrint' &&
        link.key !== 'dataExtract'
      );
    });
  }, [smoOnlyView, isFinancial, omersUser, featureFlags, isSMO]);

  // poll message inbox endpoint on interval for latest message count if financial user
  useEffect(() => {
    const interval = 60000;
    const pollInboxInterval =
      isFinancial || omersUser
        ? setInterval(() => {
            dispatch(getReturnedCountAction());
            dispatch(getInboxCountAction());
          }, interval)
        : null;

    dispatch(getPaginationInfoAction());

    return () => {
      if (pollInboxInterval) {
        clearTimeout(pollInboxInterval);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFinancial]);

  return (
    <nav className={styles.nav}>
      {navLinks.map(link => {
        const buttonClass = cx({
          buttonWrapper: true,
          active: link.paths.includes(last(Router.pathname.split('/', 2)) ?? '')
        });

        const href =
          link.key === 'stewardshipReport'
            ? `/reports/stewardship/${currentGroupID}`
            : link.href;

        const icon = () => {
          if (link.key === 'messages' && inboxCount > 0) {
            return (
              <>
                <ReactSVG
                  src="/images/messages-partial.svg"
                  className={cx({
                    icon: true,
                    messages: link.key === 'messages'
                  })}
                />
                <span className={styles.messagesCount}>
                  <span className={styles.messagesCountText}>
                    {inboxCount > 99 ? '99+' : inboxCount}
                  </span>
                </span>
              </>
            );
          }

          if (link.key === 'nft' && nftCount > 0) {
            return (
              <>
                <ReactSVG
                  src="/images/nft.svg"
                  className={cx({
                    icon: true,
                    messages: link.key === 'nft'
                  })}
                />
                <span className={styles.nftCount}>
                  <span className={styles.nftCountText}>
                    {nftCount > 99 ? '99+' : nftCount}
                  </span>
                </span>
              </>
            );
          }

          if (link.key === 'returnedRequests' && returnedRequestsCount > 0) {
            return (
              <>
                <ReactSVG
                  src="/images/returned-requests-full.svg"
                  className={cx({
                    icon: true,
                    returnedRequests: link.key === 'returnedRequests'
                  })}
                />
                <span className={styles.returnedRequestsCount}>
                  <span className={styles.returnedRequestsCountText}>
                    {returnedRequestsCount > 99 ? '99+' : returnedRequestsCount}
                  </span>
                </span>
              </>
            );
          }

          if (link.key === 'reports' && newMssReportsCount > 0) {
            return (
              <>
                <ReactSVG
                  src="/images/reports-nav-unread.svg"
                  className={cx({
                    icon: true,
                    returnedRequests: link.key === 'reports'
                  })}
                />
                <span className={styles.messagesCount}>
                  <span className={styles.messagesCountText}>
                    {newMssReportsCount > 99 ? '99+' : newMssReportsCount}
                  </span>
                </span>
              </>
            );
          }

          return (
            <>
              <ReactSVG src={link.icon} className={styles.icon} />
            </>
          );
        };

        return (
          <div className={buttonClass} key={link.key}>
            <Link
              href={href}
              className={styles.link}
              data-testid={`${link.key}-nav-link`}
            >
              {icon()}
              <span className={styles.text}>{t(link.translation)}</span>
            </Link>
          </div>
        );
      })}
    </nav>
  );
};

export default Nav;
