import { all, put, call, select, takeEvery } from 'redux-saga/effects';
import { FeatureInterface } from 'unleash-client/lib/feature';

import { safeEffect } from 'store/sagas/sagaUtils';
import { RootState } from 'types';
import { getFeatureFlags } from 'services';
import { PromiseReturn } from 'utils/typescript/util-types';
import { showErrorToast } from 'features/v2/toaster';
import {
  featureFlagsActionTypes,
  getFeatureFlagsFailedAction,
  getFeatureFlagsSuccessAction
} from './featureFlagsActions';

export function* getFeatureFlagsSaga() {
  const cachedFeatureFlags: Array<FeatureInterface> = yield select(
    (state: RootState) => state.featureFlags.featureFlags
  );

  try {
    const res: PromiseReturn<typeof getFeatureFlags> = yield call(
      getFeatureFlags
    );

    yield put(getFeatureFlagsSuccessAction(res));
  } catch (error) {
    // if error occurred set possible cached flags as active feature flags if exists to improve user experience
    if (cachedFeatureFlags.length > 0) {
      yield put(getFeatureFlagsSuccessAction(cachedFeatureFlags));
    } else {
      yield put(getFeatureFlagsFailedAction());

      yield put(
        showErrorToast({
          message: 'Some features failed to load, please try refreshing'
        })
      );
    }
  }
}

export function* featureFlagsSaga(): any {
  yield all([
    yield takeEvery(
      featureFlagsActionTypes.FEATURE_FLAGS_REQUESTED,
      safeEffect,
      getFeatureFlagsSaga
    )
  ]);
}
