import Button from 'components/v2/atomic/button/Button';
import Icon, { IconName } from 'components/v2/atomic/icon/Icon';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import Menu from 'components/v2/atomic/menu/Menu';
import MenuItem from 'components/v2/atomic/menuItem/MenuItem';
import Popover from 'components/v2/atomic/popover/Popover';
import { ExactTheme } from 'styles/theme';
import { ThemeUICSSObject, useThemeUI } from 'theme-ui';
import { useEffect, useState } from 'react';

import Link from 'next/link';
import Divider from 'components/v2/atomic/divider/Divider';

export type SubMenuItem = {
  id: string;
  name: React.ReactNode;
  icon?: IconName;
  url?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
  hideItem?: boolean;
  sxOverride?: ThemeUICSSObject;
};

export interface MainNavButtonProps {
  id: string;
  buttonText?: string | JSX.Element;
  buttonColor?: 'light' | 'dark' | string;
  isDropdown?: boolean;
  isTextOnly?: boolean;
  rightDivider?: boolean;
  leftDivider?: boolean;
  subMenuItems?: SubMenuItem[];
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  isDisabled?: boolean;
  url?: string;
  openInNewTab?: boolean;
  onClick?: () => void;
}

const MainNavButton = ({
  id,
  buttonText,
  buttonColor = 'light',
  isDropdown = false,
  isTextOnly = false,
  rightDivider = false,
  leftDivider = false,
  subMenuItems,
  icon,
  iconPosition = 'right',
  isDisabled = false,
  url,
  openInNewTab = false,
  onClick
}: MainNavButtonProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [iconName, setIconName] = useState(icon);
  const ctx = useThemeUI();
  const theme = ctx.theme as ExactTheme;

  const NavButton = () => {
    useEffect(() => {
      if (icon) {
        setIconName(icon);
      } else if (isDropdown && !isDropdownOpen) {
        setIconName(IconNamesSmall.ARROW_DOWN);
      } else if (isDropdown && isDropdownOpen) {
        setIconName(IconNamesSmall.ARROW_UP);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDropdownOpen]);

    const getButtonBackgroundColor = () => {
      if (buttonColor === 'light') {
        return 'white';
      }
      if (buttonColor === 'dark') {
        return theme.colors.brandNavy100;
      }
      return buttonColor;
    };

    const buttonStyles = {
      maxHeight: '100%',
      fontFamily: 'body',
      color: buttonColor === 'light' ? 'brandNavy100' : 'white',
      backgroundColor: getButtonBackgroundColor(),
      border: 'none',
      svg: {
        color: buttonColor === 'light' ? 'brandNavy100' : 'white'
      },
      '&:disabled': {
        color: buttonColor === 'light' ? 'grey80' : 'brandNavy50',
        backgroundColor: buttonColor === 'light' ? 'white' : 'brandNavy100',
        svg: {
          color: buttonColor === 'light' ? 'grey80' : 'brandNavy50'
        }
      },
      '&:hover:not(:disabled)': {
        border: 'none',
        textDecoration: 'none',
        backgroundColor:
          buttonColor === 'light' ? 'extendedBlue25' : 'brandNavy75',
        color: buttonColor === 'light' ? 'brandNavy100' : 'white',
        svg: {
          color: buttonColor === 'light' ? 'brandNavy100' : 'white'
        }
      },
      '&:active:not(:disabled)': {
        textDecoration: 'none',
        border: 'none',
        color:
          buttonColor === 'light'
            ? `${theme.colors.extendedBlue100} !important`
            : 'white',
        svg: {
          color:
            buttonColor === 'light'
              ? `${theme.colors.extendedBlue100} !important`
              : 'white'
        }
      },
      '&:focus': {
        outline: 'none',
        color: buttonColor === 'light' ? 'brandNavy100' : 'white',
        backgroundColor: getButtonBackgroundColor()
      },
      '&:focus-visible:not(disabled)': {
        backgroundColor:
          buttonColor === 'light' ? 'extendedBlue25' : 'brandNavy75',
        border: 'none',
        color: buttonColor === 'light' ? 'brandNavy100' : 'white',
        outline:
          buttonColor === 'light'
            ? `2px solid ${theme.colors.extendedBlue100}`
            : '2px solid white',
        svg: {
          color: buttonColor === 'light' ? 'extendedBlue100' : 'extendedBlue50'
        }
      }
    };

    if (isTextOnly) {
      return (
        <div sx={{ display: 'flex', alignItems: 'center' }}>
          {leftDivider && (
            <Divider
              id={`main-nav-button-leftdivider-${id}`}
              vertical
              condensed
              sx={{ height: '2.1875rem' }}
            />
          )}
          <span
            sx={{
              padding: !buttonText ? '0.875rem' : '0.75rem 1rem',
              lineHeight: '1.5rem',
              ...buttonStyles,
              '&:hover:not(:disabled)': {
                backgroundColor: 'transparent'
              },
              '&:active:not(:disabled)': {
                color: buttonColor === 'light' ? 'brandNavy100' : 'white'
              }
            }}
          >
            {buttonText}
          </span>
          {rightDivider && (
            <Divider
              id={`main-nav-button-rightdivider-${id}`}
              vertical
              condensed
              sx={{ height: '2.1875rem' }}
            />
          )}
        </div>
      );
    }

    if (!isDropdown && url) {
      return (
        <div sx={{ display: 'flex', alignItems: 'center' }}>
          {leftDivider && (
            <Divider
              id={`main-nav-button-leftdivider-${id}`}
              vertical
              condensed
              sx={{ height: '2.1875rem' }}
            />
          )}
          <Link
            sx={{
              padding: !buttonText ? '0.875rem' : '0.75rem 1rem',
              textDecoration: 'none',
              borderRadius: '5px',
              display: 'flex',
              gap: '0.5rem',
              alignItems: 'center',
              lineHeight: '1.5rem',
              ...buttonStyles
            }}
            href={url}
            target={openInNewTab ? '_blank' : '_self'}
            rel={openInNewTab ? 'noopener noreferrer' : undefined}
          >
            {!!iconName && iconPosition === 'left' && <Icon icon={iconName} />}
            {buttonText}
            {!!iconName && iconPosition === 'right' && <Icon icon={iconName} />}
          </Link>
          {rightDivider && (
            <Divider
              id={`main-nav-button-rightdivider-${id}`}
              vertical
              condensed
              sx={{ height: '2.1875rem' }}
            />
          )}
        </div>
      );
    }
    return (
      <div sx={{ display: 'flex', alignItems: 'center' }}>
        {leftDivider && (
          <Divider
            id={`main-nav-button-leftdivider-${id}`}
            vertical
            condensed
            sx={{ height: '2.1875rem' }}
          />
        )}
        <Button
          id={id}
          variant="simple"
          disabled={isDisabled}
          isIconButton={!buttonText}
          sxOverride={{
            ...buttonStyles
          }}
          onClick={() => {
            if (isDropdown) {
              setIsDropdownOpen(!isDropdownOpen);
            } else if (onClick) {
              onClick();
            }
          }}
        >
          {!!iconName && iconPosition === 'left' && <Icon icon={iconName} />}
          {buttonText}
          {!!iconName && iconPosition === 'right' && <Icon icon={iconName} />}
        </Button>
        {rightDivider && (
          <Divider
            id={`main-nav-button-divider-${id}`}
            vertical
            condensed
            sx={{ height: '2.1875rem' }}
          />
        )}
      </div>
    );
  };

  const dropdownItems = subMenuItems ? (
    <Menu id={`${id}-submenu`}>
      {subMenuItems
        .filter(item => !item.hideItem)
        .map(item =>
          item.url ? (
            <Link
              href={item.url ?? ''}
              key={item.id}
              target={item.openInNewTab ? '_blank' : '_self'}
              sx={{
                textDecoration: 'none'
              }}
            >
              <MenuItem
                id={`${id}-${item.id}`}
                text={item.name}
                icon={item.icon}
                target={item.openInNewTab ? '_blank' : '_self'}
                sxOverride={item.sxOverride}
              />
            </Link>
          ) : (
            <MenuItem
              key={item.id}
              id={`${id}-${item.id}`}
              text={item.name}
              icon={item.icon}
              onClick={item.onClick}
              sxOverride={item.sxOverride}
            />
          )
        )}
    </Menu>
  ) : null;

  return (
    <>
      {isDropdown && !isDisabled ? (
        <Popover
          transitionDuration={0}
          content={dropdownItems ?? undefined}
          placement="bottom-start"
          minimal
          containerPadding="0px"
          width="fit-content"
          onOpening={() => setIsDropdownOpen(true)}
          onClose={() => setIsDropdownOpen(false)}
          enforceFocus={false}
          usePortal={false}
          autoFocus={false}
        >
          <NavButton />
        </Popover>
      ) : (
        <NavButton />
      )}
    </>
  );
};

export default MainNavButton;
