import { EaccessAPIError } from 'services';
import { EapAction } from 'types';
import { notificationActionTypes } from './notificationsActions';

export interface Notification {
  type: string;
  message: string | EaccessAPIError[];
}

/**
 * Generally use the common shared notification object for displaying any
 * notification messages; separate unique notifications (i.e. lockedForm, etc.)
 * are specifically for managing separately and/or in parallel with the common
 * notification messages
 */
export interface NotificationsState {
  showNotification: boolean;
  notification: Notification | null;
  lockedForm: {
    showNotification: boolean;
    notification: Notification | null;
  };
}

const initialState = {
  showNotification: false,
  notification: null,
  lockedForm: {
    showNotification: false,
    notification: null
  }
};

const notifications = (
  state: NotificationsState = initialState,
  action: EapAction
): NotificationsState => {
  const { type, data } = action;
  switch (type) {
    case notificationActionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        showNotification: false,
        notification: null
      };
    case notificationActionTypes.SYSTEM_ERROR_DETECTED:
    case notificationActionTypes.NOTIFY_ERROR:
      return {
        ...state,
        showNotification: true,
        notification: { type: 'error', message: data }
      };
    case notificationActionTypes.NOTIFY_INFO:
      return {
        ...state,
        showNotification: true,
        notification: { type: 'info', message: data }
      };
    case notificationActionTypes.NOTIFY_SUCCESS:
      return {
        ...state,
        showNotification: true,
        notification: { type: 'success', message: data }
      };
    case notificationActionTypes.NOTIFY_WARNING:
      return {
        ...state,
        showNotification: true,
        notification: { type: 'warning', message: data }
      };
    case notificationActionTypes.NOTIFY_ERROR_LOCKED_FORM:
      return {
        ...state,
        lockedForm: {
          showNotification: true,
          notification: { type: 'error', message: data }
        }
      };
    case notificationActionTypes.CLEAR_NOTIFICATION_LOCKED_FORM:
      return {
        ...state,
        lockedForm: {
          showNotification: false,
          notification: null
        }
      };
    default:
      return state;
  }
};

export default notifications;
