import { DonnaApiResponse } from '@omers-packages/next-donna-gateway-handler';

export const MSSReportCodes = {
  annualStatementOfPaymentRemittances: 'EMC104',
  form119ContributionAdjustmentSummary: 'ANR126',
  membersApproachingAge71: 'MVT101',
  retirementForecasting: 'MRQ103'
} as const;

export interface ReportDetail {
  jobNo: number;
  creationDate: string;
  year: string;
  month?: string;
}

export interface MSSReport {
  id:
    | typeof MSSReportCodes.annualStatementOfPaymentRemittances
    | typeof MSSReportCodes.form119ContributionAdjustmentSummary
    | typeof MSSReportCodes.membersApproachingAge71;
  description: string;
  details: ReportDetail[];
}

export interface MSSReportData {
  jobNo: number;
  employerNo: string | null;
  status: string;
  statusDescription: string | null;
  completionDate: string;
  requestedBy: string | null;
  reportId:
    | typeof MSSReportCodes.annualStatementOfPaymentRemittances
    | typeof MSSReportCodes.form119ContributionAdjustmentSummary
    | typeof MSSReportCodes.membersApproachingAge71;
  reportDescription: string;
  extension: string;
  removeInd: string;
}

export type MSSReportsApiResponse = DonnaApiResponse<MSSReportData[]>;

export type AdhocReportTypes = typeof MSSReportCodes.retirementForecasting;

export interface AdhocReportParams {
  employerGroupNo: string;
  offset?: number;
  isPaged?: boolean;
  page?: number;
  pageSize?: number;
  reportType?: AdhocReportTypes;
  dateTo?: string;
  dateFrom?: string;
  requestedBy?: string;
  isSorted?: boolean;
}

export interface GenerateAdhocReportParams {
  type: AdhocReportTypes;
  mssReport: AdhocReportTypes;
  employerNo: number;
  employerGroupNo: string;
  retirementDate?: string;
  externalUserId: string;
}

export interface AdhocReportDataContent {
  jobNo: number;
  employerNo: number;
  status: string;
  completionDate: string;
  requestedBy: string;
  reportId: string;
  reportDescription: string;
}

export interface AdhocReportData {
  content: Array<AdhocReportDataContent>;
  pageable: {
    unsorted: boolean;
    sort: {
      sorted: boolean;
      empty: boolean;
      pageSize: number;
    };
    pageNumber: number;
    offset: number;
    paged: boolean;
    unpaged: boolean;
  };
  totalPages: number;
  totalElements: number;
  last: boolean;
  first: boolean;
  size: number;
  number: number;
  sort: {
    unsorted: boolean;
    sorted: boolean;
    empty: boolean;
  };
  numberOfElements: number;
  empty: boolean;
}

export type AdhocReportApiResponse = DonnaApiResponse<AdhocReportData>;
