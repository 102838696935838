import {
  all,
  takeLeading,
  takeLatest,
  put,
  select,
  call,
  delay
} from 'redux-saga/effects';
import { format } from 'url';
import Router from 'next/router';

import { EapAction, RootState } from 'types';
import { getApiErrorMessage } from 'utils';
import {
  memberSearch,
  createForm165c,
  getForm165c,
  adjustLeavePeriodForm165c,
  removeLeavePeriodForm165c,
  updateLeavePeriodForm165c,
  saveForm165c,
  cancelLeavePeriodForm165c,
  getOriginalState165c,
  submitForm165c
} from 'services';
import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import {
  lockFormSaga,
  unlockFormSaga,
  clearLockedFormNotificationSaga,
  deleteFormSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  notifyLockedFormErrorAction,
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import { LeavePeriod } from 'interfaces';
import {
  form165cActionTypes,
  getForm165cSuccessAction,
  getForm165cFailedAction,
  deleteForm165cSuccessAction,
  deleteForm165cFailedAction,
  getLatestForm165cSuccessAction,
  setEditAdjustLeavePeriodForm165cAction,
  setForm165cStepAction,
  saveForm165cSuccessAction,
  saveForm165cFailedAction,
  updateLeavePeriod165cSuccessAction,
  setIsAddLeavePeriod165cAction,
  setAddPeriodStep165cAction,
  updateLeavePeriod165cFailedAction,
  setForm165cSavingStatusAction,
  submitForm165cAction,
  clearForm165cStatusAction,
  getOriginalFormState165cSuccessAction,
  setForm165cSubmitStatusAction,
  submitForm165cSuccessAction
} from './form165cActions';
import {
  mapForm165,
  buildCreateUpdateRequestModel
} from '../common/form165/utils/form165Utils';

/**
 * Fetch original state for 165c form
 */
export function* getOriginalStateForm165c(): any {
  const { requestNumber } = yield select((state: RootState) => state.form165c);
  yield put(startLoadingAction());

  // fetch 165c form latest data
  const res = yield call(safeAuthEffect, getOriginalState165c, requestNumber);

  if (res.data) {
    yield put(getOriginalFormState165cSuccessAction(res.data?.e165cModel));
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(notifyErrorAction(res.errors)));
  }
  yield put(clearLoadingAction());
}

/**
 * Common fetch logic for latest form 165c data
 */
export function* getLatestForm165c(): any {
  const { requestNumber } = yield select((state: RootState) => state.form165c);

  // fetch 165c form latest data
  const res = yield call(safeAuthEffect, getForm165c, requestNumber);

  if (res.data) {
    yield put(
      getLatestForm165cSuccessAction({
        leavePeriods: res.data?.e165cModel?.leavePeriods ?? [],
        availableBrokenServices:
          res.data?.e165cModel?.availableBrokenServices ?? [],
        commentsToMember: res.data?.e165cModel?.commentsToMember ?? '',
        notes: res.data?.e165cModel?.notes ?? '',
        exceptions: res.data?.e165cModel?.exceptions ?? []
      })
    );
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(notifyErrorAction(res.errors)));
  }
}

export function* createForm165cSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm165c, action.data);

  if (res.data) {
    // route user to newly created 165c
    yield call(
      Router.push,
      '/form/165c/[id]',
      format({ pathname: `/form/165c/${res.data.requestNumber}` })
    );
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* getForm165cSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  // fetch 165c form
  const res = yield call(safeAuthEffect, getForm165c, requestNumber);

  if (res.data) {
    // fetch member info
    const memberInfoRes = yield call(safeAuthEffect, memberSearch, {
      omersMembershipNumber: res?.data?.e165cModel?.membershipNo,
      employerNumber: res?.data?.e165cModel?.employerNo
    });

    yield put(
      getForm165cSuccessAction({
        employerNo: res?.data?.e165cModel?.employerNo,
        requestNumber,
        memberInfo:
          memberInfoRes?.data?.memberSearchResult?.memberships[0] ?? null,
        leavePeriods: res.data?.e165cModel?.leavePeriods ?? [],
        availableBrokenServices:
          res.data?.e165cModel?.availableBrokenServices ?? [],
        commentsToMember: res.data?.e165cModel?.commentsToMember ?? '',
        notes: res.data?.e165cModel?.notes ?? '',
        exceptions: res.data?.e165cModel?.exceptions ?? []
      })
    );

    // route user to form if not already there
    if (Router.route !== '/form/165c/[id]') {
      yield call(
        Router.push,
        '/form/165c/[id]',
        format({ pathname: `/form/165c/${requestNumber}` })
      );
    }
  } else {
    yield put(getForm165cFailedAction());
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(res.errors)));

    // navigate off the request if user is on the page from a direct link
    if (Router.route === '/form/165c/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());
  yield call(clearLockedFormNotificationSaga);
}

export function* unlockForm165cSaga(action: EapAction) {
  const { submissionComplete } = yield select(
    (state: RootState) => state.form165c
  );

  // prevent unnecessary unlock if form successfully submitted
  if (!submissionComplete) {
    yield call(unlockFormSaga, action);
  }
}

export function* deleteForm165cSaga(action: EapAction) {
  yield call(deleteFormSaga, action, {
    deleteSuccessAction: deleteForm165cSuccessAction(),
    deleteFailedAction: deleteForm165cFailedAction()
  });
}

export function* adjustLeavePeriodForm165cSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const { requestNumber } = yield select((state: RootState) => state.form165c);

  const res = yield call(
    safeAuthEffect,
    adjustLeavePeriodForm165c,
    requestNumber,
    action.data
  );

  // fetch latest form data if elected to adjust successful and set edit view
  if (res.data) {
    yield call(getLatestForm165c);
    yield put(
      setEditAdjustLeavePeriodForm165cAction(res?.data?.intervalNumber ?? null)
    );
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
  }

  yield put(clearLoadingAction());
}

export function* removeLeavePeriodForm165cSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const { requestNumber } = yield select((state: RootState) => state.form165c);

  const res = yield call(
    safeAuthEffect,
    removeLeavePeriodForm165c,
    requestNumber,
    action.data
  );

  // fetch latest form data if remove successful
  if (res.status === 204) {
    yield call(getLatestForm165c);
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
  }

  yield put(clearLoadingAction());
}

export function* changeLeavePeriodCancelStateForm165cSaga(
  action: EapAction
): any {
  yield put(startLoadingAction());

  const { requestNumber } = yield select((state: RootState) => state.form165c);

  // if purchaseNumber provided, elect leave period then set to cancel
  const adjustRes = action.data.purchaseNumber
    ? yield call(
        safeAuthEffect,
        adjustLeavePeriodForm165c,
        requestNumber,
        action.data.purchaseNumber
      )
    : null;

  const intervalNumber = action.data.purchaseNumber
    ? adjustRes?.data?.intervalNumber
    : action.data.intervalNumber;

  const res = yield call(
    safeAuthEffect,
    cancelLeavePeriodForm165c,
    {
      leavePeriods: [
        {
          brokenService: {
            cancelLeaveInd: action.data.purchaseNumber ? 'Y' : action.data.state
          }
        }
      ]
    },
    requestNumber,
    intervalNumber
  );

  // fetch latest form data if change to cancel state successful
  if (res.data) {
    yield call(getLatestForm165c);
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
  }

  yield put(clearLoadingAction());
}

export function* saveForm165cSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form165c);

  const res = yield call(
    safeAuthEffect,
    saveForm165c,
    {
      commentsToMember:
        action.data.commentsToMember?.length > 0
          ? action.data.commentsToMember
          : null,
      notes: action.data.notes?.length > 0 ? action.data.notes : null,
      exceptions: action.data.exceptions
    },
    requestNumber
  );

  if (res.data) {
    yield put(saveForm165cSuccessAction(action.data));

    if (
      action.type === form165cActionTypes.SAVE_AND_REVIEW_FORM_165C_REQUESTED
    ) {
      yield put(setForm165cStepAction(2));
    }

    if (action.data.submitForm) {
      yield put(submitForm165cAction());
    }
  } else {
    yield put(saveForm165cFailedAction());
  }
}

export function* updateLeavePeriod165cSaga(action: EapAction): any {
  const {
    requestNumber,
    activeIntervalNumber,
    adjustLeavePeriodStep
  } = yield select((state: RootState) => state.form165c);

  // update leave period based on activeIntervalNumber set or null
  const res = yield call(
    updateLeavePeriodForm165c,
    buildCreateUpdateRequestModel(
      action.data.formData,
      null,
      adjustLeavePeriodStep
    ),
    requestNumber,
    activeIntervalNumber,
    adjustLeavePeriodStep
  );

  // fetch latest 165c form data
  const resForm = yield call(safeAuthEffect, getForm165c, requestNumber);

  if (res.data && resForm.data) {
    const intervalNumber =
      !activeIntervalNumber || action.data.resetLeavePeriod
        ? res.data.intervalNumber
        : activeIntervalNumber;

    yield put(
      updateLeavePeriod165cSuccessAction({
        formData: mapForm165(
          resForm.data?.e165cModel?.leavePeriods?.find(
            (period: LeavePeriod) =>
              period.brokenService.intervalNumber === intervalNumber
          ),
          {
            noteToMember: resForm.data?.e165cModel?.commentsToMember ?? '',
            noteToOmers: resForm.data?.e165cModel?.notes ?? ''
          }
        ),
        leavePeriods: resForm.data?.e165cModel?.leavePeriods ?? [],
        intervalNumber
      })
    );

    if (!action.data.preventWizardUpdate) {
      // close add leave period wizard if final step
      if (
        (action.data.isNonPurchasable && adjustLeavePeriodStep === 2) ||
        (!action.data.isNonPurchasable && adjustLeavePeriodStep === 3)
      ) {
        yield put(setIsAddLeavePeriod165cAction(false));
      } else {
        yield put(setAddPeriodStep165cAction(adjustLeavePeriodStep + 1));
      }
    }

    yield put(clearNotifications());
    yield delay(3000);
    yield put(setForm165cSavingStatusAction(''));
  } else {
    yield put(updateLeavePeriod165cFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield delay(3000);
    yield put(setForm165cSavingStatusAction(''));
  }
}

export function* clearNotificationsSaga() {
  yield put(clearNotifications());
}

export function* clearForm165cStatusSaga() {
  yield delay(3000);
  yield put(clearForm165cStatusAction());
}

export function* submitForm165cSaga(): any {
  yield put(startLoadingAction());
  yield put(setForm165cSubmitStatusAction('submitting'));

  const { requestNumber } = yield select((state: RootState) => state.form165c);

  const res = yield call(safeAuthEffect, submitForm165c, requestNumber);

  if (res?.data?.submitResponse) {
    yield put(clearNotifications());
    yield put(submitForm165cSuccessAction(res.data.submitResponse));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm165cSubmitStatusAction(''));
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(setForm165cSubmitStatusAction('error'));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm165cSubmitStatusAction(''));
  }
}

export function* form165cSaga(): any {
  yield all([
    yield takeLeading(form165cActionTypes.CREATE_FORM_165C, createForm165cSaga),
    yield takeLatest(form165cActionTypes.GET_FORM_165C, getForm165cSaga),
    yield takeLatest(
      form165cActionTypes.GET_ORIGINAL_FORM_STATE_165C,
      getOriginalStateForm165c
    ),
    yield takeLatest(form165cActionTypes.UNLOCK_FORM_165C, unlockForm165cSaga),
    yield takeLeading(form165cActionTypes.DELETE_FORM_165C, deleteForm165cSaga),
    yield takeLeading(
      form165cActionTypes.ADJUST_LEAVE_PERIOD_FORM_165C,
      adjustLeavePeriodForm165cSaga
    ),
    yield takeLeading(
      form165cActionTypes.REMOVE_LEAVE_PERIOD_FORM_165C,
      removeLeavePeriodForm165cSaga
    ),
    yield takeLeading(
      form165cActionTypes.CHANGE_LEAVE_PERIOD_CANCEL_STATE_FORM_165C,
      changeLeavePeriodCancelStateForm165cSaga
    ),
    yield takeLatest(
      form165cActionTypes.UPDATE_LEAVE_PERIOD_165C_REQUESTED,
      updateLeavePeriod165cSaga
    ),
    yield takeLatest(
      [
        form165cActionTypes.SAVE_FORM_165C_REQUESTED,
        form165cActionTypes.SAVE_AND_REVIEW_FORM_165C_REQUESTED
      ],
      saveForm165cSaga
    ),
    yield takeLatest(
      [
        form165cActionTypes.SET_IS_ADD_LEAVE_PERIOD_165C,
        form165cActionTypes.SET_ADD_PERIOD_STEP_165C
      ],
      clearNotificationsSaga
    ),
    yield takeLatest(
      [
        form165cActionTypes.SAVE_FORM_165C_SUCCESS,
        form165cActionTypes.SAVE_FORM_165C_FAILED
      ],
      clearForm165cStatusSaga
    ),
    yield takeLatest(
      form165cActionTypes.SUBMIT_FORM_165C_REQUESTED,
      submitForm165cSaga
    )
  ]);
}

export default form165cSaga;
