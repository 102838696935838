/* eslint-disable no-useless-escape */
export const sinPattern: RegExp = /(?!000|666)[1-7,9]\d{2}( )?\d{3}( )?(?!0000)\d{3}/;
export const phonePattern: RegExp = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
export const validMembershipNumber: RegExp = /(\d){7}(-)?(\d){0,2}/;
export const validNameCharacters: RegExp = /^(?:[a-zA-ZÀ-ÿ \-']|\.)*$/;
export const nonNameCharacters: RegExp = /[^a-zA-ZÀ-ÿ \-']+/g;
export const nonAlphaNumeric: RegExp = /[^a-z0-9]+/gi;
export const notEmployeeId: RegExp = /[^a-z0-9-]+/gi;
export const restrictedBeforeAtSignPattern: RegExp = /[<>\\(),[\]:";àáâäæãåāçćčèéêëēėęîïíīįìłńñôöòóœøōõßśšûüùúūÿžźż]+/gi;
export const restrictedDomainNamePattern: RegExp = /[^a-z0-9.-]+/gi;
export const restrictedTopLevelDomainPattern: RegExp = /[^a-z]+/gi;
export const alphaNumericCharacters: RegExp = /^[a-zA-Z0-9 ]*$/;
export const frenchCharacters: RegExp = /^[àâçéèêëîïôûùüÿñæœ]*$/;
export const restrictedCharacters: RegExp = /[:~!$%^_{}|\"<>?\[\]]/gi;
export const addressCharacters: RegExp = /^[a-zA-Z0-9 À-ÿàâçéèêëîïôûùüÿñæœ'()*+,-.\/;=@\\`]*$/;
export const notNumberOrDecimal: RegExp = /[^0-9.]/g;
export const numbersOnly: RegExp = /\D/g;
