import * as yup from 'yup';
import { formatCurrencyStringToNumber, validateEmailAddress } from 'utils';
import { TFunction } from 'i18next';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { yupResolver } = require('@hookform/resolvers/yup');

const form119Validations = (t: TFunction) => {
  const schema = {
    employeeModel: yup.object().shape({
      email: yup.string().test({
        name: 'email-validation',
        test: function validateEmail(value) {
          if (value) {
            const res = validateEmailAddress(value, t);
            if (res !== '') {
              return this.createError({
                message: res,
                path: 'employeeModel.email'
              });
            }
            return true;
          }
          return true;
        }
      })
    }),
    financialModel: yup.object().shape({
      employmentPeriodModels: yup.array().of(
        yup.object().shape({
          hashMap: yup.object().shape({
            credited_service: yup
              .string()
              .required(
                t('validations:VALIDATION_IS_MANDATORY', {
                  field: t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_1')
                })
              )
              .test(
                'is has 2 or less decimal places',
                t('validations:VALIDATION_MUST_HAVE_2_OR_LESS_DECIMALS', {
                  field: t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_1')
                }),
                value => (value.split('.')[1]?.length ?? 0) <= 2
              )
              .test(
                'is valid value',
                t('validations:VALIDATION_MUST_BE_VALID', {
                  field: t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_1')
                }),
                value => /^\d*(\.\d{1,2})?$/g.test(value)
              )
              .test(
                'is less than or equal to 12',
                t('validations:VALIDATION_CANNOT_BE_GREATHER_THAN', {
                  field1: t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_1'),
                  field2: '12'
                }),
                value => value <= 12
              ),
            earnings: yup.string().required(
              t('validations:VALIDATION_IS_MANDATORY', {
                field: t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_2')
              })
            ),
            pension_adjustment_amt: yup.string().test({
              name: 'pension_adjustment_amt-test',
              test: function validatePA(value) {
                const { isPensionAdjustmentMandatory } = this.parent;
                if (isPensionAdjustmentMandatory === 'Y' && !value) {
                  return false;
                }
                return true;
              },
              message: t('validations:VALIDATION_IS_MANDATORY', {
                field: t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_3')
              }) as string
            }),
            rpp_contribution_amt: yup.string().required(
              t('validations:VALIDATION_IS_MANDATORY', {
                field: t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_4')
              })
            ),
            rca_contribution_amt: yup.string().test({
              name: 'rca-contribution-amt-test',
              test: function validateRCA(value) {
                const {
                  rpp_contribution_amt: rppContributionAmt
                } = this.parent;
                if (
                  rppContributionAmt &&
                  formatCurrencyStringToNumber(rppContributionAmt) > 0
                ) {
                  return true;
                }
                return formatCurrencyStringToNumber(value) <= 0;
              },
              message: t('ANNUAL_REPORTING_RCA_CONTRIBUTIONS_ERROR') as string
            })
          })
        })
      )
    })
  };
  return yupResolver(yup.object().shape(schema));
};

export default form119Validations;
