import { ChangeEvent, FocusEvent, useState, useEffect } from 'react';
import * as React from 'react';
import classNames from 'classnames/bind';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import { isUndefined } from 'lodash';
import InputMask, {
  BeforeMaskedStateChangeStates,
  InputState
} from 'react-input-mask';
import { Controller, Control, useFormContext } from 'react-hook-form-legacy';

import { FormError } from 'types';
import { getErrorMessage } from 'components/forms/formUtils';
import Button from 'components/button/Button';
import ToolTip from 'components/toolTip/ToolTip';
import styles from './TextInput.module.scss';

const cx = classNames.bind(styles);

export interface TextInputProps {
  name: string;
  id?: string;
  value?: string;
  defaultValue?: string;
  labelText?: string;
  optional?: boolean;
  placeholder?: string;
  errorMessage?: string | FormError;
  hasError?: boolean;
  type?:
    | 'text'
    | 'password'
    | 'email'
    | 'textarea'
    | 'number'
    | 'search'
    | 'tel';
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: FocusEvent<HTMLInputElement>) => void;
  minLength?: number;
  maxLength?: number;
  clearInputCallback?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  selectMinWidth?: number;
  children?: React.ReactNode;
  tooltip?: string | Array<string> | React.ReactNode;
  testingTag?: string;
  autoTestingTag?: string;
  disabled?: boolean;
  isHighlighted?: boolean;
  specialToolTip?: boolean;
  specialToolTipText?: string;
  control?: Control;
  mask?: string | Array<string | RegExp>;
  maskPlaceholder?: any;
  beforeMaskedStateChange?: (
    states: BeforeMaskedStateChangeStates
  ) => InputState;
  isCurrency?: boolean;
  isPercentage?: boolean;
  hasCalculator?: boolean;
  calcFn?: () => void;
  calcDisabled?: boolean;
  noMarginBottom?: boolean;
  readOnly?: boolean;
}

/**
 * TextInput works with both react-hook-form register and control. Provide
 * methods.register as ref value to register form field to input for general
 * use; provide methods.control as control value to register controlled form
 * field as InputMask to enable input masking.
 *
 * ex. <TextInput name="firstName" ref={methods.register} />
 * ex. <TextInput name="sin" control={methods.control} mask="999 999 999" />
 *
 * NOTE: If using clearInputCallback, for clear button to work properly with
 * react-hook-form, pass in watch() value as value
 */
const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      id,
      labelText,
      optional = false,
      placeholder,
      errorMessage,
      hasError,
      type = 'text',
      onChange,
      onBlur,
      onFocus,
      minLength,
      maxLength,
      clearInputCallback,
      selectMinWidth,
      children,
      tooltip,
      testingTag,
      autoTestingTag,
      disabled,
      value,
      defaultValue,
      isHighlighted,
      specialToolTip,
      specialToolTipText = '',
      control,
      mask,
      maskPlaceholder = null,
      beforeMaskedStateChange,
      isCurrency = false,
      isPercentage = false,
      hasCalculator = false,
      calcFn = () => {},
      calcDisabled = false,
      noMarginBottom,
      readOnly
    }: TextInputProps,
    ref
  ) => {
    const { t } = useTranslation();
    const currencyInputPadding = '32px';
    const percentageInputPadding = '52px';
    const context = useFormContext();
    const watchedValue = context ? context.watch()[name] : null;

    const inputClassNames = cx({
      textInput: true,
      isFieldError: getErrorMessage(errorMessage) || hasError,
      textInputClearButton: !isUndefined(clearInputCallback),
      specialFocus: specialToolTip || isHighlighted
    });

    const [inputValueLength, setInputValueLength] = useState(
      ref ? watchedValue?.length ?? 0 : value?.length ?? 0
    );

    useEffect(() => {
      setInputValueLength(ref ? watchedValue?.length ?? 0 : value?.length ?? 0);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value, watchedValue]);
    const renderInput = () => {
      if (mask && control) {
        return (
          <Controller
            control={control}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            defaultValue={defaultValue}
            render={({
              name: maskName,
              onChange: maskOnChange,
              onBlur: maskOnBlur,
              value: maskValue
            }) => (
              <InputMask
                mask={mask}
                name={maskName}
                id={id}
                value={maskValue}
                className={inputClassNames}
                // @ts-ignore
                maskPlaceholder={maskPlaceholder}
                beforeMaskedStateChange={beforeMaskedStateChange}
                onChange={e => {
                  // NOTE: react-hook-form Controller does not properly wrap
                  // render={<InputMask ...>} and must manually invoke both form
                  // onChange and masking onChange, same with onBlur below
                  // https://react-hook-form.com/api#Controller
                  // https://github.com/sanniassin/react-input-mask#children
                  if (onChange) onChange(e);
                  maskOnChange(e);
                }}
                onBlur={e => {
                  if (onBlur) onBlur(e);
                  maskOnBlur();
                }}
                onFocus={onFocus}
                placeholder={placeholder}
                type={type}
                minLength={minLength}
                maxLength={maxLength}
                style={{
                  paddingLeft: isCurrency
                    ? currencyInputPadding
                    : `calc(${selectMinWidth}px + 12px)`,
                  paddingRight: isPercentage
                    ? percentageInputPadding
                    : undefined
                }}
                data-testid={testingTag}
                data-auto={autoTestingTag}
                disabled={disabled}
                readOnly={readOnly}
              />
            )}
          />
        );
      }

      return (
        <input
          className={inputClassNames}
          name={name}
          id={id}
          placeholder={placeholder}
          type={type}
          ref={ref}
          value={!ref ? value : undefined}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          minLength={minLength}
          maxLength={maxLength}
          style={{
            paddingLeft: isCurrency
              ? currencyInputPadding
              : `calc(${selectMinWidth}px + 12px)`,
            paddingRight: isPercentage ? percentageInputPadding : undefined
          }}
          data-testid={testingTag}
          data-auto={autoTestingTag}
          disabled={disabled}
          readOnly={readOnly}
        />
      );
    };

    return (
      <div
        className={cx({
          textInputContainer: true,
          noMarginBottom
        })}
      >
        {labelText && (
          <label htmlFor={name} className={styles.labelText}>
            <span>{labelText}</span>
            {optional && (
              <span className={styles.optional}>
                {' '}
                {t('FORM_TEXT_FIELD_OPTIONAL_TAG')}
              </span>
            )}
            {tooltip && <ToolTip content={tooltip} />}
          </label>
        )}
        <div className={styles.inputWrapper}>
          {children && (
            <div
              className={styles.selectInput}
              style={{ minWidth: `${selectMinWidth}px` }}
            >
              {children}
            </div>
          )}
          {isCurrency && (
            <div className={styles.currencyLabel}>
              <span>$</span>
            </div>
          )}
          {isPercentage && (
            <div className={styles.percentageLabel}>
              <span>%</span>
            </div>
          )}
          {hasCalculator && (
            <div className={styles.calculator}>
              <Button
                testingTag={`${testingTag}-calculator`}
                autoTestingTag={`${autoTestingTag}-calculator`}
                baseStyle="basic"
                onClick={() => calcFn()}
                isDisabled={calcDisabled}
              >
                <ReactSVG src="/images/calculator.svg" />
              </Button>
            </div>
          )}
          {specialToolTip ? (
            <ToolTip
              content={specialToolTipText}
              placement="bottom"
              OverlayElement={renderInput()}
              baseStyle="blue"
            />
          ) : (
            renderInput()
          )}
          {inputValueLength > 0 && clearInputCallback && (
            <button
              type="button"
              className={cx({ inputButton: true, isDisabled: disabled })}
              onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
                clearInputCallback(e);
              }}
              data-testid={testingTag ? `${testingTag}-clear-btn` : null}
              data-auto={autoTestingTag ? `${autoTestingTag}-clear-btn` : null}
              disabled={disabled}
            >
              <ReactSVG
                src="/images/cancel.svg"
                className={styles.inputButtonIcon}
              />
            </button>
          )}
        </div>
        {errorMessage && (
          <div
            className={cx({
              [styles.errorMessage]: true,
              errorMsg: true // global class to allow Table component to remove margin easily
            })}
          >
            {getErrorMessage(errorMessage)}
          </div>
        )}
      </div>
    );
  }
);

export default TextInput;
