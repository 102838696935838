import { all, takeLeading, put, call } from 'redux-saga/effects';
import Router from 'next/router';

import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { EapAction, EmployerGroup } from 'types';
import { getApiErrorMessage } from 'utils';
import { RoleTypes } from 'utils/hooks/useRole';
import {
  selectEmployer,
  getClassCoverage,
  getEmailOptoutStatus,
  getUserNames
} from 'services';
import { EmailOptoutResponse } from 'interfaces';
import {
  clearNotifications,
  notifyErrorAction
} from 'containers/notifications/notificationsActions';
import {
  startLoadingAction,
  clearLoadingAction,
  setECorrespondenceOptOutAction,
  setShowEmailNotificationRowAction
} from 'containers/auth/userSessionActions';
import { resetEsrData } from 'containers/stewardshipReport/esrSliceReducer';
import { getInboxCountAction } from 'containers/messages/messagesActions';
import {
  getReturnedCountAction,
  resetAllFilters
} from 'containers/requests/requestsActions';

import { setIdleAction } from 'containers/routeConfirm/idleActionActions';
import { getMSSReportsAction } from 'containers/reports/reportsSliceReducer';
import {
  selectEmployersActionTypes,
  selectEmployerSuccessAction,
  selectGroupNumberSuccessAction,
  setClassCoverageAction,
  getCurrentGroupUsersAction,
  getCurrentGroupUsersSuccessAction,
  getCurrentGroupUsersFailedAction,
  setAttestationCompletedAction
} from './selectEmployersActions';

export function* configureECorrespondence(
  role: number,
  groupNumber: string,
  isSMO: boolean
): any {
  if (role === RoleTypes.FINANCIAL || isSMO) {
    yield put(setShowEmailNotificationRowAction(true));

    // fetch returned action count immediately
    yield put(getReturnedCountAction());

    // fetch latest inbox count immediately
    yield put(getInboxCountAction());

    // get mss reports
    yield put(getMSSReportsAction());

    // fetch email opt out flag
    const emailOptOut: EmailOptoutResponse = yield call(
      safeAuthEffect,
      getEmailOptoutStatus,
      groupNumber
    );

    if (emailOptOut?.records?.length > 0) {
      const optout = emailOptOut.records[0].OM_E_Correspondence_Opt_Out__c;
      yield put(setECorrespondenceOptOutAction(optout));
    }
  } else {
    yield put(setShowEmailNotificationRowAction(false));
  }
}

const getGroupMap = (employerGroups: EmployerGroup[]) => {
  const smoGroup = employerGroups.find(group => group.role === RoleTypes.SMO);
  const otherGroup = employerGroups.find(group => group.role !== RoleTypes.SMO);

  // at least one of the smo group or other group must be defined
  const effectiveGroup = (smoGroup ?? otherGroup) as EmployerGroup;
  return { smoGroup, otherGroup, effectiveGroup };
};

export function* configureClassCoverage(groupNumber: string): any {
  const resClassCoverage = yield call(
    safeAuthEffect,
    getClassCoverage,
    groupNumber
  );

  if (resClassCoverage.data) {
    yield put(setClassCoverageAction(resClassCoverage.data.classCoverage));
  }
}

// external users from select membership dropdown
export function* selectMembershipDropdownSaga(action: EapAction): any {
  try {
    // navigation is required as first step as it will trigger RouteConfirm logic if applicable
    if (!Router.pathname.includes('home')) {
      yield Router.push('/home');
    }
    yield put(startLoadingAction());
    yield put(setAttestationCompletedAction(false));
    const res = yield call(
      safeAuthEffect,
      selectEmployer,
      action.data.groupNumber
    );

    if (res.data) {
      const { effectiveGroup, smoGroup } = getGroupMap(res.data.groups);
      yield put(selectEmployerSuccessAction(effectiveGroup));
      yield put(resetAllFilters());
      yield put(clearNotifications());
      yield put(resetEsrData());
      yield put(getCurrentGroupUsersAction(effectiveGroup.groupNumber));

      yield all([
        call(
          configureECorrespondence,
          effectiveGroup.role,
          effectiveGroup.groupNumber,
          Boolean(smoGroup)
        ),
        call(configureClassCoverage, effectiveGroup.groupNumber),
        put(clearLoadingAction())
      ]);
    } else {
      yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
      yield put(clearLoadingAction());
    }
  } catch (e) {
    // catch cancelled saga from RouteConfirm navigation hault and re-queue
    yield put(setIdleAction(action));
    yield put(clearLoadingAction());
  }
}

function* selectGroupNumberSuccess(employerGroups: EmployerGroup[]) {
  const { smoGroup, effectiveGroup } = getGroupMap(employerGroups);

  yield put(selectGroupNumberSuccessAction(effectiveGroup));
  yield put(resetAllFilters());
  yield put(clearNotifications());

  yield put(getCurrentGroupUsersAction(effectiveGroup?.groupNumber));

  yield all([
    call(
      configureECorrespondence,
      effectiveGroup.role,
      effectiveGroup.groupNumber,
      Boolean(smoGroup)
    ),
    call(configureClassCoverage, effectiveGroup.groupNumber),
    put(clearLoadingAction()),
    call(Router.push, '/home')
  ]);
}

// external users from select membership page
export function* selectMembershipSaga(action: EapAction): any {
  yield put(startLoadingAction());
  yield put(setAttestationCompletedAction(false));
  const res = yield call(
    safeAuthEffect,
    selectEmployer,
    action.data.groupNumber
  );

  if (res.data) {
    yield selectGroupNumberSuccess(res.data.groups);
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(clearLoadingAction());
  }
  yield put(clearLoadingAction());
}

// internal/admin users from select membership page
export function* selectGroupNumberSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, selectEmployer, action.data);

  if (res.data) {
    yield selectGroupNumberSuccess(res.data.groups);
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(clearLoadingAction());
  }
  yield put(clearLoadingAction());
}

export function* getCurrentGroupUsersSaga(action: EapAction): any {
  const groupNumber = action.data;

  const res = yield call(safeAuthEffect, getUserNames, groupNumber);

  if (res.data) {
    yield put(getCurrentGroupUsersSuccessAction(res.data?.users));
  } else {
    yield put(getCurrentGroupUsersFailedAction());
  }
}

export function* selectEmployersSaga() {
  yield all([
    takeLeading(
      selectEmployersActionTypes.SELECT_EMPLOYER_DROPDOWN_REQUESTED,
      selectMembershipDropdownSaga
    ),
    takeLeading(
      selectEmployersActionTypes.SELECT_EMPLOYER_REQUESTED,
      selectMembershipSaga
    ),
    takeLeading(
      selectEmployersActionTypes.SELECT_GROUP_NUMBER_REQUESTED,
      selectGroupNumberSaga
    ),
    takeLeading(
      selectEmployersActionTypes.GET_CURRENT_GROUP_USERS_REQUESTED,
      getCurrentGroupUsersSaga
    )
  ]);
}
