import { ReportedUnReportedServiceFormDataModel } from 'interfaces';
import { merge } from 'lodash';
import { formatCurrencyStringToNumber, formatDateUTC } from 'utils';
import { Form105Data } from '../form105Reducer';

export const mergePayload = (
  formValues: { [key: string]: any },
  formData: Form105Data,
  formStep: number | null
) => ({
  ...formValues,
  ...(formStep === 1
    ? {
        paymentSchedule: merge(
          formData.paymentSchedule,
          formValues.paymentSchedule
        ),
        optionalServiceAgreement:
          formData.optionalServiceAgreement ??
          formValues.optionalServiceAgreement,
        newPaymentSchedule: formValues.newPaymentSchedule ?? [],
        servicePurchaseReportedModels:
          formValues.servicePurchaseReportedModels ||
          formData.servicePurchaseReportedModels
            ? merge(
                formData.servicePurchaseReportedModels,
                formValues.servicePurchaseReportedModels
              )
            : [],
        servicePurchaseUnreportedModels:
          formValues.servicePurchaseUnreportedModels ||
          formData.servicePurchaseUnreportedModels
            ? merge(
                formData.servicePurchaseUnreportedModels,
                formValues.servicePurchaseUnreportedModels
              )
            : [],
        paymentOwingModels: formData.paymentOwingModels,
        contributionModel: {
          memberRcaAmt: formValues.memberRcaAmt,
          memberRppAmt: formValues.memberRppAmt,
          employerRcaAmt: formValues.employerRcaAmt,
          employerRppAmt: formValues.employerRppAmt,
          retroIncludedInd: formValues.retroIncludedInd
        }
      }
    : {})
});

export const getPaymentTypeCode = (code: string) => {
  switch (code) {
    case 'Optional Service':
      return 'OPT';
    case 'NRA Conversion':
      return 'NRA';
    case 'Type 1':
      return 'TY1';
    case 'Type 3':
      return 'TY3';
    default:
      return '';
  }
};

export const mergeDirtyValuesToFormData = (
  formValues: { [key: string]: any },
  formData: Form105Data
) => ({
  ...(formValues
    ? {
        paymentSchedule: merge(
          formData.paymentSchedule,
          formValues.paymentSchedule
        ),
        newPaymentSchedule: merge(
          formData.newPaymentSchedule,
          formValues.newPaymentSchedule
        ),
        servicePurchaseReportedModels: formData.servicePurchaseReportedModels
          ? merge(
              formData.servicePurchaseReportedModels,
              formValues.servicePurchaseReportedModels
            )
          : [],
        servicePurchaseUnreportedModels: formData.servicePurchaseUnreportedModels
          ? merge(
              formData.servicePurchaseUnreportedModels,
              formValues.servicePurchaseUnreportedModels
            )
          : [],
        optionalServiceAgreement: merge(
          formData.optionalServiceAgreement,
          formValues.optionalServiceAgreement
        ),
        paymentOwingModels: formData.paymentOwingModels,
        contributionModel: {
          memberRcaAmt: formValues.memberRcaAmt,
          memberRppAmt: formValues.memberRppAmt,
          employerRcaAmt: formValues.employerRcaAmt,
          employerRppAmt: formValues.employerRppAmt,
          retroIncludedInd: formValues.retroIncludedInd
        }
      }
    : {})
});

export const getFilteredServicePurchase = (
  data: Array<number>,
  serviceArray: any
) => {
  const tempArray: any[] = [...serviceArray];
  data?.forEach((lineNo: number) => {
    tempArray?.forEach((service, i) => {
      if (service?.lineNo === lineNo) {
        tempArray.splice(i, 1);
      }
    });
  });
  return tempArray;
};

export const getAdjustmentIndForPaymentSchedule = (
  payment: any,
  formData: any
) => {
  if (payment?.lineNo) {
    const data = formData?.filter((fd: any) => fd?.lineNo === payment?.lineNo);

    // Existing payment schedule with status O or N and with the lineNo
    if (
      formatCurrencyStringToNumber(payment?.employerAmt) !==
        formatCurrencyStringToNumber(data[0]?.originalEmployerAmt) ||
      formatCurrencyStringToNumber(payment?.memberAmt) !==
        formatCurrencyStringToNumber(data[0]?.originalMemberAmt) ||
      data[0]?.adjustmentReason !== ''
    ) {
      return 'Y';
    }
  }

  return 'N';
};

export const getAdjustmentIndForServicePurchase = (
  service: ReportedUnReportedServiceFormDataModel
) => {
  if (
    formatCurrencyStringToNumber(service?.employerAmt ?? '') > 0 ||
    formatCurrencyStringToNumber(service?.memberAmt ?? '') > 0
  ) {
    return 'Y';
  }
  return 'N';
};

export const isSubSourceCodeDisabled = (code: string) => {
  const disabledSubSourceCodes = ['', 'AL', 'BB', 'I3', 'LS', 'TL'];
  return disabledSubSourceCodes.includes(code);
};

export const getEmployerAmt = (
  service: ReportedUnReportedServiceFormDataModel
) =>
  isSubSourceCodeDisabled(service?.purchaseSubSourceCode ?? '')
    ? null
    : formatCurrencyStringToNumber(service?.employerAmt ?? '');

export const buildCreateUpdateRequestModel = (
  data: Form105Data,
  formStep: number | null,
  requestNumber: number | null,
  remittanceType: string,
  form105Data: Form105Data,
  formMonth: string
) => {
  switch (formStep) {
    case 1: {
      return {
        contributionModel: {
          remittanceType,
          requestNumber,
          formMonth,
          memberRcaAmt: data?.memberRcaAmt
            ? parseFloat(data?.memberRcaAmt.replace(/,/g, ''))
            : null,
          memberRppAmt: data?.memberRppAmt
            ? parseFloat(data?.memberRppAmt.replace(/,/g, ''))
            : null,
          employerRcaAmt: data?.employerRcaAmt
            ? parseFloat(data?.employerRcaAmt.replace(/,/g, ''))
            : null,
          employerRppAmt: data?.employerRppAmt
            ? parseFloat(data?.employerRppAmt.replace(/,/g, ''))
            : null,
          retroIncludedInd: data?.retroIncludedInd,
          paymentMethod: form105Data.summaryContributionModel?.paymentMethod,
          groupNo:
            form105Data.summaryContributionModel?.paymentMethod === 'P'
              ? form105Data.summaryContributionModel.groupNo
              : null,
          offsetAmt: form105Data.summaryModel?.offsetAmt
            ? Number(
                formatCurrencyStringToNumber(
                  form105Data.summaryModel?.offsetAmt
                ).toFixed(2)
              )
            : null,
          bankPaymentDate: form105Data.summaryContributionModel?.bankPaymentDate
            ? formatDateUTC(
                form105Data.summaryContributionModel.bankPaymentDate
              )
            : null,
          totalPaidAmt:
            form105Data.summaryContributionModel?.paymentMethod !== 'P' &&
            form105Data.summaryContributionModel?.totalPaidAmt
              ? formatCurrencyStringToNumber(
                  form105Data.summaryContributionModel.totalPaidAmt
                )
              : null,
          company:
            form105Data.summaryContributionModel?.paymentMethod === 'D'
              ? form105Data.summaryContributionModel.company?.substring(0, 20)
              : null,
          locationNo:
            form105Data.summaryContributionModel?.paymentMethod === 'D'
              ? form105Data.summaryContributionModel.locationNo
              : null,
          confirmationNo:
            form105Data.summaryContributionModel?.paymentMethod === 'D'
              ? form105Data.summaryContributionModel.confirmationNo
              : null
        },
        optionalServiceAgreement: data?.optionalServiceAgreement ?? 'N',
        paymentScheduleModels: [
          ...data?.paymentSchedule?.map(payment => ({
            requestNo: Number(requestNumber),
            lineNo: payment?.lineNo,
            adjustedInd:
              payment?.adjustedInd === 'Y'
                ? payment?.adjustedInd
                : getAdjustmentIndForPaymentSchedule(
                    payment,
                    form105Data?.paymentSchedule
                  ),
            adjustmentReason: payment?.adjustmentReason ?? null,
            employerAmt: payment?.employerAmt
              ? formatCurrencyStringToNumber(payment?.employerAmt)
              : null,
            memberAmt: payment?.memberAmt
              ? formatCurrencyStringToNumber(payment?.memberAmt)
              : null,
            status: 'O',
            includedInd: payment?.includedInd ?? 'N',
            epaymentScheduleType: payment?.epaymentScheduleType ?? 'OPT'
          })),
          ...data?.newPaymentSchedule?.map(payment => ({
            requestNo: Number(requestNumber),
            lineNo: payment?.lineNo !== '' ? Number(payment?.lineNo) : null,
            adjustedInd: null,
            adjustmentReason: payment?.adjustmentReason ?? null,
            employerAmt: payment?.employerAmt
              ? formatCurrencyStringToNumber(payment?.employerAmt)
              : null,
            memberAmt: payment?.memberAmt
              ? formatCurrencyStringToNumber(payment?.memberAmt)
              : null,
            status: 'N',
            includedInd: 'Y',
            epaymentScheduleType: payment?.epaymentScheduleType ?? ''
          }))
        ],
        servicePurchaseReportedModels: [
          ...data?.servicePurchaseReportedModels?.map(
            (service: ReportedUnReportedServiceFormDataModel) => ({
              requestNumber: Number(requestNumber),
              lineNo: service?.lineNo ?? null,
              employerAmt: service?.employerAmt
                ? formatCurrencyStringToNumber(service?.employerAmt)
                : null,
              employmentNo: service?.employmentNo ?? null,
              memberAmt: service?.memberAmt
                ? formatCurrencyStringToNumber(service?.memberAmt)
                : null,
              membershipNo: service?.membershipNo,
              purchaseNo: service?.purchaseNo,
              purchaseSourceCode:
                service?.purchaseSubSourceCode === 'BB' ||
                service?.purchaseSubSourceCode === 'OMS'
                  ? service?.purchaseSubSourceCode
                  : null,
              purchaseSubSourceCode:
                service?.purchaseSubSourceCode !== 'BB' &&
                service?.purchaseSubSourceCode !== 'OMS'
                  ? service?.purchaseSubSourceCode
                  : null,
              adjustedInd: service?.adjustedInd ?? null,
              adjustmentReason: null,
              suppPlanInd: 'N'
            })
          )
        ],
        servicePurchaseUnreportedModels: [
          ...data?.servicePurchaseUnreportedModels?.map(
            (service: ReportedUnReportedServiceFormDataModel) => ({
              requestNumber: Number(requestNumber),
              lineNo: service?.lineNo ?? null,
              employerAmt: service?.employerAmt
                ? getEmployerAmt(service)
                : null,
              employmentNo: service?.employmentNo ?? null,
              memberAmt: service?.memberAmt
                ? formatCurrencyStringToNumber(service?.memberAmt)
                : null,
              membershipNo: service?.membershipNo,
              purchaseNo: service?.purchaseNo,
              purchaseSourceCode:
                service?.purchaseSubSourceCode === 'BB' ||
                service?.purchaseSubSourceCode === 'OMS'
                  ? service?.purchaseSubSourceCode
                  : null,
              purchaseSubSourceCode:
                service?.purchaseSubSourceCode !== 'BB' &&
                service?.purchaseSubSourceCode !== 'OMS'
                  ? service?.purchaseSubSourceCode
                  : null,
              adjustedInd:
                service?.adjustedInd === 'Y'
                  ? service?.adjustedInd
                  : getAdjustmentIndForServicePurchase(service),
              adjustmentReason: null,
              suppPlanInd: 'N'
            })
          )
        ]
      };
    }
    default:
      return {};
  }
};
