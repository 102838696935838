import {
  useState,
  ChangeEvent,
  FocusEvent,
  MouseEvent,
  KeyboardEvent
} from 'react';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { isNil } from 'lodash';
import { Controller, Control } from 'react-hook-form-legacy';

import styles from './TextArea.module.scss';

const cx = classNames.bind(styles);

interface TextAreaProps {
  name: string;
  value?: string;
  title?: string;
  maxLength?: number;
  minLength?: number;
  placeholder?: string;
  disabled?: boolean;
  onKeyUp?: (event: KeyboardEvent<HTMLTextAreaElement>) => void;
  onClick?: (event: MouseEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  errorMessage?: string;
  hasError?: boolean;
  resizeNone?: boolean;
  resizeOnlyVertically?: boolean;
  resizeonlyHorizontally?: boolean;
  maxLengthCounter?: boolean;
  characterCount?: number;
  refRegister?: (instance: HTMLTextAreaElement) => void;
  control?: Control;
  rows?: number;
  testingTag?: string;
  defaultValue?: string;
  id?: string;
}
const TextArea = ({
  name,
  value,
  title,
  maxLength,
  minLength = 0,
  placeholder,
  disabled,
  onKeyUp,
  onClick,
  onBlur,
  onChange,
  errorMessage,
  hasError,
  resizeNone = false,
  resizeOnlyVertically = false,
  resizeonlyHorizontally = false,
  maxLengthCounter = false,
  characterCount,
  refRegister,
  control,
  rows = 7,
  testingTag,
  defaultValue,
  id
}: TextAreaProps) => {
  const { t } = useTranslation();
  const inputClassNames = cx({
    isFieldError: hasError,
    isFieldNonResizable: resizeNone,
    isFieldOnlyVerticallyResizable: resizeOnlyVertically,
    isFieldOnlyHorizontallyResizable: resizeonlyHorizontally
  });

  const [inputValueLength, setInputValueLength] = useState(0);

  // wrap onChange to trigger live character counter; value ignored if parent provides characterCount
  const onChangeWrapper = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(event);
    }

    const val = event.target.value;
    setInputValueLength(val.length);
  };

  if (control) {
    return (
      <Controller
        name={name}
        control={control}
        as={
          <div className={styles.textArea}>
            {title && (
              <label htmlFor={name} className={styles.textAreaLabel}>
                {title}
              </label>
            )}
            <textarea
              className={inputClassNames}
              value={value}
              id={id}
              defaultValue={defaultValue}
              onBlur={onBlur}
              onChange={onChangeWrapper}
              name={name}
              minLength={minLength}
              maxLength={maxLength}
              placeholder={placeholder}
              disabled={disabled}
              onKeyUp={onKeyUp}
              onClick={onClick}
              ref={refRegister}
              rows={rows}
              style={disabled ? { resize: 'none' } : undefined}
              data-testid={testingTag}
            />
            <div className={styles.info}>
              <span className={styles.errorMessage}>{errorMessage}</span>
              {maxLengthCounter && maxLength && (
                <span className={styles.counter}>
                  {!isNil(characterCount) ? characterCount : inputValueLength}{' '}
                  {t('FORM_TEXT_AREA_COUNTER_OF')} {maxLength.toLocaleString()}{' '}
                  {t('FORM_TEXT_AREA_COUNTER_CHARACTERS')}
                </span>
              )}
            </div>
          </div>
        }
      />
    );
  }
  return (
    <div className={styles.textArea}>
      {title && (
        <label htmlFor={name} className={styles.textAreaLabel}>
          {title}
        </label>
      )}
      <textarea
        className={inputClassNames}
        value={value}
        id={id}
        onBlur={onBlur}
        onChange={onChangeWrapper}
        name={name}
        minLength={minLength}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        onKeyUp={onKeyUp}
        onClick={onClick}
        ref={refRegister}
        rows={rows}
        style={disabled ? { resize: 'none' } : undefined}
        data-testid={testingTag}
      />
      <div className={styles.info}>
        <span className={styles.errorMessage}>{errorMessage}</span>
        {maxLengthCounter && maxLength && (
          <span className={styles.counter}>
            {!isNil(characterCount) ? characterCount : inputValueLength}{' '}
            {t('FORM_TEXT_AREA_COUNTER_OF')} {maxLength.toLocaleString()}{' '}
            {t('FORM_TEXT_AREA_COUNTER_CHARACTERS')}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextArea;
