import { transparentize } from '@theme-ui/color';

import {
  Classes,
  Radio as RadioBP,
  RadioProps as RadioPropsBP
} from '@blueprintjs/core';

export interface RadioButtonProps extends RadioPropsBP {
  error?: string;
  noMargin?: boolean;
  id: string;
}

const radioStyles = (
  hasError: boolean,
  isDisabled: boolean,
  noMargin: boolean
) => ({
  [`&.${Classes.RADIO}`]: {
    fontFamily: 'body',
    fontSize: 'small',
    fontWeight: 'body',
    lineHeight: 'base',
    marginBottom: (() => {
      if (hasError) {
        return '8px';
      }
      if (noMargin) {
        return 0;
      }
      return '10px';
    })(),
    [`input ~ .${Classes.CONTROL_INDICATOR} `]: {
      height: '24px',
      width: '24px',
      border: '2px solid',
      borderColor: (() => {
        if (isDisabled) {
          return 'grey80';
        }
        if (hasError) {
          return 'extendedRed100';
        }
        return 'brandNavy100';
      })(),
      backgroundColor: hasError ? 'extendedRed25' : 'grey20',
      boxShadow: 'none'
    },
    [`input:checked ~ .${Classes.CONTROL_INDICATOR} `]: {
      border: 'none',
      backgroundColor: (() => {
        if (isDisabled) {
          return 'brandNavy25';
        }
        if (hasError) {
          return 'extendedRed100';
        }
        return 'brandNavy100';
      })(),
      '::before': {
        height: '24px',
        width: '24px'
      }
    },
    [`input:focus ~ .${Classes.CONTROL_INDICATOR} `]: {
      outlineColor: !isDisabled ? transparentize('#58B4ED', 0.5) : undefined,
      outlineOffset: 0
    },
    [`input:hover ~ .${Classes.CONTROL_INDICATOR} `]: {
      backgroundColor: !(isDisabled || hasError) ? 'grey60' : undefined
    },
    [`input:active ~ .${Classes.CONTROL_INDICATOR} `]: {
      backgroundColor: !(isDisabled || hasError) ? 'grey60' : undefined,
      outline: !isDisabled ? '2px solid' : undefined,
      outlineColor: !isDisabled ? transparentize('#58B4ED', 0.5) : undefined,
      outlineOffset: 0
    },
    [`input:checked:hover ~ .${Classes.CONTROL_INDICATOR} `]: {
      backgroundColor: !(isDisabled || hasError) ? 'extendedBlue100' : undefined
    }
  },
  [`&.${Classes.CONTROL}.${Classes.RADIO} input:checked:disabled ~ .${Classes.CONTROL_INDICATOR}::before`]: {
    opacity: 1
  }
});

const RadioButton: React.FC<RadioButtonProps> = ({
  error,
  noMargin,
  id,
  readOnly,
  disabled,
  ...props
}) => (
  <>
    <RadioBP
      {...props}
      id={id}
      disabled={readOnly || disabled}
      sx={{ ...radioStyles(!!error, !!disabled, !!noMargin) }}
    />
  </>
);

export default RadioButton;
