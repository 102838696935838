/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form-legacy';
import classNames from 'classnames/bind';
import { ReactSVG } from 'react-svg';
import Link from 'next/link';
import { useSelector, useDispatch } from 'react-redux';
import { merge } from 'lodash';

import { RootState } from 'types';
import {
  formatDate,
  maskEmployeeId,
  maskAlphanumeric,
  formatCurrencyStringToNumber,
  formatAsCurrencyOrGetEmptyString
} from 'utils';
import FormHeader from 'components/formHeader/FormHeader';
import SaveProgressModal from 'containers/requestForm/common/modals/SaveProgressModal';
import { clearNotifications } from 'containers/notifications/notificationsActions';
import CreateFailedModal from 'components/modal/createFailedModal/CreateFailedModal';
import ContainerBorder from 'components/containerBorder/ContainerBorder';
import Table from 'components/table/Table';
import ScrollTo from 'components/scrollTo/ScrollTo';
import Divider from 'components/divider/Divider';
import TextInput from 'components/forms/inputs/textInput/TextInput';
import Notification from 'components/notification/Notification';
import CommonNotification from 'containers/notifications/components/commonNotification/CommonNotification';
import ToolTip from 'components/toolTip/ToolTip';
import useModal from 'components/modal/useModal';
import Button from 'components/button/Button';
import { employmentPeriodModelObject } from 'interfaces';
import SubmitLandingPage from 'containers/requestForm/common/submitLandingPage/SubmitLandingPage';
import Modal from 'components/modal/Modal';
import ConfirmationModal from 'containers/requestForm/common/modals/ConfirmationModal';
import RetroPayModal from 'containers/requestForm/common/modals/retroPayModal/RetroPayModal';
import useSavedForm from 'containers/requestForm/common/hooks/useSavedForm';
import useFormExit from 'containers/requestForm/common/hooks/useFormExit';
import useDirtyState from 'containers/requestForm/common/hooks/useDirtyState';
import { EXTERNAL_URLS } from 'utils/constants';
import QASEmailAddressInput from 'components/forms/inputs/qasEmailAddressInput/QASEmailAddressInput';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import form119Validations from './form119Validations';
import { createPeriodColumn } from './utils/annualReportingUtils';
import AnnualReportingReview from './annualReportingReview/AnnualReportingReview';
import AnnualReportingSinglePeriodTable from './AnnualReportingSinglePeriodTable/AnnualReportingSinglePeriodTable';
import AnnualReportingSplitPeriodTable from './AnnualReportingSplitPeriodTable/AnnualReportingSplitPeriodTable';
import {
  setForm119StepAction,
  deleteForm119Action,
  clearForm119ExceptionOverrides,
  getForm119,
  unlockForm119,
  saveForm119,
  saveForm119InStore,
  setCurrentRetroIndex,
  setForm119SubmitStatusAction,
  updateAndSubmitForm119Action,
  getRetroPay,
  setSelectedRetroType,
  setRetroStartDateInStore,
  setRetroEndDateInStore,
  saveForm119AndPerformNewAction,
  form119ActionTypes,
  checkMemberProfileValidity,
  resetValidMember,
  refreshForm119Action,
  setValidEmailAddressStateForm119,
  setValidEmailAddressForm119,
  removeHas11998Or11999Warnings
} from './form119Actions';
import { createForm106InNewTabAction } from '../memberUpdate/form106Actions';
import { createForm165aInNewTabAction } from '../leavePeriodEmployer/form165aActions';
import { Form119State, filterOverrideExceptions } from './form119Reducer';

import styles from './AnnualReporting.module.scss';
import { triggerOverride } from '../common/formActions';

const cx = classNames.bind(styles);

const getPAAmount = (paMandatory: any, paAmount: any) => {
  if (!paMandatory && paAmount === '') {
    return null;
  }
  return formatCurrencyStringToNumber(paAmount);
};

export const formatPayload = (
  requestNumber: string | null,
  data: any,
  paMandatory: boolean,
  exceptions: any = [],
  overrideExceptions: any = [],
  originalEmail: string | null = null
) => {
  const {
    selectedReconciliationYear,
    financialModel,
    employerNo,
    membershipNo
  } = data;
  const payload: any = {
    employerNo,
    membershipNo,
    employeeModel: {
      deptId: data.employeeModel.deptId,
      email: data.employeeModel.email,
      employeeId: data.employeeModel.employeeId
    },
    selectedReconciliationYear,
    financialModel: {
      ...financialModel,
      employmentPeriodModels: data.financialModel.employmentPeriodModels.map(
        (model: any, index: any) => {
          // The API requires the payload structure to include all fields even if hasn't been updated by the user
          // Here we pull the existing hashmap data from the store to complete the structure
          const {
            end_date,
            retro_type,
            rebound_rpp_amt,
            service_period_no,
            retro_end_date,
            pay_periods,
            service_period_type,
            rebound_rca_amt,
            employment_status,
            retro_start_date,
            NRA,
            start_date
          } = financialModel.employmentPeriodModels[index].hashMap;
          return {
            hashMap: {
              REQUEST_NO: requestNumber ? parseInt(requestNumber, 10) : '',
              credited_service:
                model.hashMap.credited_service === ''
                  ? null
                  : Number(model.hashMap.credited_service),
              rca_contribution_amt:
                model.hashMap.rca_contribution_amt === ''
                  ? null
                  : formatCurrencyStringToNumber(
                      model.hashMap.rca_contribution_amt
                    ),
              earnings:
                model.hashMap.earnings === ''
                  ? null
                  : formatCurrencyStringToNumber(model.hashMap.earnings),
              rpp_contribution_amt:
                model.hashMap.rpp_contribution_amt === ''
                  ? null
                  : formatCurrencyStringToNumber(
                      model.hashMap.rpp_contribution_amt
                    ),
              end_date,
              retro_type,
              rebound_rpp_amt,
              service_period_no,
              retro_end_date,
              pension_adjustment_amt:
                model.hashMap.pension_adjustment_amt === ''
                  ? null
                  : getPAAmount(
                      paMandatory,
                      model.hashMap.pension_adjustment_amt
                    ),
              pay_periods,
              service_period_type,
              rebound_rca_amt,
              employment_status,
              retro_start_date,
              NRA,
              start_date
            }
          };
        }
      )
    },
    originalEmail
  };
  if (exceptions && exceptions.length > 0) {
    payload.exceptions = filterOverrideExceptions(overrideExceptions);
    payload.has11998Or11999Warnings =
      filterOverrideExceptions(overrideExceptions)?.filter(
        (x: any) => x.messageid === '11998' || x.messageid === '11999'
      ).length > 0;
  }
  return payload;
};

const AnnualReporting = () => {
  const form119State: Form119State = useSelector(
    (state: RootState) => state.form119
  );
  const {
    initLoading,
    formStep,
    form119Data,
    memberInfo,
    requestNumber,
    formStatus,
    submissionStatus,
    submissionComplete,
    overrideExceptions,
    validMemberProfile,
    validEmailAddressState,
    validEmailAddress,
    currentRetroIndex
  } = form119State;

  const {
    selectedReconciliationYear,
    financialModel,
    originalEmail,
    exceptions,
    employeeModel
  } = form119Data.e119Model;
  const {
    columnYear,
    currentLeavePeriodModel,
    employmentPeriodModels,
    retroPay,
    form119Model,
    prevLeavePeriodModel,
    paMandatory
  } = financialModel;
  const { createFormFailedCode } = useSelector(
    (state: RootState) => state.memberSearch
  );
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );
  const [showValidationError, setShowValidationError] = useState(false);
  const [showRetroPayModal, setShowRetroPayModal] = useState(false);
  const [showResetRequiredModal, toggleResetRequiredModal] = useModal();
  const [showDeleteModal, toggleDeleteModal] = useModal();
  const [isResetModalShowing, toggleResetModal] = useModal();
  const [, toggleSaveProgressModal] = useModal();
  const [isSubmitModalShowing, toggleSubmitModal] = useModal();
  const [showFormRefreshModal, toggleFormRefreshModal] = useModal();
  const [isCreateDeleteModalShowing, toggleCreateDeleteModal] = useModal();
  const [retroStartDate, setRetroStartDate] = useState('');
  const [retroEndDate, setRetroEndDate] = useState('');
  const [formToCreate, setFormToCreate] = useState('');
  const [resetModalShown, setResetModalShown] = useState(false);
  const [retroServicePeriodNo, setRetroServicePeriodNo] = useState(1);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [totalCreditedService, setTotalCreditedService] = useState(0);
  const [totalContributoryEarnings, setTotalContributoryEarnings] = useState(0);
  const [totalPensionAdjustment, setTotalPensionAdjustment] = useState(0);
  const [totalRpp, setTotalRpp] = useState(0);
  const [totalRca, setTotalRca] = useState(0);
  const [totalRetro, setTotalRetro] = useState(0);
  const [
    selectedEmploymentPeriodModel,
    setSelectedEmploymentPeriodModel
  ] = useState<any>(null);
  const { t } = useTranslation(['form119', 'validations', 'forms', 'common']);
  const dispatch = useDispatch();
  const progressBarProps = {
    steps: [
      {
        stepNumber: 1,
        stepLabel: t('common:ENTER_INFORMATION')
      },
      {
        stepNumber: 2,
        stepLabel: t('common:REVIEW')
      },
      {
        stepNumber: 3,
        stepLabel: t('common:COMPLETE')
      }
    ],
    selectedStep: formStep
  };
  const validations = form119Validations(t);
  const methods = useForm({
    resolver: validations,
    defaultValues: form119Data.e119Model,
    mode: 'onBlur'
  });

  const hasErrors = Object.keys(methods.errors).length > 0;

  const { isDirty } = useDirtyState(methods.formState);

  useEffect(() => {
    // Show reset modal if split period and one or more periods are missing NRA or employment_status
    if (
      employmentPeriodModels.length > 1 &&
      !employmentPeriodModels.reduce((prev, curr) => {
        if (prev === false) {
          return false;
        }
        if (curr.hashMap.NRA && curr.hashMap.employment_status) {
          return true;
        }
        return false;
      }, true)
    ) {
      toggleResetRequiredModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employmentPeriodModels]);

  useEffect(() => {
    // trigger form create failed modal
    if (createFormFailedCode) {
      toggleCreateDeleteModal();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createFormFailedCode]);

  const deleteForm119 = () => {
    if (requestNumber) {
      dispatch(deleteForm119Action(requestNumber));
    }
    toggleDeleteModal();
  };

  const refreshForm = () => {
    // Delete current 119 and create fresh one
    // This is intended to be used after a new 106 or 165a form has been completed
    // The fresh form should include changes made in other forms
    toggleFormRefreshModal();
    dispatch(
      refreshForm119Action({
        create119Data: {
          membershipNo: memberInfo?.membershipNumber,
          employerNo:
            memberInfo?.mssemploymentItem?.theMSSEmployer?.employerNumber,
          employeeModel: form119Data.e119Model,
          selectedReconciliationYear
        },
        deleteAction: {
          type: form119ActionTypes.DELETE_FORM_119_REQUESTED,
          data: requestNumber
        }
      })
    );
  };

  useSavedForm(
    methods,
    (state: RootState) => state.form119.form119Data.e119Model,
    () => {
      if (requestNumber && initLoading) {
        dispatch(getForm119({ requestNo: requestNumber }));
      }
    }
  );

  const onSubmit = (data: any) => {
    dispatch(setForm119SubmitStatusAction('submitting'));
    dispatch(removeHas11998Or11999Warnings());
    dispatch(
      updateAndSubmitForm119Action(
        formatPayload(
          requestNumber,
          data,
          paMandatory,
          exceptions,
          overrideExceptions
        )
      )
    );
  };

  const onSubmitCallback = () => {
    toggleSubmitModal();
    onSubmit(form119Data.e119Model);
  };

  const saveCallback = () => {
    const mergedData = merge(form119Data.e119Model, methods.getValues());
    dispatch(
      saveForm119InStore(
        formatPayload(
          requestNumber,
          mergedData,
          paMandatory,
          exceptions,
          overrideExceptions,
          originalEmail
        )
      )
    );
    dispatch(
      saveForm119(
        formatPayload(
          requestNumber,
          mergedData,
          paMandatory,
          exceptions,
          overrideExceptions,
          originalEmail
        )
      )
    );
  };

  const watchedFields = methods.watch();

  // Update totals for split period view and summary table
  useEffect(() => {
    const formEmploymentPeriodModels = methods.getValues().financialModel
      ?.employmentPeriodModels;
    let tempCreditedService = 0;
    let tempContributoryEarnings = 0;
    let tempPensionAdjustment = 0;
    let tempRpp = 0;
    let tempRca = 0;
    let tempRetro = 0;

    if (formEmploymentPeriodModels) {
      formEmploymentPeriodModels.forEach((item: any) => {
        const hashMap = item.hashMap;
        if (hashMap) {
          if (hashMap.credited_service) {
            tempCreditedService += Number(hashMap.credited_service);
          }
          if (hashMap.earnings) {
            tempContributoryEarnings += Number(
              hashMap.earnings.replace(/,/g, '')
            );
          }
          if (hashMap.pension_adjustment_amt) {
            tempPensionAdjustment += Number(
              hashMap.pension_adjustment_amt.replace(/,/g, '')
            );
          }
          if (hashMap.rpp_contribution_amt) {
            tempRpp += Number(hashMap.rpp_contribution_amt.replace(/,/g, ''));
          }
          if (hashMap.rca_contribution_amt) {
            tempRca += Number(hashMap.rca_contribution_amt.replace(/,/g, ''));
          }
        }
      });
    }
    retroPay.forEach((item: any) => {
      if (item) {
        tempRetro += item;
      }
    });
    setTotalCreditedService(tempCreditedService);
    setTotalContributoryEarnings(tempContributoryEarnings);
    setTotalPensionAdjustment(tempPensionAdjustment);
    setTotalRpp(tempRpp);
    setTotalRca(tempRca);
    setTotalRetro(tempRetro);
    if (!columnYear[0]) {
      setShowSummaryTable(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedFields]);

  useEffect(() => {
    if (validMemberProfile && Object.keys(validMemberProfile).length > 0) {
      // create new form for given formNumber and selected member
      const createActionData = {
        membershipNo: validMemberProfile.membershipNumber,
        employerNo:
          validMemberProfile.mssemploymentItem?.theMSSEmployer?.employerNumber
      };
      switch (formToCreate) {
        case '106':
          dispatch(createForm106InNewTabAction(createActionData));
          break;
        case '165a':
          dispatch(createForm165aInNewTabAction(createActionData));
          break;
      }
      dispatch(resetValidMember());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validMemberProfile]);

  const noCurrent =
    currentLeavePeriodModel === null &&
    form119Model !== null &&
    prevLeavePeriodModel !== null;
  const noFormModel =
    currentLeavePeriodModel !== null &&
    form119Model === null &&
    prevLeavePeriodModel !== null;
  const noPrev =
    currentLeavePeriodModel !== null &&
    form119Model !== null &&
    prevLeavePeriodModel === null;
  const onlyCurrent =
    currentLeavePeriodModel !== null &&
    form119Model === null &&
    prevLeavePeriodModel === null;
  const onlyFormModel =
    currentLeavePeriodModel === null &&
    form119Model !== null &&
    prevLeavePeriodModel === null;
  const onlyPrev =
    currentLeavePeriodModel === null &&
    form119Model === null &&
    prevLeavePeriodModel !== null;
  const allThree =
    currentLeavePeriodModel !== null &&
    form119Model !== null &&
    prevLeavePeriodModel !== null;
  const onlyTwo = noCurrent || noFormModel || noPrev;
  const onlyOne = onlyCurrent || onlyFormModel || onlyPrev;

  const getPeriodTableHeadings = () => {
    const firstYear = columnYear[0] || columnYear[1];
    const secondYear = columnYear[2] || columnYear[3];
    const headings = [
      [{ headingCell: '' }, { headingCell: firstYear, txtAlign: 'center' }]
    ];
    if (allThree) {
      headings[0].push({ headingCell: firstYear, txtAlign: 'center' });
      headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
    }
    if (onlyTwo) {
      if (noFormModel || noPrev) {
        headings[0].push({ headingCell: firstYear, txtAlign: 'center' });
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      }
      if (noCurrent) {
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      }
    }
    if (onlyOne) {
      if (onlyCurrent) {
        headings[0].push({ headingCell: firstYear, txtAlign: 'center' });
      } else {
        headings[0].push({ headingCell: secondYear, txtAlign: 'center' });
      }
    }
    return headings;
  };

  const beforeEditingExistingRetro = (
    item: employmentPeriodModelObject,
    index: number
  ) => {
    setSelectedEmploymentPeriodModel(item);
    dispatch(setCurrentRetroIndex(index));
    dispatch(
      setRetroStartDateInStore(
        formatDate(
          item.hashMap.retro_start_date || '',
          true,
          false,
          'MM/dd/yyyy'
        )
      )
    );
    dispatch(
      setRetroEndDateInStore(
        formatDate(item.hashMap.retro_end_date || '', true, false, 'MM/dd/yyyy')
      )
    );
    dispatch(setSelectedRetroType(item.hashMap.retro_type));
    dispatch(
      getRetroPay({
        requestNumber,
        retroServicePeriodNo: item.hashMap.service_period_no
      })
    );
  };

  const summaryTableHeading = getPeriodTableHeadings();

  const getSumForSummaryTableField = (fieldName: string) =>
    form119Data.e119Model.financialModel.employmentPeriodModels.reduce(
      (total: number, currentEmploymentPeriodModel: any) =>
        total +
        Number(
          currentEmploymentPeriodModel?.hashMap[fieldName]
            ?.toString()
            ?.replace(/,/g, '') ?? '0'
        ),
      0
    );

  const summaryTableData = () => {
    let initialData = [
      [
        { contentCell: '', className: 'coloredCell' },
        {
          contentCell: (
            <span className={styles.columnHeading}>
              {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_1')}
            </span>
          ),
          className: 'coloredCell'
        }
      ],
      [
        {
          contentCell: (
            <span className={styles.rowHeader}>
              {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_1')}{' '}
            </span>
          )
        },
        {
          contentCell:
            form119Data.e119Model.financialModel.employmentPeriodModels.length >
              1 && formStep === 1
              ? totalCreditedService.toFixed(2)
              : getSumForSummaryTableField('credited_service').toFixed(2)
        }
      ],
      [
        {
          contentCell: (
            <span className={styles.rowHeader}>
              {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_2')}
            </span>
          )
        },
        {
          contentCell: formatAsCurrencyOrGetEmptyString(
            totalContributoryEarnings ||
              (form119Data.e119Model.financialModel.employmentPeriodModels
                .length === 1
                ? form119Data.e119Model.financialModel.employmentPeriodModels[0]
                    .hashMap.earnings
                : getSumForSummaryTableField('earnings')),
            'en'
          )
        }
      ],
      [
        {
          contentCell: (
            <span className={styles.rowHeader}>
              {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_3')}
            </span>
          )
        },
        {
          contentCell: formatAsCurrencyOrGetEmptyString(
            totalPensionAdjustment ||
              (form119Data.e119Model.financialModel.employmentPeriodModels
                .length === 1
                ? form119Data.e119Model.financialModel.employmentPeriodModels[0]
                    .hashMap.pension_adjustment_amt
                : getSumForSummaryTableField('pension_adjustment_amt')),
            'en'
          )
        }
      ],
      [
        {
          contentCell: (
            <span className={styles.rowHeader}>
              {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_4')}
            </span>
          )
        },
        {
          contentCell: formatAsCurrencyOrGetEmptyString(
            totalRpp ||
              (form119Data.e119Model.financialModel.employmentPeriodModels
                .length === 1
                ? form119Data.e119Model.financialModel.employmentPeriodModels[0]
                    .hashMap.rpp_contribution_amt
                : getSumForSummaryTableField('rpp_contribution_amt')),
            'en'
          )
        }
      ],
      [
        {
          contentCell: (
            <span className={styles.rowHeader}>
              {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_5')}
            </span>
          )
        },
        {
          contentCell: formatAsCurrencyOrGetEmptyString(
            totalRca ||
              (form119Data.e119Model.financialModel.employmentPeriodModels
                .length === 1
                ? form119Data.e119Model.financialModel.employmentPeriodModels[0]
                    .hashMap.rca_contribution_amt
                : getSumForSummaryTableField('rca_contribution_amt')),
            'en'
          )
        }
      ],
      [
        {
          contentCell: (
            <span className={styles.rowHeader}>
              {t('ANNUAL_REPORTING_SUMMARY_ROW_HEADING_6')}
            </span>
          )
        },
        {
          contentCell: formatAsCurrencyOrGetEmptyString(totalRetro, 'en')
        }
      ]
    ];

    if (currentLeavePeriodModel) {
      // first row
      initialData[0].push({
        contentCell: (
          <span className={styles.columnHeading}>
            {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_2')}
          </span>
        ),
        className: 'coloredCell'
      });
      initialData = createPeriodColumn(
        initialData,
        currentLeavePeriodModel,
        retroPay[1]
      );
    }
    if (form119Model) {
      // first row
      initialData[0].push({
        contentCell: (
          <span className={styles.columnHeading}>
            {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_3')}
          </span>
        ),
        className: 'coloredCell'
      });
      initialData = createPeriodColumn(initialData, form119Model, retroPay[2]);
    }
    if (prevLeavePeriodModel) {
      // first row
      initialData[0].push({
        contentCell: (
          <span className={styles.columnHeading}>
            {t('ANNUAL_REPORTING_SUMMARY_COLUMN_HEADING_4')}
          </span>
        ),
        className: 'coloredCell'
      });
      initialData = createPeriodColumn(
        initialData,
        prevLeavePeriodModel,
        retroPay[3]
      );
    }
    return initialData;
  };

  // totalCreditedService > 12 error for split periods
  useEffect(() => {
    if (formStep === 1 && isDirty && employmentPeriodModels.length > 1) {
      if (totalCreditedService > 12) {
        employmentPeriodModels.forEach((_, index) => {
          methods.setError(
            `financialModel.employmentPeriodModels[${index}].hashMap.credited_service`,
            {
              message: ' ',
              type: 'validate'
            }
          );
        });
      } else if (hasErrors) {
        employmentPeriodModels.forEach((_, index) => {
          if (
            methods.errors.financialModel?.employmentPeriodModels &&
            methods.errors?.financialModel?.employmentPeriodModels[index]
              ?.hashMap?.credited_service
          ) {
            if (
              methods.errors.financialModel.employmentPeriodModels[index]!
                .hashMap!.credited_service!.type === 'validate'
            ) {
              // doesn't work, leaves credited_service as undefined in errors
              methods.clearErrors(
                `financialModel.employmentPeriodModels[${index}].hashMap.credited_service`
              );
              // forced to do this
              methods.clearErrors();
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalCreditedService,
    employmentPeriodModels,
    isDirty,
    formStep,
    hasErrors
  ]);

  // totalContributoryEarnings < totalRetro error for split periods
  useEffect(() => {
    if (formStep === 1 && isDirty && employmentPeriodModels.length >= 1) {
      if (totalContributoryEarnings < totalRetro) {
        employmentPeriodModels.forEach((_, index) => {
          // for single period case, we set message
          if (employmentPeriodModels.length === 1) {
            methods.setError(
              `financialModel.employmentPeriodModels[${index}].hashMap.earnings`,
              {
                message: t('ANNUAL_REPORTING_CONTRIBUTORY_EARNINGS_ERROR'),
                type: 'validate'
              }
            );
          }
          // set empty message for split period case, as message is shown outside table
          else {
            methods.setError(
              `financialModel.employmentPeriodModels[${index}].hashMap.earnings`,
              {
                message: ' ',
                type: 'validate'
              }
            );
          }
        });
      } else if (hasErrors) {
        employmentPeriodModels.forEach((_, index) => {
          if (
            methods.errors.financialModel?.employmentPeriodModels &&
            methods.errors?.financialModel?.employmentPeriodModels[index]
              ?.hashMap?.earnings
          ) {
            if (
              methods.errors.financialModel.employmentPeriodModels[index]!
                .hashMap!.earnings!.type === 'validate'
            ) {
              // doesn't work, leaves earnings as undefined in errors
              methods.clearErrors(
                `financialModel.employmentPeriodModels[${index}].hashMap.earnings`
              );
              // forced to do this
              methods.clearErrors();
            }
          }
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    totalContributoryEarnings,
    totalRetro,
    employmentPeriodModels,
    isDirty,
    formStep,
    hasErrors
  ]);

  useFormExit(() => {
    if (requestNumber && !initLoading && !isDirty) {
      dispatch(unlockForm119(requestNumber));
    }
  }, [requestNumber, initLoading, isDirty]);

  return (
    <>
      <FormHeader
        extraHeaderInfo={
          memberInfo?.memberEmployeeId
            ? `${t('ANNUAL_REPORTING_EMPLOYEE_ID_FIELD')}: ${
                memberInfo?.memberEmployeeId
              }`
            : undefined
        }
        formTitle={`${t(
          'ANNUAL_REPORTING_FORM_HEADER_TITLE'
        )}${selectedReconciliationYear?.yearText ?? ''}`}
        formDescription={
          memberInfo?.membershipNumber ? (
            <Link
              href="/members/profile/[profile]"
              as={`/members/profile/${memberInfo.membershipNumber}?employer=${memberInfo.mssemploymentItem?.theMSSEmployer?.employerNumber}`}
              target="_blank"
            >
              {`${memberInfo.memberFirstName} ${memberInfo.memberLastName}`}
            </Link>
          ) : null
        }
        altReviewText={t('ANNUAL_REPORTING_REVIEW_BTN_TEXT')}
        progressBarProps={progressBarProps}
        onDeleteFormCallback={() => {
          toggleDeleteModal(deleteForm119Action);
        }}
        onBackCallback={() => {
          dispatch(clearForm119ExceptionOverrides());
          dispatch(clearNotifications());
          dispatch(setForm119StepAction(1));
        }}
        useBackCallback={formStep === 2}
        onReviewCallback={async () => {
          await methods.trigger();
          if (Object.keys(methods.errors).length === 0) {
            // Submit form
            setShowValidationError(false);
            saveCallback();
            dispatch(setForm119StepAction(2));
          } else {
            // Show error
            setShowValidationError(true);
          }
        }}
        onSubmitCallback={() => {
          dispatch(triggerOverride(true));
          !Array.from(document.querySelectorAll('textarea[name="notepad"]'))
            .map(item => (item as HTMLInputElement).value.length)
            .includes(0) && toggleSubmitModal();
        }}
        onSaveCallback={saveCallback}
        saveDisabled={
          formStep === 3 ||
          (formStep === 1 && !isDirty) ||
          Object.keys(methods.errors).length > 0 ||
          formStatus === 'deleted'
        }
        submitStatus={submissionStatus}
        resetDisabled={formStep !== 1 || !isDirty}
        onResetFormCallback={toggleResetModal}
        reviewDisabled={
          Object.keys(methods.errors).length > 0 || formStatus === 'saving'
        }
        showSubmit={formStep === 2}
        saveStatus={formStatus}
        hideEverything={submissionComplete}
        sendCorrespondenceTopic="119"
        exceptions={exceptions ?? []}
      />
      <div
        className={cx({
          annualReporting: true,
          annualReportingWithNewNav: isEnabled(
            featureFlags,
            currentFlags.NEW_NAV_HEADER
          )
        })}
      >
        {!initLoading && (
          <FormProvider {...methods}>
            <ScrollTo offset={-300} scrollOnChange={formStep} />
            {showRetroPayModal && (
              <RetroPayModal
                onConfirm={() => {
                  saveCallback();
                  setShowRetroPayModal(false);
                }}
                onCancel={() => {
                  setShowRetroPayModal(false);
                }}
                startDate={retroStartDate}
                endDate={retroEndDate}
                earnings={
                  financialModel?.employmentPeriodModels[currentRetroIndex]
                    ?.hashMap?.earnings ?? ''
                }
                retroServicePeriodNo={retroServicePeriodNo}
                employmentPeriodModel={selectedEmploymentPeriodModel}
              />
            )}

            <Modal
              visible={showResetRequiredModal && !resetModalShown}
              onOk={() => {
                toggleResetRequiredModal();
                setResetModalShown(true);
                dispatch(
                  refreshForm119Action({
                    create119Data: {
                      membershipNo: memberInfo?.membershipNumber,
                      employerNo:
                        memberInfo?.mssemploymentItem?.theMSSEmployer
                          ?.employerNumber,
                      employeeModel: form119Data.e119Model,
                      selectedReconciliationYear
                    },
                    deleteAction: {
                      type: form119ActionTypes.DELETE_FORM_119_REQUESTED,
                      data: requestNumber
                    }
                  })
                );
              }}
              onCancel={() => {
                setResetModalShown(true);
                toggleResetRequiredModal();
              }}
              okText={t('ANNUAL_REPORTING_RESET_REQUIRED_MODAL_OK')}
              titleText={t('ANNUAL_REPORTING_RESET_REQUIRED_MODAL_TITLE')}
              isCancel={false}
              width={660}
            >
              <p>{t('ANNUAL_REPORTING_RESET_REQUIRED_MODAL_BODY')}</p>
              <Notification
                type="info"
                messages={[
                  t('ANNUAL_REPORTING_RESET_REQUIRED_MODAL_NOTIFICATON')
                ]}
              />
            </Modal>

            <ConfirmationModal
              visible={showDeleteModal}
              onConfirm={deleteForm119}
              onCancel={toggleDeleteModal}
              titleText={t('common:DIALOG_DELETE_FORM_HEADER')}
              bodyText={t('common:DIALOG_DELETE_FORM_BODY')}
              okText={t('common:DIALOG_DELETE_FORM_BTN')}
            />

            <ConfirmationModal
              visible={showFormRefreshModal}
              onConfirm={refreshForm}
              onCancel={toggleFormRefreshModal}
              okText={t('common:RESET_FORM_MODAL_RESET')}
              bodyText={t('common:RESET_FORM_MODAL_BODY')}
              titleText={t('common:RESET_FORM_MODAL_TITLE')}
              width={630}
            />

            <CreateFailedModal
              visible={isCreateDeleteModalShowing}
              onConfirm={toggleCreateDeleteModal}
              onClose={toggleCreateDeleteModal}
              formNumber={formToCreate}
            />

            <ConfirmationModal
              visible={isResetModalShowing}
              onConfirm={() => {
                methods.reset({
                  employeeModel: form119Data?.e119Model?.employeeModel,
                  financialModel: form119Data?.e119Model?.financialModel
                });
                dispatch(clearNotifications());
                toggleResetModal();
              }}
              onCancel={toggleResetModal}
              okText={t('common:DIALOG_RESET_FORM_BTN')}
              bodyText={t('common:DIALOG_RESET_FORM_BODY_TEXT')}
              titleText={t('common:DIALOG_RESET_FORM_TITLE')}
            />

            {formStep === 1 && (
              <div
                className={cx({
                  annualReporting: true,
                  annualReportingWithNewNav: isEnabled(
                    featureFlags,
                    currentFlags.NEW_NAV_HEADER
                  )
                })}
              >
                <SaveProgressModal
                  showModal={
                    formStatus !== 'success' &&
                    formStatus !== 'deleted' &&
                    !submissionComplete
                  }
                  onSave={() => {
                    dispatch(unlockForm119(requestNumber));
                    saveCallback();
                    toggleSaveProgressModal();
                  }}
                  onDiscard={() => {
                    dispatch(unlockForm119(requestNumber));
                    methods.reset({ ...(form119Data as any) });
                    toggleSaveProgressModal();
                  }}
                  onClose={() => {
                    toggleSaveProgressModal();
                  }}
                  isDirty={isDirty}
                />

                {selectedReconciliationYear?.adjustInd === 'Y' && (
                  <Notification
                    type="warning"
                    messages={[
                      t(
                        'forms:ANNUAL_REPORTING_RECONCILIATION_ALREADY_REPORTED',
                        {
                          reconciliationYear:
                            selectedReconciliationYear?.yearText
                        }
                      )
                    ]}
                  />
                )}
                <CommonNotification noMargin={false} marginBottom={false} />
                {showValidationError && (
                  <Notification
                    type="error"
                    messages={[t('ANNUAL_REPORTING_VALIDATION_WARNING')]}
                    scrollTo={hasErrors}
                  />
                )}
                <Notification
                  messages={[t('ANNUAL_REPORTING_PA_NOTIFICATION')]}
                  type="info"
                />

                <form onSubmit={methods.handleSubmit(onSubmit)}>
                  <ContainerBorder>
                    {/* EMPLOYEE INFORMATION */}
                    <div className={styles.employeeInfo}>
                      <h2>{t('ANNUAL_REPORTING_EMPLOYEE_INFO')}</h2>

                      <div className={styles.annualReportingDesc}>
                        <ReactSVG src="/images/info-blue.svg" />
                        <span>{t('ANNUAL_REPORTING_PERSONAL_INFO_DESC')}</span>
                      </div>

                      <Divider />

                      <div className={styles.rowFirst}>
                        <TextInput
                          name="employeeModel.employeeId"
                          labelText={t('ANNUAL_REPORTING_EMPLOYEE_ID_FIELD')}
                          type="text"
                          optional
                          control={methods.control}
                          mask={maskEmployeeId}
                        />

                        <TextInput
                          name="employeeModel.deptId"
                          labelText={t('ANNUAL_REPORTING_DEPARTMENT_ID_FIELD')}
                          type="text"
                          optional
                          control={methods.control}
                          mask={maskAlphanumeric(15)}
                        />

                        {!originalEmail && (
                          <QASEmailAddressInput
                            name="employeeModel.email"
                            label={t('ANNUAL_REPORTING_EMAIL_FIELD')}
                            emailValidationRef={methods.register}
                            errorMessage={methods.errors.employeeModel?.email}
                            verificationState={validEmailAddressState}
                            verificationEmailAddress={validEmailAddress}
                            optional
                            setEmailState={(e: string) =>
                              dispatch(setValidEmailAddressStateForm119(e))
                            }
                            setEmailAddress={(e: string) =>
                              dispatch(setValidEmailAddressForm119(e))
                            }
                            onBlur={() => {
                              if (
                                validEmailAddressState ===
                                  QASEmailResponseTypes.ILLEGITIMATE &&
                                employeeModel.email !== ''
                              ) {
                                dispatch(
                                  setValidEmailAddressStateForm119(
                                    QASEmailResponseTypes.ILLEGITIMATE
                                  )
                                );
                              }
                            }}
                            tooltip={t(
                              'REQUEST_FORM_ANNUAL_REPORTING_EMAIL_MISSING_TOOLTIP'
                            )}
                          />
                          // <TextInput
                          //   name="employeeModel.email"
                          //   labelText={t('ANNUAL_REPORTING_EMAIL_FIELD')}
                          //   type="text"
                          //   ref={methods.register}
                          //   errorMessage={methods.errors.employeeModel?.email}
                          //   onChange={e =>
                          //     methods.setValue(
                          //       'employeeModel.email',
                          //       maskEmail(e.target.value)
                          //     )
                          //   }
                          //   optional
                          //   tooltip={t(
                          //     'REQUEST_FORM_ANNUAL_REPORTING_EMAIL_MISSING_TOOLTIP'
                          //   )}
                          // />
                        )}

                        {originalEmail && (
                          <div className={styles.existingEmail}>
                            <div>{t('ANNUAL_REPORTING_EMAIL_FIELD')}</div>
                            <span>
                              {t(
                                'REQUEST_FORM_ANNUAL_REPORTING_EMAIL_EXISTING'
                              )}
                            </span>
                            <ToolTip
                              content={t(
                                'REQUEST_FORM_ANNUAL_REPORTING_EMAIL_EXISTING_TOOLTIP'
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </div>

                    <Divider />

                    <div className={styles.financialInfo}>
                      <h2>{t('ANNUAL_REPORTING_FINANCIAL_INFO')}</h2>
                      <Notification
                        messages={[
                          <span>
                            <span>
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_TEXT_1'
                              )}
                            </span>
                            <Button
                              baseStyle="link"
                              onClick={() => {
                                // new 106
                                setFormToCreate('106');
                                dispatch(
                                  checkMemberProfileValidity(memberInfo)
                                );
                              }}
                              isDisabled={omersUser}
                            >
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_LINK_1'
                              )}
                            </Button>
                            <span>
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_TEXT_2'
                              )}
                            </span>
                            <Button
                              baseStyle="link"
                              onClick={() => {
                                // new 165a
                                setFormToCreate('165a');
                                dispatch(
                                  checkMemberProfileValidity(memberInfo)
                                );
                              }}
                              isDisabled={omersUser}
                            >
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_LINK_2'
                              )}
                            </Button>
                            <span>.</span>
                          </span>,
                          <span>
                            <span className="semiBold">
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_PLEASE_NOTE_TEXT'
                              )}
                            </span>
                            {t(
                              'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_TEXT_3'
                            )}
                            <Button
                              baseStyle="link"
                              onClick={() => {
                                toggleFormRefreshModal();
                              }}
                              isDisabled={omersUser}
                            >
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_LINK_3'
                              )}
                            </Button>
                            <span>
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_1_TEXT_4'
                              )}
                            </span>
                          </span>,
                          <span>
                            <span>
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_2_TEXT'
                              )}
                            </span>
                            <a
                              href={
                                EXTERNAL_URLS.OMERS_CONTRIBUTIONS_AND_PA_CALCULATORS
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              {t(
                                'common:FINANICAL_INFO_NOTIFICATION_PARAGRAPH_2_LINK'
                              )}
                            </a>
                          </span>
                        ]}
                        type="info"
                        noMargin
                        marginBottom
                      />

                      {employmentPeriodModels?.length === 1 && (
                        <AnnualReportingSinglePeriodTable
                          onClickRetroDelete={() => {
                            saveCallback();
                            dispatch(
                              saveForm119AndPerformNewAction({
                                actionType:
                                  form119ActionTypes.DELETE_RETRO_PAY_REQUESTED,
                                actionData: 1
                              })
                            );
                          }}
                          onClickRetroEdit={() => {
                            saveCallback();
                            if (
                              employmentPeriodModels &&
                              employmentPeriodModels[0]
                            ) {
                              setRetroStartDate(
                                employmentPeriodModels[0].hashMap.start_date
                              );
                              setRetroEndDate(
                                employmentPeriodModels[0].hashMap.end_date
                              );
                            }
                            setRetroServicePeriodNo(1);
                            if (financialModel.retroPay[0] !== null) {
                              beforeEditingExistingRetro(
                                employmentPeriodModels[0],
                                0
                              );
                            }
                            setShowRetroPayModal(true);
                          }}
                        />
                      )}

                      {employmentPeriodModels.length > 1 && (
                        <>
                          <AnnualReportingSplitPeriodTable
                            onClickRetroDelete={field => {
                              saveCallback();
                              dispatch(
                                saveForm119AndPerformNewAction({
                                  actionType:
                                    form119ActionTypes.DELETE_RETRO_PAY_REQUESTED,
                                  actionData: field.hashMap.service_period_no
                                })
                              );
                            }}
                            onClickRetroEdit={(field, index) => {
                              saveCallback();
                              setRetroStartDate(field.hashMap.start_date);
                              setRetroEndDate(field.hashMap.end_date);
                              setRetroServicePeriodNo(
                                field.hashMap.service_period_no
                              );
                              if (field.hashMap.retro_type) {
                                beforeEditingExistingRetro(field, index);
                              }
                              dispatch(setCurrentRetroIndex(index));
                              setShowRetroPayModal(true);
                            }}
                            totalCreditedService={totalCreditedService}
                            totalContributoryEarnings={
                              totalContributoryEarnings
                            }
                            totalRetro={totalRetro}
                            totalPensionAdjustment={totalPensionAdjustment}
                            totalRpp={totalRpp}
                            totalRca={totalRca}
                          />

                          {totalCreditedService > 12 && (
                            <p className={styles.errorPara}>
                              {t('ANNUAL_REPORTING_CREDITED_SERVICE_ERROR')}
                            </p>
                          )}
                          {totalContributoryEarnings < totalRetro && (
                            <p className={styles.errorPara}>
                              {t(
                                'ANNUAL_REPORTING_TOTAL_CONTRIBUTORY_EARNINGS_ERROR'
                              )}
                            </p>
                          )}
                          {showSummaryTable && (
                            <>
                              <br />
                              <h3>{t('ANNUAL_REPORTING_SUMMARY')}</h3>
                              <Table
                                withColoredHeaderRow
                                headerColor="blue"
                                withTableBorder
                                boldFirstColumn
                                withRowBorders
                                withColumnBorders
                                tableHeading={summaryTableHeading}
                                tableData={summaryTableData()}
                                withEvenWidthColumns
                              />
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </ContainerBorder>
                </form>
              </div>
            )}

            {formStep === 2 && (
              <>
                <ConfirmationModal
                  visible={isSubmitModalShowing}
                  onConfirm={onSubmitCallback}
                  onCancel={toggleSubmitModal}
                  titleText={t(
                    'forms:REQUEST_FORM_CONFIRM_SUBMIT_MODAL_HEADER'
                  )}
                  okText={t('forms:REQUEST_FORM_CONFIRM_SUBMIT_MODAL_BTN')}
                  bodyText={t('forms:REQUEST_FORM_CONFIRM_SUBMIT_MODAL_BODY', {
                    formNumber: '119',
                    formTitle: t('forms:FORM_NAME_119'),
                    memberName: `${
                      memberInfo?.memberFirstName
                        ? memberInfo?.memberFirstName
                        : null
                    } ${
                      memberInfo?.memberLastName
                        ? memberInfo?.memberLastName
                        : null
                    }`
                  })}
                />

                <AnnualReportingReview
                  summaryTableData={summaryTableData()}
                  summaryTableHeading={summaryTableHeading}
                />
              </>
            )}

            {formStep === 3 && (
              <>
                <SubmitLandingPage
                  form="119"
                  requestNumber={requestNumber}
                  reconciliationYear={
                    selectedReconciliationYear?.yearText ?? ''
                  }
                  hasPdf={false}
                  memberInfo={memberInfo}
                />
              </>
            )}
          </FormProvider>
        )}
      </div>
    </>
  );
};

export default AnnualReporting;
