import { useEffect, useRef } from 'react';
import {
  Classes,
  Drawer as DrawerBP,
  DrawerProps as DrawerBPProps
} from '@blueprintjs/core';
import { useTranslation } from 'react-i18next';
import { ThemeUICSSObject } from 'theme-ui';
import Button from '../button/Button';
import Icon, { IconNames } from '../icon/Icon';

export interface DrawerProps extends Omit<DrawerBPProps, 'icon' | 'title'> {
  header?: string | React.ReactNode;
  footer?: React.ReactNode;
  footerAlign?: 'center' | 'left' | 'right' | 'fill';
  isActionButtonsEnabled?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  loading?: boolean;
  isPrimaryDisabled?: boolean;
  isSecondaryDisabled?: boolean;
  useLoadingOverlaySpinner?: boolean;
  isFooterSticky?: boolean;
  secondaryButtonMinWidth?: string;
  primaryButtonMinWidth?: string;
  maxWidth?: string;
  minWidth?: string;
  enableScrollToTopOnChange?: boolean;
  buttonsSideBySide?: boolean;
  scrollToTopOnChange?: unknown;
  primaryButtonHandler?: () => void;
  stickyContent?: React.ReactNode;
  footerSxOverrides?: ThemeUICSSObject;
  childrenSxOverride?: ThemeUICSSObject;
}

/**
 * See: https://blueprintjs.com/docs/#core/components/drawer.props
 */
const Drawer: React.FC<DrawerProps> = ({
  isOpen = false,
  hasBackdrop = true,
  header,
  footer,
  children,
  onClose,
  primaryButtonHandler,
  footerAlign = 'left',
  isActionButtonsEnabled = true,
  primaryButtonText,
  secondaryButtonText,
  canOutsideClickClose = false,
  isCloseButtonShown = true,
  size = '640px',
  loading = false,
  isPrimaryDisabled = false,
  isSecondaryDisabled = false,
  useLoadingOverlaySpinner = false,
  isFooterSticky = true,
  secondaryButtonMinWidth,
  primaryButtonMinWidth,
  maxWidth,
  minWidth,
  enableScrollToTopOnChange = false,
  scrollToTopOnChange,
  stickyContent,
  buttonsSideBySide = false,
  footerSxOverrides = {},
  childrenSxOverride = {},
  ...props
}) => {
  const { t } = useTranslation('common');
  const ref = useRef<HTMLDivElement>(null);

  // if enableScrollToTopOnChange is enabled, watch for value change to trigger
  // a scroll to top of Drawerbody
  useEffect(() => {
    if (enableScrollToTopOnChange && ref.current) {
      ref.current.scroll({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [enableScrollToTopOnChange, scrollToTopOnChange]);

  return (
    <DrawerBP
      {...props}
      isOpen={isOpen}
      onClose={onClose}
      hasBackdrop={hasBackdrop}
      size={size}
      canEscapeKeyClose={loading ? false : props.canEscapeKeyClose}
      canOutsideClickClose={loading ? false : canOutsideClickClose}
      sx={{
        overflow: !isFooterSticky ? 'auto' : undefined,
        maxWidth: maxWidth || '100%',
        minWidth
      }}
    >
      {isCloseButtonShown && (
        <div sx={{ m: '1rem' }}>
          {isCloseButtonShown && (
            <Button
              sx={{
                float: 'right'
              }}
              isIconButton
              variant="simple"
              id="drawer-close-btn"
              onClick={onClose}
              disabled={loading}
              data-testid="drawer-close-btn"
            >
              <Icon
                icon={IconNames.CLOSE}
                sx={{
                  lineHeight: '0px',
                  svg: {
                    color: 'brandNavy100',
                    '&:hover': {
                      color: 'extendedBlue100'
                    }
                  }
                }}
              />
            </Button>
          )}
        </div>
      )}

      {header &&
        (typeof header === 'string' ? (
          <h2
            sx={{
              p: ['0 2rem', '0 1.5rem', null]
            }}
          >
            {header}
          </h2>
        ) : (
          header
        ))}
      <div
        ref={ref}
        className={Classes.DRAWER_BODY}
        sx={{
          '> div, > span, > p': {
            fontSize: 'base',
            lineHeight: 'base'
          },
          overflow: !isFooterSticky ? 'unset' : undefined,
          lineHeight: 'inherit'
        }}
      >
        {stickyContent && (
          <div
            sx={{
              position: 'sticky',
              zIndex: 1,
              top: 0,
              background: 'white',
              p: ['0 2rem', '0 1.5rem', null],
              pb: 4
            }}
          >
            {stickyContent}
          </div>
        )}
        <div
          sx={{
            overflow: 'hidden',
            zIndex: 0,
            p: ['0 2rem 2rem 2rem', '0 1.5rem 1.5rem 1.5rem', null],
            ...childrenSxOverride
          }}
        >
          {children}
        </div>
      </div>
      {(footer || isActionButtonsEnabled) && (
        <div
          sx={{
            ...footerSxOverrides,
            '> div, > span, > p': {
              fontSize: 'body',
              lineHeight: 'body',
              p: ['0.875rem 0.75rem', '1rem', null]
            },
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {footer && (
            <div
              sx={{
                mx: footerAlign === 'center' ? 'auto' : undefined,
                mr: footerAlign === 'left' ? 'auto' : undefined,
                ml: footerAlign === 'right' ? 'auto' : undefined,
                width: footerAlign === 'fill' ? '100%' : undefined
              }}
            >
              {footer}
            </div>
          )}

          {isActionButtonsEnabled && (
            <div
              sx={{
                ml: 'auto',
                display: 'flex',
                justifyContent: ['flex-end', 'center', null],
                gap: 3,
                width: [null, '100%', null],
                flexDirection: [
                  null,
                  buttonsSideBySide ? 'row' : 'column',
                  null
                ]
              }}
            >
              <Button
                id="drawer-primary-btn"
                onClick={primaryButtonHandler}
                disabled={loading || isPrimaryDisabled}
                loading={!useLoadingOverlaySpinner ? loading : false}
                minWidth={primaryButtonMinWidth}
                data-testid="drawer-primary-btn"
                sx={{
                  width: [null, '100%', null]
                }}
              >
                {primaryButtonText || t('DRAWER_OK')}
              </Button>
              {secondaryButtonText && (
                <Button
                  color="orange"
                  variant="outline"
                  id="drawer-secondary-btn"
                  onClick={onClose}
                  disabled={loading || isSecondaryDisabled}
                  minWidth={secondaryButtonMinWidth}
                  data-testid="drawer-secondary-btn"
                  sx={{
                    width: [null, '100%', null]
                  }}
                >
                  {secondaryButtonText || t('DRAWER_CANCEL')}
                </Button>
              )}
            </div>
          )}
        </div>
      )}
    </DrawerBP>
  );
};

export default Drawer;
