import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { DataObj } from 'types';
import { postData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getBatchPrintSearchResults = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/print/search`, data);

export const printSelectedRequest = (data: DataObj) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/print`,
    data,
    undefined,
    undefined,
    'blob'
  );
