/**
 * @deprecated - Use /utils/hooks/useBreakpoint.ts
 */

import { useState, useEffect } from 'react';
import { throttle } from 'lodash';

const BREAKPOINTS = {
  xsmall: 376,
  smartphone: 425,
  small: 576,
  medium: 768,
  large: 993,
  xlarge: 1281
} as { [key: string]: number };
type Breakpoints = typeof BREAKPOINTS;

const getDeviceConfig = (width: number) => {
  if (width < BREAKPOINTS.xsmall) {
    return 'xsmall';
  }
  if (width >= BREAKPOINTS.xsmall && width < BREAKPOINTS.smartphone) {
    return 'smartphone';
  }
  if (width >= BREAKPOINTS.smartphone && width < BREAKPOINTS.small) {
    return 'small';
  }
  if (width >= BREAKPOINTS.small && width < BREAKPOINTS.medium) {
    return 'medium';
  }
  if (width >= BREAKPOINTS.medium && width < BREAKPOINTS.large) {
    return 'large';
  }
  return 'xlarge';
};

const useBreakpoint = (): [string, Breakpoints] => {
  const [breakpoint, setBreakpoint] = useState(() =>
    getDeviceConfig(window.innerWidth)
  );

  useEffect(() => {
    const innerWidth = throttle(() => {
      setBreakpoint(getDeviceConfig(window.innerWidth));
    }, 200);

    window.addEventListener('resize', innerWidth);
    return () => window.removeEventListener('resize', innerWidth);
  }, []);

  return [breakpoint, BREAKPOINTS];
};

export default useBreakpoint;
