import { useRef } from 'react';

/**
 * Hook to track previous state of given value
 */
const usePrevious = <T>(
  value: T | undefined,
  initial?: T | undefined
): T | undefined => {
  const ref = useRef({ target: value, previous: initial });

  if (ref.current.target !== value) {
    ref.current.previous = ref.current.target;
    ref.current.target = value;
  }

  return ref.current.previous;
};

export default usePrevious;
