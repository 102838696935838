import { all, takeLatest, put, select, call, take } from 'redux-saga/effects';
import { cloneDeep } from 'lodash';
import Router from 'next/router';
import i18n from 'i18next';

import { RootState, EapAction } from 'types';
import { downloadFile, getApiErrorMessage } from 'utils';
import {
  getFileBuilder,
  sendImport,
  getBatchProcessingImports,
  getReconciliationYearsForGroup,
  getImportErrorsToReview,
  completeReviewImportErrors,
  deleteImports,
  submitBatch,
  getSubmission,
  getForm119ToBatchSubmit,
  getForm165aToBatchSubmit,
  getForm102ToBatchSubmit,
  searchBatch,
  checkPreProcessingBatchSubmissionStatus
} from 'services/batchProcessing';
import { FileExtensions } from 'types/file-extensions';
import { responseTypes } from 'types/response-type';
import {
  clearNotifications,
  systemErrorNotificationAction,
  notifyErrorAction
} from 'containers/notifications/notificationsActions';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import {
  setAnrMainTab,
  setAnrTotalsTab
} from 'containers/annualReconciliation/annualReconciliationActions';
import {
  annualDashboardActionTypes,
  getAnrDashboardInfoAction
} from 'containers/annualDashboard/annualDashboardActions';
import { pollingActionTypes, stopPoll } from 'store/polling/pollingActions';
import { pollInterval } from 'store/polling/types';
import { indicators } from 'utils/constants';
import {
  batchProcessingActionTypes,
  downloadFileBuilderSucceededAction,
  downloadFileBuilderFailedAction,
  getImportsSucceededAction,
  getImportsFailedAction,
  getReconcilationYearsForGroupSucceededAction,
  getReconcilationYearsForGroupFailedAction,
  setReconcilationYearsAction,
  setIsConfirmImportModalAction,
  batchProcessUploadRequestedSucceededAction,
  batchProcessUploadRequestedFailedAction,
  setIsSuccessfulImportModalAction,
  setHasReconcilationYearsAction,
  getReviewImportErrorsSucceededAction,
  getReviewImportErrorsFailedAction,
  completeReviewImportErrorsFailedAction,
  completeReviewImportErrorsSucceededAction,
  cancelImportsSucceededAction,
  cancelImportsFailedAction,
  showCancelImportsModalAction,
  isCancelImportsSucceededAction,
  batchSubmitSucceededAction,
  batchSubmitFailedAction,
  reviewSubmissionSucceededAction,
  getDataToBatchSubmitSucceededAction,
  getDataToBatchSubmitFailedAction,
  reviewSubmissionFailedAction,
  searchBatchIdsSucceededAction,
  searchBatchIdsFailedAction,
  setBatchIdSearchResultsAction,
  setSelectedBatchIdAction,
  updatePreProcessingBatchSubmissionStatusAction
} from './batchProcessingActions';
import {
  transformImportsData,
  transformErrorsToReview,
  getCurrentBatchData,
  verifyReviewStatus,
  transformSubmissionData,
  adjustSearchCriteriaPayload,
  createDefaultSearchObj
} from './utils';
import {
  fileNames,
  batchProcessingTabIds,
  FilingType,
  formTypes,
  formId,
  reviewErrorStatus,
  IDataToBatchSubmit
} from '../types';

enum apiResponse {
  REVIEW_COMPLETED = 'review complete'
}

const createPayload = (
  groupNo: string,
  importType: string,
  reconciliationYear?: string
) => {
  if (importType !== FilingType.FORM_165) {
    return {
      groupNo,
      importType,
      reconciliationYear
    };
  }
  return {
    groupNo,
    importType
  };
};

export function* downloadFileBuilderSaga(): any {
  try {
    const res = yield call(getFileBuilder);
    if (res?.type === responseTypes.OCTET_STREAM) {
      yield put(downloadFileBuilderSucceededAction());
      yield call(
        downloadFile,
        res,
        fileNames.IMPORT_FILE_BUILDER,
        FileExtensions.EXCEL
      );
      yield put(clearNotifications());
    } else {
      yield put(downloadFileBuilderFailedAction());
      yield put(systemErrorNotificationAction());
    }
  } catch (e) {
    console.error(e);
    yield put(downloadFileBuilderFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

export function* getReconcialtionYearsSaga(): any {
  const employerNo = yield select(
    state => state.selectEmployers?.selectEmployerNumber
  );
  try {
    const res = yield call(getReconciliationYearsForGroup, employerNo);

    const {
      data: { reconciliationYears }
    } = res;
    if (reconciliationYears) {
      yield put(
        getReconcilationYearsForGroupSucceededAction(reconciliationYears)
      );
      yield put(setReconcilationYearsAction(reconciliationYears[0].yearCode));
      yield put(setHasReconcilationYearsAction(true));
    } else {
      yield put(getReconcilationYearsForGroupSucceededAction([]));
    }
  } catch (e) {
    console.error(e);
    yield put(getReconcilationYearsForGroupFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

export function* batchUploadSaga(): any {
  const file = yield select(state => state.batchProcessing.filePendingUpload);
  const groupNo = yield select(
    state => state.selectEmployers?.selectGroupNumber
  );
  const importType = yield select(
    state => state.batchProcessing.selectedFileType
  );
  const reconciliationYear = yield select(
    state => state.batchProcessing.selectedReconciliationYear
  );
  const payload = createPayload(groupNo, importType, reconciliationYear);

  const res = yield call(safeAuthEffect, sendImport, payload, [
    cloneDeep(file)
  ]);

  if (res?.data) {
    yield put(batchProcessUploadRequestedSucceededAction());
    yield put(setIsSuccessfulImportModalAction(true));
  } else if (res?.status === 400) {
    // display in-progress error in modal
    yield put(
      batchProcessUploadRequestedFailedAction(getApiErrorMessage(res?.errors))
    );
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(setIsConfirmImportModalAction(false));
}

export function* getImportErrorsToReviewSaga(action: EapAction): any {
  const { reviewImports } = yield select(
    (state: RootState) => state.batchProcessing
  );
  const { reviewedImports } = yield select(
    (state: RootState) => state.batchProcessing.reviewImportErrors
  );

  const batchProcessNo = action.data.batchProcessNo;
  const currentBatchData = getCurrentBatchData(reviewImports, batchProcessNo);
  const hasBeenReviewed = verifyReviewStatus(reviewedImports, batchProcessNo);

  yield put(stopPoll());
  try {
    const res = yield call(getImportErrorsToReview, batchProcessNo);

    const getReviewStatus = () => {
      if (currentBatchData?.reviewedInd === indicators.TRUE)
        return reviewErrorStatus.COMPLETED;
      if (hasBeenReviewed && currentBatchData?.reviewedInd === null) {
        return reviewErrorStatus.PENDING;
      }
      return reviewErrorStatus.TO_REVIEW;
    };

    if (res.data.batchErrorModel.statusModelList) {
      const transformedErrorsToReview = transformErrorsToReview([
        ...res.data.batchErrorModel.statusModelList
      ]);

      yield put(
        getReviewImportErrorsSucceededAction({
          errors: transformedErrorsToReview,
          reviewStatus: getReviewStatus(),
          showErrorsMaxNumberWarning:
            currentBatchData?.isErrorsNumberExceeding2000,
          hasAlreadyBeenImported: false,
          importErrors: res.data.batchErrorModel.importErrorModelList
        })
      );
      yield put(clearNotifications());
    } else if (res.data.batchErrorModel.importErrorModelList) {
      const errorCode = new RegExp(/12302/i);

      const checkFor = (error: RegExp) => (index: any): boolean =>
        error.test(index.importError);

      yield put(
        getReviewImportErrorsSucceededAction({
          errors: [],
          reviewStatus: getReviewStatus(),
          showErrorsMaxNumberWarning:
            currentBatchData?.isErrorsNumberExceeding2000,
          hasAlreadyBeenImported: !!res.data.batchErrorModel.importErrorModelList.some(
            checkFor(errorCode)
          ),
          importErrors: res.data.batchErrorModel.importErrorModelList
        })
      );
      yield put(clearNotifications());
    } else {
      yield put(getReviewImportErrorsFailedAction());
      yield put(systemErrorNotificationAction());
    }
  } catch (e) {
    console.error(e);
    yield put(getReviewImportErrorsFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

export function* handleTabChangeSaga() {
  if (
    batchProcessingTabIds.IMPORT_FILES ||
    batchProcessingTabIds.SUBMIT_BATCH
  ) {
    yield put(stopPoll());
  }
  yield put(clearNotifications());
}

export function* completeReviewImportErrorsSaga(): any {
  const { currentBatchProcessNo } = yield select(
    (state: RootState) => state.batchProcessing
  );

  const { reviewedImports } = yield select(
    (state: RootState) => state.batchProcessing.reviewImportErrors
  );

  const hasBeenReviewed = verifyReviewStatus(
    reviewedImports,
    currentBatchProcessNo
  );

  try {
    const res = yield call(completeReviewImportErrors, currentBatchProcessNo);

    if (res.data.status === apiResponse.REVIEW_COMPLETED) {
      const updatedReviewedImports = [...reviewedImports];
      if (!hasBeenReviewed) {
        updatedReviewedImports.push({
          batchProcessNo: currentBatchProcessNo,
          isReviewed: true
        });
      }
      yield put(
        completeReviewImportErrorsSucceededAction(updatedReviewedImports)
      );
      yield put(clearNotifications());
    }
  } catch (e) {
    console.error(e);
    yield put(completeReviewImportErrorsFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

export function* cancelImportsSaga(): any {
  const { currentBatchProcessNo } = yield select(
    (state: RootState) => state.batchProcessing
  );
  yield put(stopPoll());
  const res = yield call(deleteImports, currentBatchProcessNo);
  if (res.status === 204) {
    yield put(cancelImportsSucceededAction());
    yield put(clearNotifications());
  } else {
    yield put(cancelImportsFailedAction());
    yield put(
      showCancelImportsModalAction({
        currentBatchProcessNo: '',
        currentForm: null,
        showCancelImportsModal: false
      })
    );
    yield put(systemErrorNotificationAction());
  }
}

export function* handleCancelImportsSucceeded() {
  const { showNotification } = yield select(
    (state: RootState) => state.notifications
  );
  yield call(getImportsDataSaga);
  if (!showNotification) {
    yield put(isCancelImportsSucceededAction());
  } else {
    yield put(
      showCancelImportsModalAction({
        currentBatchProcessNo: '',
        currentForm: null,
        showCancelImportsModal: false
      })
    );
  }
}

export function* batchSubmitSaga(action: EapAction): any {
  const getFilingType = (filingType: string) => {
    switch (filingType) {
      case formTypes.FORM_119:
        return formId.FORM_119;
      case formTypes.FORM_165_A:
        return formId.FORM_165;
      case formTypes.FORM_102:
        return formId.FORM_102;
      default:
        return null;
    }
  };

  const employerGroupNo = yield select(
    state => state.selectEmployers?.selectGroupNumber
  );

  const { type } = action.data;
  const { requestIds } = action.data;
  const submittedFilingType = getFilingType(type);

  try {
    yield call(
      safeAuthEffect,
      submitBatch,
      employerGroupNo,
      submittedFilingType,
      requestIds
    );
    yield put(batchSubmitSucceededAction());
  } catch (e) {
    console.error(e);
    yield put(batchSubmitFailedAction());
  }
}

export function* getSubmissionToReviewSaga(): any {
  yield put({
    type: pollingActionTypes.POLL_START,
    data: {
      *tryCallBackFunction() {
        const employerGroupNo: string = yield select(
          state => state.selectEmployers.selectGroupNumber
        );
        const res: any = yield call(getSubmission, employerGroupNo);
        const preprocessingBatchSubmission = yield call(
          checkPreProcessingBatchSubmissionStatus,
          employerGroupNo
        );
        if (preprocessingBatchSubmission.data.asyncActivityInprogressInd) {
          yield put(updatePreProcessingBatchSubmissionStatusAction(true));
        } else {
          yield put(updatePreProcessingBatchSubmissionStatusAction(false));
        }
        if (res.data.MSSANRStatusModel?.length > 0) {
          const transformedSubmissionData = transformSubmissionData(
            res.data.MSSANRStatusModel
          );
          yield put(reviewSubmissionSucceededAction(transformedSubmissionData));
          yield put(clearNotifications());
        } else if (res.data) {
          yield put(reviewSubmissionSucceededAction([]));
          yield put(clearNotifications());
        }
      },
      *catchCallBackFunction() {
        yield put(reviewSubmissionFailedAction());
        yield put(systemErrorNotificationAction());
      },
      pollInterval: pollInterval.FIFTEEN_SEC
    }
  });
}

export function* goToExceptionsTabSaga() {
  yield put(getAnrDashboardInfoAction());

  // wait for get dashboard info saga success before navigation
  yield take(annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_SUCCESS);

  const { notReconciled119 } = yield select(
    (state: RootState) => state.annualDashboard
  );

  if (notReconciled119.length > 0) {
    yield call(
      Router.push,
      `/annual-dashboard/${notReconciled119[0].reconciliationYear}`
    );
    yield put(setAnrMainTab('totals'));
    yield put(setAnrTotalsTab('unresolved-exceptions'));
  } else {
    yield put(
      notifyErrorAction(i18n.t('batch-processing:ANR_ALREADY_RECONCILED_ERROR'))
    );
  }
}

export function* searchBatchIdsSaga(payload: EapAction): any {
  const { data: usersSearchCriteria } = payload;
  const { selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { selectedFilteredFilingType } = yield select(
    (state: RootState) => state.batchProcessing
  );

  const defaultSearch = createDefaultSearchObj(
    selectEmployerNumber,
    selectedFilteredFilingType
  );

  try {
    const res = yield call(
      safeAuthEffect,
      searchBatch,
      adjustSearchCriteriaPayload(defaultSearch, usersSearchCriteria)
    );
    if (res?.status === 400) {
      yield put(setBatchIdSearchResultsAction({ requests: [] }));
      yield put(searchBatchIdsSucceededAction());
    }
    if (res.data?.batchRequestSearchResult) {
      yield put(
        setBatchIdSearchResultsAction(res.data.batchRequestSearchResult)
      );
      yield put(searchBatchIdsSucceededAction());
    }
  } catch (e) {
    console.error(e);
    yield put(searchBatchIdsFailedAction());
  }
}

export function* configureSelectedBatchIdSaga(payload: any) {
  const { data } = payload;
  const { selectedBatchIds } = yield select(
    (state: RootState) => state.batchProcessing
  );
  if (selectedBatchIds[data]) {
    delete selectedBatchIds[data];
  } else {
    selectedBatchIds[data] = data;
  }
  yield put(setSelectedBatchIdAction(selectedBatchIds));
}

export function* selectAllBatchIdSaga() {
  const {
    batchIdSearchResults: { requests }
  } = yield select((state: RootState) => state.batchProcessing);
  const { selectedBatchIds } = yield select(
    (state: RootState) => state.batchProcessing
  );
  if (Object.keys(selectedBatchIds).length === requests.length) {
    yield put(setSelectedBatchIdAction({}));
    return;
  }
  const selectedIds = requests.reduce((total: any, current: any) => {
    const newTotal = { ...total };
    newTotal[current.requestNo] = current.requestNo;
    return newTotal;
  }, {});
  yield put(setSelectedBatchIdAction(selectedIds));
}

export function* getImportsDataSaga(): any {
  yield put({
    type: pollingActionTypes.POLL_START,
    data: {
      *tryCallBackFunction() {
        const employerGroupNo: number = yield select(
          state => state.selectEmployers.selectGroupNumber
        );
        const res: any = yield call(getBatchProcessingImports, employerGroupNo);
        if (res.data.MSSANRStatusModel?.length > 0) {
          const importsData = res.data.MSSANRStatusModel;
          const transformedImportsData = transformImportsData([...importsData]);
          yield put(getImportsSucceededAction(transformedImportsData));
          yield put(clearNotifications());
        } else if (res.data) {
          yield put(getImportsSucceededAction([]));
          yield put(clearNotifications());
        }
      },
      *catchCallBackFunction() {
        yield put(getImportsFailedAction());
        yield put(systemErrorNotificationAction());
      },
      pollInterval: pollInterval.FIFTEEN_SEC
    }
  });
}

export function* handleGoBackToViewImportsSaga(): any {
  yield put(clearNotifications());
  yield call(getImportsDataSaga);
}

export function* getDataToBatchSubmitSaga(): any {
  const employerGroupNo = yield select(
    state => state.selectEmployers.selectGroupNumber
  );

  try {
    const [form119, form165a, form102]: any = yield all([
      call(getForm119ToBatchSubmit, employerGroupNo),
      call(getForm165aToBatchSubmit, employerGroupNo),
      call(getForm102ToBatchSubmit, employerGroupNo)
    ]);

    const transformData = (
      formData: any,
      formType: formTypes
    ): IDataToBatchSubmit | null => {
      if (formData.data.requestAvailableToSubmit) {
        return {
          type: formType,
          totalRecordsNo:
            formData.data.requestAvailableToSubmit !== '0'
              ? formData.data.requestAvailableToSubmit
              : null
        };
      }
      if (formData.data.error) return null;
      return null;
    };

    const transformedData = [
      transformData(form119, formTypes.FORM_119),
      transformData(form165a, formTypes.FORM_165_A),
      transformData(form102, formTypes.FORM_102)
    ];

    const hasNoData = transformedData.every(
      (currentData: any) => currentData.totalRecordsNo === '0'
    );

    if (hasNoData) {
      yield put(getDataToBatchSubmitSucceededAction([]));
      yield put(clearNotifications());
    } else {
      yield put(getDataToBatchSubmitSucceededAction(transformedData));
      yield put(clearNotifications());
    }
  } catch (e) {
    console.error(e);
    yield put(getDataToBatchSubmitFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

export function* batchProcessingSaga(): any {
  yield all([
    yield takeLatest(
      batchProcessingActionTypes.DOWNLOAD_FILE_BUILDER_REQUESTED,
      downloadFileBuilderSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.SET_BATCH_PROCESSING_ACTIVE_TAB,
      handleTabChangeSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.GET_RECONCILIATION_YEARS_FOR_GROUP_REQUESTED,
      getReconcialtionYearsSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.BATCH_PROCESS_UPLOAD_REQUESTED,
      batchUploadSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.GET_IMPORT_ERRORS_REQUESTED,
      getImportErrorsToReviewSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.COMPLETE_REVIEW_IMPORT_ERRORS_REQUESTED,
      completeReviewImportErrorsSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.CANCEL_IMPORTS_REQUESTED,
      cancelImportsSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.CANCEL_IMPORTS_SUCCEEDED,
      handleCancelImportsSucceeded
    ),
    yield takeLatest(
      batchProcessingActionTypes.BATCH_SUBMIT_REQUESTED,
      batchSubmitSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.REVIEW_SUBMISSION_REQUESTED,
      getSubmissionToReviewSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.GO_TO_EXCEPTIONS_TAB,
      goToExceptionsTabSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.GET_IMPORTS_REQUESTED,
      getImportsDataSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.GO_BACK_TO_VIEW_IMPORTS,
      handleGoBackToViewImportsSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.GET_DATA_TO_BATCH_SUBMIT_REQUESTED,
      getDataToBatchSubmitSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.SEARCH_BATCH_IDS_REQUESTED,
      searchBatchIdsSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.CONFIGURE_SELECTED_BATCH_ID,
      configureSelectedBatchIdSaga
    ),
    yield takeLatest(
      batchProcessingActionTypes.SELECT_ALL_BATCH_IDS,
      selectAllBatchIdSaga
    )
  ]);
}
