import { ActionCreator } from 'redux';
import { EapAction } from 'types';
import { FeatureInterface } from 'unleash-client/lib/feature';

export enum featureFlagsActionTypes {
  FEATURE_FLAGS_REQUESTED = 'FEATURE_FLAGS_REQUESTED',
  FEATURE_FLAGS_SUCCEEDED = 'FEATURE_FLAGS_SUCCEEDED',
  FEATURE_FLAGS_FAILED = 'FEATURE_FLAGS_FAILED'
}

export const getFeatureFlagsAction: ActionCreator<EapAction> = () => ({
  type: featureFlagsActionTypes.FEATURE_FLAGS_REQUESTED
});

export const getFeatureFlagsSuccessAction: ActionCreator<EapAction> = (
  data: Array<FeatureInterface>
) => ({
  type: featureFlagsActionTypes.FEATURE_FLAGS_SUCCEEDED,
  data
});

export const getFeatureFlagsFailedAction: ActionCreator<EapAction> = () => ({
  type: featureFlagsActionTypes.FEATURE_FLAGS_FAILED
});
