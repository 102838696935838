export const EXTERNAL_URLS = {
  OMERS: 'https://www.omers.com/',
  OMERS_PRIVACY: 'https://www.omers.com/privacy',
  OMERS_ACCESSIBILITY: 'https://www.omers.com/accessibility',
  OMERS_PRIVACY_STATEMENT: 'https://www.omers.com/privacy-statement',
  OMERS_FORM_132:
    'https://assets.ctfassets.net/iifcbkds7nke/6o3WX8yxAKnPMnGgZXLNC/d082549981908ddde752ba430d2580f5/132.pdf',
  OMERS_EAM_HOMEPAGE: 'https://kmpub.omers.com/',
  OMERS_EMPLOYMENT_MANUAL_OTCFT:
    'https://kmpub.omers.com/#/reader/EocIXoDm8VEaZgAVqmJNWw/TtibjwY7XJG8piWn08U4jQ',
  OMERS_EMPLOYMENT_MANUAL_DIVESTMENT:
    'https://kmpub.omers.com/#/reader/EocIXoDm8VEaZgAVqmJNWw/NjY~R_23aYV2OIoM2XWjTg',
  OMERS_EMPLOYMENT_MANUAL_ADMIN:
    'https://kmpub.omers.com/#/reader/EocIXoDm8VEaZgAVqmJNWw/YPtH8xKQBFdrtCU9YginyA',
  OMERS_EMPLOYMENT_MANUAL_LEAVE_PERIOD:
    'https://kmpub.omers.com/#/reader/EocIXoDm8VEaZgAVqmJNWw/YM7Tum7m2cb8FDfi~9oMMA',
  OMERS_EMPLOYER_ADMINISTRATION_MANUAL: 'https://kmpub.omers.com',
  OMERS_EACCESS_UPDATE: 'https://www.omers.com/e-access-update',
  OMERS_TRAINING_VIDEOS: 'https://www.omers.com/get-training',
  OMERS_EACCESS: 'https://e-access.omers.com',
  OMERS_EACCESS_EMPLOYER_WEEK: 'https://www.omers.com/employer-education-week',
  OMERS_EACCESS_TIP_SHEET:
    'https://assets.ctfassets.net/iifcbkds7nke/4gy1URS5auRhW4jJbeqCFS/bddf308beb2564cbce9dd86446df25d1/e-access_-which_system_to_use.pdf',
  OMERS_EACCESS_USER_FEEDBACK:
    'https://a.sprig.com/UHhVSWw5M1MtRUJQfnNpZDpjZDM2MDAzZS1mY2FmLTRiZGYtYTkzYi1kMjM3ZDVjZGYwMTI=',
  OMERS_EACCESS_119_INFO:
    'https://www.omers.com/annual-reconciliation-e-form-119',
  OMERS_CONTRIBUTIONS_AND_PA_CALCULATORS:
    'https://www.omers.com/contributions-and-pa-calculators',
  OMERS_DATA_EXTRACT:
    'https://www.omers.com/e-access-tools-and-resources#Dataextracts',
  OMERS_CHECKLISTS: 'https://www.omers.com/checklists',
  OMERS_EMPLOYER_FORMS: 'https://www.omers.com/employer-forms',
  OMERS_UNIONS_AND_ASSOCIATIONS:
    'https://www.omers.com/list-of-unions-and-associations',
  OMERS_FORM_109:
    'https://assets.ctfassets.net/crj1za6j29iv/628kSjz9MNJivDHEmWThdw/52441cc203d30cca0b01d770d0e6007d/109.pdf',
  OMERS_PENSION_BLUEPRINT_PODCAST:
    'https://www.omers.com/the-pension-blueprint-podcast',
  OMERS_PENSION_BLUEPRINT_PODCAST_SPOTIFY_EPISODES:
    'https://open.spotify.com/show/010ilte7BtWYsnK66ZMQ97',
  OMERS_NEWS: 'https://www.omers.com/news',
  OMERS_CONTACT_US: 'https://www.omers.com/contact-us',
  OMERS_TERMS_OF_USE: 'https://www.omers.com/terms-of-use',
  OMERS_SECURITY: 'https://www.omers.com/security',
  OMERS_MEMBER_HANDBOOK: 'https://www.omers.com/member-handbook',
  EMPLOYER_QUARTERLY: 'https://newsletter.omers.com/en-CA/employer-q1-2024',
  MYOMERS_LOGIN: 'https://www.myomers.omers.com/login',
  OMERS_FACEBOOK: 'https://www.facebook.com/OMERSpensionplan',
  OMERS_INSTAGRAM: 'https://www.instagram.com/weareomers/',
  OMERS_LINKEDIN:
    'https://www.linkedin.com/company/omers/mycompany/verification/',
  OMERS_TRAINING_VIDEO_EMPLOYER_OFFERING:
    'https://www.youtube.com/watch?v=PL3ztTi3_I8',
  OMERS_TRAINING_VIDEO_OMERS_OFFERING:
    'https://www.youtube.com/watch?v=X4FtMUA1rZ4',
  CRA_BUSINESS_NUMBER:
    'https://www.canada.ca/en/revenue-agency/services/tax/businesses/topics/registering-your-business/you-need-a-business-number-a-program-account.html'
};

export const PUBLIC_SITE_URLS = {
  OFFERING_OMERS_MEMBERSHIP:
    '/content/administering-the-plan/offering-omers-membership',
  OFFERING_OMERS_MEMBERSHIP_TEST: '/content/offering-omers-membership',
  SUPPLEMENTAL_PLAN: '/content/administering-the-plan/supplemental-plan',
  AVC_PAYROLL_DEDUCTIONS:
    '/content/administering-the-plan/avc-payroll-deductions',
  GET_TRAINING: '/content/get-training',
  EMPLOYER_FORMS: '/content/resources/employer-forms',
  TEMPLATE_SPREADSHEETS: '/content/resources/template-spreadsheets',
  TEMPLATE_SPREADSHEETS_TEST: '/content/template-spreadsheets',
  CONTRIBUTIONS_AND_PA_CALCULATORS:
    '/content/resources/contributions-and-pa-calculators',
  LIST_OF_UNIONS_AND_ASSOCIATIONS:
    '/content/resources/list-of-unions-and-associations',
  EMPLOYER_ADMINISTRATION_MANUAL:
    '/content/resources/employer-administration-manual',
  REQUEST_TRAINING: '/content/employer-request-forms/employer-training-request',
  REQUEST_FOR_PRINT_MATERIALS:
    '/content/employer-request-forms/print-materials',
  CONFIRMATION_PAGE: '/content/employer-request-forms/confirmation',
  EMPLOYER_EDUCATION_WEEK: '/content/employer-education-week',
  EMPLOYER_TOOLKIT: '/content/resources/employer-toolkit'
};

export const MONTH_OPTIONS = [
  {
    key: 'JAN',
    val: '01',
    value: '0',
    translation: 'common:JANUARY_SHORT',
    translationLong: 'common:JANUARY_LONG',
    contributionMonth: 'common:DECEMBER_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'FEB',
    val: '02',
    value: '1',
    translation: 'common:FEBRUARY_SHORT',
    translationLong: 'common:FEBRUARY_LONG',
    contributionMonth: 'common:JANUARY_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'MAR',
    val: '03',
    value: '2',
    translation: 'common:MARCH_SHORT',
    translationLong: 'common:MARCH_LONG',
    contributionMonth: 'common:FEBRUARY_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'APR',
    val: '04',
    value: '3',
    translation: 'common:APRIL_SHORT',
    translationLong: 'common:APRIL_LONG',
    contributionMonth: 'common:MARCH_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'MAY',
    val: '05',
    value: '4',
    translation: 'common:MAY_SHORT',
    translationLong: 'common:MAY_LONG',
    contributionMonth: 'common:APRIL_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'JUN',
    val: '06',
    value: '5',
    translation: 'common:JUNE_SHORT',
    translationLong: 'common:JUNE_LONG',
    contributionMonth: 'common:MAY_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'JUL',
    val: '07',
    value: '6',
    translation: 'common:JULY_SHORT',
    translationLong: 'common:JULY_LONG',
    contributionMonth: 'common:JUNE_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'AUG',
    val: '08',
    value: '7',
    translation: 'common:AUGUST_SHORT',
    translationLong: 'common:AUGUST_LONG',
    contributionMonth: 'common:JULY_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'SEP',
    val: '09',
    value: '8',
    translation: 'common:SEPTEMBER_SHORT',
    translationLong: 'common:SEPTEMBER_LONG',
    contributionMonth: 'common:AUGUST_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'OCT',
    val: '10',
    value: '9',
    translation: 'common:OCTOBER_SHORT',
    translationLong: 'common:OCTOBER_LONG',
    contributionMonth: 'common:SEPTEMBER_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'NOV',
    val: '11',
    value: '10',
    translation: 'common:NOVEMBER_SHORT',
    translationLong: 'common:NOVEMBER_LONG',
    contributionMonth: 'common:OCTOBER_SHORT_WITH_CONTRIBUTION'
  },
  {
    key: 'DEC',
    val: '12',
    value: '11',
    translation: 'common:DECEMBER_SHORT',
    translationLong: 'common:DECEMBER_LONG',
    contributionMonth: 'common:NOVEMBER_SHORT_WITH_CONTRIBUTION'
  }
];

export const PHONE_OPTIONS = [
  {
    key: 'MOBILE',
    val: 'M',
    translation: 'common:PHONE_MOBILE'
  },
  {
    key: 'HOME',
    val: 'H',
    translation: 'common:PHONE_HOME'
  }
];

export const SALARY_OPTIONS = [
  {
    key: 'ANNUALIZED',
    val: 'A',
    translation: 'common:SALARY_ANNUALIZED'
  },
  {
    key: 'HOURLY',
    val: 'H',
    translation: 'common:SALARY_HOURLY'
  }
];

export const NRA_OPTIONS = [
  {
    key: '60',
    val: '60'
  },
  {
    key: '65',
    val: '65'
  }
];

export const PAY_PERIOD_OPTIONS = [
  {
    key: '12',
    val: '12',
    translation: 'common:PAY_PERIOD_12'
  },
  {
    key: '20',
    val: '20',
    translation: 'common:PAY_PERIOD_20'
  },
  {
    key: '21',
    val: '21',
    translation: 'common:PAY_PERIOD_21'
  },
  {
    key: '22',
    val: '22',
    translation: 'common:PAY_PERIOD_22'
  },
  {
    key: '23',
    val: '23',
    translation: 'common:PAY_PERIOD_23'
  },
  {
    key: '24',
    val: '24',
    translation: 'common:PAY_PERIOD_24'
  },
  {
    key: '25',
    val: '25',
    translation: 'common:PAY_PERIOD_25'
  },
  {
    key: '26',
    val: '26',
    translation: 'common:PAY_PERIOD_26'
  },
  {
    key: '27',
    val: '27',
    translation: 'common:PAY_PERIOD_27'
  },
  {
    key: '52',
    val: '52',
    translation: 'common:PAY_PERIOD_52'
  },
  {
    key: 'other',
    val: 'other',
    translation: 'common:PAY_PERIOD_OTHER'
  }
];

export const SUPPORTED_BROWSERS = {
  // anything below this is error page
  'Internet Explorer': '>=11',
  'Microsoft Edge': '>=15',
  safari: '>=10',
  chrome: '>=49',
  firefox: '>=52',
  opera: '>=41'
};

export const BOT_SUPPORTED_BROWSERS = {
  googlebot: '>0',
  'google-structured-data-testing-tool': '>0',
  bingbot: '>0',
  linkedinbot: '>0',
  'mediapartners-google': '>0'
};

export const COMPATIBLE_BROWSERS = {
  // anything below this is deprecated
  'Internet Explorer': '>11',
  'Microsoft Edge': '>=80',
  safari: '>=11',
  chrome: '>=80',
  firefox: '>=76',
  opera: '>=66'
};

export enum filterStatusCode {
  READ = 'R',
  UNREAD = 'U',
  UNRESOLVED = 'UN',
  AWAITING_REPLY = 'W',
  RESOLVED = 'RE'
}

export enum filterValues {
  ALL = 'all',
  ALL_UNREAD = 'all-unread',
  ALL_UNRESOLVED = 'all-unresolved',
  ACTION_REQUIRED = 'action-required',
  AWAITING_REPLY = 'awaiting-reply',
  RESOLVED = 'resolved'
}
export interface IFilterStatus {
  val: filterValues;
  bruceStatuses?: string[];
  translation: string;
  key?: filterStatusCode;
}

export const FILTER_STATUS: IFilterStatus[] = [
  {
    val: filterValues.ALL,
    translation: 'common:FILTER_DROPDOWN_ALL',
    bruceStatuses: [
      'Follow-up',
      'Monitoring',
      'Available',
      'Closed',
      'In Progress',
      'Internal Follow-up'
    ]
  },
  {
    val: filterValues.ALL_UNREAD,
    bruceStatuses: [
      'Follow-up',
      'Monitoring',
      'Available',
      'Closed',
      'In Progress',
      'Internal Follow-up'
    ],
    translation: 'common:FILTER_DROPDOWN_ALL_UNREAD',
    key: filterStatusCode.UNREAD
  },
  {
    val: filterValues.ALL_UNRESOLVED,
    bruceStatuses: ['Follow-up', 'Monitoring', 'Available', 'In Progress'],
    translation: 'common:FILTER_DROPDOWN_ALL_UNRESOLVED',
    key: filterStatusCode.UNRESOLVED
  },
  {
    val: filterValues.ACTION_REQUIRED,
    bruceStatuses: ['Follow-up', 'Monitoring'],
    translation: 'common:FILTER_DROPDOWN_ACTION_REQUIRED'
  },
  {
    val: filterValues.AWAITING_REPLY,
    bruceStatuses: ['Available', 'In Progress', 'Internal Follow-up'],
    translation: 'common:FILTER_DROPDOWN_AWAITING_REPLY',
    key: filterStatusCode.AWAITING_REPLY
  },
  {
    val: filterValues.RESOLVED,
    bruceStatuses: ['Closed'],
    translation: 'common:FILTER_DROPDOWN_RESOLVED',
    key: filterStatusCode.RESOLVED
  }
];

export enum indicators {
  TRUE = 'Y',
  FALSE = 'N'
}

export const ECORR_TOPICS = [
  {
    key: '119',
    val: 'Annual Reporting (119)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_119'
  },
  {
    key: 'attestation',
    val: 'Attestation',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_ATTESTATION'
  },
  {
    key: '105',
    val: 'Contribution Remittance (105)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_105'
  },
  {
    key: '143',
    val: 'Disability Reporting (143)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_143_DR'
  },
  {
    key: 'e-access-support',
    val: 'e-Access Support',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_EACCESS_SUPPORT'
  },
  {
    key: 'eligible-service',
    val: 'Eligible Service',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_ELIGIBLE'
  },
  {
    key: '109',
    val: 'Employer Contact Information (109)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_109'
  },
  {
    key: '158',
    val: 'Employment Change/Benefit Request (158)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_158'
  },
  {
    key: 'forecasting-reports',
    val: 'Forecasting Reports',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_FORECASTING_REPORTS'
  },
  {
    key: 'general-inquiry',
    val: 'General Inquiry',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_GENERAL'
  },
  {
    key: '165',
    val: 'Leave Period Reporting (165)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_165'
  },
  {
    key: '102',
    val: 'Member Enrolment (102)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_102'
  },
  {
    key: '106',
    val: 'Member Info Change (106)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_106'
  },
  {
    key: 'nra-conversion',
    val: 'NRA conversion',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_NRA'
  },
  {
    key: '167',
    val: 'Omission Period (167)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_167'
  },
  {
    key: '190',
    val: 'Pension Estimate (190)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_190'
  },
  {
    key: 'post-retirement-death',
    val: 'Post-Retirement Death',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_POST_RETIREMENT_DEATH'
  },
  {
    key: '143-pre-retirement-death',
    val: 'Pre-Retirement Death (143)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_143_PRD'
  },
  {
    key: '143-retirement',
    val: 'Retirement (143)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_143_RETIREMENT'
  },
  {
    key: 'separation-and-divorce',
    val: 'Separation and Divorce',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_SEPARATION_AND_DIVORCE'
  },
  {
    key: '300-301',
    val: 'Supplemental Plans (300/301)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_300_301'
  },
  {
    key: '143-termination',
    val: 'Termination (143)',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_143_TERMINATION'
  },
  {
    key: '152',
    val: 'Disability Reporting (152)'
  }
];

// topics that cannot be created inside of e-access but are available within Bruce
export const ECORR_TOPICS_BRUCE_INCOMING = [
  {
    key: 'salary-buyback',
    val: 'Salary Rate Required - Buyback',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_SALARY_BUYBACK'
  },
  {
    key: 'salary-transfers',
    val: 'Salary Rate Required - Transfers',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_SALARY_TRANSFER'
  },
  {
    key: 'financial-inf-required',
    val: 'Financial Information Required',
    translation: 'common:MESSAGES_FILTER_DROPDOWN_TOPIC_FINANCIAL_INFO'
  }
];

export const PAYMENT_SCHEDULE_TYPE_OPTIONS = [
  {
    key: 'optional-service',
    val: 'OPT',
    translation: 'common:PAYMENT_SCHEDULE_TYPE_OPTIONAL_SERVICE'
  },
  {
    key: 'nra-conversion',
    val: 'NRA',
    translation: 'common:PAYMENT_SCHEDULE_TYPE_NRA_CONVERSION'
  },
  {
    key: 'type-1',
    val: 'TY1',
    translation: 'common:PAYMENT_SCHEDULE_TYPE_TYPE_1'
  },
  {
    key: 'type-3',
    val: 'TY3',
    translation: 'common:PAYMENT_SCHEDULE_TYPE_TYPE_3'
  }
];

export const SERVICE_PURCHASE_TYPE = [
  {
    key: 'SELECT',
    val: '',
    translationShort: 'common:FORM_SELECT_PLACEHOLDER_SELECT_ONE',
    translationLong: 'common:FORM_SELECT_PLACEHOLDER_SELECT_ONE'
  },
  {
    key: 'AL',
    val: 'AL',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_AL_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_AL_LONG'
  },
  {
    key: 'BB',
    val: 'BB',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_BB_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_BB_LONG'
  },
  {
    key: 'EL',
    val: 'EL',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_EL_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_EL_LONG'
  },
  {
    key: 'I2',
    val: 'I2',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_I2_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_I2_LONG'
  },
  {
    key: 'I3',
    val: 'I3',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_I3_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_I3_LONG'
  },
  {
    key: 'LS',
    val: 'LS',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_LS_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_LS_LONG'
  },
  {
    key: 'OE',
    val: 'OE',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_OE_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_OE_LONG'
  },
  {
    key: 'ID',
    val: 'ID',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_ID_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_ID_LONG'
  },
  {
    key: 'OMS',
    val: 'OMS',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_OMS_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_OMS_LONG'
  },
  {
    key: 'PL',
    val: 'PL',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_PL_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_PL_LONG'
  },
  {
    key: 'TL',
    val: 'TL',
    translationShort: 'common:SERVICE_PURCHASE_TYPE_TL_SHORT',
    translationLong: 'common:SERVICE_PURCHASE_TYPE_TL_LONG'
  }
];

export const PAYMENT_METHOD = [
  {
    key: 'E',
    val: 'EFT'
  },
  {
    key: 'C',
    val: 'Cheque'
  },
  {
    key: 'B',
    val: 'Bill Payments'
  },
  {
    key: 'D',
    val: 'Direct Deposit'
  },
  {
    key: 'P',
    val: 'Consolidated Payment'
  }
];

export const BATCH_PRINT_FILTER_OPTIONS = {
  SEARCH_BY_NRA_SELECT: [
    {
      val: 'All',
      translation: 'BATCH_PRINT_FILTER_ALL'
    },
    {
      val: 60,
      translation: 'BATCH_PRINT_FILTER_60'
    },
    {
      val: 65,
      translation: 'BATCH_PRINT_FILTER_65'
    }
  ],
  SEARCH_BY_EMPLOYMENT_STATUS_SELECT: [
    {
      val: 'All',
      translation: 'BATCH_PRINT_FILTER_ALL'
    },
    {
      val: 'C',
      translation: 'BATCH_PRINT_FILTER_CFT'
    },
    {
      val: 'O',
      translation: 'BATCH_PRINT_FILTER_OTCFT'
    }
  ]
};

export enum enrolmentReason {
  VOLUNTARY = 'VL'
}

export enum employmentStatus {
  OTHER_THAN_CONTINUOUS_FULL_TIME = 'O',
  CONTINUOUS_FULL_TIME = 'C'
}
