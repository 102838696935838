import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const options = {
  backend: {
    loadPath:
      process.env.STORYBOOK && process.env.NEXT_PUBLIC_APP_ENV !== 'local'
        ? '/pensions/e-access/eaccess-front-end/storybook/locales/{{lng}}/{{ns}}.json'
        : '/locales/{{lng}}/{{ns}}.json'
  },
  lng: 'en',
  fallbackLng: 'en',
  ns: [
    'attachments',
    'authentication',
    'common',
    'form102',
    'form106',
    'form119',
    'form143',
    'form152',
    'form158',
    'form165',
    'form165a',
    'form165b',
    'form165c',
    'forms',
    'header',
    'home',
    'members',
    'messages',
    'requests',
    'validations',
    'batch-processing',
    'stewardship-report'
  ],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false
  },
  react: {
    useSuspense: true
  }
};

if (process.browser) {
  i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init();
}

if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;
