import { ChangeEvent, FocusEvent } from 'react';
import * as React from 'react';
import { useFormContext, Control } from 'react-hook-form-legacy';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';
import { maskDate } from 'utils';
import TextInput from '../textInput/TextInput';
import styles from './DateRangeInput.module.scss';

const cx = classNames.bind(styles);

interface DateRangeInputProps {
  label?: string;
  toLabel?: string;
  control: Control;
  startDateName: string;
  endDateName: string;
  hasError?: boolean;
  hideError?: boolean;
  startDateOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  endDateOnChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  startDateOnBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  endDateOnBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  tooltip?: string | Array<string> | React.ReactNode;
  testingTag?: string;
  fromDateFieldError?: string;
  toDateFieldError?: string;
  commonError?: string; // used to display error that shared by 2 fields together
  size?: 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';
  hideTo?: boolean;
}
const DateRangeInput = ({
  label,
  toLabel,
  control,
  startDateName,
  endDateName,
  hasError = false,
  hideError = false,
  startDateOnChange,
  endDateOnChange,
  startDateOnBlur,
  endDateOnBlur,
  tooltip,
  testingTag = 'date-range-input',
  fromDateFieldError,
  toDateFieldError,
  commonError,
  size = 'xlarge',
  hideTo = false
}: DateRangeInputProps) => {
  const { t } = useTranslation('common');
  const { errors } = useFormContext();

  return (
    <>
      <div
        className={cx({
          dateRangeInput: true,
          isLarge: size === 'large',
          isMedium: size === 'medium',
          isSmall: size === 'small',
          isXsmall: size === 'xsmall'
        })}
      >
        <div className={styles.dateRangeInputFrom}>
          <TextInput
            name={startDateName}
            labelText={label}
            type="text"
            mask={maskDate}
            placeholder="MM/DD/YYYY"
            testingTag={`${testingTag}-from`}
            control={control}
            errorMessage={
              !hideError && !commonError
                ? fromDateFieldError || errors[startDateName]
                : undefined
            }
            hasError={
              !!errors[startDateName] ||
              hasError ||
              !!fromDateFieldError ||
              !!commonError
            }
            onChange={startDateOnChange}
            onBlur={startDateOnBlur}
            tooltip={tooltip}
          />
        </div>
        <span className={styles.toText}>{hideTo ? '' : t('TO')}</span>
        <div className={styles.dateRangeInputTo}>
          <TextInput
            name={endDateName}
            type="text"
            labelText={toLabel}
            mask={maskDate}
            placeholder="MM/DD/YYYY"
            testingTag={`${testingTag}-to`}
            control={control}
            errorMessage={
              !hideError && !commonError
                ? toDateFieldError || errors[endDateName]
                : undefined
            }
            hasError={
              !!errors[endDateName] ||
              hasError ||
              !!toDateFieldError ||
              !!commonError
            }
            onChange={endDateOnChange}
            onBlur={endDateOnBlur}
          />
        </div>
      </div>
      {!!commonError && (
        <div
          className={styles.error}
          data-testid="date-range-input-common-error-msg"
        >
          {commonError}
        </div>
      )}
    </>
  );
};

export default DateRangeInput;
