import { ActionCreator } from 'redux';
import { EapAction } from 'types';
import { IArchivedMessagesValues } from './archivedMessagesFilter/ArchivedMessagesFilter';
import {
  IArchivedMessagesMessage,
  IArchivedMessagesSearchPayload
} from './types';

export enum archivedMessagesActionTypes {
  GET_ARCHIVED_MESSAGES_REQUESTED = 'GET_ARCHIVED_MESSAGES_REQUESTED',
  GET_ARCHIVED_MESSAGES_SUCCEEDED = 'GET_ARCHIVED_MESSAGES_SUCCEEDED',
  GET_ARCHIVED_MESSAGES_FAILED = 'GET_ARCHIVED_MESSAGES_FAILED',
  SET_ARCHIVED_MESSAGES_BUTTON_STATUS = 'SET_ARCHIVED_MESSAGES_BUTTON_STATUS',
  APPLY_ARCHIVED_MESSAGES_FILTER_REQUESTED = 'APPLY_ARCHIVED_MESSAGES_FILTER_REQUESTED',
  GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_REQUESTED = 'GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_REQUESTED',
  GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_SUCCEEDED = 'GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_SUCCEEDED',
  GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_FAILED = 'GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_FAILED',
  SET_ARCHIVED_MESSAGES_CORRESPONDENCE_NUMBER = 'SET_ARCHIVED_MESSAGES_CORRESPONDENCE_NUMBER',
  SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED = 'SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED',
  SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED_SUCCEEDED = 'SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED_SUCCEEDED',
  SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED_FAILED = 'SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED_FAILED',
  RESOLVE_ARCHIVED_MESSAGE_REQUESTED = 'RESOLVE_ARCHIVED_MESSAGE_REQUESTED',
  RESOLVE_ARCHIVED_MESSAGE_SUCCEEDED = 'RESOLVE_ARCHIVED_MESSAGE_SUCCEEDED',
  RESOLVE_ARCHIVED_MESSAGE_FAILED = 'RESOLVE_ARCHIVED_MESSAGE_FAILED'
}

export const getArchivedMessagesRequestedAction: ActionCreator<EapAction> = (
  data: IArchivedMessagesSearchPayload
) => ({
  type: archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_REQUESTED,
  data
});

export const getArchivedMessagesSucceededAction: ActionCreator<EapAction> = (
  data: IArchivedMessagesMessage[]
) => ({
  type: archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_SUCCEEDED,
  data
});

export const getArchivedMessagesFailedAction: ActionCreator<EapAction> = data => ({
  type: archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_FAILED,
  data
});

export const setArchivedMessagesButtonStatus: ActionCreator<EapAction> = (data: {
  isResetButtonDisabled: boolean;
  isApplyButtonDisabled: boolean;
  isApplyButtonClicked?: boolean;
}) => ({
  type: archivedMessagesActionTypes.SET_ARCHIVED_MESSAGES_BUTTON_STATUS,
  data
});

export const applyArchivedMessagesFilterRequestedAction: ActionCreator<EapAction> = (
  data: IArchivedMessagesValues
) => ({
  type: archivedMessagesActionTypes.APPLY_ARCHIVED_MESSAGES_FILTER_REQUESTED,
  data
});
export const getArchivedMessagesMessageThreadRequestedAction: ActionCreator<EapAction> = () => ({
  type:
    archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_REQUESTED
});

export const getArchivedMessagesMessageThreadSucceededAction: ActionCreator<EapAction> = (
  data: IArchivedMessagesMessage[]
) => ({
  type:
    archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_SUCCEEDED,
  data
});

export const getArchivedMessagesMessageThreadFailedAction: ActionCreator<EapAction> = data => ({
  type: archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_FAILED,
  data
});

export const setCorrespondenceNumber: ActionCreator<EapAction> = (
  data: string
) => ({
  type: archivedMessagesActionTypes.SET_ARCHIVED_MESSAGES_CORRESPONDENCE_NUMBER,
  data
});

export const sendReplyRequestedAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: archivedMessagesActionTypes.SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED,
  data
});
export const sendReplyRequestedSucceededAction: ActionCreator<EapAction> = () => ({
  type:
    archivedMessagesActionTypes.SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED_SUCCEEDED
});
export const sendReplyRequestedFailedAction: ActionCreator<EapAction> = data => ({
  type:
    archivedMessagesActionTypes.SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED_FAILED,
  data
});

export const resolveAction: ActionCreator<EapAction> = (data: string) => ({
  type: archivedMessagesActionTypes.RESOLVE_ARCHIVED_MESSAGE_REQUESTED,
  data
});
