import { SelectMenuItem } from 'components/v2/atomic/selectInput/SelectInput';
import { postData } from './services';

export const getStaffMembers = (groupNumber: string) =>
  postData('/api/microsoft-bookings/get-staff-members', {
    groupNumber
  });

export const getAppointments = (groupNumber: string, email: string) =>
  postData('/api/microsoft-bookings/list-appointments', {
    groupNumber,
    email
  });

export const getStaffAvailability = (
  groupNumber: string,
  params: {
    staffMember: Array<SelectMenuItem> | SelectMenuItem;
    day: string;
    timeInterval: number | undefined;
  }
) =>
  postData('/api/microsoft-bookings/get-staff-availability', {
    groupNumber,
    params
  });

export const createAppointment = (
  groupNumber: string,
  payload: {
    staff: string[];
    day: string | undefined;
    time: string | undefined;
    request: string;
    duration: string | undefined;
    email: string;
    name: string;
  }
) =>
  postData('/api/microsoft-bookings/create-appointment', {
    groupNumber,
    payload
  });

export const updateAppointment = (
  groupNumber: string,
  payload: {
    appointmentId: string;
    staff: string[];
    day: string | undefined;
    time: string | undefined;
    request: string;
    duration: string | undefined;
  }
) =>
  postData('/api/microsoft-bookings/update-appointment', {
    groupNumber,
    payload
  });

export const cancelAppointment = (groupNumber: string, appointmentId: string) =>
  postData('/api/microsoft-bookings/cancel-appointment', {
    groupNumber,
    appointmentId
  });
