import Modal from 'components/modal/Modal';

interface ConfirmationModalProps {
  visible: boolean;
  onConfirm: Function;
  onCancel?: Function;
  okText: string;
  titleText: string;
  bodyText: string;
  cancelText?: string;
  width?: number;
  isCancel?: boolean;
  isLoading?: boolean;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  closeable?: boolean;
  maskClosable?: boolean;
  keyboard?: boolean;
  onClose?: Function;
}

const ConfirmationModal = ({
  visible,
  onConfirm,
  onCancel,
  okText,
  bodyText,
  titleText,
  cancelText,
  width = 660,
  isCancel = true,
  isLoading = false,
  okDisabled = false,
  cancelDisabled = false,
  closeable = true,
  maskClosable = true,
  keyboard = true,
  onClose
}: ConfirmationModalProps) => (
  <Modal
    visible={visible}
    onOk={onConfirm}
    onCancel={onCancel}
    onClose={onClose}
    okText={okText}
    titleText={titleText}
    width={width}
    cancelText={cancelText}
    isCancel={isCancel}
    isLoading={isLoading}
    okDisabled={okDisabled}
    closable={closeable}
    cancelDisabled={cancelDisabled}
    maskClosable={maskClosable}
    keyboard={keyboard}
  >
    {bodyText}
  </Modal>
);

export default ConfirmationModal;
