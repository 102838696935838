import { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ExceptionsHashmapObject,
  ExceptionsObject,
  PropertiesObjectItem
} from 'interfaces';
import CheckBoxInput from 'components/forms/inputs/checkBoxInput/CheckBoxInput';
import SelectInput from 'components/forms/inputs/selectInput/SelectInput';
import TextArea from 'components/forms/inputs/textArea/TextArea';

import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import { triggerOverride } from 'containers/requestForm/common/formActions';
import styles from './Override.module.scss';

/**
 * Validates if all exceptions have no errors, and if exceptions have been
 * overridden; if any overridden exceptions are of reason type Other, it also
 * validates if reason text is provided
 */
export const validateOverridesComplete = (
  exceptions: Array<ExceptionsObject>,
  overrideExceptions: Array<ExceptionsObject>
) => {
  if (
    exceptions?.length > 0 &&
    exceptions?.filter(exception => exception.exception === 'Error').length > 0
  ) {
    return false;
  }

  if (exceptions?.length > 0 && overrideExceptions?.length >= 0) {
    return (
      overrideExceptions?.filter(override =>
        exceptions?.find(
          exception => override.messageid === exception.messageid
        )
      ).length === exceptions.length &&
      overrideExceptions?.filter(
        override =>
          override.overrideReasonCode === 'SN' &&
          (override.overrideReasonOtherText?.length ?? 0) <= 0
      ).length === 0 &&
      overrideExceptions?.filter(override => override.overrideInd === 'N')
        .length === 0
    );
  }
  return true;
};

/**
 * Takes exceptions and override exceptions, and dispatches updates to any form
 * based on given dispatchAction
 */
export const updateExceptions = (
  exception: ExceptionsObject,
  overrideExceptions: Array<ExceptionsObject>,
  dispatchAction: (exceptions: Array<ExceptionsObject>) => void
) => {
  let res = [...overrideExceptions];
  const exceptionIndex = overrideExceptions.findIndex(
    (item: ExceptionsObject) => item.messageid === exception.messageid
  );
  if (res.length === 0) {
    res = [exception];
    dispatchAction(res);
  } else {
    if (exceptionIndex === -1) {
      res.push(exception);
    } else {
      res[exceptionIndex] = exception;
    }
    if (overrideExceptions[exceptionIndex]) {
      const codesChanged =
        res[exceptionIndex].overrideReasonCode !==
        overrideExceptions[exceptionIndex].overrideReasonCode;
      const explanationChanged =
        res[exceptionIndex].overrideReasonOtherText !==
        overrideExceptions[exceptionIndex].overrideReasonOtherText;
      if (explanationChanged || codesChanged) {
        dispatchAction(res);
      }
    } else if (res.length > overrideExceptions.length) {
      dispatchAction(res);
    }
  }
};

/**
 * Generates error or Override component for use as Notification message
 */
export const getOverrideNotificationMessage = (
  exception: ExceptionsObject,
  overrideExceptions: Array<ExceptionsObject>,
  overrideReasons: Array<PropertiesObjectItem>,
  dispatchAction: (exceptions: Array<ExceptionsObject>) => void,
  reasonRequired?: boolean
) => {
  if (exception.exception === 'Error') {
    // return exception.moreSupportingData
    //   ? [
    //       `**Error**&nbsp;&nbsp;&nbsp;${exception.itemtx}<br /><br />
    //       **Applies To: ${exception.moreSupportingData}**`
    //     ]
    //   :
    return [`**Error**&nbsp;&nbsp;&nbsp;${exception.itemtx}`];
  }

  return [
    <Override
      exception={exception}
      onUpdateCallback={(e: ExceptionsObject) =>
        updateExceptions(e, overrideExceptions, dispatchAction)
      }
      defaultOverrideReasons={overrideReasons}
      overrideExceptionSaved={overrideExceptions.find(
        (override: ExceptionsObject) =>
          override.messageid === exception.messageid
      )}
      reasonRequired={reasonRequired}
    />
  ];
};

interface OverrideProps {
  exception: ExceptionsObject;
  onUpdateCallback: Function;
  defaultOverrideReasons: PropertiesObjectItem[];
  overrideExceptionSaved?: ExceptionsObject;
  reasonRequired?: boolean;
}
const Override = ({
  exception,
  defaultOverrideReasons,
  onUpdateCallback,
  overrideExceptionSaved,
  reasonRequired
}: OverrideProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { submitPressed } = useSelector(
    (state: RootState) => state?.commonOverride
  );
  const {
    hashMap,
    messageid: messageId,
    exception: status,
    itemtx: itemText
  } = exception;
  const [showOverrideBody, setShowOverrideBody] = useState(
    !!overrideExceptionSaved?.overrideReasonCode
  );
  const [overrideReason, updateOverrideReason] = useState(
    overrideExceptionSaved?.overrideReasonCode ?? ''
  );
  const [overrideExplanation, setOverrideExplanation] = useState(
    overrideExceptionSaved?.overrideReasonOtherText ?? ''
  );

  // track initialization to prevent updates from clearing prepopulated fields
  const [init, setInit] = useState(true);

  const altHashMap: ExceptionsHashmapObject[] = defaultOverrideReasons.map(
    item => ({
      code: item.key,
      description: item.val,
      dirty: true,
      hashMap: null,
      messageid: null
    })
  );
  const exceptionDetails = hashMap ? hashMap[messageId] : altHashMap;

  const getUpdateObj = (override: 'Y' | 'N') => ({
    requestNo: exception.requestNo,
    sequenceNo: exception.sequenceNo,
    exception: exception.exception,
    itemtx: exception.itemtx,
    overrideInd: override,
    overrideReasonCode: override === 'Y' ? overrideReason : null,
    overrideReasonOtherText:
      override === 'Y' ? overrideExplanation || null : null,
    moreSupportingData: exception.moreSupportingData,
    messageid: exception.messageid,
    batchOverrideAllowedInd: exception.batchOverrideAllowedInd
  });

  // initiate if previously saved exception has properties filled
  useEffect(() => {
    if (exception.overrideInd === 'Y') {
      setShowOverrideBody(true);
    }

    if (exception.overrideReasonCode) {
      updateOverrideReason(exception.overrideReasonCode);
    }

    if (exception.overrideReasonOtherText) {
      setOverrideExplanation(exception.overrideReasonOtherText);
    }

    setInit(false);
  }, []);

  useEffect(() => {
    if (
      (overrideReason !== '' || overrideExplanation !== '') &&
      showOverrideBody
    ) {
      onUpdateCallback(getUpdateObj('Y'));
    }
  }, [overrideExplanation, overrideReason, showOverrideBody]);

  useEffect(() => {
    if (!showOverrideBody) {
      onUpdateCallback(getUpdateObj('N'));
    }
  }, [showOverrideBody]);

  useEffect(() => {
    if (!init && overrideReason !== 'SN') {
      setOverrideExplanation('');
    }
  }, [overrideReason]);

  const selectOptions = exceptionDetails.map(
    (item: ExceptionsHashmapObject) => ({
      text: item.description,
      id: item.code
    })
  );

  const explanationError =
    submitPressed &&
    (reasonRequired || overrideReason === 'SN') &&
    overrideExplanation.length === 0
      ? t('OVERRIDE_REASON_ERROR')
      : '';

  return (
    <div className={styles.overrideContainer}>
      <div className={styles.overrideRow}>
        <div>
          <span className="semiBold">{status}</span>
          &nbsp;&nbsp;&nbsp;
          <span className={styles.itemText}>{itemText}</span>
        </div>
        {exceptionDetails && (
          <CheckBoxInput
            checked={showOverrideBody}
            onChange={() => {
              setShowOverrideBody(!showOverrideBody);
            }}
            title={t('OVERRIDE_CHECKBOX')}
            override
          />
        )}
      </div>
      {exceptionDetails && showOverrideBody && (
        <div className={styles.overrideBody}>
          <div className={styles.select}>
            <SelectInput
              name="reason"
              title={t('OVERRIDE_DROPDOWN_REASON')}
              value={overrideReason}
              placeholder={t('OVERRIDE_DROPDOWN_REASON_PLACEHOLDER')}
              onChange={(e: FormEvent<HTMLSelectElement>) => {
                updateOverrideReason(e.currentTarget.value);
                dispatch(triggerOverride(false));
              }}
            >
              {selectOptions.map((item: any) => (
                <option key={item.id} value={item.id}>
                  {item.text}
                </option>
              ))}
            </SelectInput>
          </div>
          {overrideReason === 'SN' && (
            <div className={styles.textarea}>
              <TextArea
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                  setOverrideExplanation(e.currentTarget.value);
                }}
                value={overrideExplanation}
                title={t('OVERRIDE_REASON_NOTE')}
                name="notepad"
                maxLength={300}
                errorMessage={explanationError}
                hasError={submitPressed && explanationError.length > 0}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Override;
