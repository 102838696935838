import { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import { ExactTheme } from 'styles/theme';
import { ThemeUICSSObject, useThemeUI } from 'theme-ui';
import { IconSize } from '../icon/Icon';
import Spinner from '../spinner/Spinner';

export type ButtonProps = {
  id: string;
  color?: ButtonColor;
  variant?: ButtonVariant;
  isIconButton?: boolean;
  loading?: boolean;
  fill?: boolean;
  minWidth?: string;
  role?: string;
  sxOverride?: ThemeUICSSObject;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export type ButtonVariant = 'default' | 'simple' | 'outline';
export type ButtonColor = 'orange' | 'blue';
type ButtonState = 'default' | 'disabled' | 'interacting';

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
  children,
  id,
  variant = 'default',
  color = 'orange',
  minWidth,
  type = 'button',
  onFocus,
  onBlur,
  isIconButton,
  fill,
  onClick,
  loading = false,
  disabled = false,
  role = 'button',
  sxOverride,
  ...props
}) => {
  const ctx = useThemeUI();
  const theme = ctx.theme as ExactTheme;

  const getBackgroundColor = (state: ButtonState) => {
    if (loading) return theme.colors.grey20;
    if (variant === 'simple') return 'transparent';

    if (disabled && variant === 'default') return theme.colors.grey80;
    if (disabled) return 'transparent';

    if (variant === 'default') {
      return state === 'default'
        ? theme.colors.brandOrangeWeb
        : theme.colors.extendedOrange100;
    }

    // remaining cases cover 'outline' variant which can be either orange or blue
    if (color === 'orange') {
      return state === 'interacting'
        ? theme.colors.extendedOrange25
        : 'transparent';
    }

    return state === 'interacting'
      ? theme.colors.extendedBlue25
      : 'transparent';
  };

  const getColor = (state: ButtonState) => {
    if (variant === 'default') return 'white';

    if (disabled && (variant === 'outline' || variant === 'simple'))
      return theme.colors.grey80;

    if (variant === 'outline' && color === 'orange') {
      return state === 'default'
        ? theme.colors.brandOrangeWeb
        : theme.colors.extendedOrange100;
    }

    if (variant === 'outline' && color === 'blue') {
      return state === 'default'
        ? theme.colors.brandNavy100
        : theme.colors.extendedBlue100;
    }

    return state === 'default'
      ? theme.colors.extendedBlue100
      : theme.colors.brandNavy100;
  };

  const getBorder = (state: ButtonState) => {
    if (loading) return `1.5px solid ${theme.colors.grey80}`;
    if (variant === 'simple') return '1.5px solid transparent';

    if (disabled && (variant === 'outline' || variant === 'default'))
      return `1.5px solid ${theme.colors.grey80}`;

    if (variant === 'default') {
      return state === 'default'
        ? `1.5px solid ${theme.colors.brandOrangeWeb}`
        : `1.5px solid ${theme.colors.extendedOrange100}`;
    }

    if (color === 'orange') {
      return state === 'default'
        ? `1.5px solid ${theme.colors.brandOrangeWeb}`
        : `1.5px solid ${theme.colors.extendedOrange100}`;
    }

    return state === 'default'
      ? `1.5px solid ${theme.colors.brandNavy100}`
      : `1.5px solid ${theme.colors.extendedBlue100}`;
  };

  return (
    <button
      {...props}
      id={id}
      data-testid={id}
      type={type}
      disabled={disabled || loading}
      onFocus={onFocus}
      onBlur={onBlur}
      onClick={onClick}
      role={role}
      sx={{
        boxShadow: 'none',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isIconButton ? '0.875rem' : '0.75rem 1rem',
        fontStyle: 'normal',
        lineHeight: '1.5rem',
        height: 'fit-content',
        backgroundColor: getBackgroundColor('default'),
        border: getBorder('default'),
        color: getColor('default'),
        fontSize: variant === 'simple' ? '1.125rem' : '1.1875rem',
        fontWeight: variant === 'simple' ? '400' : '700',
        width: fill ? '100%' : 'unset',
        gap: '0.5rem',
        minWidth,
        svg: {
          color: getColor('default')
        },
        '&:focus-visible': {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: theme.colors.extendedBlue100,
          outlineOffset: '2px'
        },
        '&:focus:not(:focus-visible)': {
          outline: 'none'
        },
        '&:hover, &:active, &:disabled': {
          backgroundColor: getBackgroundColor('interacting'),
          border: getBorder('interacting'),
          color: getColor('interacting'),
          svg: {
            color: getColor('interacting')
          }
        },
        '&:active:not(:disabled)': {
          textDecoration: 'underline'
        },
        '`&:disable': {
          backgroundColor: getBackgroundColor('disabled'),
          border: getBorder('disabled'),
          color: getColor('disabled'),
          svg: {
            color: getColor('disabled')
          }
        },
        ...sxOverride
      }}
    >
      {loading ? (
        <Spinner
          id={`${id}-spinner`}
          isFullScreen={false}
          size={IconSize.SMALL}
          backgroundColor={theme.colors.grey20}
        />
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
