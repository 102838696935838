import { isNull } from 'lodash';
import i18n from 'i18next';
import { formatToCad, maskCurrency } from 'utils';
import {
  PaymentScheduleModel,
  ReportedUnReportedServiceFormDataModel,
  SummaryContributionModel,
  SummaryModel
} from 'interfaces';
import { format, parseISO } from 'date-fns';

export const mapForm105 = (
  data: any,
  notes?: { noteToMember: string | null; noteToOmers: string | null }
) => {
  const existingPaymentSchedules = data?.paymentScheduleModels?.filter(
    (payment: PaymentScheduleModel) => payment?.status === 'O'
  );
  const newPaymentSchedules = data?.paymentScheduleModels?.filter(
    (payment: PaymentScheduleModel) => payment?.status === 'N'
  );

  return {
    employerRcaAmt: data?.contributionModel?.employerRcaAmt
      ? maskCurrency(
          data?.contributionModel.employerRcaAmt?.toFixed(2).toString() ?? '',
          'en'
        )
      : '',
    employerRppAmt: data?.contributionModel?.employerRppAmt
      ? maskCurrency(
          data?.contributionModel.employerRppAmt?.toFixed(2).toString() ?? '',
          'en'
        )
      : '',
    memberRcaAmt: data?.contributionModel?.memberRcaAmt
      ? maskCurrency(
          data?.contributionModel.memberRcaAmt?.toFixed(2).toString() ?? '',
          'en'
        )
      : '',
    memberRppAmt: data?.contributionModel?.memberRppAmt
      ? maskCurrency(
          data?.contributionModel.memberRppAmt?.toFixed(2).toString() ?? '',
          'en'
        )
      : '',
    retroIncludedInd: data?.contributionModel?.retroIncludedInd ?? 'N',
    paymentSchedule:
      existingPaymentSchedules?.map((payment: PaymentScheduleModel) => ({
        epaymentScheduleType: payment?.epaymentScheduleType
          ? payment?.epaymentScheduleType.toString()
          : 'OPT',
        employerAmt: !isNull(payment?.employerAmt)
          ? formatToCad(payment?.employerAmt, i18n.language, false)
          : '',
        originalEmployerAmt: !isNull(payment?.originalEmployerAmt)
          ? formatToCad(payment?.originalEmployerAmt, i18n.language, false)
          : '',
        memberAmt: !isNull(payment?.memberAmt)
          ? formatToCad(payment?.memberAmt, i18n.language, false)
          : '',
        originalMemberAmt: !isNull(payment?.originalMemberAmt)
          ? formatToCad(payment?.originalMemberAmt, i18n.language, false)
          : '',
        adjustmentReason: payment?.adjustmentReason
          ? payment?.adjustmentReason.toString()
          : '',
        effectiveDate: payment?.effectiveDate
          ? format(parseISO(payment?.effectiveDate), 'MM/dd/yyyy')
          : '',
        includedInd: payment?.includedInd ?? 'N',
        adjustedInd: payment?.adjustedInd ?? 'N',
        lineNo: payment?.lineNo,
        originalAdjustmentReason: payment.adjustmentReason || ''
      })) ?? [],
    newPaymentSchedule:
      newPaymentSchedules?.map((payment: PaymentScheduleModel) => ({
        epaymentScheduleType: payment?.epaymentScheduleType
          ? payment?.epaymentScheduleType.toString()
          : '',
        employerAmt: !isNull(payment?.employerAmt)
          ? formatToCad(payment?.employerAmt, i18n.language, false)
          : '',
        originalEmployerAmt: !isNull(payment?.originalEmployerAmt)
          ? formatToCad(payment?.originalEmployerAmt, i18n.language, false)
          : '',
        memberAmt: !isNull(payment?.memberAmt)
          ? formatToCad(payment?.memberAmt, i18n.language, false)
          : '',
        originalMemberAmt: !isNull(payment?.originalMemberAmt)
          ? formatToCad(payment?.originalMemberAmt, i18n.language, false)
          : '',
        adjustmentReason: payment?.adjustmentReason
          ? payment?.adjustmentReason.toString()
          : '',
        effectiveDate: payment?.effectiveDate
          ? format(parseISO(payment?.effectiveDate), 'MM/dd/yyyy')
          : '',
        includedInd: payment?.includedInd ?? 'N',
        adjustedInd: payment?.adjustedInd ?? 'N',
        lineNo: payment?.lineNo
      })) ?? [],
    optionalServiceAgreement:
      existingPaymentSchedules?.length > 0 || newPaymentSchedules?.length > 0
        ? 'Y'
        : 'N',
    servicePurchaseReportedModels:
      data?.servicePurchaseReportedModels?.map(
        (service: ReportedUnReportedServiceFormDataModel) => ({
          employerAmt: service?.employerAmt ?? '',
          purchaseAmt: service?.purchaseAmt ?? '',
          memberAmt: service?.memberAmt ?? '',
          memberAmtOriginal: service?.memberAmtOriginal ?? '',
          purchaseNo: service?.purchaseNo ?? '',
          purchaseSourceCode: service?.purchaseSourceCode ?? null,
          purchaseSubSourceCode: service?.purchaseSubSourceCode ?? null,
          firstName: service?.firstName ?? '',
          lastName: service?.lastName ?? '',
          middleName: service?.middleName ?? '',
          sin: service?.sin ?? '',
          employeeId: service?.employeeId ?? '',
          adjustedInd: service?.adjustedInd ?? 'N',
          lineNo: service?.lineNo ?? null,
          selectedInd: 'N',
          membershipNo: service?.membershipNo ?? null,
          employmentNo: service?.employmentNo ?? null
        })
      ) ?? [],
    servicePurchaseUnreportedModels:
      data?.servicePurchaseUnreportedModels?.map(
        (service: ReportedUnReportedServiceFormDataModel) => ({
          employerAmt: service?.employerAmt ?? '',
          purchaseAmt: service?.purchaseAmt ?? '',
          memberAmt: service?.memberAmt ?? '',
          memberAmtOriginal: service?.memberAmtOriginal ?? '',
          purchaseNo: null,
          purchaseSourceCode: service?.purchaseSourceCode ?? null,
          purchaseSubSourceCode: service?.purchaseSubSourceCode ?? null,
          firstName: service?.firstName ?? '',
          lastName: service?.lastName ?? '',
          middleName: service?.middleName ?? '',
          sin: service?.sin ?? '',
          employeeId: service?.employeeId ?? '',
          adjustedInd: service?.adjustedInd ?? 'N',
          lineNo: service?.lineNo ?? null,
          selectedInd: 'N',
          membershipNo: service?.membershipNo ?? null,
          employmentNo: service?.employmentNo ?? null
        })
      ) ?? [],
    paymentOwingModels: data?.paymentOwingModels ?? [],
    summaryModel: {
      ...data?.summaryModel,
      offsetAmt: data?.contributionModel?.offsetAmt
    },
    summaryContributionModel: {
      paymentMethod: data?.contributionModel?.paymentMethod,
      groupNo: data?.contributionModel?.groupNo,
      bankPaymentDate: data?.contributionModel?.bankPaymentDate,
      totalPaidAmt: data?.contributionModel?.totalPaidAmt,
      company: data?.contributionModel?.company,
      locationNo: data?.contributionModel?.locationNo,
      confirmationNo: data?.contributionModel?.confirmationNo,
      employerRcaAmt: null,
      employerRppAmt: null,
      formMonth: null,
      memberRcaAmt: null,
      memberRppAmt: null,
      offsetAmt: null,
      paymentAmt: null
    },
    showLateRemittanceChargesMsg: data?.showLateRemittanceChargesMsg ?? null,
    noteToMember: notes?.noteToMember ?? '',
    noteToOmers: notes?.noteToOmers ?? '',
    servicePurchaseFilter: {
      typeOfPurchase: '',
      dateReceivedFrom: '',
      dateReceivedTo: '',
      searchByMemberSelect: 'emp-id',
      searchByMemberValue: '',
      isFilterLoading: false,
      isFilterDirty: false
    }
  };
};

export const mapSummaryModel = (data: SummaryModel) => ({
  contributionTotalAmt: !isNull(data?.contributionTotalAmt)
    ? formatToCad(data?.contributionTotalAmt, i18n.language, false)
    : '',
  contributionSuppPlanTotalAmt: !isNull(data?.contributionSuppPlanTotalAmt)
    ? formatToCad(data?.contributionSuppPlanTotalAmt, i18n.language, false)
    : '',
  paymentScheduleTotalAmt: !isNull(data?.paymentScheduleTotalAmt)
    ? formatToCad(data?.paymentScheduleTotalAmt, i18n.language, false)
    : '',
  servicePurchaseTotalAmt: !isNull(data?.servicePurchaseTotalAmt)
    ? formatToCad(data?.servicePurchaseTotalAmt, i18n.language, false)
    : '',
  invoiceTotalAmt: !isNull(data?.invoiceTotalAmt)
    ? formatToCad(data?.invoiceTotalAmt, i18n.language, false)
    : '',
  invoiceSuppPlanTotalAmt: !isNull(data?.invoiceSuppPlanTotalAmt)
    ? formatToCad(data?.invoiceSuppPlanTotalAmt, i18n.language, false)
    : '',
  offsetAmt: !isNull(data?.offsetAmt)
    ? formatToCad(data?.offsetAmt, i18n.language, false)
    : '',
  formMonthTotalAmt: !isNull(data?.formMonthTotalAmt)
    ? formatToCad(data?.formMonthTotalAmt, i18n.language, false)
    : '',
  adjustedInvoiceTotalAmt: data?.invoiceTotalAmt
    ? data?.offsetAmt
      ? formatToCad(
          data.invoiceTotalAmt - data?.offsetAmt,
          i18n.language,
          false
        )
      : formatToCad(data.invoiceTotalAmt, i18n.language, false)
    : '0.00'
});

export const mapSummaryContributionModel = (
  data: SummaryContributionModel,
  employerName: string
) => ({
  bankPaymentDate: data?.bankPaymentDate
    ? format(parseISO(data?.bankPaymentDate), 'MM/dd/yyyy')
    : '',
  company: !isNull(data?.company)
    ? data?.company
    : employerName?.substring(0, 20),
  confirmationNo: data?.confirmationNo ?? '',
  employerRcaAmt: !isNull(data?.employerRcaAmt)
    ? formatToCad(data?.employerRcaAmt, i18n.language, false)
    : '',
  employerRppAmt: !isNull(data?.employerRppAmt)
    ? formatToCad(data?.employerRppAmt, i18n.language, false)
    : '',
  formMonth: data?.formMonth ?? '2021-01-01',
  groupNo: data?.groupNo ?? '',
  locationNo: data?.locationNo ?? '',
  memberRcaAmt: !isNull(data?.memberRcaAmt)
    ? formatToCad(data?.memberRcaAmt, i18n.language, false)
    : '',
  memberRppAmt: !isNull(data?.memberRppAmt)
    ? formatToCad(data?.memberRppAmt, i18n.language, false)
    : '',
  offsetAmt: !isNull(data?.offsetAmt)
    ? formatToCad(data?.offsetAmt, i18n.language, false)
    : '',
  paymentAmt: !isNull(data?.paymentAmt)
    ? formatToCad(data?.paymentAmt, i18n.language, false)
    : '',
  paymentMethod: data?.paymentMethod ?? 'E',
  totalPaidAmt: !isNull(data?.totalPaidAmt)
    ? formatToCad(data?.totalPaidAmt, i18n.language, false)
    : ''
});
