import { Children, cloneElement, isValidElement } from 'react';
import { Menu as MenuBP, MenuProps as MenuBPProps } from '@blueprintjs/core';

export interface MenuProps extends MenuBPProps {
  onMenuItemClick?: () => void;
  id: string;
}

/**
 * See: https://blueprintjs.com/docs/#core/components/menu.props
 */
const Menu: React.FC<MenuProps> = ({
  onMenuItemClick,
  id,
  children,
  ...props
}) => (
  <MenuBP id={id} {...props}>
    {Children.map<React.ReactNode, React.ReactNode>(children, child => {
      if (isValidElement(child)) {
        return cloneElement(child, {
          // @ts-ignore
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            child.props.onClick?.(e);

            onMenuItemClick?.();
          },
          shouldDismissPopover: true
        });
      }

      return child;
    })}
  </MenuBP>
);

export default Menu;
