import * as React from 'react';
import RcDialog from 'rc-dialog';
import IDialogPropTypes from 'rc-dialog/lib/IDialogPropTypes';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { isFunction } from 'lodash';

import Button from 'components/button/Button';
import { ThemeUIStyleObject } from 'theme-ui';
import { RobotoFlex } from 'styles/theme';

export interface ModalProps extends IDialogPropTypes {
  titleText: string;
  onOk?: any;
  onCancel?: any;
  onClose?: any;
  okText?: string | React.ReactNode;
  cancelText?: string | React.ReactNode;
  notification?: string | React.ReactNode;
  footer?: string | React.ReactNode;
  isCancel?: boolean;
  isOk?: boolean;
  closable?: boolean;
  className?: string;
  okDisabled?: boolean;
  cancelDisabled?: boolean;
  maskClosable?: boolean;
  keyboard?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  sx?: ThemeUIStyleObject;
}
const Modal = ({
  titleText,
  onOk,
  onCancel,
  onClose,
  okText,
  cancelText,
  width = 865,
  footer,
  isCancel = true,
  isOk = true,
  closable = true,
  className,
  isLoading = false,
  okDisabled = false,
  cancelDisabled = false,
  maskClosable = true,
  keyboard = true,
  sx,
  ...props
}: ModalProps) => {
  const { t } = useTranslation();

  const renderFooter = (
    <>
      {isCancel && (
        <Button
          onClick={(e: any) => {
            if (isFunction(onCancel)) {
              onCancel(e);
            }
          }}
          baseStyle="outlineOrange"
          testingTag="modal-cancel-btn"
          isDisabled={cancelDisabled}
        >
          {cancelText || t('MODAL_CANCEL_BUTTON')}
        </Button>
      )}
      {isOk && (
        <Button
          onClick={(e: any) => {
            if (isFunction(onOk)) {
              onOk(e);
            }
          }}
          testingTag="modal-ok-btn"
          isDisabled={okDisabled}
          isLoading={isLoading}
        >
          {okText || t('MODAL_OKAY_BUTTON')}
        </Button>
      )}
    </>
  );

  return (
    <RcDialog
      {...props}
      style={{ width, maxHeight: '100%' }}
      className={className}
      title={
        <div
          sx={{
            '& > h2': {
              fontFamily: RobotoFlex.style.fontFamily,
              fontWeight: 'semibold',
              fontSize: 'large',
              margin: '0',
              padding: '0 3rem'
            }
          }}
        >
          <h2> {titleText}</h2>
        </div>
      }
      onClose={e => {
        if (isFunction(onClose)) {
          onClose(e);
        } else if (isFunction(onCancel)) {
          onCancel(e);
        }
      }}
      closeIcon={<ReactSVG src="/images/close.svg" />}
      closable={closable}
      footer={footer || (isOk || isCancel ? renderFooter : null)}
      maskClosable={maskClosable}
      keyboard={keyboard}
      sx={{ ...sx }}
    />
  );
};

export default Modal;
