export enum SPACING_VALUES {
  XSMALL = '0.25rem',
  SMALL = '0.5rem',
  MEDIUM = '1rem',
  LARGE = '1.5rem',
  LARGE_2 = '2rem',
  LARGE_3 = '3rem',
  LARGE_4 = '4rem',
  LARGE_6 = '6rem',
  LARGE_8 = '8rem',
  XLARGE = '16rem'
}

export enum SPACING_DIRECTION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export type SPACING_CONSTANTS = SPACING_VALUES;
export type SPACING_TYPE = SPACING_DIRECTION;

export interface SpacerProps {
  spacingType: SPACING_DIRECTION;
  spacing?: SPACING_CONSTANTS | string;
  id: string;
}

const Spacer = ({
  spacingType = SPACING_DIRECTION.VERTICAL,
  spacing = SPACING_VALUES.MEDIUM,
  id
}: SpacerProps) => (
  <div
    sx={{
      backgroundColor: 'transparent',
      minWidth:
        spacingType === SPACING_DIRECTION.HORIZONTAL ? spacing : undefined,
      height: spacingType === SPACING_DIRECTION.VERTICAL ? spacing : undefined
    }}
    id={id}
  />
);

export default Spacer;
