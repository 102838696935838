import { format, parseISO } from 'date-fns';
import { isNull } from 'lodash';
import i18n from 'i18next';

import { BrokenService, PurchaseCostItem } from 'interfaces';
import { formatToCad } from 'utils';
import { Form165bData } from './form165bReducer';

/**
 * Map incoming api response data to react-hook-form data; isElectView will
 * map active leavePeriod's brokenService and non-isElectView will map
 * main table form view -- redux and api manage source of truth so map will
 * is only to trigger switching between elect view
 */
const mapForm165b = (
  isElectView: boolean,
  data: {
    brokenService?: BrokenService | null;
    purchaseCostList?: Array<PurchaseCostItem> | null;
    notes?: {
      noteToMember: string | null;
      noteToOmers: string | null;
    };
  }
): Form165bData => {
  const { brokenService, purchaseCostList, notes } = data;

  if (isElectView) {
    return {
      fullName: '',
      workPhone: '',
      workEmail: '',
      noteToMember: '',
      noteToOmers: '',
      intendToPurchase: brokenService?.purchaseStatus === 'N' ? 'N' : 'Y',
      electionDate: brokenService?.memberElectionDate
        ? format(parseISO(brokenService?.memberElectionDate), 'MM/dd/yyyy')
        : '',
      buyingAllLeave: brokenService?.memberBuyingAllInd ?? 'Y',
      shareCostWithMember: brokenService?.pplSharedCostInd ?? 'N',
      amountForLeave:
        brokenService?.memberPayingAmount &&
        !isNull(brokenService.memberPayingAmount)
          ? formatToCad(brokenService.memberPayingAmount, i18n.language, false)
          : '',
      paymentCheque:
        brokenService?.byChequeAmount && !isNull(brokenService.byChequeAmount)
          ? formatToCad(brokenService.byChequeAmount, i18n.language, false)
          : '',
      paymentRRSP:
        brokenService?.fromRRSPAmount && !isNull(brokenService.fromRRSPAmount)
          ? formatToCad(brokenService.fromRRSPAmount, i18n.language, false)
          : '',
      paymentConfirmAVC: !isNull(brokenService?.fromAvcAmount),
      paymentAVC:
        brokenService?.fromAvcAmount && !isNull(brokenService.fromAvcAmount)
          ? formatToCad(brokenService.fromAvcAmount, i18n.language, false)
          : '',
      paToCra: brokenService?.paReportedInd ?? 'N',
      paReporting:
        purchaseCostList?.map(item => ({
          revisedPA: !isNull(item.reportedPAAmt)
            ? formatToCad(item.reportedPAAmt, i18n.language, false)
            : ''
        })) ?? []
    };
  }

  return {
    fullName: '', // TODO
    workPhone: '', // TODO
    workEmail: '', // TODO
    noteToMember: notes?.noteToMember ?? '',
    noteToOmers: notes?.noteToOmers ?? '',
    intendToPurchase: 'Y',
    electionDate: '',
    buyingAllLeave: 'Y',
    shareCostWithMember: 'N',
    amountForLeave: '',
    paymentCheque: '',
    paymentRRSP: '',
    paymentConfirmAVC: false,
    paymentAVC: '',
    paToCra: 'N',
    paReporting: []
  };
};

export default mapForm165b;
