import useBreakpoint from 'utils/hooks/useBreakpoint';
import { useState } from 'react';
import Button from 'components/v2/atomic/button/Button';
import { useRouter } from 'next/router';
import { logoutAction } from 'containers/auth/userSessionActions';
import Icon from 'components/v2/atomic/icon/Icon';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { MainNavButtonProps } from '../mainNavButton/MainNavButton';
import Header from './Header';
import BottomNavigation from './BottomNavigation';
import BurgerMenu, { BurgerMenuItemType } from './BurgerMenu';

export type NavMenuItem = Omit<
  MainNavButtonProps,
  'buttonColor' | 'buttonText'
> & {
  activeLink?: string;
  buttonText: string | JSX.Element;
  showIconInNav?: boolean;
  hideItem?: boolean;
  burgerMenuItemType?: BurgerMenuItemType;
};

interface MainNavProps {
  topLinks?: NavMenuItem[];
  menuItems?: NavMenuItem[];
  showSignInButton?: boolean;
  showSignOutButton?: boolean;
  burgerMenuItems?: NavMenuItem[];
  includeWrapper?: boolean;
  stickyTopBar?: boolean;
  bottomNavBGColor?: string;
}

const MainNav = ({
  topLinks,
  menuItems,
  burgerMenuItems,
  showSignInButton = true,
  showSignOutButton = false,
  includeWrapper = true,
  stickyTopBar = false,
  bottomNavBGColor
}: MainNavProps) => {
  const breakpoint = useBreakpoint();
  const Router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation('header');
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);

  return (
    <>
      <Header
        isSticky={stickyTopBar}
        includeWrapper={includeWrapper}
        headerItems={topLinks}
        showSignInButton={showSignInButton}
        setIsBurgerMenuOpen={setIsBurgerMenuOpen}
      />

      {!breakpoint.isLessThanMediumMin && (
        <BottomNavigation
          backgroundColor={bottomNavBGColor}
          includeWrapper={includeWrapper}
          navigationItems={menuItems}
        />
      )}

      <BurgerMenu
        burgerMenuItems={burgerMenuItems ?? []}
        isBurgerMenuOpen={isBurgerMenuOpen}
        setIsBurgerMenuOpen={setIsBurgerMenuOpen}
        footerButton={
          <>
            {showSignInButton && (
              <Button
                id="main-nav-signin"
                sx={{ width: ['fit-content', '100%', null] }}
                onClick={() => {
                  Router.push('/');
                  setIsBurgerMenuOpen(false);
                }}
              >
                {t('SIGN_IN_TO_EACCESS')}
              </Button>
            )}

            {showSignOutButton && (
              <Button
                id="main-nav-signout"
                sx={{ width: '100%' }}
                onClick={() => {
                  dispatch(logoutAction());
                  setIsBurgerMenuOpen(false);
                }}
              >
                <Icon icon={IconNamesSmall.LOG_OUT} />
                {t('SIGN_OUT_OF_EACCESS')}
              </Button>
            )}
          </>
        }
      />
    </>
  );
};

export default MainNav;
