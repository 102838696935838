// DEPRECATED: Utilize `./formActions` and `./formSaga` for common functionality instead
/* eslint-disable consistent-return */
import { put, select, call, delay } from 'redux-saga/effects';
import Router from 'next/router';

import { EapAction, RootState } from 'types';
import { deleteForm, lockForm, unlockForm } from 'services';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  clearNotifications,
  notifyErrorAction,
  clearLockedFormNotificationAction
} from 'containers/notifications/notificationsActions';
import { getApiErrorMessage } from 'utils';
import { safeAuthEffect } from 'store/sagas/sagaUtils';

/**
 * Generic delete form saga; takes given requestNumber as main action data,
 * and fires delete success action, or on fail fires failed action
 */
export function* deleteFormSaga(
  action: EapAction,
  formActions: {
    deleteSuccessAction: EapAction;
    deleteFailedAction: EapAction;
  }
): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, deleteForm, action.data);

  if (res.status === 200 || res.status === 204) {
    yield put(formActions.deleteSuccessAction);
    yield put(clearNotifications());
    yield call(Router.push, '/requests');
  } else {
    yield put(formActions.deleteFailedAction);
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

/**
 * Generic lock form saga; locks given form by requestNumber
 */
export function* lockFormSaga(action: EapAction): any {
  if (action.data) {
    // lock form if not omers admin user
    const { omersUser } = yield select(
      (state: RootState) => state.userSession.userData
    );

    if (!omersUser) {
      return yield call(lockForm, action.data);
    }
  }
}

/**
 * Generic unlock form saga; unlocks given form by requestNumber provided as
 * action data
 */
export function* unlockFormSaga(action: EapAction): any {
  if (action.data) {
    yield call(unlockForm, action.data);
  }
}

/**
 * Generic logic to clear potentially visible locked form notification after
 * delay; make sure to place at end of saga to prevent delay from freezing logic
 */
export function* clearLockedFormNotificationSaga() {
  yield delay(15000);
  yield put(clearLockedFormNotificationAction());
}
