import { useState, useEffect } from 'react';
import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { ReactSVG } from 'react-svg';

import { RootState } from 'types';
import { setNavBackBtnFallbackRouteAction } from 'containers/navHistory/navHistoryActions';
import Button from 'components/button/Button';
import styles from './NavBackButton.module.scss';

/**
 * TODO: Update component library UtilityButton to support alternative text;
 * currently using variant 'exit' forces text "Exit" and does not allow different text
 * https://dev-component-library.non-prod-pen-gcp.omers.com/?path=/story/utilitybutton--button
 */
interface NavBackButtonProps {
  btnText?: string | React.ReactElement;
  iconSrc?: string;
  callback?: Function;
  useCallBackFunction?: boolean;
  testingTag?: string;
}
const NavBackButton = ({
  btnText,
  iconSrc = '/images/back-arrow.svg',
  callback,
  useCallBackFunction = true,
  testingTag
}: NavBackButtonProps) => {
  const dispatch = useDispatch();
  const Router = useRouter();
  const { navBackBtnFallbackRoute, history } = useSelector(
    (state: RootState) => state.navHistory
  );

  // track fallback with defaulted useState to prevent redux reducer update
  // from changing while back btn exists in the dom
  const [fallback] = useState<string | null>(navBackBtnFallbackRoute);

  // track where back button intended to navigate to but potentially failed;
  // this is intended to capture when RouteConfirm has cancelled and replace the
  // Router.back() with a direct navigation to this failed path because calling
  // back() after a failed attempt will modify the window history
  const [failedRoute, setFailedRoute] = useState<string | null>(null);

  useEffect(() => {
    const routeChangeStart = (url: string) => setFailedRoute(url);
    Router.events.on('routeChangeStart', routeChangeStart);

    return () => {
      // clear fallback route on component destroy
      dispatch(setNavBackBtnFallbackRouteAction(null));

      Router.events.off('routeChangeStart', routeChangeStart);
    };
  }, []);

  return (
    <div className={styles.navBackButton}>
      <Button
        baseStyle="basic"
        testingTag={testingTag}
        autoTestingTag={testingTag}
        onClick={() => {
          if (useCallBackFunction && callback) {
            // prioritize provided callback
            callback();
          } else if (window.history.length > 1) {
            // navigate back in history if not new tab; or navigate to last failed
            // route captured by RouteConfirm cancelling last back button press
            if (!failedRoute) {
              if (
                history.length > 2 &&
                (history[history.length - 2] === '/form/102' ||
                  history[history.length - 2] === '/messages/new')
              ) {
                Router.back();
              }
              Router.back();
            } else {
              Router.push(failedRoute);
            }
          } else if (fallback) {
            // navigate to fallback url if no browser history
            Router.push(fallback);
          } else {
            // default to home if no fallback provided
            Router.push('/home');
          }
        }}
      >
        <ReactSVG src={iconSrc} />
        {btnText && <span>{btnText}</span>}
      </Button>
    </div>
  );
};

export default NavBackButton;
