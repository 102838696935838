import {
  all,
  takeLatest,
  takeEvery,
  takeLeading,
  select,
  put,
  delay,
  call
} from 'redux-saga/effects';
import Router from 'next/router';
import { format } from 'url';

import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import { EapAction, RootState } from 'types';
import {
  createForm106,
  getPDF,
  createServicePeriod,
  deleteLatestServicePeriod,
  getForm106,
  upsertForm106,
  submitForm106,
  memberSearch,
  getOriginalStateData
} from 'services';
import {
  lockFormSaga,
  unlockFormSaga,
  deleteFormSaga,
  clearLockedFormNotificationSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  viewPdf,
  downloadPdf,
  getApiErrorMessage,
  featureFlagSelector
} from 'utils';
import {
  clearNotifications,
  notifyErrorAction,
  clearLockedFormNotificationAction,
  notifyLockedFormErrorAction
} from 'containers/notifications/notificationsActions';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import { responseTypes } from 'types/response-type';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import mapForm106 from './mapForm106';
import {
  form106ActionTypes,
  setForm106StepAction,
  getOriginalFormState106SuccessAction
} from './form106Actions';

/**
 * Fetch original state for e106 form
 */
export function* getOriginalStateForm106(): any {
  const { requestNumber } = yield select((state: RootState) => state.form106);
  yield put(startLoadingAction());

  // fetch 106 form latest data
  const res = yield call(safeAuthEffect, getOriginalStateData, requestNumber);

  if (res.data) {
    yield put(getOriginalFormState106SuccessAction(res.data?.e106Model));
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(notifyErrorAction(res.errors)));
  }
  yield put(clearLoadingAction());
}

export const requestNumberSelector106 = (state: any) =>
  state.form106.requestNumber;
export function* createForm106Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm106, action.data);
  if (res.data) {
    // route to newly created
    yield Router.push(
      '/form/106/[id]',
      format({ pathname: `/form/106/${res.data.requestNumber}` })
    );
    yield put(clearLoadingAction());
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
    yield put(clearLoadingAction());
  }
}

export function* createForm106InNewTabSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm106, action.data);
  if (res.data) {
    yield window.open(`/form/106/${res.data.requestNumber}`, '_blank');
    yield put(clearLoadingAction());
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
    yield put(clearLoadingAction());
  }
}

export function* getForm106Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const featureFlags = yield select(featureFlagSelector);
  const { requestNumber, lockForm } = action.data;

  if (lockForm) {
    yield call(lockFormSaga, { ...action, data: requestNumber });
  }

  const isNFTEnabled = isEnabled(featureFlags?.featureFlags, currentFlags.NFT);

  const formRes = yield call(safeAuthEffect, getForm106, requestNumber);
  const memberSearchRes = yield call(safeAuthEffect, memberSearch, {
    omersMembershipNumber: formRes?.data?.e106Model?.membershipNo,
    employerNumber: formRes?.data?.e106Model?.employerNo
  });

  if (formRes.data) {
    yield put({
      type: form106ActionTypes.GET_FORM_106_SUCCEEDED,
      data: {
        employerNo: formRes.data.e106Model.employerNo,
        requestNumber,
        formData: mapForm106(formRes.data),
        exceptions: formRes.data?.e106Model?.exceptions,
        memberInfo:
          memberSearchRes?.data?.memberSearchResult?.memberships[0] ?? null,
        featureFlagEnabled: isNFTEnabled
      }
    });
    yield put(clearLockedFormNotificationAction());
    if (Router.route !== '/form/106/[id]') {
      yield Router.push(
        '/form/106/[id]',
        format({ pathname: `/form/106/${requestNumber}` })
      );
    }
  } else {
    yield put({
      type: form106ActionTypes.GET_FORM_106_FAILED
    });
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(formRes?.errors)));

    // navigate off the request if user is already there
    if (Router.route === '/form/106/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }
  yield put({
    type: form106ActionTypes.SET_FORM_106_STATUS,
    data: ''
  });

  yield put(clearLoadingAction());

  // clear potential locked form notification after delay
  yield call(clearLockedFormNotificationSaga);
}

export function* saveForm106Saga(action: EapAction): any {
  const requestNumber = yield select(requestNumberSelector106);
  const res = yield call(
    safeAuthEffect,
    upsertForm106,
    action.data.formData,
    requestNumber
  );

  if (res.data) {
    yield put({
      type: form106ActionTypes.SAVE_FORM_106_SUCCEEDED
    });
    yield put(clearNotifications());

    if (action.data.isReview) {
      yield put(setForm106StepAction(2));
    }
  } else {
    yield put({
      type: form106ActionTypes.SAVE_FORM_106_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* saveFormAndCreatePeriodSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const requestNumber = yield select(requestNumberSelector106);
  const res = yield call(
    safeAuthEffect,
    upsertForm106,
    action.data,
    requestNumber
  );

  if (res && res.data) {
    const result = yield call(
      safeAuthEffect,
      createServicePeriod,
      requestNumber
    );
    if (result && result.data) {
      yield put({
        type: form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_SUCCEEDED,
        data: requestNumber
      });
      yield put(clearNotifications());
    } else {
      yield put({
        type: form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_FAILED
      });
      yield put(notifyErrorAction(getApiErrorMessage(result?.errors)));
      yield put(clearLoadingAction());
    }
  } else {
    yield put({
      type: form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(clearLoadingAction());
  }
}

export function* postSaveAndCreatePeriodSaga(action: EapAction) {
  const requestNumber = action.data;
  yield put({
    type: form106ActionTypes.GET_FORM_106_REQUESTED,
    data: {
      requestNumber,
      lockForm: false
    }
  });
}

export function* postSaveAndCreatePeriodSagaDelete(action: EapAction) {
  // for deletion, you must set period staus to deleting lastest period, so that PUT request can be made again within UseEffects MemberUpdate
  const requestNumber = action.data;
  yield put({
    type: form106ActionTypes.SET_PERIOD_STATUS,
    data: 'deleting latest period'
  });
  yield put({
    type: form106ActionTypes.GET_FORM_106_REQUESTED,
    data: {
      requestNumber,
      lockForm: false
    }
  });
}

export function* deleteLatestServicePeriodSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    deleteLatestServicePeriod,
    action.data
  );

  if (res.status === 200 || res.status === 204) {
    yield put({
      type: form106ActionTypes.DELETE_LATEST_SERVICE_PERIOD_FORM_106_SUCCEEDED,
      data: action.data
    });
    yield put(clearNotifications());
  } else {
    yield put({
      type: form106ActionTypes.DELETE_LATEST_SERVICE_PERIOD_FROM_106_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(clearLoadingAction());
  }
}

function* deleteForm106Saga(action: EapAction) {
  yield deleteFormSaga(action, {
    deleteSuccessAction: {
      type: form106ActionTypes.DELETE_FORM_106_SUCCEEDED
    },
    deleteFailedAction: {
      type: form106ActionTypes.DELETE_FORM_106_FAILED
    }
  });
}

function* getPDF106Saga(action: EapAction): any {
  const { isView } = action.data;
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getPDF, action.data);
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    if (isView) {
      viewPdf(res, 'e-Form 106: Member Update');
    } else {
      downloadPdf(res, 'e-Form 106: Member Update');
    }
    yield put({
      type: form106ActionTypes.GET_FORM_106_PDF_SUCCEEDED,
      data: res.data
    });
    yield put(clearNotifications());
  } else {
    yield put({
      type: form106ActionTypes.GET_FORM_106_PDF_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* updateAndSubmitForm106Saga(action: EapAction): any {
  yield put(startLoadingAction());
  const requestNumber = yield select(requestNumberSelector106);
  const res = yield call(
    safeAuthEffect,
    upsertForm106,
    action.data,
    requestNumber
  );

  if (res.data) {
    const result = yield call(
      safeAuthEffect,
      submitForm106,
      undefined,
      requestNumber
    );

    if (result.data && result.data.submitResponse) {
      yield put({
        type: form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_SUCCEEDED,
        data: result.data
      });
      yield put(clearNotifications());
    } else {
      yield put({
        type: form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_FAILED
      });
      yield put(notifyErrorAction(getApiErrorMessage(result.errors)));
    }
  } else {
    yield put({
      type: form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

export function* clearStatusSaga() {
  yield delay(3000);
  yield put({
    type: form106ActionTypes.RESET_FORM_106_STATUS
  });
}

export function* clearSubmitStatusSaga() {
  yield delay(3000);
  yield put({
    type: form106ActionTypes.RESET_FORM_106_SUBMIT_STATUS
  });
}

export function* form106Saga(): any {
  yield all([
    yield takeLeading(form106ActionTypes.CREATE_FORM_106, createForm106Saga),
    yield takeLatest(form106ActionTypes.GET_FORM_106_REQUESTED, getForm106Saga),
    yield takeLatest(
      form106ActionTypes.GET_ORIGINAL_FORM_STATE_106,
      getOriginalStateForm106
    ),
    yield takeLatest(
      form106ActionTypes.DELETE_FORM_106_REQUESTED,
      deleteForm106Saga
    ),
    yield takeEvery(
      form106ActionTypes.SAVE_FORM_106_REQUESTED,
      saveForm106Saga
    ),
    yield takeEvery(
      form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_REQUESTED,
      saveFormAndCreatePeriodSaga
    ),
    yield takeEvery(
      form106ActionTypes.DELETE_LATEST_SERVICE_PERIOD_FORM_106_REQUESTED,
      deleteLatestServicePeriodSaga
    ),
    yield takeLatest(
      form106ActionTypes.GET_FORM_106_PDF_REQUESTED,
      getPDF106Saga
    ),
    yield takeLatest(
      [form106ActionTypes.SAVE_FORM_AND_CREATE_PERIOD_106_SUCCEEDED],
      postSaveAndCreatePeriodSaga
    ),
    yield takeLatest(
      [form106ActionTypes.DELETE_LATEST_SERVICE_PERIOD_FORM_106_SUCCEEDED],
      postSaveAndCreatePeriodSagaDelete
    ),
    yield takeLatest(
      form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_REQUESTED,
      updateAndSubmitForm106Saga
    ),
    yield takeLatest(
      [
        form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_SUCCEEDED,
        form106ActionTypes.UPDATE_AND_SUBMIT_FORM_106_FAILED
      ],
      clearSubmitStatusSaga
    ),
    yield takeLatest(
      [
        form106ActionTypes.SAVE_FORM_106_SUCCEEDED,
        form106ActionTypes.SAVE_FORM_106_FAILED
      ],
      clearStatusSaga
    ),
    yield takeLatest(
      [form106ActionTypes.CLEAR_FORM_106, form106ActionTypes.UNLOCK_FORM_106],
      unlockFormSaga
    ),
    yield takeLatest(
      [form106ActionTypes.CREATE_FORM_106_IN_NEW_TAB],
      createForm106InNewTabSaga
    )
  ]);
}
