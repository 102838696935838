import { Popover2, Popover2Props } from '@blueprintjs/popover2';
import { ThemeUIStyleObject } from 'theme-ui';

export interface PopoverProps extends Popover2Props {
  width?: string;
  maxHeight?: string;
  containerPadding?: string;
  sxOverride?: ThemeUIStyleObject;
}

const Popover: React.FC<PopoverProps> = ({
  placement = 'auto',
  content,
  interactionKind = 'click',
  width = '350px',
  maxHeight,
  containerPadding,
  children,
  disabled,
  sxOverride,
  ...props
}) => (
  <Popover2
    {...props}
    content={
      <div
        sx={{
          width,
          maxHeight,
          overflowY: maxHeight ? 'auto' : undefined,
          padding: containerPadding ?? '24px',
          lineHeight: 'small',
          overflowX: 'auto'
        }}
      >
        {content}
      </div>
    }
    placement={placement}
    interactionKind={interactionKind}
    sx={{ ...sxOverride }}
    disabled={disabled}
  >
    {children}
  </Popover2>
);

export default Popover;
