import { ActionCreator } from 'redux';

import { EapAction } from 'types';

export enum idleActionActionTypes {
  SET_IDLE_ACTION = 'SET_IDLE_ACTION',
  RUN_IDLE_ACTION = 'RUN_IDLE_ACTION',
  CLEAR_IDLE_ACTION = 'CLEAR_IDLE_ACTION'
}

// set an idle EapAction action for future potential action triggering
export const setIdleAction: ActionCreator<EapAction> = (data: EapAction) => ({
  type: idleActionActionTypes.SET_IDLE_ACTION,
  data
});

// run idle EapAction action and remove it
export const runIdleAction: ActionCreator<EapAction> = () => ({
  type: idleActionActionTypes.RUN_IDLE_ACTION
});

// clear idle EapAction action
export const clearIdleAction: ActionCreator<EapAction> = () => ({
  type: idleActionActionTypes.CLEAR_IDLE_ACTION
});
