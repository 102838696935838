import { indicators } from 'utils/constants';

export interface IReturnedRequestsMessage {
  categoryCode: string;
  categoryDescription: string;
  lastModifiedDate: string;
  memberFirstName: string;
  memberLastName: string;
  memberEmployeeId: string;
  subject: 'Returned e-form';
  readInd: indicators;
  resolvedInd: indicators;
  waitingInd: indicators;
  correspondenceNo: string;
  memberMembershipNumber: string;
  memberEmployerNumber: string;
}

export enum returnedRequestsTopicCategories {
  CORRESPONDENCE_CATEGORIES = 'Correspondence Categories'
}

export enum apiResponse {
  NO_DATA_FOUND = 'No data found'
}

export interface IReturnedRequestsButtonStatus {
  isResetButtonDisabled: boolean;
  isApplyButtonDisabled: boolean;
  isApplyButtonClicked: boolean;
}

export interface IReturnedRequestsSearchPayload {
  employerNo: string;
  categoryCode: string | null;
  status: string | null;
  sin: null;
  employeeId: string | null;
  fromDate: null;
  toDate: null;
  formReturnedInd: 'Y' | 'N';
}
export interface IMessageThread {
  firstName: string;
  lastName: string;
  date: string;
  message: string;
}
export interface IMessageError {
  showError: boolean;
  message: string;
}
