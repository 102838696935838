import { EapAction } from 'types';
import {
  batchProcessingTabIds,
  IReviewImports,
  FilingType,
  IReviewImportErrors,
  uploadError,
  IReviewedImports,
  reviewErrorStatus,
  IDataToBatchSubmit,
  ISubmittedBatchData,
  formTypes,
  ISelectedBatchIds,
  IBatchIdSearchResults,
  IBatchFilter
} from '../types';
import { batchProcessingActionTypes } from './batchProcessingActions';

export interface BatchProcessingState {
  activeTab: batchProcessingTabIds;
  reviewImports: IReviewImports[];
  selectedFileType: FilingType | string;
  filePendingUpload: any;
  reconciliationYears: any;
  selectedReconciliationYear: any;
  showDisplayFileInput: boolean;
  isConfirmImportModal: boolean;
  isSuccessfulImportModal: boolean;
  hasReconciliationYears: boolean;
  currentBatchProcessNo: string;
  currentForm: formTypes | null;
  showFilteredBatchTable: boolean;
  selectedFilteredFilingType: formTypes | null;
  batchIdSearchResults: IBatchIdSearchResults;
  selectedBatchIds: ISelectedBatchIds;
  batchSubmitModals: {
    showForm119RetroModal: boolean;
    showConfirmationModal: boolean;
    showBatchSubmittedModal: boolean;
    filingTypeToBeSubmitted: IDataToBatchSubmit;
  };
  reviewImportErrors: {
    showReviewErrors: boolean;
    errors: IReviewImportErrors[];
    reviewStatus: reviewErrorStatus;
    showErrorsMaxNumberWarning: boolean;
    reviewedImports: IReviewedImports[];
    hasAlreadyBeenImported: boolean;
    importErrors: any[];
  };
  uploadError: uploadError;
  cancelImports: {
    showCancelImportsModal: boolean;
    isCancelImportsSucceeded: boolean;
  };
  reviewSubmission: {
    submittedBatchData: ISubmittedBatchData[];
    showPreProcessingBatchSubmitNotification: boolean;
  };
  dataToBatchSubmit: IDataToBatchSubmit[];
  filter: IBatchFilter;
  applyButtonIsDisabled: boolean;
  importsLoading: boolean;
  batchSubmitLoading: boolean;
  reviewBatchSubmitLoading: boolean;
  reviewBatchSubmitFilterLoading: boolean;
}

const initialState = {
  activeTab: batchProcessingTabIds.IMPORT_FILES,
  reviewImports: [],
  selectedFileType: '',
  filePendingUpload: {},
  reconciliationYears: [],
  selectedReconciliationYear: '',
  showDisplayFileInput: false,
  isConfirmImportModal: false,
  isSuccessfulImportModal: false,
  hasReconciliationYears: false,
  currentBatchProcessNo: '',
  currentForm: null,
  showFilteredBatchTable: false,
  selectedFilteredFilingType: null,
  selectedBatchIds: {},
  batchIdSearchResults: {
    requests: []
  },
  batchSubmitModals: {
    showForm119RetroModal: false,
    showConfirmationModal: false,
    showBatchSubmittedModal: false,
    filingTypeToBeSubmitted: {
      type: null,
      totalRecordsNo: null
    }
  },
  reviewImportErrors: {
    showReviewErrors: false,
    errors: [],
    showErrorsMaxNumberWarning: false,
    reviewedImports: [],
    reviewStatus: reviewErrorStatus.TO_REVIEW,
    hasAlreadyBeenImported: false,
    importErrors: []
  },
  uploadError: {
    showError: false,
    message: ''
  },
  cancelImports: {
    showCancelImportsModal: false,
    isCancelImportsSucceeded: false
  },
  reviewSubmission: {
    submittedBatchData: [],
    showPreProcessingBatchSubmitNotification: false
  },
  dataToBatchSubmit: [],
  filter: {
    dateImported: 'All Time',
    employmentStatus: 'All',
    importedBy: 'All',
    nameRangeA: 'A',
    nameRangeZ: 'Z',
    normalRetirementAge: 'All'
  },
  applyButtonIsDisabled: true,
  importsLoading: false,
  batchSubmitLoading: false,
  reviewBatchSubmitLoading: false,
  reviewBatchSubmitFilterLoading: false
};

const batchProcessing = (
  state: BatchProcessingState = initialState,
  action: EapAction
): BatchProcessingState => {
  const { type, data } = action;
  switch (type) {
    case batchProcessingActionTypes.SET_BATCH_PROCESSING_ACTIVE_TAB:
      return {
        ...initialState,
        activeTab: data,
        hasReconciliationYears: state.hasReconciliationYears,
        reviewImportErrors: {
          ...initialState.reviewImportErrors,
          reviewedImports: state.reviewImportErrors.reviewedImports
        },
        selectedBatchIds: {}
      };
    case batchProcessingActionTypes.GET_IMPORTS_REQUESTED:
      return {
        ...state,
        importsLoading: true
      };
    case batchProcessingActionTypes.GET_IMPORTS_SUCCEEDED:
      return {
        ...state,
        reviewImports: data,
        importsLoading: false
      };
    case batchProcessingActionTypes.GET_IMPORTS_FAILED:
      return {
        ...state,
        importsLoading: false
      };
    case 'SET_SELECT_FILE_TYPE':
      return {
        ...state,
        selectedFileType: data
      };
    case 'ADD_FILE_TO_BATCH_PROCESS_PENDING_UPLOAD':
      return {
        ...state,
        filePendingUpload: data
      };
    case 'CLEAR_FILE_PENDING_UPLOAD':
      return {
        ...state,
        filePendingUpload: {}
      };
    case 'GET_RECONCILIATION_YEARS_FOR_GROUP_SUCCEEDED':
      return {
        ...state,
        reconciliationYears: data
      };
    case 'SET_RECONCILIATION_YEARS':
      return {
        ...state,
        selectedReconciliationYear: data
      };
    case 'SET_HAS_RECONCILIATION_YEARS':
      return {
        ...state,
        hasReconciliationYears: data
      };
    case 'CLEAR_RECONCILIATION_YEARS':
      return {
        ...state,
        reconciliationYears: [],
        selectedReconciliationYear: ''
      };
    case 'SET_DISPLAY_FILE_INPUT':
      return {
        ...state,
        showDisplayFileInput: data
      };
    case 'SET_IS_CONFIRM_IMPORT_MODAL':
      return {
        ...state,
        isConfirmImportModal: data
      };
    case 'SET_IS_SUCCESSFUL_IMPORT_MODAL':
      return {
        ...state,
        isSuccessfulImportModal: data
      };
    case batchProcessingActionTypes.GET_IMPORT_ERRORS_REQUESTED:
      return {
        ...state,
        currentBatchProcessNo: data.batchProcessNo,
        currentForm: data.formToReview,
        reviewImportErrors: {
          ...state.reviewImportErrors
        }
      };
    case batchProcessingActionTypes.GET_IMPORT_ERRORS_SUCCEEDED:
      return {
        ...state,
        reviewImportErrors: {
          ...state.reviewImportErrors,
          showReviewErrors: true,
          errors: data.errors,
          showErrorsMaxNumberWarning: data.showErrorsMaxNumberWarning,
          reviewStatus: data.reviewStatus,
          hasAlreadyBeenImported: data.hasAlreadyBeenImported,
          importErrors: data.importErrors
        }
      };
    case batchProcessingActionTypes.GO_BACK_TO_VIEW_IMPORTS:
      return {
        ...state,
        reviewImportErrors: {
          ...initialState.reviewImportErrors,
          reviewedImports: state.reviewImportErrors.reviewedImports
        }
      };
    case batchProcessingActionTypes.BATCH_PROCESS_UPLOAD_FAILED:
      return {
        ...state,
        uploadError: {
          showError: true,
          message: data
        }
      };
    case batchProcessingActionTypes.SET_IMPORT_ERROR:
      return {
        ...state,
        uploadError: {
          ...data
        }
      };
    case batchProcessingActionTypes.COMPLETE_REVIEW_IMPORT_ERRORS_SUCCEEDED:
      return {
        ...state,
        reviewImportErrors: {
          ...state.reviewImportErrors,
          reviewStatus: reviewErrorStatus.PENDING,
          reviewedImports: [...data]
        }
      };
    case batchProcessingActionTypes.SHOW_CANCEL_IMPORTS_MODAL:
      return {
        ...state,
        currentBatchProcessNo: data.currentBatchProcessNo,
        currentForm: data.currentForm,
        cancelImports: {
          ...state.cancelImports,
          showCancelImportsModal: data.showCancelImportsModal
        }
      };
    case batchProcessingActionTypes.SHOW_CANCEL_IMPORTS_SUCCEEDED_NOTIFICATION: {
      return {
        ...state,
        cancelImports: {
          ...state.cancelImports,
          isCancelImportsSucceeded: true
        }
      };
    }
    case batchProcessingActionTypes.SET_BATCH_SUBMIT_FORM_119_RETRO_MODAL: {
      return {
        ...state,
        batchSubmitModals: {
          ...state.batchSubmitModals,
          showForm119RetroModal: data
        }
      };
    }
    case batchProcessingActionTypes.SET_BATCH_SUBMIT_CONFIRMATION_MODAL: {
      return {
        ...state,
        batchSubmitModals: {
          ...state.batchSubmitModals,
          showConfirmationModal: data
        }
      };
    }
    case batchProcessingActionTypes.SET_BATCH_SUBMITTED_MODAL: {
      return {
        ...state,
        batchSubmitModals: {
          ...state.batchSubmitModals,
          showBatchSubmittedModal: data
        }
      };
    }
    case batchProcessingActionTypes.SET_FILING_TYPE_TO_BE_SUBMITTED: {
      return {
        ...state,
        batchSubmitModals: {
          ...state.batchSubmitModals,
          filingTypeToBeSubmitted: data
        }
      };
    }
    case batchProcessingActionTypes.BATCH_SUBMIT_SUCCEEDED: {
      return {
        ...state,
        batchSubmitModals: {
          ...state.batchSubmitModals,
          showBatchSubmittedModal: true
        }
      };
    }
    case batchProcessingActionTypes.REVIEW_SUBMISSION_REQUESTED: {
      return {
        ...state,
        reviewBatchSubmitLoading: true
      };
    }
    case batchProcessingActionTypes.REVIEW_SUBMISSION_SUCCEEDED: {
      return {
        ...state,
        reviewSubmission: {
          ...state.reviewSubmission,
          submittedBatchData: data
        },
        reviewBatchSubmitLoading: false
      };
    }
    case batchProcessingActionTypes.REVIEW_SUBMISSION_FAILED: {
      return {
        ...state,
        reviewBatchSubmitLoading: false
      };
    }
    case batchProcessingActionTypes.GET_DATA_TO_BATCH_SUBMIT_REQUESTED: {
      return {
        ...state,
        batchSubmitLoading: true
      };
    }
    case batchProcessingActionTypes.GET_DATA_TO_BATCH_SUBMIT_SUCCEEDED: {
      return {
        ...state,
        dataToBatchSubmit: data,
        batchSubmitLoading: false
      };
    }
    case batchProcessingActionTypes.GET_DATA_TO_BATCH_SUBMIT_FAILED: {
      return {
        ...state,
        batchSubmitLoading: false
      };
    }
    case batchProcessingActionTypes.SET_SHOW_FILTERED_BATCH_TABLE: {
      return {
        ...state,
        showFilteredBatchTable: data
      };
    }
    case batchProcessingActionTypes.SET_SELECTED_FILTERED_FILING_TYPE: {
      return {
        ...state,
        selectedFilteredFilingType: data
      };
    }
    case batchProcessingActionTypes.SET_BATCH_ID_SEARCH_RESULTS: {
      return {
        ...state,
        batchIdSearchResults: data
      };
    }
    case batchProcessingActionTypes.RESET_BATCH_ID_SEARCH_RESULTS: {
      return {
        ...state,
        batchIdSearchResults: {
          ...initialState.batchIdSearchResults
        }
      };
    }
    case batchProcessingActionTypes.SET_SELECTED_BATCH_ID: {
      return {
        ...state,
        selectedBatchIds: data
      };
    }
    case batchProcessingActionTypes.SET_BATCH_PROCESSING_FILTER: {
      return {
        ...state,
        filter: {
          ...state.filter,
          ...data
        }
      };
    }
    case batchProcessingActionTypes.RESET_BATCH_PROCESSING_FILTER: {
      return {
        ...state,
        filter: {
          ...initialState.filter
        }
      };
    }
    case batchProcessingActionTypes.SET_APPLY_BUTTON_IS_DISABLED: {
      return {
        ...state,
        applyButtonIsDisabled: data
      };
    }
    case batchProcessingActionTypes.CHECK_BATCH_SUBMISSION_PRE_PROCESSING_STATUS: {
      return {
        ...state,
        reviewSubmission: {
          ...state.reviewSubmission,
          showPreProcessingBatchSubmitNotification: data
        }
      };
    }
    case batchProcessingActionTypes.SEARCH_BATCH_IDS_REQUESTED: {
      return {
        ...state,
        reviewBatchSubmitFilterLoading: true
      };
    }
    case batchProcessingActionTypes.SEARCH_BATCH_IDS_SUCCEEDED:
    case batchProcessingActionTypes.SEARCH_BATCH_IDS_FAILED: {
      return {
        ...state,
        reviewBatchSubmitFilterLoading: false
      };
    }
    default:
      return state;
  }
};

export default batchProcessing;
