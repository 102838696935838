/* eslint-disable @typescript-eslint/no-unused-vars */
import { ActionCreator } from 'redux';

import { systemErrorNotificationAction } from 'containers/notifications/notificationsActions';
import { LoginForm, EapAction, SysDateObj } from 'types';
import { JWTUser } from 'utils/ping/types';

export enum userSessionActionTypes {
  LOGIN_REQUESTED = 'LOGIN_REQUESTED',
  LOGIN_SUCCEEDED = 'LOGIN_SUCCEEDED',
  LOGIN_FAILED = 'LOGIN_FAILED',
  LOGOUT_REQUESTED = 'LOGOUT_REQUESTED',
  LOGOUT_FORCED_REQUESTED = 'LOGOUT_FORCED_REQUESTED',
  LOGOUT_SUCCEEDED_FAILED = 'LOGOUT_SUCCEEDED_FAILED',
  LOGOUT_NOTIFICATION_HIDE = 'LOGOUT_NOTIFICATION_HIDE',
  SESSION_REFRESHED = 'SESSION_REFRESHED',
  SHOW_SESSION_REFRESH_MODAL = 'SHOW_SESSION_REFRESH_MODAL',
  HIDE_SESSION_REFRESH_MODAL = 'HIDE_SESSION_REFRESH_MODAL',
  SHOW_TOKEN_EXPIRY_TIMER = 'SHOW_TOKEN_EXPIRY_TIMER',
  HIDE_TOKEN_EXPIRY_TIMER = 'HIDE_TOKEN_EXPIRY_TIMER',
  SET_TOKEN_EXPIRY_TIMER_VISIBILITY = 'SET_TOKEN_EXPIRY_TIMER_VISIBILITY',
  SET_SESSION_EXPIRY = 'SET_SESSION_EXPIRY',
  START_LOADING = 'START_LOADING',
  CLEAR_LOADING = 'CLEAR_LOADING',
  SET_SHOW_EMAIL_NOTIFICATION_ROW = 'SET_SHOW_EMAIL_NOTIFICATION_ROW',
  SET_SHOW_EMAIL_NOTIFICATION_MODAL = 'SET_SHOW_EMAIL_NOTIFICATION_MODAL',
  SET_SHOW_EMAIL_NOTIFICATION_SPINNER = 'SET_SHOW_EMAIL_NOTIFICATION_SPINNER',
  SET_E_CORRESPONDENCE_OPT_OUT = 'SET_E_CORRESPONDENCE_OPT_OUT',
  E_CORRESPONDENCE_OPT_OUT_REQUESTED = 'E_CORRESPONDENCE_OPT_OUT_REQUESTED',
  E_CORRESPONDENCE_OPT_OUT_FAILED = 'E_CORRESPONDENCE_OPT_OUT_FAILED',
  E_CORRESPONDENCE_OPT_OUT_SUCCEEDED = 'E_CORRESPONDENCE_OPT_OUT_SUCCEEDED',
  INTERNAL_LOGIN_REQUESTED = 'INTERNAL_LOGIN_REQUESTED',
  SET_PASSWORD_EXPIRED_FLAG = 'SET_PASSWORD_EXPIRED_FLAG',
  SET_USERNAME_FOR_RESET_EXPIRED_PASSWORD = 'SET_USERNAME_FOR_RESET_EXPIRED_PASSWORD',
  SET_SYS_DATE = 'SET_SYS_DATE'
}

export const systemErrorAction: ActionCreator<EapAction> = (error: string) => {
  if (process.env.NEXT_PUBLIC_APP_ENV !== 'prod') {
    // eslint-disable-next-line no-console
    console.error({
      type: 'systemErrorAction',
      error
    });
  }
  return systemErrorNotificationAction();
};

export const loginAction = (data: LoginForm) => ({
  type: userSessionActionTypes.LOGIN_REQUESTED,
  data
});

export const loginSucceededAction = (data: {
  sri?: string;
  userProfile?: JWTUser;
  accessToken?: string;
}) => ({
  type: userSessionActionTypes.LOGIN_SUCCEEDED,
  data
});

export const loginFailedAction = () => ({
  type: userSessionActionTypes.LOGIN_FAILED
});

export const logoutAction = (data?: { logoutNotification: string }) => ({
  type: userSessionActionTypes.LOGOUT_REQUESTED,
  data
});

export const logoutForcedAction: ActionCreator<EapAction> = (data?: {
  logoutNotification: string;
}) => ({
  type: userSessionActionTypes.LOGOUT_FORCED_REQUESTED,
  data
});

export const setSysDateAction: ActionCreator<EapAction> = (data?: {
  sysDate: SysDateObj;
}) => ({
  type: userSessionActionTypes.SET_SYS_DATE,
  data
});

// enable app-wide fullscreen loading spinner
export const startLoadingAction: ActionCreator<EapAction> = () => ({
  type: userSessionActionTypes.START_LOADING
});

// clear app-wide fullscreen loading spinner
export const clearLoadingAction: ActionCreator<EapAction> = () => ({
  type: userSessionActionTypes.CLEAR_LOADING
});

export const clearLogoutNotificationAction: ActionCreator<EapAction> = () => ({
  type: userSessionActionTypes.LOGOUT_NOTIFICATION_HIDE
});

export const setShowEmailNotificationRowAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: userSessionActionTypes.SET_SHOW_EMAIL_NOTIFICATION_ROW,
  data
});

export const setShowEmailNotificationModalAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: userSessionActionTypes.SET_SHOW_EMAIL_NOTIFICATION_MODAL,
  data
});

export const setShowEmailNotificationSpinnerAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: userSessionActionTypes.SET_SHOW_EMAIL_NOTIFICATION_SPINNER,
  data
});

export const setECorrespondenceOptOutAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: userSessionActionTypes.SET_E_CORRESPONDENCE_OPT_OUT,
  data
});

export const eCorrespondenceOptOutAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: userSessionActionTypes.E_CORRESPONDENCE_OPT_OUT_REQUESTED,
  data
});

export const internalUserLoginAction = (data: { authCode: string }) => ({
  type: userSessionActionTypes.INTERNAL_LOGIN_REQUESTED,
  data
});

export const setPasswordExpiredFlagAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: userSessionActionTypes.SET_PASSWORD_EXPIRED_FLAG,
  data
});

export const setUsernameForResetExpiredPasswordAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: userSessionActionTypes.SET_USERNAME_FOR_RESET_EXPIRED_PASSWORD,
  data
});

export const sessionRefreshedAction: ActionCreator<EapAction<boolean>> = (
  makeRequest: boolean = false
) => ({
  type: userSessionActionTypes.SESSION_REFRESHED,
  data: makeRequest
});

export const showSessionRefreshModal: ActionCreator<EapAction> = () => ({
  type: userSessionActionTypes.SHOW_SESSION_REFRESH_MODAL
});

export const hideSessionRefreshModal: ActionCreator<EapAction> = () => ({
  type: userSessionActionTypes.HIDE_SESSION_REFRESH_MODAL
});

export const setTokenExpiryTimerVisibility: ActionCreator<EapAction<
  boolean
>> = (shouldShowTimer: boolean) => ({
  type: userSessionActionTypes.SET_TOKEN_EXPIRY_TIMER_VISIBILITY,
  data: shouldShowTimer
});

export const setSessionExpiry: ActionCreator<EapAction<
  number | null
>> = expiry => ({
  type: userSessionActionTypes.SET_SESSION_EXPIRY,
  data: expiry
});
