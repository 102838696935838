import { RootState } from 'types';

export const tokenSelector = (state: RootState): string =>
  state.userSession.token;

export const selectEmployersSelector = (state: RootState) =>
  state.selectEmployers;

export const isAuthenticated = (state: RootState) =>
  state.userSession.isAuthenticated;

export const selectEmployerNumber = (state: RootState) =>
  state.selectEmployers.selectEmployerNumber;

export const propertiesSelector = (state: RootState) =>
  state.properties.properties;

export const overrideReasonsSelector = (state: RootState) =>
  state.overrideReasons.overrideReasons;

export const featureFlagSelector = (state: RootState) => state.featureFlags;

export const memberEventConfigSelector = (state: RootState) =>
  state.donnaBusinessConfig;

export const returnedRequestsSelector = (state: RootState) =>
  state.returnedRequests;

export const archivedMessagesSelector = (state: RootState) =>
  state.archivedMessages;

export const isTrainingUser = (state: RootState) =>
  state.userSession.userData.training;
