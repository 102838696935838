import {
  PurchaseCostItem,
  LeavePeriod,
  BrokenService,
  Purchased,
  PurchasedPPLID
} from 'interfaces';
import { formatDateUTC, roundNumber } from 'utils';
import { Form165bData } from '../form165bReducer';

/**
 * Calculates user's total cost intending to purchase
 */
export const getElectionTotalCost = (
  paymentCheque: string,
  paymentRRSP: string,
  paymentAVC: string
) => {
  const chequeTotal = parseFloat(
    paymentCheque ? paymentCheque.replace(/,/g, '') : '0'
  );
  const rrspTotal = parseFloat(
    paymentRRSP ? paymentRRSP.replace(/,/g, '') : '0'
  );
  const avcTotal = parseFloat(paymentAVC ? paymentAVC.replace(/,/g, '') : '0');

  return roundNumber(chequeTotal + rrspTotal + avcTotal);
};

/**
 * Calculates user's total remaining cost of purchase cost minus their total intended cost
 */
export const getElectionTotalLeftToPay = (
  buyingAllLeave: 'Y' | 'N',
  purchaseCost: number,
  customCost: string,
  totalAmount: number
) => {
  const defaultToPay = customCost ? customCost.replace(/,/g, '') : '0';
  const totalToPay =
    buyingAllLeave === 'Y' ? purchaseCost : parseFloat(defaultToPay);

  return roundNumber(totalToPay - totalAmount);
};

/**
 * Determines if given elected leave period has incomplete information
 */
export const isElectedLeavePeriodIncomplete = (
  brokenLeavePeriod: {
    brokenService: BrokenService;
    purchased?: Purchased;
    purchasedPPLID?: PurchasedPPLID;
    pplSharedCostVisibleInd?: boolean;
  },
  shareCostWithMember: string | null
) => {
  const {
    memberElectionDate,
    purchaseStatus,
    memberBuyingAllInd,
    memberPayingAmount,
    byChequeAmount,
    fromRRSPAmount,
    fromAvcAmount
  } = brokenLeavePeriod.brokenService;
  const { pplSharedCostVisibleInd } = brokenLeavePeriod;
  const { inAvailPurchCostY, inAvailPurchCostN } =
    brokenLeavePeriod?.purchasedPPLID ?? {};
  const { inAvailPurchCost } = brokenLeavePeriod?.purchased ?? {};

  const sharedCost =
    shareCostWithMember === 'Y'
      ? inAvailPurchCostY ?? 0
      : inAvailPurchCostN ?? 0;
  const purchaseCost =
    pplSharedCostVisibleInd && shareCostWithMember
      ? sharedCost
      : inAvailPurchCost ?? 0;
  const totalCost =
    (byChequeAmount ?? 0) + (fromRRSPAmount ?? 0) + (fromAvcAmount ?? 0);
  const totalLeftToPay = getElectionTotalLeftToPay(
    memberBuyingAllInd ?? 'Y',
    purchaseCost,
    memberPayingAmount?.toString() ?? '0',
    totalCost
  );

  return (
    !memberElectionDate ||
    (purchaseStatus === 'Y' && totalLeftToPay !== 0) ||
    (purchaseStatus !== 'Y' && purchaseStatus !== 'N')
  );
};

/**
 * Determines if any elected leave periods are incomplete
 */
export const hasIncompleteElectedLeavePeriods = (
  leavePeriods: Array<LeavePeriod>
) =>
  leavePeriods
    .map(leavePeriod =>
      isElectedLeavePeriodIncomplete(
        leavePeriod,
        leavePeriod.brokenService.pplSharedCostInd
      )
    )
    .filter(isIncomplete => isIncomplete).length > 0;

/**
 * Builds elect leave period step wizard put update call request model
 */
export const buildUpdateRequestModel = (
  data: Form165bData,
  electLeavePeriodStep: number,
  purchaseCostList?: Array<PurchaseCostItem>
) => {
  switch (electLeavePeriodStep) {
    case 1:
      return {
        leavePeriods: [
          {
            brokenService: {
              purchaseStatus: data.intendToPurchase,
              memberElectionDate: data.electionDate
                ? formatDateUTC(data.electionDate)
                : null
            }
          }
        ]
      };
    case 2: {
      return {
        leavePeriods: [
          {
            brokenService: {
              byChequeAmount: data.paymentCheque
                ? parseFloat(data.paymentCheque.replace(/,/g, ''))
                : null,
              fromRRSPAmount: data.paymentRRSP
                ? parseFloat(data.paymentRRSP.replace(/,/g, ''))
                : null,
              fromAvcAmount: data.paymentAVC
                ? parseFloat(data.paymentAVC.replace(/,/g, ''))
                : null,
              memberBuyingAllInd: data.buyingAllLeave,
              memberPayingAmount: data.amountForLeave
                ? parseFloat(data.amountForLeave.replace(/,/g, ''))
                : null,
              paReportedInd: data.paToCra,
              pplSharedCostInd: data.shareCostWithMember
            },
            purchaseCostList:
              purchaseCostList?.map((item, index) => ({
                purchaseCostNumber: item.purchaseCostNumber,
                reportedPAAmt:
                  data?.paReporting?.length > 0 &&
                  data.paReporting[index]?.revisedPA
                    ? parseFloat(
                        data.paReporting[index].revisedPA.replace(/,/g, '')
                      )
                    : undefined
              })) ?? []
          }
        ]
      };
    }
    default:
      return {};
  }
};
