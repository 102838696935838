import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { RootState } from 'types';
import { getApiErrorMessageByCode } from 'utils';
import Modal from 'components/modal/Modal';
import { createFormFailedClearAction } from 'containers/memberSearch/memberSearchActions';

interface CreateFailedModalProps {
  visible: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  formNumber?: string;
  altBodyText?: string;
}
const CreateFailedModal = ({
  visible,
  onConfirm = () => {},
  onClose = () => {},
  formNumber = '',
  altBodyText
}: CreateFailedModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('forms');
  const { createFormFailedCode } = useSelector(
    (state: RootState) => state.memberSearch
  );

  return (
    <Modal
      visible={visible}
      onOk={() => {
        dispatch(createFormFailedClearAction());
        onConfirm();
      }}
      onClose={() => {
        dispatch(createFormFailedClearAction());
        onClose();
      }}
      titleText={t('FORM_CREATE_FAILED_MODAL_HEADER', {
        formNumber
      })}
      width={600}
      isCancel={false}
    >
      {altBodyText || null}
      {!altBodyText && getApiErrorMessageByCode(createFormFailedCode)}
    </Modal>
  );
};

export default CreateFailedModal;
