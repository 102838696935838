import { Callout, CalloutProps, Classes, Intent } from '@blueprintjs/core';

import Icon, { IconName } from '../icon/Icon';
import { IconNamesStatus, IconNamesXSmall } from '../icon/Icons';

export type CalloutNotificationIntent =
  | 'default'
  | 'primary'
  | 'warning'
  | 'success'
  | 'danger';

const calloutNotificationIntentMap: Record<string, Intent> = {
  default: 'none',
  primary: 'primary',
  warning: 'warning',
  success: 'success',
  danger: 'danger'
};

export interface CalloutNotificationProps
  extends Omit<CalloutProps, 'intent' | 'icon'> {
  intent?: CalloutNotificationIntent;
  icon?: IconName;
  disableIcon?: boolean;
  maxWidth?: string;
  fill?: boolean;
  disableMargin?: boolean;
  disableMarginTop?: boolean;
  disableMarginBottom?: boolean;
  disableMarginX?: boolean;
  disableMarginY?: boolean;
  'data-testid'?: string;
  id: string;
  onDismiss?: () => void;
}

/**
 * See: https://blueprintjs.com/docs/#core/components/callout
 */
const CalloutNotification: React.FC<CalloutNotificationProps> = ({
  intent = 'primary',
  icon,
  disableIcon = false,
  maxWidth = '100%',
  fill,
  title,
  children,
  disableMargin = true,
  disableMarginTop = false,
  disableMarginBottom = false,
  disableMarginX = false,
  disableMarginY = false,
  id,
  onDismiss,
  ...props
}) => {
  const selectedIcon = (() => {
    if (disableIcon) {
      return null;
    }

    if (icon) {
      return icon;
    }

    switch (intent) {
      case 'warning':
        return IconNamesStatus.WARNING;
      case 'success':
        return IconNamesStatus.COMPLETED;
      case 'danger':
        return IconNamesStatus.DANGER;
      case 'primary':
      default:
        return IconNamesStatus.INFO;
    }
  })();

  return (
    <Callout
      {...props}
      id={id}
      role="status"
      intent={calloutNotificationIntentMap[intent]}
      data-testid={props['data-testid'] ?? `${intent}-callout-notification`}
      sx={{
        fontSize: 'small',
        lineHeight: 'base',
        wordBreak: 'break-word',
        width: fill ? '100%' : undefined,
        maxWidth: !fill ? maxWidth : undefined,
        borderWidth: '1px',
        borderStyle: 'solid',
        padding: '16px 20px',
        m: disableMargin ? 0 : 3,
        mt: disableMarginTop ? 0 : undefined,
        mb: disableMarginBottom ? 0 : undefined,
        mx: disableMarginX ? 0 : undefined,
        my: disableMarginY ? 0 : undefined,
        textAlign: 'left',
        p: {
          marginBottom: '0px'
        },
        [`&.${Classes.CALLOUT}.${Classes.CALLOUT_ICON}`]: {
          paddingLeft: '20px'
        },
        '.title': {
          fontWeight: '500',
          marginBottom: '10px'
        },
        [`&.${Classes.CALLOUT}.${Classes.CALLOUT_ICON} > .bp4-icon:first-of-type`]: {
          display: 'none'
        },
        // eslint-disable-next-line @blueprintjs/classes-constants
        [`&.${Classes.CALLOUT}:not([class*="bp4-intent-"])`]: {
          backgroundColor: 'white',
          borderColor: 'light-gray-01',
          color: 'black'
        },
        [`&.${Classes.CALLOUT}.${Classes.INTENT_PRIMARY}`]: {
          backgroundColor: 'extendedBlue25',
          borderColor: 'extendedBlue100',
          color: 'black'
        },
        [`&.${Classes.CALLOUT}.${Classes.INTENT_WARNING}`]: {
          backgroundColor: 'extendedYellow25',
          borderColor: 'extendedYellow100',
          color: 'black'
        },
        [`&.${Classes.CALLOUT}.${Classes.INTENT_SUCCESS}`]: {
          backgroundColor: 'extendedGreen25',
          borderColor: 'extendedGreen100',
          color: 'black'
        },
        [`&.${Classes.CALLOUT}.${Classes.INTENT_DANGER}`]: {
          backgroundColor: 'extendedRed25',
          borderColor: 'extendedRed100',
          color: 'black'
        }
      }}
    >
      <div
        sx={{
          display: 'flex',
          alignItems: 'start',
          '& > div:first-of-type': { marginRight: 3 }
        }}
      >
        {selectedIcon && (
          <Icon
            icon={selectedIcon}
            data-testid="callout-icon"
            sx={{ paddingTop: '0.15rem' }}
          />
        )}
        <div sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          {title && <p className="title">{title}</p>}
          {children}
        </div>
        {onDismiss && (
          <button
            sx={{ border: 'none', background: 'transparent' }}
            onClick={onDismiss}
            aria-label="close"
          >
            <Icon
              icon={IconNamesXSmall.REMOVE}
              sx={{ paddingTop: '0.15rem' }}
              color="extendedRed100"
            />
          </button>
        )}
      </div>
    </Callout>
  );
};

export default CalloutNotification;
