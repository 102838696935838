import {
  useRef,
  useEffect,
  useState,
  EffectCallback,
  DependencyList
} from 'react';
import { useSelector } from 'react-redux';
import { FieldValues, UseFormMethods } from 'react-hook-form-legacy';
import { isEqual } from 'lodash';

import { RootState } from 'types';

/**
 * React hook initializes form data with loaded store data if initCallback
 * provided. Then deeply watches updated store data when changed, and updates
 * the react-hook-form controller's defaultValue and dirty states to reflect the
 * recently saved store data.
 */
const useSavedForm = <T extends FieldValues>(
  methods: UseFormMethods<T>,
  formSelector: (state: RootState) => any,
  initCallback?: () => void
) => {
  const [init, setInit] = useState(true);
  const data = useSelector(formSelector);
  const ref = useRef();

  const useEffectDeepCompare = (
    callback: EffectCallback,
    deps: DependencyList
  ) => {
    useEffect(
      callback,
      ((val: any) => {
        if (!isEqual(val, ref.current)) {
          ref.current = val;
        }

        return ref.current;
      })(deps)
    );
  };

  // initialize form data if initCallback provided
  useEffect(() => {
    if (initCallback) {
      initCallback();
    }
  }, []);

  // watch for store update to modify active form data, and reset the form with the latest saved store data
  useEffectDeepCompare(() => {
    // prevent form from calling reset when it's being initialized
    if (!init) {
      methods.reset(data);
    } else {
      setInit(false);
    }
  }, [data]);
};

export default useSavedForm;
