import { EapAction } from 'types';
import { updatePasswordActionTypes } from './updatePasswordActions';

export interface UpdatePasswordData {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export interface UpdatePasswordState {
  updatePasswordData: UpdatePasswordData;
  step: number;
}

const updatePasswordData: UpdatePasswordData = {
  currentPassword: '',
  newPassword: '',
  confirmNewPassword: ''
};

export const initialState: UpdatePasswordState = {
  updatePasswordData,
  step: 1
};

const updatePassword = (
  state: UpdatePasswordState = initialState,
  action: EapAction
): UpdatePasswordState => {
  const { type } = action;
  switch (type) {
    case updatePasswordActionTypes.INIT_UPDATE_PASSWORD:
      return {
        ...state,
        step: 1
      };
    case updatePasswordActionTypes.SUBMIT_UPDATE_PASSWORD_SUCCESS:
      return {
        ...state,
        step: 2
      };
    default:
      return state;
  }
};

export default updatePassword;
