import { EapAction } from 'types';
import { dataExtractTabIds } from '../types';

import { dataExtractActionTypes } from './dataExtractActions';

export interface DataExtractState {
  activeTab: dataExtractTabIds;
  extractDataType: any[];
  extractDataStatus: any[];
  chosenExtractDataType: string;
  chosenExtractDataYear: string;
  status: string;
  error: string;
}

const initialState = {
  activeTab: dataExtractTabIds.EXTRACT_DATA,
  extractDataType: [],
  extractDataStatus: [],
  chosenExtractDataType: '',
  chosenExtractDataYear: '',
  status: '',
  error: ''
};

const dataExtract = (
  state: DataExtractState = initialState,
  action: EapAction
): DataExtractState => {
  const { type, data } = action;
  switch (type) {
    case dataExtractActionTypes.SET_DATA_EXTRACT_ACTIVE_TAB:
      return {
        ...state,
        activeTab: data
      };
    case dataExtractActionTypes.SET_DATA_EXTRACT_TYPE:
      return {
        ...state,
        extractDataType: data
      };
    case dataExtractActionTypes.SET_DATA_EXTRACT_STATUS:
      return {
        ...state,
        extractDataStatus: data
      };
    case dataExtractActionTypes.SET_CHOSEN_DATA_EXTRACT_TYPE:
      return {
        ...state,
        chosenExtractDataType: data,
        chosenExtractDataYear: new Date().getFullYear().toString()
      };
    case dataExtractActionTypes.SET_CHOSEN_DATA_EXTRACT_YEAR:
      return {
        ...state,
        chosenExtractDataYear: data
      };
    case dataExtractActionTypes.SET_EXTRACT_DATA_RES:
      return {
        ...state,
        status: data
      };
    case dataExtractActionTypes.SET_EXTRACT_DATA_RES_ERROR:
      return {
        ...state,
        error: data
      };
    case dataExtractActionTypes.RESET_EXTRACT:
      return {
        ...state,
        chosenExtractDataType: initialState.chosenExtractDataType,
        chosenExtractDataYear: initialState.chosenExtractDataYear
      };
    case dataExtractActionTypes.RESET_EXTRACT_STATUS:
      return {
        ...state,
        status: initialState.status,
        error: initialState.error
      };
    default:
      return state;
  }
};

export default dataExtract;
