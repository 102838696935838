import { ActionCreator } from 'redux';

import { EapAction } from 'types';

export enum formActionTypes {
  DELETE_FORM = 'DELETE_FORM',
  DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS',
  DELETE_FORM_FAILED = 'DELETE_FORM_FAILED',
  LOCK_FORM = 'LOCK_FORM',
  UNLOCK_FORM = 'UNLOCK_FORM',
  CLEAR_LOCKED_FORM_NOTIFICATION = 'CLEAR_LOCKED_FORM_NOTIFICATION',
  TRIGGER_OVERRIDE = 'TRIGGER_OVERRIDE'
}

export const deleteFormAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  deleteSuccessCallbackAction?: ActionCreator<EapAction>;
}) => ({
  type: formActionTypes.DELETE_FORM,
  data
});

export const deleteFormSuccessAction: ActionCreator<EapAction> = () => ({
  type: formActionTypes.DELETE_FORM_SUCCESS
});

export const deleteFormFailedAction: ActionCreator<EapAction> = () => ({
  type: formActionTypes.DELETE_FORM_FAILED
});

export const lockFormAction: ActionCreator<EapAction> = (data: string) => ({
  type: formActionTypes.LOCK_FORM,
  data
});

export const unlockFormAction: ActionCreator<EapAction> = (data: {
  requestNumber: string | number;
  submissionComplete: boolean;
}) => ({
  type: formActionTypes.UNLOCK_FORM,
  data
});

export const clearLockedFormNotificationAction: ActionCreator<EapAction> = () => ({
  type: formActionTypes.CLEAR_LOCKED_FORM_NOTIFICATION
});

export const triggerOverride: ActionCreator<EapAction> = (data: boolean) => ({
  type: formActionTypes.TRIGGER_OVERRIDE,
  data
});
