import usePrevious from 'utils/hooks/usePrevious';
import { useFormContext } from 'react-hook-form-legacy';

import TextInput, { TextInputProps } from '../textInput/TextInput';

/**
 * Custom float input component to restrict user input; requires refRegister for
 * methods.register from parent react-hook-form to properly invoke value setter.
 */
interface TextFloatInputProps extends TextInputProps {
  refRegister: any;
  integerMaxLength?: number; // max integer-part length
  decimalMaxLength?: number; // max decimal-part length
}
const TextFloatInput = (props: TextFloatInputProps) => {
  const { setValue, watch, trigger } = useFormContext();
  const previousVal = usePrevious<string>(watch(props.name)) ?? '';

  const integerMaxLength = props.integerMaxLength ?? 2;
  const decimalMaxLength = props.decimalMaxLength ?? 2;

  return (
    <TextInput
      {...props}
      ref={props.refRegister}
      onChange={e => {
        const startsWithDecimal = e.target.value === '.';
        const valPeriodDelimited = e.target.value.split('.');
        const moreThanOneDecimal = valPeriodDelimited.length > 2;
        if (moreThanOneDecimal) {
          setValue(props.name, previousVal);
        }
        if (valPeriodDelimited[0].length > integerMaxLength) {
          // prevent user exceeding max integer-part length
          setValue(props.name, previousVal);
        } else if (
          e.target.value.length > 0 &&
          !/^[\d.]*$/.test(e.target.value)
        ) {
          // prevent non-valid characters
          setValue(props.name, e.target.value.replace(/[^\d.]/g, ''));
        } else if (valPeriodDelimited.length > 1) {
          if (
            valPeriodDelimited[1].length > decimalMaxLength ||
            !/[\d]+\.[\d]*$/.test(e.target.value)
          ) {
            // prevent user from inserting excessive decimal values
            // or user from inserting excessive decimal places
            setValue(props.name, previousVal);
            if (startsWithDecimal) {
              setValue(props.name, '0.');
            }
          }
        }

        if (
          props.onChange &&
          watch(props.name).length > 0 &&
          !startsWithDecimal &&
          !moreThanOneDecimal
        ) {
          props.onChange(e);
        }
      }}
      onBlur={async e => {
        if (e.target.value) {
          const parsedFloat = parseFloat(e.target.value);

          // if hanging decimal place, remove it and retrigger validation
          if (parsedFloat.toString() !== e.target.value) {
            setValue(props.name, parsedFloat, { shouldDirty: true });
            await trigger(props.name);
          }
        }
      }}
      clearInputCallback={props.clearInputCallback}
    />
  );
};

export default TextFloatInput;
