/* eslint-disable camelcase */
import { employmentPeriodModelObject } from 'interfaces';
import { formatToCad, formatAsCurrencyOrGetEmptyString } from 'utils';

export const createPeriodColumn = (
  arr: any[],
  periodModelObject: employmentPeriodModelObject,
  retroPay: any,
  startIndex: number = 1
) => {
  if (arr.length > 0) {
    // second row
    arr[startIndex + 0].push({
      contentCell: Number(periodModelObject?.hashMap?.credited_service)
        ? Number(periodModelObject?.hashMap?.credited_service)!.toFixed(2)
        : ''
    });
    // third row
    arr[startIndex + 1].push({
      contentCell: formatAsCurrencyOrGetEmptyString(
        periodModelObject?.hashMap?.earnings,
        'en'
      )
    });
    // fourth row
    arr[startIndex + 2].push({
      contentCell: formatAsCurrencyOrGetEmptyString(
        periodModelObject?.hashMap?.pension_adjustment_amt,
        'en'
      )
    });
    // fifth row
    arr[startIndex + 3].push({
      contentCell: formatAsCurrencyOrGetEmptyString(
        periodModelObject?.hashMap?.rpp_contribution_amt,
        'en'
      )
    });
    // sixth row
    arr[startIndex + 4].push({
      contentCell: formatAsCurrencyOrGetEmptyString(
        periodModelObject?.hashMap?.rca_contribution_amt,
        'en'
      )
    });
    // seventh and last row
    arr[startIndex + 5].push({
      contentCell: formatToCad(retroPay || 0, 'en')
    });
  }

  return arr;
};
