import { all, takeLeading, put } from 'redux-saga/effects';

import {
  navHistoryActionTypes,
  setNavBackBtnFallbackRouteCompleteAction
} from './navHistoryActions';

export function* setNavBackBtnFallbackRouteSaga() {
  yield put(setNavBackBtnFallbackRouteCompleteAction());
}

export function* navHistorySaga(): any {
  yield all([
    yield takeLeading(
      navHistoryActionTypes.SET_NAV_BACK_BTN_FALLBACK_ROUTE,
      setNavBackBtnFallbackRouteSaga
    )
  ]);
}

export default navHistorySaga;
