import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { extractErrorRecords, updateDataSet } from './form101Utils';

export enum NFT_TABS {
  REPORTED_EMPLOYEES = 'reportedEmployees',
  BATCH_UPLOADS = 'batchUploads'
}

export type filterDate =
  | 'last-7-days'
  | 'last-30-days'
  | 'last-6-months'
  | 'last-12-months'
  | 'custom'
  | 'all';
type ValidationState = 'valid' | 'invalid' | 'not-validated';

export interface Form101Data {
  email: string;
  primaryPhone: string;
  secondaryPhone: string;
  employeeId: string;
  langPref: string;
  dateOfBirth: string;
  hireDate: string;
  createDate?: string;
  salary: string;
  address1: string;
  address2: string;
  apartmentUnit: string;
  city: string;
  country: string;
  postal: string;
  province: string;
  occupation: string;
  memberAffiliation: string;
  employmentType: string;
  firstName: string;
  lastName: string;
  sin: string;
  salaryRate?: number | null;
  primaryPhoneValidityStatus: string;
  secondaryPhoneValidityStatus: string;
  validAddressState: ValidationState;
  validEmailAddressState: QASEmailResponseTypes | string;
}

export interface EmployeeInformation {
  nftId: number;
  groupNo: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  emailAddress: string;
  addressLine1: string;
  addressLine2: string;
  aptSuitUnitNumber: string;
  city: string;
  province: string;
  country: string;
  postalCode: string;
  addressInvalidInd: boolean;
  canadaPostValidInd?: boolean;
  hireDate: string;
  createDate: string;
  homePhone: string | null;
  mobilePhone: string | null;
  languagePreference: string;
  employeeId: string;
  employmentType: string;
  unionAffiliation: string;
  salaryAmount: number | null;
  salaryType: string;
}

export interface MemberData {
  addressInvalidInd: boolean;
  createDate: string;
  dateOfBirth: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  hireDate: string;
  modifiedBy?: string;
  nftId: number;
}

export interface ReportedEmployeesFilterState {
  firstName?: string;
  lastName?: string;
  employeeId?: string;
  hireDateFrom?: string;
  hireDateTo?: string;
  createdDateFrom?: string;
  createdDateTo?: string;
}
export interface ReportedEmployeesTableState {
  pages: number;
  pageSize: number;
  count: number;
  currentPage: number;
  invalidCount: number;
}
export interface NFTState {
  activeTab: NFT_TABS;
  loading: boolean;
  form101Data: Form101Data;
  memberData: Array<MemberData>;
  initLoading: boolean;
  successfullySubmitted: boolean;
  loadedNFTEmployee: null | EmployeeInformation;
  reportedEmployeesFilter: ReportedEmployeesFilterState;
  reportedEmployeesTable: ReportedEmployeesTableState;
  omersOffersNFT: boolean;
  isEditModeForm101: boolean;
  filePendingUpload: any;
  fileDataAsJSON: any;
  batchErrors: any;
  uploadedBatchNFTsList: Array<any>;
  loadingUploadedBatchNFTs: boolean;
  errorRecords: Array<any>;
  loadingValidation: boolean;
  token: string;
  outstandingNFT: boolean;
}

export const initialForm101Data = {
  email: '',
  primaryPhone: '',
  secondaryPhone: '',
  employeeId: '',
  langPref: 'EN',
  dateOfBirth: '',
  hireDate: '',
  salary: '',
  address1: '',
  address2: '',
  apartmentUnit: '',
  city: '',
  country: 'CAN',
  postal: '',
  province: 'ON',
  occupation: '',
  memberAffiliation: '',
  employmentType: '',
  firstName: '',
  lastName: '',
  sin: '',
  salaryRate: null,
  primaryPhoneValidityStatus: '',
  secondaryPhoneValidityStatus: '',
  validAddressState: 'not-validated' as const,
  validEmailAddressState: QASEmailResponseTypes.NOT_VERIFIED as const
};

export const initialMemberData = [];

export const initialReportedEmployeesFilterData = {
  employeeId: undefined,
  firstName: undefined,
  hireDateFrom: undefined,
  hireDateTo: undefined,
  lastName: undefined,
  createdDateFrom: undefined,
  createdDateTo: undefined
};

export const initialReportedEmployeesTableData = {
  pages: 0,
  pageSize: 50,
  count: 0,
  invalidCount: 0,
  currentPage: 1
};

export const initialState: NFTState = {
  activeTab: NFT_TABS.REPORTED_EMPLOYEES,
  loading: false,
  initLoading: false,
  form101Data: initialForm101Data,
  memberData: initialMemberData,
  successfullySubmitted: false,
  loadedNFTEmployee: null,
  reportedEmployeesFilter: initialReportedEmployeesFilterData,
  reportedEmployeesTable: initialReportedEmployeesTableData,
  omersOffersNFT: false,
  isEditModeForm101: false,
  filePendingUpload: {},
  fileDataAsJSON: [],
  batchErrors: null,
  uploadedBatchNFTsList: [],
  loadingUploadedBatchNFTs: false,
  errorRecords: [],
  loadingValidation: false,
  token: '',
  outstandingNFT: false
};

type OptionalDateRange =
  | {
      fromDate: string;
      toDate: string;
    }
  | {
      fromDate: null;
      toDate: null;
    }
  | any;

const nftSlice = createSlice({
  name: 'nft',
  initialState,
  reducers: {
    setActiveTabAction: (
      draft,
      { payload: { activeTab } }: PayloadAction<{ activeTab: NFT_TABS }>
    ) => {
      draft.activeTab = activeTab;
    },
    setAccessTokenAction: (draft, { payload }: PayloadAction<string>) => {
      draft.token = payload;
    },
    validatePhoneNumberWithCanadaPostAction: (
      draft,
      {
        payload: { phoneNumber, isPrimary }
      }: PayloadAction<{
        phoneNumber: string;
        isPrimary: boolean;
      }>
    ) => {
      if (isPrimary) {
        draft.form101Data.primaryPhone = phoneNumber;
      } else {
        draft.form101Data.secondaryPhone = phoneNumber;
      }
    },
    setPhoneNumberValidityStatusAction: (
      draft,
      {
        payload: { phoneNumberValidity, isPrimary }
      }: PayloadAction<{ phoneNumberValidity: string; isPrimary: boolean }>
    ) => {
      if (isPrimary) {
        draft.form101Data.primaryPhoneValidityStatus = phoneNumberValidity;
      } else {
        draft.form101Data.secondaryPhoneValidityStatus = phoneNumberValidity;
      }
    },
    setValidEmailAddressForm101: (
      draft,
      { payload }: PayloadAction<{ email: string }>
    ) => {
      draft.form101Data.validEmailAddressState = QASEmailResponseTypes.VERIFIED as const;
      draft.form101Data.email = payload.email;
    },
    setValidEmailAddressStateForm101: (
      draft,
      { payload }: PayloadAction<string>
    ) => {
      draft.form101Data.validEmailAddressState = payload;
    },
    setValidAddressForm101: draft => {
      draft.form101Data.validAddressState = 'valid' as const;
    },
    setValidAddressStateForm101: (
      draft,
      { payload }: PayloadAction<'valid' | 'invalid' | 'not-validated'>
    ) => {
      draft.form101Data.validAddressState = payload;
    },
    saveOrUpdateForm101: (
      draft,
      {
        payload
      }: PayloadAction<{
        form101Data: {
          groupNo: string;
          firstName: string;
          lastName: string;
          suffixSin: string;
          dateOfBirth: string;
          emailAddress: string;
          addressLine1: string;
          addressLine2: string;
          aptSuitUnitNumber: string;
          city: string;
          province: string;
          country: string;
          postalCode: string;
          hireDate: string;
          homePhone: string;
          mobilePhone: string;
          languagePreference: string;
          employeeId: string;
          employmentType: string;
          unionAffiliation: string;
          salaryAmount: number;
          homePhoneValidInd: string;
          mobilePhoneValidIn: string;
          addressInvalidInd: 'valid' | 'invalid' | 'not-validated';
          emailValidInd: QASEmailResponseTypes | string;
        };
      }>
    ) => {
      draft.form101Data = {
        email: payload.form101Data.emailAddress,
        primaryPhone: payload.form101Data.homePhone,
        secondaryPhone: payload.form101Data.mobilePhone,
        employeeId: payload.form101Data.employeeId,
        langPref: payload.form101Data.languagePreference,
        dateOfBirth: payload.form101Data.dateOfBirth,
        hireDate: payload.form101Data.hireDate,
        salary: '',
        address1: payload.form101Data.addressLine1,
        address2: payload.form101Data.addressLine2,
        apartmentUnit: payload.form101Data.aptSuitUnitNumber,
        city: payload.form101Data.city,
        country: payload.form101Data.country,
        postal: payload.form101Data.postalCode,
        province: payload.form101Data.province,
        occupation: '',
        memberAffiliation: payload.form101Data.unionAffiliation,
        employmentType: payload.form101Data.employmentType,
        firstName: payload.form101Data.firstName,
        lastName: payload.form101Data.lastName,
        sin: payload.form101Data.suffixSin,
        salaryRate: payload.form101Data.salaryAmount,
        primaryPhoneValidityStatus: payload.form101Data.homePhoneValidInd,
        secondaryPhoneValidityStatus: payload.form101Data.mobilePhoneValidIn,
        validAddressState: payload.form101Data.addressInvalidInd,
        validEmailAddressState: payload.form101Data.emailValidInd
      };
    },
    getForm101Action: (_, _action: PayloadAction<any>) => {},
    saveOrUpdateForm101Successful: draft => {
      draft.successfullySubmitted = true;
      draft.form101Data = initialForm101Data;
    },
    setToInitial: draft => {
      draft.successfullySubmitted = false;
      draft.form101Data = initialForm101Data;
      draft.filePendingUpload = {};
    },
    resetEditModeData: draft => {
      draft.loadedNFTEmployee = null;
      draft.isEditModeForm101 = false;
      draft.omersOffersNFT = false;
    },
    getPaginationInfoAction: (
      _,
      _action: PayloadAction<
        | {
            firstName?: string;
            lastName?: string;
            employeeId?: string;
            hireDate?: OptionalDateRange;
            createdDate?: OptionalDateRange;
          }
        | undefined
      >
    ) => {},
    getPaginationInfoSuccessAction: (draft, payload) => {
      draft.reportedEmployeesTable.count = payload.payload.count;
      draft.reportedEmployeesTable.pageSize = payload.payload.pageSize;
      draft.reportedEmployeesTable.pages = payload.payload.pages;
      draft.reportedEmployeesTable.invalidCount = payload.payload.invalidCount;
    },
    getEmployeesAction: (
      draft,
      {
        payload
      }: PayloadAction<
        | {
            firstName?: string;
            lastName?: string;
            employeeId?: string;
            page: number;
            hireDate?:
              | { fromDate: string; toDate: string }
              | { fromDate: null; toDate: null }
              | any;
            createdDate?:
              | { fromDate: string; toDate: string }
              | { fromDate: null; toDate: null }
              | any;
            hireDateFrom?: string;
            hireDateTo?: string;
            createdDateFrom?: string;
            createdDateTo?: string;
          }
        | undefined
      >
    ) => {
      if (payload === undefined) {
        draft.reportedEmployeesFilter = {
          employeeId: undefined,
          firstName: undefined,
          hireDateFrom: undefined,
          hireDateTo: undefined,
          lastName: undefined,
          createdDateFrom: undefined,
          createdDateTo: undefined
        };
      } else {
        draft.reportedEmployeesFilter = {
          employeeId: payload?.employeeId,
          firstName: payload?.firstName,
          hireDateFrom:
            payload?.hireDate?.fromDate ?? payload?.hireDateFrom ?? undefined,
          hireDateTo:
            payload?.hireDate?.toDate ?? payload?.hireDateTo ?? undefined,
          lastName: payload?.lastName,
          createdDateFrom:
            payload?.createdDate?.fromDate ??
            payload?.createdDateFrom ??
            undefined,
          createdDateTo:
            payload?.createdDate?.toDate ?? payload?.createdDateTo ?? undefined
        };
      }
      draft.loading = true;
    },
    getEmployeesSuccessful: (
      draft,
      { payload }: PayloadAction<Array<MemberData>>
    ) => {
      draft.memberData = payload;
      draft.loading = false;
    },
    getEmployeesFailed: draft => {
      draft.loading = false;
    },
    getEmployeeInfoAction: (
      _,
      _action: PayloadAction<{ nftId: number; groupNo: string }>
    ) => {},
    getEmployeeInfoSuccessAction: (draft, { payload }: any) => {
      draft.loadedNFTEmployee = payload;
    },
    resetLoadedNFTEmployeeAction: draft => {
      draft.loadedNFTEmployee = null;
    },
    setCurrentPageAction: (draft, { payload }: PayloadAction<number>) => {
      draft.reportedEmployeesTable.currentPage = payload;
    },
    resetReportedEmployeesAction: draft => {
      draft.reportedEmployeesFilter = initialReportedEmployeesFilterData;
      draft.reportedEmployeesTable = initialReportedEmployeesTableData;
    },
    setModeAction: (draft, { payload }: PayloadAction<boolean>) => {
      draft.isEditModeForm101 = payload;
    },
    getOmersOffersNFTAction: (
      _,
      _action: PayloadAction<{ groupNo: string }>
    ) => {},
    getOmersOffersNFTSuccessful: (
      draft,
      { payload }: PayloadAction<boolean>
    ) => {
      draft.omersOffersNFT = payload;
    },
    addFileToBatchPendingUploadAction: (
      draft,
      { payload }: PayloadAction<any>
    ) => {
      draft.filePendingUpload = payload;
    },
    clearFilePendingUploadAction: draft => {
      draft.filePendingUpload = {};
    },
    addJSONFileDataBatchUploadAction: (
      draft,
      { payload }: PayloadAction<any>
    ) => {
      draft.fileDataAsJSON = payload;
    },
    clearJSONDataBatchUploadAction: draft => {
      draft.fileDataAsJSON = [];
      draft.errorRecords = [];
      draft.batchErrors = null;
    },
    batchUploadRequestAction: (draft, { payload }: PayloadAction<any>) => {
      const data = updateDataSet(payload.validateData, payload.dataSet);
      draft.fileDataAsJSON = data;
      draft.loadingValidation = true;
    },
    batchUploadSuccessAction: (
      draft,
      { payload }: PayloadAction<{ errors: any; dataSet: any }>
    ) => {
      if (payload?.errors) {
        const errorRecords = extractErrorRecords(
          payload?.errors?.validationError,
          payload?.dataSet
        );
        draft.errorRecords = errorRecords;
      } else {
        draft.errorRecords = [];
      }
      draft.batchErrors = payload?.errors;
      draft.loadingValidation = false;
    },
    batchUploadFailedAction: draft => {
      draft.loadingValidation = false;
    },
    resetErrorsAction: draft => {
      draft.batchErrors = null;
      draft.errorRecords = [];
      draft.loadingValidation = false;
    },
    downloadFileBuilderAction: draft => draft,
    fetchUploadedBatchNFTListAction: draft => {
      draft.loadingUploadedBatchNFTs = true;
    },
    fetchUploadedBatchNFTListSuccessAction: (draft, payload: any) => {
      const data = payload?.payload?.sort((a: any, b: any) => b.id - a.id);

      draft.uploadedBatchNFTsList = data ?? [];
      draft.loadingUploadedBatchNFTs = false;
    },
    fetchUploadedBatchNFTListFailAction: draft => {
      draft.loadingUploadedBatchNFTs = false;
    },
    setOutstandingNFTAction: (draft, { payload }: PayloadAction<any>) => {
      draft.outstandingNFT = payload;
    }
  }
});

export const {
  setActiveTabAction,
  validatePhoneNumberWithCanadaPostAction,
  setPhoneNumberValidityStatusAction,
  setValidEmailAddressStateForm101,
  setValidEmailAddressForm101,
  setValidAddressForm101,
  setValidAddressStateForm101,
  getForm101Action,
  saveOrUpdateForm101,
  saveOrUpdateForm101Successful,
  setToInitial,
  resetEditModeData,
  getPaginationInfoAction,
  getPaginationInfoSuccessAction,
  getEmployeesAction,
  getEmployeesSuccessful,
  getEmployeesFailed,
  getEmployeeInfoAction,
  getEmployeeInfoSuccessAction,
  resetLoadedNFTEmployeeAction,
  setCurrentPageAction,
  resetReportedEmployeesAction,
  getOmersOffersNFTAction,
  getOmersOffersNFTSuccessful,
  setModeAction,
  addFileToBatchPendingUploadAction,
  clearFilePendingUploadAction,
  addJSONFileDataBatchUploadAction,
  clearJSONDataBatchUploadAction,
  batchUploadRequestAction,
  batchUploadSuccessAction,
  batchUploadFailedAction,
  downloadFileBuilderAction,
  fetchUploadedBatchNFTListAction,
  fetchUploadedBatchNFTListSuccessAction,
  fetchUploadedBatchNFTListFailAction,
  resetErrorsAction,
  setAccessTokenAction,
  setOutstandingNFTAction
} = nftSlice.actions;

export default nftSlice.reducer;
