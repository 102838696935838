import { useFormContext } from 'react-hook-form-legacy';
import { isNaN } from 'lodash';

import usePrevious from 'utils/hooks/usePrevious';
import TextInput, { TextInputProps } from '../textInput/TextInput';

/**
 * Custom currency input component to restrict user input and format currency
 * masking onBlur; requires refRegister as methods.register from parent
 * react-hook-form to properly invoke value setter. Optionally disables
 * user inputting decimal and formats with 'x.00' decimal on blur
 */
interface TextCurrencyInputProps extends TextInputProps {
  refRegister: any;
  currencyMaxLength: number; // max integer-part length
  disableUserDecimalInput?: boolean; // will still visually apply decimals
}
const TextCurrencyInput = (props: TextCurrencyInputProps) => {
  const { setValue, watch } = useFormContext();
  const previousVal = usePrevious<string>(watch(props.name)) ?? '';

  const validCharacterTestRegex = props.disableUserDecimalInput
    ? /^[\d,]*$/
    : /^[\d,.]*$/;
  const validCharacterReplaceRegex = props.disableUserDecimalInput
    ? /[^\d,]/g
    : /[^\d,.]/g;

  return (
    <TextInput
      {...props}
      ref={props.refRegister}
      isCurrency
      onChange={e => {
        const valPeriodDelimited = props.disableUserDecimalInput
          ? [e.target.value]
          : e.target.value.split('.');

        // prevent user exceeding max integer-part length
        if (
          valPeriodDelimited[0].replace(/,/g, '').length >
          props.currencyMaxLength
        ) {
          setValue(props.name, previousVal);
        }
        // prevent non-valid characters
        else if (
          e.target.value.length > 0 &&
          !validCharacterTestRegex.test(e.target.value)
        ) {
          setValue(
            props.name,
            e.target.value.replace(validCharacterReplaceRegex, '')
          );
        } else {
          // strip away commas to allow onBlur to handle this correctly after the fact
          if (/,/g.test(e.target.value)) {
            setValue(props.name, e.target.value.replace(/,/g, ''));
          }

          // prevent user from inserting excessive decimal points
          if (valPeriodDelimited.length > 2) {
            setValue(props.name, previousVal);
          } else if (valPeriodDelimited.length > 1) {
            // prevent user from inserting excessive decimal places
            if (!/[\d,]+\.(\d){0,2}$/.test(e.target.value)) {
              setValue(props.name, previousVal);
            }
          }
        }

        if (props.onChange) {
          props.onChange(e);
        }
      }}
      onFocus={e => {
        if (props.disableUserDecimalInput) {
          const parsedInt = parseInt(
            watch(props.name)?.replace(/,/g, '') ?? '',
            10
          );
          const formattedVal = !isNaN(parsedInt) ? parsedInt : '';
          setValue(props.name, formattedVal);
        } else {
          setValue(props.name, watch(props.name)?.replace(/,/g, '') ?? '');
        }

        if (props.onFocus) {
          props.onFocus(e);
        }
      }}
      onBlur={e => {
        const parsedFloat = parseFloat(e.target.value.replace(',', ''));
        const paddedFloat = !isNaN(parsedFloat) ? parsedFloat.toFixed(2) : '';
        setValue(
          props.name,
          paddedFloat
            .replace(',', '')
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'),
          { shouldDirty: true }
        );

        if (props.onBlur) {
          props.onBlur(e);
        }
      }}
      clearInputCallback={props.clearInputCallback}
    />
  );
};

export default TextCurrencyInput;
