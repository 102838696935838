import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { form143ChangeTypes } from 'containers/requests/requestsSaga';
import { DonnaBusinessConfigNames } from 'containers/donnaBusinessConfig/types';
import { getStoreState } from 'utils';
import { DonnaApiResponse } from '@omers-packages/next-donna-gateway-handler';
import { getData, postData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const requestsSearch = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/search`, data);

export const requestsSearchDrilldownCount = (data: DataObj) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/search/drilldownrowcount`,
    data
  );

export const getReturnedMessagesCount = (employerGroupNumber: DataObj) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss/${employerGroupNumber}/countmss/unread`
  );

export type EaccessAPIError = {
  code: string;
  msg: string;
  trace: string;
  msgUI?: string;
  path?: string;
  errorId: string | null;
  parameters: string[] | null;
};

export type Form143ChangeError = {
  errors: EaccessAPIError[];
};

export type Form143ChangeData = {
  status: string;
  errors: Array<string>;
  additionalData: {
    processInstanceId: string;
    e143Flow: string;
    errorMessages: Array<string> | null;
    originalRequestNo: string;
    activeRequestNo: string;
    e143ActionDecision: form143ChangeTypes;
    eventType: 'T' | 'R' | null;
    mssMessageId: string | null;
  };
  technical?: Array<string>;
  processErrorMessage?: string | null;
};

export type Form143ChangeValidationResponse = DonnaApiResponse<
  Form143ChangeData
>;

export const form143ChangeValidation = (
  membershipNumber: string,
  eventType: string,
  username: string
): Promise<Form143ChangeValidationResponse> =>
  postData(
    `/api/wfm-orchestrator/execute`,
    {
      processInput: {
        type: 'e143ActionDecisionInputAggregate',
        processType: 'E143_ACTION_DECISION',
        contextDetails: {
          membershipId: membershipNumber
        },
        eventType
      }
    },
    undefined,
    undefined,
    undefined,
    undefined,
    {
      username
    }
  );

export type Form143ChangeRequestReponse =
  | {
      data: { requestNo: string };
    }
  | Form143ChangeError;

export const getAdjustmentRequestNumber = (
  requestNumber: string
): Promise<Form143ChangeRequestReponse> => {
  const {
    [DonnaBusinessConfigNames.TERMINATION]: terminationConfig
  } = getStoreState().donnaBusinessConfig;

  return postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/adjust`,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    {
      isDonnaTermination:
        terminationConfig?.configValue.value.toString() ?? 'false'
    }
  );
};

export const getRecallRequestNumber = (
  requestNumber: string
): Promise<Form143ChangeRequestReponse> => {
  const {
    [DonnaBusinessConfigNames.TERMINATION]: terminationConfig
  } = getStoreState().donnaBusinessConfig;

  return putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e143/${requestNumber}/recall`,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    {
      isDonnaTermination:
        terminationConfig?.configValue.value.toString() ?? 'false'
    }
  );
};
