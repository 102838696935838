import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData, putData, deleteData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getForm119 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/${requestNumber}`,
    undefined,
    requestNumber
  );

export const createForm119 = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119`, data);

export const upsertForm119 = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const submitForm119 = (data: DataObj, requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/submit/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const getReconciliationYears = (
  employerNo: string,
  membershipNo: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/reconciliation-years/${employerNo}/${membershipNo}`
  );

export const getRetroPayData = (data: {
  requestNumber: string;
  retroServicePeriodNo: number;
}) => {
  const { requestNumber, retroServicePeriodNo } = data;
  return getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/${requestNumber}/retropay/${retroServicePeriodNo}`
  );
};

export const updateRetroPayData = (data: DataObj, requestNumber: string) => {
  const { payload, retroServicePeriodNo } = data;
  return putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/${requestNumber}/retropay/${retroServicePeriodNo}`,
    payload,
    undefined,
    requestNumber
  );
};

export const deleteRetroPayData = (
  retroServicePeriodNo: string,
  requestNumber: string
) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/${requestNumber}/retropay/${retroServicePeriodNo}`,
    undefined,
    requestNumber
  );
