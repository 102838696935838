import {
  all,
  takeLeading,
  takeLatest,
  put,
  select,
  call,
  delay
} from 'redux-saga/effects';
import Router from 'next/router';
import { format } from 'url';
import { isNull } from 'lodash';

import { RootState, EapAction } from 'types';
import { BrokenService, PurchaseCostItem } from 'interfaces';
import { responseTypes } from 'types/response-type';
import { getApiErrorMessage, viewPdf, downloadPdf } from 'utils';
import {
  createForm165b,
  getForm165b,
  memberSearch,
  electLeavePeriodForm165b,
  unelectLeavePeriodForm165b,
  updateLeavePeriodStep1Form165b,
  updateLeavePeriodStep2Form165b,
  getLeavePeriodForm165b,
  saveForm165b,
  submitForm165b,
  getPDF
} from 'services';
import {
  lockFormSaga,
  unlockFormSaga,
  deleteFormSaga,
  clearLockedFormNotificationSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  notifyErrorAction,
  notifyLockedFormErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import {
  form165bActionTypes,
  getForm165bSuccessAction,
  getForm165bFailedAction,
  getLatestForm165bSuccessAction,
  deleteForm165bSuccessAction,
  deleteForm165bFailedAction,
  setEditElectLeavePeriodForm165bAction,
  setForm165bSavingStatusAction,
  updateLeavePeriod165bSuccessAction,
  updateLeavePeriod165bFailedAction,
  setElectLeavePeriodStepForm165bAction,
  setEditElectLeavePeriodForm165bSuccessAction,
  setEditElectLeavePeriodForm165bFailedAction,
  exitElectLeavePeriodForm165bAction,
  saveForm165bSuccessAction,
  saveForm165bFailedAction,
  clearForm165bStatusAction,
  setForm165bStepAction,
  submitForm165bAction,
  setForm165bSubmitStatusAction,
  submitForm165bSuccessAction
} from './form165bActions';
import { buildUpdateRequestModel } from './utils/leavePeriodMemberUtils';

/**
 * Common fetch logic for latest form 165b data
 */
export function* getLatestForm165b(showSpinner = true): any {
  if (showSpinner) {
    yield put(startLoadingAction());
  }

  const { requestNumber } = yield select((state: RootState) => state.form165b);

  // fetch 165b form latest data
  const res = yield call(safeAuthEffect, getForm165b, requestNumber);

  if (res.data) {
    yield put(
      getLatestForm165bSuccessAction({
        leavePeriods: res.data?.e165bModel?.leavePeriods ?? [],
        availableBrokenServices:
          res.data?.e165bModel?.availableBrokenServices ?? [],
        commentsToMember: res.data?.e165bModel?.commentsToMember ?? '',
        notes: res.data?.e165bModel?.notes ?? '',
        exceptions: res.data?.e165bModel?.exceptions ?? []
      })
    );
    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(notifyErrorAction(res.errors)));
  }

  if (showSpinner) {
    yield put(clearLoadingAction());
  }
}

export function* createForm165bSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm165b, action.data);

  if (res.data) {
    // route user to newly created 165b
    yield call(
      Router.push,
      '/form/165b/[id]',
      format({ pathname: `/form/165b/${res.data.requestNumber}` })
    );
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* getForm165bSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  // fetch 165b form
  const res = yield call(safeAuthEffect, getForm165b, requestNumber);

  if (res.data) {
    // reset view to table view if the cached requestNumber has changed from the requested
    const { requestNumber: cachedRequestNumber } = yield select(
      (state: RootState) => state.form165b
    );
    if (requestNumber !== cachedRequestNumber) {
      yield put(setEditElectLeavePeriodForm165bAction(null));
    }

    // fetch member info
    const memberInfoRes = yield call(safeAuthEffect, memberSearch, {
      omersMembershipNumber: res?.data?.e165bModel?.membershipNo,
      employerNumber: res?.data?.e165bModel?.employerNo
    });

    yield put(
      getForm165bSuccessAction({
        employerNo: res?.data?.e165bModel?.employerNo,
        requestNumber,
        memberInfo:
          memberInfoRes?.data?.memberSearchResult?.memberships[0] ?? null,
        leavePeriods: res.data?.e165bModel?.leavePeriods ?? [],
        availableBrokenServices:
          res.data?.e165bModel?.availableBrokenServices ?? [],
        commentsToMember: res.data?.e165bModel?.commentsToMember ?? '',
        notes: res.data?.e165bModel?.notes ?? '',
        exceptions: res.data?.e165bModel?.exceptions ?? []
      })
    );

    // route user to form if not already there
    if (Router.route !== '/form/165b/[id]') {
      yield call(
        Router.push,
        '/form/165b/[id]',
        format({ pathname: `/form/165b/${requestNumber}` })
      );
    }
  } else {
    yield put(getForm165bFailedAction());
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(res.errors)));

    // navigate off the request if user is on the page from a direct link
    if (Router.route === '/form/165b/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());
  yield call(clearLockedFormNotificationSaga);
}

export function* unlockForm165bSaga(action: EapAction) {
  const { submissionComplete } = yield select(
    (state: RootState) => state.form165b
  );

  // prevent unnecessary unlock if form successfully submitted
  if (!submissionComplete) {
    yield call(unlockFormSaga, action);
  }
}

export function* deleteForm165bSaga(action: EapAction) {
  yield call(deleteFormSaga, action, {
    deleteSuccessAction: deleteForm165bSuccessAction(),
    deleteFailedAction: deleteForm165bFailedAction()
  });
}

export function* electLeavePeriodForm165bSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form165b);

  const res = yield call(
    safeAuthEffect,
    electLeavePeriodForm165b,
    requestNumber,
    action.data
  );

  // fetch latest form data if elect successful
  if (res.data) {
    yield call(getLatestForm165b);

    // set newly elected period to leave period in edit view
    const { leavePeriods } = yield select((state: RootState) => state.form165b);
    yield put(
      setEditElectLeavePeriodForm165bAction(
        leavePeriods.find(
          (leavePeriod: {
            brokenService: BrokenService;
            purchaseCostList: Array<PurchaseCostItem>;
          }) => leavePeriod.brokenService.purchaseNumber === action.data
        )?.brokenService?.intervalNumber ?? null
      )
    );
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
  }
}

export function* removeLeavePeriodForm165bSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form165b);

  const res = yield call(
    safeAuthEffect,
    unelectLeavePeriodForm165b,
    requestNumber,
    action.data
  );

  // fetch latest form data if remove successful
  if (res.status === 204) {
    yield call(getLatestForm165b);
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
  }
}

export function* updateLeavePeriod165bSaga(action: EapAction): any {
  const {
    requestNumber,
    activeIntervalNumber,
    electLeavePeriodStep,
    activeLeavePeriod,
    form165bData
  } = yield select((state: RootState) => state.form165b);

  // update leave period based on activeIntervalNumber and active step
  const res = yield call(
    electLeavePeriodStep === 1
      ? updateLeavePeriodStep1Form165b
      : updateLeavePeriodStep2Form165b,
    buildUpdateRequestModel(
      action.data.formData,
      electLeavePeriodStep,
      activeLeavePeriod.purchaseCostList
    ),
    requestNumber,
    activeIntervalNumber
  );

  if (res.data) {
    yield call(getLatestForm165b, false);
    yield put(updateLeavePeriod165bSuccessAction());

    // advance wizard step to step 2 or close wizard if on final step
    if (!action.data.preventWizardUpdate) {
      const isPurchasable =
        (action.data.formData.intendToPurchase ??
          form165bData.intendToPurchase) === 'Y';
      if (
        (!isPurchasable && electLeavePeriodStep === 1) ||
        (isPurchasable && electLeavePeriodStep === 2)
      ) {
        yield put(setEditElectLeavePeriodForm165bAction(null));
      } else {
        yield put(setElectLeavePeriodStepForm165bAction(2));
      }
    }

    yield put(clearNotifications());
    yield delay(3000);
    yield put(setForm165bSavingStatusAction(''));
  } else {
    yield put(updateLeavePeriod165bFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield delay(3000);
    yield put(setForm165bSavingStatusAction(''));
  }
}

export function* setEditElectLeavePeriodForm165bSaga(action: EapAction): any {
  if (isNull(action.data)) {
    // trigger to close and clear step wizard data
    yield put(exitElectLeavePeriodForm165bAction());
  } else {
    yield put(startLoadingAction());

    const { requestNumber } = yield select(
      (state: RootState) => state.form165b
    );

    // fetch active interval number leave period info
    const res = yield call(
      safeAuthEffect,
      getLeavePeriodForm165b,
      requestNumber,
      action.data
    );

    if (res.data) {
      yield put(
        setEditElectLeavePeriodForm165bSuccessAction({
          intervalNumber: action.data,
          leavePeriod: res.data.e165bModel.leavePeriods[0]
        })
      );

      yield put(clearNotifications());
    } else {
      yield put(setEditElectLeavePeriodForm165bFailedAction());
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
    }

    yield put(clearLoadingAction());
  }
}

export function* saveForm165bSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form165b);

  const res = yield call(
    safeAuthEffect,
    saveForm165b,
    {
      commentsToMember:
        action.data.commentsToMember?.length > 0
          ? action.data.commentsToMember
          : null,
      notes: action.data.notes?.length > 0 ? action.data.notes : null,
      exceptions: action.data.exceptions
    },
    requestNumber
  );

  if (res.data) {
    yield put(saveForm165bSuccessAction(action.data));

    if (action.data.reviewForm) {
      yield put(setForm165bStepAction(2));
    }

    if (action.data.submitForm) {
      yield put(submitForm165bAction());
    }
  } else {
    yield put(saveForm165bFailedAction());
  }
}

export function* clearForm165bStatusSaga() {
  yield delay(3000);
  yield put(clearForm165bStatusAction());
}

export function* submitForm165bSaga(): any {
  yield put(startLoadingAction());
  yield put(setForm165bSubmitStatusAction('submitting'));

  const { requestNumber } = yield select((state: RootState) => state.form165b);

  const res = yield call(safeAuthEffect, submitForm165b, requestNumber);

  if (res?.data?.submitResponse) {
    yield put(clearNotifications());
    yield put(submitForm165bSuccessAction(res.data.submitResponse));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm165bSubmitStatusAction(''));
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(setForm165bSubmitStatusAction('error'));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm165bSubmitStatusAction(''));
  }
}

export function* getPDF165bSaga(action: EapAction): any {
  const { isView } = action.data;
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getPDF, action.data);
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    const fileName =
      action.data.formNo === '165_ele'
        ? 'Member Election Form'
        : 'e-Form 165b: Leave Period (Member Election)';

    if (isView) {
      viewPdf(res, fileName);
    } else {
      downloadPdf(res, fileName);
    }

    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* form165bSaga(): any {
  yield all([
    yield takeLeading(form165bActionTypes.CREATE_FORM_165B, createForm165bSaga),
    yield takeLatest(form165bActionTypes.GET_FORM_165B, getForm165bSaga),
    yield takeLatest(form165bActionTypes.UNLOCK_FORM_165B, unlockForm165bSaga),
    yield takeLeading(form165bActionTypes.DELETE_FORM_165B, deleteForm165bSaga),
    yield takeLeading(
      form165bActionTypes.ELECT_LEAVE_PERIOD_FORM_165B,
      electLeavePeriodForm165bSaga
    ),
    yield takeLeading(
      form165bActionTypes.REMOVE_LEAVE_PERIOD_FORM_165B,
      removeLeavePeriodForm165bSaga
    ),
    yield takeLatest(
      form165bActionTypes.UPDATE_LEAVE_PERIOD_FORM_165B,
      updateLeavePeriod165bSaga
    ),
    yield takeLatest(
      form165bActionTypes.SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B,
      setEditElectLeavePeriodForm165bSaga
    ),
    yield takeLatest(form165bActionTypes.SAVE_FORM_165B, saveForm165bSaga),
    yield takeLatest(
      [
        form165bActionTypes.SAVE_FORM_165B_SUCCESS,
        form165bActionTypes.SAVE_FORM_165B_FAILED
      ],
      clearForm165bStatusSaga
    ),
    yield takeLeading(form165bActionTypes.SUBMIT_FORM_165B, submitForm165bSaga),
    yield takeLeading(form165bActionTypes.GET_FORM_165B_PDF, getPDF165bSaga)
  ]);
}
