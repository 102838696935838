import { toLoggerError } from 'utils/api';
import logger from 'utils/logger/client-side';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { contentful } from 'modules/contentful';

const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV');
export type MaintenanceFlags =
  | 'login'
  | 'form102'
  | 'annualReports'
  | 'stewardshipReport'
  | 'adhocReports'
  | 'form158PreRetirementDeath'
  | 'mfa'
  | 'msBookingsEmployerListing';

export const MAINTENANCE_FLAG_KEY = 'maintenanceFlags';

export type MaintenanceFlagContainer = {
  [flag: string]: boolean;
} & { environment: string };

export const fetchMaintenanceFlags = async (): Promise<MaintenanceFlagContainer | null> => {
  let flags;

  try {
    const contentfulFlags = await contentful.entries.get({
      content_type: 'maintenanceFlagContainer',
      'fields.environment': NEXT_PUBLIC_APP_ENV as string
    });

    flags = contentfulFlags.items[0].fields as Record<string, boolean>;
  } catch (error) {
    logger.error(
      'There was an unexpected error fetching maintenance flags from Contentful:',
      toLoggerError(error)
    );
    return null;
  }

  return {
    ...flags,
    environment: NEXT_PUBLIC_APP_ENV as string
  } as MaintenanceFlagContainer;
};

export const isInMaintenance = (
  flag: MaintenanceFlags,
  providedMaintenanceFlags?: Record<string, boolean>
): boolean => {
  let maintenanceFlags: Record<string, boolean>;
  if (!providedMaintenanceFlags) {
    const maintenanceFlagJSON = window.localStorage.getItem(
      MAINTENANCE_FLAG_KEY
    );
    if (!maintenanceFlagJSON || maintenanceFlagJSON === 'undefined') {
      logger.warn('No maintenance flags found in session storage');
      return false;
    }

    maintenanceFlags = JSON.parse(maintenanceFlagJSON);
  } else {
    maintenanceFlags = providedMaintenanceFlags;
  }

  if (!(flag in maintenanceFlags)) {
    logger.warn(
      `Maintenance flag ${flag} not found in Contentful in environment ${maintenanceFlags.environment}`,
      maintenanceFlags
    );
    return false;
  }

  if (typeof maintenanceFlags[flag] !== 'boolean') {
    logger.warn(`Maintenance flag ${flag} is not a boolean`, maintenanceFlags);
    return false;
  }

  return maintenanceFlags[flag];
};
