/* eslint-disable camelcase */
import { EapAction } from 'types';
import {
  MemberSearchResult,
  employmentPeriodModelObject,
  reconciliationYearObject,
  RetroPayObject,
  form119Data
} from 'interfaces';
import { formatCurrencyStringToNumber, getSubmissionStatus } from 'utils';
import { FormState } from 'containers/requestForm/common/interfaces';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { form119ActionTypes } from './form119Actions';

export interface EForm119Model {
  employeeModel: {
    dirty: boolean;
    hashMap: any;
    requestNumber: string | null;
    adjustmentInd: string;
    deptId: string;
    employeeId: string;
    imprtFileRawRecd: string | null;
    imprtfileRecdNo: string | null;
    timeStamp: string | null;
    userId: string | null;
    email: string | null;
  };
  financialModel: {
    employmentPeriodModels: employmentPeriodModelObject[];
    form119Model: employmentPeriodModelObject | null;
    currentLeavePeriodModel: employmentPeriodModelObject | null;
    columnYear: any[];
    retroPayIndex: number;
    suppServiceIndex: number;
    retro: boolean[];
    supplementals: boolean[];
    retroPay: any[];
    suppAllowed: boolean[];
    reboundAllowed: boolean[];
    prevLeavePeriodModel: employmentPeriodModelObject | null;
    paMandatory: boolean;
  };
  originalEmail: string | null;
  membershipNo: string | null;
  employerNo: string | null;
  reconciliationYears: reconciliationYearObject[];
  selectedReconciliationYear: {
    dirty: boolean;
    hashMap: any;
    yearCode: string | null;
    yearText: string;
    adjustInd: string;
  };
  notes: any;
  exceptions: any[];
}

export interface Form119State extends FormState {
  memberInfo: MemberSearchResult | null;
  form119Data: {
    e119Model: {
      employeeModel: {
        dirty: boolean;
        hashMap: any;
        requestNumber: string | null;
        adjustmentInd: string;
        deptId: string;
        employeeId: string;
        imprtFileRawRecd: string | null;
        imprtfileRecdNo: string | null;
        timeStamp: string | null;
        userId: string | null;
        email: string | null;
      };
      financialModel: {
        employmentPeriodModels: employmentPeriodModelObject[];
        form119Model: employmentPeriodModelObject | null;
        currentLeavePeriodModel: employmentPeriodModelObject | null;
        columnYear: any[];
        retroPayIndex: number;
        suppServiceIndex: number;
        retro: boolean[];
        supplementals: boolean[];
        retroPay: any[];
        suppAllowed: boolean[];
        reboundAllowed: boolean[];
        prevLeavePeriodModel: employmentPeriodModelObject | null;
        paMandatory: boolean;
      };
      originalEmail: string | null;
      membershipNo: string | null;
      employerNo: string | null;
      reconciliationYears: reconciliationYearObject[];
      selectedReconciliationYear: {
        dirty: boolean;
        hashMap: any;
        yearCode: string | null;
        yearText: string;
        adjustInd: string;
      };
      notes: any;
      exceptions: any[];
    };
  };
  has11998Or11999Warnings: boolean;
  originalForm119Data: form119Data;
  newFormCreated: boolean;
  retroPayModels: RetroPayObject[] | null;
  retroPayTotal: number;
  showRetroModalTable: boolean;
  selectedRetroType: string;
  retroStartDateInStore: string;
  retroEndDateInStore: string;
  currentRetroIndex: number;
  reconciliationYears: reconciliationYearObject[];
  validMemberProfile: any;
  validEmailAddressState: QASEmailResponseTypes | string;
  validEmailAddress: {
    email: string;
  };
}

export const initialState = {
  requestNumber: null,
  memberInfo: null,
  initLoading: false,
  exceptions: [], // TODO: Use this array instead of nested e119Model exceptions
  overrideExceptions: [],
  has11998Or11999Warnings: false,
  form119Data: {
    e119Model: {
      employeeModel: {
        dirty: true,
        hashMap: null,
        requestNumber: null,
        adjustmentInd: '',
        deptId: '',
        employeeId: '',
        email: '',
        imprtFileRawRecd: null,
        imprtfileRecdNo: null,
        timeStamp: null,
        userId: null
      },
      financialModel: {
        employmentPeriodModels: [],
        currentLeavePeriodModel: null,
        form119Model: null,
        columnYear: ['2018', null, '2017', null],
        retroPayIndex: 0,
        suppServiceIndex: 0,
        retro: [false],
        supplementals: [false],
        retroPay: ['', null, '', null],
        suppAllowed: [false],
        reboundAllowed: [false],
        paMandatory: false,
        prevLeavePeriodModel: null
      },
      membershipNo: null,
      employerNo: null,
      originalEmail: null,
      reconciliationYears: [
        {
          dirty: true,
          hashMap: null,
          yearCode: null,
          yearText: '',
          adjustInd: ''
        }
      ],
      selectedReconciliationYear: {
        dirty: true,
        hashMap: null,
        yearCode: null,
        yearText: '',
        adjustInd: ''
      },
      notes: null,
      exceptions: []
    }
  },
  originalForm119Data: {
    e119Model: {
      employeeModel: {
        dirty: true,
        hashMap: null,
        requestNumber: null,
        adjustmentInd: '',
        deptId: '',
        employeeId: '',
        email: '',
        imprtFileRawRecd: null,
        imprtfileRecdNo: null,
        timeStamp: null,
        userId: null
      },
      financialModel: {
        employmentPeriodModels: [],
        currentLeavePeriodModel: null,
        form119Model: null,
        columnYear: ['2018', null, '2017', null],
        retroPayIndex: 0,
        suppServiceIndex: 0,
        retro: [false],
        supplementals: [false],
        retroPay: ['', null, '', null],
        suppAllowed: [false],
        reboundAllowed: [false],
        paMandatory: false,
        prevLeavePeriodModel: null
      },
      membershipNo: null,
      employerNo: null,
      originalEmail: null,
      reconciliationYears: [
        {
          dirty: true,
          hashMap: null,
          yearCode: null,
          yearText: '',
          adjustInd: ''
        }
      ],
      selectedReconciliationYear: {
        dirty: true,
        hashMap: null,
        yearCode: null,
        yearText: '',
        adjustInd: ''
      },
      notes: null,
      exceptions: []
    }
  },
  formStep: 1,
  formStatus: '' as const,
  submissionStatus: '' as const,
  submissionComplete: false,
  newFormCreated: false,
  retroPayModels: null,
  retroPayTotal: 0,
  showRetroModalTable: false,
  selectedRetroType: '',
  retroStartDateInStore: '',
  retroEndDateInStore: '',
  currentRetroIndex: 0,
  reconciliationYears: [],
  validMemberProfile: {},
  validEmailAddressState: '' as const,
  validEmailAddress: {
    email: ''
  }
};

export const filterOverrideExceptions = (exceptions: any) =>
  exceptions.filter((exception: any) => exception.overrideInd === 'Y');

const setEmploymentPeriodModel = (
  employmentPeriodModels: any,
  data: { index: number; field: any; value: any }
) => {
  const { index, field, value } = data;
  const periods = [...employmentPeriodModels];
  const item = periods[index];
  item.hashMap[field] = value;
  periods[index] = { ...item };
  return periods;
};

const setRetroPayModel = (
  retroPayModels: any,
  data: { index: number; field: any; value: any }
) => {
  const { index, field, value } = data;
  const periods = [...retroPayModels];
  if (periods.length === 0) {
    periods[0] = {
      dirty: true,
      hashMap: {
        earnings: 0,
        EFF_YEAR: '0'
      },
      requestNo: null,
      userId: null
    };
    periods[0].hashMap[field] = value;
  } else {
    const item = periods[index];
    item.hashMap[field] = value;
    periods[index] = { ...item };
  }
  return periods;
};

const setRetroPayTotal = (retroPayModels: any) => {
  let totalAmt = 0;
  if (retroPayModels && retroPayModels.length > 0) {
    retroPayModels.forEach((item: RetroPayObject) => {
      let earning = item.hashMap.earnings;
      if (typeof earning === 'string') {
        earning = formatCurrencyStringToNumber(earning);
      }
      totalAmt += earning ?? 0;
    });
  }
  return totalAmt;
};

const addToRetroPayModels = (retroPayModels: any, year: string) => {
  const models = retroPayModels ? [...retroPayModels] : [];
  let index = -1;
  if (models.length > 0) {
    models.forEach((item, idx) => {
      if (item.hashMap.EFF_YEAR === year) {
        index = idx;
      }
    });
  }
  const addition = {
    dirty: true,
    hashMap: {
      earnings: 0,
      EFF_YEAR: year
    },
    requestNo: null,
    userId: null
  };
  if (index !== -1) {
    models[index] = addition;
  } else {
    models.push(addition);
  }
  return models;
};

const removeFromRetroPayModels = (retroPayModels: any, index: number) =>
  retroPayModels.filter((_: any, idx: number) => idx !== index);

const form119 = (
  state: Form119State = initialState,
  action: EapAction
): Form119State => {
  const { type, data } = action;
  switch (type) {
    case form119ActionTypes.SET_REQUEST_NUMBER_FORM_119:
      return {
        ...initialState,
        requestNumber: data,
        initLoading: true
      };
    case form119ActionTypes.CREATE_FORM_119:
      return {
        ...state,
        newFormCreated: true
      };
    case form119ActionTypes.GET_FORM_119_SUCCEEDED:
      return {
        ...state,
        employerNo: data.employerNo,
        requestNumber: data.requestNumber,
        form119Data: data.formData,
        originalForm119Data: data.formData,
        memberInfo: data.memberInfo,
        initLoading: false,
        submissionStatus: '' as const,
        submissionComplete: false,
        overrideExceptions: filterOverrideExceptions(
          data.formData.e119Model.exceptions
        ),
        validEmailAddressState: data.validEmailAddressState,
        validEmailAddress: data.validEmailAddress
      };
    case form119ActionTypes.FETCH_LATEST_119_SUCCEEDED:
      return {
        ...state,
        requestNumber: data.requestNumber,
        form119Data: data.formData,
        originalForm119Data: data.formData,
        memberInfo: data.memberInfo,
        initLoading: false,
        submissionComplete: false
      };
    case form119ActionTypes.GET_FORM_119_FAILED:
    case form119ActionTypes.FETCH_LATEST_119_FAILED:
      return {
        ...state,
        initLoading: false
      };
    case form119ActionTypes.SET_EMPLOYMENT_PERIODS:
      return {
        ...state,
        // prevent override for originalForm119Data
        originalForm119Data: { ...state.originalForm119Data },
        form119Data: {
          e119Model: {
            ...state.form119Data.e119Model,
            financialModel: {
              ...state.form119Data.e119Model.financialModel,
              employmentPeriodModels: setEmploymentPeriodModel(
                state.form119Data.e119Model.financialModel
                  .employmentPeriodModels,
                data
              )
            }
          }
        }
      };
    case form119ActionTypes.GET_RETRO_PAY_SUCCEEDED:
      return {
        ...state,
        retroPayModels: data,
        retroPayTotal: setRetroPayTotal(data)
      };
    case form119ActionTypes.SET_DEPT_ID_IN_STORE:
      return {
        ...state,
        form119Data: {
          e119Model: {
            ...state.form119Data.e119Model,
            employeeModel: {
              ...state.form119Data.e119Model.employeeModel,
              deptId: data
            }
          }
        }
      };
    case form119ActionTypes.SET_EMPLOYEE_ID_IN_STORE:
      return {
        ...state,
        form119Data: {
          e119Model: {
            ...state.form119Data.e119Model,
            employeeModel: {
              ...state.form119Data.e119Model.employeeModel,
              employeeId: data
            }
          }
        }
      };
    case form119ActionTypes.SET_EMAIL_IN_STORE:
      return {
        ...state,
        form119Data: {
          e119Model: {
            ...state.form119Data.e119Model,
            employeeModel: {
              ...state.form119Data.e119Model.employeeModel,
              email: data
            }
          }
        }
      };
    case form119ActionTypes.SET_RETRO_START_DATE:
      return {
        ...state,
        retroStartDateInStore: data
      };
    case form119ActionTypes.SET_RETRO_END_DATE:
      return {
        ...state,
        retroEndDateInStore: data
      };
    case form119ActionTypes.SHOW_RETRO_MODAL_TABLE:
      return {
        ...state,
        showRetroModalTable: data
      };
    case form119ActionTypes.SET_RETRO_PAY_IN_STORE:
      return {
        ...state,
        retroPayModels: setRetroPayModel(state.retroPayModels || [], data),
        retroPayTotal: setRetroPayTotal(state.retroPayModels)
      };
    case form119ActionTypes.SET_CURRENT_RETRO_INDEX:
      return {
        ...state,
        currentRetroIndex: data
      };
    case form119ActionTypes.UPDATE_RETRO_PAY_SUCCEEDED:
    case form119ActionTypes.UPDATE_RETRO_PAY_FAILED:
    case form119ActionTypes.DELETE_RETRO_PAY_SUCCEEDED:
    case form119ActionTypes.CLEAR_RETRO_PAY_IN_STORE:
      return {
        ...state,
        retroPayTotal: 0,
        showRetroModalTable: false,
        selectedRetroType: '',
        retroStartDateInStore: '',
        retroEndDateInStore: '',
        retroPayModels: null,
        currentRetroIndex: 0
      };
    case form119ActionTypes.CLEAR_RETRO_PAY_MODELS:
      return {
        ...state,
        retroPayModels: null,
        retroPayTotal: 0
      };
    case form119ActionTypes.SAVE_FORM_119_IN_STORE:
      return {
        ...state,
        form119Data: {
          e119Model: data
        }
      };
    case form119ActionTypes.SAVE_FORM_119_REQUESTED:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form119ActionTypes.SAVE_FORM_119_SUCCEEDED:
      return {
        ...state,
        formStatus: 'success' as const,
        newFormCreated: false
      };
    case form119ActionTypes.SAVE_FORM_119_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form119ActionTypes.SET_FORM_119_STEP:
      return {
        ...state,
        formStep: data
      };
    case form119ActionTypes.CLEAR_FORM_119:
    case form119ActionTypes.CLEAR_119_WITHOUT_UNLOCK:
    case form119ActionTypes.DELETE_FORM_119_SUCCEEDED:
      return {
        ...initialState
      };
    case form119ActionTypes.SET_MEMBER_INFO_FORM_119:
      return {
        ...state,
        memberInfo: data
      };
    case form119ActionTypes.SET_FORM_119_STATUS:
      return {
        ...state,
        formStatus: data
      };
    case form119ActionTypes.SET_FORM_119_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form119ActionTypes.RESET_FORM_119_STATUS:
      return {
        ...state,
        formStatus: '' as const
      };
    case form119ActionTypes.RESET_FORM_119_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: '' as const
      };
    case form119ActionTypes.OVERRIDE_FORM_119_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form119ActionTypes.REMOVE_FORM_119_EXCEPTION_OVERRIDES:
      return {
        ...state,
        overrideExceptions: []
      };
    case form119ActionTypes.SET_FORM_119_EXCEPTION_OVERRIDES:
      return {
        ...state,
        overrideExceptions: filterOverrideExceptions(
          state.form119Data.e119Model.exceptions
        )
      };
    case form119ActionTypes.SET_FORM_119_NOT_NEW_FORM:
      return {
        ...state,
        newFormCreated: false
      };
    case form119ActionTypes.ADD_TO_RETRO_PAY_IN_STORE:
      return {
        ...state,
        retroPayModels: addToRetroPayModels(state.retroPayModels, data)
      };
    case form119ActionTypes.REMOVE_FROM_RETRO_PAY_IN_STORE:
      return {
        ...state,
        retroPayModels: removeFromRetroPayModels(state.retroPayModels, data)
      };
    case form119ActionTypes.SET_SELECTED_RETRO_TYPE:
      return {
        ...state,
        selectedRetroType: data
      };
    case form119ActionTypes.UPDATE_SELECTED_RECONCILIATION_YEAR:
      return {
        ...state,
        form119Data: {
          e119Model: {
            ...state.form119Data.e119Model,
            selectedReconciliationYear: data
          }
        }
      };
    case form119ActionTypes.GET_RECONCILIATON_YEARS_SUCCEEDED:
      return {
        ...state,
        reconciliationYears: data.reconciliationYears
      };
    case form119ActionTypes.RESET_RECONCILIATON_YEARS:
      return {
        ...state,
        reconciliationYears: []
      };
    case form119ActionTypes.RESET_TO_ORIGINAL_DATA_ON_REFRESH:
      return {
        ...state,
        form119Data: {
          ...state.originalForm119Data
        }
      };
    case form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_SUCCEEDED:
      return {
        ...state,
        submissionComplete:
          getSubmissionStatus(
            data.submitResponse,
            data.submitResponse.exceptions
          ) === 'success',
        submissionStatus: getSubmissionStatus(
          data.submitResponse,
          data.submitResponse.exceptions
        ),
        form119Data: {
          e119Model: {
            ...state.form119Data.e119Model,
            exceptions: !data.submitResponse.exceptions
              ? []
              : data.submitResponse.exceptions
          }
        },
        formStep:
          getSubmissionStatus(
            data.submitResponse,
            data.submitResponse.exceptions
          ) === 'success'
            ? 3
            : 2
      };
    case form119ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_119_SUCCEEDED:
      return {
        ...state,
        validMemberProfile: data
      };
    case form119ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_119_FAILED:
    case form119ActionTypes.VALID_MEMBER_RESET_119:
      return {
        ...state,
        validMemberProfile: {}
      };
    case form119ActionTypes.SET_VALID_EMAIL_ADDRESS_FORM_119:
      return {
        ...state,
        validEmailAddressState: QASEmailResponseTypes.VERIFIED as const,
        validEmailAddress: data
      };
    case form119ActionTypes.SET_VALID_EMAIL_ADDRESS_STATE_FORM_119:
      return {
        ...state,
        validEmailAddressState: data
      };
    case form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_REQUESTED:
      return {
        ...state,
        has11998Or11999Warnings: data.has11998Or11999Warnings
      };
    case form119ActionTypes.REMOVE_11998_OR_11999_WARNING:
      return {
        ...state,
        has11998Or11999Warnings: false
      };
    default:
      return state;
  }
};

export default form119;
