import { EapAction } from 'types';
import { userSessionActionTypes } from 'containers/auth/userSessionActions';
import { idleActionActionTypes } from './idleActionActions';

export interface IdleActionState {
  action: EapAction | null;
  bypassRouteConfirm: boolean;
}

const initialState = {
  action: null,
  bypassRouteConfirm: false
};

const idleAction = (
  state: IdleActionState = initialState,
  action: EapAction
): IdleActionState => {
  const { type, data } = action;
  switch (type) {
    case idleActionActionTypes.SET_IDLE_ACTION:
      return {
        ...state,
        action: data
      };
    case idleActionActionTypes.CLEAR_IDLE_ACTION:
      return {
        ...state,
        action: null,
        bypassRouteConfirm: false
      };
    case userSessionActionTypes.LOGOUT_FORCED_REQUESTED:
      return {
        ...state,
        bypassRouteConfirm: true
      };
    case userSessionActionTypes.LOGIN_SUCCEEDED:
    case userSessionActionTypes.LOGOUT_SUCCEEDED_FAILED:
      return {
        ...state,
        bypassRouteConfirm: false
      };
    default:
      return state;
  }
};

export default idleAction;
