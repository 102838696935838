import {
  Options,
  documentToReactComponents
} from '@contentful/rich-text-react-renderer';
import {
  BLOCKS,
  MARKS,
  INLINES,
  Inline,
  Block,
  Document
} from '@contentful/rich-text-types';
import Icon from 'components/v2/atomic/icon/Icon';
import Tooltip from 'components/v2/atomic/tooltip/Tooltip';
import {
  IconNamesLarge,
  IconNamesNavigation,
  IconNamesRegular,
  IconNamesSmall,
  IconNamesStatus,
  IconNamesFileFormat,
  IconNamesXSmall
} from 'components/v2/atomic/icon/Icons';
import React, { ReactNode } from 'react';
import { gt, isArray, isEmpty } from 'lodash';
import LinkButton from 'components/v2/atomic/linkButton/LinkButton';
import Link from 'next/link';
import logger from 'utils/logger/client-side';
import Spacer, { SPACING_DIRECTION } from 'components/v2/slices/spacer/Spacer';

export interface InsertIcon {
  fields: {
    iconName:
      | IconNamesStatus
      | IconNamesNavigation
      | IconNamesLarge
      | IconNamesRegular
      | IconNamesSmall
      | IconNamesXSmall
      | IconNamesFileFormat;
    size: number;
    color: string;
  };
}

export interface EmbeddedEntryNode extends Inline {
  nodeType: typeof INLINES.EMBEDDED_ENTRY;
  data: {
    target: {
      sys: {
        contentType: {
          sys: {
            id: 'tooltip' | 'icon' | 'linkButton' | 'text' | null;
          };
        };
      };
      fields: {
        content: string;
        tooltipText: string;
        type: 'help' | 'info' | undefined;
        inlineText: boolean;
        description: Document;
        iconName:
          | IconNamesStatus
          | IconNamesNavigation
          | IconNamesLarge
          | IconNamesRegular
          | IconNamesSmall
          | IconNamesXSmall
          | IconNamesFileFormat;
        size: number;
        color: string;
        hyperlinkText: string;
        id: string;
        variant: 'default' | 'simple' | 'outline';
        isIconButton: boolean;
        icon: Document;
        insertIcon: InsertIcon;
        openInNewWindow: boolean;
        href: string;
      };
    };
  };
}

const isEmbeddedEntryNode = (node: Inline | Block): node is EmbeddedEntryNode =>
  (node as EmbeddedEntryNode).nodeType === INLINES.EMBEDDED_ENTRY;

// isValidURL checks whether the provided urlString is valid, this includes:
//  https://, http://, tcp://, ftp://, etc.
// Requires URL to have a protocol + domain to be valid
const isValidURL = (urlString: string) => {
  try {
    return Boolean(new URL(urlString));
  } catch (e) {
    return false;
  }
};

export const richTextOptions: Options = {
  renderNode: {
    [INLINES.HYPERLINK]: ({ data }, children: ReactNode) => {
      // checks whether data.uri is a validURL and if the domain are the same we don't open a new tab
      // only if the domains are different we open a new tab
      // since we can have domain.com/pathname?workshopDrawer=true
      if (isValidURL(data.uri)) {
        const urlCheck = new URL(data.uri);
        return (
          <Link
            href={data.uri}
            target={
              urlCheck.origin === window.location.origin ? undefined : '_blank'
            }
          >
            {children}
          </Link>
        );
      }

      // checks if data.uri is a pathname and if it is, checks to see if it begins with slash
      // Note: if it is an invalid URL that begins with a slash that does not exist, it redirects
      //       to home page.
      if (data.uri.startsWith('/')) {
        const contentUrl = new URL(data.uri, window.location.origin);
        return (
          <Link
            href={{
              pathname: contentUrl.pathname,
              query: contentUrl.searchParams.toString()
            }}
            replace
          >
            {children}
          </Link>
        );
      }
      logger.debug(`${data.uri} is not a valid URL`);
      return <a href="">{children}</a>;
    },
    [BLOCKS.PARAGRAPH]: (_, children: ReactNode) => <p>{children}</p>,
    [BLOCKS.EMBEDDED_ENTRY]: ({ data }, _children: ReactNode) => {
      if (isEmpty(data) || isEmpty(data.target)) return null;
      if (data?.target.sys.contentType.sys.id === 'text') {
        return (
          <div>
            {documentToReactComponents(
              data.target.fields.description,
              richTextOptions
            )}
          </div>
        );
      }

      if (data?.target.sys.contentType.sys.id === 'spacer') {
        return (
          <Spacer
            id={data.target.sys.id}
            spacingType={SPACING_DIRECTION.VERTICAL}
            spacing={data.target.fields.spacing}
          />
        );
      }
      return null;
    },
    [BLOCKS.HEADING_3]: (_, children: ReactNode) => (
      <h3
        sx={{
          display: 'flex',
          alignitems: 'center'
        }}
      >
        {children}
      </h3>
    ),
    [BLOCKS.OL_LIST]: (_, children: ReactNode) => <ol>{children}</ol>,
    [BLOCKS.UL_LIST]: (_, children: ReactNode) => (
      <ul sx={{ paddingLeft: '1.25rem' }}>{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (_, children: ReactNode) => (
      <li
        sx={{
          listStyleType: 'revert',
          listStylePosition: 'outside',
          '>p': { margin: '0.5rem' }
        }}
      >
        {children}
      </li>
    ),
    [MARKS.BOLD]: (_, children: ReactNode) => (
      <span sx={{ fontWeight: '700' }}>{children}</span>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node: Inline | Block) => {
      if (!isEmbeddedEntryNode(node)) {
        return null;
      }

      const { target } = node.data;
      const contentTypeId = target.sys.contentType.sys.id;

      if (contentTypeId === 'tooltip') {
        const { content, tooltipText, type, inlineText } = target.fields;
        return (
          <Tooltip
            content={content}
            tooltipText={tooltipText}
            type={type}
            inlineText={inlineText}
          />
        );
      }

      if (contentTypeId === 'icon') {
        const { iconName, size, color } = target.fields;
        return (
          <div
            className="container"
            sx={{
              float: 'left',
              height: 'fit-content',
              margin: 'auto 0.5rem auto 0'
            }}
          >
            <Icon icon={iconName} size={size} color={color} />
          </div>
        );
      }

      if (contentTypeId === 'linkButton') {
        const {
          hyperlinkText,
          id,
          variant,
          isIconButton,
          href,
          openInNewWindow,
          insertIcon,
          color
        } = target.fields;

        let iconElement = null;
        if (isIconButton && !isEmpty(insertIcon)) {
          const { iconName, size, color: iconColor } = insertIcon.fields;
          iconElement = <Icon icon={iconName} size={size} color={iconColor} />;
        }

        return (
          <div
            className="container"
            sx={{
              width: 'fit-content',
              display: 'inline-flex'
            }}
          >
            <LinkButton
              id={id}
              variant={variant}
              isIconButton={isIconButton}
              href={href}
              target={openInNewWindow ? '_blank' : undefined}
              rel={openInNewWindow ? 'noopener noreferrer' : undefined}
              color={color === 'blue' ? 'blue' : 'orange'}
            >
              {hyperlinkText}
              {iconElement}
            </LinkButton>
          </div>
        );
      }

      if (contentTypeId === 'text') {
        const { description } = target.fields;
        return (
          <div>{documentToReactComponents(description, richTextOptions)}</div>
        );
      }
      return null;
    },
    [BLOCKS.TABLE]: (_, children: ReactNode) => (
      <div
        sx={{
          width: '100%',
          overflowX: 'auto',
          height: gt(React.Children.count(children), 12) ? '54rem' : '100%',
          overflowY: 'auto',
          border: '1px solid',
          borderColor: 'grey60'
        }}
      >
        <table
          sx={{
            border: '1px solid white',
            borderCollapse: 'collapse',
            width: '100%'
          }}
        >
          {children}
        </table>
      </div>
    ),
    [BLOCKS.TABLE_HEADER_CELL]: (_, children: ReactNode) => {
      // this checks to see if it is an element
      const isChildrenAnArray = isArray(children);
      if (!isChildrenAnArray) {
        return null;
      }
      const colSpanVal: string[] = children[0].props.children[0]
        .toString()
        .split('|');
      if (children[0].props.children[0] === '') {
        return null;
      }
      if (children[0].props.children[0] === '|empty') {
        return (
          <th
            sx={{
              border: '1px solid',
              borderColor: 'grey60',
              borderCollapse: 'collapse',
              color: 'white',
              borderBottomColor: 'grey60',
              padding: '1rem',
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textAlign: colSpanVal[1] ? 'center' : 'left',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'extendedBlue100',
              borderTop: '1px solid',
              borderTopColor: 'extendedBlue100',

              '>p': {
                color: 'white'
              }
            }}
          >
            {' '}
          </th>
        );
      }
      return (
        <th
          colSpan={colSpanVal[1] ? Number(colSpanVal[1]) : 1}
          sx={{
            border: '1px solid',
            borderColor: 'grey60',
            borderCollapse: 'collapse',
            color: 'white',
            borderBottomColor: 'grey60',
            padding: '1rem',
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textAlign: colSpanVal[1] ? 'center' : 'left',
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'extendedBlue100',
            borderTop: '1px solid',
            borderTopColor: 'extendedBlue100',

            '>p': {
              color: 'white'
            }
          }}
        >
          {colSpanVal && colSpanVal.length === 1 ? children : colSpanVal[0]}
        </th>
      );
    },
    [BLOCKS.TABLE_CELL]: (_, children: ReactNode) => {
      if (!isArray(children)) {
        return null;
      }
      const getTableCellContent: string = children[0].props.children[0]
        .toString()
        .split('|');
      if (
        getTableCellContent !== '' &&
        getTableCellContent.length > 1 &&
        getTableCellContent[1] === 'VH'
      ) {
        return (
          <th
            sx={{
              border: '1px solid white',
              borderCollapse: 'collapse',
              color: 'white',
              borderBottomColor: 'grey60',
              padding: '1rem',
              whiteSpace: 'pre-wrap',
              overflow: 'hidden',
              textAlign: 'left',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: 'extendedBlue100',
              borderTop: '1px solid',
              borderTopColor: 'extendedBlue100',

              '>p': {
                color: 'white'
              }
            }}
          >
            {getTableCellContent[0]}
          </th>
        );
      }
      return (
        <td
          sx={{
            border: '1px solid',
            borderColor: 'grey60',
            borderCollapse: 'collapse',
            borderBottomColor: 'grey60',
            backgroundColor: 'white',
            padding: '1rem 1rem 0 1rem',
            whiteSpace: 'pre-wrap',
            overflow: 'hidden',
            textAlign: 'left'
          }}
        >
          {children}
        </td>
      );
    }
  }
};

export const RichText = ({
  node
}: {
  node: string | React.ReactElement | { nodeType: string };
}) => {
  if (typeof node === 'string' || React.isValidElement(node)) {
    return <>{node}</>;
  }
  if (node?.nodeType === 'document') {
    // Typescript has a bug in here, nodeType is a property of Document
    return <>{documentToReactComponents(node as Document, richTextOptions)}</>;
  }
  return null;
};
