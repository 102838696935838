/* eslint-disable no-constant-condition */
/* eslint-disable camelcase */
import {
  all,
  takeLatest,
  takeLeading,
  select,
  put,
  call,
  delay,
  take
} from 'redux-saga/effects';
import Router from 'next/router';

import { RootState, EapAction } from 'types';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { getApiErrorMessage, downloadFile } from 'utils';
import {
  memberSearch,
  getInbox,
  getInboxCount,
  getInboxUnresolvedCount,
  updateCorr,
  getMessageThread,
  sendMessage,
  sendMessageThreadReply,
  updateMessageThreadStatus,
  downloadAttachment,
  getEcorrTopics,
  getEcorrContacts,
  getDmsDocumentList,
  getDmsAttachments
} from 'services';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  setNavBackBtnFallbackRouteAction,
  navHistoryActionTypes
} from 'containers/navHistory/navHistoryActions';
import {
  notifyErrorAction,
  clearNotifications,
  notificationActionTypes
} from 'containers/notifications/notificationsActions';
import {
  getInboxSucceededAction,
  getInboxFailedAction,
  getInboxCountActionSucceededFailed,
  messagesActionTypes,
  linkMemberSearchSuccess,
  linkMemberSearchFailed,
  getMessageThreadAction,
  getMessageThreadSuccessAction,
  getMessageThreadFailedAction,
  sendMessageSuccessAction,
  sendMessageFailedAction,
  sendMessageThreadReplySuccessAction,
  sendMessageThreadReplyFailedAction,
  updateMessageThreadStatusSuccessAction,
  hideSendMessageSuccessNotificationAction,
  hideResolveMessageSuccessNotificationAction,
  hideReopenMessageSuccessNotificationAction,
  setPresetDataAction,
  setPresetDataCompleteAction,
  getEcorrTopicsSuccessAction,
  getEcorrContactsSuccessAction,
  getDmsDocumentListSuccessAction,
  getDmsDocumentListFailedAction,
  getDmsDocumentListAction
} from './messagesActions';

export function* getInboxSaga(action: EapAction): any {
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const res = yield call(safeAuthEffect, getInbox, selectGroupNumber, {
    status: action.data.query.status,
    topic: action.data.query.topic,
    employeeId: action.data.query.employeeId,
    membershipNo: action.data.query.membershipNo,
    lastName: action.data.query.lastName,
    showUnreadOnly: action.data.query.showUnreadOnly,
    orgContactId: action.data.query.contact
  });

  const unresolvedCountRes = yield call(
    getInboxUnresolvedCount,
    selectGroupNumber
  );

  if (res.records) {
    yield put(getInboxSucceededAction(res.records));
    yield put({
      type: messagesActionTypes.GET_INBOX_UNRESOLVED_COUNT_SUCCESS_FAILED,
      data:
        unresolvedCountRes?.records?.length > 0
          ? unresolvedCountRes.records[0].UnresolvedCount
          : 0
    });
    yield put(clearNotifications());
  } else {
    yield put(getInboxFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* getInboxCountSaga(): any {
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const res = yield call(safeAuthEffect, getInboxCount, selectGroupNumber);
  yield put(
    getInboxCountActionSucceededFailed(
      res?.records?.length > 0 ? res.records[0].UnreadMessageCount : 0
    )
  );
}

export function* resolveSaga(action: EapAction): any {
  const res = yield call(safeAuthEffect, updateCorr, action.data);

  if (res.data && res.data.status === true) {
    yield put({
      type: messagesActionTypes.RESOLVE_MESSAGE_SUCCEEDED,
      data: action.data
    });
  } else {
    yield put({
      type: messagesActionTypes.RESOLVE_MESSAGE_FAILED
    });
  }
}

export function* linkMemberSearchSaga(action: EapAction): any {
  const res = yield call(safeAuthEffect, memberSearch, action.data);

  if (res.data) {
    yield put(linkMemberSearchSuccess(res.data));
  } else {
    yield put(linkMemberSearchFailed());
  }
}

export function* getMessageThreadSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getMessageThread, action.data.caseID);

  if (
    res?.compositeResponse &&
    res?.compositeResponse[0].httpStatusCode === 200
  ) {
    yield put(
      getMessageThreadSuccessAction({
        thread: res.compositeResponse[0].body.records[0],
        showSendSuccessNotification: action.data.showSendSuccessNotification,
        showReopenSuccessNotification:
          action.data.showReopenSuccessNotification,
        isMemberLinked:
          !!res?.compositeResponse[0]?.body?.records[0]
            ?.OM_Related_Employment__r ?? false
      })
    );
    if (action.data.showReopenSuccessNotification) {
      yield put(clearLoadingAction());
      yield delay(3000);
      yield put(hideReopenMessageSuccessNotificationAction());
    }
  } else {
    yield put(getMessageThreadFailedAction());
    yield Router.push('/messages');
  }
  yield put(clearLoadingAction());
}

export function* sendMessageSaga(action: EapAction): any {
  const res = yield call(
    safeAuthEffect,
    sendMessage,
    action.data.formDataFields,
    action.data.files
  );

  if (res && res.data) {
    yield put(sendMessageSuccessAction(res));
    yield put(getDmsDocumentListAction());
    yield Router.push(`/messages/${res.data.id}`);
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(sendMessageFailedAction());
  }
}

export function* sendMessageThreadReplySaga(action: EapAction): any {
  const res = yield call(
    safeAuthEffect,
    sendMessageThreadReply,
    action.data.formDataFields,
    action.data.files
  );

  if (res && res.data) {
    yield put(clearNotifications());
    yield put(
      getMessageThreadAction({
        caseID: action.data.formDataFields.id,
        showSendSuccessNotification: true,
        showReopenSuccessNotification: false
      })
    );
    yield put(getDmsDocumentListAction());
    yield put(sendMessageThreadReplySuccessAction(res));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(hideSendMessageSuccessNotificationAction());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(sendMessageThreadReplyFailedAction());
  }
}

export function* updateMessageThreadStatusSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    updateMessageThreadStatus,
    action.data.caseID,
    action.data
  );

  if (!res.errors) {
    yield put(clearNotifications());
    yield put(updateMessageThreadStatusSuccessAction(action.data.status));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(hideResolveMessageSuccessNotificationAction());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(clearLoadingAction());
  }
}

export function* downloadAttachmentSaga(action: EapAction): any {
  const { orbitId, fileName } = action.data;
  const groupNo = yield select(
    (state: RootState) => state.selectEmployers.selectGroupNumber
  );
  const res = yield call(safeAuthEffect, downloadAttachment, orbitId, groupNo);
  if (res && !res.errors) {
    downloadFile(res, fileName);
    yield put({
      type: messagesActionTypes.DOWNLOAD_ATTACHMENT_SUCCEEDED,
      data: res.data
    });
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });
  } else {
    yield put({
      type: messagesActionTypes.DOWNLOAD_ATTACHMENT_FAILED
    });
    yield put({
      type: notificationActionTypes.NOTIFY_ERROR,
      data: getApiErrorMessage(res.errors)
    });
  }
}

export function* reopenMessageThreadAndSendSaga(action: EapAction): any {
  const { caseID, status, formDataFields, files } = action.data;
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, updateMessageThreadStatus, caseID, {
    caseID,
    status
  });
  if (!res.errors) {
    const sendResponse = yield call(
      safeAuthEffect,
      sendMessageThreadReply,
      formDataFields,
      files
    );
    if (sendResponse && sendResponse.data) {
      yield put({
        type: messagesActionTypes.REOPEN_MESSAGE_THREAD_AND_SEND_SUCCEEDED,
        data: status
      });
      yield put(
        getMessageThreadAction({
          caseID,
          showSendSuccessNotification: false,
          showReopenSuccessNotification: true
        })
      );
    } else {
      yield put({
        type: messagesActionTypes.REOPEN_MESSAGE_THREAD_AND_SEND_FAILED
      });
      yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
      yield put(clearLoadingAction());
    }
  } else {
    yield put({
      type: messagesActionTypes.REOPEN_MESSAGE_THREAD_AND_SEND_FAILED
    });
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(clearLoadingAction());
  }
}

export function* openNewMessageWithPresetDataSaga(action: EapAction) {
  if (action?.data?.newTab) {
    yield put(setNavBackBtnFallbackRouteAction(action.data.fallbackRoute));
    // wait for fallback route to be set before opening new tab to ensure
    // redux-persist stores fallback route
    yield take(navHistoryActionTypes.SET_NAV_BACK_BTN_FALLBACK_ROUTE_COMPLETE);

    // wait for preset data to be set before opening new tab to ensure
    // redux-persist stores fallback route
    yield put(setPresetDataAction(action.data));
    yield take(messagesActionTypes.SET_PRESET_MESSAGE_DATA_COMPLETE);

    yield call(window.open, '/messages/new', '_blank');
  } else {
    yield call(Router.push, '/messages/new');
    yield put(setPresetDataAction(action.data));
  }
}

export function* setPresetDataSaga(): any {
  yield put(setPresetDataCompleteAction());
}

export function* getEcorrTopicsSaga(): any {
  const res = yield call(getEcorrTopics);

  if (res.data) {
    yield put(getEcorrTopicsSuccessAction(res.data.topics));
  }
}

export function* getEcorrContactsSaga(): any {
  const groupNo = yield select(
    (state: RootState) => state.selectEmployers.selectGroupNumber
  );
  const res = yield call(getEcorrContacts, groupNo);

  if (res.records) {
    yield put(getEcorrContactsSuccessAction(res.records));
  }
}

export function* getDmsDocumentListSaga(): any {
  const employerGroupNumber = yield select(
    (state: RootState) => state.selectEmployers.selectGroupNumber
  );
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    getDmsDocumentList,
    employerGroupNumber
  );
  yield put(clearLoadingAction());

  if (res) {
    yield put(getDmsDocumentListSuccessAction(res.data));
  } else {
    yield put(getDmsDocumentListFailedAction());
  }
}

export function* getDmsAttachmentSaga(action: EapAction): any {
  const employerGroupNumber = yield select(
    (state: RootState) => state.selectEmployers.selectGroupNumber
  );
  const { documentReferenceId, fileName } = action.data;
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    getDmsAttachments,
    documentReferenceId,
    employerGroupNumber
  );
  yield put(clearLoadingAction());
  if (res && !res.errors) {
    downloadFile(res, fileName);
    yield put({
      type: messagesActionTypes.GET_DMS_ATTACHMENT_SUCCEEDED,
      data: res
    });
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });
  } else {
    yield put({
      type: messagesActionTypes.GET_DMS_ATTACHMENT_FAILED
    });
    yield put({
      type: notificationActionTypes.NOTIFY_ERROR,
      data: getApiErrorMessage(res?.errors)
    });
  }
}

export function* messagesSaga(): any {
  yield all([
    yield takeLatest(messagesActionTypes.GET_INBOX_REQUESTED, getInboxSaga),
    yield takeLeading(
      messagesActionTypes.GET_INBOX_COUNT_REQUESTED,
      getInboxCountSaga
    ),
    yield takeLatest(
      messagesActionTypes.LINK_MEMBER_SEARCH_REQUESTED,
      linkMemberSearchSaga
    ),
    yield takeLatest(
      messagesActionTypes.RESOLVE_MESSAGE_REQUESTED,
      resolveSaga
    ),
    yield takeLatest(
      messagesActionTypes.GET_MESSAGE_THREAD_REQUESTED,
      getMessageThreadSaga
    ),
    yield takeLatest(
      messagesActionTypes.SEND_MESSAGE_REQUESTED,
      sendMessageSaga
    ),
    yield takeLatest(
      messagesActionTypes.SEND_MESSAGE_THREAD_REPLY_REQUESTED,
      sendMessageThreadReplySaga
    ),
    yield takeLatest(
      messagesActionTypes.UPDATE_MESSAGE_THREAD_STATUS_REQUESTED,
      updateMessageThreadStatusSaga
    ),
    yield takeLatest(
      messagesActionTypes.DOWNLOAD_ATTACHMENT_REQUESTED,
      downloadAttachmentSaga
    ),
    yield takeLatest(
      messagesActionTypes.REOPEN_MESSAGE_THREAD_AND_SEND_REQUESTED,
      reopenMessageThreadAndSendSaga
    ),
    yield takeLatest(
      messagesActionTypes.OPEN_NEW_MESSAGE_WITH_PRESET_DATA,
      openNewMessageWithPresetDataSaga
    ),
    yield takeLatest(
      messagesActionTypes.SET_PRESET_MESSAGE_DATA,
      setPresetDataSaga
    ),
    yield takeLatest(messagesActionTypes.GET_ECORR_TOPICS, getEcorrTopicsSaga),
    yield takeLatest(
      messagesActionTypes.GET_ECORR_CONTACTS,
      getEcorrContactsSaga
    ),
    yield takeLatest(
      messagesActionTypes.GET_DMS_DOCUMENT_LIST_REQUESTED,
      getDmsDocumentListSaga
    ),
    yield takeLatest(
      messagesActionTypes.GET_DMS_ATTACHMENT_REQUESTED,
      getDmsAttachmentSaga
    )
  ]);
}
