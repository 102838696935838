import { ActionCreator } from 'redux';

import { MemberSearchForm, EapAction } from 'types';

export enum memberSearchActionTypes {
  MEMBER_SEARCH_REQUESTED = 'MEMBER_SEARCH_REQUESTED',
  MEMBER_SEARCH_SUCCEEDED = 'MEMBER_SEARCH_SUCCEEDED',
  MEMBER_SEARCH_FAILED = 'MEMBER_SEARCH_FAILED',
  MEMBER_SEARCH_CLEAR_SEARCH_INITIATED = 'MEMBER_SEARCH_CLEAR_SEARCH_INITIATED',
  MEMBER_SEARCH_CLEAR_IS_REDIRECTED = 'MEMBER_SEARCH_CLEAR_IS_REDIRECTED',
  CHECK_MEMBER_PROFILE_VALIDITY_REQUESTED = 'CHECK_MEMBER_PROFILE_VALIDITY_REQUESTED',
  CHECK_MEMBER_PROFILE_VALIDITY_SUCCEEDED = 'CHECK_MEMBER_PROFILE_VALIDITY_SUCCEEDED',
  CHECK_MEMBER_PROFILE_VALIDITY_FAILED = 'CHECK_MEMBER_PROFILE_VALIDITY_FAILED',
  VALID_MEMBER_RESET = 'VALID_MEMBER_RESET',
  CREATE_FORM_FAILED = 'CREATE_FORM_FAILED',
  CREATE_FORM_FAILED_CLEAR = 'CREATE_FORM_FAILED_CLEAR',
  RESET_WARNING_CODE = 'RESET_WARNING_CODE'
}

export const memberSearch: ActionCreator<EapAction> = (
  data: MemberSearchForm
) => ({
  type: memberSearchActionTypes.MEMBER_SEARCH_REQUESTED,
  data
});

export const memberSearchSuccess: ActionCreator<EapAction> = (data: any) => ({
  type: memberSearchActionTypes.MEMBER_SEARCH_SUCCEEDED,
  data
});

export const memberSearchFailed: ActionCreator<EapAction> = (data: any) => ({
  type: memberSearchActionTypes.MEMBER_SEARCH_FAILED,
  data
});

export const clearIsRedirected: ActionCreator<EapAction> = (data: any) => ({
  type: memberSearchActionTypes.MEMBER_SEARCH_CLEAR_IS_REDIRECTED,
  data
});

export const clearMemberSearchInitiated: ActionCreator<EapAction> = (
  data: any
) => ({
  type: memberSearchActionTypes.MEMBER_SEARCH_CLEAR_SEARCH_INITIATED,
  data
});

export const checkMemberProfileValidity: ActionCreator<EapAction> = (
  data: any
) => ({
  type: memberSearchActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_REQUESTED,
  data
});

export const memberProfileValid: ActionCreator<EapAction> = (data: any) => ({
  type: memberSearchActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_SUCCEEDED,
  data
});

export const memberProfileInvalid: ActionCreator<EapAction> = () => ({
  type: memberSearchActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_FAILED
});

export const resetValidMember: ActionCreator<EapAction> = () => ({
  type: memberSearchActionTypes.VALID_MEMBER_RESET
});

export const createFormFailedAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: memberSearchActionTypes.CREATE_FORM_FAILED,
  data
});

export const createFormFailedClearAction: ActionCreator<EapAction> = () => ({
  type: memberSearchActionTypes.CREATE_FORM_FAILED_CLEAR
});

export const resetWarningCodeAction: ActionCreator<EapAction> = () => ({
  type: memberSearchActionTypes.RESET_WARNING_CODE
});
