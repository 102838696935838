import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { postData, getData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const searchArchivedMessages = (payload: any) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss/search`, payload);

export const getArchivedMessagesMessageThread = (correspondenceNo: string) =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss/${correspondenceNo}`);

export const sendArchivedMessagesReply = (
  correspondenceNo: string,
  message: string
) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss`, {
    correspondenceNo,
    message
  });

export const updateCorrStatusForArchivedMessage = (correspondenceNo: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorrmss/${correspondenceNo}/resolved`,
    {}
  );
