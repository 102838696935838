import useRole from 'utils/hooks/useRole';
import Modal from 'components/modal/Modal';
import LinkList from 'components/linkList/LinkList';
import { LinkItemType } from 'components/linkList/types';

export const FORM_LINKS: Record<string, LinkItemType> = {
  form102: {
    formNumber: '102',
    formName: 'FORM_NAME_102'
  },
  form105: {
    formNumber: '105',
    formName: 'FORM_NAME_105'
  },
  form106: {
    formNumber: '106',
    formName: 'FORM_NAME_106'
  },
  form119: {
    formNumber: '119',
    formName: 'FORM_NAME_119'
  },
  form143: {
    formNumber: '143',
    formName: 'FORM_NAME_143'
  },
  form143death: {
    formNumber: '143v',
    formName: 'FORM_NAME_143_DEATH',
    overrideNumber: '143'
  },
  form143disability: {
    formNumber: '143d',
    formName: 'FORM_NAME_143_DISABILITY',
    overrideNumber: '143'
  },
  form152: {
    formNumber: '152',
    formName: 'FORM_NAME_152',
    tag: 'NEW'
  },
  form158rtw: {
    formNumber: '158rtw',
    formName: 'FORM_NAME_158RTW',
    overrideNumber: '158',
    tag: 'NEW'
  },
  form158prd: {
    formNumber: '158prd',
    formName: 'FORM_NAME_158PRD',
    overrideNumber: '158',
    tag: 'NEW'
  },
  form165a: {
    formNumber: '165a',
    formName: 'FORM_NAME_165A'
  },
  form165b: {
    formNumber: '165b',
    formName: 'FORM_NAME_165B'
  },
  form165c: {
    formNumber: '165c',
    formName: 'FORM_NAME_165C'
  },
  form190: {
    formNumber: '190',
    formName: 'FORM_NAME_190'
  }
};

interface Item {
  formNumber: string;
  formName: string;
  callback?: () => void;
}

interface NewRequestModalProps {
  visible: boolean;
  titleText: string;
  onCancel: () => void;
  itemList: Item[];
}

const NewRequestModal = ({
  visible,
  titleText,
  onCancel,
  itemList
}: NewRequestModalProps) => {
  const { isNonFinancial } = useRole();

  // filter list options; non-financial users restricted to only 102 and 106
  const filteredItemList = isNonFinancial
    ? itemList.filter(
        item => item.formNumber === '102' || item.formNumber === '106'
      )
    : itemList;

  return (
    <Modal
      visible={visible}
      titleText={titleText}
      onCancel={onCancel}
      isCancel={false}
      isOk={false}
      width={660}
    >
      <LinkList itemList={filteredItemList} />
    </Modal>
  );
};

export default NewRequestModal;
