import { env } from '@omers-packages/next-isomorphic-runtime-env';

import { DataObj } from 'types';
import { getData, postData, putData, deleteData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const createForm165b = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b`, data);

export const getForm165b = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/${requestNumber}`,
    undefined,
    requestNumber
  );

export const submitForm165b = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const getLeavePeriodForm165b = (
  requestNumber: string,
  intervalNumber: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/${requestNumber}/${intervalNumber}`,
    undefined,
    requestNumber
  );

export const saveForm165b = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const electLeavePeriodForm165b = (
  requestNumber: string,
  purchaseNumber: number
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/${requestNumber}/${purchaseNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const unelectLeavePeriodForm165b = (
  requestNumber: string,
  intervalNumber: number
) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/${requestNumber}/${intervalNumber}`,
    undefined,
    requestNumber
  );

export const updateLeavePeriodStep1Form165b = (
  data: DataObj,
  requestNumber: string,
  intervalNumber: number
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/step1/${requestNumber}/${intervalNumber}`,
    data,
    undefined,
    requestNumber
  );

export const updateLeavePeriodStep2Form165b = (
  data: DataObj,
  requestNumber: string,
  intervalNumber: number
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165b/step2/${requestNumber}/${intervalNumber}`,
    data,
    undefined,
    requestNumber
  );
