import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form-legacy';
import {
  differenceInCalendarYears,
  format,
  parse,
  isValid,
  getYear
} from 'date-fns';
import { ReactSVG } from 'react-svg';

import { RootState } from 'types';
import { RetroPayObject } from 'interfaces';
import Button from 'components/button/Button';
import Modal from 'components/modal/Modal';
import Table from 'components/table/Table';
import TextInput from 'components/forms/inputs/textInput/TextInput';
import TextCurrencyInput from 'components/forms/inputs/textCurrencyInput/TextCurrencyInput';
import SelectInput from 'components/forms/inputs/selectInput/SelectInput';
import Notification from 'components/notification/Notification';
import {
  formatToCad,
  formatCurrencyStringToNumber,
  formatDate,
  getNumberOrZero,
  maskDate
} from 'utils';
import {
  addToRetroPayInStore,
  clearRetroPay,
  clearRetroPayModels,
  form119ActionTypes,
  saveForm119AndPerformNewAction,
  setRetroEndDateInStore,
  setRetroPay,
  setRetroStartDateInStore,
  setSelectedRetroType,
  showRetroModalTableAction
} from 'containers/requestForm/annualReporting/form119Actions';
import Divider from 'components/divider/Divider';
import retroPayModalValidations from './retroPayModalValidations';

import styles from './RetroPayModal.module.scss';

interface RetroPayModalProps {
  onConfirm: Function;
  onCancel: Function;
  earnings: string;
  startDate: string;
  endDate: string;
  retroServicePeriodNo: number;
  employmentPeriodModel: any | null;
}

export const RETRO_TYPE_OPTIONS = [
  {
    val: 'R',
    translation: 'RETRO_MODAL_SELECT_OPTION_REGULAR'
  },
  {
    val: 'I',
    translation: 'RETRO_MODAL_SELECT_OPTION_INCENTIVE'
  }
];

const RetroPayModal = ({
  onConfirm,
  onCancel,
  earnings,
  startDate,
  endDate,
  retroServicePeriodNo,
  employmentPeriodModel
}: RetroPayModalProps) => {
  const {
    retroPayModels,
    retroPayTotal,
    memberInfo,
    selectedRetroType,
    retroStartDateInStore,
    retroEndDateInStore,
    showRetroModalTable
  } = useSelector((state: RootState) => state.form119);
  const enrolmentDate = memberInfo?.mssemploymentItem.enrolmentDate || '';
  const enrolmentDateFormatted = formatDate(enrolmentDate, true, false);
  const servicePeriodYear = getYear(parse(endDate, 'yyyy-MM-dd', new Date()));
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation(['form119', 'common']);
  const validations = retroPayModalValidations(
    t,
    enrolmentDate,
    servicePeriodYear,
    enrolmentDateFormatted
  );
  const methods = useForm({
    resolver: validations,
    defaultValues: {
      retroType: '',
      retroStartDate: retroStartDateInStore,
      retroEndDate: retroEndDateInStore
    },
    mode: 'onBlur'
  });
  const { clearErrors, control, errors, register, reset, watch } = methods;
  const [tableData, setTableData] = useState<any>([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [calculateRetroDisabled, setCalculateRetroDisabled] = useState(true);
  const [showRetroTotalErrMsg, setShowRetroTotalErrMsg] = useState(false);
  const { retroType, retroStartDate, retroEndDate } = watch([
    'retroType',
    'retroStartDate',
    'retroEndDate'
  ]);

  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );

  const saveLogic = () => {
    const models = retroPayModels?.map((item: RetroPayObject) => {
      if (typeof item.hashMap.earnings === 'string') {
        // eslint-disable-next-line no-param-reassign
        item.hashMap.earnings = formatCurrencyStringToNumber(
          item.hashMap.earnings
        );
      }
      return item;
    });

    const payload = {
      retroPayDetails: {
        hashMap: {
          retro_type: retroType,
          retro_start_date: format(new Date(retroStartDate), 'yyyy-MM-dd'),
          retro_end_date: format(new Date(retroEndDate), 'yyyy-MM-dd')
        }
      },
      retroPayModels: models
    };

    dispatch(
      saveForm119AndPerformNewAction({
        actionType: form119ActionTypes.UPDATE_RETRO_PAY_REQUESTED,
        actionData: { payload, retroServicePeriodNo }
      })
    );

    clearErrors(['retroType', 'retroStartDate', 'retroEndDate']);
    reset();
    dispatch(showRetroModalTableAction(false));
    onConfirm();
  };

  const cancelLogic = () => {
    dispatch(clearRetroPay());
    clearErrors(['retroType', 'retroStartDate', 'retroEndDate']);
    reset();
    dispatch(showRetroModalTableAction(false));
    onCancel();
  };

  const tableHeading = [
    [
      { headingCell: t('ANNUAL_REPORTING_RETRO_MODAL_TABLE_COLUMN_HEADING_1') },
      { headingCell: t('ANNUAL_REPORTING_RETRO_MODAL_TABLE_COLUMN_HEADING_2') }
    ]
  ];

  const getRow = (selectedYear: string, index: number) => [
    {
      contentCell: <span className={styles.stronger}>{selectedYear}</span>
    },
    {
      contentCell: (
        <>
          <TextCurrencyInput
            name={`retro-amount-${index}`}
            type="text"
            refRegister={register}
            defaultValue={(() => {
              const parsedEarnings = parseFloat(
                retroPayModels
                  ? ((retroPayModels[index]?.hashMap
                      ?.earnings as unknown) as string)
                  : ''
              );
              return retroPayModels
                ? formatToCad(parsedEarnings, i18n.language, false)
                : '';
            })()}
            currencyMaxLength={7}
            clearInputCallback={() => {
              dispatch(
                setRetroPay({
                  index,
                  field: 'earnings',
                  value: 0
                })
              );
              methods.setValue(`retro-amount-${index}` as any, '');
            }}
            onChange={() => {
              const retroAmount = methods.watch(`retro-amount-${index}`);
              dispatch(
                setRetroPay({
                  index,
                  field: 'earnings',
                  value: retroAmount
                })
              );
            }}
            noMarginBottom
            disabled={omersUser}
          />
        </>
      )
    }
  ];

  const getLastRow = () => [
    {
      contentCell: (
        <span className={styles.stronger}>
          {t('ANNUAL_REPORTING_RETRO_MODAL_TOTAL')}
        </span>
      )
    },
    {
      contentCell: (
        <span>{formatToCad(getNumberOrZero(retroPayTotal), 'en')}</span>
      )
    }
  ];

  const retroPayHandler = () => {
    control.trigger(['retroType', 'retroStartDate', 'retroEndDate']);
    if (noErrors) {
      calculateRetroLogic();
    } else {
      setCalculateRetroDisabled(true);
    }
  };

  const calculateRetroLogic = () => {
    const startYear = Number(retroStartDate.split('/')[2]);
    const endYear = Number(retroEndDate.split('/')[2]);
    const data = [];
    if (startYear === endYear) {
      dispatch(addToRetroPayInStore(startYear.toString()));
      data.push(getRow(startYear.toString(), 0));
    } else if (startYear < endYear) {
      const parsedStartDate = parse(retroStartDate, 'MM/dd/yyyy', new Date());
      const parsedEndDate = parse(retroEndDate, 'MM/dd/yyyy', new Date());
      const diff = differenceInCalendarYears(parsedEndDate, parsedStartDate);
      for (let i = 0, j = 0; i <= diff; i += 1, j += 1) {
        const year = startYear + i;
        dispatch(addToRetroPayInStore(year.toString()));
        data.push(getRow(year.toString(), j));
      }
    }
    data.push(getLastRow());
    setTableData(data);
    dispatch(showRetroModalTableAction(true));
    setCalculateRetroDisabled(true);
  };

  const constructTable = () => {
    if (retroPayModels && retroPayModels.length > 0) {
      const data = [];
      if (retroPayModels.length === 1) {
        data.push(getRow(retroPayModels[0].hashMap.EFF_YEAR, 0));
        data.push(getLastRow());
      } else {
        const begin = Number(retroPayModels[0].hashMap.EFF_YEAR);
        const end = Number(
          retroPayModels[retroPayModels.length - 1].hashMap.EFF_YEAR
        );
        for (let i = begin, j = 0; i <= end; i += 1, j += 1) {
          data.push(getRow(i.toString(), j));
        }
        data.push(getLastRow());
      }
      setTableData(data);
    }
  };

  const noErrors =
    !errors.retroEndDate &&
    !errors.retroStartDate &&
    !errors.retroType &&
    !showRetroTotalErrMsg;

  // create table for existing retro that is being edited
  useEffect(() => {
    if (employmentPeriodModel && retroPayModels && retroPayModels.length > 0) {
      constructTable();
      dispatch(showRetroModalTableAction(true));
    }
  }, [employmentPeriodModel, retroPayModels]);

  const renderFooter = () => (
    <div className={styles.footer}>
      {onCancel && (
        <Button onClick={() => cancelLogic()} baseStyle="outlineOrange">
          {t('common:MODAL_CANCEL_BUTTON')}
        </Button>
      )}
      {onConfirm && (
        <Button
          isDisabled={isSaveDisabled || omersUser}
          onClick={() => saveLogic()}
        >
          {t('ANNUAL_REPORTING_RETRO_MODAL_OK_TEXT')}
        </Button>
      )}
    </div>
  );

  useEffect(() => {
    const startingDate = parse(retroStartDate, 'MM/dd/yyyy', new Date());
    const endingDate = parse(retroEndDate, 'MM/dd/yyyy', new Date());
    if (
      noErrors &&
      retroType !== '' &&
      retroStartDate?.length === 10 &&
      isValid(startingDate) &&
      retroEndDate?.length === 10 &&
      isValid(endingDate) &&
      !showRetroModalTable
    ) {
      setCalculateRetroDisabled(false);
    } else {
      setCalculateRetroDisabled(true);
    }
  }, [retroType, retroStartDate, retroEndDate, noErrors, showRetroModalTable]);

  useEffect(() => {
    const contributoryEarningsNumber = formatCurrencyStringToNumber(earnings);
    if (retroPayTotal > contributoryEarningsNumber) {
      setShowRetroTotalErrMsg(true);
    } else {
      setShowRetroTotalErrMsg(false);
    }
    constructTable();
  }, [retroPayTotal]);

  // enable/disable save button
  useEffect(() => {
    if (noErrors && retroPayTotal > 0) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  }, [noErrors, retroPayTotal]);

  // hide table and clear models if user is editing dates
  useEffect(() => {
    if (showRetroModalTable) {
      dispatch(showRetroModalTableAction(false));
      dispatch(clearRetroPayModels());
    }
  }, [retroStartDate, retroEndDate]);

  return (
    <FormProvider {...methods}>
      <Modal
        visible
        titleText={t('ANNUAL_REPORTING_RETRO_MODAL_TITLE')}
        onOk={onConfirm}
        onCancel={cancelLogic}
        onClose={cancelLogic}
        width={568}
        footer={renderFooter()}
        closable
      >
        <div className={styles.retroPayModal}>
          <div className={styles.infoContainer}>
            <div className={styles.row}>
              <span>{t('ANNUAL_REPORTING_RETRO_MODAL_SERVICE_PERIOD')}</span>
              <span>
                {formatDate(startDate, true, false)} -{' '}
                {formatDate(endDate, true, false)}
              </span>
            </div>
            <div className={styles.row}>
              <span>{t('ANNUAL_REPORTING_RETRO_MODAL_EARNINGS')}</span>
              <span>
                {formatToCad(
                  parseFloat(
                    typeof earnings === 'string'
                      ? earnings?.replace(/,/g, '') || '0'
                      : earnings
                  ),
                  i18n.language
                )}
              </span>
            </div>
          </div>
          <SelectInput
            name="retroType"
            title={t('ANNUAL_REPORTING_RETRO_MODAL_SELECT_LABEL')}
            placeholder={t('ANNUAL_REPORTING_RETRO_MODAL_SELECT_PLACEHOLDER')}
            ref={register}
            value={retroType || selectedRetroType}
            errorMessage={errors.retroType}
            onChange={() => dispatch(setSelectedRetroType(retroType))}
            disabled={omersUser}
          >
            {RETRO_TYPE_OPTIONS.map(option => (
              <option key={option.val} value={option.val}>
                {t(`common:${option.translation}`)}
              </option>
            ))}
          </SelectInput>
          <div className={styles.secondRow}>
            <TextInput
              labelText={t('ANNUAL_REPORTING_RETRO_MODAL_START_DATE')}
              type="text"
              name="retroStartDate"
              control={control}
              mask={maskDate}
              defaultValue={retroStartDateInStore}
              placeholder="MM/DD/YYYY"
              errorMessage={errors.retroStartDate}
              onBlur={e => dispatch(setRetroStartDateInStore(e.target.value))}
              onChange={() => {
                errors.retroEndDate?.type ===
                  'is endDate on or after startDate' &&
                  clearErrors(['retroEndDate']);
              }}
              disabled={omersUser}
            />
            <TextInput
              labelText={t('ANNUAL_REPORTING_RETRO_MODAL_END_DATE')}
              type="text"
              name="retroEndDate"
              mask={maskDate}
              control={control}
              defaultValue={retroEndDateInStore}
              placeholder="MM/DD/YYYY"
              errorMessage={errors.retroEndDate}
              onBlur={e => dispatch(setRetroEndDateInStore(e.target.value))}
              onChange={() => {
                errors.retroStartDate?.type ===
                  'is startDate on or before endDate' &&
                  clearErrors(['retroStartDate']);
              }}
              disabled={omersUser}
            />
            <div className={styles.calculator}>
              <Button
                isDisabled={calculateRetroDisabled || omersUser}
                onClick={() => retroPayHandler()}
              >
                <ReactSVG src="/images/calculator.svg" />
              </Button>
            </div>
          </div>
          <Divider />
          {showRetroModalTable && (
            <Table
              tableData={tableData}
              tableHeading={tableHeading}
              withRowBorders
              withColumnBorders
              withColoredHeaderRow
              headerColor="blue"
              withTableBorder
            />
          )}
          {showRetroTotalErrMsg && (
            <Notification
              type="error"
              messages={[t('ANNUAL_REPORTING_RETRO_PAY_ERROR')]}
              noMargin
              marginTop
            />
          )}
        </div>
      </Modal>
    </FormProvider>
  );
};

export default RetroPayModal;
