import classNames from 'classnames/bind';
import styles from './LinkList.module.scss';
import { DefaultLinkListItem } from './DefaultLinkListItem';
import { LinkItemType } from './types';

const cx = classNames.bind(styles);

interface ListProps {
  itemList: LinkItemType[];
  disableLinks?: boolean;
}

const LinkList = ({ itemList, disableLinks = false }: ListProps) => (
  <>
    {itemList.map(item => (
      <div
        className={cx({
          linkList: true,
          disabled: disableLinks
        })}
        key={`${item.formNumber}-${item.formName}`}
      >
        {item.CustomComponent ? (
          <item.CustomComponent item={item} disableLinks={disableLinks} />
        ) : (
          <DefaultLinkListItem item={item} disableLinks={disableLinks} />
        )}
      </div>
    ))}
  </>
);

export default LinkList;
