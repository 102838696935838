import { ActionCreator } from 'redux';
import { EaccessAPIError } from 'services/requests';
import { EapAction } from 'types';

import {
  RequestsFilterState,
  RequestsTableState,
  REQUESTS_TABS
} from './requestsReducer';
import { RequestsQuery } from './requestsSearchUtil';

export enum requestsActionTypes {
  SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
  APPLY_FILTER = 'APPLY_FILTER',
  SHOW_FORM_143_CHANGE_CONFIRMATION = 'SHOW_FORM_143_CHANGE_CONFIRMATION',
  HIDE_FORM_143_CHANGE_CONFIRMATION = 'HIDE_FORM_143_CHANGE_CONFIRMATION',
  SHOW_FORM_143_ERROR_MODAL = 'SHOW_FORM_143_ERROR_MODAL',
  HIDE_FORM_143_ERROR_MODAL = 'HIDE_FORM_143_ERROR_MODAL',
  RESET_ALL_FILTERS = 'RESET_ALL_FILTERS',
  SET_FILTER_IS_DIRTY = 'SET_FILTER_IS_DIRTY',
  SEARCH_SUCCEEDED = 'SEARCH_SUCCEEDED',
  SEARCH_FAILED = 'SEARCH_FAILED',
  GET_REQUEST_PDF_REQUESTED = 'GET_REQUEST_PDF_REQUESTED',
  GET_REQUEST_PDF_SUCCEEDED = 'GET_REQUEST_PDF_SUCCEEDED',
  GET_REQUEST_PDF_FAILED = 'GET_REQUEST_PDF_FAILED',
  ADD_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE = 'ADD_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE',
  REMOVE_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE = 'REMOVE_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE',
  ADD_ALL_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE = 'ADD_ALL_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE',
  REMOVE_ALL_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE = 'REMOVE_ALL_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE',
  VALIDATE_FORM_143_CHANGE_TYPE = 'VALIDATE_FORM_143_CHANGE_TYPE',
  COMPLETE_FORM_143_CHANGE = 'COMPLETE_FORM_143_CHANGE',
  SET_FORM_143_CONTEXT = 'SET_FORM_143_CONTEXT',
  FORM_143_CHANGE_VALIDATION_SUCCEEDED = 'FORM_143_CHANGE_VALIDATION_SUCCEEDED',
  FORM_143_CHANGE_VALIDATION_FAILED = 'FORM_143_CHANGE_VALIDATION_FAILED',
  FORM_143_CHANGE_VALIDATION_TIMEOUT = 'FORM_143_CHANGE_VALIDATION_TIMEOUT',
  DELETE_FORM_REQUESTED = 'DELETE_FORM_REQUESTED',
  DELETE_FORM_FAILED = 'DELETE_FORM_FAILED',
  DELETE_FORM_SUCCEEDED = 'DELETE_FORM_SUCCEEDED',
  DELETE_MULTIPLE_FORMS_REQUESTED = 'DELETE_MULTIPLE_FORMS_REQUESTED',
  DELETE_MULTIPLE_FORMS_FAILED = 'DELETE_MULTIPLE_FORMS_FAILED',
  DELETE_MULTIPLE_FORMS_SUCCEEDED = 'DELETE_MULTIPLE_FORMS_SUCCEEDED',
  CLEAR_DELETE_CALL_RESPONSE = 'CLEAR_DELETE_CALL_RESPONSE',
  GET_RETURNED_ACTIONS_COUNT_REQUESTED = 'GET_RETURNED_ACTIONS_COUNT_REQUESTED',
  GET_RETURNED_ACTIONS_COUNT_SUCCEEDED_FAILED = 'GET_RETURNED_ACTIONS_COUNT_SUCCEEDED_FAILED',
  SET_SORT = 'SET_SORT'
}

export const getReturnedCountAction: ActionCreator<EapAction> = () => ({
  type: requestsActionTypes.GET_RETURNED_ACTIONS_COUNT_REQUESTED
});

export const getReturnedCountActionSucceededFailed: ActionCreator<EapAction> = (
  data: number
) => ({
  type: requestsActionTypes.GET_RETURNED_ACTIONS_COUNT_SUCCEEDED_FAILED,
  data
});

export const clearDeleteCallResponse: ActionCreator<EapAction> = () => ({
  type: requestsActionTypes.CLEAR_DELETE_CALL_RESPONSE
});

export const deleteFormAction: ActionCreator<EapAction> = (data: {
  request: string;
  preventRefresh: boolean;
}) => ({
  type: requestsActionTypes.DELETE_FORM_REQUESTED,
  data
});

export const deleteMultipleFormsAction: ActionCreator<EapAction> = (data: {
  requests: Array<string>;
  preventRefresh: boolean;
}) => ({
  type: requestsActionTypes.DELETE_MULTIPLE_FORMS_REQUESTED,
  data
});

export const addAllToRequestsPendingDeleteOrOverride: ActionCreator<EapAction> = () => ({
  type: requestsActionTypes.ADD_ALL_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE
});

export const removeAllFromRequestsPendingDeleteOrOverride: ActionCreator<EapAction> = () => ({
  type: requestsActionTypes.REMOVE_ALL_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE
});

export const addToRequestsPendingDeleteOrOverride: ActionCreator<EapAction> = (
  data: string
) => ({
  type: requestsActionTypes.ADD_TO_REQUESTS_PENDING_DELETE_OR_OVERRIDE,
  data
});

export const removeFromRequestsPendingDeleteOrOverride: ActionCreator<EapAction> = (
  data: string
) => ({
  type: requestsActionTypes.REMOVE_FROM_REQUESTS_PENDING_DELETE_OR_OVERRIDE,
  data
});

export const setActiveTab: ActionCreator<EapAction> = (
  data: REQUESTS_TABS
) => ({
  type: requestsActionTypes.SET_ACTIVE_TAB,
  data
});

export const applyFilter: ActionCreator<EapAction> = (data: {
  tab: REQUESTS_TABS;
  filter: RequestsFilterState;
  query: RequestsQuery;
}) => ({
  type: requestsActionTypes.APPLY_FILTER,
  data
});

export const resetAllFilters: ActionCreator<EapAction> = () => ({
  type: requestsActionTypes.RESET_ALL_FILTERS
});

export const setFilterIsDirty: ActionCreator<EapAction> = (data: {
  tab: REQUESTS_TABS;
  isDirty: boolean;
}) => ({
  type: requestsActionTypes.SET_FILTER_IS_DIRTY,
  data
});

export const searchSucceeded: ActionCreator<EapAction> = (data: {
  tab: REQUESTS_TABS;
  table: RequestsTableState;
}) => ({
  type: requestsActionTypes.SEARCH_SUCCEEDED,
  data
});

export const searchFailed: ActionCreator<EapAction> = (data: {
  tab: REQUESTS_TABS;
}) => ({
  type: requestsActionTypes.SEARCH_FAILED,
  data
});

export const getPdfAction: ActionCreator<EapAction> = (data: any) => ({
  type: requestsActionTypes.GET_REQUEST_PDF_REQUESTED,
  data
});

export const setSort: ActionCreator<EapAction> = (data: {
  tab: REQUESTS_TABS;
  query: RequestsQuery;
  searchAscending: boolean;
  sortByLastName: boolean;
}) => ({
  type: requestsActionTypes.SET_SORT,
  data
});

export const validateForm143ChangeType: ActionCreator<EapAction<{
  request: unknown;
}>> = data => ({
  type: requestsActionTypes.VALIDATE_FORM_143_CHANGE_TYPE,
  data
});

export const completeForm143Change: ActionCreator<EapAction<{
  request: unknown;
}>> = data => ({
  type: requestsActionTypes.COMPLETE_FORM_143_CHANGE,
  data
});

export const setForm143Context: ActionCreator<EapAction<{
  request: unknown;
}>> = data => ({
  type: requestsActionTypes.SET_FORM_143_CONTEXT,
  data
});

export const showForm143ChangeConfirmation: ActionCreator<EapAction<{
  request: unknown;
}>> = data => ({
  type: requestsActionTypes.SHOW_FORM_143_CHANGE_CONFIRMATION,
  data
});

export const showForm143ErrorModal: ActionCreator<EapAction<{
  error: EaccessAPIError;
}>> = data => ({
  type: requestsActionTypes.SHOW_FORM_143_ERROR_MODAL,
  data
});

export const hideForm143ErrorModal: ActionCreator<EapAction<never>> = () => ({
  type: requestsActionTypes.HIDE_FORM_143_ERROR_MODAL
});

export const hideForm143ChangeConfirmation: ActionCreator<EapAction<
  never
>> = () => ({
  type: requestsActionTypes.HIDE_FORM_143_CHANGE_CONFIRMATION
});

export const validateForm143ChangeFailed: ActionCreator<EapAction<
  never
>> = () => ({
  type: requestsActionTypes.FORM_143_CHANGE_VALIDATION_FAILED
});

export const validateForm143ChangeSucceeded: ActionCreator<EapAction<
  never
>> = () => ({
  type: requestsActionTypes.FORM_143_CHANGE_VALIDATION_SUCCEEDED
});

export const validateForm143ChangeTimeout: ActionCreator<EapAction<
  never
>> = () => ({
  type: requestsActionTypes.FORM_143_CHANGE_VALIDATION_TIMEOUT
});
