import { useState, ChangeEvent, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Modal from 'components/modal/Modal';
import SelectInput from 'components/forms/inputs/selectInput/SelectInput';
import { MONTH_OPTIONS } from 'utils/constants';
import { RootState } from 'types';
import { createForm105Action } from 'containers/requestForm/contributionRemittance/form105Actions';
import useProperties from 'utils/hooks/useProperties';
import styles from './SelectRemittanceTypeModal.module.scss';

interface SelectRemittanceTypeModalProps {
  visible: boolean;
  onClose: () => void;
}
const SelectRemittanceTypeModal = ({
  visible,
  onClose
}: SelectRemittanceTypeModalProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['form105', 'common']);
  const { selectEmployerNumber } = useSelector(
    (state: RootState) => state.selectEmployers
  );
  const remittanceTypes = useProperties([
    ['remittanceTypes', 'Remittance Type']
  ]);
  const years = [
    new Date().getFullYear() - 1,
    new Date().getFullYear(),
    new Date().getFullYear() + 1
  ];

  const [remittanceMonth, setRemittanceMonth] = useState(
    new Date().getMonth().toString()
  );
  const [remittanceYear, setRemittanceYear] = useState(
    new Date().getFullYear().toString()
  );
  const [remittanceType, setRemittanceType] = useState('1');
  const typeOneToolTip = (
    <p>
      {t('SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP1_TYPE1_A')}
      <span className="semiBold">
        {t(
          'SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP1_TYPE1_B'
        )}
      </span>
      {t('SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP1_TYPE1_C')}
    </p>
  );

  const typeTwoToolTip = (
    <p>
      {t('SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP1_TYPE2_A')}
      <span className="semiBold">
        {t(
          'SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP1_TYPE2_B'
        )}
      </span>
      {t('SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP1_TYPE2_C')}
    </p>
  );

  useEffect(() => {
    if (remittanceType === '3') {
      setRemittanceMonth(new Date().getMonth().toString());
      setRemittanceYear(new Date().getFullYear().toString());
    }
  }, [remittanceType]);

  const toolTip = (
    <div>
      {t(
        'SELECT_REMITTANCE_TYPE_MODAL_REMITTANCE_TYPE_DROPDOWN_TITLE_TOOLTIP_1'
      )}
      <span className="semiBold">
        {t(
          'SELECT_REMITTANCE_TYPE_MODAL_REMITTANCE_TYPE_DROPDOWN_TITLE_TOOLTIP_2'
        )}
      </span>
      {t(
        'SELECT_REMITTANCE_TYPE_MODAL_REMITTANCE_TYPE_DROPDOWN_TITLE_TOOLTIP_3'
      )}
    </div>
  );

  return (
    <Modal
      visible={visible}
      onOk={() => {
        dispatch(
          createForm105Action({
            employerNo: selectEmployerNumber,
            remittanceType,
            month: Number(remittanceMonth) + 1,
            year: Number(remittanceYear)
          })
        );
        onClose();
      }}
      onClose={() => {
        onClose();
      }}
      onCancel={() => {
        onClose();
      }}
      titleText={t('SELECT_REMITTANCE_TYPE_MODAL_HEADER')}
      width={650}
      isCancel
      okText={t('SELECT_REMITTANCE_TYPE_MODAL_CONTINUE_BUTTON_TEXT')}
      cancelText={t('SELECT_REMITTANCE_TYPE_MODAL_CANCEL_BUTTON_TEXT')}
    >
      <div>{t('SELECT_REMITTANCE_TYPE_MODAL_SUBHEADER')}</div>
      <form className={styles.selectRemittanceTypeForm}>
        <SelectInput
          name="remittanceType"
          tooltip={toolTip}
          title={t(
            'SELECT_REMITTANCE_TYPE_MODAL_REMITTANCE_TYPE_DROPDOWN_TITLE'
          )}
          value={remittanceType}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setRemittanceType(e.target.value);
          }}
          placeholder={t('common:FORM_SELECT_PLACEHOLDER_SELECT_ONE')}
          testingTag="remittance-type-select"
        >
          {remittanceTypes.remittanceTypes.map(option => (
            <option key={option.val} value={option.key}>
              {option.val}
            </option>
          ))}
        </SelectInput>
        {remittanceType !== '3' && (
          <div className={styles.yearMonthDropdown}>
            <div className={styles.year}>
              <SelectInput
                name="remittanceMonth"
                title={t('SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE')}
                value={remittanceMonth}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setRemittanceMonth(e.target.value);
                }}
                placeholder={t('common:FORM_SELECT_PLACEHOLDER_SELECT_ONE')}
                testingTag="remittance-month-select"
                tooltip={
                  remittanceType === '1'
                    ? [
                        typeOneToolTip,
                        t(
                          'SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP2_TYPE1'
                        ),
                        t(
                          'SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP3_TYPE1'
                        )
                      ]
                    : [
                        typeTwoToolTip,
                        t(
                          'SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP2_TYPE2'
                        ),
                        t(
                          'SELECT_REMITTANCE_TYPE_MODAL_MONTH_DROPDOWN_TITLE_TOOLTIP3_TYPE2'
                        )
                      ]
                }
              >
                {MONTH_OPTIONS.map(option => (
                  <option key={option.val} value={option.value}>
                    {`${t(option.translationLong)} (`}
                    {`${t(option.contributionMonth)})`}
                  </option>
                ))}
              </SelectInput>
            </div>
            <div className={styles.month}>
              <SelectInput
                name="remittanceYear"
                title={t('SELECT_REMITTANCE_TYPE_MODAL_YEAR_DROPDOWN_TITLE')}
                value={remittanceYear}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setRemittanceYear(e.target.value);
                }}
                placeholder={t('common:FORM_SELECT_PLACEHOLDER_SELECT_ONE')}
                testingTag="remittance-year-select"
              >
                {years.map(year => (
                  <option key={year.toString()} value={year.toString()}>
                    {year.toString()}
                  </option>
                ))}
              </SelectInput>
            </div>
          </div>
        )}
      </form>
    </Modal>
  );
};

export default SelectRemittanceTypeModal;
