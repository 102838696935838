import { FormDataFieldsObj, FileObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postFileData, postData, deleteData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getFileBuilder = () =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/download/import-file-builder`,
    undefined,
    '',
    'blob'
  );

export const getBatchProcessingImports = (employerGroupNo: number) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/import/${employerGroupNo}`
  );

export const getReconciliationYearsForGroup = (employerNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e119/reconciliation-years?employerNo=${employerNo}`
  );

export const sendImport = (
  formDataFields: FormDataFieldsObj,
  data: Array<FileObj>
) =>
  postFileData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/import`, {
    formDataFields,
    data
  });

export const getImportErrorsToReview = (batchProcessNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/import/${batchProcessNumber}/errors`
  );

export const completeReviewImportErrors = (batchProcessNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/import/${batchProcessNumber}/errors/reviewed`
  );

export const deleteImports = (batchProcessNumber: string) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/import/${batchProcessNumber}`
  );

export const submitBatch = (
  employerGroupNo: string,
  formId: string,
  requestIds: any
) => {
  if (requestIds) {
    return postData(
      `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/submit/${employerGroupNo}/${formId}?requestIds=${Object.keys(
        requestIds
      )}`
    );
  }
  return postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/submit/${employerGroupNo}/${formId}`
  );
};

export const getSubmission = (employerGroupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/submit/${employerGroupNo}`
  );

export const getForm119ToBatchSubmit = (employerGroupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/submit/${employerGroupNo}/119`
  );

export const getForm165aToBatchSubmit = (employerGroupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/submit/${employerGroupNo}/165a`
  );

export const getForm102ToBatchSubmit = (employerGroupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/submit/${employerGroupNo}/102`
  );

export const searchBatch = (data: any) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/search`, data);

export const checkPreProcessingBatchSubmissionStatus = (
  employerGroupNo: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/async-activity-inprogress/${employerGroupNo}`
  );
