import { userSessionActionTypes } from 'containers/auth/userSessionActions';
import { EapAction, EmployerGroup, User } from 'types';
import { selectEmployersActionTypes } from './selectEmployersActions';

export interface SelectEmployersState {
  readonly selectEmployerName: string;
  readonly selectEmployerNumber: number;
  readonly selectGroupNumber: string;
  readonly selectedAttestationDueDate: string | null;
  readonly selectedAttestationDueDays: number | null;
  readonly selectedAttestationRequired: boolean;
  readonly groups: Array<EmployerGroup>;
  readonly classCoverage: Array<any>;
  readonly usersInCurrentGroup: Array<User>;
  readonly attestationCompleted: boolean;
  readonly showAccountLockedModal: boolean;
  readonly showAccountLockedModalForEmployer: string;
  readonly showAttestationRequiredModal: boolean;
  readonly showAttestationRequiredBanner: boolean;
}

const initialState = {
  selectEmployerName: '',
  selectEmployerNumber: 0,
  selectGroupNumber: '',
  selectedAttestationDueDate: null,
  selectedAttestationDueDays: null,
  selectedAttestationRequired: false,
  groups: [],
  classCoverage: [],
  usersInCurrentGroup: [],
  attestationCompleted: false,
  showAccountLockedModal: false,
  showAccountLockedModalForEmployer: '',
  showAttestationRequiredModal: false,
  showAttestationRequiredBanner: false
};

const selectEmployers = (
  state: SelectEmployersState = initialState,
  action: EapAction
): SelectEmployersState => {
  const { type, data } = action;
  switch (type) {
    case userSessionActionTypes.LOGIN_SUCCEEDED:
      return {
        ...state,
        groups: data.userProfile.groups
      };
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
    case selectEmployersActionTypes.SELECT_GROUP_NUMBER_SUCCESS:
      return {
        ...state,
        selectEmployerName: data.name,
        selectEmployerNumber: data.employerNumber,
        selectGroupNumber: data.groupNumber,
        selectedAttestationRequired: data.attestationRequired,
        selectedAttestationDueDate: data.attestationDueDate,
        selectedAttestationDueDays: data.attestationDueDays
      };
    case selectEmployersActionTypes.SET_CLASS_COVERAGE:
      return {
        ...state,
        classCoverage: data
      };
    case selectEmployersActionTypes.GET_CURRENT_GROUP_USERS_SUCCEEDED:
      return {
        ...state,
        usersInCurrentGroup: data
      };
    case selectEmployersActionTypes.SET_ATTESTATION_COMPLETED:
      return {
        ...state,
        attestationCompleted: data
      };
    case selectEmployersActionTypes.SHOW_ACCOUNT_LOCKED_MODAL:
      return {
        ...state,
        showAccountLockedModal: data.showModal,
        showAccountLockedModalForEmployer: data.showModalFor
      };
    case selectEmployersActionTypes.SHOW_ATTESTATION_REQUITRED_MODAL:
      return {
        ...state,
        showAttestationRequiredModal: data
      };
    case selectEmployersActionTypes.SHOW_ATTESTATION_REQUITRED_BANNER:
      return {
        ...state,
        showAttestationRequiredBanner: data
      };
    case selectEmployersActionTypes.UPDATE_ATTESTATION_DEADLINE: {
      const newGroupData = state.groups?.map(group =>
        group?.groupNumber === data.groupNo
          ? {
              ...group,
              attestationDueDate: data.dueDate,
              attestationDueDays: data.dueDays,
              attestationRequired: data.attestationRequired
            }
          : group
      );

      return {
        ...state,
        groups: newGroupData,
        selectedAttestationDueDate: data.dueDate,
        selectedAttestationDueDays: data.dueDays
      };
    }
    default:
      return state;
  }
};

export default selectEmployers;
