import { DataObj, FormDataFieldsObj, FileObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { postData, putData, getData, postFileData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getInbox = (employerGroupNumber: string, data: DataObj) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/inbox/${employerGroupNumber}`,
    data
  );

export const getInboxCount = (employerGroupNumber: DataObj) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/inbox/${employerGroupNumber}/count/unread`
  );

export const getInboxUnresolvedCount = (employerGroupNumber: DataObj) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/inbox/${employerGroupNumber}/count/unresolved`
  );

export const updateCorr = (data: DataObj) =>
  putData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/${data.corrId}`, data);

export const getMessageThread = (caseID: string) =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/${caseID}`);

export const updateMessageThreadStatus = (caseID: string, data: DataObj) =>
  putData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/${caseID}`, data);

export const sendMessage = (
  formDataFields: FormDataFieldsObj,
  data: Array<FileObj>
) =>
  postFileData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr`, {
    formDataFields,
    data
  });

export const sendMessageThreadReply = (
  formDataFields: FormDataFieldsObj,
  data: Array<FileObj>
) =>
  postFileData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/econv`, {
    formDataFields,
    data
  });

export const downloadAttachment = (orbitId: string, groupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/attachment/${orbitId}?employerGroupNo=${groupNo}`,
    undefined,
    undefined,
    'blob'
  );

export const getEcorrTopics = () =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/topics`);

export const getEcorrContacts = (groupNo: string) =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/contacts/${groupNo}`);

export const getDmsDocumentList = (employerGroupNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/inbox-dms/${employerGroupNumber}`
  );

export const getDmsAttachments = (
  documentReferenceId: string,
  employerGroupNumber: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/attachment-dms/${documentReferenceId}?employerGroupNo=${employerGroupNumber}`,
    undefined,
    undefined,
    'blob'
  );
