import classNames from 'classnames/bind';

import styles from './ContainerBorder.module.scss';

const cx = classNames.bind(styles);

interface ContainerBorderProps {
  children?: React.ReactNode;
  accent?: boolean;
  noMargin?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  marginLeft?: boolean;
  marginRight?: boolean;
  noPadding?: boolean;
  customPadding?: boolean;
  header?: string | React.ReactNode;
  testingTag?: string;
  autoTestingTag?: string;
  noBorder?: boolean;
}
const ContainerBorder = ({
  children,
  accent = false,
  noMargin = false,
  marginTop = false,
  marginBottom = false,
  marginLeft = false,
  marginRight = false,
  noPadding = false,
  customPadding = false,
  header,
  testingTag,
  autoTestingTag,
  noBorder
}: ContainerBorderProps) => {
  const containerClassNames = cx({
    containerBorder: true,
    accent,
    noMargin,
    noPadding,
    customPadding,
    marginTop,
    marginBottom,
    marginLeft,
    marginRight,
    noBorder
  });

  return (
    <div
      className={containerClassNames}
      data-testid={testingTag}
      data-auto={autoTestingTag}
    >
      {header && <div className={styles.header}>{header}</div>}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default ContainerBorder;
