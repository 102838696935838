import { format, lastDayOfMonth, parse } from 'date-fns';
import { getDateFromYMDStrings, getYMDStringsFromDate } from './getters';

/**
 * to use the date preference selection, the consumer should not pass 'pattern' property, let it get the value from the local storage
 */
export interface FormatDateOptions {
  ignoreTimezone?: boolean;
  showTime?: boolean;
  showMonthAndYearOnly?: boolean;
  ymd?: boolean;
  showTimeOnly?: boolean;
  pattern?: string;
  showTimeMonthYear?: boolean;
}

export const dateIgnoreTimezone = (date: Date) =>
  new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);

export const formatDate = (
  arg: string | Date | null,
  {
    ignoreTimezone,
    showTime,
    showMonthAndYearOnly,
    ymd,
    showTimeOnly,
    pattern = 'yyyy-MMM-dd',
    showTimeMonthYear
  }: FormatDateOptions = {}
) => {
  let dateStr = typeof arg === 'string' ? arg : '';
  if (!dateStr && !arg) {
    return '';
  }
  if (typeof arg === 'string') {
    if (
      new RegExp(/^\d{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$/).exec(
        dateStr
      ) !== null
    ) {
      dateStr = dateStr.replace(/-/g, '/');
    } else if (
      /^(\d{4})-(\d{2})-(\d{2})T(.*)Z$/.exec(dateStr) &&
      !showTimeOnly
    ) {
      // this format 'dddd-dd-ddTdd:dd:ddZ' comes from server, need to convert it to EST, so no mutation
      dateStr = `${RegExp.$1}/${RegExp.$2}/${RegExp.$3} ${RegExp.$4}`;
    }
  }
  let date = arg && typeof arg === 'string' ? new Date(dateStr) : (arg as Date);
  if (ignoreTimezone) {
    // https://stackoverflow.com/a/52352512/220671
    date = dateIgnoreTimezone(date);
  }
  if (showTimeOnly) {
    return format(date, 'h:mm a');
  }
  if (showTime) {
    return format(date, `${pattern}  h:mm a`);
  }
  if (showMonthAndYearOnly) {
    return format(date, 'MMMM yyyy');
  }
  if (ymd) {
    return format(date, 'yyyy-MM-dd');
  }
  if (showTimeMonthYear) {
    return format(date, `${pattern}  h:mm a`);
  }
  return format(date, pattern);
};

/**
 * Convert date YYYY-MM-DD to MM/DD/YYYY
 * @param date a date string of form 'YYYY-MM-DD'
 */
export const convertDateYMDtoMDY = (date: string): string => {
  try {
    return format(parse(date, 'yyyy-MM-dd', new Date()), 'MM/dd/yyyy');
  } catch (e) {
    return '';
  }
};

/**
 * Convert date MM/DD/YYYY to YYYY-MM-DD
 * @param date a date string of form 'MM/dd/yyyy'
 */
export const convertDateMDYtoYMD = (date: string): string => {
  try {
    return format(parse(date, 'MM/dd/yyyy', new Date()), 'yyyy-MM-dd');
  } catch (error) {
    return '';
  }
};

/**
 * Find the later of the two input dates
 * @param date a date string of form 'YYYY-MM-DD'
 * @param otherDate a date string of form 'YYYY-MM-DD'
 * @return a date string of form 'YYYY-MM-DD'
 */
export const findLaterDate = (date: string, otherDate: string): string =>
  date > otherDate ? date : otherDate;

/**
 * Find the 1st day of the month following date
 * @param date a date string of form 'YYYY-MM-DD'
 * @return a date string of form 'YYYY-MM-DD'
 */
export const findFirstDateAfterDate = (date: string): string => {
  const { day, month, year } = getYMDStringsFromDate(date);
  if (day === '' || month === '' || year === '') {
    return '';
  }
  const resDay = 1;
  const resMonth = Number(month) !== 12 ? Number(month) + 1 : 1;
  const resYear = Number(month) !== 12 ? Number(year) : Number(year) + 1;
  return (
    getDateFromYMDStrings(
      resYear.toString(),
      resMonth.toString(),
      resDay.toString()
    ) ?? ''
  );
};

/**
 * Find the last day of the month following date
 * @param date a date string of form 'YYYY-MM-DD'
 * @return a date string of form 'YYYY-MM-DD'
 */

export const findLastDateAfterDate = (date: string): string => {
  const { day, month, year } = getYMDStringsFromDate(date);
  if (day === '' || month === '' || year === '') {
    return '';
  }

  const resMonth = Number(month) !== 12 ? Number(month) : 1;
  const resYear = Number(month) !== 12 ? Number(year) : Number(year) + 1;

  const resDay = new Date(resYear, resMonth, 0).getDate();
  return (
    getDateFromYMDStrings(
      resYear.toString(),
      resMonth.toString(),
      resDay.toString()
    ) ?? ''
  );
};

/**
 * Calculate enrolment date based on hireDate and electionDate
 *  enrolment date is the 1st day of the month following hireDate
 *  and electionDate, whichever is later
 * @param hireDate a date string of form 'MM/dd/yyyy'
 * @param electionDate a date string of form 'MM/dd/yyyy'
 * @param setValue methods.setValue, where methods = useFormContext()
 */
export const calculateEnrolmentDate = (
  hireDate: string | undefined,
  electionDate: string | undefined,
  setValue?: any
) => {
  const formattedHireDate =
    hireDate !== undefined ? convertDateMDYtoYMD(hireDate) : '';
  const formattedElectionDate =
    electionDate !== undefined ? convertDateMDYtoYMD(electionDate) : '';
  const laterDate = findLaterDate(formattedHireDate, formattedElectionDate);

  const enrolmentDate = convertDateYMDtoMDY(findFirstDateAfterDate(laterDate));

  if (setValue) {
    setValue('enrolDate', enrolmentDate);
  }

  return enrolmentDate;
};

export const addMonths = (date: any, months: number) => {
  date.setMonth(date.getMonth() + months);

  return date;
};

export const convertToDateString = (date: any) => {
  date.setDate(date.getDate() + 1);

  return date;
};

/**
 * Calculate last date based on hireDate and electionDate
 *  enrolment date is the last day of the month following hireDate
 *  and electionDate, whichever is later
 * @param hireDate a date string of form 'MM/dd/yyyy'
 * @param electionDate a date string of form 'MM/dd/yyyy'
 * */
export const endOfMonthFollowingLater = (
  hireDate: string | undefined,
  electionDate: string | undefined
) => {
  const formattedHireDate =
    hireDate !== undefined ? convertDateMDYtoYMD(hireDate) : '';
  const formattedElectionDate =
    electionDate !== undefined ? convertDateMDYtoYMD(electionDate) : '';
  const laterDate = findLaterDate(formattedHireDate, formattedElectionDate);
  const formattedLaterDate = convertToDateString(new Date(laterDate));
  const followingMonthDate = addMonths(formattedLaterDate, 1);

  return formatDate(lastDayOfMonth(new Date(followingMonthDate)) || '', {
    pattern: 'MM/dd/yyyy'
  });
};
