import { ActionCreator } from 'redux';

import { DataObj, EapAction } from 'types';
import {
  ExceptionsObject,
  MemberSearchResult,
  PaymentScheduleModel,
  ServicePurchaseModel,
  ReportedUnReportedServicePurchaseModel
} from 'interfaces';
import { Form105Data } from './form105Reducer';

export enum form105ActionTypes {
  SET_REQUEST_NUMBER_FORM_105 = 'SET_REQUEST_NUMBER_FORM_105',
  GET_FORM_105_REQUESTED = 'GET_FORM_105_REQUESTED',
  CREATE_FORM_105 = 'CREATE_FORM_105',
  GET_FORM_105_SUCCESS = 'GET_FORM_105_SUCCESS',
  GET_FORM_105_FAILED = 'GET_FORM_105_FAILED',
  SET_FORM_105_STEP = 'SET_FORM_105_STEP',
  SAVE_FORM_105 = 'SAVE_FORM_105',
  SAVE_FORM_105_SUCCESS = 'SAVE_FORM_105_SUCCESS',
  SAVE_FORM_105_FAILED = 'SAVE_FORM_105_FAILED',
  CLEAR_FORM_105_STATUS = 'CLEAR_FORM_105_STATUS',
  SET_ADD_NEW_SERVICE_PURCHASE = 'SET_ADD_NEW_SERVICE_PURCHASE',
  APPLY_SERVICE_PURCHASE_MEMBER_FILTER = 'APPLY_SERVICE_PURCHASE_MEMBER_FILTER',
  SET_SERVICE_PURCHASE_MEMBER_FILTER_IS_DIRTY = 'SET_SERVICE_PURCHASE_MEMBER_FILTER_IS_DIRTY',
  SEARCH_SERVICE_PURCHASES_SUCCEED = 'SEARCH_SERVICE_PURCHASES_SUCCEED',
  SEARCH_SERVICE_PURCHASES_FAILED = 'SEARCH_SERVICE_PURCHASE_FAILED',
  SET_SELECTED_SERVICE_PURCHASE_TO_ADD = 'SET_SELECTED_SERVICE_PURCHASE_TO_ADD',
  ADD_SELECTED_REPORTED_SERVICE_PURCHASE_REQUESTED = 'ADD_SELECTED_REPORTED_SERVICE_PURCHASE_REQUESTED',
  ADD_SELECTED_REPORTED_SERVICE_PURCHASE_SUCCEED = 'ADD_SELECTED_REPORTED_SERVICE_PURCHASE_SUCCEED',
  ADD_SELECTED_REPORTED_SERVICE_PURCHASE_FAILED = 'ADD_SELECTED_REPORTED_SERVICE_PURCHASE_FAILED',
  RESET_SERVICE_PURCHASE_MEMBER_FILTER = 'RESET_SERVICE_PURCHASE_MEMBER_FILTER',
  REFRESH_SERVICE_PURCHASE_REQUESTED = 'REFRESH_SERVICE_PURCHASE_REQUESTED',
  REFRESH_SERVICE_PURCHASE_SUCCEED = 'REFRESH_SERVICE_PURCHASE_SUCCEED',
  REFRESH_SERVICE_PURCHASE_FAILED = 'REFRESH_SERVICE_PURCHASE_FAILED',
  REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_REQUESTED = 'REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_REQUESTED',
  REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_SUCCESS = 'REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_SUCCESS',
  REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_FAILED = 'REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_FAILED',
  STASH_DIRTY_VALUES = 'STASH_DIRTY_VALUES',
  CLEAR_FORM_105_DATA = 'CLEAR_FORM_105_DATA',
  SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_REQUESTED = 'SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_REQUESTED',
  SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_SUCCEED = 'SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_SUCCEED',
  SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_FAILED = 'SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_FAILED',
  ADD_UNREPORTED_SERVICE_PURCHASE_REQUESTED = 'ADD_UNREPORTED_SERVICE_PURCHASE_REQUESTED',
  ADD_UNREPORTED_SERVICE_PURCHASE_SUCCEED = 'ADD_UNREPORTED_SERVICE_PURCHASE_SUCCEED',
  ADD_UNREPORTED_SERVICE_PURCHASE_FAILED = 'ADD_UNREPORTED_SERVICE_PURCHASE_FAILED',
  GET_SUMMARY_MODEL_FORM_105 = 'GET_SUMMARY_MODEL_FORM_105',
  GET_SUMMARY_MODEL_FORM_105_SUCCESS = 'GET_SUMMARY_MODEL_FORM_105_SUCCESS',
  GET_SUMMARY_MODEL_FORM_105_FAILED = 'GET_SUMMARY_MODEL_FORM_105_FAILED',
  SUBMIT_FORM_105 = 'SUBMIT_FORM_105',
  SUBMIT_FORM_105_SUCCESS = 'SUBMIT_FORM_105_SUCCESS',
  SET_FORM_105_SUBMIT_STATUS = 'SET_FORM_105_SUBMIT_STATUS',
  OVERRIDE_FORM_105_EXCEPTIONS = 'OVERRIDE_FORM_105_EXCEPTIONS',
  DELETE_FORM_105 = 'DELETE_FORM_105',
  GET_FORM_105_EXCEPTIONS = 'GET_FORM_105_EXCEPTIONS',
  GET_EXCEPTIONS_FORM_105_SUCCESS = 'GET_EXCEPTIONS_FORM_105_SUCCESS'
}

export const setForm105RequestNumberAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form105ActionTypes.SET_REQUEST_NUMBER_FORM_105,
  data
});

export const createForm105Action: ActionCreator<EapAction> = (data: {
  employerNo: number;
  remittanceType: string;
  month: number;
  year: number;
}) => ({
  type: form105ActionTypes.CREATE_FORM_105,
  data
});

export const getForm105Action: ActionCreator<EapAction> = (data: string) => ({
  type: form105ActionTypes.GET_FORM_105_REQUESTED,
  data
});

export const getForm105SuccessAction: ActionCreator<EapAction> = (data: {
  employerNo?: string;
  requestNumber: string;
  commentsToMember: string;
  notes: string;
  exceptions: Array<ExceptionsObject>;
  memberInfo: MemberSearchResult | null;
  contributionModel: Array<any>;
  paymentScheduleModels: Array<PaymentScheduleModel>;
  servicePurchaseModel: Array<any>;
  paymentOwingModels: Array<any>;
  summaryModel: Array<any>;
  showLateRemittanceChargesMsg: boolean;
  remittanceType: string;
  formMonth: string;
  paymentMethod: string;
}) => ({
  type: form105ActionTypes.GET_FORM_105_SUCCESS,
  data
});

export const getForm105FailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.GET_FORM_105_FAILED
});

export const setForm105StepAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form105ActionTypes.SET_FORM_105_STEP,
  data
});

export const clearForm105DataAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.CLEAR_FORM_105_DATA
});

export const clearForm105StatusAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.CLEAR_FORM_105_STATUS
});

export const saveForm105Action: ActionCreator<EapAction> = (data: {
  formData: Form105Data;
  commentsToMember: string;
  notes: string;
  exceptions?: Array<ExceptionsObject>;
  reviewForm?: boolean;
  submitForm?: boolean;
}) => ({
  type: form105ActionTypes.SAVE_FORM_105,
  data
});

export const saveForm105SuccessAction: ActionCreator<EapAction> = (
  data: {
    commentsToMember: string;
    notes: string;
  },
  payload: any
) => ({
  type: form105ActionTypes.SAVE_FORM_105_SUCCESS,
  data,
  payload
});

export const saveForm105FailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.SAVE_FORM_105_FAILED
});

export const setAddNewServicePurchaseAction: ActionCreator<EapAction> = (data: {
  isAddingNewServicePurchase: boolean;
}) => ({
  type: form105ActionTypes.SET_ADD_NEW_SERVICE_PURCHASE,
  data
});

export const stashDirtyValues: ActionCreator<EapAction> = (data: any) => ({
  type: form105ActionTypes.STASH_DIRTY_VALUES,
  data
});

export const removeAddedServicePurchaseForm105Action: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form105ActionTypes.REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_REQUESTED,
  data
});

export const applyServicePurchaseMemberFilter: ActionCreator<EapAction> = (data: {
  searchByMemberSelect?: string;
  searchByMemberValue?: string;
  typeOfPurchase?: string;
  dateReceivedFrom?: string;
  dateReceivedTo?: string;
}) => ({
  type: form105ActionTypes.APPLY_SERVICE_PURCHASE_MEMBER_FILTER,
  data
});

export const resetServicePurchaseMemberFilter: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.RESET_SERVICE_PURCHASE_MEMBER_FILTER
});

export const searchServicePurchasesSucceedAction: ActionCreator<EapAction> = (data: {
  requestSearchResult: {
    totalRowCount: number;
    servicePurchases: Array<ServicePurchaseModel>;
  };
}) => ({
  type: form105ActionTypes.SEARCH_SERVICE_PURCHASES_SUCCEED,
  data
});

export const searchServicePurchasesFailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.SEARCH_SERVICE_PURCHASES_FAILED
});

export const setServicePurchaseMemberFilterIsDirty: ActionCreator<EapAction> = (data: {
  isFilterDirty: boolean;
}) => ({
  type: form105ActionTypes.SET_SERVICE_PURCHASE_MEMBER_FILTER_IS_DIRTY,
  data
});

export const setSelectedServicePurchaseToAddAction: ActionCreator<EapAction> = (data: {
  purchaseNo: number;
  isSelected: boolean;
}) => ({
  type: form105ActionTypes.SET_SELECTED_SERVICE_PURCHASE_TO_ADD,
  data
});

export const addSelectedReportedServicePurchaseAction: ActionCreator<EapAction> = (data: {
  servicePurchasesToBeAdded: number[];
}) => ({
  type: form105ActionTypes.ADD_SELECTED_REPORTED_SERVICE_PURCHASE_REQUESTED,
  data
});

export const addSelectedReportedServicePurchaseSucceedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.ADD_SELECTED_REPORTED_SERVICE_PURCHASE_SUCCEED
});

export const addSelectedReportedServicePurchaseFailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.ADD_SELECTED_REPORTED_SERVICE_PURCHASE_FAILED
});

export const refreshServicePurchaseAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.REFRESH_SERVICE_PURCHASE_REQUESTED
});

export const refreshServicePurchaseSucceedAction: ActionCreator<EapAction> = (data: {
  servicePurchaseReportedModels: Array<ReportedUnReportedServicePurchaseModel>;
  servicePurchaseUnreportedModels: Array<
    ReportedUnReportedServicePurchaseModel
  >;
}) => ({
  type: form105ActionTypes.REFRESH_SERVICE_PURCHASE_SUCCEED,
  data
});

export const refreshServicePurchaseFailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.REFRESH_SERVICE_PURCHASE_FAILED
});

export const removeAddedServicePurchaseForm105SuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form105ActionTypes.REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_SUCCESS,
  data
});

export const removeAddedServicePurchaseForm105FailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_FAILED
});

export const searchMemberForAddingServicePurchaseAction: ActionCreator<EapAction> = (data: {
  type: string;
  sin?: string;
  employeeId?: string;
  employerNumber: number;
}) => ({
  type: form105ActionTypes.SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_REQUESTED,
  data
});

export const searchMemberForAddingServicePurchaseSucceedAction: ActionCreator<EapAction> = (data: {
  memberSearchResult: DataObj;
}) => ({
  type: form105ActionTypes.SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_SUCCEED,
  data
});

export const searchMemberForAddingServicePurchaseFailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_FAILED
});

export const addUnreportedServicePurchaseAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.ADD_UNREPORTED_SERVICE_PURCHASE_REQUESTED
});

export const addUnreportedServicePurchaseSucceedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.ADD_UNREPORTED_SERVICE_PURCHASE_SUCCEED
});

export const addUnreportedServicePurchaseFailedAction: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.ADD_UNREPORTED_SERVICE_PURCHASE_FAILED
});

export const getSummaryModelForForm105Action: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form105ActionTypes.GET_SUMMARY_MODEL_FORM_105,
  data
});

export const getSummaryModelForForm105SuccessAction: ActionCreator<EapAction> = (
  data,
  employerName
) => ({
  type: form105ActionTypes.GET_SUMMARY_MODEL_FORM_105_SUCCESS,
  data: {
    ...data,
    employerName
  }
});

export const getSummaryModelForForm105FailedAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form105ActionTypes.GET_SUMMARY_MODEL_FORM_105_FAILED,
  data
});

export const submitForm105Action: ActionCreator<EapAction> = () => ({
  type: form105ActionTypes.SUBMIT_FORM_105
});

export const submitForm105SuccessAction: ActionCreator<EapAction> = (data: {
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form105ActionTypes.SUBMIT_FORM_105_SUCCESS,
  data
});

export const setForm105SubmitStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form105ActionTypes.SET_FORM_105_SUBMIT_STATUS,
  data
});

export const overrideForm105ExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: form105ActionTypes.OVERRIDE_FORM_105_EXCEPTIONS,
  data
});

export const deleteForm105Action: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form105ActionTypes.DELETE_FORM_105,
  data
});

export const getExceptionsForm105Action: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form105ActionTypes.GET_FORM_105_EXCEPTIONS,
  data
});

export const getExceptionsForm105SuccessAction: ActionCreator<EapAction> = (
  data,
  employerName
) => ({
  type: form105ActionTypes.GET_EXCEPTIONS_FORM_105_SUCCESS,
  data: {
    ...data,
    employerName
  }
});
