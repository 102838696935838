import { AriaRole, MouseEventHandler } from 'react';
import { ReactSVG } from 'react-svg';
import { ThemeUICSSObject } from 'theme-ui';

import IconMap, {
  IconNamesLarge,
  IconNamesNavigation,
  IconNamesRegular,
  IconNamesSmall,
  IconNamesStatus,
  IconNamesFileFormat,
  IconNamesXSmall
} from './Icons';

export enum IconSize {
  XSMALL = 16,
  SMALL = 20,
  REGULAR = 24,
  LARGE = 30,
  XLARGE = 64
}

export const IconNames = {
  ...IconNamesNavigation,
  ...IconNamesLarge,
  ...IconNamesRegular,
  ...IconNamesSmall,
  ...IconNamesXSmall,
  ...IconNamesStatus,
  ...IconNamesFileFormat
};

export type IconName =
  | IconNamesNavigation
  | IconNamesLarge
  | IconNamesRegular
  | IconNamesSmall
  | IconNamesXSmall
  | IconNamesStatus
  | IconNamesFileFormat;

const imgStyle = (size: IconSize) => ({
  svg: {
    display: 'flex',
    justifyContent: 'center',
    width: size,
    height: size
  }
});

const addTrailingSlash = (path: string) =>
  process.env.STORYBOOK ? path : `/${path}`;

export interface IconProps {
  icon: IconName;
  size?: number;
  sx?: ThemeUICSSObject;
  color?: string;
  role?: AriaRole;
  className?: string;
  wrapper?: 'div' | 'span';
  onClick?: MouseEventHandler;
}

const Icon: React.FC<IconProps> = ({
  icon,
  size = IconSize.SMALL,
  color = 'brandNavy100',
  role,
  className,
  wrapper = 'div',
  sx,
  onClick,
  ...props
}) => {
  const iconPath = IconMap[icon] ?? null;

  return iconPath ? (
    <ReactSVG
      role={role}
      src={addTrailingSlash(iconPath)}
      className={className}
      wrapper={wrapper}
      sx={{
        color,
        ...imgStyle(size),
        ...sx
      }}
      onClick={onClick}
      {...props}
    />
  ) : null;
};

export default Icon;
