import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form-legacy';
import { useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import { RootState } from 'types';
import { maskEmployeeId } from 'utils';
import { FILTER_STATUS, filterValues } from 'utils/constants';
import useProperties from 'utils/hooks/useProperties';
import SelectInput from 'components/forms/inputs/selectInput/SelectInput';
import TextInput from 'components/forms/inputs/textInput/TextInput';
import Button from 'components/button/Button';
import ResetButton from 'components/resetButton/ResetButton';
import {
  setArchivedMessagesButtonStatus,
  applyArchivedMessagesFilterRequestedAction
} from '../ArchivedMessagesActions';
import {
  archivedMessagesTopicCategories,
  IArchivedMessagesButtonStatus
} from '../types';
import styles from './ArchivedMessagesFilter.module.scss';

enum archivedMessagesFieldNames {
  STATUS = 'status',
  TOPIC = 'topic',
  EMPLOYEE_ID = 'employeeId'
}
export interface IArchivedMessagesValues {
  status: string;
  topic: string;
  employeeId: string;
}

export const defaultValues = {
  status: FILTER_STATUS[2].val,
  topic: FILTER_STATUS[0].val,
  employeeId: ''
};

export const defaultPayload = {
  ...defaultValues,
  status: filterValues.ALL_UNRESOLVED
};

export const privateFunctions = {
  getTopics: (categories: { key: string; val: string }[]) => {
    const availableCategoryKeys = [
      '119',
      '165',
      'D43',
      'T43',
      'R43',
      'X43',
      '190'
    ];

    return [
      { val: FILTER_STATUS[0].val, translation: FILTER_STATUS[0].translation },
      ...categories
        .filter(category => availableCategoryKeys.includes(category.key))
        .map(category => ({
          ...category,
          translation: `TOPIC_${category.key}`
        }))
    ];
  },
  handleApplyButtonClick: (
    dispatch: Function,
    currentValues: IArchivedMessagesValues
  ) => dispatch(applyArchivedMessagesFilterRequestedAction(currentValues)),
  handleButtonState: (
    areDefaultValuesSelected: boolean,
    areCurrentValuesInStore: boolean,
    buttonStatus: IArchivedMessagesButtonStatus,
    dispatch: Function
  ) => {
    // Masked Input does not work in sync with onChange; input state updates one step behind user input
    // Because of the masked input, here values are compared to update button state
    // We may consider fixing the masked input and trigger button status change onChange
    if (areDefaultValuesSelected) {
      if (buttonStatus.isApplyButtonClicked) {
        dispatch(
          setArchivedMessagesButtonStatus({
            ...buttonStatus,
            isResetButtonDisabled: true,
            isApplyButtonDisabled: false
          })
        );
        return;
      }
      dispatch(
        setArchivedMessagesButtonStatus({
          ...buttonStatus,
          isResetButtonDisabled: true,
          isApplyButtonDisabled: true
        })
      );
      return;
    }
    if (!areDefaultValuesSelected && areCurrentValuesInStore) {
      dispatch(
        setArchivedMessagesButtonStatus({
          ...buttonStatus,
          isResetButtonDisabled: false,
          isApplyButtonDisabled: true
        })
      );
      return;
    }
    dispatch(
      setArchivedMessagesButtonStatus({
        ...buttonStatus,
        isResetButtonDisabled: false,
        isApplyButtonDisabled: false
      })
    );
  }
};

const ArchivedMessagesFilter = () => {
  const dispatch = useDispatch();
  const { buttonStatus, filters } = useSelector(
    (state: RootState) => state.archivedMessages
  );
  const { correspondenceCategories } = useProperties([
    [
      'correspondenceCategories',
      archivedMessagesTopicCategories.CORRESPONDENCE_CATEGORIES
    ]
  ]);
  const { t } = useTranslation(['archived-messages', 'common']);
  const { register, getValues, watch, reset, control } = useForm<
    IArchivedMessagesValues
  >({
    defaultValues: filters
  });
  const currentValues = getValues();
  const areDefaultValuesSelected = isEqual(defaultValues, currentValues);
  const areCurrentValuesInStore = isEqual(filters, currentValues);

  useEffect(() => {
    privateFunctions.handleButtonState(
      areDefaultValuesSelected,
      areCurrentValuesInStore,
      buttonStatus,
      dispatch
    );
  }, [currentValues.employeeId, currentValues.status, currentValues.topic]);

  useEffect(() => {
    dispatch(applyArchivedMessagesFilterRequestedAction(filters));
  }, []);

  const FILTER_OPTIONS = {
    FILTER_STATUS,
    TOPICS: privateFunctions.getTopics(correspondenceCategories)
  };

  const watchedFields = watch([
    archivedMessagesFieldNames.STATUS,
    archivedMessagesFieldNames.TOPIC,
    archivedMessagesFieldNames.EMPLOYEE_ID
  ]);

  return (
    <form className={styles.filters}>
      <div className={styles.inputs}>
        <SelectInput
          name={archivedMessagesFieldNames.STATUS}
          ref={register}
          title={t('common:FILTER')}
          value={watchedFields.status}
        >
          {FILTER_OPTIONS.FILTER_STATUS.filter(
            status => status.val !== filterValues.ACTION_REQUIRED
          ).map(status => (
            <option value={status.val} key={`status_${status.val}`}>
              {t(status.translation)}
            </option>
          ))}
        </SelectInput>
        <SelectInput
          name={archivedMessagesFieldNames.TOPIC}
          ref={register}
          title={t('common:TOPIC')}
          value={watchedFields.topic}
        >
          {FILTER_OPTIONS.TOPICS.map(topic => (
            <option value={topic.val} key={`topics_${topic.val}`}>
              {t(topic.translation)}
            </option>
          ))}
        </SelectInput>

        <TextInput
          name={archivedMessagesFieldNames.EMPLOYEE_ID}
          control={control}
          labelText={t('SEARCH_BY_EMPLOYEE_ID')}
          type="text"
          placeholder={t('SEARCH_BY_EMPLOYEE_ID_PLACEHOLDER')}
          mask={maskEmployeeId}
        />
      </div>

      <div className={styles.reset}>
        <ResetButton
          onClick={() => {
            reset(defaultValues);
            dispatch(
              applyArchivedMessagesFilterRequestedAction(defaultPayload)
            );
          }}
          isDisabled={buttonStatus?.isResetButtonDisabled}
        />
      </div>
      <div className={styles.apply}>
        <Button
          type="button"
          isDisabled={buttonStatus?.isApplyButtonDisabled}
          onClick={() => {
            privateFunctions.handleApplyButtonClick(dispatch, currentValues);
          }}
          testingTag="applyBtn"
        >
          {t('common:APPLY')}
        </Button>
      </div>
    </form>
  );
};

export default ArchivedMessagesFilter;
