import * as yup from 'yup';
import { TFunction } from 'i18next';
import { parse, isValid, isEqual, isAfter, getYear } from 'date-fns';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { yupResolver } = require('@hookform/resolvers/yup');

const retroPayModalValidations = (
  t: TFunction,
  enrolmentDate: string,
  servicePeriodYear: number,
  enrolmentDateFormatted: string
) => {
  const schema = {
    retroType: yup
      .string()
      .required(t('ANNUAL_REPORTING_RETRO_TYPE_REQUIRED_ERROR')),
    retroStartDate: yup
      .string()
      .required(t('ANNUAL_REPORTING_DATE_REQUIRED_ERROR'))
      .test('is date valid', t('ANNUAL_REPORTING_DATE_INVALID_ERROR'), value =>
        isValid(parse(value, 'MM/dd/yyyy', new Date()))
      )
      .test(
        'validate year',
        t('ANNUAL_REPORTING_INVALID_YEAR_ERROR'),
        value => {
          if (value.length >= 7) {
            const year = Number(value.split('/')[2]);
            return year >= 1900;
          }
          return true;
        }
      )
      .test(
        'is startDate on or before endDate',
        t('validations:VALIDATION_ON_OR_BEFORE', {
          field1: t('common:RETRO_MODAL_START_DATE'),
          field2: t('common:RETRO_MODAL_END_DATE')
        }),
        function(value) {
          const dateStarted = parse(value, 'MM/dd/yyyy', new Date());
          if (
            !this.parent.retroEndDate ||
            !isValid(parse(this.parent.retroEndDate, 'MM/dd/yyyy', new Date()))
          ) {
            return true;
          }
          const dateEnded = parse(
            this.parent.retroEndDate,
            'MM/dd/yyyy',
            new Date()
          );
          return (
            isEqual(dateEnded, dateStarted) || isAfter(dateEnded, dateStarted)
          );
        }
      )
      .test(
        'check if startDate is on or after enrolmentDate',
        t('ANNUAL_REPORTING_START_DATE_ON_AFTER_ENROL_DATE_ERROR', {
          enrolmentDate: enrolmentDateFormatted
        }),
        value => {
          const dateStarted = parse(enrolmentDate, 'yyyy-MM-dd', new Date());
          const dateEnded = parse(value, 'MM/dd/yyyy', new Date());
          if (value.length === 10 && isValid(dateEnded)) {
            const startDateAfterEnrolmentDate =
              isEqual(dateEnded, dateStarted) ||
              isAfter(dateEnded, dateStarted);
            if (!startDateAfterEnrolmentDate) {
              return false;
            }
            return true;
          }
          return true;
        }
      ),
    retroEndDate: yup
      .string()
      .required(t('ANNUAL_REPORTING_DATE_REQUIRED_ERROR'))
      .test('is date valid', t('ANNUAL_REPORTING_DATE_INVALID_ERROR'), value =>
        isValid(parse(value, 'MM/dd/yyyy', new Date()))
      )
      .test(
        'validate year',
        t('ANNUAL_REPORTING_INVALID_YEAR_ERROR'),
        value => {
          if (value.length >= 7) {
            const year = Number(value.split('/')[2]);
            return year >= 1900;
          }
          return true;
        }
      )
      .test(
        'is endDate on or after startDate',
        t('validations:VALIDATION_ON_OR_AFTER', {
          field1: t('common:RETRO_MODAL_END_DATE'),
          field2: t('common:RETRO_MODAL_START_DATE')
        }),
        function(value) {
          const dateStarted = parse(
            this.parent.retroStartDate,
            'MM/dd/yyyy',
            new Date()
          );
          if (!this.parent.retroStartDate) {
            return true;
          }
          const dateEnded = parse(value, 'MM/dd/yyyy', new Date());
          return (
            isEqual(dateEnded, dateStarted) || isAfter(dateEnded, dateStarted)
          );
        }
      )
      .test(
        'check if endDate is after servicePeriodYear',
        t('ANNUAL_REPORTING_END_DATE_SERVICE_PERIOD_ERROR'),
        value => {
          const dateStarted = parse(value, 'MM/dd/yyyy', new Date());
          if (value?.length === 10 && isValid(dateStarted)) {
            const retroEndDateYear = getYear(dateStarted);
            if (retroEndDateYear > servicePeriodYear) {
              return false;
            }
          }
          return true;
        }
      )
  };
  return yupResolver(yup.object().shape(schema));
};

export default retroPayModalValidations;
