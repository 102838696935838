import { FeatureInterface } from 'unleash-client/lib/feature';
import { DonnaBusinessConfigNames } from 'containers/donnaBusinessConfig/types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, deleteData, postData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');
const NEXT_PUBLIC_APP_URL = env('NEXT_PUBLIC_APP_URL');
const NEXT_PUBLIC_ADMIN_APP_URL = env('NEXT_PUBLIC_ADMIN_APP_URL');

export const getSysDate = () =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/common/dbsysdate`);

export const properties = () =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/common/properties`);

export const getOverrideReasons = (omersUser: boolean) =>
  getData(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/business-config/v1/override-reasons/eaccess`
  );

export const getBusinessConfig = (
  configName: DonnaBusinessConfigNames,
  omersUser: boolean
) =>
  getData(
    `${
      omersUser ? NEXT_PUBLIC_ADMIN_APP_URL : NEXT_PUBLIC_APP_URL
    }/api/business-config/v1/configuration/${configName}`
  );

export const getClassCoverage = (groupNo: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/common/classcoverage?groupNo=${groupNo}`
  );

export const getUserNames = (employerGroupNo: number) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/common/users/${employerGroupNo}`
  );

export const getOriginalStateData = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/${requestNumber}/original-state`,
    undefined,
    requestNumber
  );

export const getPDF = (data: {
  requestNumber: string;
  employerNo: number;
  formNo: string;
  oracleReportInd?: string;
}) => {
  const { requestNumber, employerNo, formNo, oracleReportInd } = data;
  const report = oracleReportInd || (formNo === '119_stm' ? 'Y' : 'N');
  return getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/common/pdf/${requestNumber}?employerNo=${employerNo}&formNo=${formNo}&report=${report}`,
    undefined,
    requestNumber,
    'blob'
  );
};

export const deleteForm = (requestNumber: string) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests?ids=${requestNumber}`,
    undefined,
    requestNumber
  );

export const deleteMultipleForms = (requestNumbers: string[]) => {
  const ids = requestNumbers.reduce((prev, current) => `${prev},${current}`);
  return deleteData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests?ids=${ids}`);
};

export const lockForm = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/${requestNumber}/lock`,
    undefined,
    undefined,
    requestNumber
  );

export const unlockForm = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/${requestNumber}/unlock`,
    undefined,
    undefined,
    requestNumber
  );

export const getEmailOptoutStatus = (employerGroupNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/optout/${employerGroupNumber}`
  );

export const setEmailOptoutStatus = (
  employerGroupNumber: string,
  flag: boolean
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/ecorr/optout/${employerGroupNumber}/${flag}`,
    {}
  );

export const getFeatureFlags = async () => {
  const res = await fetch(`/api/feature-flags`);
  const flags: Array<FeatureInterface> = await res.json();
  if (flags.length > 0) {
    return flags;
  }

  throw new Error('No feature flags found');
};
