import { call, all, takeLatest, put } from 'redux-saga/effects';

import { EapAction } from 'types';
import { responseTypes } from 'types/response-type';
import { getPDF } from 'services';
import { getApiErrorMessage, viewPdf, downloadPdf } from 'utils';
import { safeAuthEffect } from 'store/sagas/sagaUtils';

import {
  clearNotifications,
  notifyErrorAction
} from 'containers/notifications/notificationsActions';
import {
  clearLoadingAction,
  startLoadingAction
} from 'containers/auth/userSessionActions';
import { formPDFActionTypes } from './submitLandingPageActions';

export function* getPdfSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(safeAuthEffect, getPDF, {
    requestNumber: action.data.requestNumber,
    formNo: action.data.formNo,
    employerNo: action.data.employerNo
  });
  yield put(clearLoadingAction());

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    if (action.data.isView) {
      viewPdf(res, action.data.fileName);
    } else {
      downloadPdf(res, action.data.fileName);
    }

    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* formPDFSaga(): any {
  yield all([yield takeLatest(formPDFActionTypes.GET_PDF, getPdfSaga)]);
}
