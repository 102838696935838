import { all, call, put, takeEvery } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { zonedTimeToUtc } from 'date-fns-tz';

import {
  getStewardshipReport,
  StewardshipReportResponse,
  SucceededStewardshipReportResponse
} from 'services/stewardshipReport';
import { isEmpty } from 'lodash';
import logger from 'utils/logger/client-side';
import { toLoggerError } from 'utils/api';
import {
  getEsrDataRequestAction,
  getEsrDataSuccessAction,
  getEsrDataFailedAction,
  getEsrEmptyDataAction,
  StewardshipReportState
} from './esrSliceReducer';

export enum InfoPanelType {
  ENROLMENTS = 'enrolments',
  OMISSION_PERIODS = 'omissionPeriods',
  EFORM_RETURN_RATE = 'eformReturnRate',
  EFORM_PROCESSING = 'eformEndToEndProcessing',
  CONTRIBUTION_REMITTANCE = 'contributionRemittance',
  DISABILITIES = 'disabilities',
  FORM143_RETIRE_OR_TERMINATION = 'retirementTermination',
  FORM143_DEATH = 'preRetirementDeath',
  FORM143_ADJUSTMENTS = 'form143Adjustments'
}

const mapResponse = (
  response: SucceededStewardshipReportResponse
): Pick<StewardshipReportState, 'membershipStats' | 'infoPanels'> => {
  const {
    data: { esrModel }
  } = response;
  return {
    membershipStats: esrModel
      ? {
          totalMembers: esrModel.totalMembers,
          cftMembers: esrModel.cftMembers,
          otcftMembers: esrModel.otcftMembers,
          myOmersUsers: esrModel.myomersUsers,
          paperlessMembers: esrModel.paperlessMembers,
          lastUpdated: zonedTimeToUtc(
            new Date(esrModel.updateTimestamp),
            'America/Toronto'
          )
        }
      : null,
    infoPanels: esrModel.otherStats.reduce(
      (acc, { id, value }) => ({
        ...acc,
        [id]: {
          value,
          type: id
        }
      }),
      {}
    )
  };
};

export function* getEsrInfoSaga({
  payload
}: PayloadAction<{
  employerId: string;
  startDate: Date;
  endDate: Date;
}>) {
  const { employerId, startDate, endDate } = payload;

  try {
    const response: StewardshipReportResponse = yield call(
      getStewardshipReport,
      employerId,
      startDate,
      endDate
    );
    if ('errors' in response) {
      throw new Error(
        JSON.stringify(
          'errors' in response
            ? JSON.stringify(response)
            : 'Stewardship api responded with no data'
        )
      );
    } else if (isEmpty(response.data)) {
      yield put(getEsrEmptyDataAction());
    } else {
      const mappedReport = mapResponse(
        response as SucceededStewardshipReportResponse
      );
      yield put(getEsrDataSuccessAction(mappedReport));
    }
  } catch (e) {
    yield put(getEsrDataFailedAction());
    logger.error(
      `Failed to fetch esr with startDate=${startDate}, endDate=${endDate}, employerId=${employerId}`,
      toLoggerError(e)
    );
  }
}

export function* esrSaga(): any {
  yield all([takeEvery(getEsrDataRequestAction.type, getEsrInfoSaga)]);
}
