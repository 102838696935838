import { useRef } from 'react';
import * as React from 'react';
import classNames from 'classnames/bind';
import { ReactSVG } from 'react-svg';

import styles from './Spinner.module.scss';

const cx = classNames.bind(styles);

interface SpinnerProps {
  isFullScreen?: boolean;
  message?: string | React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  visible?: boolean;
}
const Spinner = ({
  isFullScreen = true,
  message,
  size = 'large',
  visible = true
}: SpinnerProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const spinnerClass = cx({
    spinnerContainer: isFullScreen,
    small: size === 'small',
    medium: size === 'medium',
    large: size === 'large',
    fullscreen: isFullScreen,
    hidden: !visible
  });

  if (message) {
    return (
      <div className={spinnerClass} data-testid="spinner-component" ref={ref}>
        <ReactSVG
          src="/images/logos/omers-o-static-ani.svg"
          className={styles.spinner}
        />
        {typeof message === 'string' ? (
          <span className={styles.text}>{message}</span>
        ) : (
          message
        )}
      </div>
    );
  }

  return (
    <div className={spinnerClass} data-testid="spinner-component" ref={ref}>
      <ReactSVG
        src="/images/logos/omers-circle-logo.svg"
        className={styles.spinner}
      />
    </div>
  );
};

export default Spinner;
