import { isNull } from 'lodash';

import { EapAction } from 'types';
import {
  BrokenService,
  PurchaseCostItem,
  Purchased,
  MemberSearchResult,
  PurchasedPPLID
} from 'interfaces';
import { FormState } from 'containers/requestForm/common/interfaces';
import { getSubmissionStatus } from 'utils';

import { form165bActionTypes } from './form165bActions';
import mapForm165b from './mapForm165b';

export interface Form165bData {
  fullName: string;
  workPhone: string;
  workEmail: string;
  noteToMember: string;
  noteToOmers: string;
  intendToPurchase: 'Y' | 'N';
  electionDate: string;
  buyingAllLeave: 'Y' | 'N';
  shareCostWithMember: 'Y' | 'N';
  amountForLeave: string;
  paymentCheque: string;
  paymentRRSP: string;
  paymentConfirmAVC: boolean;
  paymentAVC: string;
  paToCra: 'Y' | 'N';
  paReporting: Array<{
    revisedPA: string;
  }>;
}

export interface Form165bState extends FormState {
  form165bData: Form165bData;
  memberInfo: MemberSearchResult | null;
  isElectLeavePeriod: boolean;
  leavePeriods: Array<{
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
    pplSharedCostVisibleInd: boolean;
    purchasedPPLID?: PurchasedPPLID;
    purchased?: Purchased;
  }>;
  availableBrokenServices: Array<BrokenService>;
  electLeavePeriodStep: number | null;
  leavePeriodLoading: boolean;
  activeIntervalNumber: number | null;
  activeLeavePeriod: {
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
    pplSharedCostVisibleInd: boolean;
    purchasedPPLID?: PurchasedPPLID;
    purchased?: Purchased;
  } | null;
  commentsToMember: string | null;
}

export const form165bData: Form165bData = {
  fullName: '',
  workPhone: '',
  workEmail: '',
  noteToMember: '',
  noteToOmers: '',
  intendToPurchase: 'Y',
  electionDate: '',
  buyingAllLeave: 'Y',
  shareCostWithMember: 'N',
  amountForLeave: '',
  paymentCheque: '',
  paymentRRSP: '',
  paymentConfirmAVC: false,
  paymentAVC: '',
  paToCra: 'N',
  paReporting: []
};

export const initialState = {
  form165bData,
  requestNumber: null,
  initLoading: false,
  memberInfo: null,
  formStep: 1,
  formStatus: '' as const,
  exceptions: [],
  overrideExceptions: [],
  submissionStatus: '' as const,
  submissionComplete: false,
  isElectLeavePeriod: false,
  leavePeriods: [],
  availableBrokenServices: [],
  electLeavePeriodStep: null,
  leavePeriodLoading: false,
  activeIntervalNumber: null,
  activeLeavePeriod: null,
  commentsToMember: null,
  notes: null
};

const form165b = (
  state: Form165bState = initialState,
  action: EapAction
): Form165bState => {
  const { type, data } = action;
  switch (type) {
    case form165bActionTypes.SET_REQUEST_NUMBER_FORM_165B:
      return {
        ...initialState,
        requestNumber: data,
        initLoading: true
      };
    case form165bActionTypes.GET_FORM_165B_SUCCESS:
      return {
        ...state,
        employerNo: data.employerNo,
        initLoading: false,
        requestNumber: data.requestNumber,
        memberInfo: data.memberInfo,
        leavePeriods: data.leavePeriods,
        availableBrokenServices: data.availableBrokenServices,
        formStep: 1,
        isElectLeavePeriod: false,
        electLeavePeriodStep: null,
        submissionComplete: false,
        commentsToMember: data.commentsToMember,
        notes: data.notes,
        form165bData: {
          ...state.form165bData,
          noteToMember: data.commentsToMember,
          noteToOmers: data.notes
        },
        exceptions: data.exceptions,
        overrideExceptions: []
      };
    case form165bActionTypes.GET_FORM_165B_FAILED:
      return {
        ...state,
        initLoading: true
      };
    case form165bActionTypes.GET_LATEST_FORM_165B_SUCCESS: {
      const activeLeavePeriod = state.activeIntervalNumber
        ? data.leavePeriods.find(
            (leavePeriod: { brokenService: BrokenService }) =>
              leavePeriod.brokenService.intervalNumber ===
              state.activeIntervalNumber
          )
        : null;

      return {
        ...state,
        leavePeriods: data.leavePeriods,
        availableBrokenServices: data.availableBrokenServices,
        commentsToMember: data.commentsToMember,
        notes: data.notes,
        activeLeavePeriod,
        form165bData: mapForm165b(state.isElectLeavePeriod, {
          brokenService: activeLeavePeriod?.brokenService ?? null,
          purchaseCostList: activeLeavePeriod?.purchaseCostList ?? null,
          notes: {
            noteToMember: data.commentsToMember,
            noteToOmers: data.notes
          }
        }),
        exceptions: data.exceptions
      };
    }
    case form165bActionTypes.SET_FORM_165B_STEP:
      return {
        ...state,
        formStep: data
      };
    case form165bActionTypes.SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_SUCCESS:
      return {
        ...state,
        leavePeriodLoading: false,
        isElectLeavePeriod: !isNull(data.intervalNumber),
        electLeavePeriodStep: !isNull(data.intervalNumber) ? 1 : null,
        activeIntervalNumber: data.intervalNumber,
        activeLeavePeriod: data.leavePeriod,
        form165bData: mapForm165b(true, {
          brokenService: data.leavePeriod.brokenService,
          purchaseCostList: data.leavePeriod.purchaseCostList
        })
      };
    case form165bActionTypes.SET_EDIT_ELECT_LEAVE_PERIOD_FORM_165B_FAILED:
    case form165bActionTypes.EXIT_ELECT_LEAVE_PERIOD_FORM_165B:
      return {
        ...state,
        leavePeriodLoading: false,
        isElectLeavePeriod: false,
        electLeavePeriodStep: null,
        activeIntervalNumber: null,
        activeLeavePeriod: null,
        formStatus: '',
        form165bData: mapForm165b(false, {
          notes: {
            noteToMember: state.commentsToMember,
            noteToOmers: state?.notes ?? null
          }
        })
      };
    case form165bActionTypes.SET_ELECT_LEAVE_PERIOD_STEP_FORM_165B:
      return {
        ...state,
        electLeavePeriodStep: data
      };
    case form165bActionTypes.UPDATE_LEAVE_PERIOD_FORM_165B:
      return {
        ...state,
        leavePeriodLoading: true,
        formStatus: 'saving' as const
      };
    case form165bActionTypes.UPDATE_LEAVE_PERIOD_FORM_165B_SUCCESS:
      return {
        ...state,
        leavePeriodLoading: false,
        formStatus: 'success' as const
      };
    case form165bActionTypes.UPDATE_LEAVE_PERIOD_FORM_165B_FAILED:
      return {
        ...state,
        leavePeriodLoading: false,
        formStatus: 'error' as const
      };
    case form165bActionTypes.SET_FORM_165B_SAVING_STATUS:
      return {
        ...state,
        formStatus: data
      };
    case form165bActionTypes.SAVE_FORM_165B:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form165bActionTypes.SAVE_FORM_165B_SUCCESS:
      return {
        ...state,
        formStatus: 'success' as const,
        commentsToMember: data.commentsToMember,
        notes: data.notes,
        form165bData: {
          ...state.form165bData,
          noteToMember: data.commentsToMember ?? '',
          noteToOmers: data.notes ?? ''
        }
      };
    case form165bActionTypes.SAVE_FORM_165B_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form165bActionTypes.CLEAR_FORM_165B_STATUS:
      return {
        ...state,
        formStatus: ''
      };
    case form165bActionTypes.DELETE_FORM_165B_SUCCESS:
      return {
        ...initialState,
        formStatus: 'deleted' as const
      };
    case form165bActionTypes.OVERRIDE_FORM_165B_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form165bActionTypes.SET_FORM_165B_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form165bActionTypes.SUBMIT_FORM_165B_SUCCESS:
      return {
        ...state,
        exceptions: !data.exceptions ? [] : data.exceptions,
        overrideExceptions: [],
        submissionStatus: getSubmissionStatus(data, data.exceptions),
        submissionComplete:
          getSubmissionStatus(data, data.exceptions) === 'success',
        formStep:
          getSubmissionStatus(data, data.exceptions) === 'success' ? 3 : 2,
        initLoading: true,
        pdfs: data.attachments
      };
    default:
      return state;
  }
};

export default form165b;
