import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'types';
import { FileSavedInStore } from 'interfaces';
import { isEnabled, currentFlags } from 'features/featureFlags/featureFlags';

// topics that cannot be created inside of e-access but are available within Bruce
const ECORR_TOPICS_BRUCE_INCOMING = [
  'Salary Rate Required - Buyback',
  'Salary Rate Required - Transfers',
  'Financial Information Required'
];

/**
 * Hook provides ecorr message options for categories, topics, and corresponding
 * document types; additionally provides suggested default category based on
 * selectedTopic, whether to disable link member button, and inferred userIntent
 */
const useMessageOptions = (
  selectedTopic = '',
  selectedCategory = '',
  files: Array<FileSavedInStore> = [],
  presetUserIntent?: 'M' | 'E'
) => {
  const { ecorrTopics } = useSelector((state: RootState) => state.messages);
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );

  const sortComparator = (a: string, b: string) =>
    a.toLowerCase().localeCompare(b.toLowerCase());
  // ecorr topics filtered by enabled feature flag
  const messageTopics = isEnabled(featureFlags, currentFlags.NEW_ECORR_OPTIONS)
    ? ecorrTopics.map(topic => topic.name)
    : ecorrTopics
        .filter(
          topic =>
            ![
              'Attestation',
              'Forecasting Reports',
              'Post-Retirement Death',
              'Separation and Divorce'
            ].includes(topic.name)
        )
        .map(topic => topic.name)
        .sort(sortComparator);

  // ecorr topics with incoming bruce topics filtered by enabled feature flag
  const messageAndBruceTopics = isEnabled(
    featureFlags,
    currentFlags.NEW_ECORR_OPTIONS
  )
    ? messageTopics.concat(ECORR_TOPICS_BRUCE_INCOMING).sort(sortComparator)
    : messageTopics;

  // find selected category map based on selected topic
  const selectedTopicMap =
    ecorrTopics?.find(topic => topic.name === selectedTopic) ?? null;

  // determine users intent based on first uploaded file documentType; intent
  // options are 'M' member, 'E' employer, or null for no determined intent
  const userIntent = useMemo(() => {
    // use predetermined user intent if passed into hook
    if (presetUserIntent) {
      return presetUserIntent;
    }

    // find and filter inferred user intents from uploaded files
    const fileIntents =
      files.length > 0
        ? files
            .map(
              file =>
                selectedTopicMap?.categories
                  ?.find(category => category.name === file.category)
                  ?.types?.find(
                    type =>
                      !type.memberOptional && type.name === file.documentType
                  )?.ctx ?? null
            )
            .filter(intent => !!intent)
        : null;

    return fileIntents && fileIntents?.length > 0 ? fileIntents[0] : null;
  }, [files, presetUserIntent, selectedTopicMap?.categories]);

  // ecorr categories filtered by active selectedTopic and userIntent
  const messageCategories = useMemo(
    () =>
      selectedTopicMap?.categories
        ?.filter(
          category =>
            !userIntent ||
            category.types.find(
              type => type.memberOptional || type.ctx === userIntent
            )
        )
        ?.map(category => category.name) ?? [],
    [selectedTopicMap?.categories, userIntent]
  );
  // ecorr default category on topic change
  const defaultCategory = selectedTopicMap?.defaultCategory ?? '';

  // find selected documentType map based on selected category
  const selectedCategoryMap =
    selectedTopicMap?.categories?.find(
      category => category.name === selectedCategory
    ) ?? null;
  // ecorr documentTypes filtered by active selectedCategory and userIntent
  const messageDocumentTypes = useMemo(
    () =>
      selectedCategoryMap?.types
        ?.filter(
          type => !userIntent || type.memberOptional || type.ctx === userIntent
        )
        .map(type => type.name) ?? [],
    [selectedCategoryMap, userIntent]
  );

  // determine if active topic should disable user from link member to topic
  const isLinkMemberDisabled = [
    'NFT Data Collection 2022',
    'Attestation',
    'Contribution Remittance (105)',
    'Forecasting Reports',
    'NFT General Inquiry',
    'Request an OMERS Session',
    'Order Member Handbooks',
    'Report Request',
    'Bill 124',
    'Volunteer Firefighter'
  ].includes(selectedTopic);

  return {
    messageTopics,
    messageAndBruceTopics,
    messageCategories,
    messageDocumentTypes,
    defaultCategory,
    isLinkMemberDisabled,
    userIntent
  };
};

export default useMessageOptions;
