import { ChangeEvent, FocusEvent, useEffect, useState } from 'react';
import * as React from 'react';
import { useFormContext } from 'react-hook-form-legacy';

import TextInput from 'components/forms/inputs/textInput/TextInput';
import { ReactSVG } from 'react-svg';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { useTranslation } from 'react-i18next';
import { isEqual, uniqueId } from 'lodash';
import { maskEmail } from 'utils';
import styles from './QASEmailAddressInput.module.scss';
import { onQASEmailAddressValidation } from './onQASEmailAddressValidation';

interface QASEmailAddressInputProps {
  name: string;
  label: string;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  emailValidationRef?: any;
  errorMessage?: any;
  verificationState?: QASEmailResponseTypes | string;
  verificationEmailAddress?: {
    email: string;
  };
  currentValue?: string | null;
  setEmailState?: any;
  setEmailAddress?: any;
  optional?: boolean;
  tooltip?: string | Array<string> | React.ReactNode;
}
const QASEmailAddressInput = ({
  name,
  label,
  onBlur,
  emailValidationRef,
  errorMessage,
  verificationState,
  verificationEmailAddress,
  currentValue,
  setEmailState,
  setEmailAddress,
  optional,
  tooltip
}: QASEmailAddressInputProps) => {
  const { errors, setValue, getValues } = useFormContext();
  const { t } = useTranslation('common');
  const [id] = useState(uniqueId('email-valid'));
  const [showValidationMessage, setShowValidationMessage] = useState(
    currentValue !== ''
  );

  const onReachQASEndPoint = (email: string | undefined | null) => {
    if (email && !errors[name] && !errorMessage) {
      onQASEmailAddressValidation(email).then(data => {
        setEmailState(data);
        if (data === QASEmailResponseTypes.VERIFIED) {
          setEmailAddress(email);
        }
        setShowValidationMessage(true);
      });
    }
  };

  useEffect(() => {
    onReachQASEndPoint(currentValue ?? getValues(name));
  }, []);

  // if email address fields have changed and previous validation state was already
  // verified, compares cached fields on change to check new validation state
  const onEmailAddressFieldChange = (
    field: string,
    e: ChangeEvent<HTMLInputElement>
  ) => {
    setValue(name, maskEmail(e.target.value));
    setShowValidationMessage(false);

    const emailAddressValues = {
      [field]: e.target.value.trim()
    };

    const isChangeEqual = isEqual(emailAddressValues, verificationEmailAddress);

    if (
      (isChangeEqual &&
        verificationState === QASEmailResponseTypes.ILLEGITIMATE) ||
      (!isChangeEqual && verificationState === QASEmailResponseTypes.VERIFIED)
    ) {
      setEmailState(isChangeEqual ? QASEmailResponseTypes.VERIFIED : '');
    }
  };

  return (
    <div className={styles.container}>
      <TextInput
        name={name}
        labelText={label}
        id={`email-address-input/${id}`}
        type="email"
        ref={emailValidationRef}
        hasError={!!errors[name]}
        errorMessage={errorMessage || errors[name]}
        onChange={e => onEmailAddressFieldChange(name, e)}
        onBlur={e => {
          if (onBlur) {
            onBlur(e);
          }

          setValue(name, e.target.value.trim());

          if (e.target.value.trim() === '') {
            setEmailAddress('');
            setEmailState('');
          }
          if (errors[name] || errorMessage) {
            setEmailState(QASEmailResponseTypes.ILLEGITIMATE);
            setShowValidationMessage(true);
          }
          onReachQASEndPoint(currentValue ?? getValues(name));
        }}
        optional={optional}
        testingTag="email-address-input"
        tooltip={tooltip}
      />

      {verificationState === QASEmailResponseTypes.VERIFIED &&
        showValidationMessage &&
        (currentValue ?? getValues(name)) !== '' && (
          <div className={styles.emailAddressValid}>
            <ReactSVG src="/images/green-check.svg" />
            <div className="semiBold">{t('common:VALID_EMAIL_ADDRESS')}</div>
          </div>
        )}

      {verificationState === QASEmailResponseTypes.UNKNOWN &&
        showValidationMessage &&
        (currentValue ?? getValues(name)) !== '' && (
          <div className={styles.emailAddressValid}>
            <ReactSVG
              className={styles.warning}
              src="/images/exclamation-triangle-solid.svg"
            />
            <div className="semiBold">
              {t('common:NOT_VALID_EMAIL_ADDRESS')}
            </div>
          </div>
        )}

      {verificationState === QASEmailResponseTypes.ILLEGITIMATE &&
        showValidationMessage &&
        (currentValue ?? getValues(name)) !== '' && (
          <div className={styles.emailAddressValid}>
            <ReactSVG
              className={styles.danger}
              src="/images/exclamation-circle-red.svg"
            />
            <div className="semiBold">{t('common:INVALID_EMAIL_ADDRESS')}</div>
          </div>
        )}
    </div>
  );
};

export default QASEmailAddressInput;
