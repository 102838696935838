// https://docs.microsoft.com/en-us/microsoft-edge/web-platform/user-agent-string
export const isEdgeHTMLBrowser = () =>
  window.navigator.userAgent.indexOf('Edge') !== -1;
export const isEdgeChromiumBrowser = () =>
  window.navigator.userAgent.indexOf('Edge') === -1 &&
  window.navigator.userAgent.indexOf('Edg') !== -1;

// https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
export const isSafariBrowser = () =>
  window.navigator.userAgent.indexOf('Safari') !== -1 &&
  window.navigator.userAgent.indexOf('Chrome') === -1;

export const downloadFile = (
  file: any,
  fileName: string,
  extension?: string
) => {
  // https://docs.microsoft.com/en-us/previous-versions/windows/internet-explorer/ie-developer/samples/hh779016(v=vs.85)?redirectedfrom=MSDN
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob && !isEdgeChromiumBrowser()) {
    // IE11 & EdgeHTML (not Edge Chromium)
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(
      file,
      extension ? `${fileName}.${extension}` : fileName
    );
  } else {
    // Other browsers
    const url = window.URL.createObjectURL(
      new Blob([file], {
        type: extension ? `application/${extension}` : ''
      })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      extension ? `${fileName}.${extension}` : fileName
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

/**
 * download the pdf to OS
 */
export const downloadPdf = (file: any, fileName: string) => {
  // https://docs.microsoft.com/en-us/previous-versions/windows/internet-explorer/ie-developer/samples/hh779016(v=vs.85)?redirectedfrom=MSDN
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob && !isEdgeChromiumBrowser()) {
    // IE11 & EdgeHTML (not Edge Chromium)
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(file, `${fileName}.pdf`);
  } else {
    // Other browsers
    const url = window.URL.createObjectURL(
      new Blob([file], { type: 'application/pdf' })
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

/**
 * view PDF in new browser tab
 */
export const viewPdf = (file: any, fileName: string) => {
  // https://docs.microsoft.com/en-us/previous-versions/windows/internet-explorer/ie-developer/samples/hh779016(v=vs.85)?redirectedfrom=MSDN
  // @ts-ignore
  if (window.navigator.msSaveOrOpenBlob && !isEdgeChromiumBrowser()) {
    // IE11 & EdgeHTML (not Edge Chromium)
    // @ts-ignore
    window.navigator.msSaveOrOpenBlob(file, `${fileName}.pdf`);
  } else if (isSafariBrowser()) {
    // Safari
    // reason for separate processing: Safari does not support link.target = '_blank'
    // https://stackoverflow.com/questions/50273899/amp-html-a-target-blank-not-working-on-iphone-safari-browser-to-go-new-wind
    const blob = new window.Blob([file], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    window.open(url, '_blank');
  } else {
    // Other browsers; Chrome, Firefox, Opera ...
    const blob = new window.Blob([file], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
