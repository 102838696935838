import { EapAction } from 'types';
import { archivedMessagesActionTypes } from 'containers/archivedMessages/ArchivedMessagesActions';
import { filterValues, indicators } from 'utils/constants';
import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import {
  IArchivedMessagesMessage,
  IArchivedMessagesButtonStatus,
  IMessageThread,
  IMessageError
} from './types';
import { defaultValues } from './archivedMessagesFilter/ArchivedMessagesFilter';

export interface ArchivedMessagesState {
  messages: IArchivedMessagesMessage[];
  isLoading: boolean;
  buttonStatus: IArchivedMessagesButtonStatus;
  filters: {
    employeeId: string;
    status: filterValues;
    topic: string;
  };
  correspondenceNumber: string;
  messageThread: IMessageThread[];
  messageError: IMessageError;
}

export const initialState = {
  messages: [],
  isLoading: false,
  buttonStatus: {
    isResetButtonDisabled: true,
    isApplyButtonDisabled: true,
    isApplyButtonClicked: false
  },
  filters: {
    ...defaultValues
  },
  correspondenceNumber: '',
  messageThread: [],
  messageError: {
    showError: false,
    message: ''
  }
};

const archivedMessages = (
  state: ArchivedMessagesState = initialState,
  action: EapAction
): ArchivedMessagesState => {
  const { type, data } = action;
  switch (type) {
    case archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_REQUESTED:
      return {
        ...state,
        messages: initialState.messages,
        isLoading: true,
        messageError: initialState.messageError
      };
    case archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_SUCCEEDED:
      return {
        ...state,
        messages: data,
        isLoading: false,
        messageError: initialState.messageError
      };
    case archivedMessagesActionTypes.SET_ARCHIVED_MESSAGES_BUTTON_STATUS: {
      return {
        ...state,
        buttonStatus: {
          ...state.buttonStatus,
          isApplyButtonClicked: data.isApplyButtonClicked
            ? data.isApplyButtonClicked
            : false,
          isResetButtonDisabled: data.isResetButtonDisabled,
          isApplyButtonDisabled: data.isApplyButtonDisabled
        }
      };
    }
    case archivedMessagesActionTypes.APPLY_ARCHIVED_MESSAGES_FILTER_REQUESTED: {
      return {
        ...state,
        filters: data,
        isLoading: true,
        buttonStatus: {
          ...initialState.buttonStatus
        }
      };
    }
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        messageError: initialState.messageError
      };
    case archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_FAILED:
      return {
        ...state,
        messages: initialState.messages,
        isLoading: false,
        messageError: initialState.messageError
      };
    case archivedMessagesActionTypes.SET_ARCHIVED_MESSAGES_CORRESPONDENCE_NUMBER:
      return {
        ...state,
        messages: state.messages,
        correspondenceNumber: data,
        messageError: initialState.messageError
      };
    case archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_SUCCEEDED:
      return {
        ...state,
        messages: state.messages,
        messageThread: data,
        messageError: initialState.messageError
      };
    case archivedMessagesActionTypes.SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED_FAILED:
      return {
        ...state,
        messages: state.messages,
        messageError: { showError: true, message: data }
      };
    case archivedMessagesActionTypes.RESOLVE_ARCHIVED_MESSAGE_SUCCEEDED:
      return {
        ...state,
        messages: state.messages.map(message => {
          if (message.correspondenceNo === data) {
            // eslint-disable-next-line no-param-reassign
            message.resolvedInd = indicators.TRUE;
            return message;
          }
          return message;
        })
      };
    default:
      return state;
  }
};

export default archivedMessages;
