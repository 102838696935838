import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import {
  MemberSearchResult,
  ExceptionsObject,
  BrokenService,
  PurchaseCostItem,
  LeavePeriod
} from 'interfaces';
import { Form165cData } from './form165cReducer';

export enum form165cActionTypes {
  SET_REQUEST_NUMBER_FORM_165C = 'SET_REQUEST_NUMBER_FORM_165C',
  CREATE_FORM_165C = 'CREATE_FORM_165C',
  GET_FORM_165C = 'GET_FORM_165C',
  GET_FORM_165C_SUCCESS = 'GET_FORM_165C_SUCCESS',
  GET_FORM_165C_FAILED = 'GET_FORM_165C_FAILED',
  GET_LATEST_FORM_165C_SUCCESS = 'GET_LATEST_FORM_165C_SUCCESS',
  UNLOCK_FORM_165C = 'UNLOCK_FORM_165C',
  SET_FORM_165C_STEP = 'SET_FORM_165C_STEP',
  DELETE_FORM_165C = 'DELETE_FORM_165C',
  DELETE_FORM_165C_SUCCESS = 'DELETE_FORM_165C_SUCCESS',
  DELETE_FORM_165C_FAILED = 'DELETE_FORM_165C_FAILED',
  ADJUST_LEAVE_PERIOD_FORM_165C = 'ADJUST_LEAVE_PERIOD_FORM_165C',
  REMOVE_LEAVE_PERIOD_FORM_165C = 'REMOVE_LEAVE_PERIOD_FORM_165C',
  CHANGE_LEAVE_PERIOD_CANCEL_STATE_FORM_165C = 'CHANGE_LEAVE_PERIOD_CANCEL_STATE_FORM_165C',
  SET_EDIT_ADJUST_LEAVE_PERIOD_FORM_165C = 'SET_EDIT_ADJUST_LEAVE_PERIOD_FORM_165C',
  SAVE_FORM_165C_REQUESTED = 'SAVE_FORM_165C_REQUESTED',
  SAVE_AND_REVIEW_FORM_165C_REQUESTED = 'SAVE_AND_REVIEW_FORM_165C_REQUESTED',
  SAVE_FORM_165C_SUCCESS = 'SAVE_FORM_165C_SUCCESS',
  SAVE_FORM_165C_FAILED = 'SAVE_FORM_165C_FAILED',
  UPDATE_LEAVE_PERIOD_165C_REQUESTED = 'UPDATE_LEAVE_PERIOD_165C_REQUESTED',
  UPDATE_LEAVE_PERIOD_165C_SUCCESS = 'UPDATE_LEAVE_PERIOD_165C_SUCCESS',
  UPDATE_LEAVE_PERIOD_165C_FAILED = 'UPDATE_LEAVE_PERIOD_165C_FAILED',
  SET_IS_ADD_LEAVE_PERIOD_165C = 'SET_IS_ADD_LEAVE_PERIOD_165C',
  SET_ADD_PERIOD_STEP_165C = 'SET_ADD_PERIOD_STEP_165C',
  SET_FORM_165C_SAVING_STATUS = 'SET_FORM_165C_SAVING_STATUS',
  SUBMIT_FORM_165C_REQUESTED = 'SUBMIT_FORM_165C_REQUESTED',
  OVERRIDE_FORM_165C_EXCEPTIONS = 'OVERRIDE_FORM_165C_EXCEPTIONS',
  CLEAR_FORM_165C_STATUS = 'CLEAR_FORM_165C_STATUS',
  SUBMIT_FORM_165C_SUCCESS = 'SUBMIT_FORM_165C_SUCCESS',
  GET_ORIGINAL_FORM_STATE_165C = 'GET_ORIGINAL_FORM_STATE_165C',
  GET_ORIGINAL_FORM_STATE_165C_SUCCESS = 'GET_ORIGINAL_FORM_STATE_165C_SUCCESS',
  GET_ORIGINAL_FORM_STATE_165C_FAILED = 'GET_ORIGINAL_FORM_STATE_165C_FAILED',
  SET_FORM_165C_SUBMIT_STATUS = 'SET_FORM_165C_SUBMIT_STATUS'
}

export const setForm165cRequestNumberAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165cActionTypes.SET_REQUEST_NUMBER_FORM_165C,
  data
});

export const createForm165cAction: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form165cActionTypes.CREATE_FORM_165C,
  data
});

export const getForm165cAction: ActionCreator<EapAction> = (data: string) => ({
  type: form165cActionTypes.GET_FORM_165C,
  data
});

export const getForm165cSuccessAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  memberInfo: MemberSearchResult;
  exceptions: Array<ExceptionsObject>;
  leavePeriods: Array<{
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
  }>;
  availableBrokenServices: Array<BrokenService>;
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165cActionTypes.GET_FORM_165C_SUCCESS,
  data
});

export const getForm165cFailedAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.GET_FORM_165C_FAILED
});

export const getLatestForm165cSuccessAction: ActionCreator<EapAction> = (data: {
  leavePeriods: Array<{
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
  }>;
  availableBrokenServices: Array<BrokenService>;
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165cActionTypes.GET_LATEST_FORM_165C_SUCCESS,
  data
});

export const getOriginalFormState165cAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.GET_ORIGINAL_FORM_STATE_165C
});

export const getOriginalFormState165cSuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form165cActionTypes.GET_ORIGINAL_FORM_STATE_165C_SUCCESS,
  data
});

export const getOriginalFormState165cFailedAction: ActionCreator<EapAction> = (
  data: Form165cData
) => ({
  type: form165cActionTypes.GET_ORIGINAL_FORM_STATE_165C_FAILED,
  data
});

export const unlockForm165cAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165cActionTypes.UNLOCK_FORM_165C,
  data
});

export const setForm165cStepAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165cActionTypes.SET_FORM_165C_STEP,
  data
});

export const deleteForm165cAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165cActionTypes.DELETE_FORM_165C,
  data
});

export const deleteForm165cSuccessAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.DELETE_FORM_165C_SUCCESS
});

export const deleteForm165cFailedAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.DELETE_FORM_165C_FAILED
});

export const adjustLeavePeriodForm165cAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165cActionTypes.ADJUST_LEAVE_PERIOD_FORM_165C,
  data
});

export const removeLeavePeriodForm165cAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165cActionTypes.REMOVE_LEAVE_PERIOD_FORM_165C,
  data
});

export const changeLeavePeriodCancelStateForm165cAction: ActionCreator<EapAction> = (data: {
  intervalNumber: number;
  purchaseNumber: number | null;
  state: 'Y' | 'N';
}) => ({
  type: form165cActionTypes.CHANGE_LEAVE_PERIOD_CANCEL_STATE_FORM_165C,
  data
});

export const setEditAdjustLeavePeriodForm165cAction: ActionCreator<EapAction> = (
  data: number | null
) => ({
  type: form165cActionTypes.SET_EDIT_ADJUST_LEAVE_PERIOD_FORM_165C,
  data
});

export const saveForm165cAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
  exceptions?: Array<ExceptionsObject>;
  submitForm?: boolean;
}) => ({
  type: form165cActionTypes.SAVE_FORM_165C_REQUESTED,
  data
});

export const saveAndReviewForm165cAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165cActionTypes.SAVE_AND_REVIEW_FORM_165C_REQUESTED,
  data
});

export const saveForm165cSuccessAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
}) => ({
  type: form165cActionTypes.SAVE_FORM_165C_SUCCESS,
  data
});

export const saveForm165cFailedAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.SAVE_FORM_165C_FAILED
});

export const updateLeavePeriod165cAction: ActionCreator<EapAction> = (data: {
  formData: Form165cData;
  isNonPurchasable?: boolean;
  preventWizardUpdate?: boolean;
  resetLeavePeriod?: boolean;
}) => ({
  type: form165cActionTypes.UPDATE_LEAVE_PERIOD_165C_REQUESTED,
  data
});

export const updateLeavePeriod165cSuccessAction: ActionCreator<EapAction> = (data: {
  formData: Form165cData;
  leavePeriods: Array<LeavePeriod>;
  activeIntervalNumber: string;
}) => ({
  type: form165cActionTypes.UPDATE_LEAVE_PERIOD_165C_SUCCESS,
  data
});

export const updateLeavePeriod165cFailedAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.UPDATE_LEAVE_PERIOD_165C_FAILED
});

export const setIsAddLeavePeriod165cAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form165cActionTypes.SET_IS_ADD_LEAVE_PERIOD_165C,
  data
});

export const setAddPeriodStep165cAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form165cActionTypes.SET_ADD_PERIOD_STEP_165C,
  data
});

export const setForm165cSavingStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165cActionTypes.SET_FORM_165C_SAVING_STATUS,
  data
});

export const submitForm165cAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.SUBMIT_FORM_165C_REQUESTED
});

export const submitForm165cSuccessAction: ActionCreator<EapAction> = (data: {
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form165cActionTypes.SUBMIT_FORM_165C_SUCCESS,
  data
});

export const setForm165cSubmitStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form165cActionTypes.SET_FORM_165C_SUBMIT_STATUS,
  data
});

export const overrideForm165cExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: form165cActionTypes.OVERRIDE_FORM_165C_EXCEPTIONS,
  data
});

export const clearForm165cStatusAction: ActionCreator<EapAction> = () => ({
  type: form165cActionTypes.CLEAR_FORM_165C_STATUS
});
