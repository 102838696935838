/* eslint-disable prettier/prettier */
import * as React from 'react';
import { zip } from 'lodash';
import classNames from 'classnames/bind';

import styles from './Table.module.scss';

const cx = classNames.bind(styles);

type AlignmentOpts = '' | 'left' | 'center' | 'right' | string;

export interface TableCellProps {
  contentCell?: React.ReactNode;
  colAlign?: AlignmentOpts;
  highlight?: boolean;
  accent?: boolean;
  disabled?: boolean;
  isFull?: boolean;
  colspan?: number;
  className?: string;
  ellipsisOverflow?: boolean;
  maxWidth?: number;
  isError?: boolean;
}

interface TableRowProps {
  withColoredRows?: boolean;
  withColoredRowsOpposite?: boolean;
  children?: React.ReactNode;
  testingTag?: string;
  className?: string;
  hiddenRow?: boolean;
}
export const TableRow = ({
  withColoredRows = false,
  withColoredRowsOpposite = false,
  children = null,
  testingTag,
  hiddenRow,
  ...otherProps
}: TableRowProps) => {
  const { className = '', ...otherNonClassName } = otherProps;
  const tdClassName = cx({
    'with-colored-rows': withColoredRows,
    'with-colored-rows-opposite': withColoredRowsOpposite,
    [className]: className,
    'hidden': hiddenRow
  });
  return (
    <tr className={tdClassName} data-testid={testingTag} {...otherNonClassName}>
      {children}
    </tr>
  );
};

interface ColumnHeadingProps {
  align?: '' | 'left' | 'center' | 'right' | string;
  isHalf?: boolean;
  scope: 'row' | 'column';
  children?: React.ReactNode;
  colspan?: number;
  rowspan?: number;
  isFixedWidth?: boolean;
  hideHeader?: boolean;
  className: string;
  testingTag?: string;
}
export const ColumnHeading = ({
  align = '',
  isHalf = false,
  children = '',
  scope = 'column',
  colspan,
  rowspan,
  isFixedWidth,
  hideHeader = false,
  className,
  testingTag
}: ColumnHeadingProps) => (
    <th
      scope={scope}
      colSpan={colspan}
      rowSpan={rowspan}
      className={cx({
        half: isHalf,
        fixedWidth: isFixedWidth,
        left: align === '',
        right: align === 'right',
        center: align === 'center',
        hidden: hideHeader,
        [className]: className
      })}
      data-testid={testingTag}
    >
      {children}
    </th>
  );

interface ColumnProps {
  children?: React.ReactNode;
  align?: '' | 'left' | 'center' | 'right' | string;
  isHalf?: boolean;
  isFull?: boolean;
  isHeading?: boolean;
  isFixedWidth?: boolean;
  colspan?: number;
  rowspan?: number;
  className?: string;
  highlight?: boolean;
  accent?: boolean;
  disabled?: boolean;
  isError?: boolean;
  expandErrorHightlight?: boolean;
  hideEmpty?: boolean;
  ellipsisOverflow?: boolean;
  maxWidth?: number;
  testingTag?: string;
}
export const Column = ({
  align = '',
  isHeading = false,
  isHalf = false,
  isFull = false,
  isFixedWidth,
  children = '',
  colspan,
  rowspan,
  highlight,
  accent,
  disabled,
  isError,
  expandErrorHightlight,
  className = '',
  hideEmpty,
  ellipsisOverflow,
  maxWidth,
  testingTag
}: ColumnProps) => isHeading ? (
    <ColumnHeading
      align={align}
      colspan={colspan}
      rowspan={rowspan}
      scope="row"
      isFixedWidth={isFixedWidth}
      hideHeader={hideEmpty}
      testingTag={testingTag}
      className={className}
    >
      {children}
    </ColumnHeading>
  ) : (<td
    colSpan={colspan}
    className={cx(className, highlight, disabled, accent, {
      highlight,
      disabled,
      isFixedWidth,
      rowspan,
      accent,
      half: isHalf,
      full: isFull,
      left: align === '',
      right: align === 'right',
      center: align === 'center',
      hidden: hideEmpty,
      ellipsisOverflow,
      error: isError,
      expandErrorHightlight
    })}
    style={{ maxWidth }}
    data-testid={testingTag}
  >
    {children}
  </td>);

interface StyledTableProps {
  children: React.ReactNode;
  withColoredHeaderRow?: boolean;
  headerColor?: '' | 'blue';
  withColoredRows?: boolean;
  withColoredRowsOpposite?: boolean;
  boldFirstColumn?: boolean;
  withRowBorders?: boolean;
  withColumnBorders?: boolean;
  withFirstRowBorderTop?: boolean;
  withRowBordersNoBottom?: boolean;
  withTableBorder?: boolean;
  withBgColor?: '' | 'blue';
  isDisabled?: boolean;
  withEvenWidthColumns?: boolean;
  accent?: boolean;
  testingTag?: string;
}
export const StyledTable = ({
  children,
  withColoredHeaderRow = false,
  headerColor = '',
  withColoredRows = false,
  withColoredRowsOpposite = false,
  boldFirstColumn = false,
  withRowBorders = false,
  withColumnBorders = false,
  withRowBordersNoBottom = false,
  withFirstRowBorderTop = false,
  withTableBorder = false,
  withBgColor = '',
  withEvenWidthColumns = false,
  accent = false,
  isDisabled = false,
  testingTag
}: StyledTableProps) => {
  const tableClass = cx({
    table: true,
    'with-colored-header-row': withColoredHeaderRow,
    'header-blue': headerColor === 'blue',
    'with-colored-rows': withColoredRows,
    'with-colored-rows-opposite': withColoredRowsOpposite,
    'with-row-borders': withRowBorders,
    'with-column-borders': withColumnBorders,
    'with-row-borders-no-bottom': withRowBordersNoBottom,
    'with-first-row-border-top': withFirstRowBorderTop,
    'with-table-border': withTableBorder,
    'bold-first-column': boldFirstColumn,
    'bg-blue': withBgColor === 'blue',
    'even-width-columns': withEvenWidthColumns,
    withAccent: accent,
    disabled: isDisabled
  });
  return (
    <table className={tableClass} data-testid={testingTag}>
      {children}
    </table>
  );
};

type TableHeading = {
  headingCell: string | number | React.ReactNode;
  txtAlign?: '' | 'left' | 'center' | 'right' | string;
  colspan?: number;
  rowspan?: number;
  isFixedWidth?: boolean;
  key?: string;
  className?: string;
}

interface TableProps {
  tableHeading?: TableHeading[][];
  tableData: Array<any>;
  rowKey?: number | string | Function;
  boldFirstColumn?: boolean;
  withColoredHeaderRow?: boolean;
  headerColor?: '' | 'blue';
  withColoredRows?: boolean;
  withColoredRowsOpposite?: boolean;
  withRowBorders?: boolean;
  withColumnBorders?: boolean;
  withRowBordersNoBottom?: boolean;
  withFirstRowBorderTop?: boolean;
  withTableBorder?: boolean;
  withEvenWidthColumns?: boolean;
  withBgColor?: '' | 'blue';
  alignCellsTop?: boolean;
  alignHeadingTop?: boolean;
  hideEmptyColumns?: boolean;
  rotateTable?: boolean;
  accent?: boolean;
  isDisabled?: boolean;
  testingTag?: string;
  isPrint?: boolean;
}
const Table = (props: TableProps = {
  tableHeading: [],
  tableData: [],
  withColoredHeaderRow: false,
  headerColor: '',
  withColoredRows: false,
  withColoredRowsOpposite: false,
  withRowBorders: false,
  withColumnBorders: false,
  withFirstRowBorderTop: false,
  withBgColor: '',
  boldFirstColumn: false,
  withRowBordersNoBottom: false,
  withTableBorder: false,
  withEvenWidthColumns: false,
  isDisabled: false,
  alignCellsTop: false,
  alignHeadingTop: false,
  hideEmptyColumns: false,
  rotateTable: false,
  accent: false,
  isPrint: false
}) => {
  const getRowKey = (row: any, rowIndex: number) => {
    if (props.rowKey) {
      if (typeof props.rowKey === 'function') {
        return props.rowKey(row);
      }
      return row[props.rowKey];
    }
    return `row-${rowIndex}-${row[0]}`;
  };

  const getColumnKey = (cell: any, colIndex: number, key?: any) => {
    if (key) {
      return key;
    }
    return `col-${colIndex}-${cell}`;
  };

  const tableData = props.rotateTable ? zip(...props.tableData) : props.tableData;

  const transposedData = props.rotateTable ? props.tableData : zip(...tableData);
  // transpose table data to validate if intending to hide empty columns
  const matrixTransposedTableData = props.hideEmptyColumns && tableData.length > 0
    ? transposedData
    : [];

  return (
    <>
      <StyledTable {...props}>
        {props.tableHeading && props.tableHeading.length > 0 && (
          <thead className={props.alignHeadingTop?styles.alignTop:undefined}>
            {props.tableHeading?.map((row, index) => {
              const headingRowKey = getRowKey(row, index);

              return (<tr key={headingRowKey} >
                {row.map(({ headingCell, txtAlign, key, colspan, rowspan, isFixedWidth, className }, indexColumn) => {
                  const colKey = getColumnKey(headingCell, indexColumn, key);

                  return (
                    <Column
                      key={colKey}
                      align={txtAlign}
                      colspan={colspan}
                      rowspan={rowspan}
                      isFixedWidth={isFixedWidth}
                      className={className}
                      isHeading
                      hideEmpty={
                        props.hideEmptyColumns &&
                        matrixTransposedTableData[indexColumn] &&
                        matrixTransposedTableData[indexColumn]
                          .filter((rowT: any) => rowT?.contentCell).length === 0
                      }
                      testingTag={props.testingTag ? `${props.testingTag}-table-header-${indexColumn}` : undefined}
                    >
                      {headingCell}
                    </Column>
                  );
                })
                }
              </tr>)
            })}
          </thead>
        )}
        {tableData && (
          <tbody className={cx({ alignTop: props.alignCellsTop })}>
            {tableData.map((row: Array<TableCellProps>, rowIndex: number) => (
              <TableRow
                key={getRowKey(row, rowIndex)}
                withColoredRows={props.withColoredRows}
                withColoredRowsOpposite={props.withColoredRowsOpposite}
              >
                {row.map(({
                  contentCell,
                  colAlign,
                  highlight,
                  accent,
                  disabled,
                  isError,
                  colspan,
                  className,
                  isFull,
                  ellipsisOverflow,
                  maxWidth
                }, index) => {
                  const key = index;
                  const colKey = getColumnKey(contentCell, index, key);
                  return (
                    <Column
                      key={colKey}
                      align={colAlign}
                      highlight={highlight}
                      disabled={disabled}
                      accent={accent}
                      isError={isError}
                      expandErrorHightlight={isError && row.length > index + 1 && row[index + 1].isError}
                      colspan={isFull ? Math.max(...tableData.map(n => n.length)) : colspan}
                      className={className}
                      isFull={isFull}
                      isHalf={tableData[0].length === 2}
                      isHeading={
                        index === 0 &&
                        !props.tableHeading?.length &&
                        props.boldFirstColumn
                      }
                      ellipsisOverflow={ellipsisOverflow}
                      maxWidth={maxWidth}
                      hideEmpty={props.hideEmptyColumns && !contentCell}
                      testingTag={props.testingTag ? `${props.testingTag}-table-${rowIndex}-${index}` : undefined}
                    >
                      {contentCell}
                    </Column>
                  );
                })}
              </TableRow>
            ))}
          </tbody>
        )}
      </StyledTable>
    </>
  );
};

export default Table;
