import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'types';

export const userSessionSelector = (state: RootState) => state.userSession;

export const sessionRefreshModalSelector = createSelector(
  userSessionSelector,
  state => state?.userTimer?.isSessionRefreshModalVisible
);

export const tokenExpiryTimerSelector = createSelector(
  userSessionSelector,
  state => state?.userTimer?.isTokenExpiryTimerVisible
);

export const sessionExpirySelector = createSelector(
  userSessionSelector,
  state => state?.userTimer?.sessionExpiry
);
