import { ActionCreator } from 'redux';
import { EapAction } from 'types';
import {
  BankingInfoModel,
  MemberSearchResult,
  Form143Model,
  ExceptionsObject,
  EmploymentModel,
  DonnaExceptionsObject
} from 'interfaces';

export enum form143ActionTypes {
  CHECK_MEMBER_CONTEXT_REQUESTED = 'CHECK_MEMBER_CONTEXT_REQUESTED',
  CHECK_MEMBER_CONTEXT_SUCCEEDED = 'CHECK_MEMBER_CONTEXT_SUCCEEDED',
  RESET_MEMBER_CONTEXT = 'RESET_MEMBER_CONTEXT',
  CREATE_FORM_143_REQUESTED = 'CREATE_FORM_143_REQUESTED',
  CREATE_FORM_143_SUCCEEDED = 'CREATE_FORM_143_SUCCEEDED',
  UPDATE_FORM_143_LAST_DATE_WORKED = 'UPDATE_FORM_143_LAST_DATE_WORKED',
  SHOW_LAST_DATE_WORKED_ERROR_MODAL = 'SHOW_LAST_DATE_WORKED_ERROR_MODAL',
  SET_IS_DIRTY_LAST_DATE_WORKED = 'SET_IS_DIRTY_LAST_DATE_WORKED',
  RETRY_CREATE_FORM_143_REQUESTED = 'RETRY_CREATE_FORM_143_REQUESTED',
  RETRY_CREATE_FORM_143_SUCCEEDED = 'RETRY_CREATE_FORM_143_REQUESTED',
  SET_FORM_143_REQUEST_NUMBER = 'SET_FORM_143_REQUEST_NUMBER',
  GET_FORM_143_REQUESTED = 'GET_FORM_143_REQUESTED',
  GET_FORM_143_SUCCEEDED = 'GET_FORM_143_SUCCEEDED',
  GET_FORM_143_FAILED = 'GET_FORM_143_FAILED',
  GET_LATEST_FORM_143_REQUESTED = 'GET_LATEST_FORM_143_REQUESTED',
  GET_LATEST_FORM_143_SUCCEEDED = 'GET_LATEST_FORM_143_SUCCEEDED',
  GET_LATEST_FORM_143_FAILED = 'GET_LATEST_FORM_143_FAILED',
  SET_FORM_143_STEP = 'SET_FORM_143_STEP',
  SET_FORM_143_STATUS = 'SET_FORM_143_STATUS',
  SET_FORM_143_CARRYOVER_REQUESTED = 'SET_FORM_143_CARRYOVER_REQUESTED',
  SET_FORM_143_CARRYOVER_SUCCEEDED = 'SET_FORM_143_CARRYOVER_SUCCEEDED',
  SET_FORM_143_CARRYOVER_FAILED = 'SET_FORM_143_CARRYOVER_FAILED',
  RESET_FINANCIAL_INFO = 'RESET_FINANCIAL_INFO',
  UPDATE_FORM_143_CARRYOVER_REQUESTED = 'UPDATE_FORM_143_CARRYOVER_REQUESTED',
  UPDATE_FORM_143_FINANCIAL_INFO = 'UPDATE_FORM_143_FINANCIAL_INFO',
  UPDATE_FORM_143_CARRYOVER_FAILED = 'UPDATE_FORM_143_CARRYOVER_FAILED',
  STASH_DIRTY_VALUES = 'STASH_DIRTY_VALUES',
  CALCULATE_PA_REQUESTED = 'CALCULATE_PA_REQUESTED',
  CALCULATE_PA_SUCCEEDED = 'CALCULATE_PA_SUCCEEDED',
  CALCULATE_PA_FAILED = 'CALCULATE_PA_FAILED',
  SET_BANKING_INFORMATION_REQUESTED = 'SET_BANKING_INFORMATION_REQUESTED',
  SET_BANKING_INFORMATION_SUCCEEDED = 'SET_BANKING_INFORMATION_SUCCEEDED',
  GET_FORM_143_PDF_SUCCEEDED = 'GET_FORM_143_PDF_SUCCEEDED',
  GET_FORM_143_PDF_FAILED = 'GET_FORM_143_PDF_FAILED',
  SET_BANKING_PDF = 'SET_BANKING_PDF',
  GET_BANKING_PDF = 'GET_BANKING_PDF',
  SET_BANKING_INFORMATION_NEEDED = 'SET_BANKING_INFORMATION_NEEDED',
  SET_BANKING_INFORMATION_SHOW_MODAL = 'SET_BANKING_INFORMATION_SHOW_MODAL',
  DELETE_BANKING_INFORMATION_PDF = 'DELETE_BANKING_INFORMATION_PDF',
  SAVE_FORM_143_REQUESTED = 'SAVE_FORM_143_REQUESTED',
  SAVE_AND_REVIEW_FORM_143_REQUESTED = 'SAVE_AND_REVIEW_FORM_143_REQUESTED',
  SAVE_FORM_143_SUCCESS = 'SAVE_FORM_143_SUCCESS',
  SAVE_FORM_143_FAILED = 'SAVE_FORM_143_FAILED',
  SUBMIT_FORM_143_REQUESTED = 'SUBMIT_FORM_143_REQUESTED',
  SUBMIT_FORM_143_SUCCESS = 'SUBMIT_FORM_143_SUCCESS',
  CLEAR_FORM_143_STATUS = 'CLEAR_FORM_143_STATUS',
  SET_FORM_143_SUBMIT_STATUS = 'SET_FORM_143_SUBMIT_STATUS',
  OVERRIDE_FORM_143_EXCEPTIONS = 'OVERRIDE_FORM_143_EXCEPTIONS',
  OVERRIDE_FORM_143_DONNA_WARNINGS = 'OVERRIDE_FORM_143_DONNA_WARNINGS',
  GET_FORM_143_PDF = 'GET_FORM_143_PDF',
  UNLOCK_FORM_143 = 'UNLOCK_FORM_143',
  DELETE_FORM_143 = 'DELETE_FORM_143',
  DELETE_FORM_143_SUCCESS = 'DELETE_FORM_143_SUCCESS',
  DELETE_FORM_143_FAILED = 'DELETE_FORM_143_FAILED',
  GET_RETRO_PAY_143_REQUESTED = 'GET_RETRO_PAY_143_REQUESTED',
  GET_RETRO_PAY_143_SUCCESS = 'GET_RETRO_PAY_143_SUCCESS',
  GET_RETRO_PAY_143_FAILED = 'GET_RETRO_PAY_143_FAILED',
  SAVE_RETRO_PAY_REQUESTED = 'SAVE_RETRO_PAY_REQUESTED',
  DELETE_RETRO_PAY_143_REQUESTED = 'DELETE_RETRO_PAY_143_REQUESTED',
  CHECK_MEMBER_PROFILE_VALIDITY_143_REQUESTED = 'CHECK_MEMBER_PROFILE_VALIDITY_143_REQUESTED',
  VALID_MEMBER_RESET_143 = 'VALID_MEMBER_RESET_143',
  CHECK_MEMBER_PROFILE_VALIDITY_143_SUCCEEDED = 'CHECK_MEMBER_PROFILE_VALIDITY_143_SUCCEEDED',
  CHECK_MEMBER_PROFILE_VALIDITY_143_FAILED = 'CHECK_MEMBER_PROFILE_VALIDITY_143_FAILED',
  DELETE_FORM_143_REQUESTED = 'DELETE_FORM_143_REQUESTED',
  REFRESH_FORM_143_REQUESTED = 'REFRESH_FORM_143_REQUESTED',
  DELETE_FORM_143_SUCCEESS = 'DELETE_FORM_143_SUCCESS',
  SAVE_FORM_143_RETRO_REQUESTED = 'SAVE_FORM_143_RETRO_REQUESTED',
  GET_DISABILITY_BENEFIT_START_DATE = 'GET_DISABILITY_BENEFIT_START_DATE',
  SHOW_LAST_DATE_WORKED_MODAL = 'SHOW_LAST_DATE_WORKED_MODAL',
  SET_MINIMUM_RETIRMENT_DATE = 'SET_MINIMUM_RETIRMENT_DATE',
  CLEAR_DONNA_EXCEPTIONS_143 = 'CLEAR_DONNA_EXCEPTIONS_143'
}

export interface CreateForm143Payload extends MemberSearchResult {
  employmentModel: {
    eventType?: 'T' | 'R';
    dateLastWorked?: string;
    lastDateRegEarnings?: string;
    reasonForDifference?: string;
    reasonForDifferenceOther?: string;
  };
}

export interface BankingInformationPayLoad {
  bankAccountNumber: string;
  bankNumber: string;
  transitNumber: string;
}

export const checkMemberContextAction: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form143ActionTypes.CHECK_MEMBER_CONTEXT_REQUESTED,
  data
});

export const checkMemberContextSucceededAction: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form143ActionTypes.CHECK_MEMBER_CONTEXT_SUCCEEDED,
  data
});

export const resetMemberContextAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.RESET_MEMBER_CONTEXT
});

export const createForm143Action: ActionCreator<EapAction> = (
  data: CreateForm143Payload
) => ({
  type: form143ActionTypes.CREATE_FORM_143_REQUESTED,
  data
});

export const updateForm143LastDateWorkedAction: ActionCreator<EapAction> = (
  data: EmploymentModel
) => ({
  type: form143ActionTypes.UPDATE_FORM_143_LAST_DATE_WORKED,
  data
});

export const retryCreateForm143Action: ActionCreator<EapAction> = (
  data: CreateForm143Payload
) => ({
  type: form143ActionTypes.RETRY_CREATE_FORM_143_REQUESTED,
  data
});

export const setForm143RequestNumberAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form143ActionTypes.SET_FORM_143_REQUEST_NUMBER,
  data
});

export const getForm143Action: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  lockForm: boolean;
}) => ({
  type: form143ActionTypes.GET_FORM_143_REQUESTED,
  data
});

export const getForm143SuccessAction: ActionCreator<EapAction> = (data: {
  requestNumber: number;
  e143Model: Form143Model;
  memberInfo: MemberSearchResult;
}) => ({
  type: form143ActionTypes.GET_FORM_143_SUCCEEDED,
  data
});

export const getLatest143Action: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  disableSpinner?: boolean;
  updateFinancialInfoOnly?: boolean;
  dirtyStateSave?: boolean;
}) => ({
  type: form143ActionTypes.GET_LATEST_FORM_143_REQUESTED,
  data
});

export const stashDirtyValues: ActionCreator<EapAction> = (data: any) => ({
  type: form143ActionTypes.STASH_DIRTY_VALUES,
  data
});

export const setIsDirtyLastDateWorked: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form143ActionTypes.SET_IS_DIRTY_LAST_DATE_WORKED,
  data
});

export const getLatest143SuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form143ActionTypes.GET_LATEST_FORM_143_SUCCEEDED,
  data
});

export const getLatestForm143FailureAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.GET_LATEST_FORM_143_FAILED
});

export const setForm143StepAction: ActionCreator<EapAction> = (data: any) => ({
  type: form143ActionTypes.SET_FORM_143_STEP,
  data
});

export const setCarryOverAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form143ActionTypes.SET_FORM_143_CARRYOVER_REQUESTED,
  data
});

export const setBankingInformationAction: ActionCreator<EapAction> = (
  data: BankingInformationPayLoad
) => ({
  type: form143ActionTypes.SET_BANKING_INFORMATION_REQUESTED,
  data
});

export const carryOverUpdateAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.UPDATE_FORM_143_CARRYOVER_REQUESTED
});

export const resetFinancialInfo: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.RESET_FINANCIAL_INFO
});

export const updateFinancialInfo: ActionCreator<EapAction> = (data: any) => ({
  type: form143ActionTypes.UPDATE_FORM_143_FINANCIAL_INFO,
  data
});

export const calculatePAAction: ActionCreator<EapAction> = (data: any) => ({
  type: form143ActionTypes.CALCULATE_PA_REQUESTED,
  data
});

export const calculatePAActionSuccess: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form143ActionTypes.CALCULATE_PA_SUCCEEDED,
  data
});

export const getBankingPdfAction: ActionCreator<EapAction> = (
  data: BankingInfoModel
) => ({
  type: form143ActionTypes.GET_BANKING_PDF,
  data
});

export const setBankingPdfAction: ActionCreator<EapAction> = (data: any) => ({
  type: form143ActionTypes.SET_BANKING_PDF,
  data
});

export const setBankingCloseAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form143ActionTypes.SET_BANKING_INFORMATION_SHOW_MODAL,
  data
});
export const deleteAdvanceElectionAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.DELETE_BANKING_INFORMATION_PDF
});

export const bankingInfoSuccededAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form143ActionTypes.SET_BANKING_INFORMATION_SUCCEEDED,
  data
});

export const bankingInfoNeededAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form143ActionTypes.SET_BANKING_INFORMATION_NEEDED,
  data
});

export const setMinimumRetirementDate: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form143ActionTypes.SET_MINIMUM_RETIRMENT_DATE,
  data
});

export const showLastDateWorkedErrorModalAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form143ActionTypes.SHOW_LAST_DATE_WORKED_ERROR_MODAL,
  data
});

export const showLastDateModalAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form143ActionTypes.SHOW_LAST_DATE_WORKED_MODAL,
  data
});

export const showModalAction: ActionCreator<EapAction> = (data: boolean) => ({
  type: form143ActionTypes.SET_BANKING_INFORMATION_SHOW_MODAL,
  data
});

export const saveForm143Action: ActionCreator<EapAction> = (data: {
  mergedDataFin: any;
  notes: string;
  exceptions?: Array<ExceptionsObject>;
  donnaExceptions?: DonnaExceptionsObject;
  reviewForm?: boolean;
  submitForm?: boolean;
  lockForm?: boolean;
  dirtyStateSave?: boolean;
}) => ({
  type: form143ActionTypes.SAVE_FORM_143_REQUESTED,
  data
});
export const saveForm143RetroAction: ActionCreator<EapAction> = (data: {
  mergedDataFin: any;
  notes: string;
  exceptions?: Array<ExceptionsObject>;
  donnaExceptions?: DonnaExceptionsObject;
  reviewForm?: boolean;
  submitForm?: boolean;
  lockForm?: boolean;
  updateFinancialInfoOnly?: boolean;
  deleteData?: any;
}) => ({
  type: form143ActionTypes.SAVE_FORM_143_RETRO_REQUESTED,
  data
});

export const saveForm143SuccessAction: ActionCreator<EapAction> = (data: {
  notes: string;
}) => ({
  type: form143ActionTypes.SAVE_FORM_143_SUCCESS,
  data
});

export const saveForm143FailedAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.SAVE_FORM_143_FAILED
});

export const clearForm143StatusAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.CLEAR_FORM_143_STATUS
});

export const submitForm143Action: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.SUBMIT_FORM_143_REQUESTED
});

export const submitForm143SuccessAction: ActionCreator<EapAction> = (data: {
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form143ActionTypes.SUBMIT_FORM_143_SUCCESS,
  data
});

export const setForm143SubmitStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form143ActionTypes.SET_FORM_143_SUBMIT_STATUS,
  data
});

export const overrideForm143ExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: form143ActionTypes.OVERRIDE_FORM_143_EXCEPTIONS,
  data
});

export const overrideForm143DonnaWarningsAction: ActionCreator<EapAction> = (
  data: Array<DonnaExceptionsObject>
) => ({
  type: form143ActionTypes.OVERRIDE_FORM_143_DONNA_WARNINGS,
  data
});

export const getForm143PDFAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  formNo: string;
  employerNo: string;
  isView: boolean;
  fileName: string;
}) => ({
  type: form143ActionTypes.GET_FORM_143_PDF,
  data
});

export const unlockForm143Action: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form143ActionTypes.UNLOCK_FORM_143,
  data
});

export const deleteForm143Action: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form143ActionTypes.DELETE_FORM_143,
  data
});
export const getRetroPay143Action: ActionCreator<EapAction> = (data: {
  servicePeriodNo: number;
  requestNumber: string;
}) => ({
  type: form143ActionTypes.GET_RETRO_PAY_143_REQUESTED,
  data
});

export const getRetroPay143SuccessAction: ActionCreator<EapAction> = (data: {
  retroPayModels: Array<any>;
  servicePeriodNo: number;
}) => ({
  type: form143ActionTypes.GET_RETRO_PAY_143_SUCCESS,
  data
});

export const deleteRetroPay143Action: ActionCreator<EapAction> = (data: {
  servicePeriodNo: number;
  requestNumber: string;
}) => ({
  type: form143ActionTypes.DELETE_RETRO_PAY_143_REQUESTED,
  data
});

export const deleteForm143SuccessAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.DELETE_FORM_143_SUCCESS
});

export const deleteForm143FailedAction: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.DELETE_FORM_143_FAILED
});

export const saveRetroPayAction: ActionCreator<EapAction> = (data: {
  servicePeriodNo: number;
  payload: any;
}) => ({
  type: form143ActionTypes.SAVE_RETRO_PAY_REQUESTED,
  data
});
export const checkMemberProfileValidity: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form143ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_143_REQUESTED,
  data
});
export const resetValidMember: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.VALID_MEMBER_RESET_143
});

export const memberProfileValid: ActionCreator<EapAction> = (data: any) => ({
  type: form143ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_143_SUCCEEDED,
  data
});

export const memberProfileInvalid: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_143_FAILED
});

export const refreshForm143Action: ActionCreator<EapAction> = (data: {
  create119Data: {
    membershipNo: string;
    employerNo: string;
    employeeModel: any;
  };
  deleteAction: EapAction;
}) => ({ type: form143ActionTypes.REFRESH_FORM_143_REQUESTED, data });

export const clearDonnaExceptions143: ActionCreator<EapAction> = () => ({
  type: form143ActionTypes.CLEAR_DONNA_EXCEPTIONS_143
});
