import classNames from 'classnames/bind';

import styles from './ProgressBar.module.scss';

const cx = classNames.bind(styles);

export type ProgressBarStep = {
  stepNumber: number;
  stepLabel: string;
};

interface ProgressBarProps {
  steps: ProgressBarStep[];
  currentStep: number;
  isContainerPadded?: boolean;
}
const ProgressBar = ({
  steps,
  currentStep,
  isContainerPadded = true
}: ProgressBarProps) => {
  const activeStep = steps.filter(step => step.stepNumber === currentStep)[0];

  const getClassName = (step: ProgressBarStep) =>
    cx({
      progressStep: true,
      activeStep: activeStep.stepNumber === step.stepNumber,
      completedStep: step.stepNumber < activeStep.stepNumber,
      nextStep: step.stepNumber > activeStep.stepNumber
    });

  return (
    <div
      className={cx({ container: isContainerPadded })}
      data-testid="progress-bar"
    >
      <div className={styles.progressBar}>
        {steps.map(step => (
          <div key={step.stepNumber} className={getClassName(step)}>
            {step.stepLabel}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
