import { mapKeys, camelCase } from 'lodash';
import { format, parse, sub } from 'date-fns';
import {
  IReviewImports,
  IReviewedImports,
  ISearchCriteria,
  IUserSearchCriteria,
  numberOfDays,
  normalRetirementAge,
  employmentStatus,
  formTypes,
  formId,
  importedBy
} from '../types';

const parseDate = (date: string) =>
  parse(date, 'MMM-dd-yyyy hh:mm aaaa', new Date());

export const transformImportsData = (importsData: Record<string, any>[]) =>
  [...importsData]
    .sort((previous: Record<string, any>, current: Record<string, any>) => {
      const previousDate: any = new Date(previous.hashMap.batch_date);
      const currentDate: any = new Date(current.hashMap.batch_date);
      return currentDate - previousDate;
    })
    .map((data: Record<string, any>) => {
      const dataWithCamelCasedKeys = mapKeys(data.hashMap, (_, key) =>
        camelCase(key)
      );

      const batchDate = parseDate(dataWithCamelCasedKeys.batchDate);
      const date = format(batchDate, 'MMM d, yyyy');
      const time = format(batchDate, 'h:mm a');

      dataWithCamelCasedKeys.batchDate = date;
      dataWithCamelCasedKeys.batchTime = time;
      dataWithCamelCasedKeys.isErrorsNumberExceeding2000 =
        Number(dataWithCamelCasedKeys.errorsNo) > 2000;
      return dataWithCamelCasedKeys;
    });

export const transformErrorsToReview = (errors: Record<string, any>[]) =>
  errors.map((data: Record<string, any>) => {
    const dataWithCamelCasedKeys = mapKeys(data.hashMap, (_, key) =>
      camelCase(key)
    );
    if (dataWithCamelCasedKeys.sin && dataWithCamelCasedKeys.sin.length > 3) {
      dataWithCamelCasedKeys.sin = `*** *** ${dataWithCamelCasedKeys.sin.slice(
        -3
      )}`;
    }
    return dataWithCamelCasedKeys;
  });

export const getCurrentBatchData = (
  reviewImportsData: IReviewImports[],
  currentBatchProcessNo: string
) =>
  reviewImportsData.find(
    (data: IReviewImports) => data.batchProcessNo === currentBatchProcessNo
  );

export const verifyReviewStatus = (
  reviewedImports: IReviewedImports[],
  currentBatchProcessNo: string
) =>
  reviewedImports.length > 0 &&
  reviewedImports.some(
    (data: IReviewedImports) => data.batchProcessNo === currentBatchProcessNo
  );

export const transformSubmissionData = (
  submissionData: Record<string, any>[]
) =>
  submissionData.map((data: Record<string, any>) => {
    const dataWithCamelCasedKeys = mapKeys(data.hashMap, (_, key) =>
      camelCase(key)
    );
    const batchDate = parseDate(dataWithCamelCasedKeys.batchDate);
    const date = format(batchDate, 'MMM d, yyyy');
    const time = format(batchDate, 'h:mm a');

    dataWithCamelCasedKeys.batchDate = date;
    dataWithCamelCasedKeys.batchTime = time;
    return dataWithCamelCasedKeys;
  });

export const trimDate = (date: string) =>
  date
    .split(' ')
    .slice(1)
    .join(' ')
    .replace(/ /g, '-');

export const adjustSearchCriteriaPayload = (
  initialPayload: ISearchCriteria,
  customPayload: IUserSearchCriteria
) => {
  const modifiedPayload = initialPayload;
  if (customPayload) {
    modifiedPayload.owner =
      customPayload.importedBy !== importedBy.ALL_USERS
        ? customPayload.importedBy
        : null;
    modifiedPayload.nra =
      customPayload.normalRetirementAge &&
      customPayload.normalRetirementAge !== normalRetirementAge.ALL
        ? customPayload.normalRetirementAge
        : null;
    modifiedPayload.fromName = customPayload.nameRangeA
      ? customPayload.nameRangeA
      : null;
    modifiedPayload.toName = customPayload.nameRangeZ
      ? customPayload.nameRangeZ
      : null;
    modifiedPayload.employmentStatus =
      customPayload.employmentStatus &&
      customPayload.employmentStatus !== employmentStatus.ALL
        ? customPayload.employmentStatus
        : null;
    if (customPayload.dateImported === numberOfDays.LAST_7_DAYS) {
      modifiedPayload.fromDate = trimDate(
        sub(new Date(), { days: 7 }).toDateString()
      );
    }
    if (customPayload.dateImported === numberOfDays.LAST_30_DAYS) {
      modifiedPayload.fromDate = trimDate(
        sub(new Date(), { days: 30 }).toDateString()
      );
    }
    if (customPayload.dateImported === numberOfDays.LAST_6_MONTHS) {
      modifiedPayload.fromDate = trimDate(
        sub(new Date(), { months: 6 }).toDateString()
      );
    }
    if (customPayload.dateImported === numberOfDays.LAST_12_MONTHS) {
      modifiedPayload.fromDate = trimDate(
        sub(new Date(), { months: 12 }).toDateString()
      );
    }
  }
  return initialPayload;
};

export const createDefaultSearchObj = (
  selectEmployerNumber: number,
  selectedFilteredFilingType: formTypes
) => {
  const defaultSearch: ISearchCriteria = {
    employeeId: null,
    employerNumber: selectEmployerNumber,
    employmentStatus: null,
    formMonth: null,
    formYear: null,
    fromDate: null,
    fromName: null,
    nra: null,
    owner: null,
    reason: null,
    reconciliationYear: null,
    requestType:
      selectedFilteredFilingType === formTypes.FORM_119
        ? formId.FORM_119
        : selectedFilteredFilingType === formTypes.FORM_102
        ? formId.FORM_102
        : formId.FORM_165,
    rowLimit: null,
    searchAscending: false,
    sin: null,
    sortByDeptId: null,
    sortByEmployeeId: null,
    sortByLastName: true,
    startIndex: 0,
    status: 1,
    toDate: trimDate(new Date().toDateString()),
    toName: null
  };

  return defaultSearch;
};
