import { useState } from 'react';
import { useInterval } from 'react-use';
import useLocalStorageState from 'use-local-storage-state';
import {
  isInMaintenance,
  MaintenanceFlagContainer,
  MaintenanceFlags,
  MAINTENANCE_FLAG_KEY
} from './maintenanceFlags';

export const useMaintenanceFlag = (flag: MaintenanceFlags) => {
  const [maintenanceFlags] = useLocalStorageState<MaintenanceFlagContainer>(
    MAINTENANCE_FLAG_KEY
  );
  const [flagState, setFlagState] = useState<boolean>(
    maintenanceFlags ? isInMaintenance(flag, maintenanceFlags) : false
  );

  useInterval(() => {
    const updatedFlagState = isInMaintenance(flag, maintenanceFlags);
    setFlagState(updatedFlagState);
  }, 10_000);

  return flagState;
};
