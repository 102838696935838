/* eslint-disable no-plusplus */
import { getYear, isAfter, isBefore, isValid, parse } from 'date-fns';
import { TFunction } from 'i18next';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';
import { formatDate } from 'utils';
import { EmployeeInformation } from './sliceReducer';

export const mapDataToForm101Data = (data: EmployeeInformation) => ({
  email: data?.emailAddress ?? '',
  primaryPhone: data?.mobilePhone ?? '',
  secondaryPhone: data?.homePhone ?? '',
  employeeId: data?.employeeId ?? '',
  langPref: data?.languagePreference ?? '',
  dateOfBirth: formatDate(data?.dateOfBirth, true, false, 'MM/dd/yyyy') ?? '',
  hireDate: formatDate(data?.hireDate, true, false, 'MM/dd/yyyy') ?? '',
  createDate: formatDate(data?.createDate, true, false, 'MM/dd/yyyy') ?? '',
  salary: data?.salaryType ?? '',
  address1: data?.addressLine1 ?? '',
  address2: data?.addressLine2 ?? '',
  apartmentUnit: data?.aptSuitUnitNumber ?? '',
  city: data?.city ?? '',
  country: data?.country ?? '',
  postal: data?.postalCode ?? '',
  province: data?.province ?? '',
  occupation: '',
  memberAffiliation:
    data?.unionAffiliation === '' ? 'None' : data?.unionAffiliation,
  employmentType: data?.employmentType ?? '',
  firstName: data?.firstName ?? '',
  lastName: data?.lastName ?? '',
  sin: '',
  salaryRate: data?.salaryAmount,
  primaryPhoneValidityStatus: '',
  secondaryPhoneValidityStatus: '',
  validAddressState: 'not-validated' as const,
  validEmailAddressState: QASEmailResponseTypes.NOT_VERIFIED as const
});

export const extractErrorRecords = (errors: any, dataSet: any) => {
  const errorRecords: any = [];
  errors?.forEach((obj: any) => {
    dataSet?.forEach((data: any) => {
      if (data?.id === obj?.index) {
        errorRecords.push(data);
      }
    });
  });
  return errorRecords;
};

export const updateDataSet = (validationData: any, dataSet: any) => {
  const tempArray = [...dataSet];
  if (JSON.stringify(validationData) === JSON.stringify(tempArray)) {
    return tempArray;
  }

  validationData?.forEach((data: any) => {
    if (data) {
      tempArray[data?.id - 1] = data;
    }
  });

  return tempArray;
};

export const isPriorToJan2023 = (date: string) => {
  if (isValid(parse(date, 'MM/dd/yyyy', new Date()))) {
    return getYear(parse(date, 'MM/dd/yyyy', new Date())) < 2023;
  }
  return false;
};

export const getAge = (today: Date, birthDate: Date) => {
  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const getAgeByEndOfTheYear = (today: Date, birthDate: Date) => {
  const endOfYearDate = new Date(`12/12/${today.getFullYear()}`);
  let age = endOfYearDate.getFullYear() - birthDate.getFullYear();
  const monthDiff = endOfYearDate.getMonth() - birthDate.getMonth();

  if (monthDiff < 0) {
    age--;
  }

  return age;
};

export const isMaxAgeReached = (
  date: string,
  systemDate: string,
  createDate?: string
) => {
  if (isValid(parse(date, 'MM/dd/yyyy', new Date()))) {
    const today = createDate
      ? new Date(new Date(createDate).setHours(0, 0, 0, 0))
      : new Date(new Date(systemDate).setHours(0, 0, 0, 0));
    const birthDate = new Date(date);
    const currentYear = today.getFullYear();

    if (
      isAfter(today, new Date(`11/29/${currentYear}`)) &&
      isBefore(today, new Date(`01/01/${currentYear + 1}`))
    ) {
      return (
        getAge(today, birthDate) >= 70 &&
        getAgeByEndOfTheYear(today, birthDate) > 70
      );
    }
    return getAge(today, birthDate) > 70;
  }
  return false;
};

export const getEmploymentTypeInFullForm = (
  type: string | undefined,
  t: TFunction
) => {
  switch (type) {
    case 'T':
      return t('NFT_EMPLOYMENT_TYPE_T');
    case 'C':
      return t('NFT_EMPLOYMENT_TYPE_C');
    case 'S':
      return t('NFT_EMPLOYMENT_TYPE_S');
    case 'ST':
      return t('NFT_EMPLOYMENT_TYPE_ST');
    case 'PT':
      return t('NFT_EMPLOYMENT_TYPE_PT');
    case 'O':
      return t('NFT_EMPLOYMENT_TYPE_O');
    default:
      return t('NA');
  }
};
