import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './ToggleSwitch.module.scss';

interface ToggleSwitchProps {
  name: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: FocusEvent<HTMLInputElement>) => void;
  checked?: boolean;
  autoTestingTag?: string;
  disabled?: boolean;
}

const cx = classNames.bind(styles);

const ToggleSwitch = ({
  name,
  onChange,
  onBlur,
  checked,
  autoTestingTag,
  disabled
}: ToggleSwitchProps) => {
  const [switchedOn, setSwitchedOn] = useState(checked);
  const inputRef = useRef<HTMLInputElement>(null);

  const switchClass = cx({
    switch: true,
    switchOn: switchedOn,
    switchOff: !switchedOn
  });

  useEffect(() => {
    setSwitchedOn(checked);
  }, [checked]);

  const clickInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <div className={styles.toggleSwitch}>
      <label className={styles.label} htmlFor={name}>
        <input
          type="checkbox"
          name={name}
          className={styles.checkbox}
          checked={switchedOn}
          onChange={onChange}
          onBlur={onBlur}
          data-auto={autoTestingTag}
          disabled={disabled}
          ref={inputRef}
        />
        <div
          onClick={clickInput}
          onKeyPress={clickInput}
          className={switchClass}
          role="switch"
          aria-checked={switchedOn}
          tabIndex={0}
        >
          <div className={styles.toggleButton} />
        </div>
      </label>
    </div>
  );
};

export default ToggleSwitch;
