import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import { MemberSearchResult } from 'interfaces';
import { QASEmailResponseTypes } from 'types/qas-email-response-types';

export enum form119ActionTypes {
  SAVE_FORM_119_IN_STORE = 'SAVE_FORM_119_IN_STORE',
  SET_FORM_119_STEP = 'SET_FORM_119_STEP',
  SET_FORM_119_STATUS = 'SET_FORM_119_STATUS',
  SET_FORM_119_NOT_NEW_FORM = 'SET_FORM_119_NOT_NEW_FORM',
  SET_EMPLOYMENT_PERIODS = 'SET_EMPLOYMENT_PERIODS',
  SET_SELECTED_RETRO_TYPE = 'SET_SELECTED_RETRO_TYPE',
  SET_RETRO_PAY_IN_STORE = 'SET_RETRO_PAY_IN_STORE',
  SET_CURRENT_RETRO_INDEX = 'SET_CURRENT_RETRO_INDEX',
  SET_RETRO_START_DATE = 'SET_RETRO_START_DATE',
  SET_RETRO_END_DATE = 'SET_RETRO_END_DATE',
  SHOW_RETRO_MODAL_TABLE = 'SHOW_RETRO_MODAL_TABLE',
  CLEAR_RETRO_PAY_IN_STORE = 'CLEAR_RETRO_PAY_IN_STORE',
  CLEAR_RETRO_PAY_MODELS = 'CLEAR_RETRO_PAY_MODELS',
  SET_EMPLOYEE_ID_IN_STORE = 'SET_EMPLOYEE_ID_IN_STORE',
  SET_DEPT_ID_IN_STORE = 'SET_DEPT_ID_IN_STORE',
  SET_EMAIL_IN_STORE = 'SET_EMAIL_IN_STORE',
  RESET_TO_ORIGINAL_DATA_ON_REFRESH = 'RESET_TO_ORIGINAL_DATA_ON_REFRESH',
  UPDATE_RETRO_PAY_REQUESTED = 'UPDATE_RETRO_PAY_REQUESTED',
  UPDATE_RETRO_PAY_SUCCEEDED = 'UPDATE_RETRO_PAY_SUCCEEDED',
  UPDATE_RETRO_PAY_FAILED = 'UPDATE_RETRO_PAY_FAILED',
  DELETE_RETRO_PAY_REQUESTED = 'DELETE_RETRO_PAY_REQUESTED',
  DELETE_RETRO_PAY_SUCCEEDED = 'DELETE_RETRO_PAY_SUCCEEDED',
  DELETE_RETRO_PAY_FAILED = 'DELETE_RETRO_PAY_FAILED',
  ADD_TO_RETRO_PAY_IN_STORE = 'ADD_TO_RETRO_PAY_IN_STORE',
  REMOVE_FROM_RETRO_PAY_IN_STORE = 'REMOVE_FROM_RETRO_PAY_IN_STORE',
  SAVE_FORM_IN_STORE = 'SAVE_FORM_IN_STORE',
  CREATE_FORM_119 = 'CREATE_FORM_119',
  CLEAR_FORM_119 = 'CLEAR_FORM_119',
  SET_MEMBER_INFO_FORM_119 = 'SET_MEMBER_INFO_FORM_119',
  SET_REQUEST_NUMBER_FORM_119 = 'SET_REQUEST_NUMBER_FORM_119',
  UPDATE_EXISTNG_FORM_119_REQUESTED = 'UPDATE_EXISTNG_FORM_119_REQUESTED',
  UPDATE_EXISTNG_FORM_119_SUCCEEDED = 'UPDATE_EXISTNG_FORM_119_SUCCEEDED',
  UPDATE_EXISTNG_FORM_119_FAILED = 'UPDATE_EXISTNG_FORM_119_FAILED',
  DELETE_FORM_119_REQUESTED = 'DELETE_FORM_119_REQUESTED',
  DELETE_FORM_119_SUCCEEDED = 'DELETE_FORM_119_SUCCEEDED',
  DELETE_FORM_119_FAILED = 'DELETE_FORM_119_FAILED',
  REFRESH_FORM_119_REQUESTED = 'REFRESH_FORM_119_REQUESTED',
  REFRESH_FORM_119_SUCCEEDED = 'REFRESH_FORM_119_SUCCEEDED',
  REFRESH_FORM_119_FAILED = 'REFRESH_FORM_119_FAILED',
  SAVE_FORM_119_REQUESTED = 'SAVE_FORM_119_REQUESTED',
  SAVE_FORM_119_SUCCEEDED = 'SAVE_FORM_119_SUCCEEDED',
  SAVE_FORM_119_FAILED = 'SAVE_FORM_119_FAILED',
  UPDATE_AND_SUBMIT_FORM_119_REQUESTED = 'UPDATE_AND_SUBMIT_FORM_119_REQUESTED',
  UPDATE_AND_SUBMIT_FORM_119_SUCCEEDED = 'UPDATE_AND_SUBMIT_FORM_119_SUCCEEDED',
  UPDATE_AND_SUBMIT_FORM_119_FAILED = 'UPDATE_AND_SUBMIT_FORM_119_FAILED',
  OVERRIDE_FORM_119_EXCEPTIONS = 'OVERRIDE_FORM_119_EXCEPTIONS',
  GET_FORM_119_REQUESTED = 'GET_FORM_119_REQUESTED',
  GET_FORM_119_SUCCEEDED = 'GET_FORM_119_SUCCEEDED',
  GET_FORM_119_FAILED = 'GET_FORM_119_FAILED',
  GET_RETRO_PAY_REQUESTED = 'GET_RETRO_PAY_REQUESTED',
  GET_RETRO_PAY_SUCCEEDED = 'GET_RETRO_PAY_SUCCEEDED',
  GET_RETRO_PAY_FAILED = 'GET_RETRO_PAY_FAILED',
  UNLOCK_FORM_119 = 'UNLOCK_FORM_119',
  CLEAR_119_WITHOUT_UNLOCK = 'CLEAR_119_WITHOUT_UNLOCK',
  RESET_FORM_119_STATUS = 'RESET_FORM_119_STATUS',
  RESET_FORM_119_SUBMIT_STATUS = 'RESET_FORM_119_SUBMIT_STATUS',
  REMOVE_FORM_119_EXCEPTION_OVERRIDES = 'REMOVE_FORM_119_EXCEPTION_OVERRIDES',
  SET_FORM_119_EXCEPTION_OVERRIDES = 'SET_FORM_119_EXCEPTION_OVERRIDES',
  SET_FORM_119_SUBMIT_STATUS = 'SET_FORM_119_SUBMIT_STATUS',
  FETCH_LATEST_119_REQUESTED = 'FETCH_LATEST_119_REQUESTED',
  FETCH_LATEST_119_FAILED = 'FETCH_LATEST_119_FAILED',
  FETCH_LATEST_119_SUCCEEDED = 'FETCH_LATEST_119_SUCCEEDED',
  SAVE_119_AND_PERFORM_ACTION_REQUESTED = 'SAVE_119_AND_PERFORM_ACTION_REQUESTED',
  SAVE_119_AND_PERFORM_ACTION_SUCCEEDED = 'SAVE_119_AND_PERFORM_ACTION_SUCCEEDED',
  SAVE_119_AND_PERFORM_ACTION_FAILED = 'SAVE_119_AND_PERFORM_ACTION_FAILED',
  UPDATE_SELECTED_RECONCILIATION_YEAR = 'UPDATE_SELECTED_RECONCILIATION_YEAR',
  GET_RECONCILIATON_YEARS_REQUESTED = 'GET_RECONCILIATON_YEARS_REQUESTED',
  GET_RECONCILIATON_YEARS_SUCCEEDED = 'GET_RECONCILIATON_YEARS_SUCCEEDED',
  RESET_RECONCILIATON_YEARS = 'RESET_RECONCILIATON_YEARS',
  CHECK_MEMBER_PROFILE_VALIDITY_119_REQUESTED = 'CHECK_MEMBER_PROFILE_VALIDITY_119_REQUESTED',
  CHECK_MEMBER_PROFILE_VALIDITY_119_SUCCEEDED = 'CHECK_MEMBER_PROFILE_VALIDITY_119_SUCCEEDED',
  CHECK_MEMBER_PROFILE_VALIDITY_119_FAILED = 'CHECK_MEMBER_PROFILE_VALIDITY_119_FAILED',
  VALID_MEMBER_RESET_119 = 'VALID_MEMBER_RESET_119',
  SET_VALID_EMAIL_ADDRESS_FORM_119 = 'SET_VALID_EMAIL_ADDRESS_FORM_119',
  SET_VALID_EMAIL_ADDRESS_STATE_FORM_119 = 'SET_VALID_EMAIL_ADDRESS_STATE_FORM_119',
  REMOVE_11998_OR_11999_WARNING = 'REMOVE_11998_OR_11999_WARNING'
}

export const saveForm119InStore: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.SAVE_FORM_119_IN_STORE,
  data
});

export const createForm119Action: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form119ActionTypes.CREATE_FORM_119,
  data
});

export const saveExistingForm119Action: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form119ActionTypes.UPDATE_EXISTNG_FORM_119_REQUESTED,
  data
});

export const getForm119: ActionCreator<EapAction> = (data: {
  requestNo: string;
  openFormToReview?: boolean;
}) => ({
  type: form119ActionTypes.GET_FORM_119_REQUESTED,
  data
});

export const saveForm119: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.SAVE_FORM_119_REQUESTED,
  data
});

export const updateAndSubmitForm119Action: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form119ActionTypes.UPDATE_AND_SUBMIT_FORM_119_REQUESTED,
  data
});

export const setForm119StepAction: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.SET_FORM_119_STEP,
  data
});

export const setForm119NotNewFormAction: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.SET_FORM_119_NOT_NEW_FORM
});

export const clearForm119: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.CLEAR_FORM_119,
  data
});

export const overrideForm119ExceptionsAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form119ActionTypes.OVERRIDE_FORM_119_EXCEPTIONS,
  data
});

export const setForm119RequestNumber: ActionCreator<EapAction> = (
  data: string | null
) => ({
  type: form119ActionTypes.SET_REQUEST_NUMBER_FORM_119,
  data
});

export const setForm119MemberInfo: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.SET_MEMBER_INFO_FORM_119,
  data
});

export const deleteForm119Action: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.DELETE_FORM_119_REQUESTED,
  data
});

export const refreshForm119Action: ActionCreator<EapAction> = (data: {
  create119Data: {
    membershipNo: string;
    employerNo: string;
    employeeModel: any;
    selectedReconciliationYear: string;
  };
  deleteAction: EapAction;
}) => ({ type: form119ActionTypes.REFRESH_FORM_119_REQUESTED, data });

export const setForm119StatusAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form119ActionTypes.SET_FORM_119_STATUS,
  data
});

export const unlockForm119: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.UNLOCK_FORM_119,
  data
});

export const clear119WithoutUnlock: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.CLEAR_119_WITHOUT_UNLOCK
});

export const setForm119SubmitStatusAction: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.SET_FORM_119_SUBMIT_STATUS
});

export const resetForm119Status: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.RESET_FORM_119_STATUS
});

export const clearForm119ExceptionOverrides: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.REMOVE_FORM_119_EXCEPTION_OVERRIDES
});

export const setForm119ExceptionOverrides: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.SET_FORM_119_EXCEPTION_OVERRIDES
});

export const setRetroPay: ActionCreator<EapAction> = (data: {
  index: number;
  field: any;
  value: any;
}) => ({
  type: form119ActionTypes.SET_RETRO_PAY_IN_STORE,
  data
});

export const getRetroPay: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  retroServicePeriodNo: number;
}) => ({
  type: form119ActionTypes.GET_RETRO_PAY_REQUESTED,
  data
});

export const addToRetroPayInStore: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form119ActionTypes.ADD_TO_RETRO_PAY_IN_STORE,
  data
});

export const removeFromRetroPayInStore: ActionCreator<EapAction> = (
  index: number
) => ({
  type: form119ActionTypes.REMOVE_FROM_RETRO_PAY_IN_STORE,
  data: index
});

export const setSelectedRetroType: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form119ActionTypes.SET_SELECTED_RETRO_TYPE,
  data
});

export const updateRetroPay: ActionCreator<EapAction> = (data: {
  payload: any;
  retroServicePeriodNo: number;
}) => ({
  type: form119ActionTypes.UPDATE_RETRO_PAY_REQUESTED,
  data
});

export const clearRetroPay: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.CLEAR_RETRO_PAY_IN_STORE
});

export const deleteRetroPay: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.DELETE_RETRO_PAY_REQUESTED,
  data
});

export const clearRetroPayModels: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.CLEAR_RETRO_PAY_MODELS
});

export const setCurrentRetroIndex: ActionCreator<EapAction> = (
  currentRetroIndex: number
) => ({
  type: form119ActionTypes.SET_CURRENT_RETRO_INDEX,
  data: currentRetroIndex
});

export const setRetroStartDateInStore: ActionCreator<EapAction> = (
  retroStartDate: string
) => ({
  type: form119ActionTypes.SET_RETRO_START_DATE,
  data: retroStartDate
});

export const setRetroEndDateInStore: ActionCreator<EapAction> = (
  retroEndDate: string
) => ({
  type: form119ActionTypes.SET_RETRO_END_DATE,
  data: retroEndDate
});

export const showRetroModalTableAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: form119ActionTypes.SHOW_RETRO_MODAL_TABLE,
  data
});

export const resetToOriginalOnRefresh: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.RESET_TO_ORIGINAL_DATA_ON_REFRESH
});

export const saveForm119AndPerformNewAction: ActionCreator<EapAction> = (data: {
  actionType: string;
  actionData?: any;
}) => ({
  type: form119ActionTypes.SAVE_119_AND_PERFORM_ACTION_REQUESTED,
  data
});

export const getReconciliationYears: ActionCreator<EapAction> = (data: {
  actionType: string;
  actionData: any;
}) => ({
  type: form119ActionTypes.GET_RECONCILIATON_YEARS_REQUESTED,
  data
});

export const clearReconciliationYears: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.RESET_RECONCILIATON_YEARS
});

export const updateSelectedReconciliationYearAction: ActionCreator<EapAction> = (data: {
  actionType: string;
  actionData?: any;
}) => ({
  type: form119ActionTypes.UPDATE_SELECTED_RECONCILIATION_YEAR,
  data
});

export const checkMemberProfileValidity: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form119ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_119_REQUESTED,
  data
});

export const memberProfileValid: ActionCreator<EapAction> = (data: any) => ({
  type: form119ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_119_SUCCEEDED,
  data
});

export const memberProfileInvalid: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_119_FAILED
});

export const resetValidMember: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.VALID_MEMBER_RESET_119
});

export const setValidEmailAddressForm119: ActionCreator<EapAction> = (data: {
  email: string;
}) => ({
  type: form119ActionTypes.SET_VALID_EMAIL_ADDRESS_FORM_119,
  data
});

export const setValidEmailAddressStateForm119: ActionCreator<EapAction> = (
  data: QASEmailResponseTypes
) => ({
  type: form119ActionTypes.SET_VALID_EMAIL_ADDRESS_STATE_FORM_119,
  data
});

export const removeHas11998Or11999Warnings: ActionCreator<EapAction> = () => ({
  type: form119ActionTypes.REMOVE_11998_OR_11999_WARNING
});
