import {
  ContentfulClientApi,
  createClient,
  Entry,
  EntryCollection,
  EntrySkeletonType,
  FieldsType
} from 'contentful';
import { env } from '@omers-packages/next-isomorphic-runtime-env';

let client: ContentfulClientApi<undefined>;

export function getClient() {
  if (!client) {
    client = createClient({
      space: env('NEXT_PUBLIC_CONTENTFUL_SPACE_ID') as string,
      accessToken: env('NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN') as string,
      environment: env('NEXT_PUBLIC_CONTENTFUL_ENV') as string,
      host: env('NEXT_PUBLIC_CONTENTFUL_HOST') as string
    });
  }

  return client;
}

export const contentful = {
  entries: {
    async get(query: Record<string, string>) {
      const queryParams = new URLSearchParams(query);
      const response = await fetch(`/api/content/entries?${queryParams}`);
      return response
        .json()
        .then(
          data =>
            data as EntryCollection<
              EntrySkeletonType<FieldsType, string>,
              undefined,
              string
            >
        );
    }
  },
  entry: {
    async get(entryId: string) {
      const entryRes = await fetch(`/api/content/entries/${entryId}`);
      return entryRes
        .json()
        .then(
          data =>
            data as Entry<
              EntrySkeletonType<FieldsType, string>,
              undefined,
              string
            >
        );
    }
  }
};
