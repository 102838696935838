import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { ApiErrorObject } from 'interfaces';
import { postData, makePingFederateCall, getData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');
const NEXT_PUBLIC_PING_AUTH_BASE_URL = env('NEXT_PUBLIC_PING_AUTH_BASE_URL');

export const login = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/auth/login`, data);

export const logout = () =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/auth/logout`);

export const selectEmployer = (groupNumber: string) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/auth/employer/${groupNumber}`);

export const resetPassword = (payload: any) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/auth/password-reset`, payload);

export const updatePassword = (payload: any) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/auth/expired-password-change`,
    payload
  );

export const getFlowId = (payload: DataObj) =>
  makePingFederateCall(
    `${NEXT_PUBLIC_PING_AUTH_BASE_URL}/as/authorization.oauth2?${payload.toString()}`,
    'get'
  );

export const getAuthorizationCode = (
  payload: DataObj,
  pingFedFlowId: string,
  optionalHeaders: DataObj
) =>
  makePingFederateCall(
    `${NEXT_PUBLIC_PING_AUTH_BASE_URL}/pf-ws/authn/flows/${pingFedFlowId}`,
    'post',
    optionalHeaders,
    payload
  );

export type SessionRefreshSuccess = {
  data: {
    idleTimeout: string;
    lastActivityTime: string;
  };
};
export type SessionRefreshError = {
  errors: ApiErrorObject[];
};

export type SessionRefreshResponse =
  | SessionRefreshError
  | SessionRefreshSuccess;

export const refreshSession = (): Promise<SessionRefreshResponse> =>
  getData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/auth/session-extend`);
