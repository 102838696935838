import { ActionCreator } from 'redux';

import { EapAction } from 'types';

export enum batchPrintActionTypes {
  GET_BATCH_PRINT_SEARCH_RESULTS_REQUESTED = 'GET_BATCH_PRINT_SEARCH_RESULTS_REQUESTED',
  GET_BATCH_PRINT_SEARCH_RESULTS_SUCCESS = 'GET_BATCH_PRINT_SEARCH_RESULTS_SUCCESS',
  GET_BATCH_PRINT_SEARCH_RESULTS_FAILED = 'GET_BATCH_PRINT_SEARCH_RESULTS_FAILED',
  SET_FILTER_IS_DIRTY = 'SET_FILTER_IS_DIRTY',
  RESET_SEARCH_FILTER = 'RESET_SEARCH_FILTER',
  SET_IS_NEW_FILTER_CRITERIA = 'SET_IS_NEW_FILTER_CRITERIA',
  PRINT_SELECTED_REQUESTED = 'PRINT_SELECTED_REQUESTED',
  PRINT_SELECTED_REQUESTED_SUCCESS = 'PRINT_SELECTED_REQUESTED_SUCCESS',
  PRINT_SELECTED_REQUESTED_FAILED = 'PRINT_SELECTED_REQUESTED_FAILED'
}

export const getBatchPrintSearchResultsAction: ActionCreator<EapAction> = (data: {
  payload: {
    employerNumber: number;
    NRA: string;
    employmentStatus: string;
    owner: string;
    fromName: string;
    toName: string;
    fromDate: string;
    toDate: string;
    printTypeIndicator: 'E' | 'S';
    searchAscending: boolean;
    sortByLastName: boolean;
    sortByEmployeeId: boolean;
    sortByDeptId: boolean;
    startIndex: number;
    rowLimit: number;
  };
  newFilterCriteria: boolean;
}) => ({
  type: batchPrintActionTypes.GET_BATCH_PRINT_SEARCH_RESULTS_REQUESTED,
  data
});

export const getBatchPrintSearchResultsSuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: batchPrintActionTypes.GET_BATCH_PRINT_SEARCH_RESULTS_SUCCESS,
  data
});

export const getBatchPrintSearchResultsFailedAction: ActionCreator<EapAction> = () => ({
  type: batchPrintActionTypes.GET_BATCH_PRINT_SEARCH_RESULTS_FAILED
});

export const setFilterDirtyStateAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchPrintActionTypes.SET_FILTER_IS_DIRTY,
  data
});

export const resetSearchFilter: ActionCreator<EapAction> = () => ({
  type: batchPrintActionTypes.RESET_SEARCH_FILTER
});

export const setIsNewFilterCriteriaStateAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: batchPrintActionTypes.SET_IS_NEW_FILTER_CRITERIA,
  data
});

export const printSelectedRequestAction: ActionCreator<EapAction> = (data: {
  printTypeIndicator: 'E' | 'S';
  requestIds: Array<number>;
}) => ({
  type: batchPrintActionTypes.PRINT_SELECTED_REQUESTED,
  data
});

export const printSelectedRequestSuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: batchPrintActionTypes.PRINT_SELECTED_REQUESTED_SUCCESS,
  data
});

export const printSelectedRequestFailedAction: ActionCreator<EapAction> = () => ({
  type: batchPrintActionTypes.PRINT_SELECTED_REQUESTED_FAILED
});
