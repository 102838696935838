import {
  all,
  takeLatest,
  takeLeading,
  select,
  call,
  put
} from 'redux-saga/effects';
import Router from 'next/router';

import { RootState, EapAction } from 'types';
import {
  AnrNotReconciled119,
  ExceptionsHashmapObject,
  ExceptionsObject
} from 'interfaces';
import { getApiErrorMessage } from 'utils';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { unlockFormSaga } from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  getAnrInfo,
  getAnrDashboardInfo,
  getAnrExceptions,
  getAnrExceptionsByErrorID,
  getAnrNotInitiated,
  setAnrOverride119,
  requestsSearchDrilldownCount,
  getAnrContributions,
  generateAnnualPensionReport,
  submitAnrRequest
} from 'services';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import { updateAnrUpdatedTimestampAction } from 'containers/annualDashboard/annualDashboardActions';
import {
  annualReconciliationActionTypes,
  getAnrInfoSuccessAction,
  getAnrUnresolvedExceptionsSuccessAction,
  getAnrUnresolvedExceptionsFailedAction,
  getAnrActiveUnresolvedExceptionsSuccessAction,
  getAnrActiveUnresolvedExceptionsFailedAction,
  overrideActiveUnresolvedExceptionsSuccessAction,
  overrideActiveUnresolvedExceptionsFailedAction,
  overrideActiveUnresolvedExceptionsClearAction,
  getAnrNotInitiatedSuccessAction,
  getAnrNotInitiatedFailedAction,
  getAnrContributionsSuccessAction,
  getAnrContributionsFailedAction,
  requestAnnualPensionReportGenerationAction,
  overrideExceptionsSuccessAction,
  overrideExceptionsFailedAction
} from './annualReconciliationActions';

export function* getAnrInfoSaga(action: EapAction): any {
  yield put(startLoadingAction());

  const { selectGroupNumber, selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { activeYear } = yield select(
    (state: RootState) => state.annualReconciliation
  );

  // retrieve reconciliation information and the drilldown request search counts for specific views
  const [
    anrInfoRes,
    outstandingRequestsDrilldownDrilldownRes,
    openInactive119DrilldownRes,
    open119AdjustingDrilldownRes,
    open143DrilldownRes
  ]: [any, any, any, any, any] = yield all([
    call(safeAuthEffect, getAnrDashboardInfo, selectGroupNumber),
    call(safeAuthEffect, requestsSearchDrilldownCount, {
      requestType: 119,
      employerNumber: selectEmployerNumber,
      singleOrAllMemberSearch: 'A',
      status: 1,
      reconciliationYear: activeYear,
      icAdjustmentInd: 'N',
      idDrillDownInd: 'Y'
    }),
    call(safeAuthEffect, requestsSearchDrilldownCount, {
      requestType: 119,
      employerNumber: selectEmployerNumber,
      singleOrAllMemberSearch: 'A',
      status: 1,
      membershipSubstatus: 'I',
      reconciliationYear: activeYear,
      icAdjustmentInd: 'N',
      idDrillDownInd: 'Y'
    }),
    call(safeAuthEffect, requestsSearchDrilldownCount, {
      requestType: 119,
      employerNumber: selectEmployerNumber,
      singleOrAllMemberSearch: 'A',
      status: 1,
      reconciliationYear: activeYear,
      icAdjustmentInd: 'Y',
      idDrillDownInd: 'Y'
    }),
    call(safeAuthEffect, requestsSearchDrilldownCount, {
      requestType: 143,
      employerNumber: selectEmployerNumber,
      singleOrAllMemberSearch: 'A',
      status: 1,
      reconciliationYear: activeYear,
      icAdjustmentInd: 'N',
      idDrillDownInd: 'Y'
    })
  ]);

  const requestNumber =
    anrInfoRes?.data?.dashboardModel?.notReconciled119?.find(
      (item: AnrNotReconciled119) =>
        item.reconciliationYear.toString() === action.data
    )?.requestNo ?? null;

  // fetch ANR info if valid request number and no lock errors, or if active user had locked it
  const res = yield call(safeAuthEffect, getAnrInfo, requestNumber);

  if (res.data) {
    yield put(
      getAnrInfoSuccessAction({
        requestANR: res.data?.anrModel?.anrRequest,
        contributions: res.data?.anrModel?.contributions,
        exceptions: res.data?.anrModel?.exceptions,
        totalsTabDrilldownCounts: {
          outstandingRequestsDrilldown:
            outstandingRequestsDrilldownDrilldownRes.data
              ?.drillDownTotalRowCount ?? 0
        },
        otherTabDrilldownCounts: {
          openInactive119Drilldown:
            openInactive119DrilldownRes.data?.drillDownTotalRowCount ?? 0,
          open119AdjustingDrilldown:
            open119AdjustingDrilldownRes.data?.drillDownTotalRowCount ?? 0,
          open143Drilldown:
            open143DrilldownRes.data?.drillDownTotalRowCount ?? 0
        }
      })
    );

    yield put(
      updateAnrUpdatedTimestampAction({
        timestamp: res.data.anrModel.anrRequest?.timeStampString ?? null,
        refreshRequestEmployer: selectGroupNumber
      })
    );

    yield put(clearNotifications());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* getAnrUnresolvedExceptionsSaga(action: EapAction): any {
  const { selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { activeYear } = yield select(
    (state: RootState) => state.annualReconciliation
  );

  const res = yield call(safeAuthEffect, getAnrExceptions, {
    employerNumber: selectEmployerNumber,
    reconciliationYear: activeYear,
    startIndex: action.data.startIndex,
    rowLimit: action.data.rowLimit
  });

  if (res.data) {
    yield put(
      getAnrUnresolvedExceptionsSuccessAction(res.data.requestSearchResult)
    );
    yield put(clearNotifications());
  } else {
    yield put(getAnrUnresolvedExceptionsFailedAction());

    // filter out 'No requests' error as the empty state of the table is
    // already managed by the table being empty with a message to the user
    const errors = res.errors.filter(
      (err: ExceptionsHashmapObject) => err.code !== '318'
    );
    if (errors.length > 0) {
      yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    }
  }
}

export function* getAnrActiveUnresolvedExceptionsSaga(action: EapAction): any {
  const { selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { activeYear } = yield select(
    (state: RootState) => state.annualReconciliation
  );

  const res = yield call(safeAuthEffect, getAnrExceptionsByErrorID, {
    employerNumber: selectEmployerNumber,
    reconciliationYear: activeYear,
    exceptionEnvironmentId: action.data.environmentId,
    exceptionMessageId: action.data.messageId,
    startIndex: action.data.startIndex,
    rowLimit: action.data.rowLimit
  });

  if (res.data) {
    yield put(
      getAnrActiveUnresolvedExceptionsSuccessAction({
        ...res.data.requestSearchResult,
        overrideReasons: res.data.overrideReasons
      })
    );
    yield put(clearNotifications());
  } else {
    yield put(getAnrActiveUnresolvedExceptionsFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* submitAnrRequestSaga(action: EapAction): any {
  const { requestANR, exceptions, overrideExceptions } = yield select(
    (state: RootState) => state.annualReconciliation
  );

  yield put(startLoadingAction());

  if (exceptions.length > 0) {
    yield all(
      overrideExceptions.map(function*(exception: ExceptionsObject) {
        yield setAnrOverrideExceptionsSaga(requestANR.requestNo, exception);
      })
    );
  }

  const res = yield call(safeAuthEffect, submitAnrRequest, action.data);

  if (res.data) {
    yield call(Router.push, '/annual-dashboard');
    yield put(
      requestAnnualPensionReportGenerationAction({ requestNo: action.data })
    );
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* getAnrNotInitiatedSaga(): any {
  yield put(clearNotifications());

  const { selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const { activeYear } = yield select(
    (state: RootState) => state.annualReconciliation
  );

  const res = yield call(safeAuthEffect, getAnrNotInitiated, {
    employerNumber: selectEmployerNumber,
    reconciliationYear: activeYear
    // TODO: Enable when API supports pagination
    // startIndex: action.data.startIndex,
    // rowLimit: action.data.rowLimit
  });

  if (res.data) {
    yield put(
      getAnrNotInitiatedSuccessAction({
        requests: res.data.membershipSearchResult
        // totalRowCount: res.data.??? // TODO: Enable when API supports pagination
      })
    );
  } else {
    yield put(getAnrNotInitiatedFailedAction());
  }
}

export function* setAnrOverride119Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(setAnrOverride119, {
    requestNo: action.data.requests.join(','),
    overrideReasonCode: action.data.overrideReason,
    overrideReasonText: action.data.overrideExplanation
      ? action.data.overrideExplanation
      : null,
    exceptionMessageId: action.data.messageId
  });

  if (res.data) {
    yield put(overrideActiveUnresolvedExceptionsSuccessAction());
    yield put(overrideActiveUnresolvedExceptionsClearAction());
    yield put(clearNotifications());
  } else {
    yield put(overrideActiveUnresolvedExceptionsFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* setAnrOverrideExceptionsSaga(
  requestNo: string,
  overridenException: any
): any {
  yield put(startLoadingAction());

  const res = yield call(setAnrOverride119, {
    requestNo,
    overrideReasonCode: overridenException.overrideReasonCode,
    overrideReasonText: overridenException.overrideReasonOtherText
      ? overridenException.overrideReasonOtherText
      : null,
    exceptionMessageId: overridenException.messageid
  });

  if (res.data) {
    yield put(overrideExceptionsSuccessAction());
    yield put(clearNotifications());
  } else {
    yield put(overrideExceptionsFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* getAnrContributionsSaga(action: EapAction): any {
  const { type, view, path } = action.data;

  const res = yield call(safeAuthEffect, getAnrContributions, path);

  if (res.data) {
    yield put(
      getAnrContributionsSuccessAction({
        type,
        view,
        data: res.data
      })
    );
    yield put(clearNotifications());
  } else {
    yield put(getAnrContributionsFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* generateAnnualPensionReportSaga(action: EapAction) {
  const { requestNo } = action.data;
  yield call(safeAuthEffect, generateAnnualPensionReport, requestNo);
}

export function* annualReconciliationSaga(): any {
  yield all([
    yield takeLatest(
      annualReconciliationActionTypes.GET_ANR_INFO_REQUESTED,
      getAnrInfoSaga
    ),
    yield takeLatest(
      annualReconciliationActionTypes.GET_ANR_UNRESOLVED_EXCEPTIONS_REQUESTED,
      getAnrUnresolvedExceptionsSaga
    ),
    yield takeLatest(
      annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED,
      getAnrActiveUnresolvedExceptionsSaga
    ),
    yield takeLatest(
      annualReconciliationActionTypes.GET_ANR_NOT_INITIATED_REQUESTED,
      getAnrNotInitiatedSaga
    ),
    yield takeLatest(
      annualReconciliationActionTypes.SUBMIT_ANR_REQUEST,
      submitAnrRequestSaga
    ),
    yield takeLeading(
      annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED,
      setAnrOverride119Saga
    ),
    yield takeLatest(
      annualReconciliationActionTypes.GET_ANR_CONTRIBUTIONS_REQUESTED,
      getAnrContributionsSaga
    ),
    yield takeLatest(
      annualReconciliationActionTypes.REQUEST_ANNUAL_PENSION_REPORT_GENERATION,
      generateAnnualPensionReportSaga
    ),
    yield takeLeading(
      annualReconciliationActionTypes.UNLOCK_ANR_REQUESTED,
      unlockFormSaga
    )
  ]);
}
