import classNames from 'classnames/bind';

import styles from './Divider.module.scss';

const cx = classNames.bind(styles);

interface DividerProps {
  vertical?: boolean;
}
const Divider = ({ vertical = false }: DividerProps) => (
  <hr
    className={cx({
      divider: true,
      vertical
    })}
  />
);

export default Divider;
