import { InfoPanelType } from 'containers/stewardshipReport/esrSaga';
import { format } from 'date-fns';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData } from './services';

interface ReportData {
  id: InfoPanelType;
  value: number;
}

export interface SucceededStewardshipReportResponse {
  data: {
    esrModel: {
      totalMembers: number;
      cftMembers: number;
      otcftMembers: number;
      myomersUsers: number;
      paperlessMembers: number;
      updateTimestamp: string;
      otherStats: ReportData[];
    };
  };
}

export interface FailedStewardshipReportResponse {
  errors: {
    code?: string;
    msg?: string;
    trace?: string;
    errorId?: string;
    parameters?: string;
  }[];
}

export interface EmptyStewardshipReportResponse {
  data: {};
}

export type StewardshipReportResponse =
  | SucceededStewardshipReportResponse
  | FailedStewardshipReportResponse
  | EmptyStewardshipReportResponse;

export const getStewardshipReport = (
  groupNumber: string | number,
  startDate: Date,
  endDate: Date
): Promise<StewardshipReportResponse> => {
  const stringifiedStartDate = encodeURIComponent(format(startDate, 'y-MM-dd'));
  const stringifiedEndDate = encodeURIComponent(format(endDate, 'y-MM-dd'));

  return getData(
    `${env(
      'NEXT_PUBLIC_API_PROXY_URL'
    )}/api/v1/common/esr/${groupNumber}?startDate=${stringifiedStartDate}&endDate=${stringifiedEndDate}`
  );
};
