/* eslint-disable react/button-has-type */
import classNames from 'classnames/bind';
import Spinner from 'components/spinner/Spinner';
import styles from './Button.module.scss';

const cx = classNames.bind(styles);

interface ButtonProps {
  style?: string;
  children?: React.ReactNode | React.ReactNode[] | string;
  type?: 'submit' | 'reset' | 'button';
  isDisabled?: boolean;
  onClick?: any;
  baseStyle?:
    | ''
    | 'basic'
    | 'solidBlue'
    | 'outlineOrange'
    | 'outlineBlue'
    | 'outlineWhite'
    | 'link'
    | 'linkOrange';
  testingTag?: string;
  autoTestingTag?: string;
  isLoading?: boolean;
  disableSvgStroke?: boolean;
}

const Button = ({
  style,
  onClick = null,
  type = 'button',
  isDisabled = false,
  baseStyle = '',
  testingTag,
  autoTestingTag,
  children,
  isLoading,
  disableSvgStroke = false,
  ...props
}: ButtonProps) => (
  <button
    type={type}
    disabled={isDisabled || isLoading}
    onClick={onClick}
    data-testid={testingTag}
    data-auto={autoTestingTag}
    className={cx(
      {
        [baseStyle]: true,
        loadingSpinner: isLoading,
        disableSvgStroke,
        button: true
      },
      style && { [style]: true }
    )}
    {...props}
  >
    {isLoading && <Spinner isFullScreen={false} size="small" visible />}
    {!isLoading && children}
  </button>
);

export default Button;
