/* eslint-disable import/prefer-default-export */
import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
import { actionTypeObserverMiddleware } from '@omers-packages/redux-action-type-observer';
import {
  createStateSyncMiddleware,
  initStateWithPrevTab
} from 'redux-state-sync';

import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import clientSide from 'utils/logger/client-side';
import { RootState } from 'types';
import rootReducer from './reducers';
import rootSaga from './sagas/rootSaga';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: any;
  }
}

const persistConfig = {
  key: 'root',
  storage: localStorage,
  blacklist: [
    'form119',
    'updatePassword',
    'resetPassword',
    'esr',
    'toaster',
    'microsoftBookingsDrawer'
  ]
};

const isBrowserDevTools =
  typeof window !== 'undefined' &&
  !!window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

const persistedReducer = persistReducer<RootState>(persistConfig, rootReducer);

export const configureStore = () => {
  const composeEnhancers = isBrowserDevTools
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
  const sagaMiddleware = createSagaMiddleware({
    onError(error, errorInfo) {
      clientSide.error('An uncaught exception was thrown within a saga');
      clientSide.error(error.message);
      clientSide.error(errorInfo.sagaStack);
    }
  });
  const middleware = [
    sagaMiddleware,
    actionTypeObserverMiddleware,
    createStateSyncMiddleware({
      whitelist: [
        selectEmployersActionTypes.SELECT_EMPLOYER_DROPDOWN_REQUESTED
      ],
      broadcastChannelOption: { type: 'native' }
    })
  ];

  const store = createStore(
    persistedReducer,
    composeEnhancers(applyMiddleware(...middleware))
  );

  initStateWithPrevTab(store);
  const persistor = persistStore(store);
  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};
