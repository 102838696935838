import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData, putData, deleteData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getForm165a = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165a/${requestNumber}`,
    undefined,
    requestNumber
  );

export const getFrenchForm165aPDF = (requestNumber: string) =>
  getData(
    `/proxy/api/v1/common/pdf-onfly/${requestNumber}?formNo=165_ele`,
    undefined,
    requestNumber,
    'blob'
  );

export const createForm165a = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165a`, data);

export const submitForm165a = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165a/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const saveForm165a = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165a/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const createLeavePeriodForm165a = (
  data: DataObj,
  requestNumber: string
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165a/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const updateLeavePeriodForm165a = (
  data: DataObj,
  requestNumber: string,
  intervalNumber: number
) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165a/${requestNumber}/${intervalNumber}`,
    data,
    undefined,
    requestNumber
  );

export const deleteLeavePeriodForm165a = (
  requestNumber: string,
  intervalNumber: number
) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e165a/${requestNumber}/${intervalNumber}`,
    undefined,
    requestNumber
  );
