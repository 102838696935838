import { showErrorToast } from 'features/v2/toaster';
import { TFunction } from 'i18next';
import logger from './logger/client-side';

export const showTerms = (dispatch: any, t: TFunction) => {
  try {
    GLANCE.Cobrowse.Visitor.showTerms();
  } catch (e) {
    logger.warn('Error setting up Cobrowse session');
    dispatch(
      showErrorToast({
        message: t('COBROWSE_ERROR_TOAST')
      })
    );
  }
};
