import { useState } from 'react';
import { FormState, DeepMap } from 'react-hook-form-legacy';
import { NumericDictionary, pickBy } from 'lodash';
import { useEffectDeepCompare } from 'utils/hooks/useEffectDeepCompare';

/**
 * React hooks determines form dirty state based on react-hook-form active
 * dirty state of the form combined with ignored hidden values -- intent is to
 * treat hidden form values as non-relevant values towards form isDirty state
 */
const useDirtyState = <T>(
  formState: FormState<T>,
  hiddenValues: Array<string> = []
) => {
  const [dirtyFields, setDirtyFields] = useState<
    NumericDictionary<DeepMap<T, boolean>>
  >({});

  // filter out hidden values
  useEffectDeepCompare(() => {
    setDirtyFields(
      pickBy<DeepMap<T, boolean>>(
        formState.dirtyFields,
        (_, key) => !hiddenValues.includes(key)
      )
    );
  }, [
    {
      ...formState.dirtyFields
    },
    formState.isDirty
  ]);

  return {
    isDirty: formState.isDirty && Object.keys(dirtyFields).length > 0,
    dirtyFields
  };
};

export default useDirtyState;
