/**
 * Decoded JWT Ping Access Token
 */
export interface PingAccessToken {
  scope: string;
  // eslint-disable-next-line camelcase
  authorization_details: Array<unknown>;
  // eslint-disable-next-line camelcase
  client_id: string;
  iss: string;
  sub: string;
  roles: string;
  'pi.sri': string;
  userType: 'External' | 'Internal';
  ismemberof: Array<string>;
  exp: number;
}

/**
 * Ping Authn PI Flow status codes
 * Note: Cannot find these _anywhere_ in the Ping API docs, but they are returned in the response for given scenarios
 */
export enum PingPIFlowAuthnStatus {
  AUTHENTICATION_REQUIRED = 'AUTHENTICATION_REQUIRED',
  COMPLETED = 'COMPLETED',
  DEVICE_PROFILE_REQUIRED = 'DEVICE_PROFILE_REQUIRED',
  MUST_CHANGE_PASSWORD = 'MUST_CHANGE_PASSWORD',
  OTP_REQUIRED = 'OTP_REQUIRED',
  USERNAME_PASSWORD_REQUIRED = 'USERNAME_PASSWORD_REQUIRED'
}

/**
 * Ping Authn Credential Errors
 * Note: These values are NOT from Ping.
 * See errorCodes for user friendly messages for each code.
 */
export enum PingCredentialErrors {
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  DISABLED_USER = 'DISABLED_USER',
  MUST_CHANGE_PASSWORD = 'MUST_CHANGE_PASSWORD',
  ACCESS_DENIED = 'ACCESS_DENIED'
}

/**
 * Ping Credential Validation Error User Message
 * Note: Do NOT modify the following enum values in order to change the message sent to the client.
 * Ping does not return a unique error code for the credential validation errors.
 * The user messages are used to check credential errors returned from Ping
 */
export enum PingCredentialErrorUserMessage {
  INVALID_CREDENTIALS = "We didn't recognize the username or password you entered. Please try again.",
  ACCOUNT_LOCKED = 'Your account is locked',
  DISABLED_USER = 'Your account is disabled'
}

/**
 * Ping Session Management status codes
 * Note: Cannot find these _anywhere_ in the Ping API docs, but they are returned in the response for given session management calls
 */
export enum PingSessionMgmtStatus {
  HAS_VALID_SESSIONS = 'HAS_VALID_SESSIONS',
  NO_VALID_SESSIONS = 'NO_VALID_SESSIONS',
  SESSION_REVOKED = 'SESSION_REVOKED'
}

type PingLink = {
  href: string;
};

/**
 * PingErrorDetails come from Ping
 * Note: In general, the code and message can be very generic.
 */
export type PingErrorDetails = {
  code: string;
  message: string;
  userMessage: string;
};

export type PingError = {
  code: string;
  message: string;
  userMessage?: string;
  details?: Array<PingErrorDetails>;
};

export interface PingPIFlowAuthnBase {
  id: string;
  pluginTypeId: string;
  status: PingPIFlowAuthnStatus;
  _links: {
    self: PingLink;
  };
}

export interface PingPIFlowAuthnInitResponse extends PingPIFlowAuthnBase {
  rememberMyUsernameSelected: boolean;
  showCaptcha: boolean;
  showRememberMyUsername: boolean;
  showThisIsMyDevice: boolean;
  thisIsMyDeviceSelected: boolean;
  _links: {
    checkUsernamePassword: PingLink;
    initiateAccountRecovery: PingLink;
    initiatePasswordChange: PingLink;
    initiateRegistration: PingLink;
    recoverUsername: PingLink;
    self: PingLink;
  };
}

export interface PingPIFlowAuthnLoginResponse extends PingPIFlowAuthnBase {
  authorizeResponse: {
    code: string;
    state: string;
  };
  user: {
    id: string;
    username: string;
  };
}

export interface PingAuthTokenResponse {
  /* eslint-disable-next-line camelcase */
  access_token: string;
  /* eslint-disable-next-line camelcase */
  token_type: string;
  /* eslint-disable-next-line camelcase */
  expires_in: number;
}

export interface PingSessionActivityResponse {
  sri: string;
  /* eslint-disable-next-line camelcase */
  status: PingSessionMgmtStatus;
  lastActivityTime?: string;
  authnSessions?: Array<{
    authnSource?: Record<string, unknown>;
    id?: string;
    creationTime?: string;
    idleTimeout?: string;
    maxTimeout?: string;
  }>;
}

export interface PingErrorResponse {
  error: string;
  /* eslint-disable-next-line camelcase */
  error_description: string;
}

export interface JWTUser {
  bffAuthenticated: boolean;
  currentEmployerNo: number;
  email: string;
  employeeType: string;
  firstName: string;
  groups: Array<{
    attestationDueDate: string | null;
    attestationDueDays: number | null;
    attestationRequired: boolean;
    employerNumber: number;
    groupNumber: string;
    name: string;
    role: number;
  }>;
  lastLoginDate: string;
  lastName: string;
  omersUser: boolean;
  training: boolean;
  username: string;
}

/**
 * User Profile response model retrieved from e-access api intended for Ping login workflow
 */
export interface EaccessUserProfileResponse {
  data: {
    JwtUser: JWTUser;
  };
}

/**
 * User Profile error response model retrieved from e-access api intended for Ping login workflow
 */
export interface EaccessUserProfileErrorResponse {
  errors: Array<{
    msg: string;
    errorId: string;
    code: string;
  }>;
}
