/**
 * @todo: Rewrite as a proper Typescript Functional Component
 */
import { Component } from 'react';
import { connect } from 'react-redux';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { systemErrorNotificationAction } from 'containers/notifications/notificationsActions';
import Notification from 'components/notification/Notification';

// @ts-ignore
const mapStateToProps = state => ({
  notifications: state.notifications
});

const mapDispatchToProps = { systemErrorNotificationAction };

const connector = connect(mapStateToProps, mapDispatchToProps);

class ErrorBoundary extends Component {
  // @ts-ignore
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // @ts-ignore
  componentDidCatch(error) {
    if (env('NEXT_PUBLIC_APP_ENV') !== 'prod') {
      console.error(error);
    }
    // @ts-ignore
    this.props.systemErrorNotificationAction();
  }

  render() {
    // @ts-ignore
    const { hasError } = this.state;
    // @ts-ignore
    const { children } = this.props;
    if (
      hasError &&
      // @ts-ignore
      this.props?.notifications?.notification?.type &&
      // @ts-ignore
      this.props?.notifications?.notification?.message
    ) {
      return (
        <Notification
          // @ts-ignore
          type={this.props.notifications.notification.type}
          // @ts-ignore
          messages={[this.props.notifications.notification.message]}
          scrollTo
        />
      );
    }
    return children;
  }
}

export default connector(ErrorBoundary);
