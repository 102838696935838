import { ThemeUIStyleObject } from 'theme-ui';
import {
  InsertIcon,
  richTextOptions
} from 'components/v2/contentful/richText/richTextOptions';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';
import { isEmpty } from 'lodash';
import CardTitleDecoration from './cardTitleDecoration/CardTitleDecoration';

export type CardHeaderElement =
  | 'brandNavy100'
  | 'extendedGreen100'
  | 'grey80'
  | 'extendedBlue100'
  | 'extendedYellow100'
  | 'extendedRed100'
  | 'brandOrangeWeb';

const containerBorder = (
  accent: boolean,
  noPadding: boolean,
  accentColor: CardHeaderElement
) => ({
  '@media print ': {
    border: '0 !important' /* stylelint-disable-line declaration-no-important */
  },

  margin: 0,
  background: 'white',
  border: '1px solid',
  borderColor: 'grey80',
  borderTop: accent && ' 6px solid',
  borderTopColor: accent && accentColor ? accentColor : 'grey80',

  '.content': {
    padding: noPadding ? 0 : '2rem',
    fontFamily: 'body',
    '@media print': {
      padding: 0
    },
    '@media (max-width: 1110px)': {
      padding: noPadding ? 0 : '1.5rem'
    }
  },

  h2: {
    fontFamily: 'body'
  }
});

export interface CardProps {
  children?: React.ReactNode;
  accent?: boolean;
  noPadding?: boolean;
  accentColor?: CardHeaderElement;
  id: string;
  sxOverride?: ThemeUIStyleObject;
  title?: string;
  description?: Document;
  bannerCard?: boolean;
  hasTitleDecoration?: boolean;
  titleDecorationIcon?: InsertIcon;
  titleDecorationText?: string;
  backgroundColor?: 'white' | 'extendedBlue25';
}
const Card = ({
  children,
  accent = false,
  noPadding = false,
  accentColor = 'grey80',
  id,
  sxOverride,
  title,
  description,
  bannerCard = false,
  hasTitleDecoration,
  titleDecorationIcon,
  titleDecorationText,
  backgroundColor
}: CardProps) => {
  let headerElement = null;
  // Check for banner card indepedent since the normal behaviour of all banner cards so far
  // do not have any title decoration so we check it as an independent case.
  // Check for title decoration as it's own case and header tag is dependent on which title decoration
  if (bannerCard) {
    headerElement = (
      <h2 sx={{ fontSize: '28px', lineHeight: '42px' }}>{title}</h2>
    );
  } else if (hasTitleDecoration) {
    if (!isEmpty(titleDecorationIcon)) {
      headerElement = <h3>{title}</h3>;
    } else if (!isEmpty(titleDecorationText)) {
      headerElement = <h2>{title}</h2>;
    }
  } else {
    headerElement = <h2>{title}</h2>;
  }

  return (
    <div
      sx={{
        ...containerBorder(accent, noPadding, accentColor),
        backgroundColor,
        ...sxOverride
      }}
      data-testid={id}
      id={id}
    >
      <div
        className="content"
        sx={{ display: children || title || description ? 'block' : 'none' }}
      >
        {children || (
          <>
            <CardTitleDecoration
              hasTitleDecoration={hasTitleDecoration}
              titleDecorationIcon={titleDecorationIcon}
              titleDecorationText={titleDecorationText}
            />
            {headerElement}
            <p>
              {documentToReactComponents(
                description as Document,
                richTextOptions
              )}
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default Card;
