import { all, takeLatest, put, call } from 'redux-saga/effects';

import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { properties } from 'services';
import { getApiErrorMessage } from 'utils';
import { notificationActionTypes } from 'containers/notifications/notificationsActions';
import { propertiesActionTypes } from './propertiesActions';

export function* fetchProperties(): any {
  const res = yield call(safeAuthEffect, properties);
  if (res.data) {
    yield put({
      type: propertiesActionTypes.PROPERTIES_SUCCEEDED,
      data: res.data
    });
    yield put({
      type: notificationActionTypes.CLEAR_NOTIFICATION
    });
  } else {
    yield put({
      type: propertiesActionTypes.PROPERTIES_FAILED
    });
    yield put({
      type: notificationActionTypes.NOTIFY_ERROR,
      data: getApiErrorMessage(res.errors)
    });
  }
}

export function* fetchPropertiesSaga(): any {
  yield all([
    yield takeLatest(
      propertiesActionTypes.PROPERTIES_REQUESTED,
      fetchProperties
    )
  ]);
}
