import { useRouter } from 'next/router';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import { EXTERNAL_URLS, PUBLIC_SITE_URLS } from 'utils/constants';
import { useSelector } from 'react-redux';
import { RootState } from 'types';
import { useEmployerQuarterly } from 'containers/auth/LoginContainer/EmployerQuarterly';
import { currentFlags, isEnabled } from 'features/featureFlags/featureFlags';
import { useTranslation } from 'react-i18next';
import MainNav, { NavMenuItem } from '../mainNav/MainNav';
import { BurgerMenuItemType } from '../mainNav/BurgerMenu';

const PublicNavHeader = () => {
  const Router = useRouter();
  const { t } = useTranslation('header');
  const { isAuthenticated } = useSelector(
    (state: RootState) => state.userSession
  );
  const { slice } = useEmployerQuarterly();
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const isEmployerEducationWeekEnabled = isEnabled(
    featureFlags,
    currentFlags.CONTENTFUL_EMPLOYER_EDUCATION_WEEK
  );

  const showSignInButton =
    !isAuthenticated && Router.pathname.startsWith('/content/');

  const newsNavItem: NavMenuItem = {
    id: 'news_menuitem',
    buttonText: 'News',
    isDropdown: false,
    url: EXTERNAL_URLS.OMERS_NEWS,
    openInNewTab: true,
    icon: IconNamesSmall.SPEAKER,
    burgerMenuItemType: BurgerMenuItemType.LINK
  };

  const homeLink: NavMenuItem = {
    id: 'home-link-menuitem',
    buttonText: t('HOME'),
    isDropdown: false,
    burgerMenuItemType: BurgerMenuItemType.LINK,
    url: '/',
    icon: IconNamesSmall.HOME
  };

  const employerQuarterlyNavItem: NavMenuItem = {
    id: 'employer-quarterly_menuitem',
    buttonText: 'Employer Quarterly',
    isDropdown: false,
    openInNewTab: true,
    url: slice?.fields.ctaLink ?? '',
    icon: IconNamesSmall.DOCUMENT_COPY,
    burgerMenuItemType: BurgerMenuItemType.LINK
  };

  const publicTopLinks: NavMenuItem[] = slice?.fields.ctaLink
    ? [newsNavItem, employerQuarterlyNavItem]
    : [newsNavItem];

  const mainNavLinks: NavMenuItem[] = [
    {
      id: 'administering-the-plan_menuitem',
      buttonText: 'Administering the Plan',
      isDropdown: true,
      icon: IconNamesSmall.TODO,
      subMenuItems: [
        {
          id: 'offering-omers-membership_menuitem',
          name: 'Offering OMERS Membership',
          url: PUBLIC_SITE_URLS.OFFERING_OMERS_MEMBERSHIP
        },
        {
          id: 'supplemental-plan_menuitem',
          name: 'Supplemental Plan',
          url: PUBLIC_SITE_URLS.SUPPLEMENTAL_PLAN
        },
        {
          id: 'avc-payroll-deductions_menuitem',
          name: 'AVC Payroll Deductions',
          url: PUBLIC_SITE_URLS.AVC_PAYROLL_DEDUCTIONS
        }
      ]
    },
    {
      id: 'get-training_menuitem',
      buttonText: 'Get Training',
      isDropdown: false,
      icon: IconNamesSmall.PRESENTATION,
      url: PUBLIC_SITE_URLS.GET_TRAINING,
      burgerMenuItemType: BurgerMenuItemType.LINK
    },
    {
      id: 'resources_menuitem',
      buttonText: 'Resources',
      isDropdown: true,
      icon: IconNamesSmall.ROAD_SIGN_RIGHT,
      subMenuItems: [
        {
          id: 'employer-forms_menuitem',
          name: 'Employer Forms',
          url: PUBLIC_SITE_URLS.EMPLOYER_FORMS
        },
        {
          id: 'template-spreadsheets_menuitem',
          name: 'Template Spreadsheets',
          url: PUBLIC_SITE_URLS.TEMPLATE_SPREADSHEETS
        },
        {
          id: 'contributions-and-pa-calculators_menuitem',
          name: 'Contributions and PA Calculators',
          url: PUBLIC_SITE_URLS.CONTRIBUTIONS_AND_PA_CALCULATORS
        },
        {
          id: 'list-of-unions-and-associations_menuitem',
          name: 'List of Unions and Associations',
          url: PUBLIC_SITE_URLS.LIST_OF_UNIONS_AND_ASSOCIATIONS
        },
        {
          id: 'employer-administration-manual_menuitem',
          name: 'Employer Administration Manual',
          url: PUBLIC_SITE_URLS.EMPLOYER_ADMINISTRATION_MANUAL
        },
        {
          id: 'employer-toolkit_menuitem',
          name: 'Employer Toolkit',
          url: PUBLIC_SITE_URLS.EMPLOYER_TOOLKIT,
          hideItem: !isEnabled(
            featureFlags,
            currentFlags.CONTENTFUL_EMPLOYER_TOOLKIT
          )
        }
      ]
    },
    {
      id: 'request-forms_menuitem',
      buttonText: 'Request Forms',
      isDropdown: true,
      icon: IconNamesSmall.DOCUMENT_COPY,
      subMenuItems: [
        {
          id: 'employer-training_menuitem',
          name: 'Request Training',
          url: PUBLIC_SITE_URLS.REQUEST_TRAINING
        },
        {
          id: 'print-materials_menuitem',
          name: 'Print Materials',
          url: PUBLIC_SITE_URLS.REQUEST_FOR_PRINT_MATERIALS
        }
      ]
    },
    {
      id: 'employer-education-week_menuitem',
      buttonText: 'Employer Education Week',
      isDropdown: false,
      icon: IconNamesSmall.BOOK,
      url: PUBLIC_SITE_URLS.EMPLOYER_EDUCATION_WEEK,
      hideItem: !isEmployerEducationWeekEnabled,
      burgerMenuItemType: BurgerMenuItemType.LINK
    }
  ];

  return (
    <MainNav
      showSignInButton={showSignInButton}
      topLinks={publicTopLinks}
      menuItems={mainNavLinks}
      burgerMenuItems={[homeLink, ...mainNavLinks, ...publicTopLinks]}
    />
  );
};

export default PublicNavHeader;
