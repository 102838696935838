import { ExtractDataSend } from 'interfaces';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData, deleteData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getExtractDataType = (employerGroupNo: number) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/extract/type/${employerGroupNo}`
  );

export const getExtractStatus = (employerGroupNo: number) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/extract/${employerGroupNo}`
  );

export const setExtract = (data: ExtractDataSend) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/extract`, data);

export const downloadCsv = (batchProcessNumber: number) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/extract/download/${batchProcessNumber}`,
    undefined,
    undefined,
    'blob'
  );

export const cancelExtract = (batchProcessNumber: number) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/batch/extract/${batchProcessNumber}`
  );
