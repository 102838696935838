import { ThemeUIStyleObject } from 'theme-ui';

export const textEllipsisMixin: ThemeUIStyleObject = {
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap'
};

export const hidden: ThemeUIStyleObject = {
  visibility: 'hidden',
  opacity: 0,
  height: 0,
  width: 0,
  margin: 0,
  padding: 0
};
