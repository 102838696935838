import { EapAction } from 'types';
import {
  AnrRequest,
  AnrContributions,
  AnrUnresolvedExceptionRequest,
  AnrNotInitiatedRequest,
  ExceptionsObject,
  ExceptionsHashmapObject,
  AnrContributionDetails
} from 'interfaces';
import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import { annualReconciliationActionTypes } from './annualReconciliationActions';

interface AnnualReconciliationState {
  lockError: string | null;
  activeYear: string | null;
  activeTabs: {
    mainTab: 'totals' | 'others' | 'contributions' | 'pension-reports';
    totalsTab: 'not-submitted' | 'unresolved-exceptions' | 'not-initiated';
    otherTab: 'inactive-memberships' | 'adjustments' | 'form-143';
  };
  requestANR: AnrRequest;
  exceptions: Array<ExceptionsObject>;
  overrideExceptions: Array<ExceptionsObject>;
  unresolvedExceptionsLoading: boolean;
  unresolvedExceptionsRequests: Array<AnrUnresolvedExceptionRequest>;
  unresolvedExceptionsTotal: number;
  activeUnresolvedExceptions: AnrUnresolvedExceptionRequest | null;
  activeUnresolvedExceptionsOverrideReasons: Array<ExceptionsHashmapObject>;
  activeUnresolvedExceptionsLoading: boolean;
  activeUnresolvedExceptionsTotal: number;
  activeUnresolvedExceptionsOverrideSuccess: boolean;
  notInitiatedLoading: boolean;
  notInitiatedRequests: Array<AnrNotInitiatedRequest>;
  notInitiatedTotal: number;
  totalsTabDrilldownCounts: {
    outstandingRequestsDrilldown: number;
  };
  otherTabDrilldownCounts: {
    openInactive119Drilldown: number;
    open119AdjustingDrilldown: number;
    open143Drilldown: number;
  };
  contributions: Array<AnrContributions>;
  contributionsActiveView: {
    type: string;
    view: 'remitted' | 'reported' | null;
    data: AnrContributionDetails | null;
  };
  contributionsLoading: boolean;
}

const initialState = {
  lockError: null,
  activeYear: null,
  activeTabs: {
    mainTab: 'totals' as const,
    totalsTab: 'not-submitted' as const,
    otherTab: 'inactive-memberships' as const
  },
  requestANR: {
    outstandingRequests: 0,
    errorRequests: 0,
    unreportedMemberships: 0,
    completedMemberships: 0,
    pendingMemberships: 0,
    reportsGenerated: 0,
    reportsGeneratedPct: 0,
    reportsToBeGenerated: 0,
    reportsToBeGeneratedPct: 0,
    totalActiveMembership: 0
  } as AnrRequest,
  unresolvedExceptionsLoading: false,
  unresolvedExceptionsRequests: [],
  unresolvedExceptionsTotal: 0,
  activeUnresolvedExceptions: null,
  activeUnresolvedExceptionsOverrideReasons: [],
  activeUnresolvedExceptionsLoading: false,
  activeUnresolvedExceptionsTotal: 0,
  activeUnresolvedExceptionsOverrideSuccess: false,
  notInitiatedLoading: false,
  notInitiatedRequests: [],
  notInitiatedTotal: 0,
  totalsTabDrilldownCounts: {
    outstandingRequestsDrilldown: 0
  },
  otherTabDrilldownCounts: {
    openInactive119Drilldown: 0,
    open119AdjustingDrilldown: 0,
    open143Drilldown: 0
  },
  contributions: [],
  contributionsActiveView: {
    type: '',
    view: null,
    data: null
  },
  contributionsLoading: false,
  exceptions: [],
  overrideExceptions: []
};

const annualReconciliation = (
  state: AnnualReconciliationState = initialState,
  action: EapAction
): AnnualReconciliationState => {
  const { type, data } = action;

  switch (type) {
    case annualReconciliationActionTypes.GET_ANR_INFO_REQUESTED:
      return {
        ...initialState,
        activeYear: data,
        activeTabs: {
          ...state.activeTabs
        },
        activeUnresolvedExceptions: state.activeUnresolvedExceptions,
        activeUnresolvedExceptionsOverrideReasons:
          state.activeUnresolvedExceptionsOverrideReasons
      };
    case annualReconciliationActionTypes.GET_ANR_INFO_SUCCESS:
      return {
        ...state,
        requestANR: data.requestANR,
        contributions: data.contributions,
        exceptions: data.exceptions,
        overrideExceptions: [],
        totalsTabDrilldownCounts: {
          outstandingRequestsDrilldown:
            data.totalsTabDrilldownCounts.outstandingRequestsDrilldown
        },
        otherTabDrilldownCounts: {
          openInactive119Drilldown:
            data.otherTabDrilldownCounts.openInactive119Drilldown,
          open119AdjustingDrilldown:
            data.otherTabDrilldownCounts.open119AdjustingDrilldown,
          open143Drilldown: data.otherTabDrilldownCounts.open143Drilldown
        }
      };
    case annualReconciliationActionTypes.SET_ANR_MAIN_TAB:
      return {
        ...state,
        activeTabs: {
          ...state.activeTabs,
          mainTab: data,
          totalsTab: 'not-submitted',
          otherTab: 'inactive-memberships'
        }
      };
    case annualReconciliationActionTypes.SET_ANR_TOTALS_TAB:
      return {
        ...state,
        activeTabs: {
          ...state.activeTabs,
          totalsTab: data
        },
        activeUnresolvedExceptions: null,
        activeUnresolvedExceptionsOverrideReasons: []
      };
    case annualReconciliationActionTypes.SET_ANR_OTHER_TAB:
      return {
        ...state,
        activeTabs: {
          ...state.activeTabs,
          otherTab: data
        }
      };
    case annualReconciliationActionTypes.OVERRIDE_ANR_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case annualReconciliationActionTypes.GET_ANR_UNRESOLVED_EXCEPTIONS_REQUESTED:
      return {
        ...state,
        unresolvedExceptionsLoading: true
      };
    case annualReconciliationActionTypes.GET_ANR_UNRESOLVED_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        unresolvedExceptionsLoading: false,
        unresolvedExceptionsTotal: data.totalRowCount,
        unresolvedExceptionsRequests: data.requests
      };
    case annualReconciliationActionTypes.GET_ANR_UNRESOLVED_EXCEPTIONS_FAILED:
      return {
        ...state,
        unresolvedExceptionsLoading: false
      };
    case annualReconciliationActionTypes.GET_ANR_NOT_INITIATED_REQUESTED:
      return {
        ...state,
        notInitiatedLoading: true
      };
    case annualReconciliationActionTypes.GET_ANR_NOT_INITIATED_SUCCESS:
      return {
        ...state,
        notInitiatedLoading: false,
        // notInitiatedTotal: data.totalRowCount, // TODO: Enable when API supports pagination
        notInitiatedRequests: data.requests
      };
    case annualReconciliationActionTypes.GET_ANR_NOT_INITIATED_FAILED:
      return {
        ...state,
        notInitiatedLoading: false
      };
    case annualReconciliationActionTypes.SET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS:
      return {
        ...state,
        activeUnresolvedExceptions: data
      };
    case annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_REQUESTED:
      return {
        ...state,
        activeUnresolvedExceptionsLoading: true
      };
    case annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        activeUnresolvedExceptionsLoading: false,
        activeUnresolvedExceptionsTotal: data.totalRowCount,
        activeUnresolvedExceptionsOverrideReasons: data.overrideReasons
      };
    case annualReconciliationActionTypes.GET_ANR_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED:
      return {
        ...state,
        activeUnresolvedExceptionsLoading: false,
        activeUnresolvedExceptions: null,
        activeUnresolvedExceptionsTotal: 0
      };
    case annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_SUCCESS:
      return {
        ...state,
        activeUnresolvedExceptionsOverrideSuccess: true
      };
    case annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_CLEAR:
    case annualReconciliationActionTypes.OVERRIDE_ACTIVE_UNRESOLVED_EXCEPTIONS_FAILED:
      return {
        ...state,
        activeUnresolvedExceptionsOverrideSuccess: false
      };
    case annualReconciliationActionTypes.GET_ANR_CONTRIBUTIONS_REQUESTED:
      return {
        ...state,
        contributionsLoading: true
      };
    case annualReconciliationActionTypes.GET_ANR_CONTRIBUTIONS_SUCCESS:
      return {
        ...state,
        contributionsActiveView: {
          type: data.type,
          view: data.view,
          data: data.data
        },
        contributionsLoading: false
      };
    case annualReconciliationActionTypes.GET_ANR_CONTRIBUTIONS_FAILED:
      return {
        ...state,
        contributionsLoading: false
      };
    case annualReconciliationActionTypes.CLEAR_ANR_CONTRIBUTIONS_VIEW:
      return {
        ...state,
        contributionsActiveView: {
          type: '',
          view: null,
          data: null
        }
      };
    case annualReconciliationActionTypes.LOCK_ANR_FAILED:
      return {
        ...state,
        lockError: data
      };
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
    case selectEmployersActionTypes.SELECT_GROUP_NUMBER_SUCCESS:
      return initialState;
    case annualReconciliationActionTypes.REQUEST_ANNUAL_PENSION_REPORT_GENERATION:
      return {
        ...state,
        requestANR: {
          ...state.requestANR,
          generateReportInd: 'Y'
        }
      };
    default:
      return {
        ...state
      };
  }
};

export default annualReconciliation;
