import { FormEvent, memo } from 'react';
import { get } from 'lodash';
import { Controller, useFormContext } from 'react-hook-form';

import RadioButtonGroup, { RadioButtonGroupProps } from './RadioButtonGroup';

interface FormRadioButtonGroupProps
  extends Omit<RadioButtonGroupProps, 'onChange'> {
  name: string;
  onChange?: (event: FormEvent<HTMLInputElement>) => void;
}
const FormRadioButtonGroup: React.FC<FormRadioButtonGroupProps> = ({
  name,
  children,
  ...props
}) => {
  const {
    formState: { errors }
  } = useFormContext();

  return (
    <Controller
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <RadioButtonGroup
          {...props}
          selectedValue={value}
          onChange={e => {
            onChange(e);

            if (props.onChange) {
              props.onChange(e);
            }
          }}
          onBlur={e => {
            onBlur();

            if (props.onBlur) {
              props.onBlur(e);
            }
          }}
          // Because Lodash get does not know the type of errors,
          // Type casting its return value as string
          error={
            props.error ??
            ((get(errors, `${name}.message`) as unknown) as string)
          }
        >
          {children}
        </RadioButtonGroup>
      )}
    />
  );
};

export default memo(FormRadioButtonGroup);
