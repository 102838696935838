import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData, deleteData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getForm106 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e106/${requestNumber}`,
    undefined,
    requestNumber
  );

export const createForm106 = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e106`, data);

export const upsertForm106 = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e106/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const submitForm106 = (data: DataObj, requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e106/submit/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const createServicePeriod = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e106/${requestNumber}/period`,
    undefined,
    undefined,
    requestNumber
  );

export const deleteLatestServicePeriod = (requestNumber: string) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e106/${requestNumber}/period`,
    undefined,
    requestNumber
  );
