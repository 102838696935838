import Divider from 'components/v2/atomic/divider/Divider';
import { useTranslation } from 'react-i18next';
import StatusIndicator from 'components/v2/atomic/statusIndicator/StatusIndicator';
import Icon, { IconNames } from 'components/v2/atomic/icon/Icon';
import Popover from 'components/v2/atomic/popover/Popover';
import Button from 'components/v2/atomic/button/Button';
import { isEnabled, currentFlags } from 'features/featureFlags/featureFlags';
import { useState } from 'react';
import Modal from 'components/v2/atomic/modal/Modal';
import { IconNamesSmall } from 'components/v2/atomic/icon/Icons';
import { SelectMenuItem } from 'components/v2/atomic/selectInput/SelectInput';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'types';
import { cancelAppointment } from 'services/microsoftBookings';
import { showSuccessToast } from 'features/v2/toaster';
import { BaseStyles } from 'theme-ui';
import Spinner from 'components/v2/atomic/spinner/Spinner';
import { isFuture, parseISO } from 'date-fns';
import { Appointment } from './interfaces';
import { cancelAppointmentAction } from './microsoftBookingsDrawerReducer';

interface AppointmentsProps {
  appointments: Appointment[];
  staffMembers: SelectMenuItem[];
  setCancelAppointmentError: (isError: boolean) => void;
  initializeUpdateAppointment: (appointment: Appointment) => void;
}

const Appointments = ({
  appointments,
  staffMembers,
  setCancelAppointmentError,
  initializeUpdateAppointment
}: AppointmentsProps) => {
  const { t } = useTranslation('microsoft-bookings');
  const dispatch = useDispatch();
  const { selectGroupNumber } = useSelector(
    (state: RootState) => state.selectEmployers
  );
  const { featureFlags } = useSelector(
    (state: RootState) => state.featureFlags
  );
  const isEmployersBookingsEnhancementsEnabled = isEnabled(
    featureFlags,
    currentFlags.EMPLOYER_BOOKINGS_ENHANCEMENTS
  );
  const [selectedAppointment, setSelectedAppointment] = useState<Appointment>();
  const [isCancelBookingModalOpen, setIsCancelBookingModalOpen] = useState<
    boolean
  >(false);
  const [isLoadingCancelBooking, setIsLoadingCancelBooking] = useState<boolean>(
    false
  );

  const futureAppointments = appointments.filter(appointment =>
    isFuture(parseISO(appointment.time))
  );

  const handleCancelClick = async () => {
    try {
      setIsLoadingCancelBooking(true);
      if (selectedAppointment) {
        const response = await cancelAppointment(
          selectGroupNumber,
          selectedAppointment?.id
        );
        if (response.success) {
          dispatch(cancelAppointmentAction(selectedAppointment));
          setIsCancelBookingModalOpen(false);
          dispatch(
            showSuccessToast({
              message: t('CANCELLATION_SUCCESS_TOAST_MESSAGE')
            })
          );
        } else {
          setCancelAppointmentError(true);
          setIsCancelBookingModalOpen(false);
        }
      }
      setIsLoadingCancelBooking(false);
      setSelectedAppointment(undefined);
    } catch (error) {
      setCancelAppointmentError(true);
      setIsLoadingCancelBooking(false);
      setIsCancelBookingModalOpen(false);
      setSelectedAppointment(undefined);
    }
  };

  return (
    <div>
      <BaseStyles>
        <Modal
          id="cancel-msbooking-modal"
          titleText={t('CANCELLATION_CONFIRMATION_MODAL_TITLE')}
          modalWidth="600px"
          childrenSxOverride={{ padding: '1.5rem 2.5rem' }}
          isOpen={isCancelBookingModalOpen}
          onClose={() => {
            setIsCancelBookingModalOpen(false);
            setSelectedAppointment(undefined);
          }}
        >
          <p sx={{ fontSize: 'small' }}>
            {t('CANCELLATION_CONFIRMATION_MODAL_BODY')}
          </p>
          <div
            sx={{
              padding: '1.5rem',
              borderRadius: '5px',
              width: '520px',
              backgroundColor: 'extendedBlue25',
              '>div': {
                fontSize: 'small',
                display: 'flex',
                alignContent: 'center',
                columnGap: '0.75rem',
                marginBottom: '1rem',

                svg: {
                  marginTop: '0.125rem'
                },

                p: {
                  margin: '0',
                  textAlign: 'left'
                }
              },
              '>div:last-child': {
                marginBottom: '0'
              }
            }}
          >
            <div>
              <Icon icon={IconNamesSmall.CALENDAR} />
              <p>{selectedAppointment?.startDay}</p>
            </div>
            <div>
              <Icon icon={IconNamesSmall.CLOCK} />
              <p>{selectedAppointment?.startTime}</p>
            </div>
            <div>
              <Icon icon={IconNamesSmall.USERS_MEETING} />
              <p>
                {staffMembers.find(
                  staff => staff.key === selectedAppointment?.staffID[0]
                )?.value ?? '-'}
              </p>
            </div>
            <div>
              <Icon icon={IconNamesSmall.HOURGLASS} />
              <p>
                {selectedAppointment?.duration} {t('MINUTES')}
              </p>
            </div>
            <div>
              <Icon icon={IconNamesSmall.TODO} />
              <p sx={{ maxHeight: '6.25rem', overflowY: 'auto' }}>
                {selectedAppointment?.meetingNotes}
              </p>
            </div>
          </div>
          <div
            sx={{
              display: 'flex',
              width: '520px',
              justifyContent: 'space-between',
              paddingTop: '1.5rem'
            }}
          >
            {!isLoadingCancelBooking && (
              <>
                <Button
                  id="cancel-booking-no"
                  minWidth="250px"
                  variant="outline"
                  onClick={() => {
                    setIsCancelBookingModalOpen(false);
                  }}
                >
                  {t('CANCELLATION_CONFIRMATION_MODAL_EXIT_BUTTON')}
                </Button>
                <Button
                  id="cancel-booking-yes"
                  minWidth="250px"
                  loading={isLoadingCancelBooking}
                  onClick={handleCancelClick}
                >
                  {t('CANCELLATION_CONFIRMATION_MODAL_CANCEL_BOOKING_BUTTON')}
                </Button>
              </>
            )}
            {isLoadingCancelBooking && (
              <div
                sx={{
                  margin: '0 auto',
                  padding: '0 50px',
                  display: 'flex',
                  gap: '0.5rem',
                  alignItems: 'center',
                  svg: {
                    marginBottom: '0'
                  }
                }}
              >
                <Spinner
                  id="cancellingAppointmentSpinner"
                  isFullScreen={false}
                  size={22}
                />
                <span
                  sx={{
                    fontWeight: 'semibold',
                    fontSize: '1.1875rem',
                    lineHeight: 'base',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {t('CANCELLING_APPOINTMENT')}
                </span>
              </div>
            )}
          </div>
        </Modal>
      </BaseStyles>

      {futureAppointments.map((appointment, index) => (
        <div key={`${appointment.startDay}-${appointment.startTime}`}>
          <div
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignContent: 'center'
            }}
          >
            <div
              sx={{
                display: 'flex',
                gap: '14px',
                fontSize: '18px',
                justifyContent: 'flex-start'
              }}
            >
              <div sx={{ padding: '8px 0px 8px 0px' }}>
                {`${appointment.startDay} ${t('AT')} ${appointment.startTime}`}
              </div>
              {(appointment.tag === 'New' || appointment.tag === 'Updated') && (
                <div sx={{ padding: '8px' }}>
                  <StatusIndicator
                    id="appointmentStatusIndicator"
                    intent="info"
                    text={appointment.tag}
                    type="tag"
                  />
                </div>
              )}
            </div>
            {isEmployersBookingsEnhancementsEnabled && (
              <div sx={{ display: 'flex' }}>
                <Popover
                  content="Modify booking"
                  containerPadding="1rem"
                  interactionKind="hover"
                  width="fit-content"
                  placement="top"
                >
                  <Button
                    id={`update-appointment-${appointment.id}`}
                    isIconButton
                    variant="simple"
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      initializeUpdateAppointment(appointment);
                    }}
                    sxOverride={{ padding: '0.75rem' }}
                  >
                    <Icon icon={IconNames.PEN} />
                  </Button>
                </Popover>
                <Popover
                  content="Cancel booking"
                  interactionKind="hover"
                  placement="top"
                  width="fit-content"
                  containerPadding="1rem"
                >
                  <Button
                    id={`cancel-appointment-${appointment.id}`}
                    isIconButton
                    variant="simple"
                    sxOverride={{ padding: '0.75rem' }}
                    onClick={() => {
                      setSelectedAppointment(appointment);
                      setIsCancelBookingModalOpen(true);
                    }}
                  >
                    <Icon icon={IconNames.DELETE} />
                  </Button>
                </Popover>
              </div>
            )}
          </div>
          <Divider id={`appointment${index}`} />
        </div>
      ))}
    </div>
  );
};

export default Appointments;
