import { EapAction } from 'types';
import {
  FormState,
  FormStatus
} from 'containers/requestForm/common/interfaces';
import {
  PaymentScheduleModel,
  ReportedUnReportedServicePurchaseModel,
  ReportedUnReportedServiceFormDataModel,
  ServicePurchaseModel,
  PaymentOwingModel,
  ServicePurchaseSearchMemberProfileInfo,
  SummaryModel,
  SummaryFormData,
  SummaryContributionFormDataModel
} from 'interfaces/form105';
import { isNull } from 'lodash';
import { getSubmissionStatus, formatCurrencyStringToNumber } from 'utils';
import { form105ActionTypes } from './form105Actions';
import {
  mapForm105,
  mapSummaryContributionModel,
  mapSummaryModel
} from './mapForm105';
import { SEARCH_BY_MEMBER } from './contributionRemittanceInformation/servicePurchase/ServicePurchaseMemberFilter';
import { getFilteredServicePurchase } from './utils/form105Utils';

export interface DirtyValues {
  [key: string]: any;
}
export type D = keyof DirtyValues;

export interface Form105Data {
  employerRcaAmt: string;
  employerRppAmt: string;
  memberRcaAmt: string;
  memberRppAmt: string;
  retroIncludedInd: string;
  paymentSchedule: Array<{
    epaymentScheduleType: string;
    employerAmt: string;
    originalEmployerAmt: string;
    memberAmt: string;
    originalMemberAmt: string;
    adjustmentReason: string;
    effectiveDate: string;
    includedInd: 'Y' | 'N';
    adjustedInd: 'Y' | 'N';
    lineNo: number | null;
  }>;
  newPaymentSchedule: Array<{
    epaymentScheduleType: string;
    employerAmt: string;
    originalEmployerAmt?: string;
    memberAmt: string;
    originalMemberAmt?: string;
    adjustmentReason: string;
    effectiveDate?: string;
    includedInd?: 'Y' | 'N';
    adjustedInd?: 'Y' | 'N' | null;
    lineNo?: number | string | null;
  }>;
  optionalServiceAgreement: string;
  servicePurchaseReportedModels: Array<ReportedUnReportedServiceFormDataModel>;
  servicePurchaseUnreportedModels: Array<
    ReportedUnReportedServiceFormDataModel
  >;
  paymentOwingModels: Array<PaymentOwingModel>;
  summaryModel: SummaryFormData | null;
  summaryContributionModel: SummaryContributionFormDataModel | null;
  showLateRemittanceChargesMsg: boolean;
  noteToMember: string;
  noteToOmers: string;
  servicePurchaseFilter: {
    typeOfPurchase: string;
    dateReceivedFrom: string;
    dateReceivedTo: string;
    searchByMemberSelect: string;
    searchByMemberValue: string;
    isFilterLoading: boolean;
    isFilterDirty: boolean;
  };
}

export interface Form105State extends FormState {
  form105Data: Form105Data;
  paymentScheduleModels: Array<PaymentScheduleModel> | null;
  servicePurchaseReportedModels: Array<
    ReportedUnReportedServicePurchaseModel
  > | null;
  servicePurchaseUnreportedModels: Array<
    ReportedUnReportedServicePurchaseModel
  > | null;
  paymentOwingModels: Array<PaymentOwingModel> | null;
  paymentsOwingTotalAmt: number | null;
  summaryModel: SummaryModel | null;
  remittanceType: string;
  showLateRemittanceChargesMsg: boolean;
  formMonth: string;
  paymentMethod: string;
  isAddingNewServicePurchase: boolean;
  servicePurchaseSearchTableRowCount: number;
  servicePurchaseSearchTableModels: Array<ServicePurchaseModel>;
  removeAddedServiceStatus: FormStatus;
  dirtyValues: DirtyValues;
  servicePurchaseSearchMemberProfileInfo: ServicePurchaseSearchMemberProfileInfo;
}

const form105Data: Form105Data = {
  employerRcaAmt: '',
  employerRppAmt: '',
  memberRcaAmt: '',
  memberRppAmt: '',
  retroIncludedInd: 'N',
  paymentSchedule: [],
  newPaymentSchedule: [],
  optionalServiceAgreement: 'N',
  servicePurchaseReportedModels: [],
  servicePurchaseUnreportedModels: [],
  paymentOwingModels: [],
  summaryModel: null,
  summaryContributionModel: null,
  showLateRemittanceChargesMsg: false,
  noteToMember: '',
  noteToOmers: '',
  servicePurchaseFilter: {
    typeOfPurchase: '',
    dateReceivedFrom: '',
    dateReceivedTo: '',
    searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
    searchByMemberValue: '',
    isFilterLoading: false,
    isFilterDirty: false
  }
};

export const initialState: Form105State = {
  requestNumber: null,
  initLoading: false,
  formStep: 1,
  formStatus: '' as const,
  removeAddedServiceStatus: '' as const,
  submissionStatus: '' as const,
  submissionComplete: false,
  exceptions: [],
  overrideExceptions: [],
  pdfs: [],
  form105Data,
  paymentScheduleModels: [],
  servicePurchaseReportedModels: [],
  servicePurchaseUnreportedModels: [],
  paymentOwingModels: [],
  paymentsOwingTotalAmt: null,
  summaryModel: null,
  showLateRemittanceChargesMsg: false,
  remittanceType: '',
  formMonth: '2021-01-01',
  paymentMethod: '',
  isAddingNewServicePurchase: false,
  servicePurchaseSearchTableRowCount: 0,
  servicePurchaseSearchTableModels: [],
  dirtyValues: {},
  servicePurchaseSearchMemberProfileInfo: {}
};

const form105 = (
  state: Form105State = initialState,
  action: EapAction
): Form105State => {
  const { type, data } = action;
  switch (type) {
    case form105ActionTypes.CREATE_FORM_105:
      return {
        ...state,
        employerNo: data.employerNo
      };
    case form105ActionTypes.SET_REQUEST_NUMBER_FORM_105:
      return {
        ...state,
        requestNumber: data,
        initLoading: true
      };
    case form105ActionTypes.GET_FORM_105_SUCCESS:
      return {
        ...state,
        employerNo: data.employerNo,
        requestNumber: data.requestNumber,
        form105Data: mapForm105(data, {
          noteToMember: !isNull(data) ? null : state?.commentsToMember ?? '',
          noteToOmers: !isNull(data) ? null : state?.notes ?? ''
        }),
        paymentScheduleModels: data.paymentScheduleModels,
        servicePurchaseReportedModels: data.servicePurchaseReportedModels,
        servicePurchaseUnreportedModels: data.servicePurchaseUnreportedModels,
        paymentOwingModels: data.paymentOwingModels,
        summaryModel: data.summaryModel,
        paymentsOwingTotalAmt: data.paymentsOwingTotalAmt,
        remittanceType: data.remittanceType,
        formMonth: data.formMonth,
        exceptions: data.exceptions,
        showLateRemittanceChargesMsg: data.showLateRemittanceChargesMsg,
        initLoading: false,
        formStep: 1,
        formStatus: '' as const,
        submissionStatus: '' as const,
        submissionComplete: false,
        overrideExceptions: [],
        paymentMethod: data.paymentMethod,
        isAddingNewServicePurchase: false
      };
    case form105ActionTypes.GET_FORM_105_FAILED:
      return {
        ...state,
        initLoading: true
      };
    case form105ActionTypes.SET_FORM_105_STEP:
      return {
        ...state,
        formStep: data
      };
    case form105ActionTypes.SAVE_FORM_105:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form105ActionTypes.SAVE_FORM_105_SUCCESS: {
      const optionalServiceAgreement =
        state.formStep === 1 &&
        (data.formData.newPaymentSchedule?.length > 0 ||
          data.formData.paymentSchedule?.length > 0)
          ? 'Y'
          : state.formStep === 1
          ? 'N'
          : state.form105Data.optionalServiceAgreement;

      return {
        ...state,
        dirtyValues: {},
        formStatus: 'success' as const,
        form105Data: {
          ...state.form105Data,
          employerRcaAmt:
            state.formStep === 1
              ? data.formData.employerRcaAmt
              : state.form105Data.employerRcaAmt,
          employerRppAmt:
            state.formStep === 1
              ? data.formData.employerRppAmt
              : state.form105Data.employerRppAmt,
          memberRcaAmt:
            state.formStep === 1
              ? data.formData.memberRcaAmt
              : state.form105Data.memberRcaAmt,
          memberRppAmt:
            state.formStep === 1
              ? data.formData.memberRppAmt
              : state.form105Data.memberRppAmt,
          retroIncludedInd:
            state.formStep === 1
              ? data.formData.retroIncludedInd
              : state.form105Data.retroIncludedInd,
          summaryContributionModel: data.formData.summaryContributionModel,
          noteToOmers: data.formData?.noteToOmers,
          optionalServiceAgreement,
          paymentSchedule:
            data.formData.paymentSchedule || state.form105Data.paymentSchedule,
          newPaymentSchedule:
            data.formData.newPaymentSchedule ||
            state.form105Data.newPaymentSchedule,
          summaryModel: state.form105Data.summaryModel
            ? {
                ...state.form105Data.summaryModel,
                offsetAmt: data.formData.summaryModel
                  ? (
                      formatCurrencyStringToNumber(
                        data.formData.summaryModel.originalInvoiceTotalAmt
                      ) -
                      formatCurrencyStringToNumber(
                        data.formData.summaryModel.adjustedInvoiceTotalAmt
                      )
                    ).toFixed(2)
                  : '0.00',
                adjustedInvoiceTotalAmt: data.formData.summaryModel
                  ? formatCurrencyStringToNumber(
                      data.formData.summaryModel?.adjustedInvoiceTotalAmt
                    ).toFixed(2)
                  : '0.00'
              }
            : null
        }
      };
    }
    case form105ActionTypes.SAVE_FORM_105_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form105ActionTypes.REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_SUCCESS: {
      const filteredReported = getFilteredServicePurchase(
        data,
        state.dirtyValues.servicePurchaseReportedModels
      );

      const filteredUnreported = getFilteredServicePurchase(
        data,
        state.dirtyValues.servicePurchaseUnreportedModels
      );

      return {
        ...state,
        removeAddedServiceStatus: 'deleted' as const,
        form105Data: {
          ...state.form105Data,
          servicePurchaseReportedModels: state.dirtyValues
            .servicePurchaseReportedModels
            ? filteredReported
            : [],
          servicePurchaseUnreportedModels: state.dirtyValues
            .servicePurchaseUnreportedModels
            ? filteredUnreported
            : []
        },
        dirtyValues: {
          ...state.dirtyValues,
          servicePurchaseReportedModels: state.dirtyValues
            .servicePurchaseReportedModels
            ? filteredReported
            : [],
          servicePurchaseUnreportedModels: state.dirtyValues
            .servicePurchaseUnreportedModels
            ? filteredUnreported
            : []
        }
      };
    }
    case form105ActionTypes.REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_FAILED:
      return {
        ...state,
        removeAddedServiceStatus: 'error' as const
      };
    case form105ActionTypes.STASH_DIRTY_VALUES:
      return {
        ...state,
        dirtyValues: data
      };
    case form105ActionTypes.CLEAR_FORM_105_STATUS:
      return {
        ...state,
        formStatus: '' as const
      };
    case form105ActionTypes.CLEAR_FORM_105_DATA:
      return {
        ...initialState
      };
    case form105ActionTypes.SET_ADD_NEW_SERVICE_PURCHASE:
      return {
        ...state,
        isAddingNewServicePurchase: data.isAddingNewServicePurchase
      };
    case form105ActionTypes.SEARCH_SERVICE_PURCHASES_SUCCEED:
      return {
        ...state,
        servicePurchaseSearchTableRowCount:
          data.requestSearchResult?.totalRowCount,
        servicePurchaseSearchTableModels: data.requestSearchResult?.servicePurchases.map(
          (servicePurchase: ServicePurchaseModel) => ({
            ...servicePurchase,
            isSelected: false
          })
        ),
        form105Data: {
          ...state.form105Data,
          servicePurchaseFilter: {
            ...state.form105Data.servicePurchaseFilter,
            isFilterLoading: false,
            isFilterDirty: false
          }
        }
      };
    case form105ActionTypes.SET_SERVICE_PURCHASE_MEMBER_FILTER_IS_DIRTY:
      return {
        ...state,
        form105Data: {
          ...state.form105Data,
          servicePurchaseFilter: {
            ...state.form105Data.servicePurchaseFilter,
            isFilterDirty: data.isFilterDirty
          }
        }
      };
    case form105ActionTypes.APPLY_SERVICE_PURCHASE_MEMBER_FILTER:
      return {
        ...state,
        form105Data: {
          ...state.form105Data,
          servicePurchaseFilter: {
            typeOfPurchase: data.typeOfPurchase,
            dateReceivedFrom: data.dateReceivedFrom,
            dateReceivedTo: data.dateReceivedTo,
            searchByMemberSelect: data.searchByMemberSelect,
            searchByMemberValue: data.searchByMemberValue,
            isFilterLoading: true,
            isFilterDirty: state.form105Data.servicePurchaseFilter.isFilterDirty
          }
        }
      };
    case form105ActionTypes.RESET_SERVICE_PURCHASE_MEMBER_FILTER:
      return {
        ...state,
        form105Data: {
          ...state.form105Data,
          servicePurchaseFilter: {
            typeOfPurchase: '',
            dateReceivedFrom: '',
            dateReceivedTo: '',
            searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
            searchByMemberValue: '',
            isFilterLoading: false,
            isFilterDirty: false
          }
        }
      };
    case form105ActionTypes.REFRESH_SERVICE_PURCHASE_SUCCEED:
      return {
        ...state,
        servicePurchaseUnreportedModels: data.servicePurchaseUnreportedModels,
        servicePurchaseReportedModels: data.servicePurchaseReportedModels,
        form105Data: {
          ...state.form105Data,
          servicePurchaseUnreportedModels: data.servicePurchaseUnreportedModels,
          servicePurchaseReportedModels: data.servicePurchaseReportedModels
        }
      };
    case form105ActionTypes.SET_SELECTED_SERVICE_PURCHASE_TO_ADD:
      return {
        ...state,
        servicePurchaseSearchTableModels: state.servicePurchaseSearchTableModels.map(
          servicePurchase => ({
            ...servicePurchase,
            isSelected:
              servicePurchase.purchaseNo === data.purchaseNo
                ? data.isSelected
                : servicePurchase.isSelected
          })
        )
      };
    case form105ActionTypes.SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_SUCCEED:
      return {
        ...state,
        servicePurchaseSearchMemberProfileInfo:
          data.memberSearchResult.memberships[0]
      };
    case form105ActionTypes.SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_FAILED:
      return {
        ...state,
        servicePurchaseSearchMemberProfileInfo: { hasMemberSearchError: true }
      };
    case form105ActionTypes.ADD_UNREPORTED_SERVICE_PURCHASE_SUCCEED:
      return {
        ...state,
        servicePurchaseSearchMemberProfileInfo: {
          ...state.servicePurchaseSearchMemberProfileInfo,
          manualAddSuccess: true
        }
      };
    case form105ActionTypes.GET_SUMMARY_MODEL_FORM_105_SUCCESS:
      return {
        ...state,
        form105Data: {
          ...state.form105Data,
          summaryModel: mapSummaryModel(data.summaryModel),
          summaryContributionModel: mapSummaryContributionModel(
            data.contributionModel,
            data.employerName
          ),
          noteToOmers: data.notes
        },
        summaryModel: data.summaryModel,
        exceptions: data.exceptions,
        notes: data.notes
      };
    case form105ActionTypes.SUBMIT_FORM_105_SUCCESS:
      return {
        ...state,
        exceptions: !data.exceptions ? [] : data.exceptions,
        overrideExceptions: [],
        submissionStatus: getSubmissionStatus(data, data.exceptions),
        submissionComplete:
          getSubmissionStatus(data, data.exceptions) === 'success',
        formStep:
          getSubmissionStatus(data, data.exceptions) === 'success' ? 3 : 2,
        pdfs: data.attachments,
        initLoading: true
      };
    case form105ActionTypes.SET_FORM_105_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form105ActionTypes.OVERRIDE_FORM_105_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form105ActionTypes.DELETE_FORM_105:
      return {
        ...state,
        formStatus: 'deleted',
        requestNumber: null
      };
    case form105ActionTypes.GET_EXCEPTIONS_FORM_105_SUCCESS:
      return {
        ...state,
        exceptions: data.exceptions
      };
    default:
      return state;
  }
};

export default form105;
