import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const memberSearch = (data: DataObj, token?: string) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/members/search`, data, token);

export const servicePeriodDetails = (
  query: {
    membershipNumber: number;
    employerNumber: number;
  },
  token?: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/members/serviceperiods?membershipNo=${query.membershipNumber}&employerNo=${query.employerNumber}`,
    token
  );

export const memberServiceSummary = (
  query: {
    membershipNumber: number;
    status: string;
  },
  token?: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/members/servicesummary?membershipNo=${query.membershipNumber}&membershipStatus=${query.status}`,
    token
  );

export const getMemberDetails = (
  query: {
    membershipNumber: number;
    employmentNumber: number;
    employerNumber: number;
    ctx: string;
  },
  token?: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/members/employment?membershipNo=${query.membershipNumber}&employmentNo=${query.employmentNumber}&employerNo=${query.employerNumber}&ctx=${query.ctx}`,
    token
  );

export const getMemberEarnings = (
  query: {
    membershipNumber: number;
    employerNumber: number;
  },
  token?: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/members/earnings?employerNo=${query.employerNumber}&membershipNo=${query.membershipNumber}`,
    token
  );

export const getMemberRetroPayEarnings = (
  data: {
    membershipNo: number;
    year: number;
  },
  token?: string
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/members/retropayearnings?membershipNo=${data.membershipNo}&year=${data.year}`,
    token
  );

export const updateForm132Consent = (data: DataObj) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/members/reportForm132`,
    data,
    undefined,
    undefined,
    'text'
  );
