import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { getData, postData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const getForm102EnrolmentReasons = (
  employmentStatus: string,
  sameDates: boolean
) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/enrolment-reason?employmentStatus=${employmentStatus}&sameDates=${sameDates}`
  );

export const createForm102 = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102`, data);

export const submitForm102 = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const updateForm102 = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const getForm102 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e102/${requestNumber}`,
    undefined,
    requestNumber
  );

export const validatePhoneNumber = (data: DataObj, requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/phone/validation`,
    data,
    undefined,
    requestNumber
  );
