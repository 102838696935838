import { useState, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg';

import Divider from 'components/divider/Divider';
import styles from './NavDropdown.module.scss';

type DisplayList = {
  text: string | JSX.Element | null;
  id: number;
  callback?: Function;
  link?: string;
  divider?: boolean;
  disabled?: boolean;
  sameTab?: boolean;
};

interface NavDropdownProps {
  displayText: string | JSX.Element;
  displayList: DisplayList[];
  positionRightPerc?: number;
}

const NavDropdown = ({
  displayText,
  displayList,
  positionRightPerc = 0
}: NavDropdownProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      // close menu on mouse outside click
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target as HTMLElement)
      ) {
        setShowMenu(false);
      }
    };
    const handleKeydown = (event: KeyboardEvent) => {
      // close menu on Escape
      if (event.key === 'Escape') {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick, true);
    if (showMenu) {
      document.addEventListener('keydown', handleKeydown);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick, true);
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [showMenu, wrapperRef]);

  return (
    <div className={styles.dropdown} ref={wrapperRef}>
      <div className={styles.container}>
        <button
          type="button"
          className={
            showMenu
              ? `${styles.icon} ${styles['icon-active']}`
              : `${styles.icon}`
          }
          onClick={() => setShowMenu(!showMenu)}
        >
          <span>{displayText}</span>
          <ReactSVG
            src="/images/chevron-down-blue.svg"
            data-testid="dropdown-icon"
          />
        </button>
      </div>
      {showMenu && (
        <div
          className={styles.displayList}
          style={{ right: `calc(${positionRightPerc}%)` }}
        >
          {displayList.map(
            ({ text, callback, id, link, divider, sameTab, disabled }) => {
              if (divider) {
                return (
                  <div className={styles.divider} key={`displayList-${id}`}>
                    <Divider />
                  </div>
                );
              }

              if (link) {
                return (
                  <a
                    href={link}
                    key={`displayList-${id}`}
                    target={sameTab ? '' : '_blank'}
                    rel="noopener noreferrer"
                    onClick={() => setShowMenu(false)}
                  >
                    {text}
                  </a>
                );
              }

              if (callback) {
                return (
                  <button
                    type="button"
                    onClick={() => {
                      setShowMenu(false);
                      callback();
                    }}
                    key={`displayList-${id}`}
                    disabled={disabled}
                    className={disabled ? styles.disabledMenuItem : ''}
                  >
                    {text}
                  </button>
                );
              }

              return (
                <div className={styles.item} key={`displayList-${id}`}>
                  {text}
                </div>
              );
            }
          )}
        </div>
      )}
    </div>
  );
};

export default NavDropdown;
