import * as React from 'react';
import classNames from 'classnames/bind';
import { isUndefined } from 'lodash';
import { FormError } from 'types';
import { getErrorMessage } from 'components/forms/formUtils';
import { Control, Controller } from 'react-hook-form-legacy';

import Tooltip from 'components/v2/atomic/tooltip/Tooltip';
import styles from './SelectInput.module.scss';

const cx = classNames.bind(styles);

interface SelectInputProps {
  name: string;
  control?: Control;
  usePortal?: boolean;
  title?: string;
  disabled?: boolean;
  errorMessage?: string | FormError;
  noMarginTopOnErrorMessage?: boolean;
  hasError?: boolean;
  onBlur?: any;
  onChange?: any;
  children?: React.ReactNode;
  value?: string;
  defaultValue?: string;
  visuallyHiddenLabel?: boolean;
  placeholder?: string;
  tooltip?: string | JSX.Element | Array<JSX.Element | string>;
  testingTag?: string;
  autoTestingTag?: string;
  onFocus?: any;
  inline?: boolean;
}
const SelectInput = React.forwardRef<HTMLSelectElement, SelectInputProps>(
  (
    {
      name,
      title = '',
      value,
      control,
      onChange = () => {},
      onBlur,
      children,
      noMarginTopOnErrorMessage = false,
      hasError,
      errorMessage,
      disabled,
      visuallyHiddenLabel,
      placeholder,
      tooltip,
      testingTag,
      defaultValue,
      autoTestingTag,
      onFocus,
      usePortal,
      inline = false
    }: SelectInputProps,
    ref
  ) => {
    const inputClassNames = cx({
      selectInput: true,
      isFieldError: errorMessage !== undefined || hasError,
      placeholder: !isUndefined(placeholder) && value === placeholder
    });
    const labelClassNames = cx({
      labelText: true,
      visuallyHidden: visuallyHiddenLabel
    });
    if (control) {
      return (
        <Controller
          name={name}
          control={control}
          defaultValue={defaultValue}
          as={
            <div className={styles.selectInputContainer}>
              {' '}
              {(title || tooltip) && (
                <div className={styles.labelTTWrapper}>
                  <label htmlFor={name} className={labelClassNames}>
                    <span>{title}</span>
                  </label>
                  <div>
                    {tooltip && (
                      <Tooltip content={tooltip} usePortal={usePortal} />
                    )}
                  </div>
                </div>
              )}
              <select
                name={name}
                value={value}
                id={name}
                className={inputClassNames}
                defaultValue={defaultValue}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                disabled={disabled}
                ref={ref}
                data-testid={testingTag}
                data-auto={autoTestingTag}
              >
                {placeholder && (
                  <option key="placeholder" value="" disabled>
                    {placeholder}
                  </option>
                )}
                {children}
              </select>
              {errorMessage && (
                <div
                  className={cx({
                    errorMessage: true,
                    noMarginTop: noMarginTopOnErrorMessage
                  })}
                >
                  {getErrorMessage(errorMessage)}
                </div>
              )}
            </div>
          }
        />
      );
    }
    return (
      <div
        className={cx({
          selectInputContainer: true,
          inline
        })}
      >
        {(title || tooltip) && (
          <div className={styles.labelTTWrapper}>
            <label htmlFor={name} className={labelClassNames}>
              <span>{title}</span>
            </label>
            <div>
              {tooltip && <Tooltip content={tooltip} usePortal={usePortal} />}
            </div>
          </div>
        )}
        <select
          name={name}
          value={value}
          defaultValue={defaultValue}
          id={name}
          className={inputClassNames}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
          ref={ref}
          data-testid={testingTag}
          data-auto={autoTestingTag}
        >
          {placeholder && (
            <option key="placeholder" value="" disabled>
              {placeholder}
            </option>
          )}
          {children}
        </select>
        {errorMessage && (
          <div
            className={cx({
              [styles.errorMessage]: true,
              errorMsg: true // global class to allow Table component to remove margin easily
            })}
          >
            {getErrorMessage(errorMessage)}
          </div>
        )}
      </div>
    );
  }
);

export default SelectInput;
