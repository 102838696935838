import { isNull } from 'lodash';

import { EapAction } from 'types';
import {
  MemberSearchResult,
  BrokenService,
  PurchaseCostItem,
  Purchased,
  PurchasedPPLID,
  ExceptionsObject
} from 'interfaces';
import { FormState } from 'containers/requestForm/common/interfaces';
import {
  Form165aData,
  form165aData
} from 'containers/requestForm/leavePeriodEmployer/form165aReducer';
import { mapForm165 } from 'containers/requestForm/common/form165/utils/form165Utils';
import { getSubmissionStatus } from 'utils';
import { form165cActionTypes } from './form165cActions';

// reuse mapper for form165a

export interface Form165cData extends Form165aData {}

export interface Form165cState extends FormState {
  form165cData: Form165cData;
  originalForm165cState: {
    availableBrokenServices: Array<BrokenService>;
    commentsToMember: string | null;
    employeeNo: number | null;
    exceptions: Array<ExceptionsObject>;
    leavePeriods: Array<{
      brokenService: BrokenService;
      purchaseCostList: Array<PurchaseCostItem>;
      purchased?: Purchased;
      pplSharedCostVisibleInd: boolean;
      purchasedPPLID?: PurchasedPPLID;
    }>;
    membershipNo: number | null;
    notes: string | null;
  } | null;
  memberInfo: MemberSearchResult | null;
  isAdjustLeavePeriod: boolean;
  adjustLeavePeriodStep: number | null;
  leavePeriods: Array<{
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
    purchased?: Purchased;
    pplSharedCostVisibleInd: boolean;
    purchasedPPLID?: PurchasedPPLID;
  }>;
  availableBrokenServices: Array<BrokenService>;
  activeLeavePeriod: {
    brokenService: BrokenService;
    purchaseCostList: Array<PurchaseCostItem>;
    purchased?: Purchased;
    pplSharedCostVisibleInd: boolean;
    purchasedPPLID?: PurchasedPPLID;
  } | null;
  activeIntervalNumber: number | null;
  leavePeriodLoading: boolean;
  commentsToMember: string | null;
}

export const form165cData = form165aData;

export const initialState: Form165cState = {
  requestNumber: null,
  initLoading: false,
  formStep: 1,
  formStatus: '' as const,
  submissionStatus: '' as const,
  submissionComplete: false,
  exceptions: [],
  overrideExceptions: [],
  form165cData,
  originalForm165cState: null,
  memberInfo: null,
  isAdjustLeavePeriod: false,
  adjustLeavePeriodStep: null,
  leavePeriods: [],
  availableBrokenServices: [],
  pdfs: [],
  activeLeavePeriod: null,
  activeIntervalNumber: null,
  commentsToMember: null,
  leavePeriodLoading: false,
  notes: null
};

const form165c = (
  state: Form165cState = initialState,
  action: EapAction
): Form165cState => {
  const { type, data } = action;
  switch (type) {
    case form165cActionTypes.SET_REQUEST_NUMBER_FORM_165C:
      return {
        ...initialState,
        requestNumber: data,
        initLoading: true
      };
    case form165cActionTypes.GET_FORM_165C_SUCCESS:
      return {
        ...state,
        employerNo: data.employerNo,
        initLoading: false,
        leavePeriodLoading: false,
        requestNumber: data.requestNumber,
        formStep: 1,
        formStatus: '' as const,
        submissionStatus: '' as const,
        submissionComplete: false,
        exceptions: data.exceptions,
        overrideExceptions: [],
        form165cData: {
          ...initialState.form165cData,
          noteToMember: data.commentsToMember ?? '',
          noteToOmers: data.notes ?? ''
        },
        commentsToMember: data.commentsToMember,
        notes: data.notes,
        memberInfo: data.memberInfo,
        isAdjustLeavePeriod: false,
        adjustLeavePeriodStep: null,
        leavePeriods: data.leavePeriods,
        availableBrokenServices: data.availableBrokenServices,
        activeIntervalNumber: null,
        activeLeavePeriod: null
      };
    case form165cActionTypes.GET_LATEST_FORM_165C_SUCCESS: {
      return {
        ...state,
        leavePeriods: data.leavePeriods,
        availableBrokenServices: data.availableBrokenServices,
        commentsToMember: data.commentsToMember,
        notes: data.notes,
        exceptions: data.exceptions
      };
    }
    case form165cActionTypes.GET_FORM_165C_FAILED:
      return {
        ...state,
        initLoading: true,
        leavePeriodLoading: false
      };
    case form165cActionTypes.GET_ORIGINAL_FORM_STATE_165C_SUCCESS:
      return {
        ...state,
        originalForm165cState: data
      };
    case form165cActionTypes.GET_ORIGINAL_FORM_STATE_165C_FAILED:
      return {
        ...state,
        originalForm165cState: null
      };
    case form165cActionTypes.UPDATE_LEAVE_PERIOD_165C_REQUESTED:
      return {
        ...state,
        leavePeriodLoading: true,
        formStatus: 'saving' as const
      };
    case form165cActionTypes.UPDATE_LEAVE_PERIOD_165C_SUCCESS:
      return {
        ...state,
        form165cData: data.formData,
        leavePeriods: data.leavePeriods,
        activeIntervalNumber: data.intervalNumber,
        leavePeriodLoading: false,
        formStatus: 'success' as const
      };
    case form165cActionTypes.UPDATE_LEAVE_PERIOD_165C_FAILED:
      return {
        ...state,
        leavePeriodLoading: false,
        formStatus: 'error' as const
      };
    case form165cActionTypes.SET_FORM_165C_STEP:
      return {
        ...state,
        formStep: data
      };
    case form165cActionTypes.SET_IS_ADD_LEAVE_PERIOD_165C:
      return {
        ...state,
        isAdjustLeavePeriod: data,
        leavePeriodLoading: false,
        activeIntervalNumber: data ? state.activeIntervalNumber : null,
        adjustLeavePeriodStep: data ? 1 : null
      };
    case form165cActionTypes.SET_ADD_PERIOD_STEP_165C:
      return {
        ...state,
        adjustLeavePeriodStep: data
      };
    case form165cActionTypes.SAVE_FORM_165C_REQUESTED:
    case form165cActionTypes.SAVE_AND_REVIEW_FORM_165C_REQUESTED:
      return {
        ...state,
        formStatus: 'saving' as const
      };
    case form165cActionTypes.SAVE_FORM_165C_SUCCESS:
      return {
        ...state,
        formStatus: 'success' as const,
        form165cData: {
          ...state.form165cData,
          noteToMember: data.commentsToMember ?? '',
          noteToOmers: data.notes ?? ''
        },
        commentsToMember: data.commentsToMember ?? '',
        notes: data.notes ?? ''
      };
    case form165cActionTypes.SAVE_FORM_165C_FAILED:
      return {
        ...state,
        formStatus: 'error' as const
      };
    case form165cActionTypes.SUBMIT_FORM_165C_SUCCESS:
      return {
        ...state,
        exceptions: !data.exceptions ? [] : data.exceptions,
        overrideExceptions: [],
        submissionStatus: getSubmissionStatus(data, data.exceptions),
        submissionComplete:
          getSubmissionStatus(data, data.exceptions) === 'success',
        formStep:
          getSubmissionStatus(data, data.exceptions) === 'success' ? 3 : 2,
        pdfs: data.attachments,
        initLoading: true
      };
    case form165cActionTypes.SET_FORM_165C_SUBMIT_STATUS:
      return {
        ...state,
        submissionStatus: data
      };
    case form165cActionTypes.DELETE_FORM_165C_SUCCESS:
      return {
        ...initialState,
        formStatus: 'deleted' as const
      };
    case form165cActionTypes.OVERRIDE_FORM_165C_EXCEPTIONS:
      return {
        ...state,
        overrideExceptions: data
      };
    case form165cActionTypes.SET_FORM_165C_SAVING_STATUS:
      return {
        ...state,
        formStatus: data
      };
    case form165cActionTypes.SET_EDIT_ADJUST_LEAVE_PERIOD_FORM_165C: {
      const activeLeavePeriod =
        (data
          ? state.leavePeriods.find(
              (leavePeriod: { brokenService: BrokenService }) =>
                leavePeriod.brokenService.intervalNumber === data
            )
          : null) ?? null;

      return {
        ...state,
        isAdjustLeavePeriod: !isNull(data),
        adjustLeavePeriodStep: !isNull(data) ? 1 : null,
        activeIntervalNumber: data,
        activeLeavePeriod,
        form165cData: mapForm165(activeLeavePeriod, {
          noteToMember: !isNull(data) ? null : state?.commentsToMember ?? '',
          noteToOmers: !isNull(data) ? null : state?.notes ?? ''
        })
      };
    }
    case form165cActionTypes.CLEAR_FORM_165C_STATUS:
      return {
        ...state,
        formStatus: '' as const
      };
    default:
      return state;
  }
};

export default form165c;
