import Button from 'components/button/Button';
import classNames from 'classnames/bind';
import { useRouter } from 'next/router';
import StatusIndicator from 'components/v2/atomic/statusIndicator/StatusIndicator';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import styles from './LinkList.module.scss';
import { LinkItemType } from './types';

const cx = classNames.bind(styles);

export const DefaultLinkListItem = ({
  item,
  disableLinks
}: {
  item: LinkItemType;
  disableLinks: boolean;
}) => {
  const Router = useRouter();
  const { t } = useTranslation('forms');
  return (
    <Button
      baseStyle="link"
      onClick={() => {
        if (item.callback) {
          item.callback();
        } else {
          Router.push(`/form/${item.formNumber}`);
        }
      }}
      testingTag={`link-list-item-${item.formNumber}`}
      isDisabled={disableLinks}
      style={styles.hideLinkUnderlines}
    >
      <a className={styles.container}>
        <div className={styles.form}>
          <span
            className={cx({
              number: true,
              largeMargin: item.overrideNumber
                ? item.overrideNumber.length === 3
                : item.formNumber.length === 3,
              mediumMargin: item.overrideNumber
                ? item.overrideNumber.length === 4
                : item.formNumber.length === 4
            })}
          >
            {item.overrideNumber ?? item.formNumber}
          </span>
          <span className={styles.title}>{t(item.formName)}</span>
          {item.tag && (
            <span>
              <StatusIndicator
                id="info-tag"
                text={t(`${item.tag}`)}
                intent="info"
                type="tag"
              />
            </span>
          )}
        </div>
        {!disableLinks && <ReactSVG src="/images/chevron-right.svg" />}
      </a>
    </Button>
  );
};
