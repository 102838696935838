import { useSelector } from 'react-redux';

import { RootState } from 'types';
import Notification from 'components/notification/Notification';

interface CommonNotificationProps {
  scrollTo?: boolean;
  noMargin?: boolean;
  marginTop?: boolean;
  marginBottom?: boolean;
  hide?: boolean;
}
const CommonNotification = ({
  scrollTo = true,
  noMargin = true,
  marginBottom = true,
  marginTop = false,
  hide = false
}: CommonNotificationProps) => {
  const { showNotification, notification } = useSelector(
    (state: RootState) => state.notifications
  );

  return showNotification && !hide ? (
    <Notification
      type={notification?.type}
      messages={[notification?.message]}
      scrollTo={scrollTo}
      noMargin={noMargin}
      marginBottom={marginBottom}
      marginTop={marginTop}
    />
  ) : null;
};

export default CommonNotification;
