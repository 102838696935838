import { EapAction } from 'types';
import { returnedRequestsActionTypes } from 'containers/returnedRequests/ReturnedRequestsActions';
import { filterValues } from 'utils/constants';
import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import {
  IReturnedRequestsMessage,
  IReturnedRequestsButtonStatus,
  IMessageThread,
  IMessageError
} from './types';
import { defaultValues } from './returnedRequestsFilter/ReturnedRequestFilter';

export interface ReturnedRequestsState {
  messages: IReturnedRequestsMessage[];
  isLoading: boolean;
  buttonStatus: IReturnedRequestsButtonStatus;
  filters: {
    employeeId: string;
    status: filterValues;
    topic: string;
  };
  correspondenceNumber: string;
  messageThread: IMessageThread[];
  messageError: IMessageError;
}

export const initialState = {
  messages: [],
  isLoading: false,
  buttonStatus: {
    isResetButtonDisabled: true,
    isApplyButtonDisabled: true,
    isApplyButtonClicked: false
  },
  filters: {
    ...defaultValues
  },
  correspondenceNumber: '',
  messageThread: [],
  messageError: {
    showError: false,
    message: ''
  }
};

const returnedRequests = (
  state: ReturnedRequestsState = initialState,
  action: EapAction
): ReturnedRequestsState => {
  const { type, data } = action;
  switch (type) {
    case returnedRequestsActionTypes.GET_RETURNED_REQUESTS_REQUESTED:
      return {
        ...state,
        messages: initialState.messages,
        isLoading: true,
        messageError: initialState.messageError
      };
    case returnedRequestsActionTypes.GET_RETURNED_REQUESTS_SUCCEEDED:
      return {
        ...state,
        messages: data,
        isLoading: false,
        messageError: initialState.messageError
      };
    case returnedRequestsActionTypes.SET_RETURNED_REQUESTS_BUTTON_STATUS: {
      return {
        ...state,
        buttonStatus: {
          ...state.buttonStatus,
          isApplyButtonClicked: data.isApplyButtonClicked
            ? data.isApplyButtonClicked
            : false,
          isResetButtonDisabled: data.isResetButtonDisabled,
          isApplyButtonDisabled: data.isApplyButtonDisabled
        }
      };
    }
    case returnedRequestsActionTypes.APPLY_RETURNED_REQUESTS_FILTER_REQUESTED: {
      return {
        ...state,
        filters: data,
        isLoading: true,
        buttonStatus: {
          ...initialState.buttonStatus
        }
      };
    }
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
      return {
        ...initialState,
        isLoading: false,
        messageError: initialState.messageError
      };
    case returnedRequestsActionTypes.GET_RETURNED_REQUESTS_FAILED:
      return {
        ...state,
        messages: initialState.messages,
        isLoading: false,
        messageError: initialState.messageError
      };
    case returnedRequestsActionTypes.SET_CORRESPONDENCE_NUMBER:
      return {
        ...state,
        messages: state.messages,
        correspondenceNumber: data,
        messageError: initialState.messageError
      };
    case returnedRequestsActionTypes.GET_RETURNED_REQUESTS_MESSAGE_THREAD_SUCCEEDED:
      return {
        ...state,
        messages: state.messages,
        messageThread: data,
        messageError: initialState.messageError
      };
    case returnedRequestsActionTypes.SEND_REPLY_REQUESTED_FAILED:
      return {
        ...state,
        messages: state.messages,
        messageError: { showError: true, message: data }
      };
    default:
      return state;
  }
};

export default returnedRequests;
