import { EapAction } from 'types';
import { navHistoryActionTypes } from './navHistoryActions';

interface NavHistoryState {
  navBackBtnFallbackRoute: string | null;
  history: Array<string | null>;
}

const initialState = {
  navBackBtnFallbackRoute: null,
  history: [null]
};

const navHistory = (
  state: NavHistoryState = initialState,
  action: EapAction
): NavHistoryState => {
  const { type, data } = action;

  switch (type) {
    case navHistoryActionTypes.SET_NAV_BACK_BTN_FALLBACK_ROUTE:
      return {
        ...state,
        navBackBtnFallbackRoute: data
      };
    case navHistoryActionTypes.CLEAR_NAV_HISTORY:
      return {
        ...state,
        history: [null]
      };
    case navHistoryActionTypes.PUSH_NAV_HISTORY:
      return {
        ...state,
        history: state.history.concat(data)
      };
    default:
      return state;
  }
};

export default navHistory;
