import {
  all,
  takeLeading,
  takeLatest,
  put,
  select,
  call,
  delay
} from 'redux-saga/effects';
import { format } from 'url';
import Router from 'next/router';

import { EapAction, RootState } from 'types';

import {
  getApiErrorMessage,
  formatDateUTC,
  formatCurrencyStringToNumber
} from 'utils';
import { parse, format as formatDate } from 'date-fns';

import {
  createForm105,
  getForm105,
  saveForm105,
  saveForm105Summary,
  searchServicePurchases,
  addReportedServicePurchase,
  addUnreportedServicePurchase,
  removeAddedServicePurchaseForm105,
  memberSearch,
  getSummaryForForm105,
  submitForm105
} from 'services';

import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';

import {
  lockFormSaga,
  clearLockedFormNotificationSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';

import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import {
  notifyErrorAction,
  notifyLockedFormErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';

import {
  clearForm105StatusAction,
  form105ActionTypes,
  getForm105FailedAction,
  getForm105SuccessAction,
  removeAddedServicePurchaseForm105FailedAction,
  removeAddedServicePurchaseForm105SuccessAction,
  saveForm105FailedAction,
  saveForm105SuccessAction,
  setForm105StepAction,
  searchServicePurchasesFailedAction,
  searchServicePurchasesSucceedAction,
  addSelectedReportedServicePurchaseSucceedAction,
  addSelectedReportedServicePurchaseFailedAction,
  applyServicePurchaseMemberFilter,
  refreshServicePurchaseAction,
  refreshServicePurchaseSucceedAction,
  refreshServicePurchaseFailedAction,
  searchMemberForAddingServicePurchaseAction,
  searchMemberForAddingServicePurchaseSucceedAction,
  searchMemberForAddingServicePurchaseFailedAction,
  addUnreportedServicePurchaseSucceedAction,
  addUnreportedServicePurchaseFailedAction,
  getSummaryModelForForm105Action,
  getSummaryModelForForm105SuccessAction,
  getSummaryModelForForm105FailedAction,
  submitForm105Action,
  setForm105SubmitStatusAction,
  submitForm105SuccessAction,
  getExceptionsForm105SuccessAction
} from './form105Actions';
import { buildCreateUpdateRequestModel } from './utils/form105Utils';
import { SEARCH_BY_MEMBER } from './contributionRemittanceInformation/servicePurchase/ServicePurchaseMemberFilter';

export function* createForm105Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm105, action.data);

  if (res.data) {
    // route user to newly created 105
    yield call(
      Router.push,
      '/form/105/[id]',
      format({ pathname: `/form/105/${res.data.requestNumber}` })
    );
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* getForm105Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  // fetch 105 form
  const res = yield call(safeAuthEffect, getForm105, requestNumber);

  if (res.data) {
    yield put(
      getForm105SuccessAction({
        employerNo: res.data?.e105Model?.employerNo,
        requestNumber,
        contributionModel: res.data?.e105Model?.contributionModel ?? [],
        paymentScheduleModels: res.data?.e105Model?.paymentScheduleModels ?? [],
        servicePurchaseReportedModels:
          res.data?.e105Model?.servicePurchaseReportedModels ?? [],
        servicePurchaseUnreportedModels:
          res.data?.e105Model?.servicePurchaseUnreportedModels ?? [],
        paymentOwingModels: res.data?.e105Model?.paymentOwingModels ?? [],
        summaryModel: res.data?.e105Model?.summaryModel ?? [],
        commentsToMember: res.data?.e105Model?.commentsToMember ?? '',
        notes: res.data?.e105Model?.notes ?? '',
        exceptions: res.data?.e105Model?.exceptions ?? [],
        showLateRemittanceChargesMsg:
          res.data?.e105Model?.showLateRemittanceChargesMsg ?? false,
        remittanceType:
          res.data?.e105Model?.contributionModel?.remittanceType ?? '',
        formMonth: res.data?.e105Model?.contributionModel?.formMonth ?? '',
        paymentMethod:
          res.data?.e105Model?.contributionModel?.paymentMethod ?? '',
        paymentsOwingTotalAmt:
          res.data?.e105Model?.paymentsOwingTotalAmt ?? null
      })
    );

    // route user to form if not already there
    if (Router.route !== '/form/105/[id]') {
      yield call(
        Router.push,
        '/form/105/[id]',
        format({ pathname: `/form/105/${requestNumber}` })
      );
    }
  } else {
    yield put(getForm105FailedAction());
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(res?.errors)));

    // navigate off the request if user is on the page from a direct link
    if (Router.route === '/form/105/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());
  yield call(clearLockedFormNotificationSaga);
}

export function* removeAddedServicePurchaseForm105Saga(action: EapAction): any {
  yield put(startLoadingAction());
  const { requestNumber } = yield select((state: RootState) => state.form105);

  // remove service purchase 105 form
  const res = yield call(
    safeAuthEffect,
    removeAddedServicePurchaseForm105,
    { selectedLineNumbers: action.data.selectedLineNumbers },
    requestNumber
  );

  if (res.status === 204) {
    yield put(
      removeAddedServicePurchaseForm105SuccessAction(
        action.data.selectedLineNumbers
      )
    );
  } else {
    yield put(removeAddedServicePurchaseForm105FailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
  }
  yield put(clearLoadingAction());
}

export function* saveForm105Saga(action: EapAction): any {
  const {
    requestNumber,
    formStep,
    remittanceType,
    form105Data,
    formMonth
  } = yield select((state: RootState) => state.form105);

  const payload =
    formStep === 1
      ? buildCreateUpdateRequestModel(
          action.data.formData,
          formStep,
          requestNumber,
          remittanceType,
          form105Data,
          formMonth
        )
      : {
          notes: action.data.notes,
          exceptions: action?.data?.exceptions,
          commentsToMember: action?.data?.commentsToMember,
          contributionModel: {
            requestNumber,
            paymentMethod:
              action.data.formData.summaryContributionModel.paymentMethod,
            groupNo:
              action.data.formData.summaryContributionModel.paymentMethod ===
              'P'
                ? action.data.formData.summaryContributionModel.groupNo
                : null,
            offsetAmt: Number(
              (
                formatCurrencyStringToNumber(
                  action.data.formData.summaryModel?.originalInvoiceTotalAmt ??
                    '0.00'
                ) -
                formatCurrencyStringToNumber(
                  action.data.formData.summaryModel?.adjustedInvoiceTotalAmt
                )
              ).toFixed(2)
            ),
            bankPaymentDate: formatDateUTC(
              action.data.formData.summaryContributionModel.bankPaymentDate
            ),
            totalPaidAmt:
              action.data.formData.summaryContributionModel.paymentMethod !==
              'P'
                ? formatCurrencyStringToNumber(
                    action.data.formData.summaryContributionModel.totalPaidAmt
                  )
                : null,
            company:
              action.data.formData.summaryContributionModel.paymentMethod ===
              'D'
                ? action.data.formData.summaryContributionModel.company
                : null,
            locationNo:
              action.data.formData.summaryContributionModel.paymentMethod ===
              'D'
                ? action.data.formData.summaryContributionModel.locationNo
                : null,
            confirmationNo:
              action.data.formData.summaryContributionModel.paymentMethod ===
              'D'
                ? action.data.formData.summaryContributionModel.confirmationNo
                : null
          }
        };

  const res =
    formStep === 1
      ? yield call(
          safeAuthEffect,
          saveForm105,
          {
            commentsToMember:
              action.data.commentsToMember?.length > 0
                ? action.data.commentsToMember
                : null,
            notes: action.data.notes?.length > 0 ? action.data.notes : null,
            exceptions: action.data.exceptions,
            ...payload
          },
          requestNumber
        )
      : yield call(safeAuthEffect, saveForm105Summary, payload, requestNumber);

  if (res.data) {
    yield put(saveForm105SuccessAction(action.data, payload));
    yield put(clearNotifications());

    if (action.data.reviewForm) {
      yield put(getSummaryModelForForm105Action(requestNumber));
    }

    if (action.data.submitForm) {
      yield put(submitForm105Action());
    }
  } else {
    yield put(saveForm105FailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* applyServicePurchaseMemberFilterSaga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form105);
  const { selectEmployerNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );
  const filterPayload = {
    sin:
      action.data.searchByMemberSelect === SEARCH_BY_MEMBER[1].val &&
      action.data.searchByMemberValue !== ''
        ? action.data.searchByMemberValue.replace(/\s/g, '')
        : null,
    employeeId:
      action.data.searchByMemberSelect === SEARCH_BY_MEMBER[0].val &&
      action.data.searchByMemberValue !== ''
        ? action.data.searchByMemberValue
        : null,
    purchaseType:
      action.data.typeOfPurchase === '' ? null : action.data.typeOfPurchase,
    fromDate:
      action.data.dateReceivedFrom === ''
        ? null
        : formatDate(
            parse(action.data.dateReceivedFrom, 'MM/dd/yyyy', new Date()),
            'MMM-dd-yyyy'
          ),
    toDate:
      action.data.dateReceivedTo === ''
        ? null
        : formatDate(
            parse(action.data.dateReceivedTo, 'MM/dd/yyyy', new Date()),
            'MMM-dd-yyyy'
          )
  };

  const res = yield call(
    safeAuthEffect,
    searchServicePurchases,
    filterPayload,
    requestNumber
  );

  if (action.data.searchByMemberValue !== '') {
    yield put(
      searchMemberForAddingServicePurchaseAction({
        type: 'profile',
        sin: filterPayload.sin,
        employeeId: filterPayload.employeeId,
        employerNumber: selectEmployerNumber
      })
    );
  }

  if (res.data) {
    yield put(searchServicePurchasesSucceedAction(res.data));
    yield put(clearNotifications());
  } else if (
    res.errors &&
    res.errors.length > 0 &&
    (res.errors[0].code === '12263' ||
      res.errors[0].code === '12260' ||
      res.errors[0].code === '205')
  ) {
    yield put(
      searchServicePurchasesSucceedAction({
        requestSearchResult: {
          totalRowCount: 0,
          servicePurchases: []
        }
      })
    );
    yield put(clearNotifications());
  } else {
    yield put(searchServicePurchasesFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* addSelectedReportedServicePurchaseSaga(
  action: EapAction
): any {
  yield put(startLoadingAction());

  const {
    requestNumber,
    form105Data: { servicePurchaseFilter }
  } = yield select((state: RootState) => state.form105);

  const res = yield call(
    safeAuthEffect,
    addReportedServicePurchase,
    {
      sin:
        servicePurchaseFilter.searchByMemberSelect ===
          SEARCH_BY_MEMBER[1].val &&
        servicePurchaseFilter.searchByMemberValue !== ''
          ? servicePurchaseFilter.searchByMemberValue.replace(/\s/g, '')
          : null,
      employeeId:
        servicePurchaseFilter.searchByMemberSelect ===
          SEARCH_BY_MEMBER[0].val &&
        servicePurchaseFilter.searchByMemberValue !== ''
          ? servicePurchaseFilter.searchByMemberValue
          : null,
      purchaseType:
        servicePurchaseFilter.typeOfPurchase === ''
          ? null
          : servicePurchaseFilter.typeOfPurchase,
      fromDate:
        servicePurchaseFilter.dateReceivedFrom === ''
          ? null
          : formatDate(
              parse(
                servicePurchaseFilter.dateReceivedFrom,
                'MM/dd/yyyy',
                new Date()
              ),
              'MMM-dd-yyyy'
            ),
      toDate:
        servicePurchaseFilter.dateReceivedTo === ''
          ? null
          : formatDate(
              parse(
                servicePurchaseFilter.dateReceivedTo,
                'MM/dd/yyyy',
                new Date()
              ),
              'MMM-dd-yyyy'
            ),
      selectedPurchaseNumbers: action.data.servicePurchasesToBeAdded
    },
    requestNumber
  );

  if (res.data) {
    yield put(addSelectedReportedServicePurchaseSucceedAction());
    yield put(clearNotifications());
    yield put(refreshServicePurchaseAction());
    yield put(applyServicePurchaseMemberFilter({ ...servicePurchaseFilter }));
  } else {
    yield put(addSelectedReportedServicePurchaseFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

export function* refreshServicePurchaseSaga(): any {
  yield put(startLoadingAction());

  const { requestNumber } = yield select((state: RootState) => state.form105);

  const res = yield call(safeAuthEffect, getForm105, requestNumber);

  if (res.data) {
    yield put(
      refreshServicePurchaseSucceedAction({
        servicePurchaseReportedModels:
          res.data?.e105Model?.servicePurchaseReportedModels ?? [],
        servicePurchaseUnreportedModels:
          res.data?.e105Model?.servicePurchaseUnreportedModels ?? []
      })
    );
  } else {
    yield put(refreshServicePurchaseFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
  }
  yield put(clearLoadingAction());
}

export function* searchMemberForAddingServicdPurchaseSaga(
  action: EapAction
): any {
  const res = yield call(safeAuthEffect, memberSearch, action.data);

  if (res.data) {
    yield put(searchMemberForAddingServicePurchaseSucceedAction(res.data));
    yield put(clearNotifications());
  } else {
    yield put(searchMemberForAddingServicePurchaseFailedAction());
  }
}

export function* addUnreportedServicePurchaseSaga(): any {
  yield put(startLoadingAction());

  const {
    requestNumber,
    form105Data: { servicePurchaseFilter }
  } = yield select((state: RootState) => state.form105);

  const res = yield call(
    safeAuthEffect,
    addUnreportedServicePurchase,
    {
      sin:
        servicePurchaseFilter.searchByMemberSelect ===
          SEARCH_BY_MEMBER[1].val &&
        servicePurchaseFilter.searchByMemberValue !== ''
          ? servicePurchaseFilter.searchByMemberValue.replace(/\s/g, '')
          : null,
      employeeId:
        servicePurchaseFilter.searchByMemberSelect ===
          SEARCH_BY_MEMBER[0].val &&
        servicePurchaseFilter.searchByMemberValue !== ''
          ? servicePurchaseFilter.searchByMemberValue
          : null,
      purchaseType: null,
      fromDate: null,
      toDate: null
    },
    requestNumber
  );

  if (res.data) {
    yield put(addUnreportedServicePurchaseSucceedAction());
    yield put(clearNotifications());
    yield put(refreshServicePurchaseAction());
  } else {
    yield put(addUnreportedServicePurchaseFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
  yield put(clearLoadingAction());
}

export function* clearForm105StatusSaga() {
  yield delay(3000);
  yield put(clearForm105StatusAction());
}

export function* getSummaryForForm105Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;
  const { selectEmployerName } = yield select(
    (state: RootState) => state.selectEmployers
  );

  // fetch 105 form
  const res = yield call(safeAuthEffect, getSummaryForForm105, requestNumber);

  if (res.data) {
    yield put(
      getSummaryModelForForm105SuccessAction(
        res.data?.e105SummaryModel,
        selectEmployerName
      )
    );
    yield put(setForm105StepAction(2));
  } else {
    yield put(getSummaryModelForForm105FailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* getExceptionsForm105Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;
  const { selectEmployerName } = yield select(
    (state: RootState) => state.selectEmployers
  );

  // fetch 105 form
  const res = yield call(safeAuthEffect, getSummaryForForm105, requestNumber);

  if (res.data) {
    yield put(
      getExceptionsForm105SuccessAction(
        res.data?.e105SummaryModel,
        selectEmployerName
      )
    );
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* submitForm105Saga(): any {
  yield put(startLoadingAction());
  yield put(setForm105SubmitStatusAction('submitting'));

  const { requestNumber } = yield select((state: RootState) => state.form105);

  const res = yield call(safeAuthEffect, submitForm105, requestNumber);

  if (res?.data?.submitResponse) {
    yield put(clearNotifications());
    yield put(submitForm105SuccessAction(res.data.submitResponse));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm105SubmitStatusAction(''));
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(setForm105SubmitStatusAction('error'));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm105SubmitStatusAction(''));
  }
}

export function* form105Saga(): any {
  yield all([
    yield takeLeading(form105ActionTypes.CREATE_FORM_105, createForm105Saga),
    yield takeLatest(form105ActionTypes.GET_FORM_105_REQUESTED, getForm105Saga),
    yield takeLatest(
      form105ActionTypes.REMOVE_ADDED_SERVICE_PURCHASE_FORM_105_REQUESTED,
      removeAddedServicePurchaseForm105Saga
    ),
    yield takeLatest(form105ActionTypes.SAVE_FORM_105, saveForm105Saga),
    yield takeLatest(
      [
        form105ActionTypes.SAVE_FORM_105_SUCCESS,
        form105ActionTypes.SAVE_FORM_105_FAILED
      ],
      clearForm105StatusSaga
    ),
    yield takeLatest(
      form105ActionTypes.APPLY_SERVICE_PURCHASE_MEMBER_FILTER,
      applyServicePurchaseMemberFilterSaga
    ),
    yield takeLatest(
      form105ActionTypes.ADD_SELECTED_REPORTED_SERVICE_PURCHASE_REQUESTED,
      addSelectedReportedServicePurchaseSaga
    ),
    yield takeLatest(
      form105ActionTypes.REFRESH_SERVICE_PURCHASE_REQUESTED,
      refreshServicePurchaseSaga
    ),
    yield takeLatest(
      form105ActionTypes.SEARCH_MEMBER_FOR_ADDING_SERVICE_PURCHASE_REQUESTED,
      searchMemberForAddingServicdPurchaseSaga
    ),
    yield takeLatest(
      form105ActionTypes.ADD_UNREPORTED_SERVICE_PURCHASE_REQUESTED,
      addUnreportedServicePurchaseSaga
    ),
    yield takeLatest(
      form105ActionTypes.GET_SUMMARY_MODEL_FORM_105,
      getSummaryForForm105Saga
    ),
    yield takeLatest(
      form105ActionTypes.GET_FORM_105_EXCEPTIONS,
      getExceptionsForm105Saga
    ),
    yield takeLatest(form105ActionTypes.SUBMIT_FORM_105, submitForm105Saga)
  ]);
}

export default form105Saga;
