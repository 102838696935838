import { InsertIcon } from 'components/v2/contentful/richText/richTextOptions';
import { isEmpty } from 'lodash';
import Icon from '../../icon/Icon';

const CardTitleDecoration = ({
  hasTitleDecoration,
  titleDecorationIcon,
  titleDecorationText
}: {
  hasTitleDecoration?: boolean;
  titleDecorationIcon?: InsertIcon;
  titleDecorationText?: string;
}) => {
  if (hasTitleDecoration && !isEmpty(titleDecorationIcon)) {
    const { iconName, size, color: iconColor } = titleDecorationIcon.fields;
    return (
      <div sx={{ paddingBottom: '20px' }}>
        <Icon icon={iconName} size={size} color={iconColor} />
      </div>
    );
  }
  if (hasTitleDecoration && !isEmpty(titleDecorationText)) {
    return (
      <div
        sx={{
          color: 'brandNavy100',
          fontSize: '28px',
          lineHeight: '42px'
        }}
      >
        {titleDecorationText}
      </div>
    );
  }
  return null;
};

export default CardTitleDecoration;
