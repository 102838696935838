import classNames from 'classnames/bind';
import { isArray, map } from 'lodash';
import * as React from 'react';
// TODO: Refactor to remove react-bootstrap deps
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tip from 'react-bootstrap/Tooltip';
import { ReactSVG } from 'react-svg';
import useBreakpoint from 'utils/hooks/deprecated/useBreakpoint';
import styles from './ToolTip.module.scss';

const cx = classNames.bind(styles);

interface ToolTipProps {
  label?: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
  content: string | Array<string> | React.ReactNode;
  isThinTooltip?: boolean;
  trigger?: 'hover' | 'click';
  baseStyle?: '' | 'blue' | 'border-grey' | 'border-none';
  iconStyle?: '' | 'grey';
  children?: any;
  icon?: string;
  OverlayElement?: React.ReactNode;
  canFlip?: boolean;
  testingTag?: string;
  className?: string;
}

const ToolTip = ({
  label = '',
  content,
  placement = 'right',
  isThinTooltip = false,
  trigger = 'click',
  baseStyle = '',
  iconStyle = '',
  children,
  OverlayElement,
  icon = '/images/question-circle.svg',
  canFlip = true,
  testingTag,
  className
}: ToolTipProps) => {
  const toolTipContainerClass = cx(
    {
      toolTip: true,
      noMarginLeft: !!OverlayElement
    },
    className && { [className]: true }
  );
  const toolTipClasses = cx({
    tip: true,
    popoverFlickeringFix: trigger === 'hover',
    thinTooltip: isThinTooltip,
    solidBlue: baseStyle === 'blue',
    borderGrey: baseStyle === 'border-grey',
    borderNone: baseStyle === 'border-none'
  });
  const [activeBreakpoint, breakpoints] = useBreakpoint();

  const renderToolTip = (props: { show: boolean }) => (
    <Tip id="tooltip" className={toolTipClasses} {...props}>
      {label && <div className={styles.toolTipLabel}>{label}</div>}
      <div className={styles.toolTipContent}>
        {isArray(content)
          ? map(content, (line, key) => <div key={key}>{line}</div>)
          : content}
      </div>
    </Tip>
  );

  return (
    <div className={toolTipContainerClass}>
      <OverlayTrigger
        trigger={trigger === 'hover' ? ['hover', 'focus'] : 'click'}
        placement={
          breakpoints[activeBreakpoint] <= breakpoints.small ? 'top' : placement
        }
        overlay={renderToolTip}
        rootClose
        flip={canFlip}
        defaultShow={!!OverlayElement}
      >
        {children || OverlayElement || (
          <button
            type="button"
            className={cx({
              toolTipIcon: true,
              grey: iconStyle === 'grey'
            })}
            data-testid={testingTag}
          >
            <ReactSVG src={icon} className={styles.toolTipIcon} />
          </button>
        )}
      </OverlayTrigger>
    </div>
  );
};

export default ToolTip;
