import { createSlice } from '@reduxjs/toolkit';

export interface Form152State {
  showFormGuardModal: boolean;
}

const initialState: Form152State = {
  showFormGuardModal: false
};

const form152Slice = createSlice({
  name: 'form152',
  initialState,
  reducers: {
    showFormGuardModalAction: (draft, { payload }) => {
      draft.showFormGuardModal = payload;
    }
  }
});

export const { showFormGuardModalAction } = form152Slice.actions;

export default form152Slice.reducer;
