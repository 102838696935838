import { isBefore } from 'date-fns';

import { getTimeZoneSafeDate } from 'utils';
import { convertDateYMDtoMDY } from 'utils/date';

export const filterEmploymentPeriods = (
  employmentPeriods: any[],
  lastPostedServicePeriodEndDate: string
) => {
  if (lastPostedServicePeriodEndDate) {
    return employmentPeriods.filter(item => {
      if (item.effectiveToDate) {
        const effectiveToDate = getTimeZoneSafeDate(item.effectiveToDate);
        const lastPostedDate = getTimeZoneSafeDate(
          lastPostedServicePeriodEndDate
        );
        return !isBefore(effectiveToDate, lastPostedDate);
      }
      if (item.effectiveFromDate && !item.effectiveToDate) {
        return true;
      }
      return false;
    });
  }
  return employmentPeriods || [];
};

export const getEmploymentPeriodsFromData = (
  employmentPeriodsAndOccupationModel: any[]
) =>
  employmentPeriodsAndOccupationModel.map(item => ({
    effectiveFromDate: convertDateYMDtoMDY(item.hashMap.PERIOD_START_DATE),
    effectiveToDate: convertDateYMDtoMDY(item.hashMap.PERIOD_END_DATE),
    nra: item.hashMap.NRA || '',
    employmentStatus: item.hashMap.EMPLOYMENT_STATUS || '',
    occupation: item.hashMap.OCCUPATION_CODE || '',
    departmentId: item.hashMap.DEPARTMENT_ID || '',
    periodNumber: item.hashMap.EMPLOYMENT_PERIOD_NO,
    memberAffiliation: item.hashMap.PSS_AFFILIATION || '',
    actionType: item.hashMap.ACTION_TYPE || ''
  }));

const mapForm106 = (form: any) => {
  const employmentPeriods = getEmploymentPeriodsFromData(
    form.e106Model.employmentPeriodsAndOccupationModel
  );
  const filteredEmploymentPeriods = filterEmploymentPeriods(
    employmentPeriods,
    form.e106Model.lastPostedServicePeriodEndDate
  );
  const activeEmploymentPeriod =
    filteredEmploymentPeriods?.length > 0
      ? filteredEmploymentPeriods[filteredEmploymentPeriods?.length - 1]
      : {};

  return {
    sin: form.e106Model.employeeModelCurrent?.sin?.toString() || '',
    employeeId: form.e106Model?.employeeModelCurrent?.employeeId || '',
    firstName: form.e106Model.employeeModelCurrent?.firstName || '',
    middleName: form.e106Model.employeeModelCurrent?.middleName || '',
    lastName: form.e106Model.employeeModelCurrent?.lastName || '',
    sex: form.e106Model.employeeModelCurrent?.sex || '',
    birthDate: convertDateYMDtoMDY(
      form.e106Model.employeeModelCurrent?.birthDate
    ),
    hireDate: convertDateYMDtoMDY(form.e106Model.employmentModel?.hireDate),
    electionDate: convertDateYMDtoMDY(
      form.e106Model.employeeModelCurrent?.electionDate
    ),
    enrolDate: convertDateYMDtoMDY(
      form.e106Model.employmentModel?.enrolmentDate
    ),
    enrolReason: form.e106Model?.employeeModelCurrent?.enrolReason,
    employmentPeriods: filteredEmploymentPeriods,
    noteToOmers: form.e106Model.notes,
    lastPostedServicePeriodStartDate:
      form.e106Model.lastPostedServicePeriodStartDate,
    lastPostedServicePeriodEndDate:
      form.e106Model.lastPostedServicePeriodEndDate,
    activeEffectiveFromDate: activeEmploymentPeriod?.effectiveFromDate || '',
    activeEffectiveToDate: activeEmploymentPeriod?.effectiveToDate || '',
    activeNra: activeEmploymentPeriod.nra || '',
    activeEmploymentStatus: activeEmploymentPeriod?.employmentStatus || '',
    activeOccupation: activeEmploymentPeriod?.occupation || '',
    activeDepartmentId: activeEmploymentPeriod?.departmentId || '',
    activeMemberAffiliation: activeEmploymentPeriod?.memberAffiliation || '',
    newPeriodCreated: false
  };
};

export default mapForm106;
