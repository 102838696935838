import Button from 'components/v2/atomic/button/Button';
import Icon, { IconName } from 'components/v2/atomic/icon/Icon';
import useBreakpoint from 'utils/hooks/useBreakpoint';
import { useRouter } from 'next/router';
import Drawer from 'components/v2/atomic/drawer/Drawer';
import { Classes } from '@blueprintjs/core';
import { NavMenuItem } from './MainNav';
import AccordionMenuItem from '../accordionMenuItem/AccordionMenuItem';

export enum BurgerMenuItemType {
  LINK = 'link',
  BUTTON = 'button',
  DROPDOWN = 'dropdown',
  STYLED_TEXT = 'styledText',
  PLAIN_TEXT = 'plainText'
}

const menuItemStyle = {
  display: 'flex',
  width: '100%',
  justifyContent: 'left',
  padding: ['1rem 2rem', '1rem 1.5rem', null],
  alignItems: 'center',
  gap: '0.75rem',
  color: 'brandNavy100',
  fontFamily: 'body',
  fontSize: 'small',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: 'base',
  textDecoration: 'none',
  svg: {
    color: 'brandNavy100'
  },
  '&:focus-visible': {
    outlineColor: 'extendedBlue100',
    outlineOffset: '-2px'
  },
  '&:hover': {
    backgroundColor: 'extendedBlue25',
    textDecoration: 'none'
  },
  '&:active': {
    color: 'extendedBlue100',
    textDecoration: 'none',
    svg: {
      color: 'extendedBlue100'
    }
  },
  borderBottom: `1px solid`,
  borderBottomColor: 'grey40'
};

const BurgerMenuLink = ({
  id,
  title,
  icon,
  url,
  openInNewTab = false,
  setIsBurgerMenuOpen
}: {
  id: string;
  title: string;
  icon?: IconName;
  url: string;
  openInNewTab?: boolean;
  setIsBurgerMenuOpen: (isOpen: boolean) => void;
}) => {
  const Router = useRouter();

  return (
    <Button
      id={id}
      sx={{
        ...menuItemStyle
      }}
      variant="simple"
      onClick={() => {
        if (openInNewTab) {
          window.open(url, '_blank');
        } else {
          Router.push(url);
        }
        setIsBurgerMenuOpen(false);
      }}
    >
      {icon && <Icon icon={icon} />}
      {title}
    </Button>
  );
};

const BurgerMenuButton = ({
  id,
  title,
  icon,
  onClick,
  setIsBurgerMenuOpen
}: {
  id: string;
  title: string;
  icon?: IconName;
  onClick: () => void;
  setIsBurgerMenuOpen: (isOpen: boolean) => void;
}) => (
  <Button
    id={id}
    onClick={() => {
      if (onClick) {
        onClick();
      }
      setIsBurgerMenuOpen(false);
    }}
    variant="simple"
    sx={{
      ...menuItemStyle
    }}
  >
    {icon && <Icon icon={icon} />}
    {title}
  </Button>
);

const BurgerMenuText = ({
  id,
  title,
  icon
}: {
  id: string;
  title: string;
  icon?: IconName;
}) => (
  <div
    id={id}
    sx={{
      ...menuItemStyle,

      '&:hover': {
        backgroundColor: 'none'
      },
      '&:active:not(:disabled)': {
        color: 'brandNavy100',
        svg: {
          color: 'brandNavy100'
        }
      }
    }}
  >
    {icon && <Icon icon={icon} />}
    {title}
  </div>
);

const BurgerMenu = ({
  burgerMenuItems,
  isBurgerMenuOpen,
  setIsBurgerMenuOpen,
  footerButton
}: {
  burgerMenuItems: NavMenuItem[];
  isBurgerMenuOpen: boolean;
  setIsBurgerMenuOpen: (isOpen: boolean) => void;
  footerButton?: React.ReactNode;
}) => {
  const breakpoint = useBreakpoint();
  const Router = useRouter();

  const getMenuItemType = (menuItem: NavMenuItem) => {
    if (menuItem.burgerMenuItemType) {
      return menuItem.burgerMenuItemType;
    }
    if (menuItem.isDropdown) {
      return BurgerMenuItemType.DROPDOWN;
    }
    return BurgerMenuItemType.PLAIN_TEXT;
  };

  return (
    <>
      {burgerMenuItems && burgerMenuItems.length > 0 && (
        <Drawer
          isOpen={isBurgerMenuOpen}
          onClose={() => {
            setIsBurgerMenuOpen(false);
          }}
          isActionButtonsEnabled={false}
          canEscapeKeyClose
          canOutsideClickClose
          sx={{
            [`& .${Classes.DRAWER_BODY} > div`]: {
              padding: '0'
            }
          }}
          footerAlign={breakpoint.isLessThanSmallMin ? 'fill' : 'center'}
          footer={footerButton}
          isFooterSticky
        >
          <ul>
            {burgerMenuItems?.map(menuItem => (
              <li key={menuItem.id}>
                {getMenuItemType(menuItem) === BurgerMenuItemType.DROPDOWN && (
                  <AccordionMenuItem
                    id={menuItem.id}
                    icon={menuItem.icon}
                    links={
                      menuItem?.subMenuItems
                        ?.filter(item => !item.hideItem)
                        .map(item => ({
                          ...item,
                          onClick: () => {
                            if (item.onClick) {
                              item.onClick();
                              setIsBurgerMenuOpen(false);
                              return;
                            }
                            if (item.url && item.openInNewTab) {
                              window.open(
                                item.url,
                                '_blank',
                                'noopener, noreferrer'
                              );
                              setIsBurgerMenuOpen(false);
                            } else if (item.url && !item.openInNewTab) {
                              Router.push(item.url);
                              setIsBurgerMenuOpen(false);
                            }
                          }
                        })) ?? []
                    }
                    title={menuItem.buttonText as string}
                    sxOverride={{
                      borderTop: 'none !important'
                    }}
                    activeLink={menuItem.activeLink ?? ''}
                  />
                )}

                {getMenuItemType(menuItem) === BurgerMenuItemType.LINK && (
                  <BurgerMenuLink
                    title={menuItem.buttonText as string}
                    icon={menuItem.icon}
                    url={menuItem.url ?? '/'}
                    openInNewTab={menuItem.openInNewTab}
                    id={menuItem.id}
                    setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                  />
                )}

                {getMenuItemType(menuItem) === BurgerMenuItemType.BUTTON && (
                  <BurgerMenuButton
                    id={menuItem.id}
                    title={menuItem.buttonText as string}
                    icon={menuItem.icon}
                    onClick={menuItem.onClick as () => void}
                    setIsBurgerMenuOpen={setIsBurgerMenuOpen}
                  />
                )}

                {getMenuItemType(menuItem) ===
                  BurgerMenuItemType.STYLED_TEXT && (
                  <BurgerMenuText
                    id={menuItem.id}
                    title={menuItem.buttonText as string}
                    icon={menuItem.icon}
                  />
                )}

                {(menuItem.burgerMenuItemType ===
                  BurgerMenuItemType.PLAIN_TEXT ||
                  (!menuItem.burgerMenuItemType && !menuItem.isDropdown)) && (
                  <>{menuItem.buttonText}</>
                )}
              </li>
            ))}
          </ul>
        </Drawer>
      )}
    </>
  );
};

export default BurgerMenu;
