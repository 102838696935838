/* eslint-disable no-useless-escape */
/* eslint-disable no-param-reassign */
import {
  format,
  parseISO,
  formatDistanceToNow,
  isToday,
  isYesterday
} from 'date-fns';
import { getNumberOrEmptyString, getNumberOrZero } from './getters';

/**
 * Format date string to 'MMM d, yyyy  h:mm a', 'MMM d, yyyy',
 * or custom format passed in as altFormat
 */
export const formatDate = (
  dateStr: string,
  ignoreTimezone?: boolean,
  showTime?: boolean,
  altFormat?: string
): string => {
  if (!dateStr) {
    return '';
  }
  let date = new Date(dateStr);
  if (ignoreTimezone) {
    // https://stackoverflow.com/a/52352512/220671
    date = new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
  }
  if (showTime) {
    return format(date, 'MMM d, yyyy  h:mm a');
  }
  if (altFormat) {
    return format(date, altFormat);
  }
  return format(date, 'MMM d, yyyy');
};

/**
 * format date to UTC string without Timestamp
 * @param date date in mm/dd/yyyy format
 * @returns date in yyyy-mm-dd format
 */
export const formatDateUTC = (date: string) => {
  if (date && date.length === 10 && date !== '00/00/0000') {
    return new Date(date).toISOString().split('T')[0];
  }
  return date;
};

/**
 * Format string to title case
 */
export const formatTitleCase = (str: string): string =>
  str
    .replace(
      /[\w']+/g,
      text => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    )
    .trim();

/**
 * Format number to dollar amount string
 */
export const formatToCad = (
  amount: number,
  language: string,
  isSign = true
): string => {
  const pattern = /(\d)(?=(\d{3})+(?!\d))/g;
  if (language === 'en') {
    return `${isSign ? '$' : ''}${amount.toFixed(2).replace(pattern, '$1,')}`;
  }

  return `${amount.toFixed(2).replace(pattern, '$1,')}${isSign ? ' $' : ''}`
    .replace(/,+/g, ' ')
    .replace(/\.+/g, ',');
};

/**
 * Format sin as 123-123-123
 * @param sin {number} the sin
 * @returns formattedSin {string} e.g. 123-123-123
 */
export const formatSinWithDashesInBetween = (sin: number): string =>
  sin
    .toString()
    .replace(/\D/g, '')
    .replace(/(\d{3})(?!$)/g, '$1-');

/**
 * Format sin as *** *** 123
 * @param sin {number} the sin
 * @returns formattedSin {string} e.g. *** *** 123
 */
export const formatSin = (sin: number | string): string =>
  sin.toString().replace(/\d{6}/, '*** *** ');

/**
 * return '$99,999.00' as 99999
 */
export const formatCurrencyStringToNumber = (currency: string | number) => {
  if (typeof currency === 'string') {
    return Number(currency.replace(/[$,]+/g, ''));
  }
  return currency;
};

/**
 * if number in str <=9, pad it with a 0
 * return '01' for '1'
 */
export const padWithZero = (str: string) =>
  Number(str) <= 9 ? `0${str}` : str;

/**
 * format 'm5a3z1' as 'M5A 3Z1'
 * @param postal {string} valid Canadian postal code
 * @returns value formatted Canadian postal code
 */
export const formatPostalCode = (postal: string) => {
  if (postal.length !== 6) {
    return postal;
  }
  const firstHalf = postal.slice(0, 3).toUpperCase();
  const secondHalf = postal.slice(3).toUpperCase();
  return `${firstHalf} ${secondHalf}`;
};

/**
 * Return distance in time from input time to now
 * @param  {string} inputDate
 * @returns  {string} like "3 days"
 */
export const formatTimeDistanceToNow = (inputDate: string) =>
  formatDistanceToNow(parseISO(inputDate));

/**
 * Format date from server into user friendly date and time
 * @param  {string} inputDate
 * @param  {string} formatted date e.g. Apr 22, 2019 9:55 AM
 */
export const parseDateAndTime = (inputDate: string) =>
  format(parseISO(inputDate), 'MMM d, yyyy h:mm a');

/**
 * Checks ISO formatted date if date is same day or yesterday and returns
 * corresponding 'Today' or 'Yesterday' string; otherwise returns formatted date
 * of 'MMM d, yyyy'
 */
export const formatDateText = (
  inputDate: string,
  todayString: string,
  yesterdayString: string,
  timestamp = false
) => {
  if (isToday(parseISO(inputDate))) {
    return todayString;
  }
  if (isYesterday(parseISO(inputDate))) {
    return yesterdayString;
  }
  return timestamp
    ? parseDateAndTime(inputDate)
    : format(parseISO(inputDate), 'MMM d, yyyy');
};

/**
 * format DateAdded for sent/received files
 * @param inputDate a valid date string
 * @param todayString 'today' label
 * @param yesterdayString 'yesterday' label
 */
export const formatDateAdded = (
  inputDate: string,
  todayString: string,
  yesterdayString: string
) => {
  const date = parseISO(inputDate);
  const formattedDate = parseDateAndTime(inputDate);
  const timeArr = formattedDate.split(' ');
  const timePartOfDate = `${timeArr[3]} ${timeArr[4]}`;
  if (isToday(date)) {
    return `${todayString} ${timePartOfDate}`;
  }
  if (isYesterday(date)) {
    return `${yesterdayString} ${timePartOfDate}`;
  }
  return formattedDate;
};

export const formatMembershipNumber = (val: string) => {
  let digitValues = val.replace(/\D/g, '');
  if (digitValues.length > 7) {
    if (digitValues.length === 8) {
      digitValues = digitValues.replace(/^(\d{7})(\d)(\d?).*/, '$1-$2');
      return digitValues;
    }
    if (digitValues.length >= 9) {
      digitValues = digitValues.replace(/^(\d{7})(\d{2})(\d?).*/, '$1-$2');
      return digitValues;
    }
  }
  return digitValues;
};

/**
 * bullet-proof currency formatter
 * will return ${val} or ''
 */
export const formatAsCurrencyOrGetEmptyString = (
  val: number | string | undefined,
  language: string,
  withoutSign?: boolean
) => {
  // if it has commas and dollar sign,
  // assume already formatted and return  it
  if (typeof val === 'string' && val.match(/[\$,]/g)) {
    return /^\$/.test(val) ? val : `$${val}`;
  }
  if (getNumberOrEmptyString(val) === '') {
    return '';
  }
  const numeric = getNumberOrZero(val);
  if (withoutSign) {
    return formatToCad(numeric, language, false);
  }
  return formatToCad(numeric, language);
};
