import { ActionCreator } from 'redux';
import { EapAction } from 'types';

export enum updatePasswordActionTypes {
  INIT_UPDATE_PASSWORD = 'INIT_UPDATE_PASSWORD',
  SUBMIT_UPDATE_PASSWORD_REQUESTED = 'SUBMIT_UPDATE_PASSWORD_REQUESTED',
  SUBMIT_UPDATE_PASSWORD_SUCCESS = 'SUBMIT_UPDATE_PASSWORD_SUCCESS',
  SUBMIT_UPDATE_PASSWORD_FAILED = 'SUBMIT_UPDATE_PASSWORD_FAILED'
}

export const submitUpdatePasswordAction: ActionCreator<EapAction> = (data: {
  oldPassword: string;
  newPassword: string;
  username: string;
}) => ({
  type: updatePasswordActionTypes.SUBMIT_UPDATE_PASSWORD_REQUESTED,
  data
});

export const submitUpdatePasswordSuccessAction: ActionCreator<EapAction> = () => ({
  type: updatePasswordActionTypes.SUBMIT_UPDATE_PASSWORD_SUCCESS
});

export const submitUpdatePasswordFailedAction: ActionCreator<EapAction> = () => ({
  type: updatePasswordActionTypes.SUBMIT_UPDATE_PASSWORD_FAILED
});

export const initUpdatePasswordAction: ActionCreator<EapAction> = () => ({
  type: updatePasswordActionTypes.INIT_UPDATE_PASSWORD
});
