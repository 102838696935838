import {
  all,
  takeLeading,
  takeLatest,
  put,
  select,
  call,
  delay
} from 'redux-saga/effects';
import Router from 'next/router';
import { format } from 'url';

import { EapAction, RootState } from 'types';
import { getApiErrorMessage } from 'utils';
import { safeAuthEffect, safeNavHistoryBack } from 'store/sagas/sagaUtils';
import {
  lockFormSaga,
  clearLockedFormNotificationSaga
} from 'containers/requestForm/common/sagas/formSagaUtils';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import { createFormFailedAction } from 'containers/memberSearch/memberSearchActions';
import {
  createForm190,
  getForm190,
  memberSearch,
  addServicePeriodForm190,
  removeServicePeriodForm190,
  saveForm190,
  setRetro190,
  getRetro190,
  deleteRetro190,
  submitForm190
} from 'services';

import {
  clearNotifications,
  notifyErrorAction,
  notifyLockedFormErrorAction
} from 'containers/notifications/notificationsActions';
import {
  form190ActionTypes,
  getForm190SuccessAction,
  getForm190FailedAction,
  addServicePeriodForm190SuccessAction,
  addServicePeriodForm190FailedAction,
  removeServicePeriodForm190FailedAction,
  removeServicePeriodForm190SuccessAction,
  saveForm190SuccessAction,
  saveForm190FailedAction,
  clearForm190StatusAction,
  getRetroPay190Action,
  getLatest190SuccessAction,
  getLatestForm190FailureAction,
  setForm190StepAction,
  getRetroPay190SuccessAction,
  saveRetroPayFailedAction,
  submitForm190Action,
  setForm190SubmitStatusAction,
  submitForm190SuccessAction
} from './form190Actions';
import { constructSaveForm190Payload } from './utils/form190Utils';

export function* createForm190Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, createForm190, action.data);

  if (res.data) {
    // route user to newly created 190
    yield call(
      Router.push,
      '/form/190/[id]',
      format({ pathname: `/form/190/${res.data.requestNumber}` })
    );
  } else {
    yield put(createFormFailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* getForm190Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const requestNumber = action.data;

  yield call(lockFormSaga, { ...action, data: requestNumber });

  // fetch 190 form
  const res = yield call(safeAuthEffect, getForm190, requestNumber);

  if (res.data) {
    // fetch member info
    const memberInfoRes = yield call(safeAuthEffect, memberSearch, {
      omersMembershipNumber: res?.data?.e190Model?.membershipNo,
      employerNumber: res?.data?.e190Model?.employerNo
    });

    yield put(
      getForm190SuccessAction({
        requestNumber,
        memberInfo:
          memberInfoRes?.data?.memberSearchResult?.memberships[0] ?? null,
        commentsToMember: res.data?.e190Model?.commentsToMember ?? '',
        notes: res.data?.e190Model?.notes ?? '',
        exceptions: res.data?.e190Model?.exceptions ?? [],
        servicePeriodModels: res.data?.e190Model?.servicePeriodModels ?? [],
        highestEarningsModels: res.data?.e190Model?.highestEarningsModels ?? [],
        quoteAssumptionModel: res.data?.e190Model?.quoteAssumptionModel ?? null
      })
    );

    // route user to form if not already there
    if (Router.route !== '/form/190/[id]') {
      yield call(
        Router.push,
        '/form/190/[id]',
        format({ pathname: `/form/190/${requestNumber}` })
      );
    }
  } else {
    yield put(getForm190FailedAction());
    yield put(notifyLockedFormErrorAction(getApiErrorMessage(res.errors)));

    // navigate off the request if user is on the page from a direct link
    if (Router.route === '/form/190/[id]') {
      yield call(safeNavHistoryBack, '/requests');
    }
  }

  yield put(clearLoadingAction());
  yield call(clearLockedFormNotificationSaga);
}

export function* addServicePeriodForm190Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(safeAuthEffect, addServicePeriodForm190, action.data);

  if (res.data) {
    yield put(
      addServicePeriodForm190SuccessAction(res.data?.servicePeriodModel)
    );
  } else {
    yield put(addServicePeriodForm190FailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* removeServicePeriodForm190Saga(action: EapAction): any {
  yield put(startLoadingAction());

  const res = yield call(
    safeAuthEffect,
    removeServicePeriodForm190,
    action.data
  );

  if (res.status === 204) {
    yield put(
      removeServicePeriodForm190SuccessAction(action.data.servicePeriodNo)
    );
  } else {
    yield put(removeServicePeriodForm190FailedAction(res?.errors[0]?.code));
  }

  yield put(clearLoadingAction());
}

export function* saveForm190Saga(action: EapAction): any {
  const { requestNumber, formStep, form190Data } = yield select(
    (state: RootState) => state.form190
  );

  const payload = constructSaveForm190Payload(
    action.data,
    form190Data,
    formStep,
    requestNumber
  );
  const res = yield call(safeAuthEffect, saveForm190, payload, requestNumber);

  if (res.data) {
    yield put(saveForm190SuccessAction(payload));
    yield put(clearNotifications());

    if (action.data.reviewForm) {
      yield put(setForm190StepAction(2));
    }

    if (action.data.submitForm) {
      yield put(submitForm190Action());
    }
  } else {
    yield put(saveForm190FailedAction());
    yield put(
      notifyErrorAction(
        res?.errors[0]?.code === '99999'
          ? res.errors[0].msg
          : getApiErrorMessage(res?.errors)
      )
    );
  }
}

export function* clearForm190StatusSaga() {
  yield delay(3000);
  yield put(clearForm190StatusAction());
}

export function* saveRetroPay190Saga(action: EapAction): any {
  const { requestNumber } = yield select((state: RootState) => state.form190);
  const { servicePeriodNo, payload } = action.data;
  const res = yield call(
    safeAuthEffect,
    setRetro190,
    requestNumber,
    servicePeriodNo,
    payload
  );

  if (res?.data) {
    yield put({
      type: form190ActionTypes.GET_LATEST_FORM_190_REQUESTED,
      data: { requestNumber, disableSpinner: true }
    });
    yield put(
      getRetroPay190Action({
        requestNumber,
        servicePeriodNo
      })
    );
  } else {
    yield put(saveRetroPayFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }
}

export function* fetchLatest190Saga(action: EapAction): any {
  if (!action.data.disableSpinner) {
    yield put(startLoadingAction());
  }

  const formRes = yield call(
    safeAuthEffect,
    getForm190,
    action.data.requestNumber
  );

  if (formRes.data) {
    yield put(
      getLatest190SuccessAction({
        ...formRes.data
      })
    );
  } else {
    yield put(getLatestForm190FailureAction());
    yield put(notifyErrorAction(getApiErrorMessage(formRes?.errors)));
  }

  if (!action.data.disableSpinner) {
    yield put(clearLoadingAction());
  }
}

export function* getRetroPay190Saga(action: EapAction): any {
  const { servicePeriodNo, requestNumber } = action.data;
  const ret = yield call(
    safeAuthEffect,
    getRetro190,
    requestNumber,
    servicePeriodNo
  );

  if (ret.data) {
    yield put(
      getRetroPay190SuccessAction({
        retroPayModels: ret.data?.retroPayModels,
        servicePeriodNo
      })
    );
  }
}

export function* deleteRetroPay190Saga(action: EapAction): any {
  const { servicePeriodNo, requestNumber } = action.data;
  yield call(safeAuthEffect, deleteRetro190, requestNumber, servicePeriodNo);
  yield put({
    type: form190ActionTypes.GET_LATEST_FORM_190_REQUESTED,
    data: { requestNumber, disableSpinner: false }
  });
}

export function* submitForm190Saga(): any {
  yield put(startLoadingAction());
  yield put(setForm190SubmitStatusAction('submitting'));

  const { requestNumber } = yield select((state: RootState) => state.form190);

  const res = yield call(safeAuthEffect, submitForm190, requestNumber);

  if (res?.data?.submitResponse) {
    yield put(clearNotifications());
    yield put(submitForm190SuccessAction(res.data.submitResponse));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm190SubmitStatusAction(''));
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(setForm190SubmitStatusAction('error'));
    yield put(clearLoadingAction());
    yield delay(3000);
    yield put(setForm190SubmitStatusAction(''));
  }
}

export function* form190Saga(): any {
  yield all([
    yield takeLeading(form190ActionTypes.CREATE_FORM_190, createForm190Saga),
    yield takeLatest(form190ActionTypes.GET_FORM_190_REQUESTED, getForm190Saga),
    yield takeLatest(
      form190ActionTypes.GET_LATEST_FORM_190_REQUESTED,
      fetchLatest190Saga
    ),
    yield takeLatest(
      form190ActionTypes.ADD_SERVICE_PERIOD_190_REQUESTED,
      addServicePeriodForm190Saga
    ),
    yield takeLatest(
      form190ActionTypes.REMOVE_SERVICE_PERIOD_190_REQUESTED,
      removeServicePeriodForm190Saga
    ),
    yield takeLatest(
      form190ActionTypes.SAVE_FORM_190_REQUESTED,
      saveForm190Saga
    ),
    yield takeLatest(
      [
        form190ActionTypes.SAVE_FORM_190_SUCCEED,
        form190ActionTypes.SAVE_FORM_190_FAILED
      ],
      clearForm190StatusSaga
    ),
    yield takeLatest(
      form190ActionTypes.GET_RETRO_PAY_190_REQUESTED,
      getRetroPay190Saga
    ),
    yield takeLatest(
      form190ActionTypes.DELETE_RETRO_PAY_190_REQUESTED,
      deleteRetroPay190Saga
    ),
    yield takeLatest(
      form190ActionTypes.SAVE_RETRO_PAY_190_REQUESTED,
      saveRetroPay190Saga
    ),
    yield takeLatest(form190ActionTypes.SUBMIT_FORM_190, submitForm190Saga)
  ]);
}

export default form190Saga;
