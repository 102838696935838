import { useRef, useEffect, EffectCallback, DependencyList } from 'react';
import { isEqual } from 'lodash';

/**
 * Custom useEffect for deep comparison of nested objects to trigger useEffect
 * on change
 */
export const useEffectDeepCompare = (
  callback: EffectCallback,
  deps: DependencyList
) => {
  const ref = useRef();

  useEffect(
    callback,
    ((val: any) => {
      if (!isEqual(val, ref.current)) {
        ref.current = val;
      }

      return ref.current;
    })(deps)
  );
};
