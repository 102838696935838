/* eslint-disable camelcase */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormContext, useFieldArray } from 'react-hook-form-legacy';
import { flatten } from 'lodash';
import { ReactSVG } from 'react-svg';

import { RootState } from 'types';
import {
  formatDate,
  getPropertiesItem,
  getPropertiesList,
  propertiesSelector,
  formatAsCurrencyOrGetEmptyString
} from 'utils';
import Table from 'components/table/Table';
import ToolTip from 'components/toolTip/ToolTip';
import TextCurrencyInput from 'components/forms/inputs/textCurrencyInput/TextCurrencyInput';
import TextFloatInput from 'components/forms/inputs/textFloatInput/TextFloatInput';
import Button from 'components/button/Button';
import { RETRO_TYPE_OPTIONS } from 'containers/requestForm/common/modals/retroPayModal/RetroPayModal';

import styles from '../AnnualReporting.module.scss';

interface AnnualReportingSplitPeriodTableProps {
  onClickRetroDelete: (field: any) => void;
  onClickRetroEdit: (field: any, index: number) => void;
  totalCreditedService: number;
  totalContributoryEarnings: number;
  totalRetro: number;
  totalPensionAdjustment: number;
  totalRpp: number;
  totalRca: number;
}
const AnnualReportingSplitPeriodTable = ({
  onClickRetroDelete,
  onClickRetroEdit,
  totalCreditedService,
  totalContributoryEarnings,
  totalRetro,
  totalPensionAdjustment,
  totalRpp,
  totalRca
}: AnnualReportingSplitPeriodTableProps) => {
  const { form119Data } = useSelector((state: RootState) => state.form119);
  const { t } = useTranslation(['form119', 'common']);
  const { selectedReconciliationYear, financialModel } = form119Data.e119Model;
  const employmentStatusList = getPropertiesList(
    'Employment Status',
    useSelector(propertiesSelector)
  );
  const { omersUser } = useSelector(
    (state: RootState) => state.userSession.userData
  );

  const { control, register, errors } = useFormContext();

  const { fields } = useFieldArray({
    control,
    name: 'financialModel.employmentPeriodModels'
  });

  const tableHeading = useMemo(
    () => [
      [
        { headingCell: t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_1') },
        { headingCell: t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_2') },
        {
          headingCell: (
            <span
              className={`${styles.rowHeader} ${styles.splitPeriodRowHeaders}`}
            >
              {t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_3')}
              <ToolTip
                content={t('ANNUAL_REPORTING_CREDITED_SERVICE_TOOLTIP')}
              />
            </span>
          )
        },
        {
          headingCell: (
            <span
              className={`${styles.rowHeader} ${styles.splitPeriodRowHeaders}`}
            >
              {t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_4')}
              <ToolTip
                content={[
                  t('ANNUAL_REPORTING_CONTRIBUTORY_EARNINGS_TOOLTIP_1'),
                  t('ANNUAL_REPORTING_CONTRIBUTORY_EARNINGS_TOOLTIP_2')
                ]}
              />
            </span>
          )
        },
        {
          headingCell: (
            <span
              className={`${styles.rowHeader} ${styles.splitPeriodRowHeaders}`}
            >
              {t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_5')}{' '}
              {!financialModel.paMandatory && t('ANNUAL_REPORTING_OPTIONAL')}
              <ToolTip
                content={[
                  t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_1'),
                  t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_2'),
                  t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_3'),
                  t('ANNUAL_REPORTING_PENSION_ADJUSTMENT_TOOLTIP_4')
                ]}
              />
            </span>
          )
        },
        {
          headingCell: (
            <span
              className={`${styles.rowHeader} ${styles.splitPeriodRowHeaders}`}
            >
              {t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_6')}
              <ToolTip content={t('ANNUAL_REPORTING_RPP_TOOLTIP')} />
            </span>
          )
        },
        {
          headingCell: (
            <span
              className={`${styles.rowHeader} ${styles.splitPeriodRowHeaders}`}
            >
              {t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_7')}{' '}
              {t('ANNUAL_REPORTING_OPTIONAL')}
              <ToolTip content={t('ANNUAL_REPORTING_RCA_TOOLTIP')} />
            </span>
          )
        },
        {
          headingCell: (
            <span
              className={`${styles.rowHeader} ${styles.splitPeriodRowHeaders}`}
            >
              {t('ANNUAL_REPORTING_SPLIT_PERIOD_COLUMN_HEADING_8')}{' '}
              {t('ANNUAL_REPORTING_OPTIONAL')}
              <ToolTip
                content={[
                  t('ANNUAL_REPORTING_RETRO_PAY_TOOLTIP_1'),
                  t('ANNUAL_REPORTING_RETRO_PAY_TOOLTIP_2')
                ]}
              />
            </span>
          )
        }
      ]
    ],
    [financialModel.paMandatory]
  );

  const tableData = (() => {
    const initialData =
      financialModel.employmentPeriodModels &&
      financialModel.employmentPeriodModels.length > 1
        ? flatten(
            fields.map((field: any, index: number) => {
              if (field && field.hashMap) {
                return [
                  [
                    {
                      contentCell: (
                        <span className={styles.columnHeading}>
                          {formatDate(
                            field.hashMap.start_date,
                            true,
                            false,
                            'd MMM yyyy'
                          )}{' '}
                          -{' '}
                          {formatDate(
                            field.hashMap.end_date,
                            true,
                            false,
                            'd MMM yyyy'
                          )}
                        </span>
                      ),
                      isFull: true
                    }
                  ],
                  [
                    {
                      contentCell: getPropertiesItem(
                        field.hashMap.employment_status,
                        employmentStatusList
                      )
                    },
                    {
                      contentCell: field.hashMap.NRA
                    },
                    {
                      contentCell: (
                        <TextFloatInput
                          key={`${field.id}-credited_service`}
                          name={`financialModel.employmentPeriodModels[${index}].hashMap.credited_service`}
                          errorMessage={
                            errors.financialModel?.employmentPeriodModels[index]
                              ?.hashMap?.credited_service
                          }
                          refRegister={register()}
                          defaultValue={field.hashMap?.credited_service ?? ''}
                          noMarginBottom
                        />
                      )
                    },
                    {
                      contentCell: (
                        <TextCurrencyInput
                          key={`${field.id}-earnings`}
                          name={`financialModel.employmentPeriodModels[${index}].hashMap.earnings`}
                          defaultValue={field.hashMap?.earnings ?? ''}
                          refRegister={register()}
                          errorMessage={
                            errors.financialModel?.employmentPeriodModels[index]
                              ?.hashMap?.earnings
                          }
                          currencyMaxLength={7}
                          noMarginBottom
                        />
                      )
                    },
                    {
                      contentCell: (
                        <span>
                          <TextCurrencyInput
                            key={`${field.id}-pension_adjustment_amt`}
                            name={`financialModel.employmentPeriodModels[${index}].hashMap.pension_adjustment_amt`}
                            defaultValue={
                              field.hashMap?.pension_adjustment_amt ?? ''
                            }
                            refRegister={register()}
                            errorMessage={
                              errors.financialModel?.employmentPeriodModels[
                                index
                              ]?.hashMap?.pension_adjustment_amt
                            }
                            currencyMaxLength={5}
                            noMarginBottom
                            disableUserDecimalInput
                          />
                          <input
                            name={`financialModel.employmentPeriodModels[${index}].hashMap.isPensionAdjustmentMandatory`}
                            type="hidden"
                            ref={register()}
                            value={financialModel.paMandatory ? 'Y' : 'N'}
                          />
                        </span>
                      )
                    },
                    {
                      contentCell: (
                        <TextCurrencyInput
                          key={`${field.id}-rpp_contribution_amt`}
                          name={`financialModel.employmentPeriodModels[${index}].hashMap.rpp_contribution_amt`}
                          defaultValue={
                            field.hashMap?.rpp_contribution_amt ?? ''
                          }
                          refRegister={register()}
                          errorMessage={
                            errors.financialModel?.employmentPeriodModels[index]
                              ?.hashMap?.rpp_contribution_amt
                          }
                          currencyMaxLength={5}
                          noMarginBottom
                        />
                      )
                    },
                    {
                      contentCell: (
                        <TextCurrencyInput
                          key={`${field.id}-rca_contribution_amt`}
                          name={`financialModel.employmentPeriodModels[${index}].hashMap.rca_contribution_amt`}
                          defaultValue={
                            field.hashMap?.rca_contribution_amt ?? ''
                          }
                          errorMessage={
                            errors.financialModel?.employmentPeriodModels[index]
                              ?.hashMap?.rca_contribution_amt
                          }
                          refRegister={register()}
                          currencyMaxLength={7}
                          noMarginBottom
                        />
                      )
                    },
                    {
                      contentCell: (
                        <div className={styles.retroEdit}>
                          {(() => {
                            if (field.hashMap.retro_type) {
                              const option = RETRO_TYPE_OPTIONS.filter(
                                item => item.val === field.hashMap.retro_type
                              )[0];
                              if (option) {
                                return t(`common:${option.translation}`);
                              }
                            }
                            return (
                              <span className={styles.greyedOut}>
                                {t('common:NOT_AVAILABLE')}
                              </span>
                            );
                          })()}

                          <span className={styles.retroEditIcons}>
                            {field.hashMap.retro_type && (
                              <div className={styles.retroDelete}>
                                <Button
                                  baseStyle="basic"
                                  isDisabled={omersUser}
                                  onClick={() => onClickRetroDelete(field)}
                                >
                                  <ReactSVG src="/images/delete.svg" />
                                </Button>
                              </div>
                            )}
                            <Button
                              baseStyle="basic"
                              onClick={() => onClickRetroEdit(field, index)}
                            >
                              <ReactSVG src="/images/edit.svg" />
                            </Button>
                          </span>
                        </div>
                      )
                    }
                  ]
                ];
              }
              return [];
            })
          )
        : [];

    if (
      financialModel.employmentPeriodModels &&
      financialModel.employmentPeriodModels.length > 1
    ) {
      initialData.push([
        {
          contentCell: (
            <span className={styles.columnHeading}>
              {t('ANNUAL_REPORTING_SPLIT_PERIOD_TOTAL')}
            </span>
          )
        },
        {
          contentCell: ''
        },
        {
          contentCell: (
            <span
              className={
                Number(totalCreditedService) > 12
                  ? `${styles.error} ${styles.columnHeading}`
                  : styles.columnHeading
              }
            >
              {Number(totalCreditedService).toFixed(2)}
            </span>
          )
        },
        {
          contentCell: (
            <span
              className={
                totalContributoryEarnings < totalRetro
                  ? `${styles.error} ${styles.columnHeading}`
                  : styles.columnHeading
              }
            >
              {formatAsCurrencyOrGetEmptyString(
                totalContributoryEarnings,
                'en'
              )}
            </span>
          )
        },
        {
          contentCell: (
            <span className={styles.columnHeading}>
              {formatAsCurrencyOrGetEmptyString(totalPensionAdjustment, 'en')}
            </span>
          )
        },
        {
          contentCell: (
            <span className={styles.columnHeading}>
              {formatAsCurrencyOrGetEmptyString(totalRpp, 'en')}
            </span>
          )
        },
        {
          contentCell: (
            <span className={styles.columnHeading}>
              {formatAsCurrencyOrGetEmptyString(totalRca, 'en')}
            </span>
          )
        },
        {
          contentCell: (
            <span className={styles.columnHeading}>
              {formatAsCurrencyOrGetEmptyString(totalRetro, 'en')}
            </span>
          )
        }
      ]);
    }

    return initialData;
  })();

  return (
    <>
      {selectedReconciliationYear && (
        <>
          <h3>{`${selectedReconciliationYear?.yearText} - ${t(
            'ANNUAL_REPORTING_SPLIT_PERIOD'
          )}`}</h3>
          <p>
            {t('ANNUAL_REPORTING_SPLIT_PERIOD_PARA', {
              reportYear: selectedReconciliationYear?.yearText
            })}
          </p>
        </>
      )}

      <Table
        withColoredHeaderRow
        headerColor="blue"
        withTableBorder
        withRowBorders
        withColumnBorders
        withColoredRowsOpposite
        tableHeading={tableHeading}
        tableData={tableData}
        alignCellsTop
      />
    </>
  );
};

export default AnnualReportingSplitPeriodTable;
