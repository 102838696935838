import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DonnaBusinessConfig, DonnaBusinessConfigNames } from './types';

export interface DonnaBusinessConfigState {
  [DonnaBusinessConfigNames.TERMINATION]: DonnaBusinessConfig | null;
  [DonnaBusinessConfigNames.RETIREMENT]: DonnaBusinessConfig | null;
}

const initialState: DonnaBusinessConfigState = {
  [DonnaBusinessConfigNames.TERMINATION]: null,
  [DonnaBusinessConfigNames.RETIREMENT]: null
};

const DonnaBusinessConfigSlice = createSlice({
  name: 'donnaBusinessConfig',
  initialState,
  reducers: {
    getDonnaBusinessConfig: () => {},
    getDonnaBusinessConfigSucceeded: (
      draft,
      {
        payload
      }: PayloadAction<{
        configTermination: DonnaBusinessConfig | null;
        configRetirement: DonnaBusinessConfig | null;
      }>
    ) => {
      draft[DonnaBusinessConfigNames.TERMINATION] = payload.configTermination;
      draft[DonnaBusinessConfigNames.RETIREMENT] = payload.configRetirement;
    }
  }
});

export default DonnaBusinessConfigSlice.reducer;
export const {
  getDonnaBusinessConfig,
  getDonnaBusinessConfigSucceeded
} = DonnaBusinessConfigSlice.actions;
