import { all, takeLatest, select, call, put } from 'redux-saga/effects';
import { EapAction } from 'types';
import { filterValues, filterStatusCode } from 'utils/constants';
import { isEqual } from 'lodash';
import { RootState } from 'store/reducers';
import {
  selectEmployerNumber,
  propertiesSelector,
  archivedMessagesSelector,
  getApiErrorMessage
} from 'utils';
import {
  searchArchivedMessages,
  getArchivedMessagesMessageThread,
  sendArchivedMessagesReply
} from 'services';
import {
  systemErrorNotificationAction,
  notifySuccessAction,
  clearNotifications,
  notifyErrorAction
} from 'containers/notifications/notificationsActions';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import i18n from 'i18next';
import { updateCorrStatusForArchivedMessage } from 'services/archivedMessages';
import clientSide from 'utils/logger/client-side';
import { toLoggerError } from 'utils/api';
import {
  archivedMessagesActionTypes,
  getArchivedMessagesSucceededAction,
  getArchivedMessagesFailedAction,
  setArchivedMessagesButtonStatus,
  getArchivedMessagesRequestedAction,
  getArchivedMessagesMessageThreadSucceededAction,
  getArchivedMessagesMessageThreadFailedAction,
  sendReplyRequestedFailedAction,
  sendReplyRequestedSucceededAction,
  getArchivedMessagesMessageThreadRequestedAction
} from './ArchivedMessagesActions';
import {
  IArchivedMessagesMessage,
  archivedMessagesTopicCategories,
  apiResponse
} from './types';
import {
  transformArchivedMessagesMessages,
  getPayload,
  transformArchivedMessagesMessageThread
} from './ArchivedMessages.utils';
import {
  defaultValues,
  defaultPayload
} from './archivedMessagesFilter/ArchivedMessagesFilter';

export function* getArchivedMessagesSaga(action: EapAction): any {
  try {
    const res = yield call(searchArchivedMessages, action.data);

    if (!res) {
      yield put(getArchivedMessagesFailedAction());
      yield put(systemErrorNotificationAction());
      clientSide.error(
        `Could not fetch archived messages; api did not return expected data`
      );
      return;
    }

    // Too many results returned from search
    if (res.status === 400 && res.errors[0]?.code === '308') {
      yield put(getArchivedMessagesFailedAction());
      yield put(notifyErrorAction(getApiErrorMessage(res.errors)));
      return;
    }

    if (res.data?.msscorrSearchResult) {
      // When no report is found, res?.data?.msscorrSearchResult will return an empty array.
      const data: IArchivedMessagesMessage[] = transformArchivedMessagesMessages(
        res.data?.msscorrSearchResult
      );
      yield put(getArchivedMessagesSucceededAction(data));
      return;
    }
  } catch (e) {
    yield put(getArchivedMessagesFailedAction());
    yield put(systemErrorNotificationAction());
    clientSide.error(
      `Could not fetch archived messages: system error`,
      toLoggerError(e)
    );
  }
}

export function* callGetArchivedMessagesSagaWithPayload(
  action: EapAction
): any {
  const employerNo = yield select(selectEmployerNumber);
  const properties = yield select(propertiesSelector);
  const correspondenceCategories = properties.find(
    (property: any) =>
      property.id === archivedMessagesTopicCategories.CORRESPONDENCE_CATEGORIES
  );
  const userData = action.data;
  const payload = getPayload(
    employerNo,
    userData,
    correspondenceCategories.items
  );

  yield put(clearNotifications());

  if (action.data.status === filterValues.ACTION_REQUIRED) {
    const readPayload = { ...payload, status: filterStatusCode.READ };
    const unreadPayload = { ...payload, status: filterStatusCode.UNREAD };

    yield put(getArchivedMessagesRequestedAction(readPayload));
    yield put(getArchivedMessagesRequestedAction(unreadPayload));
  } else {
    yield put(getArchivedMessagesRequestedAction(payload));
  }
}

export function* handleGetArchivedMessagesSucceededSaga() {
  const { filters } = yield select(
    (state: RootState) => state.archivedMessages
  );

  if (isEqual(filters, defaultValues) || isEqual(filters, defaultPayload)) {
    yield put(
      setArchivedMessagesButtonStatus({
        isResetButtonDisabled: true,
        isApplyButtonDisabled: true,
        isApplyButtonClicked: false
      })
    );
  } else {
    yield put(
      setArchivedMessagesButtonStatus({
        isResetButtonDisabled: false,
        isApplyButtonDisabled: true,
        isApplyButtonClicked: true
      })
    );
  }
}
function* getArchivedMessagesMessageThreadSaga(): any {
  const { correspondenceNumber } = yield select(archivedMessagesSelector);

  const res = yield call(
    safeAuthEffect,
    getArchivedMessagesMessageThread,
    correspondenceNumber
  );
  if (res.status === 400 && res.errors[0].msg === apiResponse.NO_DATA_FOUND) {
    yield put(getArchivedMessagesMessageThreadFailedAction());
  } else if (res.data.mssEcorrMessages.length > 0) {
    const data = transformArchivedMessagesMessageThread(
      res.data.mssEcorrMessages
    );
    yield put(getArchivedMessagesMessageThreadSucceededAction(data));
  } else {
    yield put(getArchivedMessagesMessageThreadFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

function* sendReplySaga(action: EapAction): any {
  const { correspondenceNumber } = yield select(archivedMessagesSelector);

  const res = yield call(
    safeAuthEffect,
    sendArchivedMessagesReply,
    correspondenceNumber,
    action.data
  );
  if (res.status === 400) {
    yield put(sendReplyRequestedFailedAction(getApiErrorMessage(res.errors)));
  } else if (res?.data?.status) {
    yield put(sendReplyRequestedSucceededAction());
    yield put(notifySuccessAction(i18n.t('SUCCESS')));
    yield put(getArchivedMessagesMessageThreadRequestedAction());
  } else {
    yield put(sendReplyRequestedFailedAction());
    yield put(systemErrorNotificationAction());
  }
}

export function* resolveSaga(action: EapAction): any {
  const res = yield call(
    safeAuthEffect,
    updateCorrStatusForArchivedMessage,
    action.data
  );

  if (res.data && res.data.status === 'resolved') {
    yield put({
      type: archivedMessagesActionTypes.RESOLVE_ARCHIVED_MESSAGE_SUCCEEDED,
      data: action.data
    });
  } else {
    yield put({
      type: archivedMessagesActionTypes.RESOLVE_ARCHIVED_MESSAGE_FAILED
    });
  }
}

export function* archivedMessagesSaga(): any {
  yield all([
    yield takeLatest(
      archivedMessagesActionTypes.APPLY_ARCHIVED_MESSAGES_FILTER_REQUESTED,
      callGetArchivedMessagesSagaWithPayload
    ),
    yield takeLatest(
      archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_REQUESTED,
      getArchivedMessagesSaga
    ),
    yield takeLatest(
      archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_SUCCEEDED,
      handleGetArchivedMessagesSucceededSaga
    ),
    yield takeLatest(
      archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_FAILED,
      handleGetArchivedMessagesSucceededSaga
    ),
    yield takeLatest(
      archivedMessagesActionTypes.GET_ARCHIVED_MESSAGES_MESSAGE_THREAD_REQUESTED,
      getArchivedMessagesMessageThreadSaga
    ),
    yield takeLatest(
      archivedMessagesActionTypes.SEND_REPLY_ARCHIVED_MESSAGE_REQUESTED,
      sendReplySaga
    ),
    yield takeLatest(
      archivedMessagesActionTypes.RESOLVE_ARCHIVED_MESSAGE_REQUESTED,
      resolveSaga
    )
  ]);
}
