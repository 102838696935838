import { isAfter } from 'date-fns';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { TypeTwoColumnWithMedia } from 'types/contentful-types';
import useLocalStorageState from 'use-local-storage-state';
import { toLoggerError } from 'utils/api';
import logger from 'utils/logger/client-side';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { contentful } from 'modules/contentful';

const EMPLOYER_QUARTERLY_KEY = 'employerQuarterly';

const defaultValue = {
  environment: env('NEXT_PUBLIC_APP_ENV') as string,
  slice: null,
  // Time to Live
  TTL: null
};

export interface EmployerQuarterlyState {
  slice: TypeTwoColumnWithMedia<undefined, 'en-us'> | null;
  TTL: number | null;
}

const shouldRefresh = (TTL: number | null) => {
  if (!TTL) {
    return true;
  }

  return isAfter(Date.now(), TTL);
};

export const fetchEmployerQuarterly = async () => {
  try {
    const response = await contentful.entries.get({
      content_type: 'publicNavigationLink',
      'fields.title': 'Employer Quarterly'
    });

    if (isEmpty(response.items)) {
      logger.warn('Could not find Employer Quarterly');
      return null;
    }
    return response.items[0].fields.linkedEntry as TypeTwoColumnWithMedia<
      undefined,
      'en-us'
    >;
  } catch (error) {
    logger.error('Could not fetch Employer Quarterly', toLoggerError(error));
    return null;
  }
};

export const useEmployerQuarterlyFetcher = () => {
  const [employerQuarterly, setEmployerQuarterly] = useLocalStorageState<
    EmployerQuarterlyState & { environment: string }
  >(EMPLOYER_QUARTERLY_KEY, {
    defaultValue
  });

  useEffect(() => {
    if (shouldRefresh(employerQuarterly.TTL)) {
      fetchEmployerQuarterly().then(newSlice => {
        if (newSlice) {
          setEmployerQuarterly(current => ({
            ...current,
            slice: {
              ...newSlice
            },
            // 10 min
            TTL: Date.now() + 1000 * 600
          }));
        } else {
          setEmployerQuarterly(current => ({
            ...current,
            slice: null
          }));
        }
      });
    }
  }, [employerQuarterly, setEmployerQuarterly]);
};

export const useEmployerQuarterly = () => {
  const [employerQuarterly] = useLocalStorageState<
    EmployerQuarterlyState & { environment: string }
  >(EMPLOYER_QUARTERLY_KEY);

  return employerQuarterly ?? defaultValue;
};
