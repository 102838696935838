export enum FileExtensions {
  'EXCEL' = 'xls',
  'PDF' = 'pdf',
  'CSV' = 'csv',
  'DOC' = 'doc',
  'DOCX' = 'docx',
  'JPEG' = 'jpeg',
  'JPG' = 'jpg',
  'PNG' = 'png',
  'XLS' = 'xls',
  'XLSX' = 'xlsx',
  'TXT' = 'txt',
  'XLSM' = 'xlsm'
}
