import { all, takeLatest, select, call, put } from 'redux-saga/effects';

import { EapAction, RootState } from 'types';
import { AnrNotReconciled119, AnrReconciled119 } from 'interfaces';
import {
  getAnrDashboardInfo,
  getAnrDashboardRequestReport,
  getAnrDashboardRequestSummary,
  refreshAnrDashboard
} from 'services';
import { getApiErrorMessage, viewPdf } from 'utils';
import {
  startLoadingAction,
  clearLoadingAction
} from 'containers/auth/userSessionActions';
import {
  notifyErrorAction,
  clearNotifications
} from 'containers/notifications/notificationsActions';
import { responseTypes } from 'types/response-type';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import {
  annualDashboardActionTypes,
  getAnrDashboardInfoSuccessAction,
  getAnrDashboardInfoFailedAction,
  setActiveNotReconciledAnrDashboardAction,
  setActiveReconciledAnrDashboardAction,
  getActiveReconciledAnr125ReportSuccessAction,
  getActiveReconciledAnr125ReportFailedAction,
  getActiveReconciledSummaryReportSuccessAction,
  getActiveReconciledSummaryReportFailedAction,
  requestAnrRefreshDashboardDataSuccessAction,
  requestAnrRefreshDashboardDataFailedAction,
  updateAnrUpdatedTimestampAction
} from './annualDashboardActions';

export function* getAnrDashboardInfoSaga(): any {
  yield put(startLoadingAction());

  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );

  const res = yield call(
    safeAuthEffect,
    getAnrDashboardInfo,
    selectGroupNumber
  );

  // sort both not reconciled and reconciled to ensure ordered by reconciliation year
  const sortedNotReconciled119 = [
    ...(res.data?.dashboardModel?.notReconciled119 ?? [])
  ].sort(
    (a: AnrNotReconciled119, b: AnrNotReconciled119) =>
      a.reconciliationYear - b.reconciliationYear
  );

  const sortedReconciled119 = [
    ...(res.data?.dashboardModel?.reconciled119 ?? [])
  ].sort(
    (a: AnrReconciled119, b: AnrReconciled119) =>
      parseInt(b.year, 10) - parseInt(a.year, 10)
  );

  if (res?.data) {
    yield put(clearNotifications());

    yield put(
      getAnrDashboardInfoSuccessAction({
        notReconciled119: sortedNotReconciled119,
        reconciled119: sortedReconciled119
      })
    );

    yield put(
      updateAnrUpdatedTimestampAction({
        timestamp:
          sortedNotReconciled119.length > 0
            ? sortedNotReconciled119[0]?.timeStampString
            : null,
        refreshRequestEmployer: selectGroupNumber
      })
    );

    if (sortedNotReconciled119.length > 0) {
      yield put(
        setActiveNotReconciledAnrDashboardAction(sortedNotReconciled119[0])
      );
    } else {
      yield put(
        setActiveReconciledAnrDashboardAction(
          sortedReconciled119.length > 0 ? sortedReconciled119[0] : null
        )
      );
    }
  } else {
    yield put(getAnrDashboardInfoFailedAction());
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* getActiveAnr125ReportSaga(action: EapAction): any {
  if (
    action.type ===
    annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_PANEL_REQUESTED
  ) {
    yield put(startLoadingAction());
  }

  const res = yield call(
    safeAuthEffect,
    getAnrDashboardRequestReport,
    action.data
  );

  if (
    res?.type === responseTypes.OCTET_STREAM ||
    res?.type === responseTypes.PDF
  ) {
    yield call(viewPdf, res, 'Form 119 RPP and RCA Reconciliation Report');
    yield put(clearNotifications());
    yield put(getActiveReconciledAnr125ReportSuccessAction());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(getActiveReconciledAnr125ReportFailedAction());
  }

  if (
    action.type ===
    annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_PANEL_REQUESTED
  ) {
    yield put(clearLoadingAction());
  }
}

export function* getActiveSummaryReportSaga(action: EapAction): any {
  yield put(startLoadingAction());
  const res = yield call(
    safeAuthEffect,
    getAnrDashboardRequestSummary,
    action.data
  );
  yield put(clearLoadingAction());

  if (
    (res && res.type && res.type === responseTypes.OCTET_STREAM) ||
    (res && res.type && res.type === responseTypes.PDF)
  ) {
    yield call(viewPdf, res, 'Form 119 Reconciliation Summary');
    yield put(clearNotifications());
    yield put(getActiveReconciledSummaryReportSuccessAction());
  } else {
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
    yield put(getActiveReconciledSummaryReportFailedAction());
  }
}

export function* requestAnrRefreshDashboardDataSaga(): any {
  yield put(startLoadingAction());

  const { token } = yield select((state: RootState) => state.userSession);
  const { selectGroupNumber } = yield select(
    (state: RootState) => state.selectEmployers
  );

  const res = yield call(
    safeAuthEffect,
    refreshAnrDashboard,
    selectGroupNumber,
    token
  );

  // handle error 12308 as success, error means refresh in progress
  if (
    !res?.errors ||
    (res?.errors?.length > 0 && res.errors[0].code === '12308')
  ) {
    yield put(requestAnrRefreshDashboardDataSuccessAction(selectGroupNumber));
  } else {
    yield put(requestAnrRefreshDashboardDataFailedAction(selectGroupNumber));
    yield put(notifyErrorAction(getApiErrorMessage(res?.errors)));
  }

  yield put(clearLoadingAction());
}

export function* annualDashboardSaga(): any {
  yield all([
    yield takeLatest(
      annualDashboardActionTypes.GET_ANR_DASHBOARD_INFO_REQUESTED,
      getAnrDashboardInfoSaga
    ),
    yield takeLatest(
      [
        annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_REQUESTED,
        annualDashboardActionTypes.GET_ACTIVE_RECONCILED_ANR_125_REPORT_PANEL_REQUESTED
      ],
      getActiveAnr125ReportSaga
    ),
    yield takeLatest(
      annualDashboardActionTypes.GET_ACTIVE_RECONCILED_SUMMARY_REPORT_REQUESTED,
      getActiveSummaryReportSaga
    ),
    yield takeLatest(
      annualDashboardActionTypes.REQUEST_ANR_REFRESH_DASHBOARD_DATA,
      requestAnrRefreshDashboardDataSaga
    )
  ]);
}
