import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import {
  ExceptionsObject,
  MemberSearchResult,
  ServicePeriodModel
} from 'interfaces';
import { Form190Data } from './form190Reducer';

export enum form190ActionTypes {
  UNLOCK_FORM_190 = 'UNLOCK_FORM_190',
  SET_REQUEST_NUMBER_FORM_190 = 'SET_REQUEST_NUMBER_FORM_190',
  CREATE_FORM_190 = 'CREATE_FORM_190',
  GET_FORM_190_REQUESTED = 'GET_FORM_190_REQUESTED',
  GET_FORM_190_SUCCESS = 'GET_FORM_190_SUCCESS',
  GET_FORM_190_FAILED = 'GET_FORM_190_FAILED',
  SET_FORM_190_STEP = 'SET_FORM_190_STEP',
  CLEAR_FORM_190_STATUS = 'CLEAR_FORM_190_STATUS',
  CLEAR_FORM_190_DATA = 'CLEAR_FORM_190_DATA',
  ADD_SERVICE_PERIOD_190_REQUESTED = 'ADD_SERVICE_PERIOD_190_REQUESTED',
  ADD_SERVICE_PERIOD_190_SUCCESS = 'ADD_SERVICE_PERIOD_190_SUCCESS',
  ADD_SERVICE_PERIOD_190_FAILED = 'ADD_SERVICE_PERIOD_190_FAILED',
  REMOVE_SERVICE_PERIOD_190_REQUESTED = 'REMOVE_SERVICE_PERIOD_190_REQUESTED',
  REMOVE_SERVICE_PERIOD_190_SUCCESS = 'REMOVE_SERVICE_PERIOD_190_SUCCESS',
  REMOVE_SERVICE_PERIOD_190_FAILED = 'REMOVE_SERVICE_PERIOD_190_FAILED',
  SAVE_FORM_190_REQUESTED = 'SAVE_FORM_190_REQUESTED',
  SAVE_FORM_190_SUCCEED = 'SAVE_FORM_190_SUCCEED',
  SAVE_FORM_190_FAILED = 'SAVE_FORM_190_FAILED',
  SAVE_RETRO_PAY_190_REQUESTED = 'SAVE_RETRO_PAY_190_REQUESTED',
  SAVE_RETRO_PAY_190_FAILED = 'SAVE_RETRO_PAY_190_FAILED',
  SAVE_RETRO_PAY_190_SUCCESS = 'SAVE_RETRO_PAY_190_SUCCESS',
  GET_LATEST_FORM_190_REQUESTED = 'GET_LATEST_FORM_190_REQUESTED',
  GET_LATEST_FORM_190_SUCCESS = 'GET_LATEST_FORM_190_SUCCESS',
  GET_LATEST_FORM_190_FAILED = 'GET_LATEST_FORM_190_FAILED',
  GET_RETRO_PAY_190_REQUESTED = 'GET_RETRO_PAY_190_REQUESTED',
  GET_RETRO_PAY_190_SUCCESS = 'GET_RETRO_PAY_190_SUCCESS',
  DELETE_RETRO_PAY_190_REQUESTED = 'DELETE_RETRO_PAY_190_REQUESTED',
  STASH_DIRTY_VALUES = 'STASH_DIRTY_VALUES',
  DELETE_FORM_190 = 'DELETE_FORM_190',
  OVERRIDE_FORM_190_EXCEPTIONS = 'OVERRIDE_FORM_190_EXCEPTIONS',
  SUBMIT_FORM_190 = 'SUBMIT_FORM_190',
  SUBMIT_FORM_190_SUCCESS = 'SUBMIT_FORM_190_SUCCESS',
  SET_FORM_190_SUBMIT_STATUS = 'SET_FORM_190_SUBMIT_STATUS'
}

export const stashDirtyValues: ActionCreator<EapAction> = (data: any) => ({
  type: form190ActionTypes.STASH_DIRTY_VALUES,
  data
});

export const unlockForm190: ActionCreator<EapAction> = (data: any) => ({
  type: form190ActionTypes.UNLOCK_FORM_190,
  data
});

export const setForm190RequestNumberAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form190ActionTypes.SET_REQUEST_NUMBER_FORM_190,
  data
});

export const createForm190Action: ActionCreator<EapAction> = (data: {
  memberSearchResult: MemberSearchResult;
}) => ({
  type: form190ActionTypes.CREATE_FORM_190,
  data
});

export const getForm190Action: ActionCreator<EapAction> = (data: string) => ({
  type: form190ActionTypes.GET_FORM_190_REQUESTED,
  data
});

export const getForm190SuccessAction: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  notes: string;
  commentsToMember: string;
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form190ActionTypes.GET_FORM_190_SUCCESS,
  data
});

export const getForm190FailedAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.GET_FORM_190_FAILED
});

export const setForm190StepAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form190ActionTypes.SET_FORM_190_STEP,
  data
});

export const clearForm190DataAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.CLEAR_FORM_190_DATA
});

export const clearForm190StatusAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.CLEAR_FORM_190_STATUS
});

export const addServicePeriodForm190Action: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form190ActionTypes.ADD_SERVICE_PERIOD_190_REQUESTED,
  data
});

export const addServicePeriodForm190SuccessAction: ActionCreator<EapAction> = (
  data: ServicePeriodModel
) => ({
  type: form190ActionTypes.ADD_SERVICE_PERIOD_190_SUCCESS,
  data
});

export const addServicePeriodForm190FailedAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.ADD_SERVICE_PERIOD_190_FAILED
});

export const removeServicePeriodForm190Action: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  servicePeriodNumber: number;
}) => ({
  type: form190ActionTypes.REMOVE_SERVICE_PERIOD_190_REQUESTED,
  data
});

export const removeServicePeriodForm190SuccessAction: ActionCreator<EapAction> = (
  data: number
) => ({
  type: form190ActionTypes.REMOVE_SERVICE_PERIOD_190_SUCCESS,
  data
});

export const removeServicePeriodForm190FailedAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.REMOVE_SERVICE_PERIOD_190_FAILED
});

export const saveForm190Action: ActionCreator<EapAction> = (data: {
  formData: Form190Data;
  commentsToMember: string;
  notes: string;
  exceptions?: Array<ExceptionsObject>;
  reviewForm?: boolean;
  submitForm?: boolean;
}) => ({
  type: form190ActionTypes.SAVE_FORM_190_REQUESTED,
  data
});

export const saveForm190SuccessAction: ActionCreator<EapAction> = (data: {
  commentsToMember: string;
  notes: string;
}) => ({
  type: form190ActionTypes.SAVE_FORM_190_SUCCEED,
  data
});

export const saveForm190FailedAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.SAVE_FORM_190_FAILED
});

export const getLatest190Action: ActionCreator<EapAction> = (data: {
  requestNumber: string;
  disableSpinner?: boolean;
}) => ({
  type: form190ActionTypes.GET_LATEST_FORM_190_REQUESTED,
  data
});

export const getLatest190SuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: form190ActionTypes.GET_LATEST_FORM_190_SUCCESS,
  data
});

export const getLatestForm190FailureAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.GET_LATEST_FORM_190_FAILED
});

export const getRetroPay190Action: ActionCreator<EapAction> = (data: {
  servicePeriodNo: number;
  requestNumber: string;
}) => ({
  type: form190ActionTypes.GET_RETRO_PAY_190_REQUESTED,
  data
});

export const getRetroPay190SuccessAction: ActionCreator<EapAction> = (data: {
  retroPayModels: Array<any>;
  servicePeriodNo: number;
}) => ({
  type: form190ActionTypes.GET_RETRO_PAY_190_SUCCESS,
  data
});

export const saveRetroPayAction: ActionCreator<EapAction> = (data: {
  servicePeriodNo: number;
  payload: any;
}) => ({
  type: form190ActionTypes.SAVE_RETRO_PAY_190_REQUESTED,
  data
});

export const saveRetroPaySuccessAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.SAVE_RETRO_PAY_190_SUCCESS
});

export const saveRetroPayFailedAction: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.SAVE_RETRO_PAY_190_FAILED
});

export const deleteRetroPay190Action: ActionCreator<EapAction> = (data: {
  servicePeriodNo: number;
  requestNumber: string;
}) => ({
  type: form190ActionTypes.DELETE_RETRO_PAY_190_REQUESTED,
  data
});

export const deleteForm190Action: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.DELETE_FORM_190
});

export const overrideForm190ExceptionsAction: ActionCreator<EapAction> = (
  data: Array<ExceptionsObject>
) => ({
  type: form190ActionTypes.OVERRIDE_FORM_190_EXCEPTIONS,
  data
});

export const submitForm190Action: ActionCreator<EapAction> = () => ({
  type: form190ActionTypes.SUBMIT_FORM_190
});

export const submitForm190SuccessAction: ActionCreator<EapAction> = (data: {
  exceptions: Array<ExceptionsObject>;
}) => ({
  type: form190ActionTypes.SUBMIT_FORM_190_SUCCESS,
  data
});

export const setForm190SubmitStatusAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: form190ActionTypes.SET_FORM_190_SUBMIT_STATUS,
  data
});
