import {
  Classes,
  Tag as TagBP,
  TagProps as TagBPProps,
  Intent
} from '@blueprintjs/core';
import Icon from '../icon/Icon';
import { IconNamesStatus } from '../icon/Icons';

const statusStyle = (type: 'pill' | 'tag', inactive: boolean) => ({
  fontFamily: 'body',
  fontSize: 'caption',
  fontWeight: '600',
  lineHeight: 'base',
  minWidth: 'fit-content',

  [`&.${Classes.TAG}.${Classes.INTENT_PRIMARY}`]: {
    backgroundColor: 'extendedBlue25',
    color: type === 'pill' ? 'darkGreyText' : 'extendedBlue100',
    border: type === 'pill' ? '1px solid' : 'none',
    borderColor: 'extendedBlue100',
    '&:hover': {
      backgroundColor: 'extendedBlue25',
      opacity: type === 'pill' ? 0.7 : 1
    },
    '&:active': {
      opacity: 1
    },
    [`&.${Classes.ACTIVE}`]: {
      backgroundColor: 'extendedBlue25'
    }
  },
  [`&.${Classes.TAG}.${Classes.INTENT_WARNING}`]: {
    color: type === 'pill' ? 'darkGreyText' : 'brandOrangeWeb',
    backgroundColor: 'extendedYellow25',
    border: type === 'pill' ? '1px solid' : 'none',
    borderColor: 'extendedYellow100',
    '&:hover': {
      backgroundColor: 'extendedYellow25',
      opacity: type === 'pill' ? 0.7 : 1
    },
    '&:active': {
      opacity: 1
    },
    [`&.${Classes.ACTIVE}`]: {
      backgroundColor: 'extendedYellow25'
    }
  },
  [`&.${Classes.TAG}.${Classes.INTENT_DANGER}`]: {
    color: type === 'pill' ? 'darkGreyText' : 'extendedRed100',
    backgroundColor: 'extendedRed25',
    border: type === 'pill' ? '1px solid' : 'none',
    borderColor: 'extendedRed100',
    '&:hover': {
      backgroundColor: 'extendedRed25',
      opacity: type === 'pill' ? 0.7 : 1
    },
    '&:active': {
      opacity: 1
    },
    [`&.${Classes.ACTIVE}`]: {
      backgroundColor: 'extendedRed25'
    }
  },
  [`&.${Classes.TAG}.${Classes.INTENT_SUCCESS}`]: {
    color: type === 'pill' ? 'darkGreyText' : 'extendedGreen100',
    backgroundColor: 'extendedGreen25',
    border: type === 'pill' ? '1px solid' : 'none',
    borderColor: 'extendedGreen100',
    '&:hover': {
      backgroundColor: 'extendedGreen25',
      opacity: type === 'pill' ? 0.7 : 1
    },
    '&:active': {
      opacity: 1
    },
    [`&.${Classes.ACTIVE}`]: {
      backgroundColor: 'extendedGreen25'
    }
  },
  [`&.${Classes.TAG}`]: {
    backgroundColor: inactive ? 'grey40' : 'white',
    border: type === 'pill' ? '1px solid' : 'none',
    borderColor: inactive ? 'grey100' : 'black',
    color: 'black',
    height: type === 'pill' ? '2rem' : '1.625rem',
    padding: type === 'pill' ? '0.3125rem 0.9375rem' : '0.1875rem 0.5rem',
    '&:hover': {
      backgroundColor: inactive ? 'grey40' : 'white'
    },
    '&:active': {
      opacity: 1
    },
    '&:focus': {
      outline: 'none'
    },
    '&:focus-visible': {
      outline: 'solid 2px',
      outlineColor: 'extendedBlue100',
      outlineOffset: '1px'
    },
    [`&.${Classes.ACTIVE}`]: {
      backgroundColor: inactive ? 'grey40' : 'white'
    }
  },
  [`.${Classes.TEXT_OVERFLOW_ELLIPSIS}`]: {
    gap: '0.625rem'
  },
  '.text': {
    textOverflow: undefined,
    whiteSpace: undefined,
    overflow: undefined
  },
  '> span': {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    svg: {
      maxWidth: '20px',
      maxHeight: '20px',
      marginRight: '0px'
    }
  }
});

export type TagIntent = 'default' | 'info' | 'warning' | 'success' | 'error';

export interface TagProps
  extends Omit<TagBPProps, 'icon' | 'rightIcon' | 'intent' | 'large'> {
  intent?: TagIntent;
  inactive?: boolean;
  showIcon?: boolean;
  text?: string;
  'data-testid'?: string;
  id: string;
  type?: 'pill' | 'tag';
}

const intentMap: Record<TagIntent, Intent> = {
  default: 'none',
  info: 'primary',
  warning: 'warning',
  success: 'success',
  error: 'danger'
};

const StatusIndicator: React.FC<TagProps> = ({
  intent = 'default',
  inactive = false,
  text,
  type = 'pill',
  onClick,
  showIcon = false,
  id,
  ...props
}) => {
  const icon = {
    name: (() => {
      switch (intent) {
        case 'info':
          return IconNamesStatus.INFO;
        case 'warning':
          return IconNamesStatus.WARNING;
        case 'success':
          return IconNamesStatus.COMPLETED;
        case 'error':
          return IconNamesStatus.DANGER;
        default:
          return undefined;
      }
    })()
  };

  return (
    <TagBP
      {...props}
      id={id}
      round={type === 'pill'}
      intent={inactive ? 'none' : intentMap[intent]}
      interactive={type === 'pill'}
      onClick={onClick}
      data-testid={props['data-testid'] ?? `${id}-status`}
      sx={{
        ...statusStyle(type, !!inactive)
      }}
    >
      {showIcon && icon.name && <Icon icon={icon.name} />}
      {text && <span className="text">{text}</span>}
    </TagBP>
  );
};

export default StatusIndicator;
