export enum DonnaBusinessConfigNames {
  TERMINATION = 'TERMINATION',
  RETIREMENT = 'RETIREMENT'
}

export interface DonnaBusinessConfig {
  configName: string;
  configValue: {
    type: string;
    value: boolean;
  };
  description: string;
}
