/** @jsxImportSource theme-ui */
import { Divider as DividerBP, IDividerProps } from '@blueprintjs/core';
import { ThemeUICSSObject } from 'theme-ui';

export interface DividerProps extends IDividerProps {
  vertical?: boolean;
  condensed?: boolean;
  borderStyle?: 'dotted' | 'dashed' | 'solid';
  borderWidth?: string;
  sx?: ThemeUICSSObject;
  id: string;
}
/**
 * See: https://blueprintjs.com/docs/#core/components/divider.props
 */
const Divider: React.FC<DividerProps> = ({
  vertical,
  condensed,
  borderStyle = 'solid',
  borderWidth = '1px',
  id,
  sx,
  ...props
}) => (
  <DividerBP
    {...props}
    id={id}
    sx={{
      mx: (() => {
        if (vertical) {
          return condensed ? '14px' : '20px';
        }

        return 0;
      })(),
      my: (() => {
        if (!vertical) {
          return condensed ? '14px' : '20px';
        }

        return 0;
      })(),
      borderColor: 'grey80',
      borderStyle,
      borderWidth,
      borderLeftWidth: '0',
      borderTopWidth: '0',
      ...sx
    }}
    data-testid="divider"
  />
);

export default Divider;
