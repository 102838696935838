import { datadogRum } from '@datadog/browser-rum';
import { env } from '@omers-packages/next-isomorphic-runtime-env';

const NEXT_PUBLIC_APP_ENV = env('NEXT_PUBLIC_APP_ENV') as string;
const NEXT_PUBLIC_APP_URL = env('NEXT_PUBLIC_APP_URL') as string;
const NEXT_PUBLIC_ADMIN_APP_URL = env('NEXT_PUBLIC_ADMIN_APP_URL') as string;
const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL') as string;
const NEXT_PUBLIC_DATADOG_APPLICATION_ID = env(
  'NEXT_PUBLIC_DATADOG_APPLICATION_ID'
) as string;
const NEXT_PUBLIC_DATADOG_CLIENT_TOKEN = env(
  'NEXT_PUBLIC_DATADOG_CLIENT_TOKEN'
) as string;
const NEXT_PUBLIC_DONNA_GATEWAY_API_HOST = env(
  'NEXT_PUBLIC_DONNA_GATEWAY_API_HOST'
) as string;
const NEXT_PUBLIC_GIT_TAG = env('NEXT_PUBLIC_GIT_TAG') as string;
const NEXT_PUBLIC_GIT_SHA = env('NEXT_PUBLIC_GIT_SHA') as string;

if (typeof window !== 'undefined') {
  if (NEXT_PUBLIC_APP_ENV && NEXT_PUBLIC_APP_ENV !== 'local') {
    datadogRum.init({
      applicationId: NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.eu',
      service: 'eaccess-front-end',
      env: NEXT_PUBLIC_APP_ENV,
      version: NEXT_PUBLIC_GIT_TAG || `v-${NEXT_PUBLIC_GIT_SHA}`,
      sessionSampleRate: 100,
      allowedTracingUrls: [
        NEXT_PUBLIC_APP_URL,
        NEXT_PUBLIC_ADMIN_APP_URL,
        NEXT_PUBLIC_API_PROXY_URL,
        NEXT_PUBLIC_DONNA_GATEWAY_API_HOST
      ],
      trackUserInteractions: NEXT_PUBLIC_APP_ENV !== 'prod',
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask'
    });

    if (NEXT_PUBLIC_APP_ENV !== 'prod') {
      datadogRum.startSessionReplayRecording();
    }
  }
}
