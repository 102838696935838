import { EapAction } from 'types';
import { resetPasswordActionTypes } from './resetPasswordActions';

export interface ResetPasswordData {
  username: string;
  lastName: string;
  email: string;
}

export interface ResetPasswordState {
  resetPasswordData: ResetPasswordData;
  step: number;
  showForgotUsername: boolean;
}

const resetPasswordData: ResetPasswordData = {
  username: '',
  lastName: '',
  email: ''
};

export const initialState: ResetPasswordState = {
  resetPasswordData,
  step: 1,
  showForgotUsername: false
};

const resetPassword = (
  state: ResetPasswordState = initialState,
  action: EapAction
): ResetPasswordState => {
  const { type } = action;
  switch (type) {
    case resetPasswordActionTypes.INIT_RESET_PASSWORD:
      return {
        ...state,
        step: 1,
        showForgotUsername: false
      };
    case resetPasswordActionTypes.SUBMIT_RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        step: 2
      };
    case resetPasswordActionTypes.SHOW_FORGOT_USERNAME:
      return {
        ...state,
        step: 2,
        showForgotUsername: true
      };
    default:
      return state;
  }
};

export default resetPassword;
