export type RequestsQuery = {
  startIndex?: number;
  rowLimit?: number;
  status?: number;
  requestType?: string | null;
  sin?: string | null;
  employeeId?: string | null;
  fromDate?: string | null;
  toDate?: string | null;
  formMonth?: string | null;
  formYear?: string | null;
  reconciliationYear?: string | null;
  reason?: string | null;
  fromName?: string | null;
  toName?: string | null;
  employmentStatus?: string | null;
  NRA?: string | null;
  sortByEmployeeId?: boolean | null;
  sortByDeptId?: boolean | null;
  searchAscending?: boolean;
  sortByLastName?: boolean;
  icAdjustmentInd?: 'Y' | 'N' | null;
  idDrillDownInd?: 'Y' | 'N' | null;
  drillDownTotalRowCount?: number | null;
  membershipSubstatus?: string | null;
  singleOrAllMemberSearch?: string | null;
  owner?: string | null;
  membershipNumber?: number | null;
  toFirstName?: string | null;
  fromFirstName?: string | null;
};

export const defaultQuery: RequestsQuery = {
  status: 1,
  requestType: null,
  sin: null,
  employeeId: null,
  fromDate: null,
  toDate: null,
  formMonth: null,
  formYear: null,
  reconciliationYear: null,
  reason: null,
  fromName: null,
  toName: null,
  employmentStatus: null,
  NRA: null,
  sortByEmployeeId: null,
  sortByDeptId: null,
  searchAscending: false,
  sortByLastName: false,
  startIndex: 0,
  rowLimit: 20,
  icAdjustmentInd: null,
  idDrillDownInd: null,
  drillDownTotalRowCount: null,
  membershipSubstatus: null,
  singleOrAllMemberSearch: null,
  owner: null,
  membershipNumber: null,
  toFirstName: null,
  fromFirstName: null
};

/**
 * Build Requests Search filtered query object with given query params and
 * fills rest of query with defaults
 */
export const getRequestsQuery = (query: RequestsQuery): RequestsQuery => ({
  ...defaultQuery,
  ...query
});

// Returns the previous month as a Contribution month of the given month
export const getContributionMonth = (month: string) => {
  switch (month) {
    case 'January':
      return 'common:DECEMBER_SHORT_WITH_CONTRIBUTION';
    case 'February':
      return 'common:JANUARY_SHORT_WITH_CONTRIBUTION';
    case 'March':
      return 'common:FEBRUARY_SHORT_WITH_CONTRIBUTION';
    case 'April':
      return 'common:MARCH_SHORT_WITH_CONTRIBUTION';
    case 'May':
      return 'common:APRIL_SHORT_WITH_CONTRIBUTION';
    case 'June':
      return 'common:MAY_SHORT_WITH_CONTRIBUTION';
    case 'July':
      return 'common:JUNE_SHORT_WITH_CONTRIBUTION';
    case 'August':
      return 'common:JULY_SHORT_WITH_CONTRIBUTION';
    case 'September':
      return 'common:AUGUST_SHORT_WITH_CONTRIBUTION';
    case 'October':
      return 'common:SEPTEMBER_SHORT_WITH_CONTRIBUTION';
    case 'November':
      return 'common:OCTOBER_SHORT_WITH_CONTRIBUTION';
    case 'December':
      return 'common:NOVEMBER_SHORT_WITH_CONTRIBUTION';
    default:
      return 'common:NOT_AVAILABLE';
  }
};
