import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Intent, Position, Toaster as ToasterBP } from '@blueprintjs/core';
import { useSelector } from 'react-redux';
import Image from 'next/image';

import { RootState } from 'types';

import errorIcon from '../../../public/images/toaster/error.svg';
import warnIcon from '../../../public/images/toaster/warn.svg';
import successIcon from '../../../public/images/toaster/success.svg';
import infoIcon from '../../../public/images/toaster/info.svg';

import { ToastLevel } from './toasterReducer';
import styles from './Toaster.module.scss';

const iconMap: Record<ToastLevel, JSX.Element> = {
  [ToastLevel.ERROR]: (
    <Image src={errorIcon} alt="Error toast" className={styles.icon} />
  ),
  [ToastLevel.WARNING]: (
    <Image src={warnIcon} alt="Warn toast" className={styles.icon} />
  ),
  [ToastLevel.SUCCESS]: (
    <Image src={successIcon} alt="Success toast" className={styles.icon} />
  ),
  [ToastLevel.INFO]: (
    <Image src={infoIcon} alt="Info toast" className={styles.icon} />
  )
};

const intentMap: Record<ToastLevel, Intent> = {
  [ToastLevel.ERROR]: Intent.DANGER,
  [ToastLevel.WARNING]: Intent.WARNING,
  [ToastLevel.SUCCESS]: Intent.SUCCESS,
  [ToastLevel.INFO]: Intent.PRIMARY
};

const Toaster: React.FC = () => {
  const { t } = useTranslation();
  const toasterRef = useRef<ToasterBP>(null);
  const { toast } = useSelector((state: RootState) => state.toaster);

  useEffect(() => {
    if (toast && toasterRef.current) {
      const newToast = {
        ...toast,
        icon: iconMap[toast.level],
        intent: intentMap[toast.level],
        message:
          typeof toast.message === 'string' && toast.translate
            ? t(toast.message, toast.translateProps)
            : toast.message
      };

      toasterRef.current.show(newToast);
    }
  }, [toast]);

  return (
    <ToasterBP
      position={Position.TOP}
      ref={toasterRef}
      className={styles.toaster}
    />
  );
};

export default Toaster;
