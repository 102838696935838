import { all, fork, takeLatest, put } from 'redux-saga/effects';

import { rootReducerActionTypes } from 'store/actions';
import { clearIdleAction } from 'containers/routeConfirm/idleActionActions';
import { idleActionSaga } from 'containers/routeConfirm/idleActionSaga';
import {
  clearLoadingAction,
  clearLogoutNotificationAction
} from 'containers/auth/userSessionActions';
import { clearNotifications } from 'containers/notifications/notificationsActions';
import { clearNavHistoryAction } from 'containers/navHistory/navHistoryActions';
import { userSessionSaga } from 'containers/auth/userSessionSaga';
import { selectEmployersSaga } from 'containers/selectEmployers/selectEmployersSaga';
import { memberProfileSaga } from 'containers/memberProfile/memberProfileSaga';
import { memberSearchSaga } from 'containers/memberSearch/memberSearchSaga';
import { fetchPropertiesSaga } from 'containers/properties/propertiesSagas';
import { fetchOverrideReasonsSaga } from 'containers/overrideReasons/overrideReasonsSagas';
import { formSaga } from 'containers/requestForm/common/formSaga';
import { nftSaga } from 'containers/nft/nftSaga';
import { form102Saga } from 'containers/requestForm/memberEnrolment/form102Saga';
import { form105Saga } from 'containers/requestForm/contributionRemittance/form105Saga';
import { form106Saga } from 'containers/requestForm/memberUpdate/form106Saga';
import { form119Saga } from 'containers/requestForm/annualReporting/form119Saga';
import { form143Saga } from 'containers/requestForm/memberEvent/form143Saga';
import { form165aSaga } from 'containers/requestForm/leavePeriodEmployer/form165aSaga';
import { form165bSaga } from 'containers/requestForm/leavePeriodMember/form165bSaga';
import { form165cSaga } from 'containers/requestForm/leavePeriodAdjustment/form165cSaga';
import { requestsSaga } from 'containers/requests/requestsSaga';
import { messagesSaga } from 'containers/messages/messagesSaga';
import { featureFlagsSaga } from 'features/featureFlags/featureFlagsSaga';
import { annualDashboardSaga } from 'containers/annualDashboard/annualDashboardSaga';
import { annualReconciliationSaga } from 'containers/annualReconciliation/annualReconciliationSaga';
import { batchProcessingSaga } from 'containers/batchProcessing/stateManagement/batchProcessingSaga';
import { navHistorySaga } from 'containers/navHistory/navHistorySaga';
import { returnedRequestsSaga } from 'containers/returnedRequests/ReturnedRequestsSaga';
import { formPDFSaga } from 'containers/requestForm/common/submitLandingPage/submitLandingPageSaga';
import { form190Saga } from 'containers/requestForm/pensionEstimate/form190Saga';
import { batchPrintSaga } from 'containers/batchPrint/BatchPrintSaga';
import { dataExtractSaga } from 'containers/dataExtract/stateManagement/dataExtractSaga';
import { resetPasswordSaga } from 'containers/resetPassword/resetPasswordSaga';
import { archivedMessagesSaga } from 'containers/archivedMessages/ArchivedMessagesSaga';
import { updatePasswordSaga } from 'containers/updatePassword/updatePasswordSaga';
import { esrSaga } from 'containers/stewardshipReport/esrSaga';
import { donnaBusinessConfigSaga } from 'containers/donnaBusinessConfig/donnaBusinessConfigSaga';
import { reportsSaga } from 'containers/reports/reportsSaga';
import { pollTaskWatcher } from '../polling/pollTaskWatcher';

// resets specific loaded states from redux-persist init load
export function* clearStatesSaga() {
  // clear fullscreen loading spinner if user refreshes app mid-load and loading flag still true
  yield all([
    put(clearLoadingAction()),
    put(clearLogoutNotificationAction()),
    put(clearNotifications()),
    put(clearIdleAction()),
    put(clearNavHistoryAction())
  ]);
}

export default function* rootSaga() {
  yield all([
    takeLatest(rootReducerActionTypes['persist/REHYDRATE'], clearStatesSaga),
    fork(idleActionSaga),
    fork(userSessionSaga),
    fork(fetchPropertiesSaga),
    fork(donnaBusinessConfigSaga),
    fork(fetchOverrideReasonsSaga),
    fork(selectEmployersSaga),
    fork(memberProfileSaga),
    fork(memberSearchSaga),
    fork(formSaga),
    fork(nftSaga),
    fork(form102Saga),
    fork(form105Saga),
    fork(form106Saga),
    fork(form119Saga),
    fork(form143Saga),
    fork(form165aSaga),
    fork(form165bSaga),
    fork(form165cSaga),
    fork(form190Saga),
    fork(requestsSaga),
    fork(messagesSaga),
    fork(featureFlagsSaga),
    fork(annualDashboardSaga),
    fork(annualReconciliationSaga),
    fork(batchProcessingSaga),
    fork(batchPrintSaga),
    fork(dataExtractSaga),
    fork(navHistorySaga),
    fork(pollTaskWatcher),
    fork(returnedRequestsSaga),
    fork(formPDFSaga),
    fork(resetPasswordSaga),
    fork(archivedMessagesSaga),
    fork(updatePasswordSaga),
    fork(esrSaga),
    fork(reportsSaga)
  ]);
}
