import { ActionCreator } from 'redux';
import { EapAction } from 'types';

export enum navHistoryActionTypes {
  SET_NAV_BACK_BTN_FALLBACK_ROUTE = 'SET_NAV_BACK_BTN_FALLBACK_ROUTE',
  SET_NAV_BACK_BTN_FALLBACK_ROUTE_COMPLETE = 'SET_NAV_BACK_BTN_FALLBACK_ROUTE_COMPLETE',
  CLEAR_NAV_HISTORY = 'CLEAR_NAV_HISTORY',
  PUSH_NAV_HISTORY = 'PUSH_NAV_HISTORY'
}

export const setNavBackBtnFallbackRouteAction: ActionCreator<EapAction> = (
  data: string | null
) => ({
  type: navHistoryActionTypes.SET_NAV_BACK_BTN_FALLBACK_ROUTE,
  data
});

export const setNavBackBtnFallbackRouteCompleteAction: ActionCreator<EapAction> = () => ({
  type: navHistoryActionTypes.SET_NAV_BACK_BTN_FALLBACK_ROUTE_COMPLETE
});

export const clearNavHistoryAction: ActionCreator<EapAction> = () => ({
  type: navHistoryActionTypes.CLEAR_NAV_HISTORY
});

export const pushNavHistoryAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: navHistoryActionTypes.PUSH_NAV_HISTORY,
  data
});
