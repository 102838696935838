import Link from 'next/link';
import { AnchorHTMLAttributes, PropsWithChildren } from 'react';
import { ExactTheme } from 'styles/theme';
import { ThemeUICSSObject, useThemeUI } from 'theme-ui';

export type LinkButtonProps = {
  href: string;
  id: string;
  color?: LinkButtonColor;
  variant?: LinkButtonVariant;
  isIconButton?: boolean;
  fill?: boolean;
  minWidth?: string;
  sxOverride?: ThemeUICSSObject;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export type LinkButtonVariant = 'default' | 'simple' | 'outline';
export type LinkButtonColor = 'orange' | 'blue';
type LinkButtonState = 'default' | 'interacting';

const LinkButton: React.FC<PropsWithChildren<LinkButtonProps>> = ({
  href,
  children,
  id,
  variant = 'default',
  color = 'orange',
  minWidth,
  isIconButton,
  fill,
  sxOverride,
  ...props
}) => {
  const ctx = useThemeUI();
  const theme = ctx.theme as ExactTheme;

  const getBackgroundColor = (state: LinkButtonState) => {
    if (variant === 'simple') return 'transparent';
    if (variant === 'default') {
      return state === 'default'
        ? theme.colors.brandOrangeWeb
        : theme.colors.extendedOrange100;
    }

    // remaining cases cover 'outline' variant which can be either orange or blue
    if (color === 'orange') {
      return state === 'interacting'
        ? theme.colors.extendedOrange25
        : 'transparent';
    }

    return state === 'interacting'
      ? theme.colors.extendedBlue25
      : 'transparent';
  };

  const getColor = (state: LinkButtonState) => {
    if (variant === 'default') return 'white';

    if (variant === 'outline' && color === 'orange') {
      return state === 'default'
        ? theme.colors.brandOrangeWeb
        : theme.colors.extendedOrange100;
    }

    if (variant === 'outline' && color === 'blue') {
      return state === 'default'
        ? theme.colors.brandNavy100
        : theme.colors.extendedBlue100;
    }

    return state === 'default'
      ? theme.colors.extendedBlue100
      : theme.colors.brandNavy100;
  };

  const getBorder = (state: LinkButtonState) => {
    if (variant === 'simple') return '1.5px solid transparent';

    if (variant === 'default') {
      return state === 'default'
        ? `1.5px solid ${theme.colors.brandOrangeWeb}`
        : `1.5px solid ${theme.colors.extendedOrange100}`;
    }

    if (color === 'orange') {
      return state === 'default'
        ? `1.5px solid ${theme.colors.brandOrangeWeb}`
        : `1.5px solid ${theme.colors.extendedOrange100}`;
    }

    return state === 'default'
      ? `1.5px solid ${theme.colors.brandNavy100}`
      : `1.5px solid ${theme.colors.extendedBlue100}`;
  };

  return (
    <Link
      href={href}
      {...props}
      id={id}
      data-testid={id}
      sx={{
        boxShadow: 'none',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: isIconButton ? '0.875rem' : '0.75rem 1rem',
        fontStyle: 'normal',
        lineHeight: '1.5rem',
        height: 'fit-content',
        backgroundColor: getBackgroundColor('default'),
        border: getBorder('default'),
        color: getColor('default'),
        fontSize: variant === 'simple' ? '1.125rem' : '1.1875rem',
        fontWeight: variant === 'simple' ? '400' : '700',
        width: fill ? '100%' : 'unset',
        gap: '0.5rem',
        minWidth,
        svg: {
          color: getColor('default')
        },
        '&:focus-visible': {
          outlineStyle: 'solid',
          outlineWidth: '2px',
          outlineColor: theme.colors.extendedBlue100,
          outlineOffset: '2px',
          textDecoration: 'none'
        },
        '&:focus:not(:focus-visible)': {
          outline: 'none'
        },
        '&:hover, &:active': {
          backgroundColor: getBackgroundColor('interacting'),
          border: getBorder('interacting'),
          color: getColor('interacting'),
          svg: {
            color: getColor('interacting')
          },
          textDecoration: 'none'
        },
        '&:active': {
          textDecoration: 'underline'
        },
        textDecoration: 'none',
        ...sxOverride
      }}
    >
      {children}
    </Link>
  );
};

export default LinkButton;
