import { DataObj } from 'types';
import { env } from '@omers-packages/next-isomorphic-runtime-env';
import { deleteData, getData, postData, putData } from './services';

const NEXT_PUBLIC_API_PROXY_URL = env('NEXT_PUBLIC_API_PROXY_URL');

export const createForm105 = (data: DataObj) =>
  postData(`${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105`, data);

export const getForm105 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/${requestNumber}`,
    undefined,
    requestNumber
  );

export const removeAddedServicePurchaseForm105 = (
  data: DataObj,
  requestNumber: string
) =>
  deleteData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/delete-service-purchases/${requestNumber}`,
    undefined,
    requestNumber,
    data
  );

export const saveForm105 = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const saveForm105Summary = (data: DataObj, requestNumber: string) =>
  putData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/summary/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const submitForm105 = (requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/submit/${requestNumber}`,
    undefined,
    undefined,
    requestNumber
  );

export const searchServicePurchases = (data: DataObj, requestNumber: string) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/search-service-purchases/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const addReportedServicePurchase = (
  data: DataObj,
  requestNumber: string
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/add-reported-service-purchases/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const addUnreportedServicePurchase = (
  data: DataObj,
  requestNumber: string
) =>
  postData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/add-unreported-service-purchases/${requestNumber}`,
    data,
    undefined,
    requestNumber
  );

export const getSummaryForForm105 = (requestNumber: string) =>
  getData(
    `${NEXT_PUBLIC_API_PROXY_URL}/api/v1/requests/e105/summary/${requestNumber}`,
    undefined,
    requestNumber
  );
