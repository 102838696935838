import { FocusEventHandler } from 'react';
import { useTranslation } from 'react-i18next';
import { Classes, FormGroup, FormGroupProps } from '@blueprintjs/core';
import {
  ItemRenderer,
  ItemPredicate,
  Select2,
  Select2Props as SelectBPProps
} from '@blueprintjs/select';
import { ThemeUICSSObject, ThemeUIStyleObject } from 'theme-ui';
import { textEllipsisMixin } from 'utils/theme-ui/mixins';
import { transparentize } from '@theme-ui/color';
import MenuItem from '../menuItem/MenuItem';
import Button from '../button/Button';
import Icon, { IconNames } from '../icon/Icon';

export interface SelectMenuItem<T extends string = string> {
  key?: string;
  value: T | null;
  text?: T | string; // visually displayed text; falls back to value if missing
  data?: any; // optional additional data to attach to select item
  disabled?: boolean;
}

export type FormGroupSelectProps<T extends string> = FormGroupProps &
  Omit<SelectBPProps<SelectMenuItem<T>>, 'itemRenderer'>;

export interface SelectProps<T extends string> extends FormGroupSelectProps<T> {
  id: string;
  error?: string;
  subText?: string;
  hideErrorMessage?: boolean;
  labelInline?: boolean;
  readOnly?: boolean;
  placeholder?: string;
  onBlur?: FocusEventHandler<HTMLButtonElement | HTMLInputElement>;
  selectOptionMinWidth?: string;
  selectOptionMaxWidth?: string;
  searchIcon?: boolean;
  loading?: boolean;
  highlightFirstActiveItem?: boolean;
  disableMarginBottom?: boolean;
  disableLabelWordWrap?: boolean;
  disableIcon?: boolean;
  matchTargetWidth?: boolean;
  'data-testid'?: string;
  sxFormGroup?: ThemeUIStyleObject;
  sxButtonOverride?: ThemeUIStyleObject;
}

const formGroupStyle = (
  fill: boolean | undefined,
  isDisabled: boolean | undefined,
  hasError: boolean | undefined,
  disableMarginBottom: boolean | undefined,
  disableLabelWordWrap: boolean | undefined
): ThemeUICSSObject => ({
  [`&.${Classes.FORM_GROUP}, .${Classes.FORM_CONTENT}`]: {
    maxWidth: !fill ? 'fit-content' : undefined
  },
  [`&.${Classes.FORM_GROUP} label.${Classes.LABEL}`]: {
    fontSize: 'small',
    color: isDisabled && 'grey80',
    fontWeight: 'bold'
  },
  [`&.${Classes.FORM_GROUP} .${Classes.FORM_HELPER_TEXT}`]: {
    fontSize: 'small',
    color: (() => {
      if (isDisabled) {
        return 'grey80';
      }
      if (hasError) {
        return 'extendedRed100';
      }
      return 'darkGreyText';
    })()
  },
  [`&.${Classes.FORM_GROUP}.${Classes.INLINE} label.${Classes.LABEL}`]: {
    lineHeight: '48px'
  },
  [`&.${Classes.FORM_GROUP}`]: {
    mb: disableMarginBottom ? 0 : undefined
  },
  [`.${Classes.LABEL}`]: {
    whiteSpace: disableLabelWordWrap ? 'nowrap' : undefined
  }
});

/**
 * See: https://blueprintjs.com/docs/#select/select2
 */
const SelectInput = <T extends string>({
  disabled,
  activeItem,
  label,
  labelInfo,
  labelInline,
  error,
  subText,
  hideErrorMessage = false,
  filterable = false,
  readOnly = false,
  placeholder,
  selectOptionMinWidth,
  selectOptionMaxWidth,
  searchIcon = false,
  loading = false,
  highlightFirstActiveItem = false,
  disableMarginBottom = false,
  disableLabelWordWrap = false,
  disableIcon = false,
  matchTargetWidth = false,
  id,
  sxFormGroup,
  sxButtonOverride,
  ...props
}: SelectProps<T>) => {
  const { t } = useTranslation('common');
  const active = activeItem as SelectMenuItem<T>;

  const renderItem: ItemRenderer<SelectMenuItem> = (
    item,
    { handleClick, modifiers }
  ) => {
    if (item.value) {
      return (
        <MenuItem
          id="select-menu-item1"
          active={modifiers.active}
          key={item?.key ?? item.value}
          text={item?.text ?? item.value}
          selected={modifiers.active}
          onClick={handleClick}
          disabled={item.disabled}
          data-testid={
            props['data-testid']
              ? `${props['data-testid']}-item-${item?.key ?? item.value}`
              : `select-item-${item?.key ?? item.value}`
          }
          roleStructure="listoption"
        />
      );
    }

    return (
      <div key={item?.key ?? item.text}>
        <MenuItem
          id="select-menu-item2"
          text={item.text}
          active={false}
          selected={false}
          disabled
          roleStructure="listoption"
        />
      </div>
    );
  };

  const filterItem: ItemPredicate<SelectMenuItem> = (
    query: string,
    item: SelectMenuItem
  ) => {
    if (query.length === 0) {
      return true;
    }

    if (item.value) {
      const compareVal: string | number | undefined = item?.text ?? item.value;

      return compareVal
        .toString()
        .toLowerCase()
        .includes(query.toLowerCase());
    }

    return false;
  };

  const buttonStyle: ThemeUIStyleObject | undefined = {
    color: 'darkGreyText',
    svg: {
      color: error ? 'darkGreyText' : 'grey100'
    },
    backgroundColor: error ? 'extendedRed25' : 'grey10',
    borderColor: error ? 'extendedRed100' : 'grey100',
    fontWeight: 'body',
    justifyContent: 'space-between',
    'span.text': {
      ...textEllipsisMixin,
      fontFamily: 'inherit',
      fontSize: 'base',
      display: 'block',
      maxWidth: selectOptionMaxWidth,
      minWidth: selectOptionMinWidth
    },
    '> span': {
      justifyContent: 'space-between'
    },
    '&:hover:not(:disabled)': {
      backgroundColor: error ? 'extendedRed25' : 'extendedBlue25',
      color: error ? 'grey100' : 'unset',
      svg: {
        color: error ? 'grey100' : 'unset'
      },
      borderColor: theme => `${theme?.colors?.grey100}`
    },
    '&:active:not(:disabled)': {
      color: 'darkGreyText',
      textDecoration: 'none'
    },
    '&:focus': {
      outlineColor: error ? 'red' : transparentize('#58B4ED', 0.5),
      outlineStyle: 'auto',
      outlineWidth: '2px'
    }
  };
  return (
    <FormGroup
      label={label}
      labelFor={id}
      labelInfo={labelInfo}
      inline={labelInline}
      helperText={((!hideErrorMessage && error) || subText) ?? undefined}
      intent={error ? 'danger' : undefined}
      sx={{
        ...formGroupStyle(
          props.fill,
          disabled,
          !!error,
          disableMarginBottom,
          disableLabelWordWrap
        ),
        ...sxFormGroup
      }}
    >
      <Select2
        {...props}
        activeItem={highlightFirstActiveItem ? props.items[0] : activeItem}
        itemRenderer={renderItem}
        itemsEqual={(a, b) =>
          a.value && b.value ? a.value === b.value : false
        }
        itemPredicate={filterItem}
        noResults={
          <MenuItem
            id="no-results-item"
            disabled
            text={t('FORM_SELECT_NO_RESULTS')}
            roleStructure="listoption"
          />
        }
        filterable={filterable}
        disabled={disabled || readOnly}
        popoverProps={{
          ...props.popoverProps,
          matchTargetWidth,
          minimal: true
        }}
        inputProps={{
          ...props.inputProps
        }}
      >
        <Button
          id={id}
          disabled={disabled || readOnly}
          variant="outline"
          fill={props.fill}
          minWidth="unset"
          loading={loading}
          aria-invalid={!!error}
          sx={{ ...buttonStyle, ...sxButtonOverride }}
          data-testid={
            props['data-testid'] ? `${props['data-testid']}-btn` : 'select-btn'
          }
        >
          {!disableIcon && searchIcon && (
            <Icon
              icon={IconNames.SEARCH}
              color={disabled || readOnly ? 'grey80' : 'darkGreyText'}
            />
          )}
          <span
            className="text"
            data-testid={
              props['data-testid']
                ? `${props['data-testid']}-active-item`
                : 'select-active-item'
            }
          >
            {active && (active?.text ?? active.value)}
            {!active &&
              (placeholder ?? t('FORM_SELECT_PLACEHOLDER_SELECT_ONE'))}
          </span>
          {!disableIcon && !searchIcon && (
            <Icon
              icon={IconNames.ARROW_DOWN}
              color={disabled || readOnly ? 'grey80' : 'darkGreyText'}
            />
          )}
        </Button>
      </Select2>
    </FormGroup>
  );
};

export default SelectInput;
