import { ActionCreator } from 'redux';

import { EapAction } from 'types';
import { EcorrTopic, MemberSearchResult } from 'interfaces';

export enum messagesActionTypes {
  GET_INBOX_REQUESTED = 'GET_INBOX_REQUESTED',
  GET_INBOX_SUCCEEDED = 'GET_INBOX_SUCCEEDED',
  GET_INBOX_FAILED = 'GET_INBOX_FAILED',
  GET_INBOX_COUNT_REQUESTED = 'GET_INBOX_COUNT_REQUESTED',
  GET_INBOX_COUNT_SUCCEEDED_FAILED = 'GET_INBOX_COUNT_SUCCEEDED_FAILED',
  GET_INBOX_UNRESOLVED_COUNT_SUCCESS_FAILED = 'GET_INBOX_UNRESOLVED_COUNT_SUCCESS_FAILED',
  SET_INBOX_FILTER_IS_DIRTY = 'SET_INBOX_FILTER_IS_DIRTY',
  RESOLVE_MESSAGE_REQUESTED = 'RESOLVE_MESSAGE_REQUESTED',
  RESOLVE_MESSAGE_SUCCEEDED = 'RESOLVE_MESSAGE_SUCCEEDED',
  RESOLVE_MESSAGE_FAILED = 'RESOLVE_MESSAGE_FAILED',
  LINK_MEMBER_SEARCH_REQUESTED = 'LINK_MEMBER_SEARCH_REQUESTED',
  LINK_MEMBER_SEARCH_SUCCEEDED = 'LINK_MEMBER_SEARCH_SUCCEEDED',
  LINK_MEMBER_SEARCH_FAILED = 'LINK_MEMBER_SEARCH_FAILED',
  CLEAR_LINK_MEMBER_SEARCH = 'CLEAR_LINK_MEMBER_SEARCH',
  LINK_MEMBER = 'LINK_MEMBER',
  UNLINK_MEMBER = 'UNLINK_MEMBER',
  ADD_FILE_TO_NEW_MESSAGE_PENDING_UPLOAD_LIST = 'ADD_FILE_TO_NEW_MESSAGE_PENDING_UPLOAD_LIST',
  REMOVE_FILE_FROM_NEW_MESSAGE_PENDING_UPLOAD_LIST = 'REMOVE_FILE_FROM_NEW_MESSAGE_PENDING_UPLOAD_LIST',
  CLEAR_FILES_NEW_MESSAGE_PENDING_UPLOAD = 'CLEAR_FILES_NEW_MESSAGE_PENDING_UPLOAD',
  ADD_FILE_TO_MESSAGE_THREAD_PENDING_UPLOAD_LIST = 'ADD_FILE_TO_MESSAGE_THREAD_PENDING_UPLOAD_LIST',
  REMOVE_FILE_FROM_MESSAGE_THREAD_PENDING_UPLOAD_LIST = 'REMOVE_FILE_FROM_MESSAGE_THREAD_PENDING_UPLOAD_LIST',
  CLEAR_FILES_MESSAGE_THREAD_PENDING_UPLOAD = 'CLEAR_FILES_MESSAGE_THREAD_PENDING_UPLOAD',
  SET_MESSAGE_THREAD_CASE_ID = 'SET_MESSAGE_THREAD_CASE_ID',
  GET_MESSAGE_THREAD_REQUESTED = 'GET_MESSAGE_THREAD_REQUESTED',
  GET_MESSAGE_THREAD_SUCCESS = 'GET_MESSAGE_THREAD_SUCCESS',
  GET_MESSAGE_THREAD_FAILED = 'GET_MESSAGE_THREAD_FAILED',
  SEND_MESSAGE_REQUESTED = 'SEND_MESSAGE_REQUESTED',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILED = 'SEND_MESSAGE_FAILED',
  CLEAR_MESSAGE_SENT = 'CLEAR_MESSAGE_SENT',
  SEND_MESSAGE_THREAD_REPLY_REQUESTED = 'SEND_MESSAGE_THREAD_REPLY_REQUESTED',
  SEND_MESSAGE_THREAD_REPLY_SUCCESS = 'SEND_MESSAGE_THREAD_REPLY_SUCCESS',
  SEND_MESSAGE_THREAD_REPLY_FAILED = 'SEND_MESSAGE_THREAD_REPLY_FAILED',
  HIDE_SEND_MESSAGE_SUCCESS_NOTIFICATION = 'HIDE_SEND_MESSAGE_SUCCESS_NOTIFICATION',
  UPDATE_MESSAGE_THREAD_STATUS_REQUESTED = 'UPDATE_MESSAGE_THREAD_STATUS_REQUESTED',
  UPDATE_MESSAGE_THREAD_STATUS_SUCCESS = 'UPDATE_MESSAGE_THREAD_STATUS_SUCCESS',
  UPDATE_MESSAGE_THREAD_STATUS_FAILED = 'UPDATE_MESSAGE_THREAD_STATUS_FAILED',
  HIDE_RESOLVE_MESSAGE_SUCCESS_NOTIFICATION = 'HIDE_RESOLVE_MESSAGE_SUCCESS_NOTIFICATION',
  DOWNLOAD_ATTACHMENT_REQUESTED = 'DOWNLOAD_ATTACHMENT_REQUESTED',
  DOWNLOAD_ATTACHMENT_SUCCEEDED = 'DOWNLOAD_ATTACHMENT_SUCCEEDED',
  DOWNLOAD_ATTACHMENT_FAILED = 'DOWNLOAD_ATTACHMENT_FAILED',
  REOPEN_MESSAGE_THREAD_AND_SEND_REQUESTED = 'REOPEN_MESSAGE_THREAD_AND_SEND_REQUESTED',
  REOPEN_MESSAGE_THREAD_AND_SEND_FAILED = 'REOPEN_MESSAGE_THREAD_AND_SEND_FAILED',
  REOPEN_MESSAGE_THREAD_AND_SEND_SUCCEEDED = 'REOPEN_MESSAGE_THREAD_AND_SEND_SUCCEEDED',
  HIDE_REOPEN_MESSAGE_SUCCESS_NOTIFICATION = 'HIDE_REOPEN_MESSAGE_SUCCESS_NOTIFICATION',
  OPEN_NEW_MESSAGE_WITH_PRESET_DATA = 'OPEN_NEW_MESSAGE_WITH_PRESET_DATA',
  SET_PRESET_MESSAGE_DATA = 'SET_PRESET_MESSAGE_DATA',
  SET_PRESET_MESSAGE_DATA_COMPLETE = 'SET_PRESET_MESSAGE_DATA_COMPLETE',
  GET_ECORR_TOPICS = 'GET_ECORR_TOPICS',
  GET_ECORR_TOPICS_SUCCESS = 'GET_ECORR_TOPICS_SUCCESS',
  GET_ECORR_CONTACTS = 'GET_ECORR_CONTACTS',
  GET_ECORR_CONTACTS_SUCCESS = 'GET_ECORR_CONTACTS_SUCCESS',
  GET_DMS_DOCUMENT_LIST_REQUESTED = 'GET_DMS_DOCUMENT_LIST_REQUESTED',
  GET_DMS_DOCUMENT_LIST_SUCCESS = 'GET_DMS_DOCUMENT_LIST_SUCCESS',
  GET_DMS_DOCUMENT_LIST_FAILED = 'GET_DMS_DOCUMENT_LIST_FAILED',
  GET_DMS_ATTACHMENT_REQUESTED = 'GET_DMS_ATTACHMENT_REQUESTED',
  GET_DMS_ATTACHMENT_SUCCEEDED = 'GET_DMS_ATTACHMENT_SUCCEEDED',
  GET_DMS_ATTACHMENT_FAILED = 'GET_DMS_ATTACHMENT_FAILED'
}

export const getInboxAction: ActionCreator<EapAction> = (data: {
  filter: {
    status: string;
    topic: string;
    searchByMemberSelect: string;
    searchByMemberText: string;
    contact: string;
  };
  query: {
    status: string | null;
    topic: string | null;
    employeeId: string | null;
    membershipNo: string | null;
    lastName: string | null;
    showUnreadOnly: boolean;
    contact: string | null;
  };
}) => ({
  type: messagesActionTypes.GET_INBOX_REQUESTED,
  data
});

export const getInboxSucceededAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.GET_INBOX_SUCCEEDED,
  data
});

export const getInboxFailedAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.GET_INBOX_FAILED
});

export const getInboxCountAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.GET_INBOX_COUNT_REQUESTED
});

export const setInboxFilterIsDirtyAction: ActionCreator<EapAction> = (
  data: boolean
) => ({
  type: messagesActionTypes.SET_INBOX_FILTER_IS_DIRTY,
  data
});

export const getInboxCountActionSucceededFailed: ActionCreator<EapAction> = (
  data: number
) => ({
  type: messagesActionTypes.GET_INBOX_COUNT_SUCCEEDED_FAILED,
  data
});

export const resolveAction: ActionCreator<EapAction> = (data: any) => ({
  type: messagesActionTypes.RESOLVE_MESSAGE_REQUESTED,
  data
});

export const linkMemberSearchAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.LINK_MEMBER_SEARCH_REQUESTED,
  data
});

export const clearMemberSearchAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.CLEAR_LINK_MEMBER_SEARCH
});

export const linkMemberAction: ActionCreator<EapAction> = (data: any) => ({
  type: messagesActionTypes.LINK_MEMBER,
  data
});

export const unlinkMemberAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.UNLINK_MEMBER
});

export const linkMemberSearchSuccess: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.LINK_MEMBER_SEARCH_SUCCEEDED,
  data
});

export const linkMemberSearchFailed: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.LINK_MEMBER_SEARCH_FAILED
});

export const addFileNewMessageAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.ADD_FILE_TO_NEW_MESSAGE_PENDING_UPLOAD_LIST,
  data
});

export const removeFileNewMessageAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.REMOVE_FILE_FROM_NEW_MESSAGE_PENDING_UPLOAD_LIST,
  data
});

export const clearFilesPendingNewMessageAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.CLEAR_FILES_NEW_MESSAGE_PENDING_UPLOAD
});

export const addFileMessageThreadAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.ADD_FILE_TO_MESSAGE_THREAD_PENDING_UPLOAD_LIST,
  data
});

export const removeFileMessageThreadAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.REMOVE_FILE_FROM_MESSAGE_THREAD_PENDING_UPLOAD_LIST,
  data
});

export const clearFilesPendingMessageThreadAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.CLEAR_FILES_MESSAGE_THREAD_PENDING_UPLOAD
});

export const setMessageThreadCaseIDAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: messagesActionTypes.SET_MESSAGE_THREAD_CASE_ID,
  data
});

export const getMessageThreadAction: ActionCreator<EapAction> = (data: {
  caseID: string;
  showSendSuccessNotification: boolean;
}) => ({
  type: messagesActionTypes.GET_MESSAGE_THREAD_REQUESTED,
  data
});

export const getMessageThreadSuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.GET_MESSAGE_THREAD_SUCCESS,
  data
});

export const getMessageThreadFailedAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.GET_MESSAGE_THREAD_FAILED
});

export const sendMessageAction: ActionCreator<EapAction> = (data: any) => ({
  type: messagesActionTypes.SEND_MESSAGE_REQUESTED,
  data
});

export const sendMessageSuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.SEND_MESSAGE_SUCCESS,
  data
});

export const sendMessageFailedAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.SEND_MESSAGE_FAILED
});

export const clearMessageSentAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.CLEAR_MESSAGE_SENT
});

export const sendMessageThreadReplyAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.SEND_MESSAGE_THREAD_REPLY_REQUESTED,
  data
});

export const sendMessageThreadReplySuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.SEND_MESSAGE_THREAD_REPLY_SUCCESS,
  data
});

export const sendMessageThreadReplyFailedAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.SEND_MESSAGE_THREAD_REPLY_FAILED
});

export const hideSendMessageSuccessNotificationAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.HIDE_SEND_MESSAGE_SUCCESS_NOTIFICATION
});

export const updateMessageThreadStatusAction: ActionCreator<EapAction> = (data: {
  caseID: string;
  status: string;
}) => ({
  type: messagesActionTypes.UPDATE_MESSAGE_THREAD_STATUS_REQUESTED,
  data
});

export const updateMessageThreadStatusSuccessAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: messagesActionTypes.UPDATE_MESSAGE_THREAD_STATUS_SUCCESS,
  data
});

export const hideResolveMessageSuccessNotificationAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.HIDE_RESOLVE_MESSAGE_SUCCESS_NOTIFICATION
});

export const downloadAttachmentAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: messagesActionTypes.DOWNLOAD_ATTACHMENT_REQUESTED,
  data
});

export const reopenMessageThreadAndSendAction: ActionCreator<EapAction> = (data: {
  caseID: string;
  status: string;
  formDataFields: {
    id: string;
    message: string;
    groupNo: string;
    orgContactEmail: string;
  };
  files: any[];
}) => ({
  type: messagesActionTypes.REOPEN_MESSAGE_THREAD_AND_SEND_REQUESTED,
  data
});

export const hideReopenMessageSuccessNotificationAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.HIDE_REOPEN_MESSAGE_SUCCESS_NOTIFICATION
});

export const openNewMessageWithPresetDataAction: ActionCreator<EapAction> = (data: {
  topic?: string;
  subject?: string | null;
  linkedMember?: MemberSearchResult | null;
  newTab?: boolean;
  fallbackRoute?: string;
  hasAttachment?: boolean;
  attachmentCategory?: string;
  attachmentDocumentType?: string;
}) => ({
  type: messagesActionTypes.OPEN_NEW_MESSAGE_WITH_PRESET_DATA,
  data
});

export const setPresetDataAction: ActionCreator<EapAction> = (data: {
  topic?: string;
  subject?: string | null;
  linkedMember?: MemberSearchResult | null;

  hasAttachment?: boolean | null;
  attachmentCategory?: string | null;
  attachmentDocumentType?: string | null;
}) => ({
  type: messagesActionTypes.SET_PRESET_MESSAGE_DATA,
  data
});

export const setPresetDataCompleteAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.SET_PRESET_MESSAGE_DATA_COMPLETE
});

export const getEcorrTopicsAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.GET_ECORR_TOPICS
});

export const getEcorrContactsAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.GET_ECORR_CONTACTS
});

export const getEcorrTopicsSuccessAction: ActionCreator<EapAction> = (
  data: Array<EcorrTopic>
) => ({
  type: messagesActionTypes.GET_ECORR_TOPICS_SUCCESS,
  data
});

export const getEcorrContactsSuccessAction: ActionCreator<EapAction> = (
  data: Array<any>
) => ({
  type: messagesActionTypes.GET_ECORR_CONTACTS_SUCCESS,
  data
});

export const getDmsDocumentListAction: ActionCreator<EapAction> = (data: {
  employerGroupNumber: string;
}) => ({
  type: messagesActionTypes.GET_DMS_DOCUMENT_LIST_REQUESTED,
  data
});
export const getDmsDocumentListSuccessAction: ActionCreator<EapAction> = (
  data: any
) => ({
  type: messagesActionTypes.GET_DMS_DOCUMENT_LIST_SUCCESS,
  data
});

export const getDmsDocumentListFailedAction: ActionCreator<EapAction> = () => ({
  type: messagesActionTypes.GET_DMS_DOCUMENT_LIST_FAILED
});

export const getDmsAttachmentAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: messagesActionTypes.GET_DMS_ATTACHMENT_REQUESTED,
  data
});
