import { createSlice } from '@reduxjs/toolkit';
import { startOfYear, startOfYesterday } from 'date-fns';
import { InfoPanelType } from './esrSaga';

export interface InfoPanelData {
  type: InfoPanelType;
  value: number;
}

export type DateRange = [Date, Date];

export interface MembershipStatsData {
  totalMembers: number;
  cftMembers: number;
  otcftMembers: number;
  myOmersUsers: number;
  paperlessMembers: number;
  lastUpdated: Date;
}

export interface StewardshipReportState {
  loading: boolean;
  membershipStats: MembershipStatsData | null;
  // Partial is required as we don't have a guarantee all infopanels will be supplied
  infoPanels: Partial<{ [key in InfoPanelType]: InfoPanelData }>;
  loadingFailed: boolean;
  noDataFound: boolean;
  isInitialLoad: boolean;
  previousReportDates: DateRange;
  currentReportDates: DateRange;
}

const initialState: StewardshipReportState = {
  loading: false,
  membershipStats: {
    totalMembers: 0,
    cftMembers: 0,
    otcftMembers: 0,
    myOmersUsers: 0,
    paperlessMembers: 0,
    lastUpdated: new Date()
  },
  infoPanels: {},
  loadingFailed: false,
  noDataFound: false,
  isInitialLoad: true,
  previousReportDates: [startOfYear(new Date()), startOfYesterday()],
  currentReportDates: [startOfYear(new Date()), startOfYesterday()]
};

const stewardshipReportSlice = createSlice({
  name: 'stewardshipReport',
  initialState,
  reducers: {
    getEsrDataRequestAction: (draft, { payload }) => {
      const { startDate, endDate } = payload;
      draft.loading = true;
      draft.previousReportDates = draft.currentReportDates;
      draft.currentReportDates = [startDate, endDate];
    },
    getEsrDataSuccessAction: (draft, action) => {
      Object.assign(draft, { ...action.payload });
      draft.loadingFailed = false;
      draft.loading = false;
      draft.isInitialLoad = false;
      draft.noDataFound = false;
    },
    getEsrDataFailedAction: draft => {
      draft.loadingFailed = true;
      draft.loading = false;
      draft.noDataFound = false;
      draft.currentReportDates = draft.previousReportDates;
    },
    getEsrEmptyDataAction: draft => {
      draft.noDataFound = true;
      draft.loading = false;
    },
    resetEsrData: draft => {
      Object.assign(draft, initialState);
    }
  }
});

export const {
  getEsrDataRequestAction,
  getEsrDataSuccessAction,
  getEsrDataFailedAction,
  getEsrEmptyDataAction,
  resetEsrData
} = stewardshipReportSlice.actions;

export default stewardshipReportSlice.reducer;
