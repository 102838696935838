import { useSelector } from 'react-redux';

import { RootState } from 'types';

export enum RoleTypes {
  FINANCIAL = 1,
  NON_FINANCIAL = 2,
  SMO = 3
}

/**
 * React hook pulls user current active role for currently selected employer;
 * returns map of role and role-validation flags
 */
const useRole = () => {
  const { selectEmployerNumber, groups } = useSelector(
    (state: RootState) => state.selectEmployers
  );

  const relevantGroups = groups.filter(
    group => group.employerNumber === selectEmployerNumber
  );
  const isSMO = relevantGroups.some(group => group.role === RoleTypes.SMO);
  const isFinancial = relevantGroups.some(
    group => group.role === RoleTypes.FINANCIAL
  );
  const isNonFinancial = relevantGroups.some(
    group => group.role === RoleTypes.NON_FINANCIAL
  );

  return {
    isFinancial,
    isNonFinancial,
    isSMO
  };
};

export default useRole;
