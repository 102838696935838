import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Appointment } from './interfaces';

export type MicrosoftBookingsDrawerState = {
  isOpen: boolean;
  appointments: Appointment[];
  loadingAppointmentsError: boolean;
};

const initialState: MicrosoftBookingsDrawerState = {
  isOpen: false,
  appointments: [],
  loadingAppointmentsError: false
};

const microsoftBookingsDrawerSlice = createSlice({
  name: 'microsoftBookingsDrawerSlice',
  initialState,
  reducers: {
    setIsOpen: (draft, { payload }: PayloadAction<boolean>) => {
      draft.isOpen = payload;
    },
    setBookingsAppointmentsAction: (draft, action) => {
      Object.assign(draft, { ...action.payload });
    },
    addNewAppointmentAction: (
      draft,
      { payload }: PayloadAction<Appointment>
    ) => {
      draft.appointments.unshift(payload);
    },
    cancelAppointmentAction: (
      draft,
      { payload }: PayloadAction<Appointment>
    ) => {
      const index = draft.appointments.findIndex(
        appointment => appointment.id === payload.id
      );
      if (index > -1) {
        draft.appointments.splice(index, 1);
      }
    },
    updateAppointmentAction: (
      draft,
      { payload }: PayloadAction<Appointment>
    ) => {
      const index = draft.appointments.findIndex(
        appointment => appointment.id === payload.id
      );
      if (index > -1) {
        draft.appointments.splice(index, 1);
        draft.appointments.unshift(payload);
      }
    },
    setLoadingAppointmentsErrorAction: (
      draft,
      { payload }: PayloadAction<boolean>
    ) => {
      draft.loadingAppointmentsError = payload;
    }
  }
});

export const {
  setIsOpen,
  setBookingsAppointmentsAction,
  setLoadingAppointmentsErrorAction,
  addNewAppointmentAction,
  cancelAppointmentAction,
  updateAppointmentAction
} = microsoftBookingsDrawerSlice.actions;

export const microsoftBookingsDrawerReducer =
  microsoftBookingsDrawerSlice.reducer;
