import { EapAction } from 'types';
import { overrideReasonsActionTypes } from './overrideReasonsActions';

export interface OverrideReasonsState {
  readonly overrideReasons: any;
}

const initialState = {
  overrideReasons: null
};

const overrideReasons = (
  state: OverrideReasonsState = initialState,
  action: EapAction
): OverrideReasonsState => {
  const { type, data } = action;

  switch (type) {
    case overrideReasonsActionTypes.OVERRIDE_REASONS_SUCCEEDED:
      return {
        ...state,
        overrideReasons: data
      };
    case overrideReasonsActionTypes.OVERRIDE_REASONS_FAILED:
      return {
        ...state,
        ...initialState
      };
    default:
      return state;
  }
};

export default overrideReasons;
