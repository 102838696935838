/* eslint-disable react-hooks/rules-of-hooks */
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { isEqual } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form-legacy';
import * as yup from 'yup';

import { RootState } from 'types';
import Button from 'components/button/Button';
import SelectInput from 'components/forms/inputs/selectInput/SelectInput';
import TextInput from 'components/forms/inputs/textInput/TextInput';
import DateRangeInput from 'components/forms/inputs/dateRangeInput/DateRangeInput';
import {
  maskEmployeeId,
  maskSin,
  validateDayMonthYearSingleField
} from 'utils';
import useProperties from 'utils/hooks/useProperties';
import { format, parse, getYear, isAfter } from 'date-fns';
import {
  applyServicePurchaseMemberFilter,
  setServicePurchaseMemberFilterIsDirty,
  resetServicePurchaseMemberFilter
} from '../../form105Actions';

import styles from './ServicePurchaseMemberFilter.module.scss';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { yupResolver } = require('@hookform/resolvers/yup');

export const SEARCH_BY_MEMBER = [
  {
    val: 'emp-id',
    translation: 'SERVICE_PURCHASE_FILTER_DROPDOWN_EMP_ID'
  },
  {
    val: 'sin-no',
    translation: 'SERVICE_PURCHASE_FILTER_SIN_NO'
  }
];

// validates if filter is set to default values
export const isDefaultFilters = (filterOptionals: {
  typeOfPurchase?: string;
  dateReceivedFrom?: string;
  dateReceivedTo?: string;
  searchByMemberSelect?: string;
  searchByMemberValue?: string;
}) =>
  filterOptionals.typeOfPurchase === '' &&
  filterOptionals.dateReceivedFrom ===
    format(
      parse(`${getYear(new Date()) - 2}-01-01`, 'yyyy-MM-dd', new Date()),
      'MM/dd/yyyy'
    ) &&
  filterOptionals.dateReceivedTo === '' &&
  filterOptionals.searchByMemberSelect === SEARCH_BY_MEMBER[0].val &&
  filterOptionals.searchByMemberValue === '';

const ServicePurchaseMemberFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('form105');

  const { servicePurchaseFilter } = useSelector(
    (state: RootState) => state.form105.form105Data
  );

  const methods = useForm({
    resolver: yupResolver(
      yup.object().shape(
        {
          dateReceivedFrom: yup
            .string()
            .test(
              'is service purchase fronm date has correct length',
              t('DATE_INVALID'),
              value => value?.length === 0 || value?.length === 10
            )
            .test(
              'is service purchase received from date valid',
              t('DATE_INVALID'),
              value => validateDayMonthYearSingleField(value)
            )
            .test('is year less than 3000', t('DATE_INVALID'), value => {
              if (value?.length === 10) {
                const year = Number(value?.slice(6, 10));
                return year < 3000;
              }
              return true;
            }),
          dateReceivedTo: yup
            .string()
            .test(
              'is service purchase to date has correct length',
              t('DATE_INVALID'),
              value => value?.length === 0 || value?.length === 10
            )
            .test(
              'is service purchase received to date valid',
              t('DATE_INVALID'),
              value => validateDayMonthYearSingleField(value)
            )
            .test('is year less than 3000', t('DATE_INVALID'), value => {
              if (value?.length === 10) {
                const year = Number(value.slice(6, 10));
                return year < 3000;
              }
              return true;
            })
        },
        [['dateReceivedFrom', 'dateReceivedTo']]
      )
    ),
    defaultValues: {
      typeOfPurchase: servicePurchaseFilter.typeOfPurchase,
      dateReceivedFrom: servicePurchaseFilter.dateReceivedFrom,
      dateReceivedTo: servicePurchaseFilter.dateReceivedTo,
      searchByMemberSelect: servicePurchaseFilter.searchByMemberSelect,
      searchByMemberValue: servicePurchaseFilter.searchByMemberValue
    },
    mode: 'onBlur'
  });

  const { register, control, watch, reset, errors } = methods;

  const {
    typeOfPurchase,
    dateReceivedFrom,
    dateReceivedTo,
    searchByMemberSelect,
    searchByMemberValue
  } = watch([
    'typeOfPurchase',
    'dateReceivedFrom',
    'dateReceivedTo',
    'searchByMemberSelect',
    'searchByMemberValue'
  ]);

  const [isFilterDirty, setIsFilterDirty] = useState<boolean>(false);

  // watch for filter dirty state
  useEffect(() => {
    const selectFilter = {
      typeOfPurchase,
      dateReceivedFrom,
      dateReceivedTo,
      searchByMemberSelect,
      searchByMemberValue
    };
    setIsFilterDirty(
      !isEqual(selectFilter, {
        typeOfPurchase: servicePurchaseFilter.typeOfPurchase,
        dateReceivedFrom: servicePurchaseFilter.dateReceivedFrom,
        dateReceivedTo: servicePurchaseFilter.dateReceivedTo,
        searchByMemberSelect: servicePurchaseFilter.searchByMemberSelect,
        searchByMemberValue: servicePurchaseFilter.searchByMemberValue
      })
    );
  }, [
    servicePurchaseFilter.typeOfPurchase,
    servicePurchaseFilter.dateReceivedFrom,
    servicePurchaseFilter.dateReceivedTo,
    servicePurchaseFilter.searchByMemberSelect,
    servicePurchaseFilter.searchByMemberValue,
    typeOfPurchase,
    dateReceivedFrom,
    dateReceivedTo,
    searchByMemberSelect,
    searchByMemberValue
  ]);

  useEffect(() => {
    if (servicePurchaseFilter.isFilterDirty !== isFilterDirty) {
      dispatch(setServicePurchaseMemberFilterIsDirty({ isFilterDirty }));
    }
  }, [servicePurchaseFilter.isFilterDirty, isFilterDirty]);

  // init service purchase table
  useEffect(() => {
    reset({
      typeOfPurchase: '',
      dateReceivedFrom: format(
        parse(`${getYear(new Date()) - 2}-01-01`, 'yyyy-MM-dd', new Date()),
        'MM/dd/yyyy'
      ),
      dateReceivedTo: '',
      searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
      searchByMemberValue: ''
    });
    dispatch(resetServicePurchaseMemberFilter());
    dispatch(
      applyServicePurchaseMemberFilter({
        searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
        searchByMemberValue: '',
        typeOfPurchase: '',
        dateReceivedFrom: format(
          parse(`${getYear(new Date()) - 2}-01-01`, 'yyyy-MM-dd', new Date()),
          'MM/dd/yyyy'
        ),
        dateReceivedTo: ''
      })
    );
    setIsFilterDirty(false);
  }, []);

  const typeOfPurchases = useProperties([
    ['typeOfPurchases', 'Source Sub Source']
  ]);

  const [
    hasFromDateAfterToDateError,
    setHasFromDateAfterToDateError
  ] = useState(false);

  const isFromDateAfterToDate = () => {
    if (
      dateReceivedFrom === '' ||
      dateReceivedTo === '' ||
      !validateDayMonthYearSingleField(dateReceivedFrom) ||
      !validateDayMonthYearSingleField(dateReceivedTo) ||
      dateReceivedFrom.length < 10 ||
      dateReceivedTo.length < 10
    ) {
      setHasFromDateAfterToDateError(false);
      return;
    }
    setHasFromDateAfterToDateError(
      isAfter(
        parse(dateReceivedFrom, 'MM/dd/yyyy', new Date()),
        parse(dateReceivedTo, 'MM/dd/yyyy', new Date())
      )
    );
  };

  return (
    <div className={styles.servicePurchaseMemberFilter}>
      <FormProvider {...methods}>
        <div className={styles.inputs}>
          <div className={styles.typeOfPurchaseInput}>
            <SelectInput
              name="typeOfPurchase"
              title={t('SERVICE_PURCHASE_SEARCH_FILTER_TYPE_OF_PURCHASE_TITLE')}
              value={typeOfPurchase || ''}
              placeholder={t('common:FORM_SELECT_PLACEHOLDER_SELECT_ONE')}
              ref={register}
            >
              {typeOfPurchases.typeOfPurchases.map(option => (
                <option key={option.val} value={option.key}>
                  {option.val}
                </option>
              ))}
            </SelectInput>
          </div>
          <div className={styles.dateRange}>
            <DateRangeInput
              startDateName="dateReceivedFrom"
              endDateName="dateReceivedTo"
              control={control}
              label={t('SERVICE_PURCHASE_SEARCH_FILTER_DATE_FROM_TITLE')}
              testingTag="service-purchase-date-received"
              hasError={hasFromDateAfterToDateError}
              startDateOnBlur={isFromDateAfterToDate}
              endDateOnBlur={isFromDateAfterToDate}
            />

            {hasFromDateAfterToDateError && (
              <div
                className={styles.error}
                data-testid="service-purchase-date-input-error-msg"
              >
                {t('SERVICE_PURCHASE_FILTER_FROM_AND_TO_DATE_VALIDATION_ERROR')}
              </div>
            )}
          </div>
          <div className={styles.searchByMemberInputDesktop}>
            <TextInput
              name="searchByMemberValue"
              labelText={t('SERVICE_PURCHASE_FILTER_FIELD_SEARCH_BY_MEMBER')}
              type="text"
              mask={(() => {
                switch (searchByMemberSelect) {
                  case SEARCH_BY_MEMBER[0].val:
                    return maskEmployeeId;
                  case SEARCH_BY_MEMBER[1].val:
                    return maskSin;
                }
                return undefined;
              })()}
              testingTag="service-purchase-filter-member-value"
              placeholder={(() => {
                switch (searchByMemberSelect) {
                  case SEARCH_BY_MEMBER[0].val:
                    return t(
                      'SERVICE_PURCHASE_FILTER_SEARCH_BY_MEMBER_EMP_ID_PLACEHOLDER'
                    );
                  case SEARCH_BY_MEMBER[1].val:
                    return t(
                      'SERVICE_PURCHASE_FILTER_SEARCH_BY_MEMBER_SIN_NUM_PLACEHOLDER'
                    );
                }
                return '';
              })()}
              selectMinWidth={178}
              control={control}
            >
              <SelectInput
                name="searchByMemberSelect"
                value={searchByMemberSelect || ''}
                ref={register}
                testingTag="service_purchase-filter-member-select"
              >
                {SEARCH_BY_MEMBER.map(opt => (
                  <option key={opt.val} value={opt.val}>
                    {t(opt.translation)}
                  </option>
                ))}
              </SelectInput>
            </TextInput>
          </div>
        </div>

        <div className={styles.reset}>
          <Button
            baseStyle="basic"
            onClick={() => {
              dispatch(resetServicePurchaseMemberFilter());
              reset({
                typeOfPurchase: '',
                dateReceivedFrom: format(
                  parse(
                    `${getYear(new Date()) - 2}-01-01`,
                    'yyyy-MM-dd',
                    new Date()
                  ),
                  'MM/dd/yyyy'
                ),
                dateReceivedTo: '',
                searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
                searchByMemberValue: ''
              });
              dispatch(
                applyServicePurchaseMemberFilter({
                  searchByMemberSelect: SEARCH_BY_MEMBER[0].val,
                  searchByMemberValue: '',
                  typeOfPurchase: '',
                  dateReceivedFrom: format(
                    parse(
                      `${getYear(new Date()) - 2}-01-01`,
                      'yyyy-MM-dd',
                      new Date()
                    ),
                    'MM/dd/yyyy'
                  ),
                  dateReceivedTo: ''
                })
              );
              setIsFilterDirty(false);
            }}
            testingTag="filter-reset-button"
            autoTestingTag="filter-reset-button"
            isDisabled={isDefaultFilters({
              typeOfPurchase,
              dateReceivedFrom,
              dateReceivedTo,
              searchByMemberSelect,
              searchByMemberValue
            })}
          >
            <ReactSVG src="/images/reset.svg" />
            <span>{t('requests:REQUESTS_FILTER_RESET')}</span>
          </Button>
        </div>

        <div className={styles.apply}>
          <Button
            isDisabled={!isFilterDirty || Object.keys(errors).length !== 0}
            onClick={() => {
              dispatch(
                applyServicePurchaseMemberFilter({
                  searchByMemberSelect,
                  searchByMemberValue,
                  typeOfPurchase,
                  dateReceivedFrom,
                  dateReceivedTo
                })
              );
              setIsFilterDirty(false);
            }}
            testingTag="filter-apply-button"
            autoTestingTag="filter-apply-button"
          >
            {t('requests:REQUESTS_FILTER_APPLY_BTN')}
          </Button>
        </div>
      </FormProvider>
    </div>
  );
};

export default ServicePurchaseMemberFilter;
