import { ActionCreator } from 'redux';

import { EapAction } from 'types';

export enum notificationActionTypes {
  NOTIFY_SUCCESS = 'NOTIFY_SUCCESS',
  NOTIFY_INFO = 'NOTIFY_INFO',
  NOTIFY_WARNING = 'NOTIFY_WARNING',
  NOTIFY_ERROR = 'NOTIFY_ERROR',
  CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION',
  SYSTEM_ERROR_DETECTED = 'SYSTEM_ERROR_DETECTED',
  NOTIFY_ERROR_LOCKED_FORM = 'NOTIFY_ERROR_LOCKED_FORM',
  CLEAR_NOTIFICATION_LOCKED_FORM = 'CLEAR_NOTIFICATION_LOCKED_FORM'
}

export const systemErrorNotificationAction: ActionCreator<EapAction> = () => ({
  type: notificationActionTypes.SYSTEM_ERROR_DETECTED,
  data:
    'Oops, looks like something went wrong on our side. Please try again later. If the problem persists, please contact us at 416.350.6750 or 1.833.884.0389, Monday to Friday between 8 a.m. and 5 p.m.'
});

export const clearNotifications: ActionCreator<EapAction> = () => ({
  type: notificationActionTypes.CLEAR_NOTIFICATION
});

export const notifySuccessAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: notificationActionTypes.NOTIFY_SUCCESS,
  data
});

export const notifyInfoAction: ActionCreator<EapAction> = (data: string) => ({
  type: notificationActionTypes.NOTIFY_INFO,
  data
});

export const notifyErrorAction: ActionCreator<EapAction> = (data: string) => ({
  type: notificationActionTypes.NOTIFY_ERROR,
  data
});

export const notifyLockedFormErrorAction: ActionCreator<EapAction> = (
  data: string
) => ({
  type: notificationActionTypes.NOTIFY_ERROR_LOCKED_FORM,
  data
});

export const clearLockedFormNotificationAction: ActionCreator<EapAction> = () => ({
  type: notificationActionTypes.CLEAR_NOTIFICATION_LOCKED_FORM
});
