import { EapAction } from 'types';
import { selectEmployersActionTypes } from 'containers/selectEmployers/selectEmployersActions';
import { memberSearchActionTypes } from './memberSearchActions';

export interface MemberSearchState {
  loading: boolean;
  memberships: object[];
  query: any;
  resultCount: number;
  isRedirected?: boolean;
  isSearchInitiated: boolean;
  validMemberProfile: any;
  createFormFailedCode: string | null;
  warningCode: string | null;
}

const initialState = {
  loading: false,
  memberships: [{}],
  query: {
    lastName: '',
    firstName: '',
    departmentID: '',
    employeeID: '',
    sin: '',
    membershipNumber: ''
  },
  resultCount: 0,
  isSearchInitiated: false,
  isRedirected: false,
  validMemberProfile: {},
  createFormFailedCode: null,
  warningCode: null
};

const memberSearch = (
  state: MemberSearchState = initialState,
  action: EapAction
): MemberSearchState => {
  const { type, data } = action;
  switch (type) {
    case memberSearchActionTypes.MEMBER_SEARCH_REQUESTED:
      return {
        ...state,
        query: {
          firstName: data.firstName,
          lastName: data.lastName,
          departmentID: data.departmentId,
          employeeID: data.employeeId,
          sin: data.sin,
          membershipNumber: data.omersMembershipNumber
        },
        loading: true,
        isSearchInitiated: true,
        isRedirected: data.isRedirected,
        createFormFailedCode: null
      };
    case memberSearchActionTypes.MEMBER_SEARCH_SUCCEEDED:
      return {
        ...state,
        loading: false,
        memberships: data.data.memberSearchResult.memberships || [{}],
        resultCount: data.data.memberSearchResult.exceededCount
          ? 200
          : data.data.memberSearchResult.memberships.length
      };
    case memberSearchActionTypes.MEMBER_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        memberships: [{}],
        resultCount: 0
      };
    case memberSearchActionTypes.MEMBER_SEARCH_CLEAR_SEARCH_INITIATED:
      return {
        ...state,
        query: {
          firstName: null,
          lastName: null,
          departmentID: null,
          employeeID: null,
          sin: null,
          membershipNumber: null
        },
        loading: false,
        isSearchInitiated: false
      };
    case memberSearchActionTypes.MEMBER_SEARCH_CLEAR_IS_REDIRECTED:
      return {
        ...state,
        isRedirected: false
      };
    case selectEmployersActionTypes.SELECT_EMPLOYER_SUCCESS:
    case selectEmployersActionTypes.SELECT_GROUP_NUMBER_SUCCESS:
      return {
        ...initialState
      };
    case memberSearchActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_SUCCEEDED:
      return {
        ...state,
        validMemberProfile: data.member,
        warningCode: data.warningCode
      };
    case memberSearchActionTypes.CHECK_MEMBER_PROFILE_VALIDITY_FAILED:
    case memberSearchActionTypes.VALID_MEMBER_RESET:
      return {
        ...state,
        validMemberProfile: {}
      };
    case memberSearchActionTypes.CREATE_FORM_FAILED:
      return {
        ...state,
        createFormFailedCode: data ?? null
      };
    case memberSearchActionTypes.CREATE_FORM_FAILED_CLEAR:
      return {
        ...state,
        createFormFailedCode: null
      };
    case memberSearchActionTypes.RESET_WARNING_CODE:
      return {
        ...state,
        warningCode: null
      };
    default:
      return state;
  }
};

export default memberSearch;
