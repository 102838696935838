import { forwardRef } from 'react';
import { Classes, FormGroup, Switch, SwitchProps } from '@blueprintjs/core';
import { transparentize } from '@theme-ui/color';
import { ThemeUICSSObject } from 'theme-ui';

export interface ToggleSwitchProps extends Omit<SwitchProps, 'label'> {
  label?: React.ReactNode;
  sxFormGroup?: ThemeUICSSObject;
  sxSwitch?: ThemeUICSSObject;
  labelInline?: boolean;
  text?: string;
  disableMarginBottom?: boolean;
  disableLabelWordWrap?: boolean;
  id: string;
  error?: string;
  hideErrorMessage?: boolean;
  subText?: string;
}

const formGroupStyles = (
  disableMarginBottom: boolean,
  disableLabelWordWrap: boolean
): ThemeUICSSObject => ({
  [`&.${Classes.FORM_GROUP} label.${Classes.LABEL}`]: {
    fontSize: 'body',
    fontFamily: 'body',
    fontWeight: 'bold'
  },
  [`&.${Classes.FORM_GROUP} .${Classes.FORM_HELPER_TEXT}`]: {
    fontSize: 'body',
    fontFamily: 'body',
    color: 'darkGreyText'
  },
  [`&.${Classes.FORM_GROUP}`]: {
    mb: disableMarginBottom ? 0 : undefined
  },
  [`.${Classes.LABEL}`]: {
    whiteSpace: disableLabelWordWrap ? 'nowrap' : undefined
  }
});

const toggleSwitchStyles: ThemeUICSSObject = {
  [`&.${Classes.CONTROL}.${Classes.SWITCH} input ~ .${Classes.CONTROL_INDICATOR}`]: {
    backgroundColor: 'grey80',
    width: '42px',
    height: '24px',
    position: 'relative'
  },
  [`&.${Classes.CONTROL}.${Classes.SWITCH} .${Classes.CONTROL_INDICATOR}::before`]: {
    boxShadow:
      '0px 0px 0px rgba(16, 22, 26, 0.1), 0px 1px 2px rgba(16, 22, 26, 0.2)',
    backgroundColor: 'white',
    width: '20px',
    height: '20px',
    top: 'calc(50% - 24px/2)',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)'
  },
  [`&.${Classes.CONTROL}.${Classes.SWITCH} input:checked ~ .${Classes.CONTROL_INDICATOR}::before`]: {
    boxShadow:
      '0px 0px 0px rgba(16, 22, 26, 0.1), 0px 1px 2px rgba(16, 22, 26, 0.2)',
    backgroundColor: 'white',
    width: '20px',
    height: '20px',
    left: '17px',
    top: 'calc(50% - 24px/2)',
    transform: 'matrix(-1, 0, 0, 1, 0, 0)'
  },
  [`&.${Classes.CONTROL}.${Classes.SWITCH} input:hover ~ .${Classes.CONTROL_INDICATOR}`]: {
    outline: 'solid 2px',
    outlineColor: 'rgba(88, 180, 237, 0.5)'
  },
  [`&.${Classes.CONTROL}.${Classes.SWITCH} input:checked:hover ~ .${Classes.CONTROL_INDICATOR}`]: {
    outline: 'none',
    backgroundColor: 'extendedBlue100'
  },
  [`&.${Classes.CONTROL}.${Classes.SWITCH} input:disabled:hover ~ .${Classes.CONTROL_INDICATOR}`]: {
    outline: 'none',
    border: 'none'
  },
  [`&.${Classes.CONTROL}.${Classes.SWITCH} input:checked:disabled ~ .${Classes.CONTROL_INDICATOR}`]: {
    backgroundColor: 'brandNavy25',
    opacity: 0.5
  },
  [`&.${Classes.CONTROL}.${Classes.SWITCH} input:checked ~ .${Classes.CONTROL_INDICATOR}`]: {
    backgroundColor: 'brandNavy100',
    outlineColor: 'blue-light',
    width: '42px',
    height: '24px',
    position: 'relative'
  },
  [`&.${Classes.CONTROL} input:not(:checked):focus ~ .${Classes.CONTROL_INDICATOR}`]: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '45px',
    height: '27px',
    border: '2px solid',
    borderOffset: '2px',
    borderColor: 'brandNavy100',
    outline: 'solid 2px',
    outlineColor: transparentize('rgb(88,180,237)', 0.5),
    outlineOffset: '0'
  },
  [`&.${Classes.CONTROL} input:checked:focus ~ .${Classes.CONTROL_INDICATOR}`]: {
    outline: 'solid 2px',
    outlineColor: transparentize('rgb(88,180,237)', 0.5),
    outlineOffset: '0'
  }
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = forwardRef<
  HTMLInputElement,
  ToggleSwitchProps
>(
  (
    {
      label,
      sxFormGroup,
      sxSwitch,
      labelInline,
      text,
      subText,
      disableMarginBottom = false,
      disableLabelWordWrap = false,
      hideErrorMessage = false,
      id,
      error,
      ...props
    },
    ref
  ) => (
    <div data-testid="toggle-switch">
      <FormGroup
        label={label}
        labelFor={id}
        inline={labelInline}
        intent={error ? 'danger' : undefined}
        helperText={
          ((!hideErrorMessage && !props.disabled && error) || subText) ??
          undefined
        }
        sx={{
          ...formGroupStyles(disableMarginBottom, disableLabelWordWrap),
          ...sxFormGroup
        }}
      >
        <Switch
          {...props}
          id={id}
          label={text}
          disabled={props.readOnly || props.disabled}
          inputRef={ref as React.MutableRefObject<HTMLInputElement>}
          aria-invalid={!!error}
          sx={{ ...toggleSwitchStyles, ...sxSwitch }}
        />
      </FormGroup>
    </div>
  )
);

export default ToggleSwitch;
