export * from './regexPatterns';
export * from './format';
export * from './getters';
export * from './mask';
export * from './redux';
export * from './validators';
export * from './file';
export * from './cookies';

/**
 * Rounds float to account for floating point errors in javascript
 * i.e. In javascript 824.84 + 204 = 1028.8400000000001
 * Returns rounded number to given decimal place, defaulting to 2.
 */
export const roundNumber = (value: number, decimalPlaces = 2) => {
  const shift = 10 ** decimalPlaces;
  return Math.round(value * shift) / shift;
};
