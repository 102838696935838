import { form119Data, employmentPeriodModelObject } from 'interfaces';
import { formatAsCurrencyOrGetEmptyString } from 'utils';
import i18n from 'i18next';

export const getEmploymentPeriodModels = (
  employmentPeriodModels: employmentPeriodModelObject[]
) =>
  employmentPeriodModels.map((model: employmentPeriodModelObject) => {
    const language = i18n.language;
    return {
      ...model,
      hashMap: {
        ...model.hashMap,
        credited_service: model.hashMap.credited_service?.toString(),
        earnings: formatAsCurrencyOrGetEmptyString(
          model.hashMap.earnings,
          language,
          true
        ),
        pension_adjustment_amt: formatAsCurrencyOrGetEmptyString(
          model.hashMap.pension_adjustment_amt,
          language,
          true
        ),
        rpp_contribution_amt: formatAsCurrencyOrGetEmptyString(
          model.hashMap.rpp_contribution_amt,
          language,
          true
        ),
        rca_contribution_amt: formatAsCurrencyOrGetEmptyString(
          model.hashMap.rca_contribution_amt,
          language,
          true
        )
      }
    };
  });

const mapForm119 = (form: form119Data) => ({
  ...form,
  e119Model: {
    ...form.e119Model,
    employeeModel: {
      deptId: form.e119Model.employeeModel.deptId ?? '',
      email: form.e119Model.employeeModel.email ?? '',
      employeeId: form.e119Model.employeeModel.employeeId ?? ''
    },
    financialModel: {
      ...form.e119Model.financialModel,
      employmentPeriodModels: getEmploymentPeriodModels(
        form.e119Model.financialModel.employmentPeriodModels
      )
    }
  }
});
export default mapForm119;
