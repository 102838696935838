import { Classes } from '@blueprintjs/core';
import {
  MenuItem2 as MenuItemBP,
  MenuItem2Props as MenuItemBPProps
} from '@blueprintjs/popover2';
import React from 'react';

import { textEllipsisMixin } from 'utils/theme-ui/mixins';
import { ThemeUICSSObject } from 'theme-ui';
import Icon, { IconName } from '../icon/Icon';

export interface MenuItemProps
  extends Omit<MenuItemBPProps, 'icon' | 'small' | 'text'> {
  text?: React.ReactNode;
  icon?: IconName;
  iconPosition?: 'left' | 'right';
  iconColor?: string;
  justifyContent?:
    | 'flex-start'
    | 'flex-end'
    | 'space-between'
    | 'space-around'
    | 'center';
  id: string;
  sxOverride?: ThemeUICSSObject;
}

/**
 * See: https://blueprintjs.com/docs/#popover2-package/menu-item2
 */
const MenuItem: React.FC<MenuItemProps> = ({
  icon,
  iconPosition = 'left',
  iconColor,
  justifyContent = 'flex-start',
  text,
  disabled,
  id,
  sxOverride,
  ...props
}) => (
  <MenuItemBP
    id={id}
    {...props}
    disabled={disabled}
    className={Classes.MENU_ITEM}
    text={
      <div
        sx={{
          display: 'flex',
          gap: '10px',
          justifyContent,
          alignItems: 'center',
          width: '100%',
          svg: {
            color: iconColor,
            marginBottom: '0.15rem'
          }
        }}
      >
        {icon && iconPosition === 'left' && (
          <Icon icon={icon} color={disabled ? 'grey90' : undefined} />
        )}

        <span
          sx={{
            m: 0,
            ...textEllipsisMixin,
            width: '100%'
          }}
        >
          {text}
        </span>

        {icon && iconPosition === 'right' && (
          <Icon icon={icon} color={disabled ? 'grey90' : undefined} />
        )}
      </div>
    }
    sx={{
      fontFamily: 'body',
      fontSize: 'small',
      lineHeight: 'body',
      p: '8px',
      [`& > div.${Classes.TEXT_OVERFLOW_ELLIPSIS}`]: {
        display: 'flex',
        padding: '0px 5px'
      },
      ...sxOverride
    }}
  />
);

export default MenuItem;
