import { isAfter, isValid, parse, sub } from 'date-fns';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { safeAuthEffect } from 'store/sagas/sagaUtils';
import { RootState } from 'types';
import { isInMaintenance } from 'features/maintenanceFlags/maintenanceFlags';
import logger from 'utils/logger/client-side';
import { toLoggerError } from 'utils/api';
import { filter, groupBy } from 'lodash';
import {
  getMSSReportsAction,
  getMssReportsFailedAction,
  getMssReportsSucceededAction
} from './reportsSliceReducer';
import { getMssReports } from './services/reports';
import { MSSReport, MSSReportCodes, MSSReportData } from './types';

export const getNewReportsCount = (data: MSSReportData[]) => {
  const now = new Date();
  const oneMonthAgo = sub(now, { months: 1 });
  return data.filter(report =>
    isAfter(parse(report.completionDate, 'yyyy-MM-dd', now), oneMonthAgo)
  ).length;
};

export const initialMssReports = [
  {
    id: MSSReportCodes.form119ContributionAdjustmentSummary,
    description: '',
    details: []
  },
  {
    id: MSSReportCodes.annualStatementOfPaymentRemittances,
    description: '',
    details: []
  },
  {
    id: MSSReportCodes.membersApproachingAge71,
    description: '',
    details: []
  }
];

const transformMssReports = (data: MSSReportData[]): MSSReport[] => {
  const groupedMssReports = groupBy(data, 'reportId');

  const annualReports = filter(groupedMssReports, report =>
    Object.values(MSSReportCodes).includes(report[0].reportId)
  );

  return annualReports.map(report => ({
    id: report[0].reportId,
    description: report[0].reportDescription,
    details: report.map(detail => ({
      jobNo: detail.jobNo,
      creationDate: detail.completionDate,
      year: isValid(new Date(detail.completionDate))
        ? parse(detail.completionDate, 'yyyy-MM-dd', new Date())
            .getFullYear()
            .toString()
        : '',
      month: isValid(new Date(detail.completionDate))
        ? parse(
            detail.completionDate,
            'yyyy-MM-dd',
            new Date()
          ).toLocaleString('default', { month: 'long' })
        : ''
    }))
  }));
};

export function* getMssReportsSaga(): any {
  const groupNo = yield select(
    (state: RootState) => state.selectEmployers.selectGroupNumber
  );

  if (isInMaintenance('annualReports')) {
    yield put(
      getMssReportsFailedAction({
        showMssReportsErrorScreen: false
      })
    );
  } else {
    try {
      const res = yield call(safeAuthEffect, getMssReports, groupNo);

      // Failure
      if (!res.result) {
        yield put(
          getMssReportsFailedAction({
            showMssReportsErrorScreen: true
          })
        );
        return;
      }

      // Success
      if (res.result.data && transformMssReports(res.result.data).length > 0) {
        yield put(
          getMssReportsSucceededAction({
            mssReports: transformMssReports(res.result.data),
            newMssReportsCount: getNewReportsCount(res.result.data)
          })
        );
      } else {
        // Empty Reports (Users do not have annual reports in DB)
        yield put(
          getMssReportsSucceededAction({
            mssReports: initialMssReports,
            newMssReportsCount: 0
          })
        );
      }
    } catch (e) {
      yield put(
        getMssReportsFailedAction({
          showMssReportsErrorScreen: true
        })
      );
      logger.error(
        `Failed to fetch Annual Reports for ${groupNo}`,
        toLoggerError(e)
      );
    }
  }
}

export function* reportsSaga(): any {
  yield all([takeLatest(getMSSReportsAction.type, getMssReportsSaga)]);
}
