import ReactMarkdown from 'react-markdown';
import htmlParser from 'react-markdown/plugins/html-parser';
import Link from 'next/link';

import styles from './RichText.module.scss';

type LinkColor = 'blue' | 'yellow' | 'red' | 'green' | 'black';
type Props = {
  href: string;
  children: Array<{
    props: {
      children: string;
    };
  }>;
};

export const isExternalLink = (props: Props) =>
  props.href.indexOf('http') !== -1;

// See https://github.com/aknuds1/html-to-react#with-custom-processing-instructions
// for more info on the processing instructions
export const parseHtml = htmlParser({
  isValidNode: (node: { type: string }) => node.type !== 'script',
  processingInstructions: []
});

/**
 * Get either external or internal link
 */
export const getLink = (
  props: Props,
  linkColor: LinkColor,
  isTargetBlank?: boolean
) => {
  if (isExternalLink(props)) {
    return (
      <a
        href={props.href}
        target="_blank"
        className={styles[linkColor]}
        rel="noopener noreferrer"
      >
        {props.children[0].props.children}
      </a>
    );
  }
  return (
    <Link
      href={props.href}
      className={styles[linkColor]}
      target={isTargetBlank ? '_blank' : '_self'}
      rel={isTargetBlank ? 'noopener noreferrer' : undefined}
    >
      {props.children[0].props.children}
    </Link>
  );
};

interface RichTextProps {
  content: string;
  linkColor?: LinkColor;
  disablePTag?: boolean;
  isTargetBlank?: boolean;
}
const RichText = ({
  content,
  linkColor = 'blue',
  disablePTag = false,
  isTargetBlank = false
}: RichTextProps) => (
  <ReactMarkdown
    source={content}
    escapeHtml={false}
    astPlugins={[parseHtml]}
    renderers={{
      link: (props: Props) =>
        getLink(props, linkColor, disablePTag ? false : isTargetBlank)
    }}
    disallowedTypes={disablePTag ? ['paragraph'] : undefined}
    unwrapDisallowed={disablePTag}
  />
);

export default RichText;
